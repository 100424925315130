import config from '../config';

export interface URLOptions {
    BASE_URL: string;
    PRODUCTS_MODALITIES: string;
    CPWS_FILE_UPLOAD: string;
    GET_IMPACTED_MATERIALS: string;
    GET_IMPACTED_MATERIAL_EXTERNAL: string;
    CPWS_ADDITIONAL_INFO: string;
    GET_MATERIAL_TYPE_WITH_COUNT: string;
    SAVE_SELECTED_MATERIAL_TYPES: string;
    GET_CLASSIFICATION_LIST: string;
    GET_MATCH_CRITERIA: string;
    GET_FINAL_RESULTS_RESPONSE: string;
    GET_FINAL_RESULTS: string;
    GET_PRODUCTS_FAM_AND_SITES_BY_MATERIALS: string;
    SITES: string;
    GET_PRESIGNED_URL: string;
    UPDATE_SESSION: string;
    LOGOUT: string;
    INGEST_CPWS_DATA: string;
    INGEST_CPWS_DATA_ASYNC: string;
    GET_INGEST_RESPONSE: string;
    EDIT_CPWS_DATA: string;
    DELETE_MATERIAL_EXTERNAL: string;
    SAVE_CPWS_ASSESSMENT: string;
    GET_CONTAINER_CLOSURE_MATERIALS: string;
    GET_MATCH_CRITERIA_YES_REGULATION: string;
    GET_MATCH_CRITERIA_YES_REGULATION_RESPONSE: string;
    SAVE_EXTERNAL_MATERIALS: string;
    FILE: string;
    GET_PDS_SITE_FUNCTIONS: string;
    GET_CLASSIFICATION_RESPONSE: string;
    ERROR: string;
    GET_CLASSIFICATION_CODES: string;
    GET_ISVIEWONLYUSER: string;
    SET_INCOMPLETE: string;
    GET_RELATED_CHANGES: string;
    GET_FINAL_ASSESSMENT_ADDITIONAL_INPUTS: string;
    REQUEST_SUBMISSION_WORKBOOK: string;
    DOWNLOAD_SUBMISSION_WORKBOOK: string;
}

const URL = `${config.apiGateway.URL}`;

export const URLS = {
    DEV: {
        BASE_URL: `${URL}`,
        PRODUCTS_MODALITIES: `${URL}/products_modalities`,
        CPWS_FILE_UPLOAD: `${URL}/cpws_file_upload2`,
        GET_IMPACTED_MATERIALS: `${URL}/get_impacted_materials`,
        GET_IMPACTED_MATERIAL_EXTERNAL: `${URL}/get_impacted_material_external`,
        CPWS_ADDITIONAL_INFO: `${URL}/cpws_additional_info`,
        GET_MATERIAL_TYPE_WITH_COUNT: `${URL}/get_material_type_with_count`,
        SAVE_SELECTED_MATERIAL_TYPES: `${URL}/save_selected_material_types`,
        GET_CLASSIFICATION_LIST: `${URL}/get_classification_list`,
        GET_MATCH_CRITERIA: `${URL}/get_match_criteria`,
        GET_PRODUCTS_FAM_AND_SITES_BY_MATERIALS: `${URL}/get_products_fam_and_sites_by_materials`,
        SITES: `${URL}/sites`,
        GET_PRESIGNED_URL: `${URL}/cpws_get_presigned_url`,
        GET_FINAL_RESULTS_RESPONSE: `${URL}/get_final_results_response`,
        GET_FINAL_RESULTS: `${URL}/get_final_result_async`,
        UPDATE_SESSION: `${URL}/update_session`,
        LOGOUT: `${URL}/logout`,
        INGEST_CPWS_DATA: `${URL}/ingest_cpws_data`,
        INGEST_CPWS_DATA_ASYNC: `${URL}/ingest_cpws_data_async`,
        GET_INGEST_RESPONSE: `${URL}/get_ingest_response`,
        EDIT_CPWS_DATA: `${URL}/edit_cpws_data`,
        DELETE_MATERIAL_EXTERNAL: `${URL}/delete_material_external`,
        SAVE_CPWS_ASSESSMENT: `${URL}/save_cpws_assessment`,
        GET_CONTAINER_CLOSURE_MATERIALS: `${URL}/get_container_closure_materials`,
        GET_MATCH_CRITERIA_YES_REGULATION: `${URL}/get_match_criteria_yes_regulation_async`,
        GET_MATCH_CRITERIA_YES_REGULATION_RESPONSE:`${URL}/get_match_criteria_yes_regulation_response`,
        SAVE_EXTERNAL_MATERIALS: `${URL}/save_external_materials`,
        FILE: `${URL}/file`,
        GET_PDS_SITE_FUNCTIONS: `${URL}/get_pds_site_functions`,
        GET_CLASSIFICATION_RESPONSE: `${URL}/get_classification_response`,
        ERROR: `${URL}/send_fe_error`,
        GET_CLASSIFICATION_CODES: `${URL}/get_classification_lookup`,
        GET_ISVIEWONLYUSER: `${URL}/is_view_only_user`,
        SET_INCOMPLETE: `${URL}/mark_uncomplete`,
        GET_RELATED_CHANGES: `${URL}/get_related_changes`,
        GET_FINAL_ASSESSMENT_ADDITIONAL_INPUTS: `${URL}/get_final_assesment_addtional_inputs`,
        REQUEST_SUBMISSION_WORKBOOK: `${URL}/request_submission_workbook`,
        DOWNLOAD_SUBMISSION_WORKBOOK: `${URL}/download_submission_workbook`
    },
};
