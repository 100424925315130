import InboxOutlined from '@ant-design/icons/InboxOutlined';
import { Card, Progress, Result, Spin, Typography, Upload, Input, Col, Collapse, Row } from 'antd';
import React from 'react';
import { CPWS, GlobalProps } from '../model';
import './screens.css';
import { CPWSStep } from './screens';
import { CPWSHeader } from '../components';
const { TextArea } = Input;
const { Text } = Typography;
const { Dragger } = Upload;
const { Panel } = Collapse;

export interface Screen0State {
    file: any;
    loading: boolean;
    success: boolean;
    error: boolean;
    filesdone: boolean;
}
export interface Screen0Props {
    sendCPWSFile: Function;
    downloadFile: Function;
    globalProps: GlobalProps;
    cpws: CPWS;
    onText: Function;
    comment: string;
}

export interface FileProperties {
    uid: string;
    name: string;
    status: string;
    url: string;
}

export class Screen0 extends React.Component<Screen0Props, Screen0State> {
    constructor(props: any) {
        super(props);
        this.state = {
            file: null,
            loading: false,
            success: false,
            error: false,
            filesdone: false,
        };
    }

    enableDownload = async (filename: string): Promise<void> => {
        if (this.state.error === false) {
            const download = await this.props.downloadFile(filename);
            if (download.response) {
                this.setState({ success: true, loading: false });
                download.callback();
            } else {
                this.setState({ error: true });
            }
        }
    };

    responseMessage(): JSX.Element {
        const { success, filesdone, error } = this.state;
        // switch (key) {
        //     case value:

        if (error) {
            return (
                <div>
                    <Result status="error" title="There was an error loading your file" subTitle="Please try again" />
                </div>
            );
        }
        if (this.state.loading) {
            return <Spin tip="Loading..."></Spin>;
        }
        if (success && !filesdone) {
            return (
                <div>
                    <Result
                        status="success"
                        title="Successfully uploaded"
                        subTitle="Your data will be available shortly"
                    />
                    <Progress
                        strokeColor={{
                            from: '#108ee9',
                            to: '#87d068',
                        }}
                        percent={90}
                        status="active"
                    />
                </div>
            );
        } else {
            return (
                <div>
                    {/* <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag a file to upload</p> */}
                </div>
            );
        }
    }

    onChange = (info: any): void => {
        this.setState({ file: info.file, loading: true });
    };
    beforeUpload = (file: any): boolean => {
        this.setState({ loading: true });
        this.props.sendCPWSFile(file, this.enableDownload);
        return false;
    };
    changeText = (event: any): void => {
        this.props.onText(event.target.value);
    };

    render(): JSX.Element {
        const { globalProps, cpws } = this.props;
        const realTitle = 'No data was loaded. Please upload a CPWS file.';
        const props = {
            name: 'file',
            multiple: false,
            action: '#',
            onChange: this.onChange,
            beforeUpload: this.beforeUpload,
            showUploadList: { showRemoveIcon: false, showDownloadIcon: false, showPreviewIcon: false },
        };
        return (
            <CPWSStep>
                <Card bordered={false} key={`CPWScard2`}>
                    <Row gutter={4}>
                        <Col
                            span={6}
                            style={{
                                background: '#fafafa',
                                padding: '16px',
                                borderColor: '#d9d9d9',
                                borderStyle: 'solid',
                                borderWidth: '0.5px',
                                borderRight: '0',
                            }}
                        >
                            <Text strong>{`CPWS ID:  0`}</Text>
                            <Input></Input>
                        </Col>
                        <Col
                            span={6}
                            style={{
                                background: '#fafafa',
                                padding: '16px',
                                borderColor: '#d9d9d9',
                                borderStyle: 'solid',
                                borderWidth: '0.5px',
                                borderLeft: '0',
                            }}
                        >
                            <Text style={{ fontSize: '20' }}>Click or Drag a local file </Text>
                            <Dragger className="ant-upload" style={{ height: '15%' }} {...props}>
                                {this.responseMessage()}
                                <InboxOutlined />
                            </Dragger>
                        </Col>

                        <Col
                            span={12}
                            style={{
                                padding: '16px',
                                borderColor: '#d9d9d9',
                                borderStyle: 'solid',
                                borderWidth: '0.5px',
                            }}
                        >
                            <Text>{realTitle}</Text>
                        </Col>
                    </Row>
                </Card>
                <CPWSHeader cpwsId={cpws.cpwsId} title={cpws.title} />
                <Card bordered={false}>
                    <Row gutter={4} key="productssitesrow">
                        <Col span={12}>
                            <Collapse>
                                <Panel header="Products" key="1" style={{ padding: '2px' }}></Panel>
                            </Collapse>
                        </Col>
                        <Col span={12}>
                            <Collapse>
                                <Panel header="Sites" key="1" style={{ padding: '2px' }}></Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Card>
                <Card bordered={false}>
                    <Collapse defaultActiveKey={1}>
                        <Panel header="Change Proposal" key="1">
                            <Row gutter={4} key="changerow">
                                <Col span={12}>
                                    <Card
                                        title="Current"
                                        bordered={true}
                                        headStyle={{
                                            backgroundColor: '#fafafa',
                                        }}
                                    ></Card>
                                </Col>
                                <Col span={12}>
                                    <Card
                                        title="Proposed"
                                        bordered={true}
                                        headStyle={{ backgroundColor: '#fafafa' }}
                                    ></Card>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Card>
                <Card bordered={false}>
                    <Collapse defaultActiveKey={['singleString']}>
                        <Panel header="Quality Assessment statements" key="singleString">
                            {'[No Quality Assessments were located]'}
                        </Panel>
                    </Collapse>
                </Card>
                <Card bordered={false}>
                    <Collapse defaultActiveKey={['technicalInformation']}>
                        <Panel header="Technical Information" key="technicalInformation">
                            {'[Not Entered]'}
                        </Panel>
                    </Collapse>
                </Card>
                <Card bordered={false}>
                    <Collapse defaultActiveKey={['additionalInfoComments']}>
                        <Panel header="Additional Info Comments" key="additionalInfoComments">
                            {'[Not Entered]'}
                        </Panel>
                    </Collapse>
                </Card>
                <Card bordered={false}>
                    <Collapse defaultActiveKey={['reasonForChange']}>
                        <Panel header="Reason For Change" key="reasonForChange">
                            {'[Not Entered]'}
                        </Panel>
                    </Collapse>
                </Card>
                <Card bordered={false}>
                    <Collapse {...globalProps.defaultPanelProps} defaultActiveKey={['attachments']}>
                        <Panel header="CPWS Attachments" key="attachments">
                            <h3>Supplemental Comments (from PDF or other sources)</h3>
                            <TextArea
                                id={'comments'}
                                rows={4}
                                onChange={this.changeText}
                                value={this.props.comment}
                                spellCheck={true}
                            />
                        </Panel>
                    </Collapse>
                </Card>
            </CPWSStep>
        );
    }
}
