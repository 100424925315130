/* eslint-disable @typescript-eslint/camelcase */
import {
    AdditionalInfo,
    AssessmentResult,
    Country,
    CPWS,
    CPWSClassification,
    Matchable,
    MatchCriteria,
    Material,
    ProductModality,
    S3Properties,
    Site,
    RegulatoryQuestions,
    MaterialType,
    CPWSAssessment,
    MatchCriteriaFinalResult,
    MatchCriteriaFinalResultNew,
    MatchCriteriaFinalRegulation,
    ExternalMaterial,
    DownstreamMaterial,
    ProductModalitySelections,
    SiteFunction,
    ApiFiling,
    ApiFinalResult,
    ApiCountryFiling,
    ApiProductModality,
    ApiGetMatchCriteriaYesRegulation,
    ApiMCObject,
    ApiMCRegulation,
    ApiGetClassificationResponse,
    ApiMatchCriteriaQuestion,
    ApiMatchCriteriaQuestionChildren,
    ApiMatchCriteria,
    Filing,
    MaterialClassificationResult,
    ChildQuestion,
    MatchCriteriaAnswer,
    ColorTag,
    CPWSClassificationCodes,
    ApiClassificationCodes,
    ApiClassificationCode,
    ContainerClosureMaterial,
    CPWSClassificationCode,
    Question,
    ApiGetContainerClosureMaterial,
    ApiMaterialTypeWithCount,
    ApiCountry,
    ApiDirectionalAssessment,
    AssesmentMarkets,
    DirectionalAssesmentResult,
    BasicInfo,
    SupportingDetails,
    AdditionalInfoData,
    RelatedChanges,
    Endorsements,
    ProductInformation,
    Modalities,
    ProductFamilies,
    ProductsInfo,
    Suppliers,
    Materials,
    Methods,
    Partners,
    Plants,
    RegisteredMarkets,
    ImpactAnalysis,
    Actions,
    SelectedMaterials,
    CommentDetails
} from '../model';
import { someRegulatoryQuestionAnswersAreYes } from '../utils';
import { v4 as uuidv4 } from 'uuid';
import partition from 'lodash/partition';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import { AppCacheFlags} from '../App';
import { AnyRecord } from 'dns';

function assignAsyncErrorToSession(tmp: string) {
    let sItem = window.sessionStorage.getItem('asyncError');
    if(sItem != undefined && sItem != null && sItem != "") {
        sItem += "\n\n\n" + tmp;
        window.sessionStorage.setItem('asyncError', sItem);
    } else {
        window.sessionStorage.setItem('asyncError', tmp);
    }
}

export function mapProductModality(product: ApiProductModality): ProductModality {
    return {
        familyName: product['fam_name'],
        genericName: product['generic_name'],
        mkNumber: product['mk_number'],
        additionalInfo: product['additional_info'] ? product['additional_info'] : '',
        found: product['found'],
        modality: product['modality'],
    };
}
// use a seperate materialFromJSON for
export function mapExternalMaterialFromJSON(materialObj: ExternalMaterial): Material {
    return {
        number: materialObj.number === '' ? `ext-${uuidv4()}` : materialObj.number,
        name: materialObj.name,
        stg_description: 'external',
        type: [],
        classification: {},
        downstreamMaterials: [],
    };
}

export function mapMaterialRequest(material: Material): string {
    return `{
        "mlt_number": "${material.number}",
        "mlt_name": "${material.name}",
        "stg_description": "${material.stg_description}",
    }`;
}
export function mapProductModalityRequest(product: ProductModality): string {
    const materials = product.materials?.map(material => {
        return mapMaterialRequest(material);
    });
    return `{
        "fam_name": "${product.familyName}",
        "impacted_materials": [
            ${materials}
        ],
    }`;
}

export function mapProductModalities(response: any): Array<ProductModality> {
    try {
        const products = response || [];
        return products.map((product: any) => {
            return mapProductModality(product);
        });
    } catch(e) {
        console.log("Error on mapProductModalities Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapSite(responseSite: any): Site {
    return {
        name: responseSite['sit_name'],
        code: responseSite['sit_code'],
        found: responseSite['found'],
        siteFunctions: [],
        isPDS: false,
    };
}

export function mapSites(response: Array<any>): Array<Site> {
    const sites = response || [];
    return sites.map((site: any) => {
        return mapSite(site);
    });
}

export function mapCPWSSites(response: any): Matchable<Site> {
    try {
        const responseSites = response || [];
        const selected: Array<Site> = [];
        const unmatched: Array<Site> = [];

        const sites: Array<Site> = responseSites.map((site: any) => {
            return mapSite(site);
        });

        sites.forEach((site: Site) => {
            site.found ? selected.push(site) : unmatched.push(site);
        });
        return { selected, unmatched };
    } catch(e) {
        console.log("Error on mapCPWSSites Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapCPWSProducts(response: any): Matchable<ProductModality> {
    try {
        const responseProducts = response || [];
        const selected: Array<ProductModality> = [];
        const unmatched: Array<ProductModality> = [];

        responseProducts?.map((product: any): void => {
            const mappedProduct = mapProductModality(product);
            mappedProduct.found ? selected.push(mappedProduct) : unmatched.push(mappedProduct);
        });

        return { selected, unmatched };
    } catch(e) {
        console.log("Error on mapCPWSProducts Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapGetPreSignedS3(response: any): S3Properties {
    try {
        if (!response) throw new Error();
        return {
            url: response.url,
            key: response.fields.key,
            AWSAccessKeyId: response.fields.AWSAccessKeyId,
            xamzsecuritytoken: response.fields['x-amz-security-token'],
            policy: response.fields.policy,
            signature: response.fields.signature,
        };
    } catch(e) {
        console.log("Error on mapGetPreSignedS3 Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapCpwsFileUploadResponse(response: any): CPWS {    
    try {
        const input = response;
        const returnCPWS: CPWS = {
        status: input['status'], // update with live api/data
        locked_by_user: input['locked_by_user'],
        cpws_session_id: input['cpws_session_id'],
        file_method: input['file_method'],
        cpwsId: input['proposal_id']? input['proposal_id'].toLocaleUpperCase():'',
        title: input['basic_info'] ? input['basic_info']['title']: '',
        changeIdentifier: input['change_identifier'],
        technicalInformation: input['technical-information'],
        additionalInfoComments: input['additional-info-comments'],
        reasonForChange: input['reason-for-change'],
        changeDescCurrent: input['change-desc-current'],
        changeDescProposed: input['change-desc-proposed'],
        qualityAssessment: input['quality_assessment'],
        linkedChangeReference: input['Linked Change Reference(s)'],
        impactedSites: mapCPWSSites(input['impacted-sites']),
        productModality: mapCPWSProducts(input['product-modality']),
        impactedMaterials: [],
        qaQuestion: '',
        attachments: input['Attachments'],
        lastAccessedUserFirstName: input['first_name'],
        lastAccessedUserLastName: input['last_name'],
        lockedByUserFirstName: input['locked_by_user_first_name'],
        lockedByUserLastName: input['locked_by_user_last_name'],
        basicInfo: mapBasicInfoData(input['basic_info']),
        supportingDetails: mapSupportingDetails(input['supporting_details']),
        additionalInfoData: mapAdditionalInfoData(input['additional_info']),
        relatedChanges: mapRelatedChangesData(input['related_changes']),
        endorsements: mapEndorsementsData(input['endorsements']),
        productInformation: mapProductInformation(input['product_information']),
        suppliers: mapSuppliers(input['suppliers']),
        materials: mapMaterials(input['materials']),
        methods: mapMethods(input['methods']),
        partners: mapPartners(input['partners']),
        plants: mapPlants(input['plants']),
        registeredMarkets: mapRegisteredMarkets(input['registered_markets']),
        soldMarkets: mapSoldMarkets(input['where_sold_markets']),
        impactAnalysis: mapImpactAnalysis(input['impact_analysis']),
    };
    return returnCPWS;

    } catch(e) {
        console.log("Error on mapCpwsFileUploadResponse Function : ", e);
        //var tmp = e.stack;
        //window.sessionStorage.setItem('asyncError', tmp);
        //const tmp = e.stack;
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}
/*export function mapCpwsFileUploadResponse(response: any): CPWS {    
    const input = response;
    const returnCPWS: CPWS = {
        status: input['status'], // update with live api/data
        locked_by_user: input['locked_by_user'],
        cpws_session_id: input['cpws_session_id'],
        file_method: input['file_method'],
        cpwsId: input['proposal_id']? input['proposal_id'].toLocaleUpperCase():'',
        title: input['basic_info'] ? input['basic_info']['title']: '',
        changeIdentifier: input['change_identifier'],
        technicalInformation: input['technical-information'],
        additionalInfoComments: input['additional-info-comments'],
        reasonForChange: input['reason-for-change'],
        changeDescCurrent: input['change-desc-current'],
        changeDescProposed: input['change-desc-proposed'],
        qualityAssessment: input['quality_assessment'],
        linkedChangeReference: input['Linked Change Reference(s)'],
        impactedSites: mapCPWSSites(input['impacted-sites']),
        productModality: mapCPWSProducts(input['product-modality']),
        impactedMaterials: [],
        qaQuestion: '',
        attachments: input['Attachments'],
        lastAccessedUserFirstName: input['first_name'],
        lastAccessedUserLastName: input['last_name'],
        lockedByUserFirstName: input['locked_by_user_first_name'],
        lockedByUserLastName: input['locked_by_user_last_name'],
        basicInfo: mapBasicInfoData(input['basic_info']),
        supportingDetails: mapSupportingDetails(input['supporting_details']),
        additionalInfoData: mapAdditionalInfoData(input['additional_info']),
        relatedChanges: mapRelatedChangesData(input['related_changes']),
        endorsements: mapEndorsementsData(input['endorsements']),
        productInformation: mapProductInformation(input['product_information']),
        suppliers: mapSuppliers(input['suppliers']),
        materials: mapMaterials(input['materials']),
        methods: mapMethods(input['methods']),
        partners: mapPartners(input['partners']),
        plants: mapPlants(input['plants']),
        registeredMarkets: mapRegisteredMarkets(input['registered_markets']),
        soldMarkets: mapSoldMarkets(input['where_sold_markets']),
        impactAnalysis: mapImpactAnalysis(input['impact_analysis']),
    };
    return returnCPWS;
}*/

export function mapIngestNotProceed(response: any): any {
    try {
        const input = response;
        const epoch = input['last_accessed_time'];
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: true };
        const date_time = new Date(epoch * 1000);
        const time = `${date_time.toLocaleString('en-US', options)} at ${date_time.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
        })}`;
        let completeFormat;
        if (input['complete'] === 'complete') {
            completeFormat = 'Complete';
        } else {
            completeFormat = 'Not Complete';
        }

        const returnCPWS: any = {
            status: input['status'],
            isid: input['isid'] || null,
            complete: completeFormat || '',
            time: time || '',
            cpwsID: input['cpwsID'] || '',
            curStep: input['curStep'],
            lastAccessedUserFirstName: input['first_name'],
            lastAccessedUserLastName: input['last_name'],
            // status: 'not_proceed',
            // isid: 'doshia' || null,
            // complete: 'not_complete' || '',
            // time: '26-June-2020 at 12:17 PM' || '',
            // cpwsID: input['cpwsID'] || '',
        };
        return returnCPWS;
    }
    catch(e) {
        console.log("Error on mapIngestNotProceed Function:", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
       
}

export function mapRegulatoryQuestionsToObject(regulatoryQuestions: Array<RegulatoryQuestions>): RegulatoryQuestions {
    const obj: RegulatoryQuestions = {};
    //This handles the case where a user does not have any regulatory questions in their session blob in dynamoDB. Any sessions stored in dynamo prior to adding this feature will meet this edge case. Defaults will be created here.
    //For the default case, answer will be false for all questions, and isanswered will be true for all questions.

    if (regulatoryQuestions) {
        regulatoryQuestions.forEach((question: RegulatoryQuestions) => {
            const key = Object.keys(question)[0];
            obj[key] = question[key];
        });
    }

    return obj;
}

export function mapEditProceed(response: any): any {
    try {
        //let date_time = new Date(Date.parse(response.time_accessed + " GMT"));
        const date_time = new Date(response.last_accessed_time * 1000);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: true };
        response.time = `${date_time.toLocaleString('en-US', options)} at ${date_time.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
        })}`;

        if (response.regulatoryQuestions) {
            response.regulatoryQuestions = mapRegulatoryQuestionsToObject(response.regulatoryQuestions);
        }

        return response;
    }
    catch(e) {
        console.log("Error on mapEditProceed Function :", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapUploadNotProceed(response: any): any {
    try {
        const input = response;
        const epoch = input['last_accessed_time'];
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: true };
        const date_time = new Date(epoch * 1000);
        const time = `${date_time.toLocaleString('en-US', options)} at ${date_time.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
        })}`;
        let completeFormat;
        if (input['complete'] === 'complete') {
            completeFormat = 'Complete';
        } else {
            completeFormat = 'Not Complete';
        }

        const returnCPWS: any = {
            status: input['status'],
            isid: input['isid'] || null,
            complete: completeFormat || '',
            time: time || '',
            cpwsID: input['cpwsID'].toLocaleUpperCase() || '',
            curStep: input['curStep'],
        };
        return returnCPWS;
    } catch(e) {
        console.log("Error on mapUploadNotProceed Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

// export function mapCpwsFileUploadResponse2(response: any): CPWS {
//     const input = response;

//     const returnCPWS: CPWS = {
//         status: input['status'], // update with live api/data
//         locked_by_user: input['locked_by_user'],
//         cpwsId: input['cpwsID'],
//         title: input['title'],
//         changeIdentifier: input['change_identifier'],
//         technicalInformation: input['technical-information'],
//         additionalInfoComments: input['additional-info-comments'],
//         reasonForChange: input['reason-for-change'],
//         changeDescCurrent: input['change-desc-current'],
//         changeDescProposed: input['change-desc-proposed'],
//         qualityAssessment: input['quality_assessment'],
//         linkedChangeReference: input['Linked Change Reference(s)'],
//         impactedSites: mapCPWSSites(input['impacted-sites']),
//         productModality: mapCPWSProducts(input['product-modality']),
//         impactedMaterials: [],
//         qaQuestion: false,
//         concurQuestion: false,
//         concurText: '',
//         attachments: input['Attachments'],
//     };

//     return returnCPWS;
// }

export function mapGetProductsRequest(url: string, filter?: string): string {
    if (filter) {
        return url + '?mtl_name=' + filter;
    }
    return url;
}

// export function mapProductRequest(products: Array<ProductModality>): any {
//     return products.map(product => {
//         return {
//             fam_name: product.familyName,
//             mk_number: product.mkNumber,
//             generic_name: product.genericName,
//             additional_info: product.additionalInfo ? product.additionalInfo : '',
//         };
//     });
// }
export function mapProductRequest(products: Array<ProductsInfo>): any {    
    return products.map(product => {
        return {
            product_number: product.product_number,
            product_name: product.product_name,
            stages: product.stages,
            manually_added: product.manually_added,
            directly_impacted: product.directly_impacted,
        };
    });
}

export function mapBasicInfoData(info: any): BasicInfo {
    try {
        if(!info)
            return {};
        return {
            proposal_id: info['proposal_id'],
            title: info['title'],
            change_owner: info['change_owner'],
            change_origin: info['change_origin'],
            change_driver: info['change_driver'],
            site_global: info['site_global'],
            site_functional_area: info['site_functional_area'],
            change_description: info['change_description'],
        };
    } catch(e) {
        console.log("Error on mapBasicInfoData Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapSupportingDetails(response: any): Array<SupportingDetails> {
    try {
        if(!response)
            return [];
        const supportingDetails = response.map(
            (data: any): SupportingDetails => {
                return {
                    additional_reference: data['additional_reference'],
                    change_element: data['change_element'],
                    change_element_detail: data['change_element_detail'],
                    change_element_current: data['change_element_current'],
                    change_element_future: data['change_element_future'],
                    rationale: data['rationale'],
                };
            },
        );
        return supportingDetails;
    } catch(e) {
        console.log("Error on mapSupportingDetails Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapRelatedChangesData(response: any): Array<RelatedChanges> {
    try {
        if(!response)
            return [];
        const relatedChanges = response.map(
            (data: any): RelatedChanges => {
                return {
                    proposal_id: data['proposal_id'],
                    title: data['title'],
                    change_type: data['change_type'],
                    status: data['status'],
                    ri_nri: data['ri_nri'],
                    assessed_by: data['assessed_by'],
                    complete:data['complete']
                };
            },
        );
        return relatedChanges;
    } catch(e) {
        console.log("Error on mapRelatedChangesData Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function getRelatedChangesRequest(response: Array<RelatedChanges>) {
    if(!response)
        return {};
    const relatedChanges = response.map(
        (data: any): RelatedChanges => {
            return {
                proposal_id: data['proposal_id'],
                title: data['title'],
                change_type: data['change_type'],
            };
        },
    );
    return {'related_changes': relatedChanges,};
}

export function mapRelatedChangesApiResponse(response: any): Array<RelatedChanges> {
    const input = response.data;
   return mapRelatedChangesData(input);
}

export function mapAdditionalInfoData(response: any): AdditionalInfoData {
    try {
        if(!response)
            return {};
        
        return {
            additional_considerations: response['additional_considerations'],
            additional_considerations_summary: response['additional_considerations_summary'],
            product_impact: response['product_impact'],
            product_impact_summary: response['product_impact_summary'],
            additional_reference: response['additional_reference'],
        };
    } catch(e) {
        console.log("Error on mapAdditionalInfoData Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapEndorsementsData(response: any): Endorsements {
    try {
        if(!response)
            return {};
        
        return {
            endorsement_decision: response['endorsement_decision'],
            endorser: response['endorser'],
            summary_justification: response['summary_justification'],
        };
    } catch(e) {
        console.log("Error on mapEndorsementsData Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapModalitiesData(response: any): Array<Modalities> {
    if(!response)
        return [];
    const modalitiesData = response.map(
        (data: any): Modalities => { 
            var modalityName = "";
            if(data['modality_name'] === "V")
                modalityName = "Vaccine";
            else if(data['modality_name'] === "SM")
                modalityName = "Small Molecule";
            else if(data['modality_name'] === "B")
                modalityName = "Biologic";
            return {
                modality_code: data['modality_name'],
                modality_name: modalityName,
            };
        },
    );
    return modalitiesData;
}

export function mapProductFamilies(response: any): Array<ProductFamilies> {
    if(!response)
        return [];
    const productFamilies = response.map(
        (data: any): ProductFamilies => {
            return {
                directly_impacted: data['directly_impacted'],
                manually_added: data['manually_added'],
                product_family_name: data['product_family_name'],
            };
        },
    );
    return productFamilies;
}

export function mapProductsData(response: any): Array<ProductsInfo> {
    if(!response)
        return [];
    const products = response.map(
        (data: any): ProductsInfo => {
            return {
                directly_impacted: data['directly_impacted'],
                manually_added: data['manually_added'],
                product_name: data['product_name'],
                product_number: data['product_number'],
                stages: data['stages'],
            };
        },
    );
    return products;
}

export function mapProductInformation(response: any): ProductInformation {
    try {
        if(!response)
            return {modalities: [], product_families: [], products: []};
        
        return {
            modalities: mapModalitiesData(response['modalities']),
            product_families: mapProductFamilies(response['product_families']),
            products: mapProductsData(response['products']),
        };
    } catch(e) {
        console.log("Error on mapProductInformation Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapSuppliers(response: any): Array<Suppliers> {
    try {
        if(!response)
            return [];

        const suppliers = response.map(
            (data: any): Suppliers => {
                return {
                    vendor_supplier_name: data['vendor_supplier_name'],
                    supplier_Type: data['Supplier_Type'],
                };
            },
        );
        return suppliers;
    } catch(e) {
        console.log("Error on mapSuppliers Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapMaterials(response: any): Array<Materials> {
    try {
        if(!response)
            return [];

        const materials = response.map(
            (data: any): Materials => {
                return {
                    material_number: data['material_number'],
                    material_name: data['material_name'],
                };
            },
        );
        return materials;
    } catch(e) {
        console.log("Error on mapMaterials Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapMethods(response: any): Array<Methods> {
    try {
        if(!response)
            return [];

        const methods = response.map(
            (data: any): Methods => {
                return {
                    method_Number: data['Method_Number'],
                    method_Name: data['Method_Name'],
                };
            },
        );
        return methods;
    } catch(e) {
        console.log("Error on mapMethods Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapPartners(response: any): Array<Partners> {
    try {
        if(!response)
            return [];

        const partners = response.map(
            (data: any): Partners => {
                return {
                    partner_name: data['partner_name'],
                    manually_added: data['manually_added'],
                    directly_impacted: data['directly_impacted'],
                };
            },
        );
        return partners;
    } catch(e) {
        console.log("Error on mapPartners Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapPlants(response: any): Array<Plants> {
    try {
        if(!response)
            return [];

        const plants = response.map(
            (data: any): Plants => {
                return {
                    plant_name: data['plant_name'],
                    plant_type: data['plant_type'],
                    manually_added: data['manually_added'],
                    directly_impacted: data['directly_impacted'],
                };
            },
        );
        return plants;
    } catch(e) {
        console.log("Error on mapPlants Function: ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapRegisteredMarkets(response: any): Array<RegisteredMarkets> {
    try {
        if(!response)
            return [];         
        const registeredMarkets = response.map(
            (data: any): RegisteredMarkets => {
                return {
                    country_code: data['country_code'],
                    country_name: data['country_name'],
                    manually_added: data['manually_added'],
                };
            },
        ).sort(function(a:any, b:any) {
            return a.country_name.localeCompare(b.country_name)
        });
        return registeredMarkets;
    } catch(e) {
        console.log("Error on mapRegisteredMarkets Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

/*export function mapSoldMarkets(response: any): Array<RegisteredMarkets> {
    if(!response)
        return [];
    try {
        //response[2]['country_name'] = null; 
        //var testErr = response[2]['country_name'].toLocaleUpperCase();   
        const soldMarkets = response.map(
        (data: any): RegisteredMarkets => {
            return {
                country_code: data['country_code'],
                country_name: data['country_name'],
                manually_added: data['manually_added'],
            };
        },
        ).sort(function(a:any, b:any) {
            return a.country_name.localeCompare(b.country_name)
        });
        console.log(soldMarkets);
        return soldMarkets;
    } catch(e) {
        console.log("Error:", e);
    }
    return [];   
    
}*/

export function mapSoldMarkets(response: any): Array<RegisteredMarkets> {
    try {
        if(!response)
            return [];    
        //response[2]['country_name'] = null; 
        const soldMarkets = response.map(
        (data: any): RegisteredMarkets => {
            return {
                country_code: data['country_code'],
                country_name: data['country_name'],
                manually_added: data['manually_added'],
            };
        },
        ).sort(function(a:any, b:any) {
            return a.country_name.localeCompare(b.country_name)
        });
        //console.log(soldMarkets);
        return soldMarkets;
    }catch(e) {
        console.log("Error on mapSoldMarkets Function", e);
        assignAsyncErrorToSession(e.stack);                
        throw new Error(e);
    }
}


export function mapActions(response: any): Array<Actions> {
    if(!response)
        return [];

    const actions = response.map(
        (data: any): Actions => {
            return {
                action_title: data['action_title'], 
                action_description: data['action_description'],
            };
        },
    );
    return actions;
}

export function mapImpactAnalysis(response: any): Array<ImpactAnalysis> {
    try {
        if(!response)
            return [];

        var key = 0;
        const impactAnalysis = response.map(
            (data: any): ImpactAnalysis => {
                return {
                    key: key++,
                    how_impacted: data['how_impacted'],
                    overall_impact: data['overall_impact'],
                    summary_justification: data['summary_justification'],
                    responsible_resource: data['responsible_resource'],
                    actions: mapActions(data['actions']),
                };
            },
        );
        return impactAnalysis;
    } catch(e) {
        console.log("Error on mapImpactAnalysis Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

// export function mapGetSiteFunctions(products: Array<ProductModality>) {
//     return {
//         'product-modality': mapProductRequest(products),
//     };
// }
export function mapGetSiteFunctions(products: Array<ProductsInfo>) {
    return {
        'product-modality': mapProductRequest(products),
    };
}

export function mapGetSiteFunctionsFromResponse(response: Array<{ sit_name: string; sit_functions: Array<string> }>) {
    try {
        return response.map(siteFunction => ({
            name: siteFunction.sit_name,
            siteFunctions: siteFunction.sit_functions,
        }));
    } catch(e) {
        console.log("Error on mapGetSiteFunctionsFromResponse Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

// export function mapGetExternalMaterialsRequest(url: string, filter?: string): string {
//     if (filter) {
//         return url + '?product_family=' + filter;
//     }
//     return url;
// }

export function mapSiteRequest(sites: Array<Site>): any {
    return sites.reduce((siteList: Array<{ sit_name: string }>, site: Site) => {
        if (!site.isPDS || !Object.prototype.hasOwnProperty.call(site, 'isPDS')) {
            siteList.push({
                sit_name: site.name,
            });
        }
        return siteList;
    }, []);
}
export function mapGetImpactedMaterialsRequest(
    products: Array<ProductModality>,
    sites: Array<Site>,
    ds_dp: Array<any>,
): any {
    const productList = products.map((product: ProductModality) => {
        return {
            fam_name: product.familyName,
            mk_number: product.mkNumber,
            generic_name: product.genericName,
        };
    });
    const siteList = sites.map((site: Site) => {
        return {
            sit_code: site.code,
            sit_name: site.name,
        };
    });
    const requestBody = {
        'product-modality': productList,
        'impacted-sites': siteList,
        ds_dp: ds_dp,
    };
    return requestBody;
}

export function mapGetAdditionalInfoRequest(
        products: Array<ProductsInfo>,
        sites: Array<Site>
    ) {
        const pds_impacted_sites = sites.reduce(
            (pdsSites: Array<{ sit_name: string; sit_functions: Array<string> }>, site: Site) => {
                if (site.isPDS) {
                    pdsSites.push({
                        sit_name: site.name,
                        sit_functions: site.siteFunctions,
                    });
                }
                return pdsSites;
            },
            [],
        );

        const impacted_products = products.map((product: ProductsInfo) => {
            return {
                product_number: product.product_number,
                product_name: product.product_name,
                stages: product.stages,
                manually_added: product.manually_added,
                directly_impacted: product.directly_impacted
            };
        });
        return {
            pds_impacted_sites,
            impacted_products
        };
    }

    

// export function mapGetAdditionalInfoRequest(
//     externalMaterials: Array<ExternalMaterial>,
//     products: Array<ProductModality>,
//     sites: Array<Site>,
//     materials: Array<Material>,
// ) {
//     const impacted_materials_external = externalMaterials.map((externalMaterial: ExternalMaterial) => ({
//         mtl_name: externalMaterial.name,
//         mtl_number: externalMaterial.number,
//     }));
//     const pds_impacted_sites = sites.reduce(
//         (pdsSites: Array<{ sit_name: string; sit_functions: Array<string> }>, site: Site) => {
//             if (site.isPDS) {
//                 pdsSites.push({
//                     sit_name: site.name,
//                     sit_functions: site.siteFunctions,
//                 });
//             }
//             return pdsSites;
//         },
//         [],
//     );

//     const impacted_materials = materials.map((material: Material) => {
//         return {
//             mtl_number: material.number,
//             mtl_name: material.name,
//             stg_description: material.stg_description,
//         };
//     });
//     const impacted_materials_for_products = products.map((product: ProductModality) => {
//         return {
//             fam_name: product.familyName,
//             impacted_materials,
//         };
//     });
//     const impacted_materials_only = {
//         impacted_materials,
//     };

//     return {
//         pds_impacted_sites,
//         impacted_materials_for_products,
//         impacted_materials_only,
//     };
// }


// export function mapGetAdditionalInfoRequest(userSelectedMaterials: Array<SelectedMaterials>) {
    
//     const pds_impacted_sites:any = [];
//     console.log("product 768", userSelectedMaterials);
//     console.log("usM length", Object.keys(userSelectedMaterials).length);
//     userSelectedMaterials.forEach((selectedProduct: any) =>{ console.log("end of line",selectedProduct)});
//     const impacted_products = Array.from(userSelectedMaterials).map((selectedProduct: SelectedMaterials) => {
//         console.log("product 769", selectedProduct);
//         if(selectedProduct.typeCode === "P")
//         {
//             console.log("product 771", selectedProduct);
//             return {
//                 product_number: selectedProduct.number,
//                 product_name: selectedProduct.name,
//                 stages: ["Analytical Test", "Raw Material"],
//                 manually_added: true,
//                 directly_impacted: false
//             };
//         }
//     });
//     console.log("impacted_products", impacted_products)
//     return {
//         pds_impacted_sites,
//         impacted_products
//       //  impacted_materials_only,
//     };
// }


export function mapCountries(countries: Array<ApiCountry>): Array<Country> {
    try {
        return countries.map(
            (country: ApiCountry): Country => {
                return {
                    name: country.cnt_name,
                    code: country.cnt_code,
                    regionCode: country.rgn_code,
                    pdsFlag: country.pds_flag === 'True' ? true : false,
                    ...(Object.prototype.hasOwnProperty.call(country, 'site_functions') && {
                        siteFunctions: country.site_functions,
                    }),
                };
            },
        );
    } catch(e) {
        console.log("Error on mapCountries Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapImpactedMaterials(materials: any): Array<Material> {
    try {
        const impactedMaterials = materials.map(
            (material: any): Material => {
                return {
                    number: material.mtl_number,
                    name: material.mtl_name,
                    stageCode: material.stg_code,
                    stageName: material.stg_name,
                    stg_description: material.stg_description,
                    type: [],
                    classification: {},
                    downstreamMaterials: [],
                };
            },
        );
        return impactedMaterials;
    } catch(e) {
        console.log("Error on mapImpactedMaterials Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

/*export function mapImpactedMaterialsExternal(materials: Array<any>) {
    // temp while cors issue
    // let tempMaterials = ["Sample Custom Material 1", "Sample Custom Material 2"];
    // const impactedMaterials = tempMaterials.map(
    //     (material: any): Material => {
    //         return {
    //             number: '0',
    //             name: material.mtl_name,
    //             stageCode: '0',
    //             stageName: 'custom',
    //             type: MaterialTypeDict.T0,
    //             classification: {},
    //         };
    //     },
    // );
    // if (materials) {
    //     materials = materials;
    // } else {
    //     materials = [];
    // }
    return materials;
}*/

/*export function mapDownstreamMaterials(materials: any): Array<DownstreamMaterial> {
    const impactedMaterials = materials.map((material: any) => {
        return {
            id: material.mtl_number,
            name: material.mtl_name,
            productCode: material.prd_code,
            tradeName: material.trade_name,
        };
    });
    return impactedMaterials;
}*/

export function mapGetImpactedMaterialsFromResponse(response: any): Array<Material> {
    const list = response['impacted-materials'];
    return mapImpactedMaterials(list);
}

export function mapGetImpactedMaterialsExternalFromResponse(
    response: Array<{ mtl_number: string; mtl_name: string }>,
): Array<ExternalMaterial> {
    try {
        return response.map((externalMaterial: any) => ({
            number: externalMaterial.mtl_number,
            name: externalMaterial.mtl_name,
        }));
    } catch(e) {
        console.log("Error on mapGetImpactedMaterialsExternalFromResponse Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapGetAdditionalInfoFromResponse(response: any): AdditionalInfo {
    //const downstreamMaterials = mapDownstreamMaterials(response['downstream_materials']);
    const impactedCountries = mapCountries(response['impacted_countries']);
    //const impactedMaterials = mapImpactedMaterials(response['impacted_materials']);
    return { impactedCountries };
}



// export function mapGetAdditionalInfoFromResponse(response: any): AdditionalInfo {
//     const downstreamMaterials = mapDownstreamMaterials(response['downstream_materials']);
//     const impactedCountries = mapCountries(response['impacted_countries']);
//     const impactedMaterials = mapImpactedMaterials(response['impacted_materials']);
//     return { downstreamMaterials, impactedCountries, impactedMaterials };
// }

/*export function mapGetProductsFamilySitesMaterial(products: Array<ProductModality>, materials: Array<Material>): any {
    const materialsRequest = materials.map((material: Material) => {
        return {
            mtl_number: material.number,
            mtl_name: material.name,
        };
    });
    const productsRequest = products.map((product: ProductModality) => {
        return {
            fam_name: product.familyName,
            impacted_materials: materialsRequest,
        };
    });
    const requestBody = {
        'impacted-materials': productsRequest,
    };
    return requestBody;
}*/

export function mapGetClassificationListRequest(cpws: CPWS) {
    const requestObjest = {
        'cpws-Id': cpws.cpwsId,
        title: cpws.title||'',
        change_driver: cpws.basicInfo ? cpws.basicInfo.change_driver: 'NA',
        //'technical-information': cpws.technicalInformation,
        //'additional-info-comments': cpws.additionalInfoComments,
        //'reason-for-change': cpws.reasonForChange,
        //'change-desc-current': cpws.changeDescCurrent,
        //'change-desc-proposed': cpws.changeDescProposed,
        //'product-modality': mapProductRequest(cpws.productModality.selected),
        //'impacted-sites': mapSiteRequest(cpws.impactedSites.selected),
        //quality_assessment: cpws.qualityAssessment,
        //'Linked Change Reference(s)': cpws.linkedChangeReference,
        'supporting_details': cpws.supportingDetails||[], 
        'additional_considerations':cpws.additionalInfoData ? cpws.additionalInfoData.additional_considerations : 'NA',   
        'change_description': cpws.basicInfo ? cpws.basicInfo.change_description : 'NA',
        'related_changes': cpws.relatedChanges||[], 
    };
    return requestObjest;
}

export function mapGetMatchCriteriaResponse(response: ApiMatchCriteria): MatchCriteria {
    try {
        return response.match_criterias.map((question: ApiMatchCriteriaQuestion) => {
            let questionChildren: Array<ChildQuestion> = [];
            if (question.children) {
                questionChildren = question.children.map((childQuestion: ApiMatchCriteriaQuestionChildren) => ({
                    parentCode: childQuestion.pmc_code,
                    parentDef: childQuestion.pmc_definition,
                    parentAnswer: childQuestion.pmc_answer,
                    mtcCode: childQuestion.mtc_code,
                    mtcDef: childQuestion.mtc_definition,
                    classificationLabel: childQuestion.cls_label,
                    materialTypeLabel: childQuestion.imt_label,
                    materialTypeCode: childQuestion.imt_code,
                    isContainerClosure: childQuestion.is_cont_clos === 'Y' ? true : false,
                }));
            }

            return {
                parentCode: question.pmc_code,
                parentDef:
                    question.children && questionChildren.length > 0
                        ? questionChildren[0].parentDef
                        : question.pmc_definition
                        ? question.pmc_definition
                        : '',
                children: questionChildren,
                classificationLabel: question.cls_label,
                materialTypeLabel:
                    question.children && questionChildren.length > 0
                        ? questionChildren[0].materialTypeLabel
                        : question.imt_label
                        ? question.imt_label
                        : '',
                materialTypeCode:
                    question.children && questionChildren.length > 0
                        ? questionChildren[0].materialTypeCode
                        : question.imt_code
                        ? question.imt_code
                        : '',
                isContainerClosure:
                    question.children && questionChildren.length > 0 && questionChildren[0].isContainerClosure
                        ? true
                        : question.children && questionChildren.length > 0 && !questionChildren[0].isContainerClosure
                        ? false
                        : question.is_cont_clos === 'Y'
                        ? true
                        : false,
            };
        });
    } catch(e) {
        console.log("Error on mapGetMatchCriteriaResponse Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapGetClassificationCodesResponse(response: ApiClassificationCodes): CPWSClassificationCodes {
    try {
        return response.map((classification: ApiClassificationCode) => ({
            code: classification.cls_code,
            definition: classification.cls_title,
            description: classification.cls_description,
        }));
    } catch(e) {
        console.log("Error on mapGetClassificationCodesResponse Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

function mapFilings(apiFilings: Array<ApiFiling>) {
    try {
        return apiFilings.reduce((filings: Array<Filing>, apiFiling: ApiFiling) => {
            if (apiFiling.cht_code) {
                filings.push({
                    chtCode: apiFiling.cht_code,
                    category: apiFiling.mtc_category,
                    description: apiFiling.cht_desc === 'n/a' ? 'Not Available' : apiFiling.cht_desc,
                    link: apiFiling.cht_link === 'n/a' ? 'Not Available' : apiFiling.cht_link,
                    regionCode: apiFiling.rgn_code,
                    filing: apiFiling.cht_filing,
                    approvalTime: Object.prototype.hasOwnProperty.call(apiFiling, 'approval_time')
                        ? apiFiling.approval_time
                        : undefined,
                    filingType: Object.prototype.hasOwnProperty.call(apiFiling, 'filing_type')
                        ? apiFiling.filing_type
                        : undefined,
                    notification: Object.prototype.hasOwnProperty.call(apiFiling, 'notification')
                        ? apiFiling.notification
                        : undefined,
                    notificationType: Object.prototype.hasOwnProperty.call(apiFiling, 'notification_type')
                        ? apiFiling.notification_type
                        : undefined,
                    rank: Object.prototype.hasOwnProperty.call(apiFiling, 'rank') ? apiFiling.rank : undefined,
                });
            }
            return filings;
        }, []);
    } catch(e) {
        console.log("Error on mapFilings Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

const clone = (obj: any) => Object.assign({}, obj);

const renameKey = (object: any, key: string, newKey: string) => {
    const clonedObj = clone(object);
    const targetKey = clonedObj[key];
    delete clonedObj[key];
    clonedObj[newKey] = targetKey;
    return clonedObj;
};

function mapMarkets(markets: ApiDirectionalAssessment): AssesmentMarkets {
    return renameKey(markets, 'prior_approval', 'priorApproval');
}

function getAssessmentResults(data: any) {
    try {
        const retval = data.map(
            (item: ApiCountryFiling): AssessmentResult => {
                const filings = mapFilings(item.filings);
                const [filingsHA, filingsRC] = partition(filings, ['category', 'HA']);
                return {
                    countryCode: item.cnt_code,
                    filingsHA: filingsHA,
                    filingsRC: filingsRC,
                    dataRequirements: item.data_requirements,
                    historicalData:{
                        RAD_RI:item.RAD_RI,
                        RAD_NRI:item.RAD_NRI,
                        RI_Percent:item.RI_Percent,
                        NRI_Percent:item.NRI_Percent
                    },
                    color:
                        filingsRC.length > 0 && filingsRC.some((filing: Filing) => filing.filing === 'Y')
                            ? 'RED'
                            : item.color,
                };
            },
        );    
        return retval;
    } catch(e) {
        console.log("Error on getAssessmentResults Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapGetFinalResultsResponse(response: ApiFinalResult): Partial<DirectionalAssesmentResult> {
    //const majorMarkets = mapMarkets(response.directional_assessment.major_markets);
    //const secondaryMarkets = mapMarkets(response.directional_assessment.secondary_markets);
    const tmp = {priorApproval: [], notification: [], nri: [], incomplete: [], total: []};
    const BMajorMarkets = (response.directional_assessment.B != undefined) ? mapMarkets(response.directional_assessment.B.major_markets) : tmp;
    const SMMajorMarkets = (response.directional_assessment.SM != undefined) ? mapMarkets(response.directional_assessment.SM.major_markets) : tmp;
    const VMajorMarkets = (response.directional_assessment.V != undefined) ? mapMarkets(response.directional_assessment.V.major_markets) : tmp;
    const BSecondaryMarkets = (response.directional_assessment.B != undefined) ? mapMarkets(response.directional_assessment.B.secondary_markets) : tmp;
    const SMSecondaryMarkets = (response.directional_assessment.SM != undefined) ? mapMarkets(response.directional_assessment.SM.secondary_markets) : tmp;
    const VSecondaryMarkets = (response.directional_assessment.V != undefined) ? mapMarkets(response.directional_assessment.V.secondary_markets) : tmp;
    const dataRequirementsResponse = response.data_requirements;
    const marketLevelAssessmentResponse = response.market_level_assessment;
    const BAssessmentResults = (response.market_level_assessment.B != undefined) ? getAssessmentResults(response.market_level_assessment.B) : [];
    const SMAssessmentResults = (response.market_level_assessment.SM != undefined) ? getAssessmentResults(response.market_level_assessment.SM) : [];
    const VAssessmentResults = (response.market_level_assessment.V != undefined) ? getAssessmentResults(response.market_level_assessment.V) : [];
    
    //console.log("reached 1117", BAssessmentResults);
    /*const finalResults = response.final_results.map(
        (item: ApiCountryFiling): AssessmentResult => {
            const filings = mapFilings(item.filings);
            const [filingsHA, filingsRC] = partition(filings, ['category', 'HA']);

            return {
                countryCode: item.cnt_code,
                filingsHA: filingsHA,
                filingsRC: filingsRC,
                color:
                    filingsRC.length > 0 && filingsRC.some((filing: Filing) => filing.filing === 'Y')
                        ? 'RED'
                        : item.color,
            };
        },
    );*/
    console.log("reached 1108");
    const responseRes = {
        /*finalResults: finalResults,
        directionalAssesment: {
            majorMarkets: majorMarkets,
            secondaryMarkets: secondaryMarkets,
        },*/
        marketLevelAssessment: {
            B: BAssessmentResults,
            SM: SMAssessmentResults,
            V: VAssessmentResults
        },
        dataRequirements: dataRequirementsResponse,
        directionalAssesment: {
            B: {
                majorMarkets: BMajorMarkets,
                secondaryMarkets: BSecondaryMarkets,
            },
            SM: {
                majorMarkets: SMMajorMarkets,
                secondaryMarkets: SMSecondaryMarkets,
            },
            V: {
                majorMarkets: VMajorMarkets,
                secondaryMarkets: VSecondaryMarkets,
            }
        },
        system_nri_ri: response.system_nri_ri,
        assessments: response.assessments,
        status:response.status,
    };
    //console.log("line 1129", JSON.stringify(responseRes, null, 2));
    return responseRes;
}

/*export function mapGetFinalResultsLatestResponse(response: ApiFinalResult) {
    return response.final_results.map(
        (item: ApiCountryFiling): AssessmentResult => {
            const filings = mapFilings(item.filings);
            const [filingsHA, filingsRC] = partition(filings, ['category', 'HA']);

            return {
                countryCode: item.cnt_code,
                filingsHA: filingsHA,
                filingsRC: filingsRC,
                color:
                    filingsRC.length > 0 && filingsRC.some((filing: Filing) => filing.filing === 'Y')
                        ? 'RED'
                        : item.color,
            };
        },
    );
}*/

function getRegulations(data: any) {
    try {
        const retval = data.map(
            (item: ApiMCObject): MatchCriteriaFinalResult => ({
                mtcDefinition: item.mtc_definition,
                regulations: item.regulations.map(
                    (reg: ApiMCRegulation): MatchCriteriaFinalRegulation => ({
                        link: reg.cht_link,
                        chtCode: reg.cht_code,
                        description: reg.cht_desc,
                        regMarket: reg.cht_market,
                    }),
                ),
            }),
        );
        //console.log(retval);
        //const obj = Object.assign({}, ...retval);
        return retval;
    } catch(e) {
        console.log("Error on getRegulations Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

export function mapGetMatchCriteriaYesRegulationFromResponse(
    response: ApiGetMatchCriteriaYesRegulation,
): Array<MatchCriteriaFinalResultNew> {
    //console.log("mapGetMatchCriteriaYesRegulationFromResponse-->", response.match_criteria_questions_regulations["B"]);
    const biologic = (response.match_criteria_questions_regulations["B"] != undefined) ? getRegulations(response.match_criteria_questions_regulations["B"]) :  [];
    const smallMolecule = (response.match_criteria_questions_regulations["SM"] != undefined) ? getRegulations(response.match_criteria_questions_regulations["SM"]) : [];
    const vaccine = (response.match_criteria_questions_regulations["V"] != undefined) ? getRegulations(response.match_criteria_questions_regulations["V"]): [];
    const responseData = [{
        B: biologic,
        SM: smallMolecule,
        V: vaccine
    }];
    //console.log(responseData);
    return responseData;
}

export function mapProductModalitySelectionToList(modalityList: ProductModalitySelections) {
    return Object.keys(modalityList).reduce((modalities: Array<string>, modality: string) => {
        if (modalityList[modality]) {
            switch (modality) {
                case 'biologic':
                    modalities.push('B');
                    break;
                case 'smallMolecule':
                    modalities.push('SM');
                    break;
                case 'vaccine':
                    modalities.push('V');
                    break;
            }

            return modalities;
        }

        return modalities;
    }, []);
}

function findAllClassifications(
    flattenedMatchCriteria: Array<Question | ChildQuestion>,
    impactedMaterialTypeCode: string,
    mtcCode: string,
) {
    return flattenedMatchCriteria.reduce((classifications: Array<string>, question: Question | ChildQuestion) => {
        if (Object.prototype.hasOwnProperty.call(question, 'mtcCode')) {
            const childQuestion = question as ChildQuestion;
            if (childQuestion.mtcCode === mtcCode && childQuestion.materialTypeCode === impactedMaterialTypeCode) {
                classifications.push(childQuestion.classificationLabel);
            }
        } else {
            const parentQuestion = question as Question;
            if (parentQuestion.parentCode === mtcCode && parentQuestion.materialTypeCode === impactedMaterialTypeCode) {
                classifications.push(parentQuestion.classificationLabel);
            }
        }
        return classifications;
    }, []);
}

export function mapGetFinalResultsRequest(
    sterile_non_sterile: Array<any>,
    category: Array<any>,
    cpws: CPWS,
    countries: Array<Country>,
    answers: MatchCriteriaAnswer,
    productModalitySelections: ProductModalitySelections,
    classificationCodes: CPWSClassificationCodes,
    matchCriteria: MatchCriteria,

) {
    const flattenedMatchCriteria = matchCriteria.reduce(
        (flattenedList: Array<Question | ChildQuestion>, question: Question) => {
            return flattenedList.concat([{ ...question, children: [] }, ...question.children]);
        },
        [],
    );

    const reducer = (codes: Array<string>, country: Country) => {
        if (
            (country.pdsFlag &&
                Object.prototype.hasOwnProperty.call(country, 'manuallyAdded') &&
                country.manuallyAdded) ||
            (country.pdsFlag && !Object.prototype.hasOwnProperty.call(country, 'manuallyAdded')) ||
            (!country.pdsFlag &&
                Object.prototype.hasOwnProperty.call(country, 'manuallyAdded') &&
                country.manuallyAdded)
        ) {
            codes.push(country.code);
        }
        return codes;
    };
    console.log("Answers:", answers);
    return {
        sterile_non_sterile: sterile_non_sterile,
        ds_dp: category,
        cpws_classifications: cpws.impactedMaterials.map((material: Material) => {
            const newMaterialTypeArr: Array<string> = [];
            material.type.forEach((obj: MaterialType) => {
                newMaterialTypeArr.push(obj.code);
            });
            const finalDownstreamArr: Array<{
                mtl_number: string;
                mtl_type: Array<string>;
            }> = [];

            material.downstreamMaterials.forEach((downstream: ContainerClosureMaterial) => {
                const newDownStreamMaterialsType: Array<string> = [];
                downstream.type.forEach((downstreamType: MaterialType) => {
                    newDownStreamMaterialsType.push(downstreamType.code);
                });
                finalDownstreamArr.push({
                    mtl_number: downstream.number,
                    mtl_type: newDownStreamMaterialsType,
                });
            });

            const impacted_material = {
                mtl_number: material.number,
                mtl_type: newMaterialTypeArr,
                downstream_mtls: finalDownstreamArr,
            };
            return {
                impacted_materials: impacted_material,
                ...material.classification,
            };
        }),
        countries: countries.map((country: Country) => country.code),
        in_scope_markets: countries.reduce(reducer, []),
        mtc_code: Object.keys(answers).reduce((mtcArrays: Array<Array<string | boolean>>, key: string) => {
            const regex = /(T\d+)_(MC\d+)/m;
            const match = regex.exec(key);
            //console.log("Match:", match);
            if (match && answers[key].isAnswered && answers[key].answer) {
                const classifications = findAllClassifications(flattenedMatchCriteria, match[1], match[2]);
                console.log("Classification:", classifications);
                classifications.forEach((classification: string) => {
                    const answerArray = [
                        match[1],
                        (find(classificationCodes, ['definition', classification]) as CPWSClassificationCode).code,
                        match[2],
                        answers[key].isContainerClosure ? 'Y' : 'N',
                    ];
                    if (!mtcArrays.some((mtcArray: Array<string | boolean>) => isEqual(mtcArray, answerArray))) {
                        mtcArrays.push(answerArray);
                    }
                });
            }
            return mtcArrays;
        }, []),
        modality_list: mapProductModalitySelectionToList(productModalitySelections),
        cpws_title: cpws.title
    };
}

export function mapGetMatchCriteriaRequest(
    cpws: CPWS,
    countries: Array<Country>,
    ds_dp: Array<string>,
    sterile_non_sterile: Array<string>,
    productModalitySelections: ProductModalitySelections,
    userSelectedMaterials: Array<SelectedMaterials>,
): any {
    return {
        cpws_ID: cpws.cpwsId,
        cpws_classifications: userSelectedMaterials.map((material: SelectedMaterials) => {
            const newMaterialTypeArr: Array<string> = [];
            material.type.forEach((obj: MaterialType) => {
                newMaterialTypeArr.push(obj.code);
            });
            const finalDownstreamArr: Array<{
                mtl_number: string;
                mtl_type: Array<string>;
                mtl_name: string;
            }> = [];

            material.downstreamMaterials.forEach((downstream: ContainerClosureMaterial) => {
                const newDownStreamMaterialsType: Array<string> = [];
                downstream.type.forEach((downstreamType: MaterialType) => {
                    newDownStreamMaterialsType.push(downstreamType.code);
                });
                finalDownstreamArr.push({
                    mtl_number: downstream.number,
                    mtl_type: newDownStreamMaterialsType,
                    mtl_name: downstream.name,
                });
            });

            const impacted_material = {
                mtl_number: material.number,
                mtl_type: newMaterialTypeArr,
                mtl_name: material.name,
                downstream_mtls: finalDownstreamArr,
            };

            return {
                impacted_materials: impacted_material,
                ...material.classification,
            };
        }),
        countries: countries.map((country: Country) => {
            return country.code;
        }),
        ds_dp: ds_dp,
        sterile_non_sterile: sterile_non_sterile,
        modality_list: mapProductModalitySelectionToList(productModalitySelections),
    };
}

// export function mapGetMatchCriteriaRequest(
//     cpws: CPWS,
//     countries: Array<Country>,
//     ds_dp: Array<string>,
//     sterile_non_sterile: Array<string>,
//     productModalitySelections: ProductModalitySelections,
// ): any {
//     return {
//         cpws_ID: cpws.cpwsId,
//         cpws_classifications: cpws.impactedMaterials.map((material: Material) => {
//             const newMaterialTypeArr: Array<string> = [];
//             material.type.forEach((obj: MaterialType) => {
//                 newMaterialTypeArr.push(obj.code);
//             });
//             const finalDownstreamArr: Array<{
//                 mtl_number: string;
//                 mtl_type: Array<string>;
//                 mtl_name: string;
//             }> = [];

//             material.downstreamMaterials.forEach((downstream: ContainerClosureMaterial) => {
//                 const newDownStreamMaterialsType: Array<string> = [];
//                 downstream.type.forEach((downstreamType: MaterialType) => {
//                     newDownStreamMaterialsType.push(downstreamType.code);
//                 });
//                 finalDownstreamArr.push({
//                     mtl_number: downstream.number,
//                     mtl_type: newDownStreamMaterialsType,
//                     mtl_name: downstream.name,
//                 });
//             });

//             const impacted_material = {
//                 mtl_number: material.number,
//                 mtl_type: newMaterialTypeArr,
//                 mtl_name: material.name,
//                 downstream_mtls: finalDownstreamArr,
//             };

//             return {
//                 impacted_materials: impacted_material,
//                 ...material.classification,
//             };
//         }),
//         countries: countries.map((country: Country) => {
//             return country.code;
//         }),
//         ds_dp: ds_dp,
//         sterile_non_sterile: sterile_non_sterile,
//         modality_list: mapProductModalitySelectionToList(productModalitySelections),
//     };
// }

export function mapGetClassificationListResponse(
    response: Partial<ApiGetClassificationResponse>,
): Partial<MaterialClassificationResult> {
    try {
        return {
            status: response.status,
            result: response.result ? response.result : {},
        };
    } catch(e) {
        console.log("Error on mapGetClassificationListResponse Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}
/*export function mapGetProductsFamilySitesMaterialResponse(response: any) {
    const productsSection = response['product-modality'];
    const sitesSection = response['sites'];

    const products = mapProductModalities(productsSection);
    const sites = mapSites(sitesSection);
    return { products, sites };
}*/

// export function mapGetFinalAssesmentAdditionalInputs(
//     modalities: any
// ) {

// }

export function mapGetProductMaterialTypeWithCountRequest(
    materials: any,
    DS_DP: Array<any>,
    modalities: ProductModalitySelections,
) {
    // const product = materials.selectedProductRowKeys.map((p: any) => {
    //     return {type:'P',number: p.product_number};
    // });
    // const material = materials.selectedMaterialRowKeys.map((m: any) => {
    //     return {type:'M',number: m.material_number};
    // });
    const product = materials.map((p: any) => {
        return {type:p.typeCode,number: p.number};
    });
    const material = materials.map((m: any) => {
        return {type:m.typeCode,number: m.number};
    });
    const all_materials = [...product, ...material];

    const impacted_materials_request: any = [];

    for (const material of all_materials) {
        const getMaterialIndex = impacted_materials_request.findIndex((x: any) => x.number === material.number);
        if (getMaterialIndex === -1) {
            impacted_materials_request.push(material);
        }
    }
    
    const selected_modalities = Object.keys(modalities).reduce((acc: Array<string>, modality: string) => {
        if (modalities[modality]) {
            acc.push(modality === 'smallMolecule' ? 'SM' : modality === 'biologic' ? 'B' : 'V');
        }
        return acc;
    }, []);

    return {
        impacted_material_numbers: impacted_materials_request,
        ds_dp: DS_DP,
        modality_list: selected_modalities,
    };
}

export function mapGetMaterialTypeWithCountRequest(
    materials: Array<Material>,
    DS_DP: Array<any>,
    modalities: ProductModalitySelections,
) {
    const impacted_materials_request = materials.map((material: Material) => {
        return material.number;
    });

    const selected_modalities = Object.keys(modalities).reduce((acc: Array<string>, modality: string) => {
        if (modalities[modality]) {
            acc.push(modality === 'smallMolecule' ? 'SM' : modality === 'biologic' ? 'B' : 'V');
        }
        return acc;
    }, []);

    return {
        impacted_material_numbers: impacted_materials_request,
        ds_dp: DS_DP,
        modality_list: selected_modalities,
    };
}

/*export function mapGetContainerClosureMaterialTypeWithCountRequest(
    materials: Array<ContainerClosureMaterial>,
    DS_DP: Array<string>,
    modalities: ProductModalitySelections,
) {
    const selected_modalities = Object.keys(modalities).reduce((acc: Array<string>, modality: string) => {
        if (modalities[modality]) {
            acc.push(modality === 'smallMolecule' ? 'SM' : modality === 'biologic' ? 'B' : 'V');
        }
        return acc;
    }, []);

    const finalObj =  {
        impacted_material_numbers: materials.map((mat: ContainerClosureMaterial) => mat.number),
        ds_dp: DS_DP,
        modality_list: selected_modalities,
    };

    return finalObj;
}*/

/*export function mapGetMaterialTypeWithCountFromResponse(response: Array<any>) {
    // add DS/DP values to request payload
    return response;
}*/

/*export function mapCpwsforEdit(response: any): CPWS {
    const input = response;

    const returnCPWS: any = {
        status: input['status'], // update with live api/data
        locked_by_user: input['locked_by_user'],
        cpwsId: input['cpwsID'],
        title: input['title'],
    };

    return returnCPWS;
}*/

/*export function mapSaveMaterialTypeWithCount(request: CPWS) {
    let materials: Array<{ mtl_number: string; types: Array<string> }> = [];
    const downstreamMaterials: Array<{ mtl_number: string; types: Array<string> }> = [];

    request.impactedMaterials.forEach((material: Material) => {
        const extractTypes: Array<string> = [];
        material.type.forEach((type: MaterialType) => {
            extractTypes.push(type.code);
        });

        material.downstreamMaterials.forEach((downstream: ContainerClosureMaterial) => {
            const extractDownstreamTypes: Array<string> = [];
            downstream.type.forEach((type: MaterialType) => {
                extractDownstreamTypes.push(type.code);
            });
            const downstreamObj = {
                mtl_number: downstream.number,
                types: extractDownstreamTypes,
            };
            downstreamMaterials.push(downstreamObj);
        });

        const obj = {
            mtl_number: material.number,
            types: extractTypes,
        };
        materials.push(obj);
    });

    materials = materials.concat(downstreamMaterials);

    return materials;
}*/

export function mapRegulatoryQuestionsToList(regulatoryQuestions: RegulatoryQuestions): Array<RegulatoryQuestions> {
    return Object.keys(regulatoryQuestions).map((question: string) => ({
        [question]: regulatoryQuestions[question],
    }));
}

export function mapRequestSubmissionWorkflow(
    cpws: CPWS,
    modalityList: ProductModalitySelections,
    markets: any,
    cht_codes: any
) {

    const submissionWorkflow = {
        title: cpws.title,
        modality_list: modalityList ? mapProductModalitySelectionToList(modalityList) : [],
        markets: markets,
        cht_codes: cht_codes
    }

    return submissionWorkflow;
}

export function mapSaveCpwsAssessment({
    cpws,
    regulatoryQuestions,
    ds_dp,
    sterile_non_sterile,
    justification,
    modalityList,
    user_nri_ri,
    system_nri_ri,
    biologic_nri_ri,
    vaccine_nri_ri,
    smallmolecule_nri_ri,
    scope_change_question,
    screen4_update,
    prat,
    sterileAnswered,
    submission_req_details,
    productsSitesImpacted,
    classificationCodes,
    materialCodeMapping,
    userSelectedMaterials,
    directionalDetails,
    answers,
    matchCriteria,
    assessments
}: {
    cpws: CPWS;
    regulatoryQuestions: RegulatoryQuestions;
    ds_dp?: Array<string>;
    sterile_non_sterile?: Array<string>;
    justification?: string;
    modalityList?: ProductModalitySelections;
    user_nri_ri: string;
    system_nri_ri: boolean;
    biologic_nri_ri: string;
    vaccine_nri_ri: string;
    smallmolecule_nri_ri: string;
    scope_change_question: string;
    screen4_update: boolean;
    prat: any;
    sterileAnswered: any;
    submission_req_details: any;
    productsSitesImpacted: any;
    classificationCodes: any;
    materialCodeMapping: any;
    userSelectedMaterials: any;
    directionalDetails: string;
    answers: MatchCriteriaAnswer;
    matchCriteria: MatchCriteria;
    assessments: []
}) {
    const newProductArr: Array<string> = [];
    const cpwsSiteArr: Array<string> = [];
    const impactedMaterialNumbers: Array<string> = [];
    let qaQuestionToString = '';
    const flattenedMatchCriteria = matchCriteria.reduce(
        (flattenedList: Array<Question | ChildQuestion>, question: Question) => {
            return flattenedList.concat([{ ...question, children: [] }, ...question.children]);
        },
        [],
    );

    cpws.productModality.selected.forEach((product: ProductModality) => {
        newProductArr.push(product.familyName);
    });
    cpws.impactedSites.selected.forEach((site: Site) => {
        if (!site.isPDS) {
            cpwsSiteArr.push(site.name);
        }
    });
    cpws.impactedMaterials.forEach((material: Material) => {
        impactedMaterialNumbers.push(material.number);
    });
    if (cpws.qaQuestion === true) {
        qaQuestionToString = 'Yes';
    } else {
        qaQuestionToString = 'No';
    }

    const material_with_types = [];

    for (const mat of userSelectedMaterials) {
        const imt = [];

        for (const mt of mat.type) {
            imt.push(mt.code);
        }

        material_with_types.push({
            type: mat.typeCode,
            number: mat.number,
            imt: imt
        })
    }

    //console.log('material_with_types: ', material_with_types)

    const pdsImpactedSites = cpws.impactedSites.selected.reduce(
        (pdsSites: Array<{ sit_name: string; sit_functions: Array<string> }>, site: Site) => {
            if (site.isPDS) {
                pdsSites.push({
                    sit_name: site.name,
                    sit_functions: site.siteFunctions,
                });
            }
            return pdsSites;
        },
        [],
    );

    const cpwsAssessment = {
        cpwsID: cpws.cpwsId,
        cpws_title: cpws.title,
        quality_impact: qaQuestionToString,
        mtl_list: impactedMaterialNumbers,
        ds_dp_list: ds_dp,
        sterile_non_sterile: sterile_non_sterile,
        cpws_fam_list: newProductArr,
        cpws_sit_list: cpwsSiteArr,
        pds_impacted_sites: pdsImpactedSites,
        materials_with_material_types: material_with_types,
        cpws_classifications: cpws.impactedMaterials.map((material: Material) => {
            const newMaterialTypeArr: Array<string> = [];
            material.type.forEach((obj: MaterialType) => {
                newMaterialTypeArr.push(obj.code);
            });
            return {
                impacted_material_type: newMaterialTypeArr,
                ...material.classification,
            };
        }),
        regulatoryQuestions: mapRegulatoryQuestionsToList(regulatoryQuestions),
        modality_list: modalityList ? mapProductModalitySelectionToList(modalityList) : [],
        user_nri_ri: user_nri_ri,
        system_nri_ri: system_nri_ri,
        biologic_nri_ri: biologic_nri_ri,
        vaccine_nri_ri: vaccine_nri_ri,
        smallmolecule_nri_ri: smallmolecule_nri_ri,
        scope_change_question: scope_change_question,
        screen4_update: screen4_update,
        justification: '',
        prat: prat,
        submission_req: submission_req_details,
        //mtc_code: [],
        mtc_code: Object.keys(answers).reduce((mtcArrays: Array<Array<string | boolean>>, key: string) => {
            const regex = /(T\d+)_(MC\d+)/m;
            const match = regex.exec(key);
            console.log("Match:", match);
            if (match && answers[key].isAnswered && answers[key].answer) {
                const classifications = findAllClassifications(flattenedMatchCriteria, match[1], match[2]);
                console.log("Classification:", classifications);
                classifications.forEach((classification: string) => {
                    const answerArray = [
                        match[1],
                        (find(classificationCodes, ['definition', classification]) as CPWSClassificationCode).code,
                        match[2],
                        answers[key].isContainerClosure ? 'Y' : 'N',
                    ];
                    if (!mtcArrays.some((mtcArray: Array<string | boolean>) => isEqual(mtcArray, answerArray))) {
                        mtcArrays.push(answerArray);
                    }
                });
            }
            return mtcArrays;
        }, []),
        userAssessment: user_nri_ri,
        directionalDetails: directionalDetails,
        assessments: assessments
    };

    if (someRegulatoryQuestionAnswersAreYes(regulatoryQuestions)) {
        cpwsAssessment.justification = (justification as any);
        cpwsAssessment.ds_dp_list = [];
        cpwsAssessment.sterile_non_sterile = [];
    } else {
        cpwsAssessment.justification = '';
        cpwsAssessment.ds_dp_list = ds_dp;
        cpwsAssessment.sterile_non_sterile = sterile_non_sterile;
    }
    return cpwsAssessment;
}

function getAddedProductsAndSites(
    productColorTags: ColorTag,
    siteColorTags: ColorTag,
    products: Array<ProductModality>,
    sites: Array<Site>,
) {
    const manualProducts = products.reduce((manual: Array<ProductModality>, product: ProductModality) => {
        if (!find(productColorTags.initiallySelected as Array<ProductModality>, ['mkNumber', product.mkNumber])) {
            manual.push(product);
        }
        return manual;
    }, []);

    const manualSites = sites.reduce((manual: Array<Site>, site: Site) => {
        if (!find(siteColorTags.initiallySelected as Array<Site>, ['name', site.name])) {
            manual.push(site);
        }
        return manual;
    }, []);

    return {
        numProductsAdded: manualProducts.length,
        numSitesAdded: manualSites.length,
    };
}

export function mapUpdateSession(
    status: any,
    isid: any,
    first_name:string,
    last_name:string,
    prevStep: any,
    curStep: any,
    cpwsID: any,
    cpws: CPWS,
    basicInfo: BasicInfo,
    supportingDetails: Array<SupportingDetails>,
    additionalInfoData: AdditionalInfoData,
    relatedChanges: Array<RelatedChanges>,
    endorsements: Endorsements,
    productInformation: ProductInformation,
    suppliers: Array<Suppliers>,
    materials: Array<Materials>,
    methods: Array<Methods>,
    partners: Array<Partners>,
    plants: Array<Plants>,
    registeredMarkets: Array<RegisteredMarkets>,
    impactAnalysis: Array<ImpactAnalysis>,
    userSelectedMaterials: Array<SelectedMaterials>,
    ds_dp?: Array<any>,
    sterile_non_sterile?: Array<any>,
    productsAvailable?: Array<ProductModality>,
    impactedMaterialsAvailable?: Array<Material>,
    impactedMaterialsAvailableOption?: any,
    downstreamImpactedMaterialsAvailableOption?: any,
    tab_num?: string,
    visitedChangeClassification?: boolean,
    comment_details?: CommentDetails,
    staticProductInformation?: ProductInformation,
    sitesAvailable?: Array<Site>,
    productsSitesImpacted?: any,
    appCacheFlags?: AppCacheFlags,
    downstreamProducts?: Array<ProductModality>,
    downstreamSites?: Array<Site>,
    classificationList?: CPWSClassification,
    matchCriteria?: MatchCriteria,
    assessmentResults?: Array<AssessmentResult>,
    comment?: string,
    productTypeSelection?: string,
    impactedMaterialsExternal?: Array<ExternalMaterial>,
    sendExternalMaterials?: Array<any>,
    impactedMaterialType?: Array<any>,
    qaQuestionAnswered?: boolean,
    dpDsAnswered?: boolean,
    sterileAnswered?: boolean,
    impactedCountries?: Array<Country>,
    matchCriteriaYesRegulation?: Array<any>,
    complete?: string,
    unlockPDF?: boolean,
    regulatoryQuestions?: RegulatoryQuestions,
    justification?: string,
    downstreamMaterials?: Array<DownstreamMaterial>,
    productModalitySelections?: ProductModalitySelections,
    siteFunctions?: Array<SiteFunction>,
    materialClassificationUuid?: string,
    matchCriteriaAnswers?: MatchCriteriaAnswer,
    matchCriteriaKeyOrder?: Array<string>,
    productColorTags?: ColorTag,
    siteColorTags?: ColorTag,
    nriOption?: string,
    rationaleAssessment?: string,
    userAssessment?: string,
    userAssessmentRationale?: string,
    additionalNotes?: string,
    cakeSuggestions?: string,
    reviewer?: string,
    majorMarkets?: AssesmentMarkets,
    secondaryMarkets?: AssesmentMarkets,
    regImpact?: boolean,
    cake_status?: string,
    user_nri_ri?: string,
    system_nri_ri?: boolean,
    biologic_nri_ri?: any,
    vaccine_nri_ri?: any,
    smallmolecule_nri_ri?: any,
    scope_change_question?: any,
    screen4_update?: boolean,
    update_type?: string,
    user_comment?:string,
    prat?: object,
    submission_req_details?: any,
    currentPlanningAssessmentType?: string,
    currentDirectionalAssessmentReason?: string,
    directionalDetails?: string
): any {
    let convertStatus = false;
    if (status == 'proceed') {
        convertStatus = true;
    }

    let stringRegImpact = '';
    if (regImpact) {
        stringRegImpact = 'TRUE';
    } else {
        stringRegImpact = 'FALSE';
    }

    const matchCriteriaQAnsweredYes = matchCriteriaAnswers
        ? Object.keys(matchCriteriaAnswers).reduce((mtcCodes: Array<string>, key: string) => {
              const regex = /^T\d+_MC\d+/m;
              /* eslint-disable */
              if (
                  !mtcCodes.includes(key) &&
                  matchCriteriaAnswers[key].isAnswered &&
                  regex.exec(key) &&
                  typeof matchCriteriaAnswers[key].answer === 'boolean' &&
                  matchCriteriaAnswers[key].answer
              ) {
                  mtcCodes.push(key);
              }
              return mtcCodes;
          }, [])
        : [];

    const { numProductsAdded, numSitesAdded } =
        productColorTags &&
        siteColorTags &&
        typeof productColorTags.initiallySelected !== 'boolean' &&
        typeof siteColorTags.initiallySelected !== 'boolean'
            ? getAddedProductsAndSites(
                  productColorTags,
                  siteColorTags,
                  cpws.productModality.selected,
                  cpws.impactedSites.selected,
              )
            : { numProductsAdded: 'N/A', numSitesAdded: 'N/A' };

    // const prImpactedMaterials = cpws.impactedMaterials.map((mat: Material) => ({
    //     ...mat,
    //     classification: mapValues(mat.classification, (num: number) =>
    //         num % 1 === 0 ? num.toString() : num.toFixed(2),
    //     ),
    // }));

    return {
        status: convertStatus || '',
        isid: isid || '',
        first_name:first_name||'',
        last_name:last_name||'',
        cpws_session_id: cpws.cpws_session_id || '',
        file_method: cpws.file_method || '',
        prevStep: prevStep.toString() || '',
        curStep: curStep.toString() || '',
        cpwsID: cpws.cpwsId || '',
        title: cpws.title || '',
        change_identifier: cpws.changeIdentifier || '',
        'technical-information': cpws.technicalInformation || '',
        'additional-info-comments': cpws.additionalInfoComments || '',
        'reason-for-change': cpws.reasonForChange || '',
        'change-desc-current': cpws.changeDescCurrent || '',
        'change-desc-proposed': cpws.changeDescProposed || '',
        'product-modality': cpws.productModality || [],
        'impacted-sites': cpws.impactedSites || [],
        qaQuestion: typeof cpws.qaQuestion === 'string' ? '' : cpws.qaQuestion ? true : false,
        quality_assessment: cpws.qualityAssessment || [],
        linked_change_reference: cpws.linkedChangeReference || '',
        ds_dp: ds_dp || [],
        sterile_non_sterile: sterile_non_sterile || [],
        impactedMaterials: cpws
            ? cpws.impactedMaterials.map((mat: Material) => ({
                  ...mat,
                  classification: mapValues(mat.classification, (num: number) =>
                      num % 1 === 0 ? num : Number(num.toFixed(2)),
                  ),
              }))
            : [],
        impactedMaterialType: impactedMaterialType || [],
        productsAvailable: productsAvailable || [],
        impactedMaterialsAvailable: impactedMaterialsAvailable || [],
        impactedMaterialsAvailableOption: impactedMaterialsAvailableOption || null,
        downstreamImpactedMaterialsAvailableOption: downstreamImpactedMaterialsAvailableOption || null,
        tab_num: tab_num || "",
        visitedChangeClassification: visitedChangeClassification || false,
        comment_details: comment_details || {screen1: "", screen2: "", screen3: "", screen4: "", screen5: ""},
        sitesAvailable: sitesAvailable || [],
        productsSitesImpacted: productsSitesImpacted || [],
        appCacheFlags: appCacheFlags || {},
        downstreamProducts: downstreamProducts || [],
        downstreamSites: downstreamSites || [],
        classificationList: classificationList
            ? mapValues(classificationList, (num: number) => (num % 1 === 0 ? num : Number(num.toFixed(2))))
            : {},
        matchCriteria: matchCriteria || {},
        assessmentResults: assessmentResults || [],
        numberOfQuestionsAnswered: matchCriteriaKeyOrder ? matchCriteriaKeyOrder.length : 0,
        numberOfQuestionsAnsweredYes: matchCriteriaQAnsweredYes.length || 0,
        matchCriteriaQuestionsAnsweredYes: matchCriteriaQAnsweredYes || [],
        comment: comment || '',
        productTypeSelection: productTypeSelection || '',
        impactedMaterialsExternal: impactedMaterialsExternal || [],
        sendExternalMaterials: sendExternalMaterials || [],
        qaQuestionAnswered: qaQuestionAnswered || false,
        dpDsAnswered: dpDsAnswered || false,
        sterileAnswered: sterileAnswered || false,
        countries: impactedCountries || [],
        matchCriteriaYesRegulation: matchCriteriaYesRegulation || [],
        complete: complete?.toLocaleUpperCase() || '',
        unlockPDF: unlockPDF || false,
        regulatoryQuestions: regulatoryQuestions ? mapRegulatoryQuestionsToList(regulatoryQuestions) : {},
        justification: justification || '',
        downstreamMaterials: downstreamMaterials || [],
        productModalitySelections: productModalitySelections || {},
        siteFunctions: siteFunctions || [],
        materialClassificationUuid: materialClassificationUuid || '',
        matchCriteriaAnswers: matchCriteriaAnswers || {},
        matchCriteriaKeyOrder: matchCriteriaKeyOrder || [],
        Reg_Impact: stringRegImpact,
        productColorTags: productColorTags || {},
        siteColorTags: siteColorTags || {},
        numAutoSelectedProductsRemoved:
            productColorTags && typeof productColorTags.initialRemoved !== 'boolean'
                ? productColorTags.initialRemoved.length
                : 'N/A',
        numAutoSelectedSitesRemoved:
            siteColorTags && typeof siteColorTags.initialRemoved !== 'boolean'
                ? siteColorTags.initialRemoved.length
                : 'N/A',
        numProductsAdded: numProductsAdded,
        numSitesAdded: numSitesAdded,
        parentChildMatchCriteria: matchCriteria || {},
        attachments: cpws.attachments,
        prClassificationList: classificationList
            ? mapValues(classificationList, (num: number) => (num % 1 === 0 ? num.toString() : num.toFixed(2)))
            : {},
        prImpactedMaterials: userSelectedMaterials || [],
        nriOption: nriOption,
        rationaleAssessment: rationaleAssessment,
        userAssessment: userAssessment || '',
        userAssessmentRationale: userAssessmentRationale || '',
        additionalNotes: additionalNotes || '',
        cakeSuggestions: cakeSuggestions || '',
        concurText: '',
        concurQuestion: '',
        reviewer: reviewer || '',
        majorMarkets: majorMarkets,
        secondaryMarkets: secondaryMarkets,
        basicInfo: cpws.basicInfo || {},
        supportingDetails: cpws.supportingDetails || [],
        additionalInfoData: cpws.additionalInfoData || {},
        relatedChanges: cpws.relatedChanges || [],
        endorsements: cpws.endorsements || {},
        productInformation: cpws.productInformation || {},
        staticProductInformation: staticProductInformation || {},
        suppliers: cpws.suppliers || [],
        materials: cpws.materials || [],
        methods: cpws.methods || [],
        partners: cpws.partners || [],
        plants: cpws.plants || [],
        registeredMarkets: cpws.registeredMarkets || [],
        impactAnalysis: cpws.impactAnalysis || [],
        cake_status: cake_status,
        user_nri_ri: user_nri_ri || '',
        system_nri_ri: system_nri_ri || false,
        biologic_nri_ri: biologic_nri_ri || '',
        vaccine_nri_ri: vaccine_nri_ri || '',
        smallmolecule_nri_ri: smallmolecule_nri_ri || '',
        scope_change_question: scope_change_question || '',
        screen4_update: screen4_update || false,
        update_type: update_type? update_type : "default",
        user_comment:user_comment?user_comment:"This is my comment",
       // prImpactedMaterials: userSelectedMaterials || [],
        prat: prat || {},
        submission_req_details: submission_req_details || [],
        currentPlanningAssessmentType: currentPlanningAssessmentType || '',
        currentDirectionalAssessmentReason: currentDirectionalAssessmentReason || '',
        directionalDetails: directionalDetails || ''
    };
}

export function mapMaterialTypes(response: any) {
    try {
        response.forEach((material: any) => {
            const materialTypeDict: any = {};
            materialTypeDict['T0'] = { code: 'T0', name: '' };

            material.mtl_types_count.forEach((materialType: any) => {
                for (const key in materialType) {
                    materialTypeDict[key] = { code: key, name: materialType[key] };
                }
            });

            const materialTypes = [materialTypeDict];
            material.mtl_types_count = materialTypes;
            return material;
        });

        return response;
    } catch(e) {
        console.log("Error on mapMaterialTypes Function : ", e);
        assignAsyncErrorToSession(e.stack);
        throw new Error(e);
    }
}

/*export function mapMaterialTypesforDownstream(response: Array<ApiMaterialTypeWithCount>) {
    let mappedResponse = {};
    if (response.length > 0) {
        mappedResponse = response.map((material: ApiMaterialTypeWithCount) => ({
            number: material.mtl_number,
            types: material.mtl_types_count.map((obj: { [key: string]: string }) => ({
                code: Object.keys(obj)[0],
                name: Object.values(obj)[0],
            })),
        }));
    }
    return mappedResponse;
}*/

/*export function mapGetContainerClosure(materialNumber: string, products: Array<ProductModality>) {
    return {
        mtl_number: materialNumber,
        fam_list: products.map((product: ProductModality) => product.familyName),
    };
}*/

/*export function mapGetContainerClosureResponse(
    response: Array<ApiGetContainerClosureMaterial>,
): Array<ContainerClosureMaterial> {
    return response.map((downstreamMaterial: ApiGetContainerClosureMaterial) => ({
        name: downstreamMaterial.mtl_name,
        number: downstreamMaterial.mtl_number,
        stg_description: 'downstream',
        type: [],
        availableTypes: [],
        manually_entered: false
    }));
}*/
