import React from 'react';
import { Table, Select, Button, Typography, Row, Col, Popconfirm, Form, Radio } from 'antd';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';
import { findObjectByValue } from '../utils';
import { Material, MaterialType, ContainerClosureMaterial, ExternalMaterial, ImpactedMaterialsRef, Materials, SelectedMaterials, LegacyContainerClosureMaterial } from '../model';
import { ExternalMaterialsDropdown } from './ExternalMaterialsDropdown';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { render } from '@testing-library/react';
import { stringify } from 'querystring';

const { Text } = Typography;
const { Option } = Select;

interface ContainerClosureProps {
    material: SelectedMaterials;
    addMaterialContainerClosure?: Function;
    removeMaterialContainerClosure?: Function;
    containerClosureApplyAll?: Function;
    impactedMaterials: Array<Materials>;
    currentScreen: number;
    containerClosureLoading: { [key: string]: boolean };
    deleteExternalMaterial?: Function;
    editExternalMaterial?: Function;
    addDownstreamMaterial?: Function;
    getDownstreamMaterials?: Function;
    getImpactedProducts: Function;
    getImpactedMaterials: Function;
    materials: Array<SelectedMaterials>;
    externalMaterials?: Array<ExternalMaterial>;
    removeExternalMaterialFromContainerClosure?: Function;
    pageNumber: number;
    onContainerClosurePageChage: Function;
    impactedMaterialsRef?: ImpactedMaterialsRef;
    printPDF?: boolean;
    updateDownstreamImpactedMaterialsOption: Function;
    downstreamImpactedMaterialsAvailableOption: any;
    removeManuallyEnteredMaterials: Function;
}

export const ContainerClosure: React.FC<ContainerClosureProps> = (props: ContainerClosureProps) => {
    const {
        material,
        addMaterialContainerClosure,
        removeMaterialContainerClosure,
        containerClosureApplyAll,
        impactedMaterials,
        currentScreen,
        containerClosureLoading,
        addDownstreamMaterial,
        getDownstreamMaterials,
        getImpactedProducts,
        getImpactedMaterials,
        materials,
        externalMaterials,
        removeExternalMaterialFromContainerClosure,
        pageNumber,
        onContainerClosurePageChage,
        impactedMaterialsRef,
        printPDF,
        updateDownstreamImpactedMaterialsOption,
        downstreamImpactedMaterialsAvailableOption,
        removeManuallyEnteredMaterials
    } = props;

    const [externalMaterialInput, setExternalMaterialInput] = React.useState<string>('');
    const [externalMaterialDropdownOpen, setExternalMaterialDropdownOpen] = React.useState<boolean>(false);
    const [productAndMaterialDropdownOpen, setProductAndMaterialDropdownOpen] = React.useState<boolean>(false);
    const [productAndMaterialInput, setProductAndMaterialInput] = React.useState<string>('');
    const [selectedTypes, setSelectedTypes] = React.useState<object>({});
    const [externalMaterialsMissing, setExternalMaterialsMissing] = React.useState<boolean>(false);
    const [addedMaterial, setAddedMaterial] = React.useState<boolean>(false);
    const [radioValue, setRadioValue] = React.useState<string>('');

    const downstreamMaterialRefs = React.useRef<Array<{ number: string; ref: React.RefObject<HTMLDivElement> }>>([]);

    const externalMaterialSelected = (materialName: string) => {
        setExternalMaterialInput(materialName);
        if (materialName !== '' && externalMaterialDropdownOpen === true) {
            setExternalMaterialDropdownOpen(false);
        }
    };

    const deleteExternalMaterial = (
        externalMaterial: ExternalMaterial,
        e: React.MouseEvent<HTMLElement, MouseEvent>,
    ) => {
        e.stopPropagation();
        if (
            props.deleteExternalMaterial &&
            (externalMaterial.number !== null || externalMaterial.number !== undefined)
        ) {
            props.deleteExternalMaterial(externalMaterial.number);
        }
        setExternalMaterialInput('');
    };

    const editExternalMaterial = (matNum: string, newName: string) => {
        setExternalMaterialInput('');
        if (props.editExternalMaterial) {
            props.editExternalMaterial(matNum, newName);
        }
    };

    const addExternalMaterial = async (externalMaterials: Array<ExternalMaterial>, externalMaterialInput: string) => {
        if (externalMaterialInput !== '' && externalMaterialInput !== undefined) {
            if (material.downstreamMaterials && material.downstreamMaterials.length === 0 && getDownstreamMaterials) {
                material.downstreamMaterials = getDownstreamMaterials(material);
            }
            if (addDownstreamMaterial) {
                const externalMaterial = findObjectByValue(externalMaterials, 'name', externalMaterialInput);
                if (externalMaterial) {
                    const updatedMaterial = await addDownstreamMaterial(externalMaterial, material, 'external');
                    material.downstreamMaterials = updatedMaterial.downstreamMaterials;
                } else {
                    const updatedMaterial = await addDownstreamMaterial({ name: externalMaterialInput, number: '' }, material, 'external');
                    material.downstreamMaterials = updatedMaterial.downstreamMaterials;
                }
            }
        }
        setExternalMaterialInput('');
        setAddedMaterial(true);
    };

    const addProductOrMaterial = async (productAndMaterialInput: string) => {
        if (productAndMaterialInput !== undefined && productAndMaterialInput !== '') {
            if (material.downstreamMaterials && material.downstreamMaterials.length === 0 && getDownstreamMaterials) {
               material.downstreamMaterials = getDownstreamMaterials(material);
            }
            /* Get selected product/material from dropdown list */
            const allMaterials = getAllMaterials();
            const selectedMaterialIndex = allMaterials.findIndex((x: any) => (productAndMaterialInput.includes(x.number) || productAndMaterialInput.includes(x['product_number']) || productAndMaterialInput.includes(x['material_number'])));
            if (selectedMaterialIndex > -1 && addDownstreamMaterial) {
                const updatedMaterial = await addDownstreamMaterial({ name: productAndMaterialInput, number: '' }, material, 'internal');
                material.downstreamMaterials = updatedMaterial.downstreamMaterials;
            }
        }
        setProductAndMaterialInput('');
    }

    const renderExternalMaterialsList = (externalMaterials: Array<ExternalMaterial>): Array<JSX.Element> => {
        const updatedExternalMaterials = externalMaterials;
        const findIndex = externalMaterials.findIndex((x: any) => x.name === material.name);

        if (findIndex > -1) {
            updatedExternalMaterials.splice(findIndex, 1);
        }

        return updatedExternalMaterials.map((externalMaterial: ExternalMaterial) => {
            return (
                <Option value={externalMaterial.name} key={externalMaterial.number} label={externalMaterial.name}>
                    <ExternalMaterialsDropdown
                        material={externalMaterial}
                        externalMaterialSelected={externalMaterialSelected}
                        deleteItem={deleteExternalMaterial}
                        setDropdown={() => setExternalMaterialDropdownOpen(true)}
                        editExternalMaterial={editExternalMaterial}
                        externalMaterials={externalMaterials}
                    />
                </Option>
            );
        });
    };

    const renderMaterialsList = (materials: Array<SelectedMaterials>) => {
        const materialsList = materials.map((material: Material) => {
            let name;
            let number;

            if (material['product_name'] && material['product_name']) {
                name = material['product_name'];
                number = material['product_number'];
            } else if (material['material_name'] && material['material_number']) {
                name = material['material_name'];
                number = material['material_number'];
            } else {
                name = material['name'];
                number = material['number'];
            }

            if (name !== props.material.name && number !== props.material.number) {
                return (
                    <Option value={`${name} \xa0\xa0\xa0\xa0 ${number}`} key={number} label={`${name} \xa0\xa0\xa0\xa0 ${number}`}>
                    </Option>
                );
            }
        });

        return materialsList;
    };

    const addManual = (event: any) => {
        for (let i = 0; i < event.length; ++i) {
            if (event[i].value) {
                const material = event[i].value;
                setExternalMaterialInput(externalMaterialInput.concat(material));
            } else {
                setExternalMaterialInput(event);
            }
        }
    };

    const addProductAndMaterialEvent = (event: any) => {
        if (event === '') {
            setProductAndMaterialInput('');
        } else {
            setProductAndMaterialInput(event);
        }
    }

    const manuallyAddDownstreamMaterials = () => {
        return (
            <FormContext.Consumer>
                {({ viewOnly }) => (
                    <div className={viewOnly ? ' view-only manualMaterials' : 'manualMaterials'}>
                        <div style={{ alignSelf: 'center' }}>Manually enter Downstream Materials: </div>
                        <Select
                            style={{ width: '50%', padding: '10px' }}
                            onSelect={value => addManual(value)}
                            onChange={value => addManual(value)}
                            onSearch={value => addManual(value)}
                            filterOption={(input, option) => {
                                if (option) {
                                    return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                } else {
                                    return false;
                                }
                            }}
                            showSearch
                            showArrow={false}
                            value={externalMaterialInput}
                            optionLabelProp="label"
                            open={externalMaterialDropdownOpen}
                            onClick={() => {
                                if (!externalMaterialDropdownOpen) {
                                    setExternalMaterialDropdownOpen(true);
                                }
                            }}
                            onBlur={() => {
                                setExternalMaterialDropdownOpen(false);
                            }}
                        >
                            {externalMaterials ? renderExternalMaterialsList(externalMaterials) : []}
                        </Select>

                        <Button
                            onClick={() => {
                                if (externalMaterials) addExternalMaterial(externalMaterials, externalMaterialInput);
                            }}
                        >
                            Add
                        </Button>
                    </div>
                )}
            </FormContext.Consumer>
        );
    };

    const selectedDownstreamMaterialType = (__: any, option: any) => {
        //console.log("In add material type function: ", option)
        const regex = /^(.+)_(.+)_/m;
        const match = regex.exec(option.key);
        //console.log("key: ", option.key)
        if (match && addMaterialContainerClosure) {
            const materialNumber = match[1];
            const downstreamMaterialNumber: any = match[2];
            //console.log('downstreamMaterialNumber: ', downstreamMaterialNumber)
            const addMaterialInfo = addMaterialContainerClosure(materialNumber, downstreamMaterialNumber, option.value);
            const updatedDownstreamMaterials = addMaterialInfo.downstreamMaterials;
            const updatedTypes = addMaterialInfo.type;

            material.downstreamMaterials = updatedDownstreamMaterials;

            const types: any = selectedTypes;
            types[downstreamMaterialNumber] = updatedTypes;
            setSelectedTypes(types);
        }
    };

    const removedDownstreamMaterialType = (
        typeValue: string,
        record: ContainerClosureMaterial,
        parentMaterial: SelectedMaterials,
    ) => {
        const downstreamIndex = material.downstreamMaterials.findIndex(x => x.number === record.number)
        const downstreamMaterial = material.downstreamMaterials[downstreamIndex];
        const typeIndex = downstreamMaterial.type.findIndex(x => x.name === typeValue);
        const type = downstreamMaterial.type[typeIndex];

        if (removeMaterialContainerClosure) {
            const removeMaterialInfo = removeMaterialContainerClosure(parentMaterial, record, type);
            const updatedTypes = removeMaterialInfo.type;

            const updatedDownstreamMaterials = removeMaterialInfo.downstreamMaterials;
            material.downstreamMaterials = updatedDownstreamMaterials;

            const types: any = selectedTypes;
            types[record.number] = updatedTypes;
            setSelectedTypes(types);
        }
    };

    // const getDownstreamMaterialSelections = (record: ContainerClosureMaterial) => {
    //     const matIndex = findIndex(impactedMaterials, ['number', material.number]);
    //     if (matIndex > -1) {
    //         const index = findIndex(impactedMaterials[matIndex].downstreamMaterials, ['number', record.number]);

    //         if (index > -1) {
    //             const regex = / \(\d+\)$/m;
    //             return impactedMaterials[matIndex].downstreamMaterials[index].type.map((type: MaterialType) =>
    //                 JSON.stringify({
    //                     code: type.code,
    //                     name: type.name.replace(regex, ''),
    //                 }),
    //             );
    //         } else {
    //             return [];
    //         }
    //     } else {
    //         return [];
    //     }
    // };

    const getAllMaterials = () => {
        const impactedProducts = getImpactedProducts();
        const impactedAndExternalMaterials = getImpactedMaterials();
        const updatedMaterials: any = impactedProducts.concat(impactedAndExternalMaterials);

        /* Add external material */
        for (const material of materials) {
            const getMaterial: any = material;
            if (getMaterial['name'] && getMaterial['name'] !== '') {
                const updatedMaterialsIndex = updatedMaterials.findIndex((x: any) => (x['number'] === getMaterial['number'] || x['product_number'] === getMaterial['number'] || x['material_number'] === getMaterial['number']));
                if (updatedMaterialsIndex === -1) {
                    updatedMaterials.push(getMaterial);
                }
            }
        }

        return updatedMaterials;
    }

    const renderProductsandMaterialsSelection = () => {
        /* Remove selected material from dropdown list */
        const allMaterials = getAllMaterials();
        const currentMaterial = props.material.name;

        const selectedMaterialIndex = allMaterials.findIndex((x: any) => (x['name'] === currentMaterial || x['product_name'] === currentMaterial || x['material_name'] === currentMaterial));

        if (selectedMaterialIndex !== -1) {
            allMaterials.splice(selectedMaterialIndex, 1);
        }

        return (
            <FormContext.Consumer>
                {({ viewOnly }) => (
                    <div className={viewOnly ? ' view-only productAndMaterials' : 'productAndMaterials'}>
                        <div style={{ alignSelf: 'center' }}>Select Product and/or Material: </div>
                        <Select
                            style={{ width: '50%', padding: '10px', color: 'black' }}
                            onSelect={value => addProductAndMaterialEvent(value)}
                            filterOption={(input, option) => {
                                if (option) {
                                    return option.value.toLowerCase().includes(input.toLowerCase());
                                } else {
                                    return false;
                                }
                            }}
                            showSearch
                            showArrow={false}
                            value={productAndMaterialInput}
                            optionLabelProp="label"
                            open={productAndMaterialDropdownOpen}
                            onClick={() => {
                                if (!productAndMaterialDropdownOpen) {
                                    setProductAndMaterialDropdownOpen(true);
                                }
                            }}
                            onBlur={() => {
                                setProductAndMaterialDropdownOpen(false);
                            }}
                        >
                            {allMaterials ? renderMaterialsList(allMaterials) : []}
                        </Select>
                        <Button
                            onClick={() => {
                                addProductOrMaterial(productAndMaterialInput);
                            }}
                        >
                            Add
                        </Button>
                    </div>
                )}
            </FormContext.Consumer>
        );
    };

    const getDownstreamMaterialTypeCount = (record: ContainerClosureMaterial) => {
        const materialIndex = material.downstreamMaterials.findIndex((x: any) => x.number === record.number);
        const donwstreamMat = material.downstreamMaterials[materialIndex];
        const count = donwstreamMat.type.length;
        return count;
    }

    const getTypesFromMaterial = (record: ContainerClosureMaterial) => {
        const downstreamMaterials = material.downstreamMaterials;
        const findIndex = downstreamMaterials.findIndex((x: any) => x.number === record.number);

        if (findIndex > -1) {
            const downstreamMat = downstreamMaterials[findIndex];
            if (downstreamMat.type.length > 0) {
                return {
                   [record.number]: downstreamMat.type
                }
            } else {
                return {};
            }
        } else {
            return {};
        }
    }

    const renderMaterialTypeSelection = (__: any, record: ContainerClosureMaterial) => {
        const recordNumber: any = record.number;
        let allTypes: any = selectedTypes;

        if (Object.keys(allTypes).length === 0) {
            allTypes = getTypesFromMaterial(record);
        }

        const types: any = allTypes[recordNumber];

        const typeNames: any = [];

        if (types) {
            for (const type of types) {
                typeNames.push(type.name);
            }
        }

        return (
            <FormContext.Consumer>
                {({ materialTypeSelected, downstreamMaterialType, viewOnly }) => (
                    <div className={viewOnly ? 'view-only' : ''}>
                        {downstreamMaterialType === false && record.type.length === 0 ? (
                            <FormValidationErrorText text="Please select a material type" />
                        ) : null}
                        <Select
                            value={typeNames}
                            mode="multiple"
                            style={{ width: '100%' }}
                            onSelect={selectedDownstreamMaterialType}
                            onDeselect={(typeValue: string) =>
                                removedDownstreamMaterialType(typeValue, record, material)
                            }
                            placeholder="Please select"
                            dropdownStyle={{ width: '375px' }}
                            dropdownMatchSelectWidth={false}
                            disabled={currentScreen === 7 ? true : false}
                            className={
                                !materialTypeSelected && getDownstreamMaterialTypeCount(record) === 0
                                    ? 'select-highlight'
                                    : ''
                            }
                        >
                            {record.availableTypes
                                .sort((a: MaterialType, b: MaterialType) => {
                                    if (a.name < b.name) {
                                        return -1;
                                    } else if (a.name > b.name) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                })
                                .reduce((options: Array<JSX.Element>, materialType: MaterialType) => {
                                    const materialTypeAny: any = materialType;
                                    for (const key in materialTypeAny) {
                                        const keyValue: any = key;
                                        const materialTypeInfo = materialTypeAny[keyValue];
                                        if (materialTypeInfo.code !== 'T14' && materialTypeInfo.name !== undefined && materialTypeInfo.name !== '') {
                                            const regex = / \(\d+\)$/m;
                                            options.push(
                                                <Option
                                                    value={JSON.stringify({
                                                        code: materialTypeInfo.code,
                                                        name: materialTypeInfo.name.replace(regex, ''),
                                                    })}
                                                    key={`${material.number}_${record.number}_${materialTypeInfo.code}`}
                                                >
                                                    {materialTypeInfo.name}
                                                </Option>,
                                            );
                                        }
                                    }
                                    return options;
                                }, [])}
                        </Select>
                    </div>
                )}
            </FormContext.Consumer>
        );
    };

    const getRef = (number: string) => {
        const obj = find(downstreamMaterialRefs.current, ['number', number]);
        if (obj) {
            return obj.ref;
        } else {
            return undefined;
        }
    };

    const renderContainerClosureMaterialName = (text: string, record: ContainerClosureMaterial) => (
        <span ref={getRef(record.number)}>{text}</span>
    );

    const renderDeleteColumn = (__: any, record: ContainerClosureMaterial) =>
        record.number.startsWith('ext-') ? (
            <FormContext.Consumer>
                {({ viewOnly }) => (
                    <Row align="middle" justify="center">
                        <Popconfirm
                            title={`Are you sure you want to remove ${record.name} from the container closure?`}
                            onConfirm={() => {
                                if (removeExternalMaterialFromContainerClosure) {
                                    const updatedMaterial = removeExternalMaterialFromContainerClosure(record.number, material);
                                    if (updatedMaterial !== undefined) {
                                        material.downstreamMaterials = updatedMaterial.downstreamMaterials;
                                    }
                                }
                            }}
                            disabled={viewOnly}
                        >
                            <DeleteOutlined className={viewOnly ? 'view-only' : ''} />
                        </Popconfirm>
                    </Row>
                )}
            </FormContext.Consumer>
        ) : null;

    const renderSelectAllColumnTitle = () => (
        <Row align="middle" gutter={16}>
            <Col span={14}>
                <Text>Select Material Type</Text>
            </Col>
            <Col span={10}>
                <Button
                    onClick={() => {
                        if (containerClosureApplyAll) {
                            const applyAllMaterialInfo = containerClosureApplyAll(material);
                            const downstreamMaterials = applyAllMaterialInfo.downstreamMaterials;

                            const updatedTypes = applyAllMaterialInfo.type;

                            const types: any = selectedTypes;
                            for (const downstreamMat of downstreamMaterials) {
                                downstreamMat.type = updatedTypes;
                                types[downstreamMat.number] = updatedTypes;
                                setSelectedTypes(types);
                            }

                            material.downstreamMaterials = downstreamMaterials;
                        }
                    }}
                >
                    Apply All
                </Button>
            </Col>
        </Row>
    );

    const containerClosureColumns =
        [
            {
                title: 'Container Closure Downstream Material',
                dataIndex: 'name',
                render: renderContainerClosureMaterialName,
            },
            {
                title: renderSelectAllColumnTitle,
                dataIndex: 'type',
                render: renderMaterialTypeSelection,
                width: '250px',
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                render: renderDeleteColumn
            }
        ]

    const containerClosureData = material.downstreamMaterials;

    const hasManuallyAddedMaterial = () => {
        const downstreamMaterials = material.downstreamMaterials;
        for (const downstreamMat of downstreamMaterials) {
            if (downstreamMat.manually_entered === true) {
                setExternalMaterialsMissing(true);
                setAddedMaterial(true);
            } 
        }
    }

    React.useEffect(() => {
        if (impactedMaterialsRef) {
            const index = findIndex(impactedMaterialsRef, ['number', material.number]);
            if (index > -1) {
                impactedMaterialsRef[index].downstreamRefs = downstreamMaterialRefs.current;
            }
        }

        if (getDownstreamMaterials) {
            material.downstreamMaterials = getDownstreamMaterials(material);

            hasManuallyAddedMaterial();
        }

        if (props.downstreamImpactedMaterialsAvailableOption !== null) {
            setRadioValue(props.downstreamImpactedMaterialsAvailableOption[material.number]);
        }

    });

    const updateExternalMaterials = (event: any) => {
        const value = event.target.value;
        if (value === 'Yes') {
            setExternalMaterialsMissing(true);
        } else {
            setExternalMaterialsMissing(false);

            /* Remove any manually entered materials from table */
            const updatedDownstreamMaterials = removeManuallyEnteredMaterials(material);
            material.downstreamMaterials = updatedDownstreamMaterials;
        }

        setRadioValue(value);
        updateDownstreamImpactedMaterialsOption(value, material.number);
    }

    return (
        <FormContext.Consumer>
            {({ materialTypeSelected, formExternalMaterialsMissing, downstreamMaterialTypeSelected, downstreamMaterialType, viewOnly }) => (
                <div>
                    {downstreamMaterialTypeSelected === false && material.downstreamMaterials && material.downstreamMaterials.length === 0
                    ? (
                        <FormValidationErrorText
                            style={{ marginLeft: '20px' }}
                            text="Please add material to container closure"
                        />
                    ) : null}
                    {renderProductsandMaterialsSelection()}
                    <div className={((props.downstreamImpactedMaterialsAvailableOption !== null && props.downstreamImpactedMaterialsAvailableOption[material.number] === undefined) || (props.downstreamImpactedMaterialsAvailableOption !== null && props.downstreamImpactedMaterialsAvailableOption[material.number] === 'Yes' && radioValue === 'Yes' && formExternalMaterialsMissing === false)) ? 'select-highlight' : ''}>
                        <Form labelCol={{ span: 12 }} wrapperCol={{ span: 24 }} >
                            <Form.Item style={{}} label="Are Impacted Materials(s) missing from the list? " name="radio" rules={[{ required: true, message: "Please select an option" }]}>
                                <Radio.Group defaultValue={props.downstreamImpactedMaterialsAvailableOption !== null && props.downstreamImpactedMaterialsAvailableOption[material.number]} onChange={updateExternalMaterials}>
                                    <Radio value={'Yes'} disabled={viewOnly}>Yes</Radio>
                                    <Radio value={'No'} disabled={viewOnly}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Form>
                    </div>
                    {(props.downstreamImpactedMaterialsAvailableOption === null && formExternalMaterialsMissing === false) || (props.downstreamImpactedMaterialsAvailableOption !== null && props.downstreamImpactedMaterialsAvailableOption[material.number] === undefined)
                    ? (
                        <FormValidationErrorText text="Please select Yes or No above" />
                    ) : null}
                    {(props.downstreamImpactedMaterialsAvailableOption !== null && props.downstreamImpactedMaterialsAvailableOption[material.number] === 'Yes' && radioValue === 'Yes' && formExternalMaterialsMissing === false)
                    ? (
                        <FormValidationErrorText text="Please Add External Materials below or Choose 'No' Option above" />
                    ) : null}
                    {props.downstreamImpactedMaterialsAvailableOption !== null && props.downstreamImpactedMaterialsAvailableOption[material.number] === 'Yes' && manuallyAddDownstreamMaterials()}
                    <Table
                        dataSource={containerClosureData}
                        columns={containerClosureColumns}
                        // pagination={
                        //     material.downstreamMaterials.length < 10 || printPDF
                        //         ? false
                        //         : {
                        //               position: ['bottomRight'],
                        //               showTitle: false,
                        //               size: 'small',
                        //               current: pageNumber,
                        //               onChange: (newPage: number) => {
                        //                   if (impactedMaterialsRef) {
                        //                       const ref = find(impactedMaterialsRef, ['number', material.material_number]);
                        //                       if (ref && ref.ref && ref.ref.current) {
                        //                           window.scrollTo(
                        //                               0,
                        //                               window.pageYOffset +
                        //                                   ref.ref.current.getBoundingClientRect().top -
                        //                                   150,
                        //                           );
                        //                       }
                        //                   }
                        //                   onContainerClosurePageChage(material.material_number, newPage);
                        //               },
                        //           }
                        // }
                        rowKey={(record: ContainerClosureMaterial) => `${material.number}_${record.number}`}
                        // bordered={material.downstreamMaterials.length === 0}
                        className="subtable-padding"
                    />
                </div>
            )}
        </FormContext.Consumer>
    );
};
