import { Select } from 'antd';
import React from 'react';
import { Site, SiteFunction } from '../model';
import { LabeledValue } from 'antd/lib/select';

const { Option } = Select;

export interface PDSSiteSelectProps {
    onSiteSelect?: Function;
    onSiteDeselect?: Function;
    impactedSites: Array<Site>;
    siteFunctions: Array<SiteFunction>;
}

export const PDSSiteSelect: React.FC<PDSSiteSelectProps> = (props: PDSSiteSelectProps) => {
    const { onSiteSelect, onSiteDeselect, impactedSites, siteFunctions } = props;

    const pdsFilterSiteSelections = (impactedSites: Array<Site>) => {
        return impactedSites.reduce((selectedSites: Array<LabeledValue>, site: Site) => {
            if (site.isPDS) {
                selectedSites.push({
                    key: site.name,
                    label: site.name,
                    value: site.name,
                });
            }
            return selectedSites;
        }, []);
    };

    const buildPDSSiteList = (sites: Array<SiteFunction>) => {
        return sites.map((site: SiteFunction) => (
            <Option value={site.name} key={site.name}>
                {site.name}
            </Option>
        ));
    };

    return (
        <Select
            mode="multiple"
            size={'middle'}
            placeholder="Please select"
            disabled={!onSiteSelect && !onSiteDeselect}
            labelInValue={true}
            onDeselect={val => {
                if (onSiteDeselect) {
                    onSiteDeselect(val);
                }
            }}
            onSelect={val => {
                if (onSiteSelect) {
                    onSiteSelect(val, 'pds');
                }
            }}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={300}
            value={pdsFilterSiteSelections(impactedSites)}
        >
            {buildPDSSiteList(siteFunctions)}
        </Select>
    );
};
