import React from 'react';
import { ChangeInformation } from '../components/ChangeInformation';

import { Country, CPWS, GlobalProps, SelectionOption, SiteFunction, ProductInformation } from '../model';
import { CPWSStep } from './screens';
import './screens.css';
import { Card, Collapse, Typography } from 'antd';
import { IpiIdTitle } from '../components/IpiIdTitle';
import { CountryList } from '../components/Countries';
import { SiteFunctions } from '../components';

const { Text } = Typography;
const { Panel } = Collapse;

export interface Screen3Props {
    cpws: CPWS;
    impactedCountries: Array<Country>;
    globalProps: GlobalProps;
    updateCountries: Function;
    resetPDS: Function;
    loadAdditionalInfo: Function;
    sessionUpdate: Function;
    onFunctionSelect: Function;
    onFunctionDeselect: Function;
    onSiteSelect: Function;
    onSiteDeselect: Function;
    siteFunctionsRef: Array<{
        name: string;
        ref: React.RefObject<HTMLDivElement>;
    }>;
    siteFunctionCardRef: React.RefObject<HTMLDivElement>;
    siteFunctions: Array<SiteFunction>;
    staticProductInformation: ProductInformation;
    loadSiteFunctions: Function;
}
export interface Screen3State {
    loadingAdditional: boolean;
}

type selectionType = 'siteSelect' | 'siteFunctionSelect' | 'siteDeselect' | 'siteFunctionDeselect';
type siteType = 'cpws' | 'pds';

export class Screen3 extends React.Component<Screen3Props, Screen3State> {
    constructor(props: Screen3Props) {
        super(props);
        this.state = {
            loadingAdditional: true,
        };
        //this.onSelect = this.onSelect.bind(this);
    }
    async componentDidMount(): Promise<void> {
        await this.props.loadSiteFunctions(this.props.cpws.productInformation.products);
        window.scrollTo(0, 0);
        this.props.sessionUpdate();
        this.props.loadAdditionalInfo().then(() => {
            this.setState({ loadingAdditional: false });
        });
    }

    async onSelect(option: SelectionOption, selectionType: selectionType, siteType?: siteType) {
        const callback = async () => {
            this.setState({
                loadingAdditional: true,
            });

            const responseStatus = await this.props.loadAdditionalInfo();
            if (responseStatus !== 0) {
                this.setState({
                    loadingAdditional: false,
                });
            }
        };

        switch (selectionType) {
            case 'siteSelect':
                this.props.onSiteSelect({ site: option, siteType, callback });
                break;
            case 'siteDeselect':
                this.props.onSiteDeselect({ site: option, callback });
                break;
            case 'siteFunctionSelect':
                this.props.onFunctionSelect(option, callback);
                break;
            case 'siteFunctionDeselect':
                this.props.onFunctionDeselect(option, callback);
        }
    }

    render(): JSX.Element {
        const { cpws, staticProductInformation } = this.props;
        const realTitle = cpws.title ? cpws.title : 'No data was loaded. ';
        return (
            <CPWSStep>
                <div  style={{border: '1px solid #d9d9d9', margin: '0px 24px'}}>
                <IpiIdTitle IpiId={cpws.cpwsId} realTitle={realTitle} />
                </div>
                {/* <SiteFunctions
                    header="PDS Filter Site Functions"
                    panelProps={this.props.globalProps.defaultPanelProps}
                    impactedSites={this.props.cpws.impactedSites.selected}
                    siteFunctions={this.props.siteFunctions}
                    onFunctionSelect={(option: SelectionOption) => this.onSelect(option, 'siteFunctionSelect')}
                    onFunctionDeselect={(option: SelectionOption) => this.onSelect(option, 'siteFunctionDeselect')}
                    onSiteSelect={(option: SelectionOption, siteType: siteType) =>
                        this.onSelect(option, 'siteSelect', siteType)
                    }
                    onSiteDeselect={(option: SelectionOption) => this.onSelect(option, 'siteDeselect')}
                    siteFunctionRefs={this.props.siteFunctionsRef}
                    siteFunctionCardRef={this.props.siteFunctionCardRef}
                /> */}
                <CountryList
                    loading={this.state.loadingAdditional}
                    countries={this.props.impactedCountries}
                    panelProps={this.props.globalProps.defaultPanelProps}
                    sites={this.props.cpws.impactedSites.selected}
                    updateCountries={this.props.updateCountries}
                    resetPDS={this.props.resetPDS}
                    impactedSites={this.props.cpws.impactedSites.selected}
                    siteFunctions={this.props.siteFunctions}
                    onFunctionSelect={(option: SelectionOption) => this.onSelect(option, 'siteFunctionSelect')}
                    onFunctionDeselect={(option: SelectionOption) => this.onSelect(option, 'siteFunctionDeselect')}
                    onSiteSelect={(option: SelectionOption, siteType: siteType) =>
                        this.onSelect(option, 'siteSelect', siteType)
                    }
                    onSiteDeselect={(option: SelectionOption) => this.onSelect(option, 'siteDeselect')}
                    siteFunctionRefs={this.props.siteFunctionsRef}
                    siteFunctionCardRef={this.props.siteFunctionCardRef}
                />
                <ChangeInformation
                    cpws={cpws}
                    staticProductInformation={staticProductInformation}
                />
            </CPWSStep>
        );
    }
}
