import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import every from 'lodash/every';
import { RegulatoryQuestions, MaterialType, AppOptions, ENV, RegulatoryQuestionValue } from './model/index';

export const allRegulatoryQuestionsAnswered = (regulatoryQuestions: RegulatoryQuestions): boolean => {
    return every(regulatoryQuestions, (val: RegulatoryQuestionValue) => {
        if (Object.prototype.hasOwnProperty.call(val, 'children') && val.isAnswered && val.answer) {
            return allRegulatoryQuestionsAnswered(val.children as RegulatoryQuestions);
        } else {
            return val.isAnswered;
        }
    });
};

export const allRegulatoryQuestionAnswersAreNo = (regulatoryQuestions: RegulatoryQuestions): boolean => {
    return Object.keys(regulatoryQuestions).every((key: string): boolean => !regulatoryQuestions[key].answer);
};

export const someRegulatoryQuestionsAnswered = (regulatoryQuestions: RegulatoryQuestions): boolean => {
    return Object.keys(regulatoryQuestions).some((key: string): boolean => regulatoryQuestions[key].isAnswered);
};

export const someRegulatoryQuestionAnswersAreYes = (regulatoryQuestions: RegulatoryQuestions): boolean => {
    return Object.keys(regulatoryQuestions).some((key: string): boolean => regulatoryQuestions[key].answer);
};

export const getRegulatoryQuestionAnswersAsList = (
    regulatoryQuestions: RegulatoryQuestions,
    numQuestions: number,
): Array<boolean> => {
    const answers: Array<boolean> = [];
    for (let i = 0; i < numQuestions; i++) {
        answers.push(regulatoryQuestions[`question${i + 1}`].answer);
    }
    return answers;
};

export const openAttachment = async (
    e: React.MouseEvent<HTMLAnchorElement>,
    link: string,
    openAttachmentApi: Function,
): Promise<void> => {
    let regex = /{api}/m;

    e.preventDefault();

    if (regex.exec(link) === null) {
        window.open(link, '_blank');
    } else {
        regex = /{api}\/file\/(.*)/m;
        const match = regex.exec(link);
        if (match !== null) {
            const response = await openAttachmentApi(match[1]);
            if (response.presigned_url) {
                const index = link.indexOf('#page');
                if (index > -1) {
                    window.open(`${response.presigned_url}${link.substring(index)}`, '_blank');
                } else {
                    window.open(response.presigned_url, '_blank');
                }
            }
        }
    }
};

export const removeCountFromMaterialType = (materialTypes: Array<MaterialType>): Array<MaterialType> => {
    const regex = /.+(?=\([0-9]+\)$)/gm;
    return materialTypes.map((materialType: MaterialType) => {
        const match = regex.exec(materialType.name);
        return match !== null
            ? {
                  name: match[0],
                  code: materialType.code,
              }
            : materialType;
    });
};

export const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];

export const filterByObjectKey = <T extends { [key: string]: any }>(array: Array<T>, key: string): Array<T> => {
    const filteredArray: Array<T> = [];
    array.forEach((elem: T) => {
        const index = filteredArray.findIndex((obj: T) => obj[key] === elem[key]);
        if (index <= -1) {
            filteredArray.push(elem);
        }
    });

    return filteredArray;
};

export const cleanObjectValue = <T extends { [key: string]: any }>(
    array: Array<T>,
    valueToClean: string,
    replaceRegex: RegExp,
): Array<T> => {
    return array.map((elem: T) => ({
        ...elem,
        [valueToClean]: elem[valueToClean].replace(replaceRegex, ''),
    }));
};

export const sortObjectArrayByValue = <T extends { [key: string]: any }>(
    array: Array<T>,
    sortValue: string,
): Array<T> => {
    return array.sort((a, b) => {
        if(a[sortValue] === null) {
            return 1;
        } else if(b[sortValue] === null) {
            return -1;
        }
        if (a[sortValue].toLowerCase() < b[sortValue].toLowerCase()) {
            return -1;
        }
        if (a[sortValue].toLowerCase() > b[sortValue].toLowerCase()) {
            return 1;
        }
        return 0;
    });
};

export const findObjectByValue = <T extends { [key: string]: any }>(
    array: Array<T>,
    key: string,
    value: string,
): T | undefined => {    
    return find(array, [key, value]);
};

export const findObjectIndexByValue = <T extends { [key: string]: any }>(
    array: Array<T>,
    key: string,
    value: string,
): number => {
    return findIndex(array, [key, value]);
};

export const objectExistsInArray = <T extends { [key: string]: K }, K>(
    objArr: Array<T>,
    key: string,
    value: K,
): T | undefined => {
    return find(objArr, obj => {
        return obj[key] === value;
    });
};

export const getAppOptions = (): AppOptions => {
    const query = new URLSearchParams(window.location.search);
    const options: any = {};
    if (query.get('debug')) {
        options.debug = true;
    }
    const startid = query.get('id');
    if (startid) {
        if (startid.length > 0) {
            options.id = startid;
        }
    }
    // if (query.get('useMocks')) {
    //     options.useMocks = true;
    // }
    if (query.get('env')?.toUpperCase() === ENV.DEV) {
        options.env = ENV.DEV;
    }
    // if (query.get('env')?.toUpperCase() === ENV.LOCAL) {
    //     options.env = ENV.LOCAL;
    // }

    const returnoptions: AppOptions = { ...options };

    return returnoptions;
};
