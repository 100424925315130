import {
    Card,
    Progress,
    Result,
    Spin,
    Typography,
    Upload,
    Collapse,
    Input,
    Row,
    Col,
    notification,
    Button,
    Tabs,
    Table,
    Popover,
    Space,
    Radio,
    Form,
    Select,
} from 'antd';
import { UserAddOutlined, AimOutlined, RightCircleTwoTone, LeftCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import {
    CPWS,
    ProductModality,
    Site,
    ProductEvents,
    SiteEvents,
    GlobalProps,
    RegulatoryQuestions,
    ProductModalitySelections,
    FormRefs,
    SiteFunction,
    ColorTag,
    Actions,
    ImpactAnalysis,
    RelatedChanges,
    Plants,
    Suppliers,
    Country,
    RegisteredMarkets,
    ProductsInfo,
    Partners,
    CPWSClassificationCodes,
    CPWSClassification,
    ExternalMaterial,
    Material,
    SelectedMaterials,
    Materials,
} from '../model';
import { findObjectByValue, sortObjectArrayByValue, findObjectIndexByValue } from '../utils';


import React, { ReactText } from 'react';
const { Text } = Typography;
const { TextArea } = Input;
import './components.css';
import { CPWSQuestions } from './CPWSQuestions';
import { MaterialClassification } from './MaterialClassification';
import Highlighter from 'react-highlight-words';
import { ExternalMaterialsDropdown } from './ExternalMaterialsDropdown';
import { cloneDeep } from 'lodash';
import { FormValidationErrorText } from '.';
import { FormContext } from '../context/FormContext';
const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;

export interface IPIMaterialsState {
    selectedProductRowKeys: Array<string>;
    selectedMaterialRowKeys: Array<string>;
    searchText: string;
    searchedColumn: string;
    areImpactedMaterialsMissing: boolean;
    customInput: string;
    externalMaterials: Array<ExternalMaterial>;
    repopulateData: boolean,
    dropdownOpen: boolean;
    isLoading: boolean;
    cpwsMaterialData: Array<Materials>;
    cpwsProductData: Array<ProductsInfo>;
}
export interface FilterInput {
    setSelectedKeys: any;
    selectedKeys: any;
    confirm: any;
    clearFilters: any;
}
export interface IPIMaterialsProps {
    cpws: CPWS;
    // addMaterial: Function;
    // externalImpactedMaterials: Array<ExternalMaterial>;
    onSelectRows?: (rows: any) => void
    userSelectedMaterials: Array<SelectedMaterials>;
    updateCurrentTab?: Function;
    viewOnly?: boolean;
}
export class IPIMaterials extends React.Component<IPIMaterialsProps, IPIMaterialsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedProductRowKeys: [],
            selectedMaterialRowKeys: [],
            searchText: '',
            searchedColumn: '',
            areImpactedMaterialsMissing: false, // Check here to configure the default column
            customInput: '',
            externalMaterials: [],
            repopulateData: true,
            dropdownOpen: false,
            isLoading: true,
            cpwsMaterialData: [],
            cpwsProductData: [],
        };

    }
    

    async componentDidMount() {

        const materialData: Array<string> = [];
        const productData: Array<string> = [];
        const cpwsMaterialData = cloneDeep(this.props.cpws.materials);
        const cpwsProductData = cloneDeep(this.props.cpws.productInformation.products);

        this.props.userSelectedMaterials.map((data: SelectedMaterials) => {
            if (data.typeCode === 'M') {
               (cpwsMaterialData.filter(value => {
                    if (value.material_number === data.number)
                    materialData.push( value.material_number);
                }))
            }
        });
        this.props.userSelectedMaterials.map((data: SelectedMaterials) => {
            if (data.typeCode === 'P') {
                (cpwsProductData.filter(value => {
                    if (value.product_number === data.number)
                        productData.push(value.product_number);
                }))
            }
        });

        this.setState({ cpwsMaterialData: cpwsMaterialData, cpwsProductData: cpwsProductData, selectedMaterialRowKeys: materialData, selectedProductRowKeys: productData, isLoading: false }, 
            () =>  this.props.onSelectRows?.({ selectedProductRowKeys: this.state.selectedProductRowKeys, selectedMaterialRowKeys: this.state.selectedMaterialRowKeys }));
        
    }

    onProductSelectChange = (selectedProductRowKeys: any) => {
        this.setState({ selectedProductRowKeys });
        this.props.onSelectRows?.({ selectedProductRowKeys, selectedMaterialRowKeys: this.state.selectedMaterialRowKeys })
    };
    onMaterialSelectChange = (selectedMaterialRowKeys: any) => {
        this.setState({ selectedMaterialRowKeys:selectedMaterialRowKeys});
        this.props.onSelectRows?.({ selectedProductRowKeys: this.state.selectedProductRowKeys, selectedMaterialRowKeys })
    };

    defaultProductsSelection = (selectedProductRowKeys: any) => {
        const value = this.props.userSelectedMaterials.filter(selectedProductRowKeys.number);
    }

    getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterInput): any => (
        //filterDropdown: (setSelectedKeys: Function, selectedKeys: any, confirm: any, clearFilters: any) => (
            <div style={{ padding: 8 }} >

                <Input
                    //   ref={node => {
                    //     this.searchInput = node;
                    //   }}
                    placeholder={`Search ${dataIndex}`}
                       value={selectedKeys[0]}
                       onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, left: 'auto', right: 0 }}

                    >
                        Search
              </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                           // confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
              </Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: !filtered ? 'rgb(100,100,100)' : 'rgb(24,144,255)' }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        // onFilterDropdownVisibleChange: (visible: boolean) => {
        //   if (visible) {
        //     setTimeout(() => this.searchInput.select(), 100);
        //   }
        // },
        render: (text: any) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={(text && text.toString() !== null) ? text.toString().replace(/^0+/, '') : ''}
                />
            ) : (
               (text !== null && text.replace(/^0+/, ''))
            ),
    });

    handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({ searchText: '', repopulateData: true });
    };

    addManual = (event: any) => {
        for (let i = 0; i < event.length; ++i) {
            if (event[i].value) {
                const material = event[i].value;

                this.setState({ customInput: this.state.customInput.concat(material) });
            } else {
                this.setState({ customInput: event });
            }
        }
    };    

    render(): JSX.Element {
        const { selectedProductRowKeys, selectedMaterialRowKeys } = this.state;
        //console.log("IPI materials >>> selectedProductRowKeys >> ", selectedProductRowKeys);
        //console.log("IPI materials >>> selectedMaterialRowKeys >> ", selectedMaterialRowKeys);
        const rowProductSelection = {
            selectedRowKeys:selectedProductRowKeys,
            onChange: (selectedRowKeys: any) => {
                if (this.props.updateCurrentTab) {
                    this.props.updateCurrentTab('ipiMaterials');
                }
                this.onProductSelectChange(selectedRowKeys);
            },

            //default: this.defaultProductsSelection,
        };
        const rowMaterialSelection = {
            selectedRowKeys:selectedMaterialRowKeys,
            onChange: (selectedRowKeys: any) => {
                if (this.props.updateCurrentTab) {
                    this.props.updateCurrentTab('ipiMaterials');
                }
                this.onMaterialSelectChange(selectedRowKeys);
           // onload: selectedMaterialRowKeys,
           // default: selectedMaterialRowKeys,
           // Selection: selectedMaterialRowKeys,
            },

        };

        
        const materials = [
            {
                title: 'Name',
                dataIndex: 'material_name',
                ...this.getColumnSearchProps('material_name'),


            },
            {
                title: 'Material Number',
                dataIndex: 'material_number',
                width: '40%',
                ...this.getColumnSearchProps('material_number'),
            },
        ];
        const products = [
            {
                title: 'Name',
                dataIndex: 'product_name',
                ...this.getColumnSearchProps('product_name'),
                render: (text: any, record: ProductsInfo) => {
                    return (
                        <div>
                            <Text>{record.product_name}</Text>
                            {record.directly_impacted ? <Popover content={'Directly impacted'} placement={'topRight'}>
                                <AimOutlined style={{ color: '#1890ff', paddingLeft: '5px' }}> </AimOutlined>
                            </Popover> : ''}
                            {record.manually_added ? <Popover content={'Manually added'} placement={'topRight'}>
                                <UserAddOutlined style={{ color: '#1890ff', paddingLeft: '5px' }}> </UserAddOutlined>
                            </Popover> : ''}                            
                        </div>);
                }
            },
            {
                title: 'Product Number',
                dataIndex: 'product_number',
                width: '35%',
                ...this.getColumnSearchProps('product_number'),
            },
        ];


        return (
            <FormContext.Consumer>
            {({ materialSelected, viewOnly }) => (
                <>
            <Card bordered={false}>
                {this.state.isLoading ? (<Spin size="large" className="loading-spin" />) :
                ( 
                <><Row gutter={16}>
                        <h4> Identify the materials/products directly impacted by the change </h4>
                        </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <h4 style={{ textAlign: 'center' }}> Materials</h4>
                                    <Table
                                        loading={this.state.isLoading}
                                        dataSource={[...this.state.cpwsMaterialData]}
                                        rowKey={(record: Materials) => record.material_number}
                                        columns={materials}
                                        scroll={{ y: 250 }}
                                        className = "custom-table"
                                        rowSelection={
                                            (viewOnly || this.props.viewOnly === true)
                                                ? {
                                                    ...rowMaterialSelection,
                                                    getCheckboxProps: () => {
                                                        return { disabled: true };
                                                    },
                                                }
                                              : rowMaterialSelection}
                                        // rowKey={
                                        //     '_' +
                                        //     Math.random()
                                        //         .toString(36)
                                        //         .substr(2, 9)
                                        // }
                                        bordered />
                                </Col>
                                <Col span={12}>
                                    <h4 style={{ textAlign: 'center' }}> Products</h4>
                                    <Table
                                        className = "custom-table"
                                        dataSource={this.props.cpws.productInformation.products}
                                        columns={products}
                                        scroll={{ y: 250 }}
                                        rowSelection={
                                            (viewOnly || this.props.viewOnly === true)
                                                ?{
                                                    ...rowProductSelection,
                                                    getCheckboxProps: () => {
                                                        return { disabled: true };
                                                    },
                                                }
                                              : rowProductSelection}
                                        rowKey={(record: ProductsInfo) => record.product_number}
                                        // rowKey={
                                        //     '_' +
                                        //     Math.random()
                                        //         .toString(36)
                                        //         .substr(2, 9)
                                        // }
                                        bordered />
                                </Col>
                            </Row></>
                 )}
                <hr className="line-style" />

                {/* {this.manualMaterials()} */}
            </Card>
            </>
                )}
            </FormContext.Consumer>
        );
    }
};
