import { Card, Col, Collapse, Radio, Row, Typography } from 'antd';
import React from 'react';
import { PanelProps, CPWS } from '../model';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';

const { Panel } = Collapse;
const { Text, Title } = Typography;

interface QAQuestionProps {
    panelProps: PanelProps;
    updateQaQuestion: Function;
    qaQuestionAnswered: boolean;
    cpws: CPWS;
    qualityRef?: React.RefObject<HTMLDivElement>;
}
export interface QAQuestionState {
    checked: boolean;
    value: any;
}

export class QualityAssessmentQuestion extends React.Component<QAQuestionProps, QAQuestionState> {
    constructor(props: QAQuestionProps) {
        super(props);
        this.state = {
            checked: false,
            value: '',
        };
    }

    componentDidMount = () => {
        const { qaQuestionAnswered, cpws } = this.props;

        const answer = cpws.qaQuestion;
        if (qaQuestionAnswered) {
            if (answer) {
                this.setState({ value: true });
            } else {
                this.setState({ value: false });
            }
        }
    };
    onChange = (e: any) => {
        this.props.updateQaQuestion(e.target.value);
        this.setState({ value: e.target.value });
    };
    render(): JSX.Element {
        return (
            <div>
                {}
                    <FormContext.Consumer>
                        {({ qualitySelected, viewOnly }) => (
                            <Row gutter={[0, 8]} style={{ paddingTop: '8px'}} align={'middle'}>
                                <Col span={12} style={{ paddingRight: '8px' }}> 
                                    <Text>Does the proposed change potentially impact the product quality?</Text>
                                </Col>
                                {!qualitySelected ? (
                                    <Col span={24} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
                                        <FormValidationErrorText text="Please answer the quality assessment question" />
                                    </Col>
                                ) : null}
                                <Col span={12} style={{ alignContent: 'center', padding: '16px' }}>
                                    <Radio.Group
                                        onChange={this.onChange}
                                        value={this.state.value}
                                        className={viewOnly ? 'view-only' : ''}
                                    >
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        )}
                    </FormContext.Consumer>
                {/* </Card> */}
            </div>
        );
    }
}

interface QAStatementsProps {
    qualityAssessment: Array<string>;
    panelProps: PanelProps;
}
export const QualityAssessmentStatements: React.FC<QAStatementsProps> = (props: QAStatementsProps) => {
    const { qualityAssessment } = props;

    return (
        <Card bordered={false}>
            <Collapse {...props.panelProps} defaultActiveKey={['singleString']}>
                <Panel header="Quality Assessment statements" key="singleString">
                    {qualityAssessment?.length > 0 ? qualityAssessment : '[No Quality Assessments were located]'}
                </Panel>
            </Collapse>
        </Card>
    );
};
