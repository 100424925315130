import CopyTwoTone from '@ant-design/icons/CopyTwoTone';
import { Collapse, Input, List, Tooltip } from 'antd';
import React from 'react';
import * as model from '../model';
const { Panel } = Collapse;

export interface DebugProps {
    appstate: any;
}

export default class Debug extends React.Component<DebugProps, {}> {
    constructor(props: DebugProps) {
        super(props);
        this.state = {
            url: '',
            requestBody: '',
        };
    }
    render(): JSX.Element {
        if (this.props.appstate.options.debug) {
            const { cpws, logger, s3props } = this.props.appstate;
            return (
                <Collapse>
                    <Panel header="debug" key="1">
                        <Collapse>
                            <Panel header="cpws" key="1">
                                <p>
                                    <a href={'/?debug=true&id=' + s3props.key}>CPWS:{cpws.cpwsId + cpws.title}</a>
                                </p>
                            </Panel>
                        </Collapse>
                        <Collapse>
                            <Panel header="api" key="1">
                                <Input
                                    onChange={e => {
                                        console.log(e);
                                    }}
                                    placeholder="URL"
                                >
                                    {}
                                </Input>
                                <Input placeholder="request"></Input> <button></button>
                                <List
                                    header={<div>Calls</div>}
                                    bordered
                                    dataSource={logger.calls}
                                    renderItem={(call: model.ApiCall, index: number): any => (
                                        <List.Item key={index + call.url}>
                                            <Input
                                                addonBefore="url"
                                                value={call.url}
                                                suffix={
                                                    <Tooltip title="Copy">
                                                        <CopyTwoTone
                                                            onClick={(): void => {
                                                                navigator.clipboard.writeText(call.url);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                            />
                                            <Input
                                                addonBefore="request"
                                                value={JSON.stringify(call.request)}
                                                suffix={
                                                    <Tooltip title="Copy">
                                                        <CopyTwoTone
                                                            onClick={(): void => {
                                                                navigator.clipboard.writeText(
                                                                    JSON.stringify(call.request),
                                                                );
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                            />
                                            <Input
                                                addonBefore="response"
                                                value={JSON.stringify(call.response)}
                                                suffix={
                                                    <Tooltip title="Copy">
                                                        <CopyTwoTone
                                                            onClick={(): void => {
                                                                navigator.clipboard.writeText(
                                                                    JSON.stringify(call.response),
                                                                );
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                                <List
                                    header={<div>errors</div>}
                                    bordered
                                    dataSource={logger.errors}
                                    renderItem={(call: model.ApiCall, index: number): any => (
                                        <List.Item key={index + call.url}>
                                            <Input
                                                addonBefore="url"
                                                value={call.url}
                                                suffix={
                                                    <Tooltip title="Copy">
                                                        <CopyTwoTone
                                                            onClick={(): void => {
                                                                navigator.clipboard.writeText(call.url);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                            />
                                            <Input
                                                addonBefore="request"
                                                value={JSON.stringify(call.request)}
                                                suffix={
                                                    <Tooltip title="Copy">
                                                        <CopyTwoTone
                                                            onClick={(): void => {
                                                                navigator.clipboard.writeText(
                                                                    JSON.stringify(call.request),
                                                                );
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                            />
                                            <Input
                                                addonBefore="response"
                                                value={JSON.stringify(call.response)}
                                                suffix={
                                                    <Tooltip title="Copy">
                                                        <CopyTwoTone
                                                            onClick={(): void => {
                                                                navigator.clipboard.writeText(
                                                                    JSON.stringify(call.response),
                                                                );
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                            />
                                            <Input
                                                addonBefore="error"
                                                value={JSON.stringify(call.error)}
                                                suffix={
                                                    <Tooltip title="Copy">
                                                        <CopyTwoTone
                                                            onClick={(): void => {
                                                                navigator.clipboard.writeText(
                                                                    JSON.stringify(call.error),
                                                                );
                                                            }}
                                                        />
                                                    </Tooltip>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Panel>
                        </Collapse>
                    </Panel>
                </Collapse>
            );
        } else {
            return <div></div>;
        }
    }
}
