import React from 'react';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';

interface TreeIconProps {
    iconKey: string;
    callback: Function;
    iconState: boolean;
}

export const TreeIcon: React.FC<TreeIconProps> = (props: TreeIconProps) => {
    const { iconKey, callback, iconState } = props;

    const handleClick = () => {
        callback(iconKey);
    };
    return iconState ? (
        <CaretDownOutlined style={{ paddingRight: '4px', fontSize: '10px' }} onClick={handleClick} />
    ) : (
        <CaretRightOutlined style={{ paddingRight: '4px', fontSize: '10px' }} onClick={handleClick} />
    );
};
