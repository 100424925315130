import { Card, Col, Collapse, Row } from 'antd';
import React from 'react';
import { PanelProps } from '../model';
import './components.css';
const { Panel } = Collapse;

interface ChangeDescriptionProps {
    currentChange: string;
    proposedChange: string;
    panelProps: PanelProps;
}
export const ChangeDescription: React.FC<ChangeDescriptionProps> = (props: ChangeDescriptionProps) => {
    const { currentChange, proposedChange } = props;

    return (
        <Card bordered={false}>
            <Collapse {...props.panelProps} defaultActiveKey={1}>
                <Panel header="Change Proposal" key="1">
                    <Row gutter={4} key="changerow">
                        <Col span={12}>
                            <Card
                                title="Current"
                                bordered={true}
                                headStyle={{
                                    backgroundColor: '#fafafa',
                                }}
                            >
                                {currentChange}
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="Proposed" bordered={true} headStyle={{ backgroundColor: '#fafafa' }}>
                                {proposedChange}
                            </Card>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Card>
    );
};
