import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
const { Text } = Typography;
export interface CPWSHeaderProps {
    cpwsId: string;
    title: string;
}
export const CPWSHeader: React.FC<CPWSHeaderProps> = (props: CPWSHeaderProps) => {
    const { cpwsId, title } = props;
    const realTitle = title ? title : 'No data was loaded. Please upload a CPWS file.';

    return (
        <div>
            <Card bordered={false} key={`CPWScard${cpwsId}2`}>
                <Row gutter={4}>
                    <Col
                        span={5}
                        style={{
                            background: '#fafafa',
                            padding: '16px',
                            borderColor: '#d9d9d9',
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                        }}
                    >
                        <Text strong>{`CPWS#:  ${cpwsId}`}</Text>
                    </Col>
                    <Col
                        span={19}
                        style={{
                            padding: '16px',
                            borderColor: '#d9d9d9',
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                        }}
                    >
                        <Text>{realTitle}</Text>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

CPWSHeader.defaultProps = { cpwsId: '0', title: 'no data' };
