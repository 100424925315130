import { Card, Collapse, Table } from 'antd';
import React from 'react';
import { PanelProps, Site } from '../model';

const { Panel } = Collapse;

export interface DownstreamSitesProps {
    panelProps: PanelProps;
    downstreamSites: Array<Site>;
    loading: boolean;
}
export const DownstreamSites: React.FC<DownstreamSitesProps> = (props: DownstreamSitesProps) => {
    const { panelProps, downstreamSites, loading } = props;

    return (
        <Card bordered={false}>
            <Collapse {...panelProps}>
                <Panel header="Downstream Sites" key="1">
                    <Table
                        pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
                        loading={loading}
                        size="small"
                        rowKey={(record: any): string => {
                            return JSON.stringify(record);
                        }}
                        columns={[
                            {
                                title: 'Site Name',
                                dataIndex: 'name',
                                sortOrder: 'ascend',
                            },
                        ]}
                        dataSource={downstreamSites}
                    />
                </Panel>
            </Collapse>
        </Card>
    );
};
