import { Card, Collapse, Table, Tabs, Tag, Typography, Popover, Button, Menu, Checkbox, Input, Space, Tooltip } from 'antd';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { AssessmentResult,HistoricalData, Country, Filing, PanelProps, RegulatoryQuestions, MarketSiteFunction } from '../model';
import { SortOrder } from 'antd/lib/table/interface';
import { someRegulatoryQuestionAnswersAreYes, openAttachment, sortObjectArrayByValue, getKeyValue } from '../utils';
import FilterFilled from '@ant-design/icons/FilterFilled';
import Item from 'antd/lib/list/Item';
import { MatchCriteriaQuestions } from '.';

const { Text, Link, Title } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;
export interface FinalResultsProps {
    panelProps: PanelProps;
    assessmentResults: Array<any>;
    loading?: boolean;
    countries: Array<Country>;
    qaQuestion: boolean;
    printPDF: boolean;
    regulatoryQuestions: RegulatoryQuestions;
    openAttachmentApi: Function;
}
interface FilterInput {
    setSelectedKeys: any;
    selectedKeys: any;
    confirm: any;
    clearFilters: any;
}

interface FilteredValues {
    countryNameFilters: string[];
    riFilters: string[];
    dataReqFilters: string[];
    filingsFilters: string[];
}

interface AssessmentResultAndCountry extends AssessmentResult, Country {}

const haAssessmentDefinitions = [
    {
        riType: 'RED',
        definition: 'Regulatory Impact',
    },
    {
        riType: 'ORANGE',
        definition: 'May have regulatory impact',
    },
    {
        riType: 'GREEN',
        definition: 'No Regulatory Impact',
    },
    {
        riType: 'YELLOW',
        definition: 'May not have regulatory impact',
    },
    {
        riType: 'GREY',
        definition: 'Data not available',
    },
];

const marketColumns = [
    {
        title: 'Site',
        dataIndex: 'site',
    },
    {
        title: 'Function',
        dataIndex: 'function',
    },
    {
        title: 'Status',
        dataIndex: 'status',
    },
];

const filingsColumns = [
    {
        title: 'Red Compass/HA Regulation',
        dataIndex: 'chtCode',
        render: (text: string) => <Text>{text}</Text>,
    },
    {
        title: 'Filing Type',
        dataIndex: 'filingType',
        render: (text: string, record: Filing) => {
            if (record.category === 'HA') {
                return <Text>RI</Text>;
            }
            if (record.category === 'RC' && record.filing === 'N') {
                return <Text>NRI</Text>;
            }
            return <Text>{text}</Text>;
        },
    },
    {
        title: 'Notification Description',
        dataIndex: 'notificationType',
        render: (text: string, record: Filing) => {
            if (record.category === 'HA') {
                return <Text>?</Text>;
            }
            return <Text>{text}</Text>;
        },
    },
    {
        title: 'Approval Time',
        dataIndex: 'approvalTime',
        render: (text: string, record: Filing) => {
            if (record.category === 'HA') {
                return <Text>?</Text>;
            }
            return <Text>{text}</Text>;
        },
    },
];

const batchAnalysisColumns = [
    {
        title: 'Product Type',
        dataIndex: 'product_type',
    },
    {
        title: 'Batch Type',
        dataIndex: 'batch_type',
    },
    {
        title: 'Number of Batches',
        dataIndex: 'number_of_batches',
    },
    {
        title: 'Regulations',
        dataIndex: 'change_types',
        render: (data: Array<string>) => {
            return data.join('; ')
        }
    },
];
const comparitiveDissolutionColumns = [
    {
        title: 'Number of Lots',
        dataIndex: 'number_of_lots',
    },
    {
        title: 'Media Type',
        dataIndex: 'media_type',
    },    
];
const stabilityColumns = [
    {
        title: 'Stability Type',
        dataIndex: 'stability_type',
    },
    {
        title: 'Number of Lots',
        dataIndex: 'number_of_lots',
    },
];

export const FinalResults: React.FC<FinalResultsProps> = (props: FinalResultsProps) => {
    const { panelProps, assessmentResults, countries, loading, printPDF } = props;
    
    const [allCountries, setAllCountries] = React.useState(
        countries.some((country: Country) => country.pdsFlag)
            ? sortObjectArrayByValue(
                  countries.reduce((filteredCountries: Array<{ name: string; checked: boolean }>, country: Country) => {
                      if (country.pdsFlag) {
                          filteredCountries.push({ name: country.name, checked: false });
                      }
                      return filteredCountries;
                  }, []),
                  'name',
              )
            : countries.map(country => ({ name: country.name, checked: false })),
    );
    const getRecords = () => {
        const tmpArr : any[] = [];
        const retArr : any[] = [];
        //console.log('assessment results', assessmentResults);
        (assessmentResults || []).map((record: any) => {            
            record.filingsRC.map((item: any) => {
                //console.log('item', item);
                if(item.filingType != "" && item.filingType != undefined && tmpArr.indexOf(item.filingType) == -1) {
                    tmpArr.push(item.filingType);
                    retArr.push({name: item.filingType, checked: false});
                }
            })
        });
        //console.log('return array', retArr);
        return retArr;
    }
    const [allFilings, setAllFilings]= React.useState(getRecords());
    const [filteredFilings, setFilteredFilings] = React.useState(cloneDeep(allFilings));
    const [filteredCountries, setFilteredCountries] = React.useState(cloneDeep(allCountries));
    const [countryFilterInput, setCountryFilterInput] = React.useState<string>('');
    const [filingFilterInput, setFilingFilterInput] = React.useState<string>('');
    const [filteredValues, setFilteredValues] = React.useState<FilteredValues>({
        countryNameFilters: [],
        riFilters: [],
        dataReqFilters: [],
        filingsFilters: []
    });
    const [clearFilterFlag, setClearFilterFlag] = React.useState(false);
    //console.log("All Filings", allFilings);
    //console.log("All Countries", allCountries);
    const filterCountries = (countryFilterInput: string): void => {
        if (countryFilterInput === '') {
            setFilteredCountries(cloneDeep(allCountries));
        } else {
            setFilteredCountries(
                cloneDeep(
                    allCountries.filter(country =>
                        country.name.toLowerCase().startsWith(countryFilterInput.toLowerCase()),
                    ),
                ),
            );
        }
    };

    const filterFilings = (filingFilterInput: string): void => {
        if (filingFilterInput === '') {
            setFilteredFilings(cloneDeep(allFilings));
        } else {
            setFilteredFilings(
                cloneDeep(
                    allFilings.filter(filing =>
                        filing.name.toLowerCase().startsWith(filingFilterInput.toLowerCase()),
                    ),
                ),
            );
        }
    };

    const getQuestions = (data: Array<string>) => {       
        return (
            <div style={{"maxHeight":"80px", "overflowY":"auto"}}>
                <ul>
                    {data.map(listitem => (
                        <li key={listitem}>{listitem}</li>
                    ))}                      
                </ul>
            </div>
        )
        //console.log(quesArray);
    }

    const countryChecked = (countryName: string | undefined) => {
        const countryIndex = findIndex(allCountries, { name: countryName });
        allCountries[countryIndex].checked = !allCountries[countryIndex].checked;
        setAllCountries([...allCountries]);

        const filteredCountryIndex = findIndex(filteredCountries, { name: countryName });
        filteredCountries[filteredCountryIndex].checked = !filteredCountries[filteredCountryIndex].checked;
        setFilteredCountries([...filteredCountries]);
    };

    const filingChecked = (filingName: string | undefined) => {
        const filingIndex = findIndex(allFilings, { name: filingName });
        allFilings[filingIndex].checked = !allFilings[filingIndex].checked;
        setAllFilings([...allFilings]);

        const filteredFilingIndex = findIndex(filteredFilings, { name: filingName });
        filteredFilings[filteredFilingIndex].checked = !filteredFilings[filteredFilingIndex].checked;
        setFilteredFilings([...filteredFilings]);
    };

    const handleFilingSearch = (confirm: Function) => {
        confirm();
        setFilteredValues({
            ...filteredValues,
            filingsFilters: allFilings.filter(filing => filing.checked).map(filing => filing.name),
        });
    };

    const clearFilingFilters = () => {
        setClearFilterFlag(true);
        setAllFilings(getRecords());
        setFilingFilterInput('');
        setFilteredValues({ ...filteredValues, filingsFilters: [] });
    };

    const handleSearch = (confirm: Function) => {
        confirm();
        setFilteredValues({
            ...filteredValues,
            countryNameFilters: allCountries.filter(country => country.checked).map(country => country.name),
        });
    };

    const clearCountryFilters = () => {
        setClearFilterFlag(true);
        setAllCountries(countries.map(country => ({ name: country.name, checked: false })));
        setCountryFilterInput('');
        setFilteredValues({ ...filteredValues, countryNameFilters: [] });
    };

    const handleChange = (pagination: any, filters: any, sorter: any) => {
        setFilteredValues({ ...filteredValues, riFilters: filters.color, dataReqFilters: filters.dataRequirements });
    };

    const riSorter = (a: AssessmentResult, b: AssessmentResult) => {
        const priorityList = ['RED', 'ORANGE', 'GREEN', 'YELLOW', 'GREY'];
        const aIndex = priorityList.findIndex((color: string) => color === a.color);
        const bIndex = priorityList.findIndex((color: string) => color === b.color);

        if (aIndex < bIndex) {
            return -1;
        } else if (aIndex > bIndex) {
            return 1;
        } else {
            return 0;
        }
    };

    const generateHaAssessmentTag = (color: string) => {
        switch (color) {
            case 'RED':
                return (
                    <Tag closable={false} color="red">
                        RI
                    </Tag>
                );
            case 'GREEN':
            case 'YELLOW':
                return (
                    <Tag closable={false} color={color.toLocaleLowerCase()}>
                        {color === 'GREEN' ? 'NRI' : 'NRI?'}
                    </Tag>
                );
            case 'ORANGE':
                return (
                    <Tag closable={false} color={color.toLocaleLowerCase()}>
                        RI?
                    </Tag>
                );
            case 'GREY':
                return (
                    <Tag closable={false} color={color.toLocaleLowerCase()}>
                        ?
                    </Tag>
                );
        }
    };


    const nriChart = (riVal:number,nriVal:number,riPer:number,nriPer:number) => {
        riPer = riPer == 100 ? riPer + 1 : riPer;
        nriPer = nriPer == 100 ? nriPer + 1 : nriPer;    
        console.log(riVal,'riVal')
        console.log(nriVal,'nriVal')
        return (
            <>
                <div id="pie-chart-container">
                <Tooltip title={'RI:'+riVal + ' ' + '/' + ' ' + 'NRI:' + nriVal} color="default" placement="topLeft">
                    <div id="pie-chart" style={ (riPer == 0 && nriPer == 0) ? {background: `conic-gradient(lightgray 100%, lightgray 0%)`} : {background: `conic-gradient(#fd7b7f 00.00%  ${riPer}%, #befa83 00.00% ${nriPer}%)`}}
                    ></div>
                </Tooltip>    
                </div>
                

                <div className="display-none">
                <ul className="key">
                    <li>
                    <strong className="percent red">{riVal}</strong>
                    <span className="choice">RI</span>
                    </li>
                    <li>
                    <strong className="percent green">{nriVal}</strong>
                    <span className="choice">NRI</span>
                    </li>
                </ul>
                </div>
            </>
        )
    };

    const renderFilingTypeTable = (filings: Array<Filing>) => {
        const sortedFilings = filings.sort((a: Filing, b: Filing) => {
            if (Object.prototype.hasOwnProperty.call(a, 'rank') && !Object.prototype.hasOwnProperty.call(b, 'rank')) {
                return -1;
            }
            if (!Object.prototype.hasOwnProperty.call(a, 'rank') && Object.prototype.hasOwnProperty.call(b, 'rank')) {
                return 1;
            }
            if (Object.prototype.hasOwnProperty.call(a, 'rank') && Object.prototype.hasOwnProperty.call(b, 'rank')) {
                if (a.rank && b.rank) {
                    if (a.rank < b.rank) {
                        return -1;
                    }
                    if (a.rank > b.rank) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            }
            return 0;
        });

        return (
            <Table
                dataSource={sortedFilings}
                columns={filingsColumns}
                bordered
                pagination={false}
                size="small"                
                scroll={{ y: 250 }}
                style={{"width":"99%"}}
            />
        );
    };

    const getFilingTypeRankOrder = (filings: Array<Filing>) => {
        const lowestRanking = filings.reduce(
            (rankings: { lowestRank: number; count: number; filingTypes: Array<string> }, filing: Filing) => {
                if (Object.prototype.hasOwnProperty.call(filing, 'rank') && filing.rank && filing.filingType) {
                    if (rankings.lowestRank === -1 || filing.rank < rankings.lowestRank) {
                        return {
                            lowestRank: filing.rank,
                            count: 1,
                            filingTypes: [filing.filingType],
                        };
                    }
                    if (filing.rank === rankings.lowestRank) {
                        return {
                            ...rankings,
                            count: rankings.count += 1,
                            filingTypes: !rankings.filingTypes.includes(filing.filingType)
                                ? [...rankings.filingTypes, filing.filingType]
                                : rankings.filingTypes,
                        };
                    }
                    return rankings;
                }
                return rankings;
            },
            { lowestRank: -1, count: 0, filingTypes: [] },
        );
        if (lowestRanking.count > 1) {
            return (
                //<Popover content={renderFilingTypeTable(filings)}>
                    <Text>
                        {lowestRanking.filingTypes.length > 1
                            ? lowestRanking.filingTypes.join(', ')
                            : lowestRanking.filingTypes.toString()}
                    </Text>
                //</Popover>
            );
        }
        if (lowestRanking.count === 1) {
            return (
                //<Popover content={renderFilingTypeTable(filings)}>
                    <Text>{lowestRanking.filingTypes.toString()}</Text>
                //</Popover>
            );
        }
        if (lowestRanking.count === 0) {
            return null;
        }
    };

    React.useEffect(() => {
        if (clearFilterFlag) {
            setFilteredCountries(cloneDeep(allCountries));
            setClearFilterFlag(false);
        }
    }, [allCountries]);

    React.useEffect(() => {
        filterCountries(countryFilterInput);
    }, [countryFilterInput]);

    React.useEffect(() => {
        if (clearFilterFlag) {
            setFilteredFilings(cloneDeep(allFilings));
            setClearFilterFlag(false);
        }
    }, [allFilings]);

    React.useEffect(() => {
        filterFilings(filingFilterInput);
    }, [filingFilterInput]);

    const columnsFilterDropdownRender = ({ confirm }: FilterInput) => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Search`}
                value={countryFilterInput}
                onChange={e => setCountryFilterInput(e.target.value ? e.target.value : '')}
                style={{ marginBottom: 8 }}
            />
            <Menu className="ant-dropdown-menu-without-submenu ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical">
                {filteredCountries.map(country => (
                    <Menu.Item
                        style={{ margin: 0, lineHeight: '22px', padding: '5px 12px', height: 'auto' }}
                        key={country.name as string}
                    >
                        <Checkbox
                            checked={country.checked}
                            onChange={e => countryChecked(e.target.name)}
                            name={country.name as string}
                        />
                        <span>{country.name}</span>
                    </Menu.Item>
                ))}
            </Menu>
            <div className={`ant-table-filter-dropdown-btns`}>
                <Button type="link" block={true} onClick={() => clearCountryFilters()}>
                    Reset
                </Button>
                <Button type="primary" block={true} onClick={() => handleSearch(confirm)}>
                    OK
                </Button>
            </div>
        </div>
    );

    const columnsFilingsFilterDropdownRender = ({ confirm }: FilterInput) => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Search`}
                value={filingFilterInput}
                onChange={e => setFilingFilterInput(e.target.value ? e.target.value : '')}
                style={{ marginBottom: 8 }}
            />
            <Menu className="ant-dropdown-menu-without-submenu ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical">
                {filteredFilings.map(filing => (
                    <Menu.Item
                        style={{ margin: 0, lineHeight: '22px', padding: '5px 12px', height: 'auto' }}
                        key={filing.name as string}
                    >
                        <Checkbox
                            checked={filing.checked}
                            onChange={e => filingChecked(e.target.name)}
                            name={filing.name as string}
                        />
                        <span>{filing.name}</span>
                    </Menu.Item>
                ))}
            </Menu>
            <div className={`ant-table-filter-dropdown-btns`}>
                <Button type="link" block={true} onClick={() => clearFilingFilters()}>
                    Reset
                </Button>
                <Button type="primary" block={true} onClick={() => handleFilingSearch(confirm)}>
                    OK
                </Button>
            </div>
        </div>
    );

    const renderFilterIcon = (filtered: boolean) =>
        filtered ? (
            <FilterFilled style={{ color: 'rgb(24,144,255)' }} />
        ) : (
            <FilterFilled style={{ color: 'rgb(138,138,138)' }} />
        );

    const renderTitle = (title: string) => <Text className="table-title">{title}</Text>;    

    const renderInScopeMarket = (item: Country) => {
        const content = item.siteFunctions ? (
            <Table dataSource={item.siteFunctions} columns={marketColumns} size="small" pagination={false} />
        ) : (
            <Text>Market is not a part of PDS Site filter.</Text>
        );
        const title = item.siteFunctions ? <Title level={5}>Approval status by Site and Site Function</Title> : '';
        const color =
            item.siteFunctions &&
            item.siteFunctions.some((marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Approval') &&
            item.siteFunctions.some((marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Delete')
                ? '#f2ca53'
                : item.siteFunctions &&
                  item.siteFunctions.some(
                      (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Approval',
                  ) &&
                  !item.siteFunctions.some(
                      (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Delete',
                  )
                ? '#1890ff'
                : item.siteFunctions &&
                  !item.siteFunctions.some(
                      (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Approval',
                  ) &&
                  item.siteFunctions.some(
                      (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Delete',
                  )
                ? '#ff0308'
                : 'black';

        return (
            //<Popover content={content} title={title} placement={'topLeft'}>
            <div>
                {item.manuallyAdded && !item.pdsFlag ? (
                    <Tag closable={false} color="green">
                        <Text>{item.name}</Text>
                    </Tag>
                ) : (
                    <Text
                        style={{
                            color: color,
                        }}
                    >
                        {item.name}
                    </Text>
                )}
            </div>    
            //</Popover>
        );
    };    

    const columns = [
        {
            title: renderTitle('Market Registered'),
            dataIndex: 'countryName',
            //key: 'countryCode'
            defaultSortOrder: 'ascend' as const,
            sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],

            sorter: (a: AssessmentResultAndCountry, b: AssessmentResultAndCountry): number => {
                if (a.name > b.name) {
                    return 1;
                } else if (a.name < b.name) {
                    return -1;
                } else {
                    return 0;
                }
            },
            filterDropdown: columnsFilterDropdownRender,
            onFilter: (value: any, record: AssessmentResultAndCountry) => {
                return record.name === value;
            },
            filteredValue: filteredValues.countryNameFilters || null,
            filterIcon: renderFilterIcon,
            // eslint-disable-next-line react/display-name
            render: (text: any, record: AssessmentResultAndCountry): JSX.Element => renderInScopeMarket(record),
        },
        {
            title: renderTitle('RI/NRI'),
            dataIndex: 'color',
            filters: [
                { text: generateHaAssessmentTag('RED'), value: 'RED' },
                { text: generateHaAssessmentTag('ORANGE'), value: 'ORANGE' },
                { text: generateHaAssessmentTag('GREEN'), value: 'GREEN' },
                { text: generateHaAssessmentTag('YELLOW'), value: 'YELLOW' },
                { text: generateHaAssessmentTag('GREY'), value: 'GREY' },
            ],
            filteredValue: filteredValues.riFilters || null,
            onFilter: (value: string | number | boolean, record: AssessmentResult) => record.color === value,
            sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
            sorter: (a: AssessmentResult, b: AssessmentResult): number => riSorter(a, b),
            filterIcon: renderFilterIcon,
            render: (color: string) => generateHaAssessmentTag(color),
        },
        {
            title: 'Historical Data',
            dataIndex: 'historicalData',  
            // filterDropdown: columnsFilingsFilterDropdownRender,     
            // filteredValue: filteredValues.filingsFilters || null,   
            // onFilter: (value: any, record: any) =>  {
            //     console.log(record.filingsRC[0] != undefined && record.filingsRC[0].filingType, value);
            //     return record.filingsRC[0] != undefined && record.filingsRC[0].filingType === value
            // },           
            render: (historicalData:HistoricalData):any => nriChart(historicalData.RAD_RI,historicalData.RAD_NRI,historicalData.RI_Percent,historicalData.NRI_Percent),              
        },
        {
            title: 'Filing Type',
            dataIndex: 'filingsRC',    
            filterDropdown: columnsFilingsFilterDropdownRender,     
            filteredValue: filteredValues.filingsFilters || null,   
            onFilter: (value: any, record: any) =>  {
                //console.log(record.filingsRC[0] != undefined && record.filingsRC[0].filingType, value);
                return record.filingsRC[0] != undefined && record.filingsRC[0].filingType === value
                //return true;
            },           
            render: (val: Array<Filing>, record: AssessmentResultAndCountry) => {
                const tmp = getFilingTypeRankOrder([...record.filingsHA, ...record.filingsRC]);
                if(record.filingsRC != undefined && record.filingsRC.length == 0) {
                    return null;
                } else {
                    return tmp;
                }
            }                
        },
        {
            title: 'Data Requirements',
            dataIndex: 'dataRequirements',
            filters: [
                { text: 'Batch Analysis', value: 'batch_analysis'},
                { text: 'Bioequivalence Study', value: 'bioequivalence_study'},
                { text: 'Comparative Dissolution', value: 'comparative_dissolution'},
                { text: 'Stability', value: 'stability'},
                { text: 'Product/Packaging Interaction', value: 'product_packaging_interaction'},
                { text: 'Transportation/Storage Condition', value: 'transportation_storage_conditions'},
                { text: 'Validation', value: 'validation'},
              ],
            filteredValue: filteredValues.dataReqFilters || null,
            onFilter: (value: any, record: any) => {
                let retval = false;
                //console.log(JSON.stringify(record, null, 2));
                //console.log(record.dataRequirements[value] != undefined && record.dataRequirements[value].length > 0);
                if(value == 'stability') {
                    if( (record.dataRequirements.dp_intermediate_stability != undefined && record.dataRequirements.dp_intermediate_stability.length > 0) ||
                        (record.dataRequirements.dp_stability != undefined && record.dataRequirements.dp_stability.length > 0) ||
                        (record.dataRequirements.ds_intermediate_stability != undefined && record.dataRequirements.ds_intermediate_stability.length > 0) ||
                        (record.dataRequirements.ds_stability != undefined && record.dataRequirements.ds_stability.length > 0)) {
                            retval = true;
                        }
                } else if(record.dataRequirements[value] != undefined && record.dataRequirements[value].length > 0) {
                    retval = true;
                }
                return retval;
            },
            render: (dataRequirements: any): any => {
                let tabText = "";
                if(dataRequirements.batch_analysis != undefined && dataRequirements.batch_analysis.length > 0) {
                    tabText += "Batch Analysis"
                }
                if(dataRequirements.bioequivalence_study != undefined && dataRequirements.bioequivalence_study.length > 0) {
                    tabText += (tabText != "") ? ", Bioequivalence Study" : "Bioequivalence Study";
                }
                if(dataRequirements.comparative_dissolution != undefined && dataRequirements.comparative_dissolution.length > 0) {
                    tabText += (tabText != "") ? ", Comparative Dissolution" : "Comparative Dissolution";
                }
                if((dataRequirements.dp_intermediate_stability != undefined && dataRequirements.dp_intermediate_stability.length > 0) ||
                (dataRequirements.dp_stability != undefined && dataRequirements.dp_stability.length > 0) ||
                (dataRequirements.ds_intermediate_stability != undefined && dataRequirements.ds_intermediate_stability.length > 0) ||
                (dataRequirements.ds_stability != undefined && dataRequirements.ds_stability.length > 0)) {
                    tabText += (tabText != "") ? ", Stability" : "Stability";
                }
                if(dataRequirements.product_packaging_interaction != undefined && dataRequirements.product_packaging_interaction.length > 0) {
                    tabText += (tabText != "") ? ", Product/Packaging Interaction" : "Product/Packaging Interaction";
                }
                if(dataRequirements.transportation_storage_conditions != undefined && dataRequirements.transportation_storage_conditions.length > 0) {
                    tabText += (tabText != "") ? ", Transportation/Storage Condition" : "Transportation/Storage Condition";
                }
                if(dataRequirements.validation != undefined && dataRequirements.validation.length > 0) {
                    tabText += (tabText != "") ? ", Validation" : "Validation";
                }                
                return tabText;
            }                
        },
        {
            title: 'HA Regulation',
            dataIndex: 'filingsHA',
          
            // sorter: ((a: any, b: any) => {
            //     if (a.filingsHA.length > 0 && b.filingsHA.length > 0) {

            //         return a.filingsHA[0].chtCode.localeCompare(b.filingsHA[0].chtCode)
            //     }
            // }),
            render: (filing: Array<Filing>): any => {
                const value = filing.map((f: any, index: any) => {
                    const title =
                        f.link === 'Not Available' ? (
                            <Text style={{ fontWeight: 600, paddingTop: '4px', paddingBottom: '4px' }}>
                                Regulation description: {f.chtCode}
                            </Text>
                        ) : (
                            <Link
                                href={f.link}
                                target="_blank"
                                onClick={e => {
                                    openAttachment(e, f.link, props.openAttachmentApi);
                                }}
                                style={{ fontWeight: 400, paddingTop: '4px', paddingBottom: '4px' }}
                            >
                                Regulation description: {f.chtCode}
                            </Link>
                        );
                    const content = (
                        <Space direction="vertical">
                            <Text>{f.description}</Text>
                            {/*<Space size={2}>
                                <Text strong>Market: </Text>
                                <Text>{f.regionCode}</Text>
                    </Space>*/}
                        </Space>
                    );

                    return (
                        <Popover key={JSON.stringify(`${f.chtCode}${index}`)} title={title} content={content}>
                            <Text key={f.chtCode}>{f.chtCode}; </Text>
                        </Popover>
                    );
                });

                return value;
            },
        },
      
       
    ];

    const displayEmptyInfo = (title: string) => {
        return (
            <div style={{textAlign: "center"}}>
                <Text><i><b>{title}</b> is not required for this change.</i></Text>
            </div>
        )
    }

    const haAssessmentLegendColumnDefRender = (text: string) => <Text className="classification-def">{text}</Text>;

    const haAssessmentLegendColumns = [
        {
            title: 'RI Type',
            dataIndex: 'riType',
            render: (color: string) => generateHaAssessmentTag(color),
            width: '10%',
            className: 'classification-dropdown-column',
        },
        {
            title: 'RI Definition',
            dataIndex: 'definition',
            render: haAssessmentLegendColumnDefRender,
            className: 'classification-dropdown-column',
        },
    ];

    const renderHaAssessmentLegend = () => {
        return (
            <Table
                dataSource={haAssessmentDefinitions}
                columns={haAssessmentLegendColumns}
                pagination={false}
                className="classification-dropdown"
                rowKey={
                    '_' +
                    Math.random()
                        .toString(36)
                        .substr(2, 9)
                }
                bordered
            />
        );
    };

    const haAssessmentLegend = () => (
        <Popover content={renderHaAssessmentLegend()} placement="bottomLeft" trigger="click">
            <Button
                onClick={(e: any) => {
                    e.stopPropagation();
                }}
            >
                RI/NRI Legend
            </Button>
        </Popover>
    );
    
    
    const displayExpandedRow = (record: any) => {
        return (
            <Tabs defaultActiveKey="filing_type" type="card" style={{"padding": "15px 0px 15px 35px"}}>
                                    
                <TabPane tab="Filing Type" key="filing_type">
                    <div>{renderFilingTypeTable([...record.filingsHA, ...record.filingsRC])}</div>
                </TabPane>
                    
                
                <TabPane tab="Data Requirements" key="data_req">
                    <Card bordered={false}>
                        <Tabs defaultActiveKey="batch_analysis" type="card" className="multi_level_data_req">
                            
                            <TabPane tab="Batch Analysis" key="batch_analysis" style={{padding:"10px 25px"}}>
                            {record.dataRequirements.batch_analysis != undefined && record.dataRequirements.batch_analysis.length > 0 ?
                                <Table
                                    dataSource={record.dataRequirements.batch_analysis}
                                    columns={batchAnalysisColumns}
                                    pagination={false}                                                            
                                    bordered
                                /> : null
                            }    
                            {record.dataRequirements.batch_analysis != undefined && record.dataRequirements.batch_analysis.length == 0 &&
                             record.dataRequirements.questions_without_data_requirements != undefined && record.dataRequirements.questions_without_data_requirements.length == 0 ?
                                displayEmptyInfo("Batch Analysis")
                            : <div></div> 
                            }
                            </TabPane>  
                            
                            <TabPane tab="Bioequivalence Study" key="bio_study">
                            {record.dataRequirements.bioequivalence_study != undefined && record.dataRequirements.bioequivalence_study.length > 0 ?
                                <div style={{textAlign: "center", padding: "15px"}}>
                                {record.dataRequirements.bioequivalence_study.map((itm: any, index: number)=> {                                                                
                                    if(index == 0 && itm.biowaiver_accepted == 'N') {                                                                    
                                        return <Text>Biowaiver Acceptanace - <b>Not Accepted</b></Text>
                                    } else if(index == 0 && itm.biowaiver_accepted == 'Y') {
                                        return <Text>Biowaiver Acceptanace - <b>Accepted</b></Text>
                                    }
                                    
                                })}                                                        
                                </div> : null
                            }
                            {record.dataRequirements.bioequivalence_study != undefined && record.dataRequirements.bioequivalence_study.length == 0 &&
                             record.dataRequirements.questions_without_data_requirements != undefined && record.dataRequirements.questions_without_data_requirements.length == 0 ?
                                displayEmptyInfo("Bioequivalence Study")
                            : <div></div> 
                            }
                            </TabPane>
                            
                            <TabPane tab="Comparative Dissolution" key="comp_dis">
                                {record.dataRequirements.comparative_dissolution != undefined && record.dataRequirements.comparative_dissolution.length > 0 ?
                                    <Table
                                        dataSource={record.dataRequirements.comparative_dissolution}
                                        columns={comparitiveDissolutionColumns}
                                        style={{width:"400px", margin:"0px auto"}}
                                        pagination={false}                                                            
                                        bordered
                                    /> : null
                                }
                                {record.dataRequirements.comparative_dissolution != undefined && record.dataRequirements.comparative_dissolution.length == 0 &&
                                record.dataRequirements.questions_without_data_requirements != undefined && record.dataRequirements.questions_without_data_requirements.length == 0 ?
                                    displayEmptyInfo("Comparative Dissolution")
                                : <div></div> 
                                }
                            </TabPane>
                            
                            <TabPane tab="Product/Packaging Interaction" key="prod_pack">
                            {record.dataRequirements.product_packaging_interaction != undefined && record.dataRequirements.product_packaging_interaction.length > 0 ?
                                <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                    <Text><b>Product/Packaging Interaction Types</b></Text>
                                    <div style={{paddingTop:"20px"}}>
                                        {
                                            record.dataRequirements.product_packaging_interaction.map((itm: any) => {
                                                return <Text key={itm} style={{paddingRight:"25px"}}>{itm.type_of_interaction}</Text>
                                            })
                                        }
                                    </div>                                                            
                                </div> : null
                            }
                            {record.dataRequirements.product_packaging_interaction != undefined && record.dataRequirements.product_packaging_interaction.length == 0 &&
                                record.dataRequirements.questions_without_data_requirements != undefined && record.dataRequirements.questions_without_data_requirements.length == 0 ?
                                    displayEmptyInfo("Product/Packaging Interaction")
                                : <div></div> 
                            }
                            </TabPane>
                            
                            <TabPane tab="Stability" key="stab">
                            {((record.dataRequirements.dp_stability != undefined && record.dataRequirements.dp_stability.length > 0) || 
                            (record.dataRequirements.dp_intermediate_stability != undefined && record.dataRequirements.dp_intermediate_stability.length > 0) ||
                            (record.dataRequirements.ds_stability != undefined && record.dataRequirements.ds_stability.length > 0) || 
                            (record.dataRequirements.ds_intermediate_stability != undefined && record.dataRequirements.ds_intermediate_stability.length > 0)) ?
                                <div>
                                {record.dataRequirements.dp_stability != undefined && record.dataRequirements.dp_stability.length > 0 ?
                                    <div style={{textAlign: "center", marginTop: "20px"}}>
                                        <Text><b>Drug Product Stability</b></Text>
                                        <Table
                                            dataSource={record.dataRequirements.dp_stability}
                                            columns={stabilityColumns}
                                            style={{width:"400px", margin:"15px auto"}}
                                            pagination={false}                                                            
                                            bordered
                                        />
                                    </div> : <div></div>
                                }
                                {record.dataRequirements.dp_intermediate_stability != undefined && record.dataRequirements.dp_intermediate_stability.length > 0 ?
                                    <div style={{textAlign: "center", marginTop: "20px"}}>
                                        <Text><b>Drug Product Intermediate Stability</b></Text>
                                        <Table
                                            dataSource={record.dataRequirements.dp_intermediate_stability}
                                            columns={stabilityColumns}
                                            style={{width:"400px", margin:"15px auto"}}
                                            pagination={false}                                                            
                                            bordered
                                        />
                                    </div> : <div></div>
                                }
                                {record.dataRequirements.ds_stability != undefined && record.dataRequirements.ds_stability.length > 0 ?
                                    <div style={{textAlign: "center", marginTop: "20px"}}>
                                        <Text><b>Drug Substance Stability</b></Text>
                                        <Table
                                            dataSource={record.dataRequirements.ds_stability}
                                            columns={stabilityColumns}
                                            style={{width:"400px", margin:"15px auto"}}
                                            pagination={false}                                                            
                                            bordered
                                        />
                                    </div> : <div></div>
                                }
                                {record.dataRequirements.ds_intermediate_stability != undefined && record.dataRequirements.ds_intermediate_stability.length > 0 ?
                                    <div style={{textAlign: "center", marginTop: "20px"}}>
                                        <Text><b>Drug Substance Intermediate Stability</b></Text>
                                        <Table
                                            dataSource={record.dataRequirements.ds_intermediate_stability}
                                            columns={stabilityColumns}
                                            style={{width:"400px", margin:"15px auto"}}
                                            pagination={false}                                                            
                                            bordered
                                        />
                                    </div> : <div></div>
                                }
                                </div> : null
                            }
                            {record.dataRequirements.dp_stability != undefined && record.dataRequirements.dp_stability.length == 0 &&
                            record.dataRequirements.dp_intermediate_stability != undefined && record.dataRequirements.dp_intermediate_stability.length == 0 &&
                            record.dataRequirements.ds_stability != undefined && record.dataRequirements.ds_stability.length == 0 &&
                            record.dataRequirements.ds_intermediate_stability != undefined && record.dataRequirements.ds_intermediate_stability.length == 0 && 
                             record.dataRequirements.questions_without_data_requirements != undefined && record.dataRequirements.questions_without_data_requirements.length == 0 ?
                                    displayEmptyInfo("Stability")
                                : <div></div> 
                            }
                            </TabPane>
                            
                            <TabPane tab="Transportation/Storage" key="trans_storage">
                            {record.dataRequirements.transportation_storage_conditions != undefined && record.dataRequirements.transportation_storage_conditions.length > 0 ?
                                <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                    <Text><b>Transportation/Storage Condition Types</b></Text>
                                    <div style={{paddingTop:"20px"}}>
                                        {
                                            record.dataRequirements.transportation_storage_conditions.map((itm: any) => {
                                                return <Text key={itm} style={{paddingRight:"25px"}}>{itm.type_of_conditions}</Text>
                                            })
                                        }
                                    </div>
                                </div> : null
                            }
                            {record.dataRequirements.transportation_storage_conditions != undefined && record.dataRequirements.transportation_storage_conditions.length == 0 &&
                                record.dataRequirements.questions_without_data_requirements != undefined && record.dataRequirements.questions_without_data_requirements.length == 0 ?
                                    displayEmptyInfo("Transportation/Storage")
                                : <div></div> 
                            }
                            </TabPane>
                            
                            <TabPane tab="Validation" key="validation">
                            {record.dataRequirements.validation != undefined && record.dataRequirements.validation.length > 0 ?
                                <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                    <Text><b>Validation Types</b></Text>
                                    <div style={{paddingTop:"20px"}}>
                                        {
                                            record.dataRequirements.validation.map((itm: any) => {
                                                return <Text key={itm} style={{paddingRight:"25px"}}>{itm.validation_type}</Text>
                                            })
                                        }
                                    </div>
                                </div> : null
                            }
                            {record.dataRequirements.validation != undefined && record.dataRequirements.validation.length == 0 &&
                                record.dataRequirements.questions_without_data_requirements != undefined && record.dataRequirements.questions_without_data_requirements.length == 0 ?
                                    displayEmptyInfo("Validation")
                                : <div></div> 
                            }
                            </TabPane>
                        </Tabs>
                        
                        {record.dataRequirements.questions_without_data_requirements != undefined && record.dataRequirements.questions_without_data_requirements.length > 0 ?
                            <div style={{paddingTop: "20px"}}>
                                <Text>CAKE database does not include data requirements for the following Regulatory impact assessment questions and further assessment is required.</Text>   
                                {getQuestions(record.dataRequirements.questions_without_data_requirements)}
                            </div>
                            : null
                        }
                                                                  
                    </Card>
                </TabPane>
                
                {record.siteFunctions ?
                <TabPane tab="Site Approval Status" key="site_app_status">
                    <div style={{"padding":"0px 20px 10px 20px"}}>
                        <div style={{"paddingBottom":"10px"}}><Text><b>Approval status by Market and Site Function</b></Text></div>
                        <Table dataSource={record.siteFunctions} columns={marketColumns} bordered={true} pagination={false} />
                    </div>
                </TabPane> :  null
                }
                
            </Tabs>
        
        );
    };

    return (
        <>
            {!someRegulatoryQuestionAnswersAreYes(props.regulatoryQuestions) ? (
                <Card bordered={false}>
                    <Collapse {...panelProps} defaultActiveKey={1}>
                        <Panel header="Market Level Assessment" key="1" extra={haAssessmentLegend()}>
                            <Table
                                loading={loading}
                                size="small"
                                rowKey={(record: any): string => {
                                    return JSON.stringify(record);
                                }}
                                expandable={{
                                    expandedRowRender: (record) => {
                                        if(record.dataRequirements != undefined && record.filingsHA != undefined && record.filingsRC != undefined) {
                                            return  displayExpandedRow(record);
                                        }
                                    },
                                    rowExpandable: record => record.dataRequirements != undefined && record.filingsHA != undefined && record.filingsRC != undefined,
                                }}
                                columns={columns}
                                dataSource={(assessmentResults || []).reduce(
                                    (
                                        impactedMarkets: Array<AssessmentResultAndCountry>,
                                        assessment: AssessmentResult,
                                    ) => {
                                        const country = find(countries, { code: assessment.countryCode });
                                        if (country) {
                                            impactedMarkets.push({
                                                ...assessment,
                                                ...country,
                                            });
                                        }
                                        return impactedMarkets;
                                    },
                                    [],
                                )}
                                pagination={
                                    printPDF ? false : { simple: false, showTitle: false, showQuickJumper: false }
                                }
                                onChange={handleChange}
                            />
                        </Panel>
                    </Collapse>
                </Card>
            ) : null}
        </>
    );
};
