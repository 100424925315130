import LeftCircleTwoTone from '@ant-design/icons/LeftCircleTwoTone';
import RightCircleTwoTone from '@ant-design/icons/RightCircleTwoTone';
import RightCircleOutlined from '@ant-design/icons/RightCircleOutlined'; 
import LeftCircleOutlined from '@ant-design/icons/LeftCircleOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { Button, Layout, notification, Typography, Space } from 'antd';
import React from 'react';
import {
    CPWS,
    RegulatoryQuestions,
    FormValidationFields,
    ProductModalitySelections,
    Site,
    ContainerClosureMaterial,
    Material,
    MaterialType,
    MatchCriteriaAnswer,
} from '../model';
import {
    allRegulatoryQuestionsAnswered,
    allRegulatoryQuestionAnswersAreNo,
    someRegulatoryQuestionAnswersAreYes,
} from '../utils';
import { FormContext } from '../context/FormContext';
import mapValues from 'lodash/mapValues';
import { isEmpty } from 'lodash';
import { SaveOutlined } from '@ant-design/icons';
import '../App.css';

const { Footer } = Layout;
const { Text, Link } = Typography;
interface FooterProps {
    showFooter: boolean;
    prev: Function;
    next: Function;
    save: Function;
    current: number;
    highestAvailable: number;
    qaQuestionAnswered: boolean;
    dsDpAnswered: boolean;
    sterileAnswered: boolean;
    saveAssessment: Function;
    cpws: CPWS;
    generatePDF: Function;
    updateHighestAvailable: Function;
    unlockPDF: any;
    visitedChangeClassification: boolean;
    regulatoryQuestions: RegulatoryQuestions;
    justification: string;
    goTo: Function;
    productModalitiesSelections: ProductModalitySelections;
    scrollTo: Function;
    afterPDFSave: Function;
    matchCriteriaAnswers: MatchCriteriaAnswer;
    loadMatchCriteria?: boolean;
    nriOption: string;
    rationaleAssessment: string;
    userAssessment: string;
    userAssessmentRationale: string;
    userSelectedMaterials: Array<Object>;
    impactedMaterialsAvailableOption: any;
    cakeAssessment: boolean;
    updateSession: Function;
    user_nri_ri: string;
    system_nri_ri: boolean;
    biologic_nri_ri: string;
    vaccine_nri_ri: string;
    smallmolecule_nri_ri: string;
    scope_change_question: string;
    user_comment: string;
    prat: any;
    submission_req_details: any;
    currentPlanningAssessmentType: string;
    currentDirectionalAssessmentReason: string;
    productInformation: any;
    productModalitySelections: any;
    directionalDetails: string;
    markets: any;
    countries: Array<object>;
}
export const StepFooter: React.FC<FooterProps> = (props: FooterProps) => {
    const {
        current,
        next,
        prev,
        save,
        qaQuestionAnswered,
        dsDpAnswered,
        sterileAnswered,
        saveAssessment,
        cpws,
        generatePDF,
        updateHighestAvailable,
        unlockPDF,
        goTo,
        productModalitiesSelections,
        afterPDFSave,
        matchCriteriaAnswers,
        highestAvailable,
        loadMatchCriteria,
        nriOption,
        rationaleAssessment,
        userAssessment,
        userAssessmentRationale,
        userSelectedMaterials,
        impactedMaterialsAvailableOption,
        visitedChangeClassification,
        cakeAssessment,
        updateSession,
        user_nri_ri,
        system_nri_ri,
        biologic_nri_ri,
        vaccine_nri_ri,
        smallmolecule_nri_ri,
        scope_change_question,
        user_comment,
        prat,
        submission_req_details,
        currentPlanningAssessmentType,
        currentDirectionalAssessmentReason,
        productInformation,
        productModalitySelections,
        directionalDetails,
        markets,
        countries
    } = props;

    const [showImage, setShowImage] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [savingAssessment, setSavingAssessment] = React.useState(false);

    //console.log('markets in footer: ', markets)

    const openScreen4Warning = (
        matchCriteriaAnswers: MatchCriteriaAnswer,
        loadMatchCriteria: boolean,
        formFields: FormValidationFields,
        setFields: Function,
    ) => {
        if (
            Object.keys(matchCriteriaAnswers).every((key: string) => matchCriteriaAnswers[key].isAnswered) &&
            !loadMatchCriteria
        ) {
            next();
        } else {
            setFields({
                ...mapValues(formFields, () => true),
                matchCriteriaAnswered: false,
                viewOnly: formFields.viewOnly,
            });
            props.scrollTo(
                {
                    matchCriteriaAnswered: false,
                },
                'screen4',
            );
        }
    };

    const openScreen5Warning = (
        materialsClassified: boolean,
        formFields: FormValidationFields,
        setFields: Function,
    ) => {
        if (materialsClassified) {
            next();
        } else {
            setFields({
                ...mapValues(formFields, () => true),
                classificationSelected: false,
                viewOnly: formFields.viewOnly,
            });
            props.scrollTo(
                {
                    classificationSelected: false,
                },
                'screen5',
            );
        }
    };

    const productModalitySelected = (productModalitySelections: ProductModalitySelections) => {
        return Object.keys(productModalitySelections).some((key: string) => productModalitySelections[key] === true);
    };

    const checkExternalMaterials = (materials : Array<any>) => {
        let retval = false;
        materials.forEach((mat: any) => {
            if(mat.typeCode == 'E') {
                retval = true;
            }
        });
        return retval;
    }

    const checkAllPDSSiteSelected = () => {
        let retval = true;
        cpws.impactedSites.selected.forEach((itm: any) => {
            if(itm.isPDS && itm.siteFunctions.length === 0) {
                retval = false;
            }
        });
        return retval;
    }

    const reducer = (codes: Array<string>, country: any) => {
        if (
            (country.pdsFlag &&
                Object.prototype.hasOwnProperty.call(country, 'manuallyAdded') &&
                country.manuallyAdded) ||
            (country.pdsFlag && !Object.prototype.hasOwnProperty.call(country, 'manuallyAdded')) ||
            (!country.pdsFlag &&
                Object.prototype.hasOwnProperty.call(country, 'manuallyAdded') &&
                country.manuallyAdded)
        ) {
            codes.push(country.code);
        }
        return codes;
    };

    const inScopeMarketNotEmptyVerify = () => {        
        let tmpArray = countries.reduce(reducer, []);
        //console.log("Country Length:", countries.length);
        //console.log("tmpArray Length:", tmpArray.length);
        let retVal = (cpws.impactedSites.selected.length > 0 && countries.length != 0 && tmpArray.length == 0) ? false : true;        
        return retVal;
    }

    const checkImpactedMaterials = (impactedMaterials: Array<any>) => {
        return impactedMaterials.every((mat: Material) => {
            const materialTypeSelected = mat.type.length > 0;
            if (!materialTypeSelected) {
                return false;
            }

            const hasContainerClosure = mat.type.some((matType: MaterialType) => matType.code === 'T14');
            if (materialTypeSelected && hasContainerClosure) {
                const hasDownstreamMaterials = mat.downstreamMaterials.length > 0;
                const allDownstreamMaterialsHaveType = mat.downstreamMaterials.every(
                    (downstreamMat: ContainerClosureMaterial) => downstreamMat.type.length > 0,
                );

                if (!hasDownstreamMaterials || !allDownstreamMaterialsHaveType) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        });
    };

    const checkArrayLength = (
        arrayLength: number,
        formFields: FormValidationFields,
        formKey: keyof FormValidationFields,
    ) => {
        if (arrayLength === 0) {
            return {
                formFields: { ...formFields, [formKey]: false },
            };
        }
        return {
            formFields: formFields,
        };
    };

    const checkJustification = (justification: string, formFields: FormValidationFields) => {
        if (justification === '') {
            return {
                formFields: { ...formFields, justificationProvided: false },
            };
        }
        return {
            formFields: formFields,
        };
    };

    const checkBoolean = (value: boolean, formFields: FormValidationFields, formKey: keyof FormValidationFields) => {
        if (!value) {
            return {
                formFields: { ...formFields, [formKey]: false },
            };
        }

        return {
            formFields: formFields,
        };
    };

    const checkObj = (
        value: { [key: string]: boolean },
        formFields: FormValidationFields,
        formKey: keyof FormValidationFields,
    ) => {
        if (Object.keys(value).every((key: string) => value[key] === false)) {
            return {
                formFields: { ...formFields, [formKey]: false },
            };
        }

        return {
            formFields: formFields,
        };
    };

    const openScreen3Warning = (
       // userHasAssignedMaterialType: boolean,
        siteFunctionsSelected: boolean,
        inScopeMarketNotEmpty: boolean,
        //PDSSiteSelected: boolean,
        formFields: FormValidationFields,
        setFields: Function,
    ) => {
        //console.log(siteFunctionsSelected);
        //if (userHasAssignedMaterialType && siteFunctionsSelected) 
        if (siteFunctionsSelected && inScopeMarketNotEmpty){
            next();
        } else {
            setFields({
                ...mapValues(formFields, () => true),
               // materialTypeSelected: userHasAssignedMaterialType ? true : false,
               // pdsSiteSelected: PDSSiteSelected ? true : false,
                siteFunctionsSelected: siteFunctionsSelected ? true : false,
                inScopeMarketNotEmpty: inScopeMarketNotEmpty ? true : false,
                viewOnly: formFields.viewOnly,
            });
            props.scrollTo(
                {
                   // materialTypeSelected: userHasAssignedMaterialType ? true : false,
                    siteFunctionsSelected: siteFunctionsSelected ? true : false,
                },
                'screen3',
            );
        }
    };
    const openScreen7Warning = (formFields: FormValidationFields, setFields: Function) => {
        setFields({
            ...mapValues(formFields, () => true),
            finalAssessmentAnswered: false,
            viewOnly: formFields.viewOnly,
        });
        props.scrollTo(
            {
                finalAssessmentAnswered: false,
            },
            'screen7',
        );
    };

    const openViewOnlySaveWarning = () => {
        notification.info({
            message: 'Unable to Save IPI in view-only mode',
            description: 'CAKE Assessments for IPI cannot be saved while in view only mode. Please edit the file to save.',
            placement: 'topRight',
            duration: 10,
            className: 'nav-warnings',
        });
    };

    const openScreen2Warning = (userAnsweredCPWS: boolean, userHavingMaterials: boolean, userHasAssignedMaterialType: boolean, impactOption: boolean, impactYesWithoutMaterial: boolean, classificationItemSelected: boolean, visitedClassification: boolean, formFields: FormValidationFields, setFields: Function) => {
        if(userHavingMaterials && userHasAssignedMaterialType && userAnsweredCPWS && !impactYesWithoutMaterial && !impactOption && !classificationItemSelected && visitedClassification) {
            next();
        } else {
            //console.log("Is View Only ?", formFields.viewOnly);
            if(formFields.viewOnly) {
                openViewOnlyWarning();
            } else {
                setFields({
                    ...mapValues(formFields, () => true),
                    cpwsQuestionsAnswered: userAnsweredCPWS ? true : false,
                    materialSelected: userHavingMaterials ? true : false,
                    materialTypeSelected: userHasAssignedMaterialType ? true : false, 
                    impactedMaterialsMissingWithYes: impactYesWithoutMaterial ? false : true,
                    impactedMaterialsMissing: impactOption ? false : true,
                    classificationSelected: !classificationItemSelected,      
                    viewOnly: formFields.viewOnly,
                });
                if(userHavingMaterials && userHasAssignedMaterialType && userAnsweredCPWS && !impactYesWithoutMaterial && !impactOption && !visitedClassification) {
                    notification.warning({
                        message: 'Please review and verify the Change Classification to continue',
                        placement: 'topRight',
                        duration: 10,
                        className: 'nav-warnings',
                    });
                }
            }
        }
        props.scrollTo(
            {
                materialSelected: false,
            },
            'screen2',
        );
    };
    const openunlockPDFWarning = () => {
        notification.warning({
            message: 'Please complete and save the CAKE Assessment for this IPI',
            placement: 'topRight',
            duration: 10,
            className: 'nav-warnings',
        });
    };

    const openViewOnlyWarning = () => {
        notification.info({
            message: 'Highest Screen Reached',
            description: 'It is not possible to proceed further with this IPI ID in view-only mode.',
            placement: 'topRight',
            duration: 10,
            className: 'nav-warnings',
        });
    };

    const saveCAKEAssessment = async (): Promise<void> => {
        setSavingAssessment(true);
        const response = await saveAssessment();
        //console.log('save assessment...', response);
        if (response === 200) {
            notification.success({
                message: 'CAKE assessment for this IPI successfully saved',
                description: 'Your CAKE assessment for this IPI has been saved to the database...',
                placement: 'topRight',
                duration: 3,
                className: 'nav-warnings',
            });
            setModalVisible(true);
        } else {
            notification.error({
                message: 'CAKE assessment for this IPI failed to save',
                description: 'Sorry! There was an error saving your CAKE record for this IPI ID, please try again...',
                placement: 'topRight',
                duration: 10,
                className: 'nav-warnings',
            });
        }
        setSavingAssessment(false);
    };

    const savePDF = async (): Promise<any> => {
        generatePDF();
    };
    
    const unlockPDFButton = () => {
        if (current === 5) {
            if (unlockPDF) {
                return (
                    <Button
                        type="primary"
                        style={{ backgroundColor: 'green', opacity: 1 }}
                        shape="round"
                        className='ml-2'
                        //disabled={markets.length === 0}
                        onClick={(): void => {
                            savePDF();
                        }}
                    >
                        Export Planning Workbook <RightCircleOutlined />
                    </Button>
                );
            } else {
                return (
                    <Button
                        type="primary"
                        style={{ backgroundColor: 'grey', opacity: 0.5 }}
                        shape="round"
                        className='ml-2'
                        onClick={(): void => {
                            openunlockPDFWarning();
                        }}
                    >
                        Export Planning Workbook <RightCircleOutlined />
                    </Button>
                );
            }
        }
    };
    const navButtonPrev = () => {
        if (current === 1) {
            // Hide if user is on screen 1
        } else {
            return (
                <Button
                    onClick={(): void => {
                        current === 7 && someRegulatoryQuestionAnswersAreYes(props.regulatoryQuestions)
                            ? goTo(1)
                            : prev();
                    }}
                    type="primary"
                    shape="round"
                    icon={<LeftCircleOutlined />}
                >
                    Previous
                </Button>
            );
        }
    };

    /*const matchCriteriaQuestionsAnswered = () => {
        const answers = matchCriteriaAnswers;

        if (Object.keys(answers).length === 0) {
            return true;
        }

        for (const key in answers) {
            const answerInfo = answers[key];
            if (answerInfo.answer === '') {
                return false;
            }
        }

        return true;
    }*/

    const filledSubmissionRequirementTable = () => {
        //console.log("Submission info in validation: ", cpws)
        return false;
    }

    const filledPlanningAssessmentInfo = () => {
        //console.log("Prat info in validation: ", cpws)
        return false;
    }

    const navButtonNext = (formFields: FormValidationFields, setFields: Function) => {
       // console.log('current screen 515 ', current);
        //console.log('highestAvailable ', highestAvailable);
        //console.log('highestAvailable: ', highestAvailable)
        if (highestAvailable === current && formFields.viewOnly && current !== 5) {
            return (
                <Button
                    type="primary"
                    style={{ background: 'grey', opacity: 0.5 }}
                    shape="round"
                    className='ml-2'
                    onClick={(): void => {
                        openViewOnlyWarning();
                    }}
                >
                    Next <RightCircleOutlined />
                </Button>
            );
        } else if (current === 5 && !formFields.viewOnly) {
            console.log('loop 2');
            if (
                //Case 1: cake yes, user ri, that's it
                (cakeAssessment && userAssessment === 'RI') || !filledSubmissionRequirementTable() ||
                !filledPlanningAssessmentInfo() ||
                //Case 2: cake yes, user nri, userassrat, nrioption, rationaleass
                (cakeAssessment &&
                    userAssessment === 'NRI' &&
                    !isEmpty(userAssessmentRationale) &&
                    !isEmpty(nriOption) &&
                    !isEmpty(rationaleAssessment)) ||
                //Case 3: cake no, user ri, userassrat
                (!cakeAssessment && userAssessment === 'RI' && !isEmpty(userAssessmentRationale)) || !filledSubmissionRequirementTable() ||
                !filledPlanningAssessmentInfo() ||
                //Case 4: cake no, user nri, nrioption, rationaleass
                (!cakeAssessment && userAssessment === 'NRI' && !isEmpty(nriOption) && !isEmpty(rationaleAssessment))
            ) {
                return (
                    <Button
                        type="primary"
                        style={{ backgroundColor: 'green', opacity: 1 }}
                        shape="round"
                        loading={savingAssessment}
                        disabled={savingAssessment}
                        onClick={(): void => {
                            saveCAKEAssessment();
                        }}
                    >
                        Save CAKE Assessment <RightCircleTwoTone />
                    </Button>
                );
            } else {
                return (
                    <Button
                        type="primary"
                        style={{ backgroundColor: 'grey', opacity: 0.6 }}
                        shape="round"
                        onClick={(): void => {
                            openScreen7Warning(formFields, setFields);
                        }}
                    >
                        Save CAKE Assessment <RightCircleTwoTone />
                    </Button>
                );
            }
        } else if (current === 5 && formFields.viewOnly) {
            console.log('loop 3');
            return (
                <Button
                    type="primary"
                    style={{ backgroundColor: 'grey', opacity: 0.6 }}
                    shape="round"
                    loading={savingAssessment}
                    disabled={savingAssessment}
                    onClick={(): void => {
                        openViewOnlySaveWarning();
                    }}
                >
                    Save CAKE Assessment <RightCircleTwoTone />
                </Button>
            );
        } else if (
            (current === 4 &&
                !Object.keys(matchCriteriaAnswers).every((key: string) => matchCriteriaAnswers[key].isAnswered)) ||
            (current === 4 && loadMatchCriteria)
        ) {
            console.log('loop 4');
            return (
                <Button
                    type="primary"
                    style={{ background: 'grey', opacity: 0.5 }}
                    shape="round"
                    className='ml-2'
                    onClick={(): void => {
                        openScreen4Warning(matchCriteriaAnswers, loadMatchCriteria as boolean, formFields, setFields);
                    }}
                >
                    Next <RightCircleOutlined />
                </Button>
            );
        } else if (
                //current === 1
                current === 0
            // current == 1 &&
            // (!allRegulatoryQuestionsAnswered(props.regulatoryQuestions) ||
            //     (someRegulatoryQuestionAnswersAreYes(props.regulatoryQuestions) && props.justification === '') ||
            //     (allRegulatoryQuestionAnswersAreNo(props.regulatoryQuestions) &&
            //         (!dsDpAnswered ||
            //             !sterileAnswered ||
            //             !productModalitySelected(props.productModalitiesSelections))) ||
            //     cpws.productModality.selected.length === 0 ||
            //     !cpws.impactedSites.selected.some((site: Site) => !site.isPDS) ||
            //     cpws.impactedSites.selected.some((site: Site) => site.isPDS && site.siteFunctions.length === 0))
        ) {
            //console.log('current screen is 1', current);
            return (
                <Button
                    type="primary"
                    style={{ background: 'grey', opacity: 0.5 }}
                    //disabled
                    shape="round"
                    className='ml-2'
                >
                    Next <RightCircleOutlined />
                </Button>
            );
        } else if (current === 3 && (!checkAllPDSSiteSelected() || !inScopeMarketNotEmptyVerify())) {

            //const PDSSiteSelected = cpws.impactedSites.selected.length > 0;
            const allPdsSiteHaveFunctionSelected = checkAllPDSSiteSelected();
            const inScopeMarketNotEmpty = inScopeMarketNotEmptyVerify();
            if(highestAvailable > 3) {updateHighestAvailable();}
            
            return (
                <Button
                    type="primary"
                    style={{ background: 'grey', opacity: 0.5 }}
                    shape="round"
                    className='ml-2'
                    onClick={(): void => {
                        openScreen3Warning(
                           // impactedMaterialsHaveType,
                            allPdsSiteHaveFunctionSelected,
                            inScopeMarketNotEmpty,
                            //PDSSiteSelected,
                            formFields,
                            setFields,
                        );
                    }}
                >
                    Next <RightCircleOutlined />
                </Button>
            );
        } else if (current === 2 && (
            !cpws.impactedMaterials.every((mat: Material) =>
            Object.keys(mat.classification).some(
                (classificationName: string) => mat.classification[classificationName]! >= 50,
            ),
        ) || !props.visitedChangeClassification || !dsDpAnswered || !sterileAnswered || !productModalitySelected(props.productModalitiesSelections) || impactedMaterialsAvailableOption == null ||
                (impactedMaterialsAvailableOption == "Yes" && !checkExternalMaterials(userSelectedMaterials)) || userSelectedMaterials.length < 1 || !checkImpactedMaterials(userSelectedMaterials))) {
            const impactedMaterialsHaveType = checkImpactedMaterials(userSelectedMaterials);
            const impactedMaterialsLength = userSelectedMaterials.length < 1 ? false : true;
            const cpwsAnswered = (!dsDpAnswered || !sterileAnswered || !productModalitySelected(props.productModalitiesSelections)) ? false : true;
            const impactOption = impactedMaterialsAvailableOption == null ? true : false;
            const impactYesWithoutMaterial = (impactedMaterialsAvailableOption == "Yes" && !checkExternalMaterials(userSelectedMaterials)) ? true : false;
            const classificationSelected =  !cpws.impactedMaterials.every((mat: Material) =>
                                                Object.keys(mat.classification).some(
                                                    (classificationName: string) => mat.classification[classificationName]! >= 50,
                                                ),
                                            );
            const visitedClassification = props.visitedChangeClassification;            
            //console.log("Classification Selected: " + classificationSelected);
            
            return (
                <Button
                    type="primary"
                    //disabled
                    style={{ backgroundColor: 'grey', opacity: 0.5 }}
                    shape="round"
                    className='ml-2'
                    onClick={(): void => {
                        openScreen2Warning(cpwsAnswered, impactedMaterialsLength, impactedMaterialsHaveType, impactOption, impactYesWithoutMaterial, classificationSelected, visitedClassification, formFields, setFields);
                       //next();
                    }}
                >
                    Next <RightCircleOutlined />
                </Button>
            );
        } else {
            console.log('else loop');
            return (
                <Button
                    type="primary"
                    style={{ backgroundColor: '#1890ff', opacity: 1 }}
                    shape="round"
                    className='ml-2'
                   // disabled
                    onClick={(): void => {
                        setFields({ ...mapValues(formFields, () => true), viewOnly: formFields.viewOnly });
                        current === 1 && someRegulatoryQuestionAnswersAreYes(props.regulatoryQuestions)
                            ? goTo(7)
                            : next();
                    }}
                >
                    Next <RightCircleOutlined />
                </Button>
            );
        }
    };

    const navButtonSave = () => {
        return (
            <Button
                // style={{ marginLeft: 8, backgroundColor: '#1890ff', opacity: 1 }}
                //disabled
                onClick={(): void => {
                    save()
                }}
                shape="round"
                icon={<SaveOutlined/>}
                className={"ml-2"}
            >
                Save
            </Button>
        );
    };

    const submitAssessmentDisabled = () => {
        //console.log("Reason", currentDirectionalAssessmentReason);
        if (user_nri_ri === '') {
            return true;
        }
        if(user_nri_ri === 'FALSE' && system_nri_ri === true && (scope_change_question === '' || scope_change_question === undefined)) {
            return true;
        }

        if ((user_nri_ri === 'TRUE' && system_nri_ri === false) || (user_nri_ri === 'FALSE' && system_nri_ri === true)) {
            if (isEmpty(userAssessmentRationale)) {
                return true;
            }
        }
        //console.log("length:", productInformation.modalities.length);

        if (user_nri_ri === 'TRUE' && productInformation.modalities.length > 1) {
            const modalityInfo = productModalitySelections;
            for (const modality in modalityInfo) {
                if (modalityInfo[modality] === true) {
                    if (modality === 'vaccine' && vaccine_nri_ri === '') {
                        return true;
                    }

                    if (modality === 'smallMolecule' && smallmolecule_nri_ri === '') {
                        return true;
                    }

                    if (modality === 'biologic' && biologic_nri_ri === '') {
                        return true;
                    }
                }
            }
        }

        if (user_nri_ri === 'TRUE') {
            if (currentPlanningAssessmentType === '') {
                return true;
            }

            if (currentPlanningAssessmentType === 'Product Agnostic' && Object.keys(prat).length === 0) {
                return true;
            }

            if (currentPlanningAssessmentType === 'Directional' && currentDirectionalAssessmentReason === '') {
                return true;
            }

            if (currentDirectionalAssessmentReason === 'Other' && directionalDetails === '') {
                return true;
            }

            if (submission_req_details.length > 0) {
                for (const row of submission_req_details) {
                    if (row.modality.length === 0) {
                        return true;
                    }

                    if (row.product === '') {
                        return true;
                    }

                    if (row.sub_req === '') {
                        return true;
                    }

                }
            }
        }

        if (user_nri_ri === 'FALSE') {
            if (nriOption === '') {
                return true;
            }

            if (nriOption === 'Other' && rationaleAssessment === '') {
                return true;
            }
        }

        return false;
    }

    const submitAssessmentButton = () => {
        return (
            <Button
                className='ml-2'
                type="primary"
                disabled={submitAssessmentDisabled()}
                onClick={async (): Promise<void> => {
                    const updateSession = await props.updateSession();
                    saveCAKEAssessment()
                }}
                shape="round"
            >
                Finalize Assessment  <EditOutlined />
            </Button>
        );
    };


    return (
        <FormContext.Consumer>
            {({ setFormFields, ...formFields }) => (
                <>
                    <Footer className="App-footer">
                        {props.showFooter && (
                            <div style={{display: 'flex', marginLeft: '43%'}}>
                                {navButtonPrev()}
                                {current === 5 ? <div></div> : navButtonNext(formFields, setFormFields)}
                                {!formFields.viewOnly ? navButtonSave() : <div></div>}
                                {current === 5 && !formFields.viewOnly ? submitAssessmentButton() : <div></div>}
                                {current === 5 ? unlockPDFButton() : <div></div>}
                            </div>
                        )}
                    </Footer>
                </>
            )}
        </FormContext.Consumer>
    );
};
