import { Select, Tag, Row, Col, Typography } from 'antd';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import React from 'react';
import { Matchable, ProductEvents, ProductModality, ColorTag } from '../model';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';
import find from 'lodash/find';

const { Option, OptGroup } = Select;
const { Text } = Typography;

export interface ProductsModalitiesState {
    loading: boolean;
}
export interface ProductsModalitiesProps {
    productsAvailable: Array<ProductModality>;
    impactedProducts: Matchable<ProductModality>;
    productEvents?: ProductEvents;
    productTypeSelection: any;
    productColorTags: ColorTag;
}

export class ProductsModalities extends React.Component<ProductsModalitiesProps, ProductsModalitiesState> {
    comparitor = (a: ProductModality, b: ProductModality): boolean => {
        return a.familyName === b.familyName;
    };
    constructor(props: ProductsModalitiesProps) {
        super(props);
        this.state = {
            loading: true,
        };
    }
    componentDidMount() {
        this.buildMasterList(this.props.productsAvailable);
        this.setState({ loading: false });
    }

    buildMasterList = (products: Array<ProductModality>): Array<JSX.Element> => {
        const selection = this.props.productTypeSelection;
        // FamilyName list
        const familyName: Array<any> = [];
        products.forEach((product: ProductModality) => {
            const index = familyName.findIndex(obj => obj.familyName == product.familyName);
            if (index <= -1 && product.familyName !== 'n/a') {
                familyName.push({
                    familyName: product.familyName,
                    genericName: product.genericName,
                    mkNumber: product.mkNumber,
                    modality: product.modality,
                });
            }
        });
        familyName.sort(function(a, b) {
            if (a.familyName.toLowerCase() < b.familyName.toLowerCase()) {
                return -1;
            }
            if (a.familyName.toLowerCase() > b.familyName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        // Generic Name list
        const genericName: Array<any> = [];
        products.forEach((product: ProductModality) => {
            const index = genericName.findIndex(obj => obj.genericName == product.genericName);
            if (index <= -1 && product.genericName !== 'n/a') {
                genericName.push({
                    familyName: product.familyName,
                    genericName: product.genericName,
                    mkNumber: product.mkNumber,
                    modality: product.modality,
                });
            }
        });
        // Sort generic name list
        genericName.sort(function(a, b) {
            if (a.genericName.toLowerCase() < b.genericName.toLowerCase()) {
                return -1;
            }
            if (a.genericName.toLowerCase() > b.genericName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        // MK # list
        const mkNumber: Array<any> = [];
        products.forEach((product: ProductModality) => {
            const index = mkNumber.findIndex(obj => obj.mkNumber == product.mkNumber);
            if (index <= -1 && product.mkNumber !== 'n/a') {
                mkNumber.push({
                    familyName: product.familyName,
                    genericName: product.genericName,
                    mkNumber: product.mkNumber,
                    modality: product.modality,
                });
            }
        });
        mkNumber.sort(function(a, b) {
            if (a.mkNumber.toLowerCase() < b.mkNumber.toLowerCase()) {
                return -1;
            }
            if (a.mkNumber.toLowerCase() > b.mkNumber.toLowerCase()) {
                return 1;
            }
            return 0;
        });

        let productsSelection: Array<JSX.Element>;

        if (selection === 'mkNumber') {
            productsSelection = mkNumber.map((product: ProductModality) => {
                return (
                    <Option value={product.mkNumber} key={JSON.stringify(product)}>
                        {`${product.mkNumber} (${product.modality !== 'n/a' ? product.modality : 'Unknown'})`}
                    </Option>
                );
            });
        } else if (selection === 'genericName') {
            productsSelection = genericName.map((product: ProductModality) => {
                return (
                    <Option value={product.genericName} key={JSON.stringify(product)}>
                        {`${product.genericName} (${product.modality !== 'n/a' ? product.modality : 'Unknown'})`}
                    </Option>
                );
            });
        } else {
            // default selection
            productsSelection = familyName.map((product: ProductModality) => {
                return (
                    <Option value={product.familyName} key={JSON.stringify(product)}>
                        {`${product.familyName} (${product.modality !== 'n/a' ? product.modality : 'Unknown'})`}
                    </Option>
                );
            });
        }

        return productsSelection;
    };

    defaultValues = (products: Array<ProductModality>): any => {
        return products.map((product: ProductModality) => {
            // Product selection by Trade Name, Generic Name, or MK
            if (!product.productTypeSelection) {
                return { key: product.familyName, label: `${product.familyName} (${product.modality})` };
            } else {
                switch (product.productTypeSelection) {
                    case '':
                        return { key: product.mkNumber, label: `${product.familyName} (${product.modality})` };
                    case 'tradeName':
                        return { key: product.mkNumber, label: `${product.familyName} (${product.modality})` };
                    case 'genericName':
                        return { key: product.mkNumber, label: `${product.genericName} (${product.modality})` };
                    case 'mkNumber':
                        return { key: product.mkNumber, label: `${product.mkNumber} (${product.modality})` };
                }
            }
        });
    };

    generateRedTags = (productColorTags: ColorTag) => {
        return Object.keys(productColorTags).reduce((tags: Array<JSX.Element>, key: string) => {
            if ((key === 'initialRemoved' && typeof productColorTags[key] !== 'boolean') || key === 'unmatched') {
                const productArr = productColorTags[key] as Array<ProductModality>;

                tags.push(
                    ...productArr.reduce((jsx: Array<JSX.Element>, product: ProductModality) => {
                        if (!product.productTypeSelection) {
                            jsx.push(
                                <Tag key={product.familyName} color="red">
                                    <Text delete={key === 'initialRemoved' ? true : false}>
                                        {product.modality !== ''
                                            ? `${product.familyName} (${product.modality})`
                                            : product.familyName}
                                    </Text>
                                </Tag>,
                            );
                        } else {
                            switch (product.productTypeSelection) {
                                case '':
                                case 'tradeName':
                                    jsx.push(
                                        <Tag key={product.familyName} color="red">
                                            <Text delete={key === 'initialRemoved' ? true : false}>
                                                {product.modality !== ''
                                                    ? `${product.familyName} (${product.modality})`
                                                    : product.familyName}
                                            </Text>
                                        </Tag>,
                                    );
                                    break;
                                case 'genericName':
                                    jsx.push(
                                        <Tag key={product.familyName} color="red">
                                            <Text delete={key === 'initialRemoved' ? true : false}>
                                                {product.modality !== ''
                                                    ? `${product.genericName} (${product.modality})`
                                                    : product.genericName}
                                            </Text>
                                        </Tag>,
                                    );
                                    break;
                                case 'mkNumber':
                                    jsx.push(
                                        <Tag key={product.familyName} color="red">
                                            <Text delete={key === 'initialRemoved' ? true : false}>
                                                {product.modality !== ''
                                                    ? `${product.mkNumber} (${product.modality})`
                                                    : product.mkNumber}
                                            </Text>
                                        </Tag>,
                                    );
                                    break;
                            }
                        }
                        return jsx;
                    }, []),
                );
            }
            return tags;
        }, []);
    };

    getTagColor = (product: ProductModality) => {
        if (
            !product.productTypeSelection ||
            product.productTypeSelection === '' ||
            product.productTypeSelection === 'tradeName'
        ) {
            return find(this.props.productColorTags.initiallySelected as Array<ProductModality>, [
                'familyName',
                product.familyName,
            ])
                ? 'default'
                : 'green';
        } else if (product.productTypeSelection === 'genericName') {
            return find(this.props.productColorTags.initiallySelected as Array<ProductModality>, [
                'genericName',
                product.genericName,
            ])
                ? 'default'
                : 'green';
        } else if (product.productTypeSelection === 'mkNumber') {
            return find(this.props.productColorTags.initiallySelected as Array<ProductModality>, [
                'mkNumber',
                product.mkNumber,
            ])
                ? 'default'
                : 'green';
        } else {
            return 'green';
        }
    };

    tagRender = (tagProps: CustomTagProps) => {
        const { label, value, closable, onClose } = tagProps;

        let color: string;
        const product = find(
            this.props.impactedProducts.selected,
            (impProduct: ProductModality) =>
                impProduct.familyName === value || impProduct.genericName === value || impProduct.mkNumber === value,
        );
        if (product && typeof this.props.productColorTags.initiallySelected !== 'boolean') {
            color = this.getTagColor(product);
            return (
                <Tag color={color} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                    {label}
                </Tag>
            );
        } else {
            return (
                <Tag color="default" closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                    {label}
                </Tag>
            );
        }
    };

    render(): JSX.Element {
        const { impactedProducts } = this.props;
        const selectProps: any = {};
        const selectedPoducts = this.defaultValues(impactedProducts.selected);
        if (this.props.productEvents) {
            selectProps.onDeselect = this.props.productEvents.onDeselect;
            selectProps.onSelect = this.props.productEvents.onSelect;
        } else {
            selectProps.disabled = true;
        }

        return (
            <FormContext.Consumer>
                {({ productsSelected, viewOnly }) => (
                    <div className={viewOnly ? 'view-only' : ''}>
                        {!productsSelected ? (
                            <Row>
                                <Col span={4}></Col>
                                <Col span={16}>
                                    <FormValidationErrorText text="Please select a product" />
                                </Col>
                                <Col span={4}></Col>
                            </Row>
                        ) : null}
                        <Row
                            style={
                                (typeof this.props.productColorTags.initialRemoved !== 'boolean' &&
                                    this.props.productColorTags.initialRemoved.length > 0) ||
                                this.props.productColorTags.unmatched.length > 0
                                    ? { paddingTop: '8px' }
                                    : {}
                            }
                        >
                            <Col offset={4}>{this.generateRedTags(this.props.productColorTags)}</Col>
                        </Row>
                        <Row style={!productsSelected ? {} : { paddingTop: '8px' }}>
                            <Col span={4}></Col>
                            <Col span={16}>
                                <Select
                                    loading={this.state.loading}
                                    mode="multiple"
                                    size={'middle'}
                                    placeholder="Please select"
                                    labelInValue={true}
                                    defaultValue={selectedPoducts}
                                    onDeselect={this.props.productEvents?.onDeselect}
                                    onSelect={this.props.productEvents?.onSelect}
                                    style={{ width: '100%' }}
                                    autoClearSearchValue={false}
                                    className={productsSelected ? '' : 'select-highlight'}
                                    tagRender={this.tagRender}
                                    value={selectedPoducts}
                                >
                                    <OptGroup label="Biologic">
                                        {this.buildMasterList(
                                            this.props.productsAvailable.filter(
                                                (product: ProductModality) => product.modality === 'B',
                                            ),
                                        )}
                                    </OptGroup>
                                    <OptGroup label="Small Molecule">
                                        {this.buildMasterList(
                                            this.props.productsAvailable.filter(
                                                (product: ProductModality) => product.modality === 'SM',
                                            ),
                                        )}
                                    </OptGroup>
                                    <OptGroup label="Vaccine">
                                        {this.buildMasterList(
                                            this.props.productsAvailable.filter(
                                                (product: ProductModality) => product.modality === 'V',
                                            ),
                                        )}
                                    </OptGroup>
                                    <OptGroup label="Unknown">
                                        {this.buildMasterList(
                                            this.props.productsAvailable.filter(
                                                (product: ProductModality) => product.modality === 'n/a',
                                            ),
                                        )}
                                    </OptGroup>
                                </Select>
                            </Col>
                            <Col span={4}></Col>
                        </Row>
                    </div>
                )}
            </FormContext.Consumer>
        );
    }
}
