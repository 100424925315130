import { Card, Col, Row, Typography, Input, Upload } from 'antd';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import React from 'react';
const { Text } = Typography;
const { Dragger } = Upload;

export interface CPWSHeaderProps {
    cpwsId: number;
    title: string;
    Uprops: any;
}
export const CPWSHeaderImportData: React.FC<CPWSHeaderProps> = (props: CPWSHeaderProps) => {
    const { cpwsId, title, Uprops } = props;
    const realTitle = title ? title : 'No data was loaded. Please upload a CPWS file.';

    return (
        <div>
            <Card bordered={false} key={`CPWScard${cpwsId}2`}>
                <Row gutter={4}>
                    <Col
                        span={6}
                        style={{
                            background: '#fafafa',
                            padding: '16px',
                            borderColor: '#d9d9d9',
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                            borderRight: '0',
                        }}
                    >
                        <Text strong>{`CPWS ID:  ${cpwsId}`}</Text>
                        <Input></Input>
                    </Col>
                    <Col
                        span={6}
                        style={{
                            background: '#fafafa',
                            padding: '16px',
                            borderColor: '#d9d9d9',
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                            borderLeft: '0',
                        }}
                    >
                        <Text style={{ fontSize: '20' }}>Click or Drag a local file </Text>

                        <Dragger className="ant-upload" style={{ height: '25%' }} action="#" {...Uprops}>
                            <InboxOutlined />
                        </Dragger>
                    </Col>

                    <Col
                        span={12}
                        style={{
                            padding: '16px',
                            borderColor: '#d9d9d9',
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                        }}
                    >
                        <Text>{realTitle}</Text>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

CPWSHeaderImportData.defaultProps = { cpwsId: 0, title: 'no data' };
