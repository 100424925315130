import { Card, Collapse, List, Typography, Spin, Popover, Table, Row, Button, Col, Tag, Modal, Transfer } from 'antd';
import React from 'react';
import { Country, MarketSiteFunction, PanelProps, Site, SiteFunction } from '../model';
import { sortObjectArrayByValue } from '../utils';
import find from 'lodash/find';
import { isEqual } from 'lodash';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';
import { PDSSiteFunctionSelect, PDSSiteSelect } from '.';
import { DiffOutlined, DiffTwoTone, FileAddOutlined, SwitcherOutlined, SwitcherTwoTone } from '@ant-design/icons';
const { Panel } = Collapse;
const { Text, Title } = Typography;

interface KeyedCountry extends Country {
    key: string;
}
export interface CountryListProps {
    countries: Array<Country>;
    panelProps: PanelProps;
    loading: boolean;
    sites: Array<Site>;
    updateCountries: Function;
    resetPDS: Function;
    //header: string;
    impactedSites: Array<Site>;
    siteFunctions: Array<SiteFunction>;
    onFunctionSelect: Function;
    onFunctionDeselect: Function;
    onSiteSelect: Function;
    onSiteDeselect: Function;
    siteFunctionRefs: Array<{
        name: string;
        ref: React.RefObject<HTMLDivElement>;
    }>;
    siteFunctionCardRef: React.RefObject<HTMLDivElement>;
}

interface CountryListState {
    isModalVisible: boolean;
    inScopeMarkets: Array<string>;
    allMarkets: Array<KeyedCountry>;
}

interface LegendData {
    color: string;
    description: string;
    render: JSX.Element;
}

const columns = [
    {
        title: 'Site',
        dataIndex: 'site',
    },
    {
        title: 'Function',
        dataIndex: 'function',
    },
    {
        title: 'Status',
        dataIndex: 'status',
    },
];

const legendColumns = [
    {
        title: 'Color',
        dataIndex: 'color',
        render: (_: string, record: LegendData) => record.render,
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
];

const legendData: Array<LegendData> = [
    {
        color: 'red',
        description: "Market includes site/site function which is in 'Pending Deletion' status",
        //render: <Text style={{ color: '#ff0308' }}>Market</Text>,
        render: <SwitcherOutlined style={{ color: '#ff0308', fontSize: 'xx-large' }} />
    },
    {
        color: 'blue',
        description: "Market includes site/site function which is in 'Pending Approval' status",
        //render: <Text style={{ color: '#1890ff' }}>Market</Text>,
        render: <FileAddOutlined style={{ color: '#1890ff', fontSize: 'xx-large' }} />
    },
    {
        color: 'yellow',
        description:
            "Market includes site/site functions which have both 'Pending Approval' and 'Pending Deletion' status",
        // render: <Text style={{ color: '#f2ca53' }}>Market</Text>,
        render: <DiffOutlined style={{ color: '#f2ca53', fontSize: 'xx-large' }} />
    },
    {
        color: 'backgroundGreen',
        description: 'Manually added market',
        render: (
            <Tag closable={false} color="green">
                <Text>Market</Text>
            </Tag>
        ),
    },
    {
        color: 'backgroundRed',
        description: 'Market included in PDS filter, but removed by user',
        render: (
            <Tag closable={false} color="red">
                <Text>Market</Text>
            </Tag>
        ),
    },
];

export class CountryList extends React.Component<CountryListProps, CountryListState> {
    constructor(props: CountryListProps) {
        super(props);
        this.state = {
            isModalVisible: false,
            inScopeMarkets: [],
            allMarkets: [],
        };
    }
    componentDidMount() {
        this.setState({
            allMarkets: this.props.countries.map((country: Country) => ({ ...country, key: country.name })),
            inScopeMarkets: this.props.countries
                .reduce((countryNames: Array<string>, country: Country) => {
                    if (
                        (country.pdsFlag &&
                            Object.prototype.hasOwnProperty.call(country, 'manuallyAdded') &&
                            country.manuallyAdded) ||
                        (country.pdsFlag && !Object.prototype.hasOwnProperty.call(country, 'manuallyAdded')) ||
                        country.manuallyAdded
                    ) {
                        countryNames.push(country.name);
                    }
                    return countryNames;
                }, [])
                .sort(),
        });
    }

    componentDidUpdate(prevProps: CountryListProps) {
        if (!isEqual(prevProps.countries, this.props.countries) || !isEqual(prevProps.sites, this.props.sites)) {
            this.setState({
                allMarkets: this.props.countries.map((country: Country) => ({ ...country, key: country.name })),
                inScopeMarkets: this.props.countries
                    .reduce((countryNames: Array<string>, country: Country) => {
                        if (
                            (country.pdsFlag &&
                                Object.prototype.hasOwnProperty.call(country, 'manuallyAdded') &&
                                country.manuallyAdded) ||
                            (country.pdsFlag && !Object.prototype.hasOwnProperty.call(country, 'manuallyAdded')) ||
                            country.manuallyAdded
                        ) {
                            countryNames.push(country.name);
                        }
                        return countryNames;
                    }, [])
                    .sort(),
            });
        }
    }

    handleTransfer(targetKeys: Array<string>) {
        this.setState({
            inScopeMarkets: targetKeys
                .reduce((inScopeNames: Array<string>, key: string) => {
                    const market = find(this.props.countries, ['name', key]);
                    if (market) {
                        inScopeNames.push(market.name);
                    }
                    return inScopeNames;
                }, [])
                .sort(),
        });
    }

    handleOk() {
        const newCountries = this.props.countries.map((country: Country) => {
            if (this.state.inScopeMarkets.includes(country.name) && !country.pdsFlag) {
                return {
                    ...country,
                    manuallyAdded: true,
                };
            } else if (
                (country.manuallyAdded || country.pdsFlag) &&
                !this.state.inScopeMarkets.includes(country.name)
            ) {
                return {
                    ...country,
                    manuallyAdded: false,
                };
            } else {
                return country;
            }
        });

        this.props.updateCountries(newCountries);
        this.setState({
            isModalVisible: false,
        });
    }

    validateScopeMarketEmpty() {
        const tmpArr = this.props.countries.filter(
            (country: Country) =>
                (country.pdsFlag &&
                    Object.prototype.hasOwnProperty.call(
                        country,
                        'manuallyAdded',
                    ) &&
                    country.manuallyAdded) ||
                (country.pdsFlag &&
                    !Object.prototype.hasOwnProperty.call(
                        country,
                        'manuallyAdded',
                    )) ||
                country.manuallyAdded,
        );        
        //console.log(tmpArr);
        if(tmpArr.length > 0) { return false} else {return true};
    }

    resetMarkets() {
        /**
         * 1. Remove all site/site functions
         * 2. Set all manually added to false.
         * 3. This should result in all markets being shown in change scope.
         */
        this.props.resetPDS();
        this.props.updateCountries(
            this.props.countries.map((country: Country) => {
                //Removing site functions property
                // eslint-disable-next-line
                const { siteFunctions, ...rest } = country;
                if (Object.prototype.hasOwnProperty.call(country, 'manuallyAdded')) {
                    return {
                        ...rest,
                        manuallyAdded: false,
                        pdsFlag: false,
                    };
                } else {
                    return {
                        ...rest,
                        pdsFlag: false,
                    };
                }
            }),
        );
    }

    renderInScopeMarket(item: Country) {
        const content = item.siteFunctions ? (
            <Table dataSource={item.siteFunctions} columns={columns} size="small" pagination={false} />
        ) : item.pdsFlag && !Object.prototype.hasOwnProperty.call(item, 'siteFunctions') ? (
            <Text>Unknown approval status for PDS site and site functions. </Text>
        ) : (
            <Text>Market is not a part of PDS Site filter.</Text>
        );
        const title = item.siteFunctions ? <Title level={5}>Approval status by Site and Site Function</Title> : '';
        const color =
            item.siteFunctions &&
                item.siteFunctions.some((marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Approval') &&
                item.siteFunctions.some((marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Deletion')
                ? '#f2ca53'
                : item.siteFunctions &&
                    item.siteFunctions.some(
                        (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Approval',
                    ) &&
                    !item.siteFunctions.some(
                        (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Deletion',
                    )
                    ? '#1890ff'
                    : item.siteFunctions &&
                        !item.siteFunctions.some(
                            (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Approval',
                        ) &&
                        item.siteFunctions.some(
                            (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Deletion',
                        )
                        ? '#ff0308'
                        : 'black';
        const icon =
            item.siteFunctions &&
                item.siteFunctions.some((marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Approval') &&
                item.siteFunctions.some((marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Deletion')
                ? <DiffOutlined style={{ color: '#f2ca53', fontSize: 'small' }} />
                : item.siteFunctions &&
                    item.siteFunctions.some(
                        (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Approval',
                    ) &&
                    !item.siteFunctions.some(
                        (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Deletion',
                    )
                    ? <FileAddOutlined style={{ color: '#1890ff', fontSize: 'small' }} />
                    : item.siteFunctions &&
                        !item.siteFunctions.some(
                            (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Approval',
                        ) &&
                        item.siteFunctions.some(
                            (marketFunction: MarketSiteFunction) => marketFunction.status === 'Pending Deletion',
                        )
                        ? <SwitcherOutlined style={{ color: '#ff0308', fontSize: 'small' }} />
                        : '';

        return (
            <Popover content={content} title={title} placement={'topLeft'}>
                <List.Item>
                    {item.manuallyAdded && !item.pdsFlag ? (
                        <Tag closable={false} color="green">
                            <Text>{item.name}</Text>
                        </Tag>
                    ) : (
                        <div>
                            {icon}
                            <Text
                                style={{
                                    color: 'black',
                                }}
                            >
                                {item.name}
                            </Text>
                        </div>
                    )}
                </List.Item>
            </Popover>
        );
    }

    renderOutOfScopeMarket(item: Country) {
        return (
            <List.Item>
                {item.pdsFlag && Object.prototype.hasOwnProperty.call(item, 'manuallyAdded') && !item.manuallyAdded ? (
                    <Tag closable={false} color="red">
                        <Text>{item.name}</Text>
                    </Tag>
                ) : (
                    <Text style={{ color: '#d9d9d9' }}>{item.name}</Text>
                )}
            </List.Item>
        );
    }

    renderLegend() {
        return (
            <Table
                dataSource={legendData}
                columns={legendColumns}
                pagination={false}
                style={{ maxWidth: '500px' }}
                rowKey={
                    '_' +
                    Math.random()
                        .toString(36)
                        .substr(2, 9)
                }
                bordered
            />
        );
    }

    renderPanelButtons() {
        return (
            <FormContext.Consumer>
                {({ viewOnly }) => (
                    <Row gutter={8}>
                        <Col>
                            <Button
                                onClick={e => {
                                    e.stopPropagation();
                                    this.setState({ isModalVisible: true });
                                }}
                                disabled={viewOnly}
                            >
                                Override
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    this.resetMarkets();
                                }}
                                disabled={viewOnly}
                            >
                                Reset
                            </Button>
                        </Col>
                        <Col>
                            <Popover content={this.renderLegend()} trigger="click" placement="bottomLeft">
                                <Button
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    Legend
                                </Button>
                            </Popover>
                        </Col>
                    </Row>
                )}
            </FormContext.Consumer>
        );
    }

    render(): JSX.Element {
        const {
            panelProps,
            impactedSites,
            siteFunctions,
            onFunctionSelect,
            onFunctionDeselect,
            onSiteSelect,
            onSiteDeselect,
            siteFunctionRefs,
            siteFunctionCardRef,
        } = this.props;
        const generatePDSSites = (siteFunctions: Array<SiteFunction>, impactedSites: Array<Site>) => {
            return (
                <>
                    <Col span={4}></Col>
                    <Col span={4} style={{ paddingBottom: '16px', display: 'flex', alignItems: 'center' }}>
                        <Text>PDS Sites:</Text>
                    </Col>
                    <Col span={12} style={{ paddingBottom: '16px', display: 'flex', alignItems: 'center' }}>
                        <PDSSiteSelect
                            onSiteDeselect={onSiteDeselect}
                            onSiteSelect={onSiteSelect}
                            impactedSites={impactedSites}
                            siteFunctions={siteFunctions}
                        />
                    </Col>
                    <Col span={4}></Col>
                </>
            );
        };

        const generateSiteFunctions = (impactedSites: Array<Site>, siteFunctions: Array<SiteFunction>) => {
            return impactedSites.reduce((sites: Array<JSX.Element>, site: Site) => {
                if (site.isPDS) {
                    sites.push(
                        <PDSSiteFunctionSelect
                            site={site}
                            siteFunctions={siteFunctions}
                            onSiteFunctionSelect={onFunctionSelect}
                            onSiteFunctionDeselect={onFunctionDeselect}
                            key={site.name}
                            siteFunctionRefs={siteFunctionRefs}
                        />,
                    );
                }
                return sites;
            }, []);
        };

        return (
            <div ref={siteFunctionCardRef}>
                <Card bordered={false}>
                    <Collapse {...panelProps}>
                        <Panel header="Where Registered" key="1" extra={this.renderPanelButtons()}>
                        <Spin spinning={this.props.loading}>
                        <FormContext.Consumer>
                                {({ viewOnly }) => (
                                    <>
                                        <Row gutter={16} className={viewOnly ? 'view-only' : ''}>
                                            {generatePDSSites(siteFunctions, impactedSites)}
                                        </Row>
                                        {generateSiteFunctions(impactedSites, siteFunctions)}
                                    </>
                                )}
                        </FormContext.Consumer>
                        </Spin>
                        <FormContext.Consumer>
                            {({ inScopeMarketNotEmpty, viewOnly }) => (
                                <div>
                                    {}                              
                                    {!inScopeMarketNotEmpty && !viewOnly && this.validateScopeMarketEmpty() ? (
                                        <FormValidationErrorText text="Markets in change scope should not be empty." />
                                    ) : null}
                                </div>
                            )}                            
                        </FormContext.Consumer>
                        
                            {this.props.countries.some(
                                (country: Country) => country.pdsFlag || country.manuallyAdded,
                            ) ? (
                                <>
                                    <List
                                        loading={this.props.loading}
                                        grid={{ gutter: 3, column: 6 }}
                                        dataSource={this.props.countries
                                            .filter(
                                                (country: Country) =>
                                                    (country.pdsFlag &&
                                                        Object.prototype.hasOwnProperty.call(
                                                            country,
                                                            'manuallyAdded',
                                                        ) &&
                                                        country.manuallyAdded) ||
                                                    (country.pdsFlag &&
                                                        !Object.prototype.hasOwnProperty.call(
                                                            country,
                                                            'manuallyAdded',
                                                        )) ||
                                                    country.manuallyAdded,
                                            )
                                            .sort((a: Country, b: Country) => {
                                                if (a.name < b.name) {
                                                    return -1;
                                                }
                                                if (a.name > b.name) {
                                                    return 1;
                                                }
                                                return 0;
                                            })}
                                        renderItem={item => this.renderInScopeMarket(item)}
                                        header={<Text strong>Markets in change scope</Text>}
                                    ></List>
                                    <List
                                        loading={this.props.loading}
                                        grid={{ gutter: 3, column: 6 }}
                                        dataSource={this.props.countries
                                            .filter(
                                                (country: Country) =>
                                                    (country.pdsFlag &&
                                                        Object.prototype.hasOwnProperty.call(
                                                            country,
                                                            'manuallyAdded',
                                                        ) &&
                                                        !country.manuallyAdded) ||
                                                    (!country.pdsFlag &&
                                                        !Object.prototype.hasOwnProperty.call(
                                                            country,
                                                            'manuallyAdded',
                                                        )) ||
                                                    (!country.pdsFlag &&
                                                        Object.prototype.hasOwnProperty.call(
                                                            country,
                                                            'manuallyAdded',
                                                        ) &&
                                                        !country.manuallyAdded),
                                            )
                                            .sort((a: Country, b: Country) => {
                                                if (a.name < b.name) {
                                                    return -1;
                                                }
                                                if (a.name > b.name) {
                                                    return 1;
                                                }
                                                return 0;
                                            })}
                                        renderItem={item => this.renderOutOfScopeMarket(item)}
                                        header={<Text strong>Markets out of change scope</Text>}
                                    ></List>
                                </>
                            ) : (
                                <List
                                    loading={this.props.loading}
                                    grid={{ gutter: 3, column: 6 }}
                                    dataSource={this.props.countries.sort((a: Country, b: Country) => {
                                        if (a.name < b.name) {
                                            return -1;
                                        }
                                        if (a.name > b.name) {
                                            return 1;
                                        }
                                        return 0;
                                    })}
                                    renderItem={item => this.renderInScopeMarket(item)}
                                    header={<Text strong>Markets in change scope</Text>}
                                ></List>
                            )}
                        </Panel>
                    </Collapse>
                </Card>
                <Modal
                    title={
                        <Title level={4} style={{ margin: '0 auto', display: 'table' }}>
                            Manual Market Override
                        </Title>
                    }
                    visible={this.state.isModalVisible}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.setState({ isModalVisible: false })}
                    closable={false}
                    width={700}
                    maskClosable={false}
                >
                    <Transfer
                        dataSource={sortObjectArrayByValue(this.state.allMarkets, 'name')}
                        titles={['Out of Scope', 'In Scope']}
                        showSearch
                        listStyle={{
                            width: 300,
                            height: 350,
                        }}
                        filterOption={(input: string, record: Country) =>
                            record.name.toLocaleLowerCase().includes(input.toLocaleLowerCase())
                        }
                        targetKeys={this.state.inScopeMarkets}
                        onChange={(targetKeys: Array<string>) => this.handleTransfer(targetKeys)}
                        render={item => item.name}
                    />
                </Modal>
            </div>
        );
    }
}
