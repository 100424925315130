import { Checkbox, Radio, Row, Col } from 'antd';
import React from 'react';
import { ChildQuestion } from '../model';
import { RadioChangeEvent } from 'antd/lib/radio';

export interface MatchCriteriaQuestionsSelectionsProps {
    childQuestions?: Array<ChildQuestion>;
    parentCode: string;
    childCode?: string;
    onSelection: Function;
    selection: Array<string> | string | boolean;
    viewOnly?: boolean;
    radioDisabled?: boolean;
    isTopLevelQuestion?: Function;
}

export const MatchCriteriaQuestionsSelections: React.FC<MatchCriteriaQuestionsSelectionsProps> = (
    props: MatchCriteriaQuestionsSelectionsProps,
) => {
    const {
        childQuestions,
        parentCode,
        onSelection,
        selection,
        childCode,
        viewOnly,
        radioDisabled,
        isTopLevelQuestion,
    } = props;
    const [radioButtonSelection, setRadioButtonSelection] = React.useState<string | boolean>(
        typeof selection === 'boolean' || typeof selection === 'string' ? selection : '',
    );

    const [checkboxSelections, setCheckboxSelections] = React.useState<Array<string | number | boolean>>(
        typeof selection !== 'boolean' && typeof selection !== 'string' ? selection : [],
    );
    const [checkboxDisabled, setCheckboxDisabled] = React.useState<boolean>(
        typeof selection !== 'boolean' && typeof selection !== 'string' && selection.includes('N/A') ? true : false,
    );

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const getChildOptions = (childQuestions: Array<ChildQuestion>) => {
        return [
            ...childQuestions
                .reduce((answers: Array<string>, childQuestion: ChildQuestion) => {
                    if (
                        childQuestion.mtcDef !== childQuestion.parentDef &&
                        isTopLevelQuestion &&
                        !isTopLevelQuestion(childQuestion.mtcCode, childQuestion.materialTypeCode) &&
                        !answers.includes(childQuestion.parentAnswer)
                    ) {
                        answers.push(childQuestion.parentAnswer);
                    }
                    return answers;
                }, [])
                .sort(),
            'N/A',
        ];
    };

    const isMultiSelectQuestion = (childQuestions: Array<ChildQuestion>) =>
        childQuestions.every((childQuestion: ChildQuestion) => childQuestion.parentAnswer !== 'NO_TYPE');

    const checkboxSelectionMade = (checkedValues: Array<string | number | boolean>) => {
        if (checkedValues.includes('N/A')) {
            setCheckboxSelections(['N/A']);
            setCheckboxDisabled(true);
            onSelection(childCode ? childCode : parentCode, ['N/A']);
        } else {
            setCheckboxSelections(checkedValues);
            setCheckboxDisabled(false);
            onSelection(childCode ? childCode : parentCode, checkedValues);
        }
    };

    const radioButtonSelected = (e: RadioChangeEvent) => {
        setRadioButtonSelection(e.target.value);
        onSelection(childCode ? childCode : parentCode, e.target.value);
    };

    React.useEffect(() => {
        setRadioButtonSelection(typeof selection === 'boolean' || typeof selection === 'string' ? selection : '');
    }, [selection]);

    return (
        <>
            {childQuestions && childQuestions.length > 0 && isMultiSelectQuestion(childQuestions) ? (
                <Checkbox.Group
                    style={{ width: '100%' }}
                    onChange={checkboxSelectionMade}
                    value={checkboxSelections}
                    className={viewOnly ? 'view-only' : ''}
                >
                    <Row>
                        {getChildOptions(childQuestions).map((answer: string) => (
                            <Col span={24} key={answer}>
                                <Checkbox value={answer} disabled={answer !== 'N/A' ? checkboxDisabled : false}>
                                    {answer}
                                </Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            ) : (
                <Radio.Group
                    onChange={radioButtonSelected}
                    value={radioButtonSelection}
                    className={viewOnly ? 'view-only' : ''}
                    disabled={radioDisabled}
                >
                    <Radio style={radioStyle} value={true}>
                        Yes
                    </Radio>
                    <Radio style={radioStyle} value={false}>
                        No
                    </Radio>
                </Radio.Group>
            )}
        </>
    );
};
