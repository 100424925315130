import { Layout, notification, Steps, Spin, Button, Modal, Typography, Space, Result, Popover, Input } from 'antd';
import { ExclamationCircleOutlined, WarningOutlined, UserOutlined, CommentOutlined } from '@ant-design/icons';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import mapKeys from 'lodash/mapKeys';
import findKey from 'lodash/findKey';
import findIndex from 'lodash/findIndex';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import every from 'lodash/every';
import React from 'react';
import ApiClient from './api/apiClient';
import {
    mapExternalMaterialFromJSON,
    mapBasicInfoData,
    mapSupportingDetails,
    mapAdditionalInfoData,
    mapRelatedChangesData,
    mapEndorsementsData,
    mapProductInformation,
    mapSuppliers,
    mapMaterials,
    mapMethods,
    mapPartners,
    mapPlants,
    mapRegisteredMarkets,
    mapSoldMarkets,
    mapImpactAnalysis,
} from './api/apiMapper';
import './App.css';
import { StepFooter } from './components';
import Debug from './components/Debug';
import {
    ApiCall,
    AppOptions,
    AssessmentResult,
    Country,
    CPWS,
    CPWSBuilder,
    CPWSClassification,
    CPWSStateChange,
    GlobalProps,
    Logger,
    MatchCriteria,
    Material,
    ProductModality,
    Question,
    S3Properties,
    Site,
    ExternalMaterial,
    MaterialType,
    ContainerClosureMaterial,
    DownstreamMaterial,
    FormValidationFields,
    ProductModalitySelections,
    FormRefs,
    SiteFunction,
    MatchCriteriaAnswer,
    ColorTag,
    CPWSClassificationCodes,
    CPWSClassificationCode,
    ChildQuestion,
    MaterialCodeMapping,
    ImpactedMaterialsRef,
    ScrollToImpactedMaterial,
    ScrollToDownstreamMaterial,
    LegacyContainerClosureMaterial,
    ContainerClosureDownstreamMaterial,
    MaterialTypeCount,
    FinalAssessmentKeys,
    RegulatoryQuestionValue,
    DirectionalAssesmentResult,
    AssesmentMarkets,
    BasicInfo,
    SupportingDetails,
    AdditionalInfoData,
    RelatedChanges,
    Endorsements,
    ProductInformation,
    Suppliers,
    Materials,
    Methods,
    Partners,
    Plants,
    RegisteredMarkets,
    ImpactAnalysis,
    SelectedMaterials,
    ProductsInfo,
    DirectAssessment,
    DataRequirements,
    CommentDetails
} from './model';
import { Screen0, Screen1, Screen2, Screen3, Screen4, Screen5 } from './screens/screens';
import config from './config';
import { Auth, Hub } from 'aws-amplify';
import { RegulatoryQuestions } from './model/index';
import {
    someRegulatoryQuestionAnswersAreYes,
    filterByObjectKey,
    sortObjectArrayByValue,
    findObjectByValue,
    findObjectIndexByValue,
    getAppOptions,
    allRegulatoryQuestionsAnswered,
} from './utils';
import { v4 as uuidv4 } from 'uuid';
import { FormContext } from './context/FormContext';
import { LabeledValue } from 'antd/lib/select';
import { ErrorBoundaryContext } from './context/ErrorBoundaryContext';
import TextArea from 'antd/lib/input/TextArea';

const { Step } = Steps;
const { Content, Header } = Layout;
const { Text } = Typography;

const download = require('downloadjs');

interface StepProps {
    current: number;
    goTo: Function;
    highestAvailable: number;
    regulatoryQuestions: RegulatoryQuestions;
}

const emptyLogger: Logger = {
    setError: (): void => {
        return;
    },
    setCall: (): void => {
        return;
    },
    setException: (): void => {
        return;
    },
    errors: [],
    calls: [],
    exceptions: [],
};

function stateLogger(app: any): Logger {
    console.log("state Logger Called");
    return {
        setError: (error: ApiCall): void => {
            const logger = app.state.logger;
            logger.errors.push(error);
            app.setState({ logger });
        },
        setCall: (call: ApiCall): void => {
            const logger = app.state.logger;
            logger.calls.push(call);
            app.setState({ logger });
        },
        setException: (exception: any): void => {
            console.log('set Exception called');
            const logger = app.state.logger;
            logger.exceptions.push(exception);
            app.setState({ logger });
        },
        errors: [],
        calls: [],
        exceptions: [],
    };
}
function getLogger(app: any, options: AppOptions): Logger {
    if (options.debug) {
        return stateLogger(app);
    }
    return emptyLogger;
}
export interface AppState {
    current: number;
    cpws: CPWS;
    newExternalMaterials: Array<ContainerClosureMaterial>
    firstName: string;
    lastName: string;
    productsAvailable: Array<ProductModality>;
    options: AppOptions;
    impactedMaterialsAvailable: Array<Material>;
    impactedMaterialsAvailableOption: any;
    downstreamImpactedMaterialsAvailableOption: any;
    tab_num: string;
    visitedChangeClassification: boolean;
    comment_details: CommentDetails;
    impactedCountries: Array<Country>;
    downstreamMaterials: Array<DownstreamMaterial>;
    sitesAvailable: Array<Site>;
    s3props: S3Properties;
    appCacheFlags: AppCacheFlags;
    logger: Logger;
    productsSitesImpacted: { products: Array<any>; sites: Array<any> };
    downstreamProducts: Array<ProductModality>;
    downstreamSites: Array<Site>;
    classificationList: CPWSClassification;
    matchCriteria: MatchCriteria;
    assessmentResults: Array<AssessmentResult>;
    majorMarkets: AssesmentMarkets;
    secondaryMarkets: AssesmentMarkets;
    user: boolean;
    isAuthenticating: boolean;
    authState: any;
    comment: string;
    productTypeSelection: string;
    category: Array<string>;
    sterilization: Array<string>;
    impactedMaterialsExternal: Array<ExternalMaterial>;
    sendExternalMaterials: Array<any>;
    impactedMaterialType: Array<any>;
    qaQuestionAnswered: boolean;
    dpDsAnswered: boolean;
    sterileAnswered: boolean;
    status: any;
    prevStep: number;
    tokenDetected: boolean;
    isViewOnlyUser: boolean;
    tempID: any;
    matchCriteriaYesRegulation: Array<any>;
    cpwsCompleted: any;
    PDF: boolean;
    unlockPDF: boolean;
    regulatoryQuestions: RegulatoryQuestions;
    justification: string;
    formFields: FormValidationFields;
    productModalitySelections: ProductModalitySelections;
    screen3PageNumber: number;
    siteFunctions: Array<SiteFunction>;
    materialClassificationUuid: string;
    afterPDFSaveRefresh: boolean;
    matchCriteriaAnswers: MatchCriteriaAnswer;
    matchCriteriaKeyOrder: Array<string>;
    productColorTags: ColorTag;
    siteColorTags: ColorTag;
    classificationCodes: CPWSClassificationCodes;
    materialCodeMapping: MaterialCodeMapping;
    nriOption: string;
    rationaleAssessment: string;
    containerClosurePageNumber: {
        [key: string]: number;
    };
    expandedRowKeys: Array<string>;
    userAssessment: string;
    userAssessmentRationale: string;
    additionalNotes: string;
    cakeSuggestions: string;
    reviewer: string;
    lockedByUserFirstName: string;
    lockedByUserLastName: string;
    userCommentValue: string;
    commentModalVisibility: boolean;
    basicInfo: BasicInfo;
    supportingDetails: Array<SupportingDetails>;
    additionalInfoData: AdditionalInfoData;
    relatedChanges: Array<RelatedChanges>;
    endorsements: Endorsements;
    productInformation: ProductInformation;
    staticProductInformation: ProductInformation;
    directionalAssessment: DirectAssessment;
    dataRequirements: DataRequirements;
    marketLevelAssessment: object;
    suppliers: Array<Suppliers>;
    materials: Array<Materials>;
    methods: Array<Methods>;
    partners: Array<Partners>;
    plants: Array<Plants>;
    registeredMarkets: Array<RegisteredMarkets>;
    impactAnalysis: Array<ImpactAnalysis>;
    isGetRelatedChangesAPISucess: boolean;
    cake_status: string;
    user_nri_ri: string;
    system_nri_ri: boolean;
    biologic_nri_ri: string;
    vaccine_nri_ri: string;
    smallmolecule_nri_ri: string;
    scope_change_question: string;
    screen4_update: boolean;
    update_type: string;
    user_comment: string;
    impactedProductMaterials?: any;
    userSelectedMaterials: any;
    userSelectedExternalMaterials: Array<ExternalMaterial>;
    prat: object;
    submission_req_details: any;
    currentPlanningAssessmentType: string;
    currentDirectionalAssessmentReason: string;
    directionalDetails: string;
    assessments: [];
}
export interface AppCacheFlags {
    loadCpws?: boolean;
    loadImpactedMaterials?: boolean;
    loadProductsAvailable?: boolean;
    loadSitesAvailable?: boolean;
    loadAdditionalInfo?: boolean;
    loadClassificationList?: boolean;
    loadClassificationListResponse?: boolean;
    loadMatchCriteria?: boolean;
    loadProductsFamilySitesMaterial?: boolean;
    loadS3Props?: boolean;
    loadFinalResults?: boolean;
    highestAvailable: number;
}

export default class App extends React.Component<{}, AppState> {
    static contextType = ErrorBoundaryContext;
    api: ApiClient;
    globalProps: GlobalProps;
    private formRefs: FormRefs;
    constructor(props: any) {
        super(props);
        this.formRefs = {
            regulatoryQuestionsAnswered: React.createRef(),
            productsSelected: React.createRef(),
            sitesSelected: React.createRef(),
            justificationProvided: React.createRef(),
            cpwsQuestionsAnswered: React.createRef(),
            materialSelected: React.createRef(),
            qualitySelected: React.createRef(),
            materialTypeSelected: React.createRef(),
            siteFunctions: [],
            impactedMaterials: [],
            siteFunctionCardRef: React.createRef(),
            matchCriteriaCardRef: React.createRef(),
            containerClosureCardRef: React.createRef(),
            finalAssessmentRef: React.createRef(),
            formExternalMaterialsMissing: React.createRef(),
            downstreamMaterialTypeSelected: React.createRef(),
            downstreamMaterialType: React.createRef()
        };
        Hub.listen('auth', data => {
            switch (data.payload.event) {
                case 'signIn':
                    Promise.all([this.getFirstName(), this.getLastName()]).then((res) => {
                        this.setState({ firstName: res[0], lastName: res[1] });
                    })
                    this.setState({ user: true, isAuthenticating: false, authState: 'signedIn' });
                    break;
                case 'signOut':
                    this.setState({ user: false, authState: 'signIn' });
                    Auth.signOut();
                    // Auth.federatedSignIn();
                    break;
                case 'tokenRefresh_failure':
                    this.setState({ user: false, authState: 'signIn' });
                    Auth.signOut();
                    // Auth.federatedSignIn();
                    break;
                default:
                    break;
            }
        });
        this.state = {
            current: 1,
            cpws: new CPWSBuilder(),
            newExternalMaterials: [],
            firstName: '',
            lastName: '',
            productsAvailable: [],
            options: getAppOptions(),
            impactedMaterialsAvailable: [],
            impactedMaterialsAvailableOption: null,
            downstreamImpactedMaterialsAvailableOption: null,
            tab_num: "",
            visitedChangeClassification: false,
            comment_details: { screen1: "", screen2: "", screen3: "", screen4: "", screen5: ""},
            impactedCountries: [],
            downstreamMaterials: [],
            sitesAvailable: [],
            productsSitesImpacted: { products: [], sites: [] },
            s3props: {
                url: 'empty',
                key: 'empty',
                AWSAccessKeyId: 'empty',
                xamzsecuritytoken: 'empty',
                policy: 'empty',
                signature: 'empty',
            },
            logger: emptyLogger,
            appCacheFlags: {
                loadAdditionalInfo: false,
                loadImpactedMaterials: false,
                loadProductsAvailable: true,
                loadSitesAvailable: true,
                loadS3Props: true,
                loadCpws: true,
                loadProductsFamilySitesMaterial: true,
                loadClassificationList: true,
                loadClassificationListResponse: false,
                loadMatchCriteria: true,
                loadFinalResults: true,
                highestAvailable: 0,
            },
            downstreamProducts: [],
            downstreamSites: [],
            classificationList: {},
            matchCriteria: [],
            assessmentResults: [],
            majorMarkets: {
                priorApproval: [],
                notification: [],
                nri: [],
                incomplete: [],
                total: [],
            },
            secondaryMarkets: {
                priorApproval: [],
                notification: [],
                nri: [],
                incomplete: [],
                total: [],
            },     
            assessments: [],       
            directionalAssessment: {
                B: {
                    majorMarkets: {
                        priorApproval: [],
                        notification: [],
                        nri: [],
                        incomplete: [],
                        total: [],
                    },
                    secondaryMarkets: {
                        priorApproval: [],
                        notification: [],
                        nri: [],
                        incomplete: [],
                        total: [],
                    }
                },
                SM: {
                    majorMarkets: {
                        priorApproval: [],
                        notification: [],
                        nri: [],
                        incomplete: [],
                        total: [],
                    },
                    secondaryMarkets: {
                        priorApproval: [],
                        notification: [],
                        nri: [],
                        incomplete: [],
                        total: [],
                    }
                },
                V: {
                    majorMarkets: {
                        priorApproval: [],
                        notification: [],
                        nri: [],
                        incomplete: [],
                        total: [],
                    },
                    secondaryMarkets: {
                        priorApproval: [],
                        notification: [],
                        nri: [],
                        incomplete: [],
                        total: [],
                    }
                }
            },
            dataRequirements: {
                B: {                    
                        batch_analysis: [],
                        bioequivalence_study: [],
                        comparative_dissolution: [],
                        dp_intermediate_stability: [],
                        dp_stability: [],
                        ds_intermediate_stability: [],
                        ds_stability: [],
                        product_packaging_interaction: [],
                        transportation_storage_conditions: [],
                        validation: [],
                        change_types_without_data_requirements:[],
                        questions_without_data_requirements:[]
                },
                SM: {                    
                    batch_analysis: [],
                    bioequivalence_study: [],
                    comparative_dissolution: [],
                    dp_intermediate_stability: [],
                    dp_stability: [],
                    ds_intermediate_stability: [],
                    ds_stability: [],
                    product_packaging_interaction: [],
                    transportation_storage_conditions: [],
                    validation: [],
                    change_types_without_data_requirements:[],
                    questions_without_data_requirements:[]
                },
                V: {                    
                    batch_analysis: [],
                    bioequivalence_study: [],
                    comparative_dissolution: [],
                    dp_intermediate_stability: [],
                    dp_stability: [],
                    ds_intermediate_stability: [],
                    ds_stability: [],
                    product_packaging_interaction: [],
                    transportation_storage_conditions: [],
                    validation: [],
                    change_types_without_data_requirements:[],
                    questions_without_data_requirements:[]                 
                }
            },
            marketLevelAssessment: {},
            user: false,
            isAuthenticating: true,
            authState: 'loading',
            comment: '',
            productTypeSelection: '',
            category: [],
            sterilization: [],
            impactedMaterialsExternal: [],
            sendExternalMaterials: [],
            impactedMaterialType: [],
            qaQuestionAnswered: false,
            dpDsAnswered: false,
            sterileAnswered: false,
            status: '',
            prevStep: 1,
            tokenDetected: false,
            isViewOnlyUser: true,
            tempID: '',
            matchCriteriaYesRegulation: [],
            cpwsCompleted: 'not complete',
            PDF: false,
            unlockPDF: false,
            regulatoryQuestions: {
                question1: {
                    isAnswered: false,
                    answer: false,
                    children: {
                        childQuestion1: {
                            isAnswered: false,
                            answer: false,
                        },
                    },
                },
                question2: {
                    isAnswered: false,
                    answer: false,
                },
                question3: {
                    isAnswered: false,
                    answer: false,
                },
                question4: {
                    isAnswered: false,
                    answer: false,
                },
                question5: {
                    isAnswered: false,
                    answer: false,
                },
            },
            justification: '',
            formFields: {
                regulatoryQuestionsAnswered: true,
                productsSelected: true,
                sitesSelected: true,
                siteFunctionsSelected: true,
                inScopeMarketNotEmpty: true,
                justificationProvided: true,
                cpwsQuestionsAnswered: true,
                materialSelected: true,
                impactedMaterialsMissing: true,
                impactedMaterialsMissingWithYes: true,
                materialTypeSelected: true,
                downstreamMaterialTypeSelected: true,
                downstreamMaterialType: true,
                formExternalMaterialsMissing: true,
                qualitySelected: true,
                classificationSelected: true,
                matchCriteriaAnswered: true,
                finalAssessmentAnswered: true,
                viewOnly: false,
            },
            productModalitySelections: {
                biologic: false,
                smallMolecule: false,
                vaccine: false,
            },
            screen3PageNumber: 1,
            siteFunctions: [],
            containerClosurePageNumber: {},
            materialClassificationUuid: '',
            afterPDFSaveRefresh: false,
            matchCriteriaAnswers: {},
            matchCriteriaKeyOrder: [],
            productColorTags: {
                initiallySelected: [],
                unmatched: [],
                initialRemoved: [],
            },
            siteColorTags: {
                initiallySelected: [],
                unmatched: [],
                initialRemoved: [],
            },
            classificationCodes: [],
            materialCodeMapping: {},
            nriOption: '',
            rationaleAssessment: '',
            expandedRowKeys: [],
            userAssessment: '',
            userAssessmentRationale: '',
            additionalNotes: '',
            cakeSuggestions: '',
            reviewer: '',
            lockedByUserFirstName: '',
            lockedByUserLastName: '',
            basicInfo: {
                proposal_id: '',
                title: '',
                change_owner: '',
                change_origin: '',
                change_driver: '',
                site_global: '',
                site_functional_area: '',
                change_description: '',
            },
            supportingDetails: [],
            additionalInfoData: {
                additional_considerations: '',
                additional_considerations_summary: '',
                product_impact: '',
                product_impact_summary: '',
            },
            relatedChanges: [],
            endorsements: {
                endorsement_decision: '',
                endorser: '',
                summary_justification: '',
            },
            productInformation: { modalities: [], product_families: [], products: [] },
            staticProductInformation: { modalities: [], product_families: [], products: [] },
            suppliers: [],
            materials: [],
            methods: [],
            partners: [],
            plants: [],
            registeredMarkets: [],
            impactAnalysis: [],
            userCommentValue: '',
            commentModalVisibility: false,
            isGetRelatedChangesAPISucess: false,
            cake_status: '',
            user_nri_ri: '',
            system_nri_ri: false,
            biologic_nri_ri: '',
            vaccine_nri_ri: '',
            smallmolecule_nri_ri: '',
            scope_change_question: '',
            screen4_update: false,
            update_type: '',
            user_comment: '',
            userSelectedMaterials: [],
            userSelectedExternalMaterials: [],
            prat: {},
            submission_req_details: [],
            currentPlanningAssessmentType: '',
            currentDirectionalAssessmentReason: '',
            directionalDetails: ''
        };
        const logger = getLogger(this, this.state.options);

        this.api = new ApiClient(this.state.options, logger);
        this.globalProps = { defaultPanelProps: { defaultActiveKey: '1', expandIconPosition: 'right' } };
        this.componentCleanup = this.componentCleanup.bind(this);
        this.onMaterialSelection = this.onMaterialSelection.bind(this);
        this.setFormFields = this.setFormFields.bind(this);
    }

    // Reset app back to initial state on fresh CPWS file load
    reset(): Promise<void> {
        console.log("reset called");
        window.sessionStorage.setItem('asyncError', '');
        window.sessionStorage.setItem('ApiFlagError','');
        return new Promise(resolve => {
            this.setState(
                {
                    cpws: new CPWSBuilder(),
                    options: getAppOptions(),
                    productsSitesImpacted: { products: [], sites: [] },
                    appCacheFlags: {
                        loadAdditionalInfo: false,
                        loadImpactedMaterials: false,
                        loadProductsAvailable: true,
                        loadSitesAvailable: true,
                        loadS3Props: true,
                        loadCpws: true,
                        loadProductsFamilySitesMaterial: true,
                        loadClassificationList: true,
                        loadClassificationListResponse: false,
                        loadMatchCriteria: true,
                        loadFinalResults: true,
                        highestAvailable: 0,
                    },
                    classificationList: {},
                    matchCriteria: [],
                    assessmentResults: [],
                    majorMarkets: {
                        priorApproval: [],
                        notification: [],
                        nri: [],
                        incomplete: [],
                        total: [],
                    },
                    secondaryMarkets: {
                        priorApproval: [],
                        notification: [],
                        nri: [],
                        incomplete: [],
                        total: [],
                    },
                    user: false,
                    isAuthenticating: true,
                    comment: '',
                    category: [],
                    sterilization: [],
                    impactedMaterialsExternal: [],
                    sendExternalMaterials: [],
                    impactedMaterialType: [],
                    qaQuestionAnswered: false,
                    dpDsAnswered: false,
                    sterileAnswered: false,
                    status: '',
                    prevStep: 1,
                    //tempID: '',
                    matchCriteriaYesRegulation: [],
                    PDF: false,
                    unlockPDF: false,
                    regulatoryQuestions: {
                        question1: {
                            isAnswered: false,
                            answer: false,
                            children: {
                                childQuestion1: {
                                    isAnswered: false,
                                    answer: false,
                                },
                            },
                        },
                        question2: {
                            isAnswered: false,
                            answer: false,
                        },
                        question3: {
                            isAnswered: false,
                            answer: false,
                        },
                        question4: {
                            isAnswered: false,
                            answer: false,
                        },
                        question5: {
                            isAnswered: false,
                            answer: false,
                        },
                    },
                    justification: '',
                    formFields: { ...mapValues(this.state.formFields, () => true), viewOnly: false },
                    productModalitySelections: {
                        biologic: false,
                        smallMolecule: false,
                        vaccine: false,
                    },
                    screen3PageNumber: 1,
                    containerClosurePageNumber: {},
                    impactedMaterialsAvailable: [],
                    impactedMaterialsAvailableOption: null,
                    downstreamImpactedMaterialsAvailableOption: null,
                    tab_num: "",
                    visitedChangeClassification: false,
                    comment_details: { screen1: "", screen2: "", screen3: "", screen4: "", screen5: ""},
                    impactedCountries: [],
                    downstreamMaterials: [],
                    s3props: {
                        url: 'empty',
                        key: 'empty',
                        AWSAccessKeyId: 'empty',
                        xamzsecuritytoken: 'empty',
                        policy: 'empty',
                        signature: 'empty',
                    },
                    downstreamProducts: [],
                    downstreamSites: [],
                    productTypeSelection: '',
                    cpwsCompleted: 'not complete',
                    materialClassificationUuid: '',
                    matchCriteriaAnswers: {},
                    matchCriteriaKeyOrder: [],
                    productColorTags: {
                        initiallySelected: [],
                        unmatched: [],
                        initialRemoved: [],
                    },
                    siteColorTags: {
                        initiallySelected: [],
                        unmatched: [],
                        initialRemoved: [],
                    },
                    materialCodeMapping: {},
                    nriOption: '',
                    rationaleAssessment: '',
                    expandedRowKeys: [],
                    userAssessment: '',
                    userAssessmentRationale: '',
                    additionalNotes: '',
                    cakeSuggestions: '',
                    reviewer: '',
                    lockedByUserFirstName: '',
                    lockedByUserLastName: '',
                    update_type: '',
                    user_comment: '',
                    user_nri_ri: '',
                    vaccine_nri_ri: '',
                    biologic_nri_ri: '',
                    smallmolecule_nri_ri: '',
                    scope_change_question: '',
                    userSelectedMaterials: [],
                    prat: {},
                    submission_req_details: [],
                    currentPlanningAssessmentType: '',
                    currentDirectionalAssessmentReason: ''
                },
                () => resolve(),
            );
        });
    }

    //step functions (get it?)
    next: any = (cmd: any): void => {
        this.setState({
            current: this.state.current + 1,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                highestAvailable:
                    this.state.appCacheFlags.highestAvailable < this.state.current + 1
                        ? this.state.current + 1
                        : this.state.appCacheFlags.highestAvailable,
            },
            update_type: cmd === 'button' ? "next" : 'default',
            cake_status: this.mapStep(this.state.current + 1),
        }, () => {console.log('cake status ', this.state.cake_status)});
    };
    
    setScreen2Validation:any = (val: any, formFields: FormValidationFields, setFields: Function): void => {
       if(!formFields.viewOnly) {
            if(val == 'mtype') {
                this.setFormFields({ ...mapValues(formFields, () => true), materialTypeSelected: false, viewOnly: formFields.viewOnly });
            } else if(val == 'material') {            
                this.setFormFields({ ...mapValues(formFields, () => true), materialSelected: false, viewOnly: formFields.viewOnly });
            } else if (val == 'matmissing') {
                this.setFormFields({ ...mapValues(formFields, () => true), impactedMaterialsMissing: false, viewOnly: formFields.viewOnly });
            } else if(val == 'matMissingWithYes') {
                this.setFormFields({ ...mapValues(formFields, () => true), impactedMaterialsMissingWithYes: false, viewOnly: formFields.viewOnly });
            } else if(val == 'cpws') {            
                this.setFormFields({ ...mapValues(formFields, () => true), cpwsQuestionsAnswered: false, viewOnly: formFields.viewOnly });
            } else if (val == 'clearMType') {
                this.setFormFields({ ...mapValues(formFields, () => true), materialTypeSelected: true, viewOnly: formFields.viewOnly });
            } else if (val == 'clearMaterial') {
                this.setFormFields({ ...mapValues(formFields, () => true), materialSelected: true, viewOnly: formFields.viewOnly });
            } else if (val == 'clearMissing') {
                this.setFormFields({ ...mapValues(formFields, () => true), impactedMaterialsMissing: true, viewOnly: formFields.viewOnly });
            } else if(val == 'clearMatMissingYes') {
                this.setFormFields({ ...mapValues(formFields, () => true), impactedMaterialsMissingWithYes: true, viewOnly: formFields.viewOnly });
            } else if(val == 'clearImpactMaterials') {
                this.setState({ impactedMaterialsAvailable: []});
            } else if (val === 'downstreamMaterialTypeSelectedFalse') {
                this.setFormFields({ ...mapValues(formFields, () => true), downstreamMaterialTypeSelected: false, viewOnly: formFields.viewOnly });
            } else if (val === 'downstreamMaterialTypeSelectedTrue') {
                this.setFormFields({ ...mapValues(formFields, () => true), downstreamMaterialTypeSelected: true, viewOnly: formFields.viewOnly });
            } else if (val === 'downstreamMaterialType') {
                this.setFormFields({ ...mapValues(formFields, () => true), downstreamMaterialType: false, viewOnly: formFields.viewOnly });
            } else if (val === 'formExternalMaterialsMissing') {
                this.setFormFields({ ...mapValues(formFields, () => true), formExternalMaterialsMissing: false, viewOnly: formFields.viewOnly });
            }
        } else {
            if(formFields.viewOnly && (val == "mtype" || val == "material" || val == "matmissing" || val == "matMissingWithYes" || val == "cpws" || val == "downstreamMaterialTypeSelectedFalse" || val == "downstreamMaterialType" || val == "formExternalMaterialsMissing")) {
                notification.info({
                    message: 'Highest Screen Reached',
                    description: 'It is not possible to proceed further with this IPI ID in view-only mode.',
                    placement: 'topRight',
                    duration: 10,
                    className: 'nav-warnings',
                });
            }
        }        
    };
    prev: any = (cmd: any): void => {
        const current = this.state.current - 1;
        this.setState({
            current,
            update_type: cmd === 'button' ? "prev" : 'default',
            cake_status: this.mapStep(current),
        });
    };

    goTo = (page: number): void => {
        //console.log(this.state.appCacheFlags.highestAvailable, page, this.state.formFields.viewOnly);
        this.setState({
            current: page,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                highestAvailable:
                    this.state.appCacheFlags.highestAvailable < page ? page : this.state.appCacheFlags.highestAvailable,
            },
            cake_status: this.mapStep(page)
        });
    };
    save: any = (cmd: any): void => {
        const current = this.state.current;
        this.setState({
            current,
            update_type: cmd === 'button' ? "page_save" : 'default',
            cake_status: this.mapStep(current)
        }, () => this.sendSessionUpdate("page_save"));

    };

    openNotification = (logger: Logger): void => {
        logger.errors.map(error => {
            const args = {
                message: 'API Error',
                description: JSON.stringify(error),
                duration: 0,
                placement: 'topRight' as const,
                className: 'notification',
            };
            return notification.open(args);
        });
    };

    async setStartingCPWS(id: string): Promise<void> {
        if (id) {
            const setid = await this.loadCPWS(id);
            if (setid) {
                if (setid.response) {
                    setid.callback();
                }
            }
        }
    }

    // Account for delay in first-time user login authentication
    waitLogin = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    async componentDidMount(): Promise<void> {
        const { authState } = this.state;
        // Delay login check
        await this.waitLogin(5000);
        // Check if user logged-in
        await Auth.currentAuthenticatedUser()
            .then((value: any) => {
                if (!value.attributes['custom:membersOf'].includes(config.distributionListName)) {
                    this.setState({ authState: 'unauthorized' });
                } else {
                    Promise.all([this.getFirstName(), this.getLastName()]).then((res) => {
                        this.setState({ firstName: res[0], lastName: res[1] });
                    })
                    this.setState({ authState: 'signedIn' });
                }
            })
            .catch(e => {
                console.log(e);
                this.checkIfLoggedIn();
            });

        const id = this.state.options.id;
        // delay api call until user token loaded
        await this.waitLogin(2000);
        if (authState === 'signedIn') {
            if (id && id.length > 1) {
                await this.setStartingCPWS(id);
            }            
            const isUserViewOnly = await this.api.getUserImportEditOption();
            this.setState({ isViewOnlyUser: isUserViewOnly })
            const codes = await this.api.getClassificationCodes();
            this.setState({
                classificationCodes: codes,
            });
        } else {
            this.checkIfLoggedIn();
            if (id && id.length > 1) {
                await this.setStartingCPWS(id);
            } else {
                this.setState({ tokenDetected: true });
            }            
            const isUserViewOnly = await this.api.getUserImportEditOption();
            this.setState({ isViewOnlyUser: isUserViewOnly })
            const codes = await this.api.getClassificationCodes();
            this.setState({
                classificationCodes: codes,
            });
        }
        window.addEventListener('beforeunload', this.componentCleanup);
        window.addEventListener('afterprint', this.afterPDFSave);
    }

    async componentDidUpdate(): Promise<void> {
        this.openNotification(this.state.logger);
    }

    async componentWillUnmount(): Promise<void> {
        this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    componentCleanup = () => {
        if (this.state.cpws.cpwsId !== '0') {
            this.api.componentCleanup(this.state.cpws.cpwsId.toString());
            window.sessionStorage.clear();
        }
    };

    afterPDFSave = async () => {
        this.setState({ afterPDFSaveRefresh: true });
        this.checkForCPWSFile();
        await this.reset();
        this.goTo(1);
        window.sessionStorage.clear();
        setTimeout(() => {
            this.setState({ afterPDFSaveRefresh: false });
            window.scrollTo(0, 0);
        }, 2000);
        this.setState({appCacheFlags :{
            highestAvailable : 0
        }})
        //console.log("highestAvailable App.tsx ",this.state.appCacheFlags.highestAvailable);
    };

    generateProductTypeSelections(products: Array<ProductModality>) {
        return products.map((product: ProductModality) =>
            !product.productTypeSelection
                ? {
                    ...product,
                    productTypeSelection: this.state.productTypeSelection,
                }
                : product,
        );
    }
    //api calls start
    /*loadAvailableProducts = async (): Promise<void> => {
        if (this.state.appCacheFlags.loadProductsAvailable) {
            try {
                const products = await this.api.getProducts();
                if (products) {
                    const appCacheFlags = this.state.appCacheFlags;
                    appCacheFlags.loadProductsAvailable = false;
                    this.setState({
                        ...this.state,
                        productsAvailable: this.generateProductTypeSelections(products),
                        appCacheFlags,
                    });
                }
            } catch (e) {
                console.log("Error in loadAvailableProducts Function", e);
                this.state.logger.setException(e);
            }
        }
    };

    loadAvailableSites = async (): Promise<void> => {
        if (this.state.appCacheFlags.loadSitesAvailable) {
            try {
                const sitesAvailable = await this.api.getSites();
                if (sitesAvailable) {
                    const appCacheFlags = this.state.appCacheFlags;
                    appCacheFlags.loadSitesAvailable = false;
                    this.setState({ ...this.state, sitesAvailable, appCacheFlags });
                }
            } catch (e) {
                console.log("Error in loadAvailableSites Function", e);
                this.state.logger.setException(e);
            }
        }
    };*/

    loadSiteFunctions = async (products: Array<ProductsInfo>): Promise<Array<SiteFunction>> => {
        if (this.state.formFields.viewOnly) {
            return this.state.siteFunctions;
        }
        const siteFunctions = await this.api.getSiteFunctions(products);

        if (siteFunctions && typeof siteFunctions !== 'number') {
            this.setState({
                siteFunctions: siteFunctions,
            });
            return siteFunctions;
        } else {
            return this.state.siteFunctions;
        }
    };

    loadS3Props = async (): Promise<void> => {
        if (this.state.appCacheFlags.loadS3Props) {
            try {
                const s3props = await this.api.getPreSignedS3();
                this.setState({
                    ...this.state,
                    s3props: s3props,
                    appCacheFlags: { ...this.state.appCacheFlags, loadS3Props: false },
                });
            } catch (e) {
                console.log("Error in loadS3Props Function", e);
                this.state.logger.setException(e);
            }
        }
    };

    checkForCPWSFile = async (): Promise<any> => {
        const { cpws } = this.state;
        if (cpws.cpwsId !== '0') {
            await this.api.logout(cpws.cpwsId);
        }
    };

    ImportProductModalitySelection = (productModalities: any) => {
        const productModalityTypes: ProductModalitySelections = {
            biologic: false,
            smallMolecule: false,
            vaccine: false,
        };

        productModalities.forEach((product: any) => {
            switch (product.modality_code) {
                case 'B':
                    productModalityTypes.biologic = true;
                    break;
                case 'SM':
                    productModalityTypes.smallMolecule = true;
                    break;
                case 'V':
                    productModalityTypes.vaccine = true;
                    break;
            }
        });

        //console.log("productModalityTypes", productModalityTypes);

        return productModalityTypes;
    };

    generateProductModalitySelection = (productModalities: Array<ProductModality>) => {
        const productModalityTypes: ProductModalitySelections = {
            biologic: false,
            smallMolecule: false,
            vaccine: false,
        };

        productModalities.forEach((product: ProductModality) => {
            switch (product.modality) {
                case 'B':
                    productModalityTypes.biologic = true;
                    break;
                case 'SM':
                    productModalityTypes.smallMolecule = true;
                    break;
                case 'V':
                    productModalityTypes.vaccine = true;
                    break;
            }
        });

        return productModalityTypes;
    };



    confirmEditPopup = async (
        cpwsID: string,
        isid: string,
        firstName: string,
        lastName: String,
        time: string,
        complete: string,
        currentScreen: number,
    ): Promise<boolean> => {
        const currentUser: string = await this.getISID();
        const message = (
            <Space direction="vertical" size={4}>
                <div>
                    <WarningOutlined style={{ display: (complete.toLocaleUpperCase() !== 'COMPLETE' && currentUser === isid) ? 'none' : '' }} />
                    {currentUser !== isid ? (
                        <Text>{`CAKE record for IPI ID ${cpwsID} was last modified by user ${lastName}, ${firstName} on ${time}.`}</Text>
                    ) : (
                        <Text>{`CAKE record for IPI ID ${cpwsID} was last modified on ${time}.`}</Text>
                    )}
                </div>
                <br />
                <Text>
                    <Text>Status: </Text>
                    <Text>{complete.toLocaleUpperCase() === 'COMPLETE' ? 'Completed' : 'In-Progress'}</Text>
                </Text>
                <Text>{`Progress: ${currentScreen} - ${this.getScreenName(currentScreen)}`}</Text>
                {complete.toLocaleUpperCase() === 'COMPLETE' ? (
                    <Text>
                        <br />
                        <Text>Editing will re-open the CAKE record and the assessement will need to be finalized again.</Text>
                        <br /><br />
                        <Text strong style={{ color: 'red' }}>{`Do you want to edit the completed CAKE record for IPI ID ${cpwsID}?`} </Text>
                    </Text>
                ) : null}
            </Space>
        );

        return new Promise(resolve => {
            Modal.confirm({
                icon: <ExclamationCircleOutlined style={{ display: 'none' }} />,
                content: message,
                onOk() {
                    resolve(true);
                },
                onCancel() {
                    resolve(false);
                },
                width: 512,
                className: complete.toLocaleUpperCase() === 'COMPLETE' ? 'edit-modal-style' : 'modal-style',
                okText: complete.toLocaleUpperCase() === 'COMPLETE' ? 'Yes' : 'Ok',
                cancelText: complete.toLocaleUpperCase() === 'COMPLETE' ? 'No' : 'Cancel',
            });
        });
    };

    confirmViewPopup = async (
        cpwsID: string,
        isid: string,
        firstName: string,
        lastName: string,
        time: string,
        complete: string,
        highestAvaialble: string,
        screenName: string,
    ): Promise<boolean> => {
        const currentUser: string = await this.getISID();

        const message = (
            <Space direction="vertical" size={4}>
                {isid !== currentUser ? (
                    <Text>{`CAKE record for IPI ID ${cpwsID} was last modified by user ${lastName}, ${firstName} on ${time}.`}</Text>
                ) :
                    (
                        <Text>{`CAKE record for IPI ID ${cpwsID} was last modified on ${time}.`}</Text>
                    )}
                <Text>
                    <Text strong>Status: </Text>
                    <Text>{complete.toLocaleUpperCase() === 'COMPLETE' ? 'Complete' : 'In-progress'}</Text>
                </Text>
                <Text>
                    <Text strong>Progress: </Text>
                    <Text>{`${highestAvaialble} - ${screenName}`}</Text>
                </Text>
            </Space>
        );

        return new Promise(resolve => {
            Modal.confirm({
                content: message,
                okText: 'OK',
                icon: <ExclamationCircleOutlined style={{ display: 'none' }} />,
                onOk() {
                    resolve(true);
                },
                onCancel() {
                    resolve(false);
                },
                className: 'modal-style',
            });
        });
    };

    userCommentValue = (event: any): void => {
        this.setState({ comment: event.target.value });
    };

    setCommentValue = (screenNumber: number) => {
        let tmpComment = this.state.comment_details;
        switch (screenNumber) {
            case 1:
                return tmpComment.screen1;
            case 2:
                return tmpComment.screen2;
            case 3:
                return tmpComment.screen3;
            case 4:
                return tmpComment.screen4;
            case 5:
                return tmpComment.screen5;
            default:
                return "";
        }
    };

    openCommentModal = (event: any): void => {
        let cpwsID = this.state.cpws.cpwsId;
        if(cpwsID != "0") {
            let comment = this.setCommentValue(this.state.current);
            this.setState({commentModalVisibility: true, comment: comment});
        }
    }

    getCommentObj = (screenNumber: number) => {
        let tmpComment = this.state.comment_details;        
        switch (screenNumber) {
            case 1:
                tmpComment.screen1 = this.state.comment;
                return tmpComment;
            case 2:
                tmpComment.screen2 = this.state.comment;
                return tmpComment;
            case 3:
                tmpComment.screen3 = this.state.comment;
                return tmpComment;
            case 4:
                tmpComment.screen4 = this.state.comment;
                return tmpComment;
            case 5:
                tmpComment.screen5 = this.state.comment;
                return tmpComment;
            default:
                return tmpComment;
        }
    };

    submitCommentModal = (event: any): void => {
        let updatedCommentObj = this.getCommentObj(this.state.current);
        this.setState({comment_details: updatedCommentObj, commentModalVisibility: false});
        this.sendSessionUpdate();
    }

    closeCommentModal = (event: any): void => {
        this.setState({ commentModalVisibility: false, comment: '' });
    };

    editBackwardsCompatibilityExternalMaterials = async (
        impactedMaterialsExternal: Array<string | ExternalMaterial>,
        impactedMaterialsAvailable: Array<Material>,
    ): Promise<{
        impactedMaterialsExternal: Array<ExternalMaterial> | void;
        impactedMaterialsAvailable: Array<Material>;
    }> => {
        const tempImpactedMaterialsExternal =
            !isEmpty(impactedMaterialsExternal) && typeof impactedMaterialsExternal[0] === 'string'
                ? await this.loadImpactedMaterialsExternal()
                : (impactedMaterialsExternal as Array<ExternalMaterial>);

        const tempImpactedMaterialsAvailable = impactedMaterialsAvailable.filter(
            (material: Material) => material.number !== 'external',
        );
        return {
            impactedMaterialsExternal: tempImpactedMaterialsExternal,
            impactedMaterialsAvailable: tempImpactedMaterialsAvailable,
        };
    };

    /*editUndefinedStgDescription = (impactedMaterials: Array<Material>) => {
        impactedMaterials.forEach((mat: Material) => {
            if (mat.stg_description === undefined && mat.stageName !== undefined) {
                mat.stg_description = mat.stageName;
            }
        });
        return impactedMaterials;
    };*/

    editBackwardsCompatibilityProductModalities = (selectedProducts: Array<ProductModality>) => {
        return selectedProducts.map((product: ProductModality) => {
            if (!product.modality) {
                const prodWithModality = find(this.state.productsAvailable, (prod: ProductModality) =>
                    prod.familyName.startsWith(product.familyName),
                );
                if (prodWithModality) {
                    return prodWithModality;
                } else {
                    return product;
                }
            } else {
                return product;
            }
        });
    };

    editBackwardsCompatibilityProductsAvailable = (productsAvailable: Array<ProductModality>) => {
        const regex = / \((B|SM|V)\)$/m;
        return (
            productsAvailable.some((prod: ProductModality) => regex.exec(prod.familyName) !== null) &&
            productsAvailable.every((prod: ProductModality) => prod.additionalInfo !== undefined)
        );
    };

    editBackwardsCompatibilityImpactedSites = (impactedSites: Array<Site>) => {
        return impactedSites.map((site: Site) => ({
            ...site,
            isPDS: Object.prototype.hasOwnProperty.call(site, 'isPDS') ? site.isPDS : false,
        }));
    };

    editBackwardsCompatibilityMaterialClassification = (
        classificationList: CPWSClassification,
        materialClassiciationUuid: string,
        currentScreen: number,
    ) => {
        console.log("editBackwardsCompatibilityMaterialClassification called");
        if (
            isEmpty(classificationList) &&
            isEmpty(materialClassiciationUuid) &&
            currentScreen > 1 &&
            currentScreen < 6
        ) {
            this.setState({ appCacheFlags: { ...this.state.appCacheFlags, loadClassificationList: true } }, () => {
                this.postClassificationData();
            });
        }
    };

    editBackwardsCompatibilityMatchCriteria = (
        matchCriteria: MatchCriteria | { questions: Array<Question> },
        matchCriteriaAnswers: MatchCriteriaAnswer,
        current: number,
        appCacheFlags: AppCacheFlags,
    ) => {
        const regex = /^T\d+/m;
        console.log("editBackwardsCompatibilityMatchCriteria called");
        const matchCriteriaOldStructure = Object.prototype.hasOwnProperty.call(matchCriteria, 'questions');
        const matchCriteriaAnswersKeyFormattingWrong =
            matchCriteriaAnswers && Object.keys(matchCriteriaAnswers).some((key: string) => regex.exec(key) === null);
        const matchCriteriaQuestionsMissingProperty = matchCriteriaOldStructure
            ? true
            : (matchCriteria as Array<Question>).some((question: Question) => {
                return (
                    !Object.prototype.hasOwnProperty.call(question, 'materialTypeCode') ||
                    !Object.prototype.hasOwnProperty.call(question, 'materialTypeLabel') ||
                    !Object.prototype.hasOwnProperty.call(question, 'isContainerClosure')
                );
            });
        const matchCriteriaAnswersMissingProperty =
            matchCriteriaAnswers &&
            Object.keys(matchCriteriaAnswers).some((key: string) => {
                return (
                    !Object.prototype.hasOwnProperty.call(matchCriteriaAnswers[key], 'classificationLabel') ||
                    !Object.prototype.hasOwnProperty.call(matchCriteriaAnswers[key], 'isContainerClosure')
                );
            });

        if (
            matchCriteriaOldStructure ||
            matchCriteriaAnswersKeyFormattingWrong ||
            matchCriteriaQuestionsMissingProperty ||
            matchCriteriaAnswersMissingProperty
        ) {
            return {
                curStep: current === 7 ? '6' : current.toString(),
                appCacheFlags: {
                    ...appCacheFlags,
                    loadMatchCriteria: true,
                },
                matchCriteria: [],
                matchCriteriaAnswers: [],
                matchCriteriaKeyOrder: [],
            };
        } else {
            return {};
        }
    };

    getClassificationCode = (classification: string): string => {
        switch (classification) {
            case 'batchSize': {
                const obj = find(this.state.classificationCodes, ['definition', 'Batch Size']);
                return obj ? obj.code : classification;
            }
            case 'dmfCep': {
                const obj = find(this.state.classificationCodes, ['definition', 'DMF/CEP/TSE']);
                return obj ? obj.code : classification;
            }
            case 'inProcessControls': {
                const obj = find(this.state.classificationCodes, ['definition', 'In-Process Controls']);
                return obj ? obj.code : classification;
            }
            case 'process': {
                const obj = find(this.state.classificationCodes, ['definition', 'Process']);
                return obj ? obj.code : classification;
            }
            case 'site': {
                const obj = find(this.state.classificationCodes, ['definition', 'Site/Source']);
                return obj ? obj.code : classification;
            }
            case 'specificationsAndMethods': {
                const obj = find(this.state.classificationCodes, ['definition', 'Specifications & Methods']);
                return obj ? obj.code : classification;
            }
            case 'stability': {
                const obj = find(this.state.classificationCodes, ['definition', 'Stability']);
                return obj ? obj.code : classification;
            }
            case 'descriptionAndComposition': {
                const obj = find(this.state.classificationCodes, ['definition', 'Description and/or Composition']);
                return obj ? obj.code : classification;
            }
            default:
                return classification;
        }
    };


    editBackwardsCompatibilityContainerClosure = (
        material: Material,
        containerClosureMaterials?: Array<LegacyContainerClosureMaterial>,
        rawContainerClosureMaterials?: Array<LegacyContainerClosureMaterial>,
        containerClosureMaterialType?: Array<MaterialTypeCount>,
    ) => {
        if (
            !containerClosureMaterials ||
            !containerClosureMaterialType ||
            !material.downstream_mtls ||
            !rawContainerClosureMaterials
        ) {
            return material;
        } else {
            const newMat = cloneDeep(material);
            newMat.downstreamMaterials = material.downstream_mtls.reduce(
                (newMaterials: Array<ContainerClosureMaterial>, downstreamMat: ContainerClosureDownstreamMaterial) => {
                    let contClosMat: LegacyContainerClosureMaterial | undefined = find(containerClosureMaterials, [
                        'mtl_number',
                        downstreamMat.mtl_number,
                    ]);
                    if (!contClosMat) {
                        contClosMat = find(rawContainerClosureMaterials, ['mtl_number', downstreamMat.mtl_number]);
                        if (!contClosMat) {
                            return newMaterials;
                        }
                    }

                    const contClosMatType = find(containerClosureMaterialType, [
                        'mtl_number',
                        downstreamMat.mtl_number,
                    ]);
                    if (!contClosMatType) {
                        return newMaterials;
                    }
                    delete contClosMatType.mtl_types_count[0]['T0'];
                    newMaterials.push({
                        name: contClosMat.mtl_name,
                        number: downstreamMat.mtl_number,
                        stg_description: contClosMat.stg_description,
                        type: downstreamMat.mtl_type,
                        availableTypes: Object.values(contClosMatType.mtl_types_count[0]),
                        manually_entered: false
                    });
                    return newMaterials;
                },
                [],
            );
            delete newMat['downstream_mtls'];
            return newMat;
        }
    };

    editBackwardsCompatibilityImpactedMaterials = (
        impactedMaterials: Array<Material>,
        containerClosureMaterials?: Array<LegacyContainerClosureMaterial>,
        rawContainerClosureMaterials?: Array<LegacyContainerClosureMaterial>,
        containerClosureMaterialType?: Array<MaterialTypeCount>,
    ) => {
        return impactedMaterials.map((mat: Material) => {
            const hasContainerClosure = mat.type.some((matType: MaterialType) => matType.code === 'T14');
            if (hasContainerClosure) {
                mat = this.editBackwardsCompatibilityContainerClosure(
                    mat,
                    containerClosureMaterials,
                    rawContainerClosureMaterials,
                    containerClosureMaterialType,
                );
            } else {
                mat.downstreamMaterials = [];
                delete mat['downstream_mtls'];
            }

            return {
                ...mat,
                classification: mapKeys(mat.classification, (value: number | undefined, key: string) =>
                    this.getClassificationCode(key),
                ),
                stg_description:
                    mat.stg_description === undefined && mat.stageName !== undefined
                        ? mat.stageName
                        : mat.stg_description,
            };
        });
    };

    editBackwardsCompatibilityClassificationList = (classificationList: CPWSClassification) =>
        mapKeys(classificationList, (value: number | undefined, key: string) => this.getClassificationCode(key));

    editBackwardsCompatibilityAppCacheFlags = (appCacheFlags: AppCacheFlags) =>
        !appCacheFlags.loadClassificationListResponse
            ? { ...appCacheFlags, loadClassificationListResponse: false }
            : appCacheFlags;

    editBackwardsCompatbilityRegulatoryQuestions = (regulatoryQuestions?: RegulatoryQuestions) => {
        if (regulatoryQuestions) {
            regulatoryQuestions = mapValues(regulatoryQuestions, (val: RegulatoryQuestionValue, key: string) => {
                if (
                    Object.prototype.hasOwnProperty.call(this.state.regulatoryQuestions[key], 'children') &&
                    !Object.prototype.hasOwnProperty.call(val, 'children')
                ) {
                    return {
                        ...val,
                        children: cloneDeep(this.state.regulatoryQuestions[key].children),
                    };
                }
                return val;
            });
            if (!Object.prototype.hasOwnProperty.call(regulatoryQuestions, 'question5')) {
                regulatoryQuestions = { ...regulatoryQuestions, question5: { answer: false, isAnswered: true } };
            }
            return regulatoryQuestions;
        } else {
            return {
                question1: {
                    isAnswered: true,
                    answer: false,
                    children: {
                        childQuestion1: {
                            isAnswered: false,
                            answer: false,
                        },
                    },
                },
                question2: {
                    isAnswered: true,
                    answer: false,
                },
                question3: {
                    isAnswered: true,
                    answer: false,
                },
                question4: {
                    isAnswered: true,
                    answer: false,
                },
                question5: {
                    isAnswered: true,
                    answer: false,
                },
            };
        }
    };

    editBackwardsCompatibilityContainerClosurePageNumber = (impactedMaterials: Array<Material>) => {
        return impactedMaterials.reduce((pageNumbers: { [key: string]: number }, mat: Material) => {
            if (mat.type.some((matType: MaterialType) => matType.code === 'T14')) {
                pageNumbers[mat.number] = 1;
            }
            return pageNumbers;
        }, {});
    };

    editBackwardsCompatibilityConcur = (
        concurAnswer: boolean | string,
        concurText: string,
        nriOption: string,
        rationaleAssessment: string,
        regulatoryQuestions: RegulatoryQuestions,
        matchCriteriaYesRegulation: MatchCriteria,
    ) => {
        const regImpact = this.determineRegulatoryImpact(regulatoryQuestions, matchCriteriaYesRegulation);
        if (typeof concurAnswer === 'string') {
            return {
                userAssessment: '',
                userAssessmentRationale: '',
                nriOption: '',
                rationaleAssessment: '',
            };
        }
        if (regImpact && !concurAnswer) {
            return {
                userAssessment: 'NRI',
                userAssessmentRationale: concurText,
                nriOption: nriOption === 'N/A, RI Change' ? '' : nriOption,
                rationaleAssessment: rationaleAssessment,
            };
        } else if (regImpact && concurAnswer) {
            return {
                userAssessment: 'RI',
                additionalNotes: !isEmpty(rationaleAssessment) ? rationaleAssessment : '',
                cakeSuggestions: concurText,
                nriOption: '',
            };
        } else if (!regImpact && !concurAnswer) {
            return {
                userAssessment: 'RI',
                additionalNotes: !isEmpty(rationaleAssessment) ? rationaleAssessment : '',
                nriOption: '',
                userAssessmentRationale: concurText,
            };
        } else if (!regImpact && concurAnswer) {
            return {
                userAssessment: 'NRI',
                nriOption: nriOption === 'N/A, RI Change' ? '' : nriOption,
                cakeSuggestions: concurText,
                rationaleAssessment: rationaleAssessment,
            };
        } else {
            return {};
        }
    };

    getMaterialCodeMapping = (questions: Array<Question>) => {
        const materialCodeMapping: MaterialCodeMapping = {};
        questions.forEach((question: Question) => {
            if (!(question.materialTypeCode in materialCodeMapping)) {
                materialCodeMapping[question.materialTypeCode] = question.materialTypeLabel;
            }
        });

        return materialCodeMapping;
    };

    editCPWSData = async (cpwsID: any) => {
        const regex = /^T\d+/m;
        try {
            let response = await this.api.edit(cpwsID, this.state.firstName, this.state.lastName);
            if (response) {
                if (response.status === 'locked') {
                    this.setState({ status: 'locked' });
                    return {
                        loaded: false,
                        locked_by_user: response.locked_by_user,
                        lockedByUserFirstName: response.locked_by_user_first_name,
                        lockedByUserLastName: response.locked_by_user_last_name,
                        error: 'file locked',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'proceed') {
                    let currentScreen = Number(response.curStep);
                    if (
                        Number(response.curStep) === 7 &&
                        (Object.prototype.hasOwnProperty.call(response.matchCriteria, 'questions') ||
                            !Object.keys(response.matchCriteriaAnswers).every(
                                (key: string) => regex.exec(key) !== null,
                            ))
                    ) {
                        currentScreen = 6;
                    }
                    //console.log("cake_status >>> ", response.cake_status);
                    const confirmed = await this.confirmEditPopup(
                        response.cpwsID,
                        response.isid,
                        response.first_name,
                        response.last_name,
                        response.time,
                        response.complete,
                        currentScreen,
                    );

                    if (!confirmed) {
                        return {
                            error: 'not proceed',
                        };
                    }

                    this.checkForCPWSFile();
                    this.reset();
                    const cpws: CPWS = {
                        status: 'proceed',
                        locked_by_user: '',
                        cpws_session_id: response['cpws_session_id'],
                        file_method: response['file_method'],
                        cpwsId: response.cpwsID,
                        title: response['title'],
                        changeIdentifier: response['change_identifier'],
                        technicalInformation: response['technical-information'],
                        additionalInfoComments: response['additional-info-comments'],
                        reasonForChange: response['reason-for-change'],
                        changeDescCurrent: response['change-desc-current'],
                        changeDescProposed: response['change-desc-proposed'],
                        qualityAssessment: response['quality_assessment'],
                        linkedChangeReference: response['linked_change_reference'],
                        impactedSites: {
                            selected: this.editBackwardsCompatibilityImpactedSites(response['impacted-sites'].selected),
                            unmatched: this.editBackwardsCompatibilityImpactedSites(
                                response['impacted-sites'].unmatched,
                            ),
                        },
                        productModality: {
                            ...response['product-modality'],
                            selected: this.editBackwardsCompatibilityProductModalities(
                                response['product-modality'].selected,
                            ),
                        },
                        impactedMaterials: this.editBackwardsCompatibilityImpactedMaterials(
                            response['impactedMaterials'],
                            response['containerClosureMaterials'],
                            response['rawContainerClosureMaterials'],
                            response['containerClosureMaterialType'],
                        ),
                        qaQuestion: response['qaQuestion'],
                        attachments: response['attachments'],
                        lastAccessedUserFirstName: response['first_name'],

                        lastAccessedUserLastName: response['last_name'],
                        lockedByUserFirstName: response['locked_by_user_first_name'],
                        lockedByUserLastName: response['locked_by_user_last_name'],
                        basicInfo: response['basicInfo'] ? mapBasicInfoData(response['basicInfo']) : {},
                        supportingDetails: mapSupportingDetails(response['supportingDetails']),
                        additionalInfoData: mapAdditionalInfoData(response['additionalInfoData']),
                        relatedChanges: mapRelatedChangesData(response['relatedChanges']),
                        endorsements: mapEndorsementsData(response['endorsements']),
                        productInformation: mapProductInformation(response['productInformation']),
                        suppliers: mapSuppliers(response['suppliers']),
                        materials: mapMaterials(response['materials']),
                        methods: mapMethods(response['methods']),
                        partners: mapPartners(response['partners']),
                        plants: mapPlants(response['plants']),
                        registeredMarkets: mapRegisteredMarkets(response['registeredMarkets']),
                        soldMarkets: mapSoldMarkets(response['soldMarkets']),
                        impactAnalysis: mapImpactAnalysis(response['impactAnalysis']),
                    };

                    if (
                        Number(response.curStep) > 1 &&
                        Number(response.curStep) < 7 &&
                        someRegulatoryQuestionAnswersAreYes(response.regulatoryQuestions)
                    ) {
                        response.curStep = '1';
                    }

                    this.setCPWSState(CPWSStateChange.LOADED);

                    const backwardsCompatibility = await this.editBackwardsCompatibilityExternalMaterials(
                        response.impactedMaterialsExternal,
                        response.impactedMaterialsAvailable,
                    );

                    /**
                     * Update back compat function to take regulatoryQuestions optionally.
                     * If there, make sure children are there for each question
                     * If not there, then function should be the same as it is now.
                     */
                    const regulatoryQuestions = response.regulatoryQuestions
                        ? this.editBackwardsCompatbilityRegulatoryQuestions(response.regulatoryQuestions)
                        : this.editBackwardsCompatbilityRegulatoryQuestions();

                    response = {
                        ...response,
                        ...this.editBackwardsCompatibilityMatchCriteria(
                            response.matchCriteria,
                            response.matchCriteriaAnswers,
                            Number(response.curStep),
                            response.appCacheFlags,
                        ),
                    };

                    if (!allRegulatoryQuestionsAnswered(regulatoryQuestions) && response.curStep === '7') {
                        response.curStep = '1';
                    }

                    if (!response['user_nri_ri']) {
                        response = {
                            ...response,
                            ...this.editBackwardsCompatibilityConcur(
                                response['concurQuestion'],
                                response['concurText'],
                                response['nriOption'] ? response['nriOption'] : '',
                                response['rationaleAssessment'] ? response['rationaleAssessment'] : '',
                                regulatoryQuestions,
                                response['matchCriteriaYesRegulation'],
                            ),
                        };
                    }

                    const pageNumbers = this.editBackwardsCompatibilityContainerClosurePageNumber(
                        cpws.impactedMaterials,
                    );

                    const justification = response.justification ? response.justification : '';

                    const classificationList = response.classificationList
                        ? this.editBackwardsCompatibilityClassificationList(response.classificationList)
                        : {};
                    const [highestAvailable] = this.calculateHighestAvailable(
                        regulatoryQuestions,
                        justification,
                        cpws.impactedMaterials,
                        response.qaQuestionAnswered,
                        classificationList,
                        response.matchCriteria,
                        response.matchCriteriaAnswers,
                        response.assessmentResults,
                        response.countries,
                        response.siteFunctions,
                        response.prImpactedMaterials,
                    ) as [number, string];
                    this.setState(
                        {
                            ...this.state,
                            cpws: cpws,
                            status: 'proceed',
                            current: Number(response.curStep),
                            category: response.ds_dp,
                            sterilization: response.sterile_non_sterile,
                            productsAvailable: this.editBackwardsCompatibilityProductsAvailable(
                                response.productsAvailable,
                            )
                                ? this.generateProductTypeSelections(response.productsAvailable)
                                : this.state.productsAvailable,
                            impactedMaterialsAvailable: backwardsCompatibility.impactedMaterialsAvailable,
                            impactedMaterialsAvailableOption: response.impactedMaterialsAvailableOption,
                            downstreamImpactedMaterialsAvailableOption: response.downstreamImpactedMaterialsAvailableOption,
                            tab_num: response.tab_num,
                            visitedChangeClassification: (response.visitedChangeClassification != undefined) ? response.visitedChangeClassification : this.state.visitedChangeClassification,
                            comment_details: (response.comment_details != undefined) ? response.comment_details : this.state.comment_details,
                            staticProductInformation: response.staticProductInformation ? response.staticProductInformation : cpws.productInformation,
                            appCacheFlags: this.editBackwardsCompatibilityAppCacheFlags({
                                ...response.appCacheFlags,
                                highestAvailable: highestAvailable,
                            }),
                            downstreamProducts: response.downstreamProducts,
                            downstreamSites: response.downstreamSites,
                            classificationList: classificationList,
                            matchCriteria: response.matchCriteria,
                            assessmentResults: response.assessmentResults,
                            comment: response.comment,
                            productTypeSelection: response.productTypeSelection ? response.productTypeSelection : '',
                            impactedMaterialsExternal: backwardsCompatibility.impactedMaterialsExternal
                                ? backwardsCompatibility.impactedMaterialsExternal
                                : this.state.impactedMaterialsExternal,
                            sendExternalMaterials: response.sendExternalMaterials,
                            impactedMaterialType: response.impactedMaterialType,
                            qaQuestionAnswered: response.qaQuestionAnswered,
                            dpDsAnswered: response.dpDsAnswered,
                            sterileAnswered: response.sterileAnswered,
                            impactedCountries: response.countries,
                            //userSelectedMaterialsWithMaterialType:response.userSelectedMAterials,
                            matchCriteriaYesRegulation: response.matchCriteriaYesRegulation,
                            cpwsCompleted: 'not complete',
                            unlockPDF: response.unlockPDF,
                            regulatoryQuestions: regulatoryQuestions,
                            justification: justification,
                            downstreamMaterials: response.downstreamMaterials,
                            productModalitySelections: response.productModalitySelections
                                ? response.productModalitySelections
                                : this.generateProductModalitySelection(cpws.productModality.selected),
                            siteFunctions: response.siteFunctions ? response.siteFunctions : [],
                            materialClassificationUuid: response.materialClassificationUuid
                                ? response.materialClassificationUuid
                                : '',
                            matchCriteriaAnswers: response.matchCriteriaAnswers,
                            matchCriteriaKeyOrder: response.matchCriteriaKeyOrder,
                            productColorTags: response.productColorTags
                                ? response.productColorTags
                                : {
                                    initiallySelected: false,
                                    initialRemoved: false,
                                    unmatched: this.state.cpws.productModality.unmatched,
                                },
                            siteColorTags: response.siteColorTags
                                ? response.siteColorTags
                                : {
                                    initiallySelected: false,
                                    initialRemoved: false,
                                    unmatched: this.state.cpws.productModality.unmatched,
                                },
                            materialCodeMapping: response.matchCriteria
                                ? this.getMaterialCodeMapping(response.matchCriteria)
                                : {},
                            nriOption: response.nriOption ? response.nriOption : '',
                            rationaleAssessment: response.rationaleAssessment ? response.rationaleAssessment : '',
                            containerClosurePageNumber: !isEmpty(pageNumbers)
                                ? pageNumbers
                                : this.state.containerClosurePageNumber,
                            userAssessment: response.userAssessment,
                            userAssessmentRationale: response.userAssessmentRationale ? response.userAssessmentRationale : '',
                            directionalDetails: response.directionalDetails ? response.directionalDetails : '',
                            additionalNotes: response.additionalNotes ? response.additionalNotes : '',
                            cakeSuggestions: response.cakeSuggestions ? response.cakeSuggestions : '',
                            reviewer: response.reviewer ? response.reviewer : '',
                            majorMarkets: response.majorMarkets ? response.majorMarkets : this.state.majorMarkets,
                            secondaryMarkets: response.secondaryMarkets
                                ? response.secondaryMarkets
                                : this.state.secondaryMarkets,
                            userSelectedMaterials: response.prImpactedMaterials,
                            cake_status: response.cake_status,
                            prat: response.prat,
                            submission_req_details: response.submission_req_details,
                            currentPlanningAssessmentType: response.currentPlanningAssessmentType,
                            currentDirectionalAssessmentReason: response.currentDirectionalAssessmentReason,
                            user_nri_ri: response.user_nri_ri,
                            vaccine_nri_ri: response.vaccine_nri_ri,
                            biologic_nri_ri: response.biologic_nri_ri,
                            smallmolecule_nri_ri: response.smallmolecule_nri_ri,
                            scope_change_question: response.scope_change_question != undefined ? response.scope_change_question : '',
                            screen4_update: response.screen4_update
                        },
                        async () => {
                            this.setSessionID();
                            await this.setCPWSID();
                            this.setErrorBoundaryContext();
                            this.editBackwardsCompatibilityMaterialClassification(
                                this.state.classificationList,
                                this.state.materialClassificationUuid,
                                this.state.current,
                            );
                            // if (this.state.siteFunctions.length === 0) {
                            //     this.loadSiteFunctions(this.state.cpws.productModality.selected);
                            // }
                            if (
                                this.state.impactedCountries.some(
                                    (country: Country) =>
                                        country.pdsFlag &&
                                        !Object.prototype.hasOwnProperty.call(country, 'siteFunctions'),
                                )
                            ) {
                                this.loadAdditionalInfo();
                            }
                            this.api.setIncomplete(cpws.cpwsId);
                        },
                    );

                    return {
                        response,
                        callback: (): void => {
                            if (Number(response.curStep) === 1) {
                                this.prev();
                                this.next();
                            }
                        },
                        loaded: true,
                    };
                } else {
                    this.setState({ status: 'file_not_found' });
                    return {
                        loaded: false,
                        error: 'file_not_found',
                        callback: (): void => {
                            return;
                        },
                    };
                }
            } else {
                return {
                    loaded: false,
                    error: 'file_not_found',
                    callback: (): void => {
                        return;
                    },
                };
            }
        } catch (e) {
            console.log("Error in editCPWSData Function", e);
            this.state.logger.setException(e);
            //throw new Error(e);
            window.sessionStorage.setItem('cpwsID',cpwsID);
            this.setState(() => {
                throw e;
            })
        }
    };

    getScreenNumber = (screenName: string) => {
        switch (screenName) {
            case 'Discover':
                return '1';
            case 'Materials':
                return '2';
            case 'Markets':
                return '3';
            case 'Impact Assessment':
                return '4';
            case 'Regulatory Screening':
                return '5';            
            default:
                return '1';
        }
    };

    getScreenName = (screenNumber: number) => {
        switch (screenNumber) {
            case 1:
                return 'Discover';
            case 2:
                return 'Materials';
            case 3:
                return 'Markets';
            case 4:
                return 'Impact Assessment';
            case 5:
                return 'Regulatory Screening';
            case 6:
                return 'Criteria';
            case 7:
                return 'Regulatory Screening';
            default:
                return 'Discover';
        }
    };

    checkImpactedMaterials = (impactedMaterials: Array<any>) => {
        return impactedMaterials.every((mat: Material) => {
            const materialTypeSelected = mat.type.length > 0;
            if (!materialTypeSelected) {
                return false;
            }

            const hasContainerClosure = mat.type.some((matType: MaterialType) => matType.code === 'T14');
            if (materialTypeSelected && hasContainerClosure) {
                const hasDownstreamMaterials = mat.downstreamMaterials.length > 0;
                const allDownstreamMaterialsHaveType = mat.downstreamMaterials.every(
                    (downstreamMat: ContainerClosureMaterial) => downstreamMat.type.length > 0,
                );

                if (!hasDownstreamMaterials || !allDownstreamMaterialsHaveType) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        });
    };

    calculateHighestAvailable = (
        regulatoryQuestions: RegulatoryQuestions,
        justification: string,
        impactedMaterials: Array<Material>,
        qaQuestionAnswered: boolean,
        classificationList: CPWSClassification,
        matchCriteria: MatchCriteria,
        matchCriteriaAnswers: MatchCriteriaAnswer,
        assessmentResults: Array<AssessmentResult>,
        impactedCountries: Array<Country>,
        siteFunctions: Array<SiteFunction>,
        userSelectedMaterials: Array<SelectedMaterials>,
    ) => {
        const regex = /^T\d+/m;
        let num: number;
        let screenName: string;
        console.log("calculateHighestAvailable 1842");        
        if (someRegulatoryQuestionAnswersAreYes(regulatoryQuestions) && !isEmpty(justification)) {
            num = 5;
            screenName = this.getScreenName(num);
        } else if (
            (matchCriteria.length !== 0 &&
                matchCriteriaAnswers &&
                findKey(matchCriteriaAnswers, obj => !obj.isAnswered)) ||
            (assessmentResults.length === 0 &&
                !(
                    ((matchCriteriaAnswers &&
                        Object.keys(matchCriteriaAnswers).every(
                            (key: string) =>
                                (typeof matchCriteriaAnswers[key].answer === 'boolean' &&
                                    !matchCriteriaAnswers[key].answer) ||
                                (typeof matchCriteriaAnswers[key].answer !== 'boolean' &&
                                    (matchCriteriaAnswers[key].answer as Array<string>).includes('N/A')),
                        )) ||
                        matchCriteria.length === 0) &&
                    impactedCountries.length === 0
                ))
        ) {
            num = 5;
            screenName = this.getScreenName(num);
        }              
        else if (!this.checkImpactedMaterials(userSelectedMaterials) || isEmpty(classificationList) || this.state.category.length < 1 || 
        this.state.sterilization.length < 1 || this.state.userSelectedMaterials.length < 1 || (!this.state.productModalitySelections.biologic && !this.state.productModalitySelections.smallMolecule && !this.state.productModalitySelections.vaccine)) {
            console.log("calculateHighestAvailable >> ", 2);
            num = 2;
            screenName = this.getScreenName(num);
        }
         else if (isEmpty(siteFunctions)) {
            num = 3;
            screenName = this.getScreenName(num);        
        } else if (
            !matchCriteriaAnswers ||
            (matchCriteriaAnswers &&
                Object.keys(matchCriteriaAnswers).some((key: string) => regex.exec(key) === null)) ||
            Object.prototype.hasOwnProperty.call(matchCriteria, 'questions') ||
            matchCriteria.some((question: Question) => {
                return (
                    !Object.prototype.hasOwnProperty.call(question, 'materialTypeCode') ||
                    !Object.prototype.hasOwnProperty.call(question, 'materialTypeLabel') ||
                    !Object.prototype.hasOwnProperty.call(question, 'isContainerClosure')
                );
            }) ||
            (matchCriteriaAnswers &&
                Object.keys(matchCriteriaAnswers).some(
                    (key: string) =>
                        !Object.prototype.hasOwnProperty.call(matchCriteriaAnswers[key], 'classificationLabel') ||
                        !Object.prototype.hasOwnProperty.call(matchCriteriaAnswers[key], 'isContainerClosure'),
                )) ||
            impactedMaterials.some((mat: Material) =>
                Object.values(mat.classification).every((val: number | undefined) => val && val < 50),
            )
        ) {
            num = 4;
            screenName = this.getScreenName(num);
        }  else if (
            (matchCriteria.length !== 0 &&
                matchCriteriaAnswers &&
                !findKey(matchCriteriaAnswers, obj => !obj.isAnswered)) ||
            matchCriteria.length === 0
        ) {
            num = 5;
            screenName = this.getScreenName(num);
        } else {
            num = 1;
            screenName = this.getScreenName(num);
        }
        return [num, screenName];
    };

    viewCPWSData = async (cpwsID: string) => {
        const { current } = this.state;
        try {
            let response = await this.api.view(cpwsID, this.state.firstName, this.state.lastName);
            if (response) {
                // If locked == true reject alert user
                if (response.status === 'proceed') {
                    this.setCPWSState(CPWSStateChange.LOADED);

                    const cpws: CPWS = {
                        status: 'proceed',
                        locked_by_user: '',
                        cpws_session_id: response['cpws_session_id'],
                        file_method: response['file_method'],
                        cpwsId: response.cpwsID,
                        title: response['title'],
                        changeIdentifier: response['change_identifier'],
                        technicalInformation: response['technical-information'],
                        additionalInfoComments: response['additional-info-comments'],
                        reasonForChange: response['reason-for-change'],
                        changeDescCurrent: response['change-desc-current'],
                        changeDescProposed: response['change-desc-proposed'],
                        qualityAssessment: response['quality_assessment'],
                        linkedChangeReference: response['linked_change_reference'],
                        impactedSites: {
                            selected: this.editBackwardsCompatibilityImpactedSites(response['impacted-sites'].selected),
                            unmatched: this.editBackwardsCompatibilityImpactedSites(
                                response['impacted-sites'].unmatched,
                            ),
                        },
                        productModality: {
                            ...response['product-modality'],
                            selected: this.editBackwardsCompatibilityProductModalities(
                                response['product-modality'].selected,
                            ),
                        },
                        impactedMaterials: this.editBackwardsCompatibilityImpactedMaterials(
                            response['impactedMaterials'],
                            response['containerClosureMaterials'],
                            response['rawContainerClosureMaterials'],
                            response['containerClosureMaterialType'],
                        ),
                        qaQuestion: response['qaQuestion'],
                        attachments: response['attachments'],
                        lastAccessedUserFirstName: response['first_name'],
                        lastAccessedUserLastName: response['last_name'],
                        lockedByUserFirstName: response['locked_by_user_first_name'],
                        lockedByUserLastName: response['locked_by_user_last_name'],
                        basicInfo: response['basicInfo'] ? mapBasicInfoData(response['basicInfo']) : {},
                        supportingDetails: mapSupportingDetails(response['supportingDetails']),
                        additionalInfoData: mapAdditionalInfoData(response['additionalInfoData']),
                        relatedChanges: mapRelatedChangesData(response['relatedChanges']),
                        endorsements: mapEndorsementsData(response['endorsements']),
                        productInformation: mapProductInformation(response['productInformation']),
                        suppliers: mapSuppliers(response['suppliers']),
                        materials: mapMaterials(response['materials']),
                        methods: mapMethods(response['methods']),
                        partners: mapPartners(response['partners']),
                        plants: mapPlants(response['plants']),
                        registeredMarkets: mapRegisteredMarkets(response['registeredMarkets']),
                        soldMarkets: mapSoldMarkets(response['soldMarkets']),
                        impactAnalysis: mapImpactAnalysis(response['impactAnalysis']),
                    };

                    if (
                        Number(response.curStep) > 1 &&
                        Number(response.curStep) < 7 &&
                        someRegulatoryQuestionAnswersAreYes(response.regulatoryQuestions)
                    ) {
                        response.curStep = '1';
                    }

                    const backwardsCompatibility = await this.editBackwardsCompatibilityExternalMaterials(
                        response.impactedMaterialsExternal,
                        response.impactedMaterialsAvailable,
                    );

                    const regulatoryQuestions = response.regulatoryQuestions
                        ? this.editBackwardsCompatbilityRegulatoryQuestions(response.regulatoryQuestions)
                        : this.editBackwardsCompatbilityRegulatoryQuestions();

                    const justification = response.justification ? response.justification : '';

                    const classificationList = response.classificationList
                        ? this.editBackwardsCompatibilityClassificationList(response.classificationList)
                        : {};
                        const findNumber1 = Number(this.getScreenNumber(response.cake_status));
                        const screenName1 = response.cake_status;
                    const [findNumber2, screenName2] = this.calculateHighestAvailable(
                        regulatoryQuestions,
                        justification,
                        cpws.impactedMaterials,
                        response.qaQuestionAnswered,
                        classificationList,
                        response.matchCriteria,
                        response.matchCriteriaAnswers,
                        response.assessmentResults,
                        response.countries,
                        response.siteFunctions,
                        response.prImpactedMaterials,
                    ) as [number, string];
                    const highestAvailable = (findNumber1 > findNumber2) ? findNumber1 : findNumber2;
                    const screenName = (findNumber1 > findNumber2) ? screenName1 : screenName2;
                    console.log("calculateHighestAvailable in 2019 after ::", highestAvailable);
                    const confirmed = await this.confirmViewPopup(
                        cpws.cpwsId,
                        response.isid,
                        response.first_name,
                        response.last_name,
                        response.time,
                        response.complete,
                        highestAvailable.toString(),
                        screenName,
                    );

                    if (!confirmed) {
                        return {
                            error: 'cancelled',
                        };
                    }

                    const pageNumbers = this.editBackwardsCompatibilityContainerClosurePageNumber(
                        cpws.impactedMaterials,
                    );

                    if (!response.user_nri_ri) {
                        response = {
                            ...response,
                            ...this.editBackwardsCompatibilityConcur(
                                response['concurQuestion'],
                                response['concurText'],
                                response['nriOption'] ? response['nriOption'] : '',
                                response['rationaleAssessment'] ? response['rationaleAssessment'] : '',
                                //response['userAssessmentRationale'] ? response['userAssessmentRationale'] : '',
                                regulatoryQuestions,
                                response['matchCriteriaYesRegulation'],
                            ),
                        };
                    }

                    await this.checkForCPWSFile();
                    this.reset();

                    this.setState(
                        {
                            ...this.state,
                            cpws: cpws,
                            status: 'proceed',
                            current: 1,
                            category: response.ds_dp,
                            sterilization: response.sterile_non_sterile,
                            productsAvailable: this.editBackwardsCompatibilityProductsAvailable(
                                response.productsAvailable,
                            )
                                ? this.generateProductTypeSelections(response.productsAvailable)
                                : this.state.productsAvailable,
                            impactedMaterialsAvailable: backwardsCompatibility.impactedMaterialsAvailable,
                            impactedMaterialsAvailableOption: response.impactedMaterialsAvailableOption,
                            downstreamImpactedMaterialsAvailableOption: response.downstreamImpactedMaterialsAvailableOption,
                            tab_num: response.tab_num,
                            visitedChangeClassification: (response.visitedChangeClassification != undefined) ? response.visitedChangeClassification : this.state.visitedChangeClassification,
                            comment_details: (response.comment_details != undefined) ? response.comment_details : this.state.comment_details,
                            staticProductInformation: response.staticProductInformation ? response.staticProductInformation : cpws.productInformation,
                            downstreamProducts: response.downstreamProducts,
                            downstreamSites: response.downstreamSites,
                            classificationList: classificationList,
                            matchCriteria: response.matchCriteria,
                            assessmentResults: response.assessmentResults,
                            comment: response.comment,
                            productTypeSelection: response.productTypeSelection ? response.productTypeSelection : '',
                            impactedMaterialsExternal: backwardsCompatibility.impactedMaterialsExternal
                                ? backwardsCompatibility.impactedMaterialsExternal
                                : this.state.impactedMaterialsExternal,
                            sendExternalMaterials: response.sendExternalMaterials,
                            impactedMaterialType: response.impactedMaterialType,
                            qaQuestionAnswered: response.qaQuestionAnswered,
                            dpDsAnswered: response.dpDsAnswered,
                            sterileAnswered: response.sterileAnswered,
                            impactedCountries: response.countries,
                            matchCriteriaYesRegulation: response.matchCriteriaYesRegulation,
                            cpwsCompleted: response.complete,
                            unlockPDF: response.unlockPDF,
                            regulatoryQuestions: regulatoryQuestions,
                            justification: justification,
                            downstreamMaterials: response.downstreamMaterials,
                            productModalitySelections: response.productModalitySelections
                                ? response.productModalitySelections
                                : this.ImportProductModalitySelection(response.productInformation.modalities),
                            siteFunctions: response.siteFunctions ? response.siteFunctions : [],
                            materialClassificationUuid: response.materialClassificationUuid
                                ? response.materialClassificationUuid
                                : '',
                            matchCriteriaAnswers: response.matchCriteriaAnswers ? response.matchCriteriaAnswers : [],
                            matchCriteriaKeyOrder: response.matchCriteriaKeyOrder ? response.matchCriteriaKeyOrder : [],
                            productColorTags: response.productColorTags
                                ? response.productColorTags
                                : {
                                    initiallySelected: false,
                                    initialRemoved: false,
                                    unmatched: this.state.cpws.productModality.unmatched,
                                },
                            siteColorTags: response.siteColorTags
                                ? response.siteColorTags
                                : {
                                    initiallySelected: false,
                                    initialRemoved: false,
                                    unmatched: this.state.cpws.productModality.unmatched,
                                },
                            formFields: {
                                ...this.state.formFields,
                                viewOnly: true,
                            },
                            materialCodeMapping:
                                response.matchCriteria &&
                                    !Object.prototype.hasOwnProperty.call(response.matchCriteria, 'questions')
                                    ? this.getMaterialCodeMapping(response.matchCriteria)
                                    : {},
                            nriOption: response.nriOption ? response.nriOption : '',
                            rationaleAssessment: response.rationaleAssessment ? response.rationaleAssessment : '',
                            containerClosurePageNumber: !isEmpty(pageNumbers)
                                ? pageNumbers
                                : this.state.containerClosurePageNumber,
                            userAssessment: response.userAssessment,
                            user_nri_ri: response.user_nri_ri,
                            vaccine_nri_ri: response.vaccine_nri_ri,
                            biologic_nri_ri: response.biologic_nri_ri,
                            smallmolecule_nri_ri: response.smallmolecule_nri_ri,
                            scope_change_question: response.scope_change_question != undefined ? response.scope_change_question : '',
                            userAssessmentRationale: response.userAssessmentRationale ? response.userAssessmentRationale : '',
                            directionalDetails: response.directionalDetails ? response.directionalDetails : '',
                            additionalNotes: response.additionalNotes ? response.additionalNotes : '',
                            cakeSuggestions: response.cakeSuggestions ? response.cakeSuggestions : '',
                            reviewer: response.reviewer ? response.reviewer : '',
                            majorMarkets: response.majorMarkets ? response.majorMarkets : this.state.majorMarkets,
                            secondaryMarkets: response.secondaryMarkets
                                ? response.secondaryMarkets
                                : this.state.secondaryMarkets,
                            userSelectedMaterials: response.prImpactedMaterials,
                            prat: response.prat,
                            submission_req_details: response.submission_req_details,
                            currentPlanningAssessmentType: response.currentPlanningAssessmentType,
                            currentDirectionalAssessmentReason: response.currentDirectionalAssessmentReason
                        },
                        async () => {
                            this.setSessionID();
                            await this.setCPWSID();
                            this.setErrorBoundaryContext();
                            this.editBackwardsCompatibilityMaterialClassification(
                                this.state.classificationList,
                                this.state.materialClassificationUuid,
                                this.state.current,
                            );

                            this.setState({
                                appCacheFlags: {
                                    ...mapValues(this.state.appCacheFlags, (val: boolean | number) =>
                                        typeof val === 'boolean' ? false : val,
                                    ),
                                    highestAvailable: highestAvailable,
                                },
                               // unlockPDF: highestAvailable === 5 ? true : false,
                            });
                        },
                    );

                    return {
                        response,
                        callback: (): void => {
                            if (current === 1) {
                                this.prev();
                                this.next();
                            }
                        },
                        loaded: true,
                    };
                } else {
                    this.setState({ status: 'file_not_found' });
                    return {
                        loaded: false,
                        error: 'file_not_found',
                        callback: (): void => {
                            return;
                        },
                    };
                }
            } else {
                return {
                    loaded: false,
                    error: 'file_not_found',
                    callback: (): void => {
                        return;
                    },
                };
            }
        } catch (e) {
            console.log("Error in viewCPWSData Function", e);
            this.state.logger.setException(e);
            //throw new Error(e);
            window.sessionStorage.setItem('cpwsID',cpwsID);
            this.setState(() => {
                throw e;
            })
        }
    };
    // Only used after initial inget call
    confirmedIngest = async () => {
        this.checkForCPWSFile();
        this.reset();
        const { tempID } = this.state;
        try {
            const response = await this.api.ingestOverwrite(tempID, this.state.firstName, this.state.lastName);
            if (response) {
                if (response.status === 'API_CONNECT_ERROR') {
                    console.log('API Connection Failed');
                    this.setState({ status: 'connection_error' });
                    return {
                        loaded: false,
                        error: 'connection_error',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'INVALID_CPWS_ID') {
                    this.invalidCPWSWarning();
                    this.setState({ status: 'INVALID_CPWS_ID' });
                    return {
                        loaded: false,
                        error: 'INVALID_CPWS_ID',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'locked') {
                    this.setState({ status: 'locked' });
                    return {
                        loaded: false,
                        locked_by_user: response.locked_by_user,
                        error: 'file locked',
                        lockedByUserFirstName: response.lockedByUserFirstName,
                        lockedByUserLastName: response.lockedByUserLastName,
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'proceed') {
                    let cpws: CPWS = response;
                    const updateRelatedChanges = await this.getRelatedChanges(cpws.relatedChanges);

                    const productModalitySelections = this.generateProductModalitySelection(
                        cpws.productModality.selected,
                    );
                    cpws = { ...cpws, relatedChanges: updateRelatedChanges }
                    this.setCPWSState(CPWSStateChange.LOADED);
                    this.setState(
                        {
                            ...this.state,
                            appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: 0 },
                            productModalitySelections: { ...productModalitySelections },
                            productColorTags: {
                                ...this.state.productColorTags,
                                initiallySelected: cloneDeep(cpws.productModality.selected),
                                unmatched: cpws.productModality.unmatched,
                            },
                            siteColorTags: {
                                ...this.state.siteColorTags,
                                initiallySelected: cloneDeep(cpws.impactedSites.selected),
                                unmatched: cpws.impactedSites.unmatched,
                            },
                            cpws: cpws,
                            staticProductInformation: cpws.productInformation,
                            status: 'proceed',
                        },
                        async () => {
                            this.setSessionID();
                            await this.setCPWSID();
                            this.setErrorBoundaryContext();
                           // this.loadSiteFunctions(cpws.productModality.selected);
                           // this.loadSiteFunctions(cpws.productInformation.products);
                            this.api.setIncomplete(cpws.cpwsId);
                        },
                    );
                    this.prev();
                    this.next();
                } else {
                    this.setState({ status: 'file_not_found' });
                    return {
                        loaded: false,
                        error: 'file_not_found',
                        callback: (): void => {
                            return;
                        },
                    };
                }
            }
        } catch (err) {
            console.log("Error in confirmedIngest Function", err);
            this.state.logger.setException(err);
        }
    };

    confirmedIngestAsync = async (cpwsID: string) => {
        const appCacheFlags = this.state.appCacheFlags;
        this.checkForCPWSFile();
        this.reset();
        const { tempID } = this.state;
        const uuid = uuidv4();
        try {
            const response = await this.api.ingestOverwriteAsync(cpwsID, this.state.firstName, this.state.lastName, uuid);
            if (response) {
                if (response.status === 'API_CONNECT_ERROR') {
                    console.log('API Connection Failed');
                    this.setState({ status: 'connection_error' });
                    return {
                        loaded: false,
                        error: 'connection_error',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'INVALID_CPWS_ID') {
                    this.invalidCPWSWarning();
                    this.setState({ status: 'INVALID_CPWS_ID' });
                    return {
                        loaded: false,
                        error: 'INVALID_CPWS_ID',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'locked') {
                    this.setState({ status: 'locked' });
                    return {
                        loaded: false,
                        locked_by_user: response.locked_by_user,
                        error: 'file locked',
                        lockedByUserFirstName: response.lockedByUserFirstName,
                        lockedByUserLastName: response.lockedByUserLastName,
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'proceed') {                    
                    let counter = 0;
                    const sleep = async () => {
                        return new Promise(resolve => setTimeout(resolve, 4000));
                    };
                    let responseInner = await this.api.getIngestResponse(cpwsID, this.state.firstName, this.state.lastName, uuid);
                    while (responseInner.status != 'proceed' && counter < 225) {
                        if (responseInner.status === 'pending') {
                            counter++;
                            await sleep();
                            responseInner = await await this.api.getIngestResponse(cpwsID, this.state.firstName, this.state.lastName, uuid);
                        } else {
                            break;
                        }
                    }

                    if (responseInner.status === 'pending') {
                        console.log("Response is still pending")
                        this.state.logger.setError('IPI Data was not retrieved.');
                        notification.warning({
                            message: 'IPI Data was not retrieved',
                            description: 'The result is still pending',
                            placement: 'topRight',
                            duration: 15,
                        });                        
                        this.afterPDFSave();
                    } else if (responseInner.status === 'API_CONNECT_ERROR' || 
                                responseInner.status === 'UNHANDLED_ERROR' || 
                                responseInner.status === 'INVALID_PROPOSAL') {
                        notification.warning({
                            message: 'Error while fetching IPI Data',
                            description: 'An error was returned',
                            placement: 'topRight',
                            duration: 15,
                        });
                        this.afterPDFSave();
                    } else {
                        let cpws: CPWS = responseInner;                        
                        const updateRelatedChanges = await this.getRelatedChanges(cpws.relatedChanges);

                        const productModalitySelections = this.ImportProductModalitySelection(
                            responseInner.productInformation.modalities,
                        );
                        cpws = { ...cpws, relatedChanges: updateRelatedChanges }
                        this.setCPWSState(CPWSStateChange.LOADED);
                        
                        this.setState(
                            {
                                ...this.state,
                                appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: 0 },
                                productModalitySelections: { ...productModalitySelections },
                                productColorTags: {
                                    ...this.state.productColorTags,
                                    initiallySelected: cloneDeep(cpws.productModality.selected),
                                    unmatched: cpws.productModality.unmatched,
                                },
                                siteColorTags: {
                                    ...this.state.siteColorTags,
                                    initiallySelected: cloneDeep(cpws.impactedSites.selected),
                                    unmatched: cpws.impactedSites.unmatched,
                                },
                                cpws: cpws,
                                staticProductInformation: cpws.productInformation,
                                status: 'proceed',
                            },
                            async () => {
                                this.setSessionID();
                                await this.setCPWSID();
                                this.setErrorBoundaryContext();
                            // this.loadSiteFunctions(cpws.productModality.selected);
                            // this.loadSiteFunctions(cpws.productInformation.products);
                                this.api.setIncomplete(cpws.cpwsId);
                            },
                        );
                        this.prev();
                        this.next();
                    }                    
                } else {
                    this.setState({ status: 'file_not_found' });
                    return {
                        loaded: false,
                        error: 'file_not_found',
                        callback: (): void => {
                            return;
                        },
                    };
                }
            }
        } catch (err) {
            console.log("Error in confirmedIngestAsync Function", err);
            //console.log(this.state.logger.setError)  
            //this.setErrorBoundaryContext();
            this.state.logger.setException(err);
            //console.log(cpwsID); 
            window.sessionStorage.setItem('cpwsID',cpwsID);
            //throw new Error(err);
            this.setState(() => {
                throw err;
            })
        }
    };

    confirmPopup = async (
        cpwsID: string,
        isid: string,
        firstName: string,
        lastName: string,
        time: string,
        complete: string,
        curStep: number,
        ingest: boolean,
    ): Promise<void> => {
        const currentUser: string = await this.getISID();

        const message = (
            <Space direction="vertical" size={4}>
                <Text>{`CAKE record for IPI ID ${cpwsID} already exists. Re-importing will overwrite this record and erase any previous work.`}</Text>
                <br />
                <div>
                    <WarningOutlined />
                    {isid && currentUser !== isid ? (
                        <Text>{`CAKE record for IPI ID ${cpwsID} was last modified by user ${lastName}, ${firstName} on ${time}.`}</Text>
                    ) : (
                        <Text>{`CAKE record for IPI ID ${cpwsID} was last modified on ${time}.`}</Text>
                    )}
                </div>
                <br />
                <Text>
                    <Text> Status: </Text>
                    <Text strong>{complete.toLocaleUpperCase() === 'COMPLETE' ? 'Completed' : 'In-Progress'}</Text>
                </Text>
                <Text>
                    <Text>Last Screen Accessed: </Text>
                    <Text strong>{`${curStep} - ${this.getScreenName(curStep)}`}</Text>
                </Text>
                <br />
                <Text>
                    <Text strong style={{ color: 'red' }}>{`Do you want to overwrite CAKE record for IPI ID ${cpwsID}?`} </Text>
                </Text>
            </Space>
        );
        Modal.confirm({
            icon: <ExclamationCircleOutlined style={{ display: 'none' }} />,
            content: message,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                ingest ? this.confirmedIngest() : this.confirmedUpload();
            },
            onCancel: () => {
                this.confirmCancel();
                //this.setState({status:'No Clicked'});
            },
            className: 'modal-style',
        });
    };
    confirmPopupAsync = async (
        cpwsID: string,
        isid: string,
        firstName: string,
        lastName: string,
        time: string,
        complete: string,
        curStep: number,
        ingest: boolean,
    ): Promise<void> => {
        const currentUser: string = await this.getISID();

        const message = (
            <Space direction="vertical" size={4}>
                <Text>{`CAKE record for IPI ID ${cpwsID} already exists. Re-importing will overwrite this record and erase any previous work.`}</Text>
                <br />
                <div>
                    <WarningOutlined />
                    {isid && currentUser !== isid ? (
                        <Text>{`CAKE record for IPI ID ${cpwsID} was last modified by user ${lastName}, ${firstName} on ${time}.`}</Text>
                    ) : (
                        <Text>{`CAKE record for IPI ID ${cpwsID} was last modified on ${time}.`}</Text>
                    )}
                </div>
                <br />
                <Text>
                    <Text> Status: </Text>
                    <Text strong>{complete.toLocaleUpperCase() === 'COMPLETE' ? 'Completed' : 'In-Progress'}</Text>
                </Text>
                <Text>
                    <Text>Last Screen Accessed: </Text>
                    <Text strong>{`${curStep} - ${this.getScreenName(curStep)}`}</Text>
                </Text>
                <br />
                <Text>
                    <Text strong style={{ color: 'red' }}>{`Do you want to overwrite CAKE record for IPI ID ${cpwsID}?`} </Text>
                </Text>
            </Space>
        );
        Modal.confirm({
            icon: <ExclamationCircleOutlined className='display-none' />,
            content: message,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                ingest ? this.confirmedIngestAsync(cpwsID) : this.confirmedUpload();
            },
            onCancel: () => {
                this.confirmCancel();
                //this.setState({status:'No Clicked'});
            },
            className: 'modal-style',
        });
    };

    // Edge case invalid cpws warning for cpws files that were once valid, but then removed from cpws system
    invalidCPWSWarning = async (): Promise<any> => {
        const msg1 = (
            <div>
                <p>{`The entered CPWS ID is not valid: `}</p>
            </div>
        );
        const msg2 = (
            <div>
                <br></br>
                <b>{`Please enter a valid CPWS ID and try again...`}</b>
            </div>
        );
        const message = (
            <div>
                {msg1} {msg2}
            </div>
        );
        Modal.confirm({
            title: `Invalid CPWS File`,
            content: message,
            okText: 'Okay',
        });
    };
    getRelatedChanges = async (relatedChanges: any) => {
        const response = await this.api.getRelatedChanges(relatedChanges);
        return response;
        // if(response)
        // {
        //     this.setState({ relatedChanges: response, isGetRelatedChangesAPISucess: true }, () => {
        //         this.sendSessionUpdate();
        //     })
        // }
    };
    /*ingestCPWSData = async (cpwsID: any) => {
        try {
            const response = await this.api.ingest(cpwsID, this.state.firstName, this.state.lastName);

            if (response) {
                // If locked == true reject alert user
                if (response.status === 'API_CONNECT_ERROR') {
                    console.log('API Connection Failed');
                    this.setState({ status: 'connection_error' });
                    return {
                        loaded: false,
                        error: 'connection_error',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'not_proceed') {
                    this.confirmPopup(
                        response.cpwsID,
                        response.isid,
                        response.lastAccessedUserFirstName,
                        response.lastAccessedUserLastName,
                        response.time,
                        response.complete,
                        Number(response.curStep),
                        true,
                    );

                    this.setState({ status: 'not_proceed', tempID: cpwsID, cpwsCompleted: response.complete });
                    return {
                        loaded: false,
                        error: 'not_proceed',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'INVALID_CPWS_ID') {
                    this.setState({ status: 'INVALID_CPWS_ID' });
                    return {
                        loaded: false,
                        error: 'INVALID_CPWS_ID',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'locked') {
                    this.setState({ status: 'locked' });
                    return {
                        loaded: false,
                        locked_by_user: response.locked_by_user,
                        lockedByUserFirstName: response.lockedByUserFirstName,
                        lockedByUserLastName: response.lockedByUserLastName,
                        error: 'file locked',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'proceed') {
                    this.checkForCPWSFile();
                    this.reset();
                    let cpws: CPWS = response;
                    const updateRelatedChanges = await this.getRelatedChanges(cpws.relatedChanges);
                    cpws = { ...cpws, relatedChanges: updateRelatedChanges };
                    const productModalitySelections = this.generateProductModalitySelection(
                        cpws.productModality.selected,
                    );
                    this.setCPWSState(CPWSStateChange.LOADED);

                    this.setState(
                        {
                            ...this.state,
                            appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: 0 },
                            productModalitySelections: { ...productModalitySelections },
                            productColorTags: {
                                ...this.state.productColorTags,
                                initiallySelected: cloneDeep(cpws.productModality.selected),
                                unmatched: cpws.productModality.unmatched,
                            },
                            siteColorTags: {
                                ...this.state.siteColorTags,
                                initiallySelected: cloneDeep(cpws.impactedSites.selected),
                                unmatched: cpws.impactedSites.unmatched,
                            },
                            cpws: cpws,
                           // staticProductInformation: response.product_information,
                            status: 'proceed',
                        },
                        async () => {
                            this.setSessionID();
                            await this.setCPWSID();
                            this.setErrorBoundaryContext();
                            //this.loadSiteFunctions(cpws.productModality.selected);
                            this.api.setIncomplete(cpws.cpwsId);
                        },
                    );

                    return {
                        response,
                        callback: (): void => {
                            this.prev();
                            this.next();
                        },
                        loaded: true,
                    };
                } else {
                    this.setState({ status: 'file_not_found' });
                    return {
                        loaded: false,
                        error: 'file_not_found',
                        callback: (): void => {
                            return;
                        },
                    };
                }
            } else {
                return {
                    loaded: false,
                    error: 'server error',
                    callback: (): void => {
                        return;
                    },
                };
            }
        } catch (e) {
            this.state.logger.setException(e);
        }
    };*/

    ingestCPWSDataAsync = async (cpwsID: any) => {
        try {
            const uuid = uuidv4();
            const response = await this.api.ingestAsync(cpwsID, this.state.firstName, this.state.lastName, uuid);

            if (response) {
                // If locked == true reject alert user
                if (response.status === 'API_CONNECT_ERROR') {
                    console.log('API Connection Failed');
                    this.setState({ status: 'connection_error' });
                    return {
                        loaded: false,
                        error: 'connection_error',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'not_proceed') {
                    this.confirmPopupAsync(
                        response.cpwsID,
                        response.isid,
                        response.lastAccessedUserFirstName,
                        response.lastAccessedUserLastName,
                        response.time,
                        response.complete,
                        Number(response.curStep),
                        true,
                    );

                    this.setState({ status: 'not_proceed', tempID: cpwsID, cpwsCompleted: response.complete });
                    return {
                        loaded: false,
                        error: 'not_proceed',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'INVALID_CPWS_ID') {
                    this.setState({ status: 'INVALID_CPWS_ID' });
                    return {
                        loaded: false,
                        error: 'INVALID_CPWS_ID',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'locked') {
                    this.setState({ status: 'locked' });
                    return {
                        loaded: false,
                        locked_by_user: response.locked_by_user,
                        lockedByUserFirstName: response.lockedByUserFirstName,
                        lockedByUserLastName: response.lockedByUserLastName,
                        error: 'file locked',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'proceed') {
                    const { tempID } = this.state;
                    let counter = 0;
                    const sleep = async () => {
                        return new Promise(resolve => setTimeout(resolve, 4000));
                    };
                    
                    let responseInner = await this.api.getIngestResponse(cpwsID, this.state.firstName, this.state.lastName, uuid);
                    while (responseInner.status != 'proceed' && counter < 225) {
                        if (responseInner.status === 'pending') {
                            counter++;
                            await sleep();
                            responseInner = await await this.api.getIngestResponse(cpwsID, this.state.firstName, this.state.lastName, uuid);
                        } else {
                            break;
                        }
                    }

                    if (responseInner.status === 'pending') {                                               
                        return 'error';
                    } else if (responseInner.status === 'API_CONNECT_ERROR' || 
                                responseInner.status === 'UNHANDLED_ERROR' || 
                                responseInner.status === 'INVALID_PROPOSAL') {
                        let tmpret = responseInner.status === 'INVALID_PROPOSAL' ? 'INVALID_CPWS_ID' : 'connection_error';          
                        return tmpret;
                    } else {
                        this.checkForCPWSFile();
                        this.reset();
                        
                        let cpws: CPWS = responseInner;
                        const updateRelatedChanges = await this.getRelatedChanges(cpws.relatedChanges);
                        cpws = { ...cpws, relatedChanges: updateRelatedChanges };
                        const productModalitySelections = this.ImportProductModalitySelection(
                            responseInner.productInformation.modalities,
                        );
                        this.setCPWSState(CPWSStateChange.LOADED);                        
                        this.setState(
                            {
                                ...this.state,
                                appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: 0 },
                                productModalitySelections: { ...productModalitySelections },
                                productColorTags: {
                                    ...this.state.productColorTags,
                                    initiallySelected: cloneDeep(cpws.productModality.selected),
                                    unmatched: cpws.productModality.unmatched,
                                },
                                siteColorTags: {
                                    ...this.state.siteColorTags,
                                    initiallySelected: cloneDeep(cpws.impactedSites.selected),
                                    unmatched: cpws.impactedSites.unmatched,
                                },
                                cpws: cpws,
                                staticProductInformation: responseInner.productInformation,
                                status: 'proceed',
                            },
                            async () => {
                                this.setSessionID();
                                await this.setCPWSID();
                                this.setErrorBoundaryContext();
                                //this.loadSiteFunctions(cpws.productModality.selected);
                                this.api.setIncomplete(cpws.cpwsId);
                            },
                        );

                        return {
                            response,
                            callback: (): void => {
                                this.prev();
                                this.next();
                            },
                            loaded: true,
                        };
                    }
                } else {
                    this.setState({ status: 'file_not_found' });
                    return {
                        loaded: false,
                        error: 'file_not_found',
                        callback: (): void => {
                            return;
                        },
                    };
                }
            } else {
                return {
                    loaded: false,
                    error: 'server error',
                    callback: (): void => {
                        return;
                    },
                };
            }
        } catch (e) {
            console.log("Error in ingestCPWSDataAsync Function", e);
            this.state.logger.setException(e);            
            window.sessionStorage.setItem('cpwsID',cpwsID);
            //throw new Error(e);
            this.setState(() => {
                throw e;
            })            
        }
    };

    sendCPWSFile = async (file: File, callback: Function): Promise<void> => {
        await this.loadS3Props();
        return await this.api.sendCpwsFiletoS3(file, this.state.s3props, callback);
    };

    loadCPWS = async (filename: string) => {
        try {
            const response = await this.api.getCPWSFromS3(filename);

            if (response) {
                if (response.status === 'locked') {
                    this.setState({ status: 'locked' });
                    return {
                        loaded: false,
                        locked_by_user: response.locked_by_user,
                        lockedByUserFirstName: response.locked_by_user_first_name,
                        lockedByUserLastName: response.locked_by_user_last_name,
                        error: 'file locked',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'not_proceed') {
                    //console.log('not proceed', response);
                    this.confirmPopup(
                        response.cpwsID,
                        response.isid,
                        response.lastAccessedUserFirstName,
                        response.lastAccessedUserLastName,
                        response.time,
                        response.complete,
                        Number(response.curStep),
                        false,
                    );

                    this.setState({ status: 'not_proceed', tempID: filename, cpwsCompleted: response.complete });
                    return {
                        loaded: false,
                        error: 'not_proceed',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'proceed') {
                    this.checkForCPWSFile();
                    this.reset();
                    //@@
                    const cpws: CPWS = response;

                    const productModalitySelections = this.generateProductModalitySelection(
                        cpws.productModality.selected,
                    );

                    this.setCPWSState(CPWSStateChange.LOADED);

                    this.setState(
                        {
                            ...this.state,
                            appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: 0 },
                            productModalitySelections: { ...productModalitySelections },
                            productColorTags: {
                                ...this.state.productColorTags,
                                initiallySelected: cloneDeep(cpws.productModality.selected),
                                unmatched: cpws.productModality.unmatched,
                            },
                            siteColorTags: {
                                ...this.state.siteColorTags,
                                initiallySelected: cloneDeep(cpws.impactedSites.selected),
                                unmatched: cpws.impactedSites.unmatched,
                            },
                            cpws: cpws,
                            status: 'proceed',
                        },
                        async () => {
                            this.setSessionID();
                            await this.setCPWSID();
                            this.setErrorBoundaryContext();
                            //this.loadSiteFunctions(cpws.productModality.selected);
                            this.api.setIncomplete(cpws.cpwsId);
                        },
                    );

                    return {
                        response,
                        callback: (): void => {
                            this.prev();
                            this.next();
                        },
                        loaded: true,
                    };
                } else {
                    this.setState({ status: 'file_not_found' });
                    return {
                        loaded: false,
                        error: 'file_not_found',
                        callback: (): void => {
                            return;
                        },
                    };
                }
            } else {
                return {
                    loaded: false,
                    error: 'server error',
                    callback: (): void => {
                        return;
                    },
                };
            }
        } catch (e) {
            console.log("Error in loadCPWS Function", e)
            this.state.logger.setException(e);
            window.sessionStorage.setItem('cpwsID',filename);
            //throw new Error(e);
            this.setState(() => {
                throw e;
            });
        }
    };

    confirmCancel = async () => {
        this.reset();
        this.setState({status:'noClicked'});
    }

    // Only used after initial upload call
    confirmedUpload = async () => {
        this.checkForCPWSFile();
        this.reset();
        const { tempID } = this.state;

        try {
            const response = await this.api.uploadOverwrite(tempID);
            if (response) {
                if (response.status === 'locked') {
                    this.setState({ status: 'locked' });
                    return {
                        loaded: false,
                        locked_by_user: response.locked_by_user,
                        error: 'file locked',
                        callback: (): void => {
                            return;
                        },
                    };
                } else if (response.status === 'proceed') {
                    const cpws: CPWS = response;
                    const productModalitySelections = this.generateProductModalitySelection(
                        cpws.productModality.selected,
                    );

                    this.setCPWSState(CPWSStateChange.LOADED);
                    this.setState(
                        {
                            ...this.state,
                            appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: 0 },
                            productModalitySelections: { ...productModalitySelections },
                            productColorTags: {
                                ...this.state.productColorTags,
                                initiallySelected: cloneDeep(cpws.productModality.selected),
                                unmatched: cpws.productModality.unmatched,
                            },
                            siteColorTags: {
                                ...this.state.siteColorTags,
                                initiallySelected: cloneDeep(cpws.impactedSites.selected),
                                unmatched: cpws.impactedSites.unmatched,
                            },
                            cpws: cpws,
                            status: 'proceed',
                        },
                        async () => {
                            this.setSessionID();
                            await this.setCPWSID();
                            this.setErrorBoundaryContext();
                            //this.loadSiteFunctions(cpws.productModality.selected);
                            this.api.setIncomplete(cpws.cpwsId);
                        },
                    );
                    this.prev();
                    this.next();
                } else {
                    this.setState({ status: 'file_not_found' });
                    return {
                        loaded: false,
                        error: 'file_not_found',
                        callback: (): void => {
                            return;
                        },
                    };
                }
            }
        } catch (err) {
            console.log("Error in confirmedUpload Function", err);
            this.state.logger.setException(err);
        }
    };

    /*onRegulatoryQuestionSelection = (selection: boolean, question: string): void => {
        if (question == 'noToAll') {
            const tempRegulatoryQuestions = mapValues(
                this.state.regulatoryQuestions,
                (val: RegulatoryQuestionValue) => ({
                    ...val,
                    isAnswered: true,
                    answer: false,
                    ...(Object.prototype.hasOwnProperty.call(val, 'children') && {
                        children: mapValues(val.children, () => ({
                            isAnswered: false,
                            answer: false,
                        })),
                    }),
                }),
            );
            const [highestAvailable] = this.calculateHighestAvailable(
                tempRegulatoryQuestions,
                this.state.justification,
                this.state.cpws.impactedMaterials,
                this.state.qaQuestionAnswered,
                this.state.classificationList,
                this.state.matchCriteria,
                this.state.matchCriteriaAnswers,
                this.state.assessmentResults,
                this.state.impactedCountries,
                this.state.siteFunctions,
                this.state.userSelectedMaterials,
            );
            console.log("calculateHighestAvailable in 2752 after::", highestAvailable);
            this.setState({
                regulatoryQuestions: tempRegulatoryQuestions,
                appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: highestAvailable as number },
            });
            return;
        }
        if (selection) {
            const tempRegulatoryQuestions = mapValues(
                this.state.regulatoryQuestions,
                (val: RegulatoryQuestionValue) => ({
                    ...val,
                    isAnswered: true,
                    answer: false,
                    ...(Object.prototype.hasOwnProperty.call(val, 'children') && {
                        children: mapValues(val.children, () => ({
                            isAnswered: false,
                            answer: false,
                        })),
                    }),
                }),
            );

            if (question.includes('ChildQuestion')) {
                const parent = question.slice(0, 9);
                const child = `c${question.slice(10)}`;

                tempRegulatoryQuestions[parent].answer = true;
                tempRegulatoryQuestions[parent].isAnswered = true;
                (tempRegulatoryQuestions[parent].children as RegulatoryQuestions)[child].answer = true;
                (tempRegulatoryQuestions[parent].children as RegulatoryQuestions)[child].isAnswered = true;
            } else {
                tempRegulatoryQuestions[question].answer = true;
                tempRegulatoryQuestions[question].isAnswered = true;
            }
            const [highestAvailable] = this.calculateHighestAvailable(
                tempRegulatoryQuestions,
                this.state.justification,
                this.state.cpws.impactedMaterials,
                this.state.qaQuestionAnswered,
                this.state.classificationList,
                this.state.matchCriteria,
                this.state.matchCriteriaAnswers,
                this.state.assessmentResults,
                this.state.impactedCountries,
                this.state.siteFunctions,
                this.state.userSelectedMaterials,
            );
            console.log("calculateHighestAvailable in 2801 after::", highestAvailable);
            this.setState({
                regulatoryQuestions: tempRegulatoryQuestions,
                appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: highestAvailable as number },
            });
        } else {
            let updatedRegQues = {};
            if (question.includes('ChildQuestion')) {
                const parent = question.slice(0, 9);
                const child = `c${question.slice(10)}`;
                updatedRegQues = {
                    ...this.state.regulatoryQuestions,
                    [parent]: {
                        ...this.state.regulatoryQuestions[parent],
                        children: {
                            ...this.state.regulatoryQuestions[parent].children,
                            [child]: {
                                isAnswered: true,
                                answer: selection,
                            },
                        },
                    },
                };
            } else {
                updatedRegQues = {
                    ...this.state.regulatoryQuestions,
                    [question]: {
                        ...this.state.regulatoryQuestions[question],
                        isAnswered: true,
                        answer: selection,
                    },
                };
            }
            const [highestAvailable] = this.calculateHighestAvailable(
                updatedRegQues,
                this.state.justification,
                this.state.cpws.impactedMaterials,
                this.state.qaQuestionAnswered,
                this.state.classificationList,
                this.state.matchCriteria,
                this.state.matchCriteriaAnswers,
                this.state.assessmentResults,
                this.state.impactedCountries,
                this.state.siteFunctions,
                this.state.userSelectedMaterials,
            );
            console.log("calculateHighestAvailable in 2848 after:: ", highestAvailable);
            this.setState(
                {
                    regulatoryQuestions: updatedRegQues,
                    appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: highestAvailable as number },
                },
                function () {
                },
            );
        }
    };*/

    /*onJustificationChange = (textValue: string): void => {
        this.setState({ justification: textValue });
    };*/

    loadImpactedMaterialsExternal = async (): Promise<void> => {
        if (this.state.formFields.viewOnly) {
            return;
        }

        try {
            const impactedMaterialsExternal = await this.api.getImpactedMaterialsExternal();

            if (impactedMaterialsExternal) {
                let filteredExternalMaterials = filterByObjectKey(impactedMaterialsExternal, 'number');
                //filteredExternalMaterials = cleanObjectValue(filteredExternalMaterials, 'name', /^\s*|[^a-zA-Z0-9_' ]/gm);
                filteredExternalMaterials = sortObjectArrayByValue(filteredExternalMaterials, 'name');

                this.setState({ impactedMaterialsExternal: filteredExternalMaterials });

                return;
            }
        } catch (err) {
            console.log("Error in loadImpactedMaterialsExternal Function", err);
            this.setState(() => {
                throw err;
            })
        }
    };

    onProductModalityChange = (value: keyof ProductModalitySelections, checked: boolean) => {
        this.setState({
            productModalitySelections: { ...this.state.productModalitySelections, [value]: checked },
            appCacheFlags: { ...this.state.appCacheFlags, loadMatchCriteria: true, highestAvailable: 2 },
            // user_nri_ri: " "
        });
        console.log('value-onproduct-change', value)
    };

    updateProductModalityChange = (value: keyof ProductModalitySelections, checked: boolean) => {
        this.setState({
            productModalitySelections: { ...this.state.productModalitySelections, [value]: checked }
        });
    };

    loadImpactedMaterials = async (): Promise<void> => {
        // reset cacheFlags loadImpactedMaterials so that we call api again if user has changed selections
        // appCacheFlags.loadImpactedMaterials = true;
        if (this.state.formFields.viewOnly) {
            return;
        }
        if (this.state.appCacheFlags.loadImpactedMaterials) {
            try {
                const impactedMaterialsAvailable = await this.api.getImpactedMaterials(
                    this.state.cpws.productModality.selected,
                    this.state.cpws.impactedSites.selected,
                    this.state.category,
                );
                if (impactedMaterialsAvailable) {
                    const appCacheFlags = this.state.appCacheFlags;
                    appCacheFlags.loadImpactedMaterials = false;
                    // sort impacted materials here because we grab the keys on componentDidMount for screen 2
                    impactedMaterialsAvailable.sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1;
                        }
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });

                    this.setState({ ...this.state, impactedMaterialsAvailable, appCacheFlags });
                    return;
                }
            } catch (e) {
                console.log("Error in loadImpactedMaterials Function", e)
                this.state.logger.setException(e);                
                this.setState(() => {
                    throw e;
                })
            }
            this.setState({ impactedMaterialsAvailable: [] });
        }
    };

    /*loadProductsFamilySitesMaterial = async (): Promise<void> => {
        if (this.state.formFields.viewOnly) {
            return;
        }
        if (this.state.appCacheFlags.loadProductsFamilySitesMaterial) {
            try {
                const currentCpws: CPWS = this.state.cpws;
                const { productModality, impactedMaterials } = currentCpws;
                if (productModality?.selected && impactedMaterials) {
                    const downstream = await this.api.getProductsFamilySitesMaterial(
                        productModality.selected,
                        impactedMaterials,
                    );
                    if (downstream) {
                        const appCacheFlags = this.state.appCacheFlags;
                        appCacheFlags.loadProductsFamilySitesMaterial = false;
                        const { products, sites } = downstream;

                        this.setState({
                            ...this.state,
                            downstreamProducts: products,
                            downstreamSites: sites,
                            appCacheFlags,
                        });
                    }
                }
            } catch (e) {
                this.state.logger.setException(e);
            }
        }
        return;
    };*/


    //load_additional_info api call.
    loadAdditionalInfo = async (): Promise<void | number> => {
        if (this.state.formFields.viewOnly) {
            return;
        }        
        if (this.state.appCacheFlags.loadAdditionalInfo) {
            try {
                const currentCpws = { ...this.state.cpws };
                if (
                    // currentCpws?.productModality?.selected &&
                    currentCpws?.impactedSites?.selected

                ) {
                    const response = await this.api.getAdditionalInfo(

                        currentCpws.productInformation.products,
                        currentCpws.impactedSites.selected
                    );

                    if (response && typeof response !== 'number') {
                        const { impactedCountries } = response;

                        // const updatedImpactedMaterials = impactedMaterials
                        //     .sort((a: Material, b: Material) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                        //     .map((mat: Material) => {
                        //         const currentMaterial = find(currentCpws.impactedMaterials, { number: mat.number });

                        //         if (currentMaterial) {
                        //             return {
                        //                 ...mat,
                        //                 ...currentMaterial,
                        //             };
                        //         } else {
                        //             return mat;
                        //         }
                        //     });

                        // currentCpws.impactedMaterials = updatedImpactedMaterials;

                        const appCacheFlags = this.state.appCacheFlags;
                        appCacheFlags.loadAdditionalInfo = false;

                        //@@@
                        this.setState({
                            ...this.state,
                            cpws: currentCpws,
                            impactedCountries,
                            //fvdownstreamMaterials,
                            appCacheFlags,
                        }, () => { 
                        });
                    } else if (typeof response === 'number' && response === 0) {
                        return 0;
                    }
                }
            } catch (e) {
                console.log("Error in loadAdditionalInfo Function", e)
                this.state.logger.setException(e);               
                this.setState(() => {
                    throw e;
                })
            }
        }
        return;
    };    

    loadClassificationList = async (): Promise<string> => {
        console.log("load Classification List called");
        const appCacheFlags = this.state.appCacheFlags;

        if (this.state.formFields.viewOnly) {
            return 'processed';
        }

        let counter = 0;
        const sleep = async () => {
            return new Promise(resolve => setTimeout(resolve, 3000));
        };

        if (this.state.appCacheFlags.loadClassificationListResponse) {
            try {
                let response = await this.api.getClassificationList(this.state.materialClassificationUuid);

                while (this.state.appCacheFlags.loadClassificationListResponse && counter < 10) {
                    if (response.status === 'pending') {
                        counter++;
                        await sleep();
                        response = await this.api.getClassificationList(this.state.materialClassificationUuid);
                    } else {
                        break;
                    }
                }

                if (response.status === 'pending') {
                    console.log("Response is still pending")
                    this.state.logger.setError('The classification list was not retrieved.');
                    notification.warning({
                        message: 'The classification list was not retrieved',
                        description: 'The result is still pending',
                        placement: 'topRight',
                        duration: 15,
                    });
                    appCacheFlags.loadClassificationListResponse = false;
                    this.setState({
                        appCacheFlags
                    });
                    return 'error';
                } else if (response.status === 'error') {
                    notification.warning({
                        message: 'Error while fetching classification list',
                        description: 'An error was returned',
                        placement: 'topRight',
                        duration: 15,
                    });
                    appCacheFlags.loadClassificationListResponse = false;
                    this.state.logger.setError('Error while fetching classification list');
                    this.setState({
                        appCacheFlags
                    });
                    return 'error';
                } else {
                    const classificationList = response.result;
                    if (classificationList) {
                        appCacheFlags.loadClassificationListResponse = false;

                        const updatedMaterials = this.state.userSelectedMaterials.map((material: any) => {
                            // check if impacted materials classfication list already exists before assigning
                            material.classification = cloneDeep(classificationList);
                            const dAndCCode = this.getClassificationCode('Description and/or Composition');
                            if (
                                dAndCCode !== 'Description and/or Composition' &&
                                material.classification[dAndCCode] === undefined
                            ) {
                                material.classification[dAndCCode] = 0;
                            }

                            return material;
                        });
                        const updatedImpactedMaterials = this.state.cpws.impactedMaterials.map((material: any) => {
                            // check if impacted materials classfication list already exists before assigning
                            material.classification = cloneDeep(classificationList);
                            const dAndCCode = this.getClassificationCode('Description and/or Composition');
                            if (
                                dAndCCode !== 'Description and/or Composition' &&
                                material.classification[dAndCCode] === undefined
                            ) {
                                material.classification[dAndCCode] = 0;
                            }

                            return material;
                        });
                        //@@
                        this.setState({
                            cpws: {
                                ...this.state.cpws,
                                impactedMaterials: updatedImpactedMaterials,
                            },
                            userSelectedMaterials: updatedMaterials,
                            classificationList,
                            appCacheFlags
                        });
                        return 'processed';
                    } else {
                        this.state.logger.setError(
                            'The classification list was null upon fetching. Get classificaiton list should always return an object contaning classifications.',
                        );
                        return 'error';
                    }
                }
            } catch (e) {
                console.log("Error in loadClassificationList Function", e)
                this.state.logger.setException(e);                
                this.setState(() => {
                    throw e;
                })
            }
        }
        return 'notRun';
    };


    postClassificationData = async () => {
        const appCacheFlags = this.state.appCacheFlags;
        const uuid = uuidv4();

        if (this.state.formFields.viewOnly) {
            return;
        }

        if (appCacheFlags.loadClassificationList) {
            const responseStatus = await this.api.postClassificationData(this.state.cpws, uuid);
            //console.log(responseStatus);
            this.setState({
                materialClassificationUuid: uuid,
                appCacheFlags: {
                    ...this.state.appCacheFlags,
                    loadClassificationList: false,
                    loadClassificationListResponse: true,
                },
            });
            return responseStatus;
        }
    };

    // postGetFinalResultsData = async () => {
    //     const appCacheFlags = this.state.appCacheFlags;
    //     const uuid = uuidv4();

    //     if (this.state.formFields.viewOnly) {
    //         return;
    //     }

    //     if (appCacheFlags.loadClassificationList) {
    //         const responseStatus = await this.api.postGetFinalResultsData(this.state.cpws, uuid);
    //         console.log(responseStatus);
    //         // this.setState({
    //         //     materialClassificationUuid: uuid,
    //         //     appCacheFlags: {
    //         //         ...this.state.appCacheFlags,
    //         //         loadClassificationList: false,
    //         //         loadClassificationListResponse: true,
    //         //     },
    //         // });
    //         return responseStatus;
    //     }
    // };

    loadClassificationListFallback = () => {
        // console.log("Load Classification List Fallback called");
        const classificationList = this.state.classificationCodes.reduce(
            (obj: CPWSClassification, codeObj: CPWSClassificationCode) => {
                if (!(codeObj.code in obj)) {
                    obj[codeObj.code] = 0;
                }
                return obj;
            },
            {},
        );

        const impactedMaterials = this.state.cpws.impactedMaterials.map(material => {
            material.classification = cloneDeep(classificationList);
            const dAndCCode = this.getClassificationCode('Description and/or Composition');
            if (dAndCCode !== 'Description and/or Composition' && material.classification[dAndCCode] === undefined) {
                material.classification[dAndCCode] = 0;
            }

            return material;
        });
        
        this.setState({
            classificationList: classificationList,
            cpws: {
                ...this.state.cpws,
                impactedMaterials: impactedMaterials,
            },
            appCacheFlags: {
                ...this.state.appCacheFlags,
                loadClassificationListResponse: false,
                loadClassificationList: false,
            },
        });
    };

    loadMatchCriteria = async (): Promise<void> => {
        if (this.state.formFields.viewOnly) {
            return;
        }        

        if (this.state.appCacheFlags.loadMatchCriteria) {
            try {
                const {
                    cpws: currentCpws,//@@
                    impactedCountries,
                    category,
                    sterilization,
                    productModalitySelections,
                    userSelectedMaterials,
                } = this.state;
                const id = currentCpws.cpwsId;
                const matchCriteria = await this.api.getMatchCriteria(
                    id,
                    currentCpws,
                    impactedCountries,
                    category,
                    sterilization,
                    productModalitySelections,
                    userSelectedMaterials,
                );
                if (matchCriteria) {
                    const appCacheFlags = this.state.appCacheFlags;
                    appCacheFlags.loadMatchCriteria = false;

                    const matchCriteriaAnswers: MatchCriteriaAnswer = {};
                    const materialCodeMapping: MaterialCodeMapping = {};
                    matchCriteria.forEach((question: Question) => {
                        if (!(`${question.materialTypeCode}_${question.parentCode}` in matchCriteriaAnswers)) {
                            matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`] = {
                                answer: '',
                                isAnswered: false,
                                isContainerClosure: question.isContainerClosure,
                                classificationLabel: question.classificationLabel,
                            };
                        }

                        if (!(question.materialTypeCode in materialCodeMapping)) {
                            materialCodeMapping[question.materialTypeCode] = question.materialTypeLabel;
                        }
                    });

                    this.setState({
                        ...this.state,
                        matchCriteria,
                        appCacheFlags,
                        matchCriteriaAnswers,
                        materialCodeMapping,
                    });
                }
            } catch (e) {
                console.log("Error in loadMatchCriteria Function", e);
                this.state.logger.setException(e);                
                this.setState(() => {
                    throw e;
                })
            }
        }
        return;
    };

    fetchApiResponse = async (api_name:any,api_params:any) => {
        let response:any;
        if(api_name === 'loadFinalResponse'){
            response =  await this.api.getFinalResultsResponse(api_params.cpws, api_params.uuid);
        } else if(api_name === 'matchCriteriaRegResponse'){
            response =  await this.api.getMatchCriteriaYesRegulationResponse(api_params.uuid);
        } 
        return response;
    }

    asyncApiCallRender = async (api_name:any,api_params:any,time_interval:number,max_time:number) => {
        let counter = 0;
        const custom_counter = max_time * 60 * 1000/time_interval;
        console.log("custom_counter", custom_counter)
        const sleep = async () => {
            return new Promise(resolve => setTimeout(resolve, time_interval));
        };
        
        let response:any = await this.fetchApiResponse(api_name,api_params);        
        while (response.status != 'processed' && counter < custom_counter && window.sessionStorage.getItem("ApiFlagError") != "true") {
            if (response.status === 'pending') {
                counter++;
                await sleep();
                response = await this.fetchApiResponse(api_name,api_params);
            }             
            else if (response.status === 'error'){
                notification.error({
                    message: 'Error while fetching IPI Data',
                    description: `An error was returned`,
                    placement: 'topRight',
                    duration: 5,
                    className: 'nav-warnings',
                });
                break;
            }
            else {
                break;
            }
        }

        if (response.length == 0 && typeof(response) != "undefined") {
                notification.warning({
                    message: 'IPI Data was not retrieved',
                    description: `The result is still pending`,
                    placement: 'topRight',
                    duration: 5,
                    className: 'nav-warnings',
        });
    }

        return response

    }

    loadFinalResults = async (): Promise<void> => {
        // if (this.state.formFields.viewOnly) {
        //     return;
        // }
        const uuid = uuidv4();
        const appCacheFlags = this.state.appCacheFlags;
        appCacheFlags.loadFinalResults = true;
        if (this.state.appCacheFlags.loadFinalResults) {
            try {
                const {
                    impactedCountries,
                    sterilization,
                    category,
                    cpws: currentCpws,
                    matchCriteriaAnswers: answers,
                    productModalitySelections,
                    classificationCodes,
                    matchCriteria,
                    assessments
                } = this.state;

                const responseStatus = await this.api.getFinalResults(
                    sterilization,
                    category,
                    currentCpws,
                    impactedCountries,
                    answers,
                    productModalitySelections,
                    classificationCodes,
                    matchCriteria,
                    assessments,
                    uuid
                );

                // let assesment_api_counter = 0;
                // const assesment_api_sleep = async () => {
                //     return new Promise(resolve => setTimeout(resolve, 4000));
                // };

                // let assessmentResults:any = await this.api.getFinalResultsResponse(this.state.cpws, uuid);
                
                // while (assessmentResults.status != 'proceed' && assesment_api_counter < 45) {
                //     if (assessmentResults.status == 'pending') {
                //         assesment_api_counter++;
                //         console.log("assesment_api_counter", assesment_api_counter)
                //         await assesment_api_sleep();
                //         assessmentResults = await await this.api.getFinalResultsResponse(this.state.cpws, uuid);
                //     } else {
                //         break;
                //     }
                // }
                let assessmentResults:any = await this.asyncApiCallRender('loadFinalResponse',{cpws:this.state.cpws,uuid:uuid},3000,2);
                if(assessmentResults) {
                    const appCacheFlags = this.state.appCacheFlags;
                    this.setState({
                        ...this.state,
                        assessments: assessmentResults.assessments,
                        directionalAssessment: assessmentResults.directionalAssesment,
                        dataRequirements: assessmentResults.dataRequirements,
                        //dataRequirements: assessmentResults.data_requirements,
                        marketLevelAssessment: assessmentResults.marketLevelAssessment,                        
                        appCacheFlags,
                        system_nri_ri: assessmentResults.system_nri_ri
                    })
                }             
            } catch (e) {
                console.log("Error in loadFinalResults Function", e)
                this.state.logger.setException(e);               
                this.setState(() => {
                    throw e;
                })
            }
        }
        return;
    };

    openAttachment = async (fileLink: string): Promise<{ presigned_url: string } | undefined> => {
        let response;
        try {
            response = this.api.getAttachment(fileLink);
        } catch (err) {
            console.log("Error in Open Attachment", err);
            this.state.logger.setException(err);
        }
        return response;
    };

    loadMatchCriteriaYesRegulation = async (): Promise<void> => {
        const uuid = uuidv4();
        if (this.state.formFields.viewOnly) {
            return;
        }

        try {
            const {
                impactedCountries,
                sterilization,
                category,
                cpws: currentCpws,
                matchCriteriaAnswers: answers,
                productModalitySelections,
                classificationCodes,
                matchCriteria,
            } = this.state;

            const matchCriteriaYesRegulationAsync:any = await this.api.getMatchCriteriaYesRegulation(
                sterilization,
                category,
                currentCpws,
                impactedCountries,
                answers,
                productModalitySelections,
                classificationCodes,
                matchCriteria,
                uuid
            );

            // let counter = 0;
            // const sleep = async () => {
            //     return new Promise(resolve => setTimeout(resolve, 4000));
            // };

            //  let matchCriteriaYesRegulationRes = await this.api.getMatchCriteriaYesRegulationResponse(uuid);
            
            // while (matchCriteriaYesRegulationRes.status != 'proceed' && counter < 45) {
            //     if (matchCriteriaYesRegulationRes.status == 'pending') {
            //         counter++;
            //         console.log("assesment_api_counter",counter)
            //         await sleep();
            //         matchCriteriaYesRegulationRes = await await this.api.getMatchCriteriaYesRegulationResponse(uuid);
            //     } else {
            //         break;
            //     }
            // }

            let matchCriteriaYesRegulationRes:any = await this.asyncApiCallRender('matchCriteriaRegResponse',{cpws:this.state.cpws,uuid:uuid},3000,2);

            if (matchCriteriaYesRegulationRes) {
                this.setState({ matchCriteriaYesRegulation: matchCriteriaYesRegulationRes });
            }
        } catch (err) {
            console.log("Error in loadMatchCriteriaYesRegulation Function", err);
        }
        return;
    };
    // Screen 3 Load Impacted Material Type with Counts
    loadImpactedMaterialTypeWithCount = async (): Promise<void> => {
        if (this.state.formFields.viewOnly === true) {
            return;
        }

        try {
            const { category } = this.state;
            const currentCpws = this.state.cpws;
            const selectedModalities = this.state.productModalitySelections;

            if (currentCpws?.impactedMaterials) {
                const materialTypeCount = await this.api.getProductMaterialTypeWithCount(
                    currentCpws.impactedMaterials,
                    category,
                    selectedModalities,
                );

                if (materialTypeCount) {
                    this.setState({ impactedMaterialType: materialTypeCount });
                }
            }
        } catch (err) {
            console.log("Error in loadImpactedMaterialTypeWithCount Function", err);
        }
    };
    loadImpactedProductMaterialTypeWithCount = async (): Promise<void> => {
        console.log("User selected materials:", this.state.userSelectedMaterials);
        if (this.state.formFields.viewOnly) {
            return;
        }
        try {
            const { category } = this.state;
            const currentCpws = this.state.cpws;
            const selectedModalities = this.state.productModalitySelections;

            if (this.state.userSelectedMaterials.length > 0) {
                const materialTypeCount = await this.api.getProductMaterialTypeWithCount(
                    this.state.userSelectedMaterials,
                    category,
                    selectedModalities,
                );

                if (materialTypeCount) {
                    this.setState({
                        ...this.state, impactedMaterialType: materialTypeCount,
                        impactedProductMaterials: this.state.userSelectedMaterials
                    });
                }
            }
        } catch (err) {
            console.log("Error in loadImpactedProductMaterialTypeWithCount Function", err);
        }
    };

    loadContainerClosureMaterialType = async (downstreamMaterials: Array<ContainerClosureMaterial>) => {
        if (this.state.formFields.viewOnly === true) {
            return;
        }

        try {
            const getMaterialTypeCountForDownstream = await this.api.getProductMaterialTypeWithCount(
                this.state.impactedProductMaterials,
                this.state.category,
                this.state.productModalitySelections,
            );
            return getMaterialTypeCountForDownstream;
        } catch (err) {
            console.log("Error in loadContainerClosureMaterialType Function", err);
        }
    };

    // saving for future use case
    // saveImpactedMaterialTypeWithCount = async (): Promise<void> => {
    //     const { cpws } = this.state;
    //     const response = await this.api.saveSelectedMaterialTypes(cpws)
    // }

    //api calls end

    //state calls start
    setCPWSState(stateChange: CPWSStateChange): void {
        //console.log("setCPWSState called", stateChange);
        switch (stateChange) {
            case CPWSStateChange.LOADED: {
                this.setState({
                    appCacheFlags: {
                        ...this.state.appCacheFlags,
                        loadCpws: false,
                        loadProductsAvailable: true,
                        loadSitesAvailable: true,
                        loadImpactedMaterials: true,
                        loadAdditionalInfo: true,
                        loadClassificationList: true,
                        loadClassificationListResponse: false,
                        loadProductsFamilySitesMaterial: true,
                        loadMatchCriteria: true,
                        loadFinalResults: true,
                    },
                });
                break;
            }
            case CPWSStateChange.PRODUCTS_UPDATED:
            case CPWSStateChange.SITES_UPDATED: {
                /*if (this.state.current > 3) {
                    this.setState({
                        appCacheFlags: {
                            ...this.state.appCacheFlags,
                            loadImpactedMaterials: true,
                            loadAdditionalInfo: true,
                            loadClassificationList: false,
                            loadClassificationListResponse: true,
                            loadProductsFamilySitesMaterial: true,
                            loadMatchCriteria: true,
                            loadFinalResults: true,
                        },
                    });
                } else {
                    this.setState({
                        appCacheFlags: {
                            ...this.state.appCacheFlags,
                            loadImpactedMaterials: true,
                            loadAdditionalInfo: true,
                            loadClassificationList: true,
                            loadClassificationListResponse: false,
                            loadProductsFamilySitesMaterial: true,
                            loadMatchCriteria: true,
                            loadFinalResults: true,
                        },
                    });
                }*/
                this.setState({
                        appCacheFlags: {
                            ...this.state.appCacheFlags, 
                            loadImpactedMaterials: true,
                            loadAdditionalInfo: true,  
                            loadProductsFamilySitesMaterial: true,                         
                            loadMatchCriteria: true,
                            loadFinalResults: true,
                        },
                    });
                break;
            }
            case CPWSStateChange.MATERIALS_UPDATED: {
                this.setState({
                    appCacheFlags: {
                        ...this.state.appCacheFlags,
                        loadImpactedMaterials: false,
                        loadAdditionalInfo: true,
                        loadClassificationList: false,
                        loadClassificationListResponse: true,
                        loadProductsFamilySitesMaterial: true,
                        loadMatchCriteria: true,
                        loadFinalResults: true,
                    },
                });

                break;
            }
        }
    }

    /*updateImpactedSiteFunctions(newSiteFunctions: Array<SiteFunction>) {
        const reducer = (newSites: Array<Site>, site: Site) => {
            if (!site.isPDS) {
                newSites.push(site);
                return newSites;
            }

            if (findIndex(newSiteFunctions, ['name', site.name]) === -1) {
                return newSites;
            }

            const siteSpecificFunctions = find(newSiteFunctions, ['name', site.name]);
            if (siteSpecificFunctions) {
                newSites.push({
                    ...site,
                    siteFunctions: siteSpecificFunctions.siteFunctions,
                });
            }
            return newSites;
        };

        const selectedSites = this.state.cpws.impactedSites.selected.reduce(reducer, []);
        //@@
        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedSites: {
                    ...this.state.cpws.impactedSites,
                    selected: selectedSites,
                },
            },
        });
    }*/

    async productStateChange(products: Array<ProductModality>): Promise<void> {
        const cpws = this.state.cpws;
        const productModalitySelections = this.generateProductModalitySelection(products);
        if (cpws) {
            if (cpws.productModality) {
                cpws.productModality.selected = products;
                this.setState({
                    ...this.state,
                    cpws,
                    productModalitySelections: { ...productModalitySelections },
                });
                this.setCPWSState(CPWSStateChange.PRODUCTS_UPDATED);
                //const newSiteFunctions = await this.loadSiteFunctions(products);
                //this.updateImpactedSiteFunctions(newSiteFunctions);
            }
        }
    }
    async siteStateChange(sites: Array<Site>, callback?: Function, siteType?: 'cpws' | 'pds'): Promise<void> {
        const cpws = this.state.cpws;
        if (cpws) {
            if (cpws.impactedSites) {
                cpws.impactedSites.selected = sites;
                this.setState(
                    {
                        ...this.state,
                        cpws,
                    },
                    () => {
                        if (callback) {
                            callback();
                        }
                    },
                );
                if (siteType === 'pds' && this.state.current === 1) {
                    this.setState({
                        appCacheFlags: {
                            ...this.state.appCacheFlags,
                            loadAdditionalInfo: true,
                            loadImpactedMaterials: true,
                        },
                    });
                } else {
                    this.setCPWSState(CPWSStateChange.PRODUCTS_UPDATED);
                }
            }
        }
    }

    /*addProduct = async (product: any): Promise<void> => {
        let selectedProduct: ProductModality | undefined;
        switch (this.state.productTypeSelection) {
            case '':
                selectedProduct = find(this.state.productsAvailable, ['familyName', product.value]);
                break;
            case 'tradeName':
                selectedProduct = find(this.state.productsAvailable, ['familyName', product.value]);
                break;
            case 'genericName':
                selectedProduct = find(this.state.productsAvailable, ['genericName', product.value]);
                break;
            case 'mkNumber':
                selectedProduct = find(this.state.productsAvailable, ['mkNumber', product.value]);
                break;
        }

        if (
            selectedProduct &&
            !find(
                this.state.cpws.productModality.selected,
                (selProduct: ProductModality) =>
                    selProduct.familyName === product.value ||
                    selProduct.mkNumber === product.value ||
                    selProduct.genericName === product.value,
            )
        ) {
            if (
                !selectedProduct.productTypeSelection ||
                selectedProduct.productTypeSelection === '' ||
                selectedProduct.productTypeSelection !== this.state.productTypeSelection
            ) {
                selectedProduct.productTypeSelection = this.state.productTypeSelection;
            }
            const currentProducts = cloneDeep(this.state.cpws.productModality.selected);
            currentProducts.push(selectedProduct);
            this.productStateChange(currentProducts);

            if (
                typeof this.state.productColorTags.initiallySelected !== 'boolean' &&
                typeof this.state.productColorTags.initialRemoved !== 'boolean' &&
                find(
                    this.state.productColorTags.initiallySelected,
                    (colorProd: ProductModality) =>
                        colorProd.familyName === product.value ||
                        colorProd.genericName === product.value ||
                        colorProd.mkNumber === product.value,
                )
            ) {
                const initialRemoved = this.state.productColorTags.initialRemoved as Array<ProductModality>;
                const index = findIndex(
                    initialRemoved,
                    (colorProd: ProductModality) =>
                        colorProd.familyName === product.value ||
                        colorProd.genericName === product.value ||
                        colorProd.mkNumber === product.value,
                );
                if (index > -1) {
                    const initialRemoved = this.state.productColorTags.initialRemoved as Array<ProductModality>;
                    initialRemoved.splice(index, 1);
                    this.setState({
                        productColorTags: {
                            ...this.state.productColorTags,
                            initialRemoved: [...initialRemoved],
                        },
                        appCacheFlags: {
                            ...this.state.appCacheFlags,
                            highestAvailable: 1,
                        },
                    });
                }
            }
        }
    };*/
    /*removeProduct = async (product: any): Promise<void> => {
        const selectedProductIndex = findIndex(this.state.cpws.productModality.selected, (o: ProductModality) => {
            return o.familyName === product.value || o.genericName === product.value || o.mkNumber === product.value;
        });

        if (selectedProductIndex > -1) {
            const currentProducts = cloneDeep(this.state.cpws.productModality.selected);
            currentProducts.splice(selectedProductIndex, 1);
            this.productStateChange(currentProducts);

            if (
                typeof this.state.productColorTags.initiallySelected !== 'boolean' &&
                typeof this.state.productColorTags.initialRemoved !== 'boolean'
            ) {
                const initialSelectedProduct = find(
                    this.state.productColorTags.initiallySelected,
                    (colorProd: ProductModality) =>
                        colorProd.familyName === product.value ||
                        colorProd.genericName === product.value ||
                        colorProd.mkNumber === product.value,
                ) as ProductModality | undefined;

                if (initialSelectedProduct) {
                    this.setState({
                        productColorTags: {
                            ...this.state.productColorTags,
                            initialRemoved: [...this.state.productColorTags.initialRemoved, initialSelectedProduct],
                        },
                        appCacheFlags: {
                            ...this.state.appCacheFlags,
                            highestAvailable: 1,
                        },
                    });
                }
            }
        }
    };*/

    onMaterialSelection(materials: Array<Material>) {
        this.setState({
            cpws: { ...this.state.cpws, impactedMaterials: materials },
            //visitedChangeClassification: false,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                loadAdditionalInfo: true,
                loadProductsFamilySitesMaterial: true,                
                highestAvailable: 2,
            },
        });
    }

    updateExternalMaterialName = (
        stateList: Array<ExternalMaterial | Material | ContainerClosureMaterial>,
        searchKey: string,
        searchVal: string,
        objKey: string,
        newMaterialName: string,
    ) => {
        //console.log("updateExternalMaterialName Called");
        const index = findObjectIndexByValue(stateList, searchKey, searchVal);
        if (index >= 0 && stateList[index][objKey] !== newMaterialName) {
            stateList[index][objKey] = newMaterialName;
        }
        return stateList;
    };

    editExternalMaterial = async (materialNumber: string, newMaterialName: string): Promise<void> => {
        //console.log("editExternalMaterial Called");
        let impactedMaterialsExternal = cloneDeep(this.state.impactedMaterialsExternal);
        let impactedMaterialsAvailable = cloneDeep(this.state.impactedMaterialsAvailable);
        let impactedMaterials = cloneDeep(this.state.cpws.impactedMaterials);

        impactedMaterialsExternal = this.updateExternalMaterialName(
            impactedMaterialsExternal,
            'number',
            materialNumber,
            'name',
            newMaterialName,
        ) as Array<ExternalMaterial>;
        impactedMaterialsAvailable = this.updateExternalMaterialName(
            impactedMaterialsAvailable,
            'number',
            materialNumber,
            'name',
            newMaterialName,
        ) as Array<Material>;
        impactedMaterials = this.updateExternalMaterialName(
            impactedMaterials,
            'number',
            materialNumber,
            'name',
            newMaterialName,
        ) as Array<Material>;

        impactedMaterials.forEach((mat: Material) => {
            if (mat.downstreamMaterials.length > 0) {
                mat.downstreamMaterials = this.updateExternalMaterialName(
                    mat.downstreamMaterials,
                    'number',
                    materialNumber,
                    'name',
                    newMaterialName,
                ) as Array<ContainerClosureMaterial>;
            }
        });

        this.setState({
            impactedMaterialsExternal: sortObjectArrayByValue(impactedMaterialsExternal, 'name'),
            impactedMaterialsAvailable: impactedMaterialsAvailable,
            cpws: {
                ...this.state.cpws,
                impactedMaterials: impactedMaterials,
            },
        });

        this.api.saveExternalMaterials({ name: newMaterialName, number: materialNumber });
    };

    addMaterial = async (materialObj: ExternalMaterial): Promise<void> => {
        //console.log("add Material Called");
        const newMaterial: Material = mapExternalMaterialFromJSON(materialObj);

        const sendExternalArr = this.state.sendExternalMaterials;

        if (materialObj.number === '') {
            materialObj.number = newMaterial.number;
        }

        sendExternalArr.push(materialObj);

        //newMaterial.name.replace( /^\s*|[^a-zA-Z0-9_' ]/gm, '')

        try {
            if (!findObjectByValue(this.state.impactedMaterialsAvailable, 'number', newMaterial.number)) {
                this.setState({
                    impactedMaterialsAvailable: [...this.state.impactedMaterialsAvailable, newMaterial],
                    appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: this.state.current },
                });
            }
        } catch (err) {
            console.log("Error in addMaterial Function", err);
        }

        if (!findObjectByValue(this.state.impactedMaterialsExternal, 'number', materialObj.number)) {
            let tempImpactedMaterialsExternal = this.state.impactedMaterialsExternal;
            tempImpactedMaterialsExternal.push(materialObj);
            tempImpactedMaterialsExternal = sortObjectArrayByValue(tempImpactedMaterialsExternal, 'name');
            this.setState({
                impactedMaterialsExternal: tempImpactedMaterialsExternal,
                appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: this.state.current },
            });
        }

        this.api.saveExternalMaterials({
            name: materialObj.name,
            number: materialObj.number,
        });
    };

    getDownstreamMaterials = (material: Material): Array<ContainerClosureMaterial> => {
        const cpws = this.state.cpws;
        const impactedMaterials = cpws.impactedMaterials;

        const materialIndex = impactedMaterials.findIndex(x => x.number === material.number)
        const selectedMaterial = impactedMaterials[materialIndex];

        if (selectedMaterial !== undefined) {
            return selectedMaterial.downstreamMaterials;
        }

        return [];
    }

    addDownstreamMaterial = async (externalMaterial: ExternalMaterial, material: Material, type: string) => {
        //console.log("add downstream material called");
        const downstreamMaterial = {
            availableTypes: [],
            name: externalMaterial.name,
            number: externalMaterial.number === '' ? `ext-${uuidv4()}` : externalMaterial.number,
            stg_description: 'downstream',
            type: [],
            manually_entered: type === 'external' ? true : false
        };

        let externalMaterials: Array<ContainerClosureMaterial> = [];

        const currentMaterials = cloneDeep(this.state.cpws.impactedMaterials);
        const findMaterialIndex = currentMaterials.findIndex(x => x.number === material.number);
        if (findMaterialIndex === -1) {
            currentMaterials.push(material);
        }
        let currentExternalMaterials = cloneDeep(this.state.impactedMaterialsExternal);
        const materialToUpdateIndex = findIndex(currentMaterials, ['number', material.number]);

        let updatedMaterial = {};

        if (materialToUpdateIndex > -1) {
            const materialToUpdate = { ...currentMaterials[materialToUpdateIndex] };

            if (!findObjectByValue(materialToUpdate.downstreamMaterials, 'number', downstreamMaterial.number)) {  
                const downstreamIndex = materialToUpdate.downstreamMaterials.findIndex((x: any) => downstreamMaterial.name === x.name);
                if (downstreamIndex === -1) {
                    materialToUpdate.downstreamMaterials.push(downstreamMaterial);
                    materialToUpdate.downstreamMaterials = sortObjectArrayByValue(
                        materialToUpdate.downstreamMaterials,
                        'name',
                    );

                    materialToUpdate.downstreamMaterials = await this.loadDownstreamMaterialTypes(
                        materialToUpdate.downstreamMaterials,
                    );

                    currentMaterials.splice(materialToUpdateIndex, 1, materialToUpdate);   
                }
            }

            updatedMaterial = materialToUpdate;
        }

        if (!findObjectByValue(currentExternalMaterials, 'number', downstreamMaterial.number)) {
            currentExternalMaterials.push({
                name: downstreamMaterial.name,
                number: downstreamMaterial.number,
            });
            currentExternalMaterials = sortObjectArrayByValue(currentExternalMaterials, 'name');
        }

        if (type === 'external') {
            this.api.saveExternalMaterials({
                name: downstreamMaterial.name,
                number: downstreamMaterial.number,
            });

            externalMaterials = this.state.newExternalMaterials;
            externalMaterials.push(downstreamMaterial);

            this.setState({
                newExternalMaterials: externalMaterials,
                impactedMaterialsExternal: currentExternalMaterials
            })
        }
        
        if (type === 'internal' || type === 'external') {
            this.setState({
                cpws: {
                    ...this.state.cpws,
                    impactedMaterials: currentMaterials,
                },
                appCacheFlags: {
                    ...this.state.appCacheFlags,
                    highestAvailable: 2,
                },
            });
        } else {
            this.setState({
                cpws: {
                    ...this.state.cpws,
                    impactedMaterials: currentMaterials,
                },
                userSelectedMaterials: currentMaterials,
                //visitedChangeClassification: false,
                appCacheFlags: {
                    ...this.state.appCacheFlags,
                    highestAvailable: 2,
                },
            });
        }
        console.log(currentMaterials,'currentMaterials')
        return updatedMaterial;
    };

    deleteMaterial = async (materialNumber: string): Promise<void> => {
        if (this.state.formFields.viewOnly) {
            return;
        }

        const currentMaterials = cloneDeep(this.state.cpws.impactedMaterials);
        let index = findIndex(currentMaterials, ['number', materialNumber]);
        if (index > -1) {
            currentMaterials.splice(index, 1);
        }

        const userSelectedMaterials = [...this.state.userSelectedMaterials];
        index = findIndex(userSelectedMaterials, ['number', materialNumber]);
        if (index > -1)
            userSelectedMaterials.splice(index, 1);

        currentMaterials.forEach((mat: Material) => {
            if (mat.downstreamMaterials.length > 0) {
                index = findIndex(mat.downstreamMaterials, ['number', materialNumber]);
                if (index > -1) {
                    mat.downstreamMaterials.splice(index, 1);
                }
            }
        });

        const impactedMaterialsExternal = cloneDeep(this.state.impactedMaterialsExternal);
        index = findIndex(impactedMaterialsExternal, ['number', materialNumber]);
        if (index > -1) {
            impactedMaterialsExternal.splice(index, 1);
        }

        const impactedMaterialsAvailable = cloneDeep(this.state.impactedMaterialsAvailable);
        index = findIndex(impactedMaterialsAvailable, ['number', materialNumber]);
        if (index > -1) {
            impactedMaterialsAvailable.splice(index, 1);
        }

        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedMaterials: currentMaterials,
            },
            impactedMaterialsExternal: impactedMaterialsExternal,
            impactedMaterialsAvailable: impactedMaterialsAvailable,
            //visitedChangeClassification: false,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                highestAvailable: this.state.current,
            },
            userSelectedMaterials: userSelectedMaterials,
        });

        this.api.deleteMaterialExternal(materialNumber);
    };

    loadDownstreamMaterialTypes = async (downstreamMaterials: Array<ContainerClosureMaterial>) => {
        const materialTypes = await this.loadContainerClosureMaterialType(downstreamMaterials);

        downstreamMaterials.forEach((mat: ContainerClosureMaterial) => {
            if (materialTypes) {
                mat.availableTypes = materialTypes[0]['mtl_types_count'];
            }
        });

        return downstreamMaterials;
    };

    updateMaterial = async (material: SelectedMaterials, selection: string): Promise<void> => {
        console.log("update material called");
        let tmpMaterials = [...this.state.userSelectedMaterials];
        if(!isEmpty(this.state.classificationList)) {
            tmpMaterials = this.state.userSelectedMaterials.map((material: any) => {
                material.classification = cloneDeep(this.state.classificationList);
                const dAndCCode = this.getClassificationCode('Description and/or Composition');
                if (
                    dAndCCode !== 'Description and/or Composition' &&
                    material.classification[dAndCCode] === undefined
                ) {
                    material.classification[dAndCCode] = 0;
                }
                return material;
            });
        }        
        const currentMaterials = tmpMaterials;
        const materialIndex = findIndex(currentMaterials, { number: material.number });
        const parsedSelection: MaterialType = JSON.parse(selection);

        if (materialIndex > -1) {
            const updatedMaterial = { ...currentMaterials[materialIndex] };
            if (!find(updatedMaterial.type, ['code', parsedSelection.code])) {
                updatedMaterial.type = updatedMaterial.type.concat(parsedSelection);
                currentMaterials.splice(materialIndex, 1, cloneDeep(updatedMaterial));

                try {
                    this.setState({
                        cpws: {
                            ...this.state.cpws,
                            impactedMaterials: currentMaterials,
                        },
                        userSelectedMaterials: [...this.state.userSelectedMaterials, ...currentMaterials]
                    });
                } catch (e) {
                    console.log("Error in updateMaterial Function", e);
                }
                this.setState({
                    cpws: {
                        ...this.state.cpws,
                        impactedMaterials: currentMaterials,
                    },
                    userSelectedMaterials: currentMaterials,
                });

                if (updatedMaterial.type.some((matType: MaterialType) => matType.code === 'T14')) {
                    let downstreamMaterials = updatedMaterial.downstreamMaterials;

                    if (downstreamMaterials.length > 0) {
                        downstreamMaterials = await this.loadDownstreamMaterialTypes(downstreamMaterials);
                        downstreamMaterials = sortObjectArrayByValue(downstreamMaterials, 'name');
                        updatedMaterial.downstreamMaterials = downstreamMaterials;
                    } else {
                        updatedMaterial.downstreamMaterials = [];
                    }

                    currentMaterials.splice(materialIndex, 1, cloneDeep(updatedMaterial));
                    this.setState({
                        cpws: {
                            ...this.state.cpws,
                            impactedMaterials: currentMaterials,
                        },
                        userSelectedMaterials: currentMaterials,
                        containerClosurePageNumber: {
                            ...this.state.containerClosurePageNumber,
                            [material.number]: 1,
                        },
                    });
                }
            }
            /*if(!isEmpty(this.state.classificationList)) {
                const updatedMaterials = this.state.userSelectedMaterials.map((material: any) => {
                    material.classification = cloneDeep(this.state.classificationList);
                    const dAndCCode = this.getClassificationCode('Description and/or Composition');
                    if (
                        dAndCCode !== 'Description and/or Composition' &&
                        material.classification[dAndCCode] === undefined
                    ) {
                        material.classification[dAndCCode] = 0;
                    }
                    return material;
                });
                
                this.setState({
                    userSelectedMaterials: updatedMaterials
                });  
            }  */  
        } else {
            console.log('Unable to find material. Cannot add materialType. Exiting');
        }
        /*this.setState({
            appCacheFlags: {
                ...this.state.appCacheFlags,
                loadAdditionalInfo: true,
                loadClassificationListResponse: true,
                loadMatchCriteria: true,
                highestAvailable: 2,
            },
        });*/
    };

    removeManuallyEnteredMaterials = (material: Material) => {
        const updatedDownstreamMaterials = []
        const downstreamMaterials = material.downstreamMaterials;
        
        for (const downstreamMat of downstreamMaterials) {
            if (downstreamMat.manually_entered !== true) {
                updatedDownstreamMaterials.push(downstreamMat);
            }
        }

        const currentMaterials = cloneDeep(this.state.cpws.impactedMaterials);

        const findMaterialIndex = currentMaterials.findIndex(x => x.number === material.number);
        if (findMaterialIndex === -1) {
            currentMaterials.push(material);
        }

        const materialToUpdateIndex = findIndex(currentMaterials, ['number', material.number]);

        if (materialToUpdateIndex > -1) {
            const materialToUpdate = { ...currentMaterials[materialToUpdateIndex] };

            materialToUpdate.downstreamMaterials = updatedDownstreamMaterials;

            currentMaterials.splice(materialToUpdateIndex, 1, materialToUpdate);
        }

        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedMaterials: currentMaterials,
            },
            //visitedChangeClassification: false,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                highestAvailable: 2,
            },
        });

        return updatedDownstreamMaterials;
    }

    removeMaterialType = (material: SelectedMaterials, matType: MaterialType) => {
        console.log("removeMaterialType called");
        let pageNumbers: undefined | { [key: string]: number };
        if (matType.code === 'T14') {
            material.downstreamMaterials = [];
            pageNumbers = cloneDeep(this.state.containerClosurePageNumber);
            delete pageNumbers[material.number];
        }
        let index = findIndex(material.type, ['code', matType.code]);
        if (index > -1) {
            material.type.splice(index, 1);
        }

        const currentMaterials = [...this.state.userSelectedMaterials];

        index = findIndex(currentMaterials, ['number', material.number]);
        if (index > -1) {
            currentMaterials[index] = material;
        }

        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedMaterials: currentMaterials,
            },
            userSelectedMaterials: currentMaterials,
            //visitedChangeClassification: false,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                loadAdditionalInfo: true,
                loadClassificationListResponse: true,                
                loadMatchCriteria: true,
                highestAvailable: 2,
            },
            containerClosurePageNumber: pageNumbers ? pageNumbers : this.state.containerClosurePageNumber,
        });
    };

    addMaterialContainerClosure = (materialNumber: string, downstreamMaterialNumber: string, selection: string) => {
        console.log("addMaterialContainerClosure called");
        let tmpMaterials = [...this.state.cpws.impactedMaterials];
        if(!isEmpty(this.state.classificationList)) {
            tmpMaterials = this.state.cpws.impactedMaterials.map((material: any) => {
                material.classification = cloneDeep(this.state.classificationList);
                const dAndCCode = this.getClassificationCode('Description and/or Composition');
                if (
                    dAndCCode !== 'Description and/or Composition' &&
                    material.classification[dAndCCode] === undefined
                ) {
                    material.classification[dAndCCode] = 0;
                }
                return material;
            });
        }
        const currentMaterials = tmpMaterials;        
        const materialIndex = findIndex(currentMaterials, { number: materialNumber });
        const parsedSelection: MaterialType = JSON.parse(selection);

        let downstreamMaterial;

        const material = { ...currentMaterials[materialIndex] };
        if (materialIndex > -1) {
            const downstreamMaterialIndex = findIndex(material.downstreamMaterials, {
                number: downstreamMaterialNumber,
            });

            if (downstreamMaterialIndex > -1) {
                downstreamMaterial = { ...material.downstreamMaterials[downstreamMaterialIndex] };
                if (!find(downstreamMaterial.type, ['code', parsedSelection.code])) {
                    downstreamMaterial.type = downstreamMaterial.type.concat(parsedSelection);
                    material.downstreamMaterials.splice(downstreamMaterialIndex, 1, downstreamMaterial);
                }
            }
            currentMaterials.splice(materialIndex, 1, material);
        }

        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedMaterials: currentMaterials,
            },
            //visitedChangeClassification: false,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                loadAdditionalInfo: true,
                loadClassificationListResponse: true,
                loadMatchCriteria: true,
                highestAvailable: 2,
            },
        });

        if (downstreamMaterial) {
            return {
                downstreamMaterials: material.downstreamMaterials,
                type: downstreamMaterial.type
            };
        }
    };

    removeExternalMaterialFromContainerClosure = (externalMaterialNumber: string, material: Material) => {
        const currentMaterials = cloneDeep(this.state.cpws.impactedMaterials);
        const matIndex = findIndex(currentMaterials, ['number', material.number]);
        let updatedMaterial;

        if (matIndex > -1) {
            const currentMaterial = cloneDeep(currentMaterials[matIndex]);
            const downstreamIndex = findIndex(currentMaterial.downstreamMaterials, ['number', externalMaterialNumber]);

            if (downstreamIndex > -1) {
                const numMaterials = currentMaterial.downstreamMaterials.length;
                currentMaterial.downstreamMaterials.splice(downstreamIndex, 1);
                currentMaterials[matIndex] = currentMaterial;
                updatedMaterial = currentMaterial;

                this.setState({
                    cpws: {
                        ...this.state.cpws,
                        impactedMaterials: currentMaterials,
                    },
                    //visitedChangeClassification: false,
                    containerClosurePageNumber:
                        numMaterials % 10 === 1
                            ? {
                                ...this.state.containerClosurePageNumber,
                                [material.number]: 1,
                            }
                            : this.state.containerClosurePageNumber,
                    appCacheFlags: {
                        ...this.state.appCacheFlags,
                        highestAvailable: 2,
                    },
                });
            }
        }

        return updatedMaterial;
    };

    removeDownstreamType = (material: Material, record: ContainerClosureMaterial, typeValue: MaterialType) => {
        console.log("removeDownstreamType called");
        const currentMaterials = [...this.state.cpws.impactedMaterials];
        const downstreamIndex = findIndex(material.downstreamMaterials, ['number', record.number]);

        if (typeValue !== undefined && downstreamIndex > -1) {
            const typeIndex = findIndex(material.downstreamMaterials[downstreamIndex].type, ['code', typeValue.code]);

            if (typeIndex > -1) {
                material.downstreamMaterials[downstreamIndex].type.splice(typeIndex, 1);
            }
        }

        const currentMaterialIndex = findIndex(currentMaterials, ['number', material.number]);
        if (currentMaterialIndex > -1) {
            currentMaterials.splice(currentMaterialIndex, 1, material);
        }

        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedMaterials: currentMaterials,
            },
            userSelectedMaterials: currentMaterials,
            //visitedChangeClassification: false,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                loadAdditionalInfo: true,
                loadClassificationListResponse: true,
                loadMatchCriteria: true,
                highestAvailable: 2,
            },
        });

        if (downstreamIndex > -1) {
            return {
                downstreamMaterials: material.downstreamMaterials,
                type: material.downstreamMaterials[downstreamIndex].type
            };
        }
    };

    getImpactedMaterials = () => {
        const materials = this.state.cpws.materials;
        const externalMaterials = this.state.newExternalMaterials;
        const allMaterials = [...materials, ...externalMaterials]
        return allMaterials;
    }

    getImpactedProducts = () => {
        const products = this.state.cpws.productInformation.products;
        return products;
    }

    containerClosureApplyAll = (material: Material) => {
        let allTypes: any = material.downstreamMaterials[0].availableTypes[0];
        const typesCleaned: any = [];

        for (const key in allTypes) {
            const keyValue: any = key;
            const typeInfo = allTypes[keyValue];
            if (typeInfo.name !== '') {
                typesCleaned.push(typeInfo);
            }
        }

        const downstreamMaterials = material.downstreamMaterials;
        const firstMaterialsType = downstreamMaterials[0].type;
        for (const downstreamMat of downstreamMaterials) {
            downstreamMat.type = firstMaterialsType;
        }

        material.downstreamMaterials.slice(1).forEach((downstreamMat: ContainerClosureMaterial) => {
            downstreamMat.type = [];
            material.downstreamMaterials[0].type.forEach((matType: MaterialType) => {
                allTypes = downstreamMat.availableTypes[0];
                const type = find(downstreamMat.availableTypes, ['code', matType.code]);
                if (type) {
                    downstreamMat.type.push(type);
                }
            });
        });

        const currentMaterials = [...this.state.cpws.impactedMaterials];
        const matIndex = findIndex(currentMaterials, ['number', material.number]);

        if (matIndex > -1) {
            currentMaterials[matIndex] = material;
        }

        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedMaterials: currentMaterials,
            },
            userSelectedMaterials: currentMaterials,
            //visitedChangeClassification: false,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                highestAvailable: 2,
            },
        });

        return {
            downstreamMaterials: downstreamMaterials,
            type: firstMaterialsType
        };
    };
    classificationListApplyAll = async(clist: any) => {
    console.log("reached apptsx");
        /*const tmpVal = [...this.state.userSelectedMaterials];
        const materialList = sortObjectArrayByValue(tmpVal, 'name');
        const newMaterialList = materialList.map((item) => {
            item.classification = materialList[0].classification;
            return item;
        });
        console.log("Final List: ", newMaterialList);
        this.setState({userSelectedMaterials : newMaterialList});*/
        const updatedMaterials = this.state.userSelectedMaterials.map((material: any) => {
            material.classification = cloneDeep(clist);            
            return material;
        });
        const updatedImpactedMaterials = this.state.cpws.impactedMaterials.map((material: any) => {
            material.classification = cloneDeep(clist);
            return material;
        });
        //@@
        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedMaterials: updatedImpactedMaterials,
            },
            userSelectedMaterials: updatedMaterials
        });
    }

    impactedMaterialsApplyAll = async () => {
        console.log("impactedMaterialsApplyAll called");
        const currentMaterials = cloneDeep(this.state.userSelectedMaterials);
        currentMaterials.slice(1).forEach((mat: SelectedMaterials) => {
            mat.type = [];
            const impactedMaterialType = find(this.state.impactedMaterialType, ['number', mat.number]);
            if (impactedMaterialType) {
                currentMaterials[0].type.forEach((matType: MaterialType) => {
                    const typeKey = findKey(impactedMaterialType.mtl_types_count[0], ['code', matType.code]);
                    if (typeKey) {
                        mat.type.push(impactedMaterialType.mtl_types_count[0][typeKey]);
                    }
                });
            }
        });
        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedMaterials: currentMaterials
            },
            userSelectedMaterials: currentMaterials,
            //visitedChangeClassification: false,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                highestAvailable: 2,
            },
        });

        const hasContainerClosure = currentMaterials[0].type.some((matType: MaterialType) => matType.code === 'T14');
        if (hasContainerClosure) {
            const updatedMaterials = await Promise.all(
                currentMaterials.slice(1).map(async (mat: SelectedMaterials) => {
                    let downstreamMaterials = [];

                    downstreamMaterials = this.getDownstreamMaterials(mat);

                    if (downstreamMaterials.length > 0) {
                        const materialTypes = await this.loadContainerClosureMaterialType(downstreamMaterials);

                        downstreamMaterials.forEach((downstreamMat: ContainerClosureMaterial) => {
                            const obj = find(materialTypes, ['number', downstreamMat.number]);
                            if (materialTypes) {
                                downstreamMat.availableTypes = materialTypes[0]['mtl_types_count'];
                            }
                        });

                        downstreamMaterials = sortObjectArrayByValue(downstreamMaterials, 'name');
                    }

                    return {
                        ...mat,
                        downstreamMaterials: downstreamMaterials,
                    };
                }),
            );

            const containerClosurePageNumbers = cloneDeep(this.state.containerClosurePageNumber);
            currentMaterials.slice(1).forEach((mat: SelectedMaterials) => (containerClosurePageNumbers[mat.number] = 1));
            this.setState({
                userSelectedMaterials: [currentMaterials[0], ...updatedMaterials],
                containerClosurePageNumber: containerClosurePageNumbers,
            });
        }
    };

    // impactedMaterialsApplyAll = async () => {
    //     const currentMaterials = sortObjectArrayByValue(cloneDeep(this.state.cpws.impactedMaterials), 'name');
    //     currentMaterials.slice(1).forEach((mat: Material) => {
    //         mat.type = [];
    //         const impactedMaterialType = find(this.state.impactedMaterialType, ['mtl_number', mat.number]);

    //         if (impactedMaterialType) {
    //             currentMaterials[0].type.forEach((matType: MaterialType) => {
    //                 const typeKey = findKey(impactedMaterialType.mtl_types_count[0], ['code', matType.code]);
    //                 if (typeKey) {
    //                     mat.type.push(impactedMaterialType.mtl_types_count[0][typeKey]);
    //                 }
    //             });
    //         }
    //     });
    //     this.setState({
    //         cpws: {
    //             ...this.state.cpws,
    //             impactedMaterials: currentMaterials,
    //         },
    //         appCacheFlags: {
    //             ...this.state.appCacheFlags,
    //             highestAvailable: 3,
    //         },
    //     });

    //     const hasContainerClosure = currentMaterials[0].type.some((matType: MaterialType) => matType.code === 'T14');
    //     if (hasContainerClosure) {
    //         const updatedMaterials = await Promise.all(
    //             currentMaterials.slice(1).map(async (mat: Material) => {
    //                 let downstreamMaterials = [];

    //                 downstreamMaterials = await this.api.getContainerClosure(
    //                     mat.number,
    //                     this.state.cpws.productModality.selected,
    //                 );
    //                 if (downstreamMaterials.length > 0) {
    //                     const materialTypes = await this.loadContainerClosureMaterialType(downstreamMaterials);

    //                     downstreamMaterials.forEach((downstreamMat: ContainerClosureMaterial) => {
    //                         const obj = find(materialTypes, ['number', downstreamMat.number]);
    //                         if (obj) {
    //                             downstreamMat.availableTypes = obj.types;
    //                         } else {
    //                             downstreamMat.availableTypes = [];
    //                         }
    //                     });

    //                     downstreamMaterials = sortObjectArrayByValue(downstreamMaterials, 'name');
    //                 }

    //                 return {
    //                     ...mat,
    //                     downstreamMaterials: downstreamMaterials,
    //                 };
    //             }),
    //         );

    //         const containerClosurePageNumbers = cloneDeep(this.state.containerClosurePageNumber);
    //         currentMaterials.slice(1).forEach((mat: Material) => (containerClosurePageNumbers[mat.number] = 1));
    //         this.setState({
    //             cpws: {
    //                 ...this.state.cpws,
    //                 impactedMaterials: [currentMaterials[0], ...updatedMaterials],
    //             },
    //             containerClosurePageNumber: containerClosurePageNumbers,
    //         });
    //     }
    // };


    updateMaterialClassification = async (newMaterial: any): Promise<void> => {
        console.log("updateMaterialClassification called", newMaterial);
        console.log("User Selected Materials:", this.state.userSelectedMaterials);
        const cpws = this.state.cpws;
        const currentMaterials = cloneDeep(this.state.userSelectedMaterials);
        const index = findIndex(currentMaterials, { name: newMaterial.name });
        console.log(index);
        if (index > -1) {
            currentMaterials[index].classification = newMaterial.classification;
        }
        console.log("Current Materials:", currentMaterials);

        this.setState({
            userSelectedMaterials: currentMaterials,
            cpws: {
                ...this.state.cpws,
                impactedMaterials: currentMaterials,
            },
            visitedChangeClassification: true,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                loadMatchCriteria: true,
                loadFinalResults: true,
                highestAvailable: 2,
            },
        });
    };

    addSite = ({
        site,
        siteType,
        callback,
    }: {
        site: LabeledValue;
        siteType?: 'cpws' | 'pds';
        callback?: Function;
    }): void => {
        let newSite: Site | undefined;
        if (siteType === 'cpws') {
            newSite = find(this.state.sitesAvailable, ['name', site.value]);
            if (newSite && !Object.prototype.hasOwnProperty.call(newSite, 'isPDS')) {
                newSite = {
                    ...newSite,
                    isPDS: false,
                };
            }
        } else {
            const newSiteFunction = find(this.state.siteFunctions, ['name', site.value]);
            if (newSiteFunction) {
                newSite = {
                    name: newSiteFunction.name,
                    code: 'pds_site',
                    found: false,
                    siteFunctions: newSiteFunction.siteFunctions,
                    isPDS: true,
                };
            }
        }

        if (newSite) {
            const currentSites = this.state.cpws.impactedSites.selected;
            currentSites.push(newSite);

            let initialRemoved = this.state.siteColorTags.initialRemoved;
            if (
                typeof this.state.siteColorTags.initiallySelected !== 'boolean' &&
                typeof this.state.siteColorTags.initialRemoved !== 'boolean' &&
                find(this.state.siteColorTags.initiallySelected, ['name', newSite.name])
            ) {
                initialRemoved = this.state.siteColorTags.initialRemoved as Array<Site>;
                const index = findIndex(initialRemoved, ['name', newSite.name]);
                if (index > -1) {
                    initialRemoved.splice(index, 1);
                }
            }

            this.setState(
                {
                    cpws: {
                        ...this.state.cpws,
                        impactedSites: {
                            ...this.state.cpws.impactedSites,
                            selected: currentSites,
                        },
                    },
                    siteColorTags: {
                        ...this.state.siteColorTags,
                        initialRemoved: typeof initialRemoved === 'boolean' ? initialRemoved : [...initialRemoved],
                    },
                    appCacheFlags: {
                        ...this.state.appCacheFlags,
                        highestAvailable: this.state.current,
                    },
                },
                () => {
                    this.siteStateChange(currentSites, callback, siteType);
                },
            );
        }
    };

    removeSite = ({ site, callback }: { site: LabeledValue; callback?: Function }): void => {
        const oldSiteIndex = findIndex(this.state.cpws.impactedSites.selected, ['name', site.value]);
        if (oldSiteIndex > -1) {
            const currentSites = cloneDeep(this.state.cpws.impactedSites.selected);
            const removed = currentSites.splice(oldSiteIndex, 1);

            let colorSite: Site | undefined;
            if (
                typeof this.state.siteColorTags.initiallySelected !== 'boolean' &&
                typeof this.state.siteColorTags.initialRemoved !== 'boolean'
            ) {
                colorSite = find(this.state.siteColorTags.initiallySelected, ['name', site.value]) as Site;
            }

            this.setState(
                {
                    cpws: {
                        ...this.state.cpws,
                        impactedSites: {
                            ...this.state.cpws.impactedSites,
                            selected: currentSites,
                        },
                    },
                    siteColorTags: {
                        ...this.state.siteColorTags,
                        initialRemoved: colorSite
                            ? [...(this.state.siteColorTags.initialRemoved as Array<Site>), colorSite]
                            : this.state.siteColorTags.initialRemoved,
                    },
                    appCacheFlags: {
                        ...this.state.appCacheFlags,
                        highestAvailable: this.state.current,
                    },
                },
                () => {
                    this.siteStateChange(currentSites, callback, removed[0].isPDS ? 'pds' : 'cpws');
                },
            );
        }
    };

    resetPDSSites = () => {
        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedSites: {
                    ...this.state.cpws.impactedSites,
                    selected: this.state.cpws.impactedSites.selected.filter((site: Site) => !site.isPDS),
                },
            },
        });
    };

    addSiteFunction = (option: { key: string; value: string; children: string }, callback?: Function) => {
        const regex = /(.*)_/m;
        const result = regex.exec(option.key);
        const siteName = result ? result[1] : '';

        const impactedSites = cloneDeep(this.state.cpws.impactedSites);

        const index = findIndex(impactedSites.selected, ['name', siteName]);

        if (index > -1) {
            if (!impactedSites.selected[index].siteFunctions.includes(option.value)) {
                impactedSites.selected[index].siteFunctions.push(option.value);
            }
        }

        this.setState(
            {
                cpws: {
                    ...this.state.cpws,
                    impactedSites: impactedSites,
                },
                appCacheFlags: {
                    ...this.state.appCacheFlags,
                    loadAdditionalInfo: true,
                    highestAvailable: this.state.current,
                },
            },
            () => {
                if (callback) {
                    callback();
                }
            },
        );
    };

    removeSiteFunction = (option: { key: string; value: string; children: string }, callback?: Function) => {
        const regex = /(.*)_/m;
        const result = regex.exec(option.key);
        const siteName = result ? result[1] : '';

        const impactedSites = cloneDeep(this.state.cpws.impactedSites);

        const index = findIndex(impactedSites.selected, ['name', siteName]);

        if (index > -1) {
            const functionIndex = impactedSites.selected[index].siteFunctions.findIndex(
                (siteFunction: string) => siteFunction === option.value,
            );
            if (functionIndex > -1) {
                impactedSites.selected[index].siteFunctions.splice(functionIndex, 1);
            }
        }

        this.setState(
            {
                cpws: {
                    ...this.state.cpws,
                    impactedSites: impactedSites,
                },
                appCacheFlags: {
                    ...this.state.appCacheFlags,
                    loadAdditionalInfo: true,
                    highestAvailable: this.state.current,
                },
            },
            () => {
                if (callback) {
                    callback();
                }
            },
        );
    };

    updateCountries = (countries: Array<Country>) => {
        this.setState({
            impactedCountries: countries,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                highestAvailable:
                    this.state.appCacheFlags.highestAvailable === 5 ? 4 : this.state.appCacheFlags.highestAvailable,
            },
        });
    };

    updateQAQuestion = async (answer: boolean): Promise<void> => {
        this.setState({
            cpws: {
                ...this.state.cpws,
                qaQuestion: answer,
            },
            qaQuestionAnswered: true,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                highestAvailable: 4,
            },
        });
    };

    updateFinalAssessmentField = (value: string, field: FinalAssessmentKeys) => {
        this.setState(prevState => ({
            ...prevState,
            [field]: value,
        }));
        this.setState({ unlockPDF: false})
    };

    clearFinalAssessmentField = (include_nri:boolean) => {
        this.setState({
            user_nri_ri : include_nri ? '' : this.state.user_nri_ri,
            vaccine_nri_ri: '',
            biologic_nri_ri: '',
            smallmolecule_nri_ri: '',
            scope_change_question: '',
            userAssessmentRationale: '',
            directionalDetails: '',
            additionalNotes: '',
            nriOption: '',
            rationaleAssessment: '',
            currentPlanningAssessmentType: ''
        })
    }

    updateHighestAvailable = (num: number) => {
        this.setState({ appCacheFlags: { ...this.state.appCacheFlags, highestAvailable: num } });
    };

    findCodeInMatchCriteria = (mtcCode: string): Question | ChildQuestion | undefined => {
        let match: Question | ChildQuestion | undefined;
        const regex = /(T\d+)_((MC|PMC)\d+)/m;
        const regexMatch = regex.exec(mtcCode);
        if (regexMatch) {
            for (const question of this.state.matchCriteria) {
                if (question.parentCode === regexMatch[2] && question.materialTypeCode === regexMatch[1]) {
                    match = question;
                    break;
                } else if (question.children.length > 0) {
                    match = find(
                        question.children,
                        (childQuestion: ChildQuestion) =>
                            childQuestion.materialTypeCode === regexMatch[1] && childQuestion.mtcCode === regexMatch[2],
                    );
                    if (match) {
                        break;
                    }
                }
            }

            return match;
        } else {
            return;
        }
    };

    updateMatchQuestion = (mtcCode: string, selection: boolean | Array<string> | string, treeKeys: Array<string>) => {
        const { matchCriteriaAnswers } = this.state;
        // console.log("Match Criteria Answers", matchCriteriaAnswers);
        let questionDifference = difference(Object.keys(matchCriteriaAnswers), treeKeys);
        // console.log('Tree Keys', treeKeys);
        // console.log("question Difference", questionDifference);
        questionDifference.forEach((key: string) => {
            const matchCriteriaQuestion = this.findCodeInMatchCriteria(key);
            if (matchCriteriaQuestion && Object.prototype.hasOwnProperty.call(matchCriteriaQuestion, 'mtcCode')) {
                const childQuestion = matchCriteriaQuestion as ChildQuestion;
                const parentLabel = `${childQuestion.materialTypeCode}_${childQuestion.parentCode}`;

                const childOfAnsweredQuestion = parentLabel === mtcCode;
                const selectionIsFalse = typeof selection === 'boolean' && !selection;
                const selectionIsEmpty = typeof selection !== 'boolean' && isEmpty(selection);
                const selectionIsNA = typeof selection !== 'boolean' && (selection as Array<string>).includes('N/A');
                const childDefEqualParenDef = isEqual(childQuestion.mtcDef, childQuestion.parentDef);
                const parentAnsweredFalse =
                    matchCriteriaAnswers[parentLabel] &&
                    typeof matchCriteriaAnswers[parentLabel].answer === 'boolean' &&
                    !matchCriteriaAnswers[parentLabel].answer;
                const parentAnswerEmpty =
                    matchCriteriaAnswers[parentLabel] &&
                    typeof matchCriteriaAnswers[parentLabel].answer !== 'boolean' &&
                    isEmpty(matchCriteriaAnswers[parentLabel].answer);
                const parentAnswerNA =
                    matchCriteriaAnswers[parentLabel] &&
                    typeof matchCriteriaAnswers[parentLabel].answer !== 'boolean' &&
                    (matchCriteriaAnswers[parentLabel].answer as Array<string>).includes('N/A');
                const parentIsMultiSelect = childQuestion.parentAnswer !== 'NO_TYPE';

                if (childOfAnsweredQuestion && (selectionIsFalse || selectionIsEmpty || selectionIsNA)) {
                    delete matchCriteriaAnswers[key];
                }

                if (
                    (childDefEqualParenDef && (parentAnsweredFalse || parentAnswerEmpty || parentAnswerNA)) ||
                    this.state.matchCriteria.some((question: Question) => question.parentCode === childQuestion.mtcCode)
                ) {
                    delete matchCriteriaAnswers[key];
                }

                if (
                    childOfAnsweredQuestion &&
                    parentIsMultiSelect &&
                    typeof selection !== 'boolean' &&
                    !(selection as string | Array<string>).includes(childQuestion.parentAnswer)
                ) {
                    delete matchCriteriaAnswers[key];
                }
            } else {
                delete matchCriteriaAnswers[key];
            }
        });
        questionDifference = difference(treeKeys, Object.keys(matchCriteriaAnswers));
        questionDifference.forEach((key: string) => {
            const matchCriteriaQuestion = this.findCodeInMatchCriteria(mtcCode);
            if (matchCriteriaQuestion) {
                matchCriteriaAnswers[key] = {
                    answer: '',
                    isAnswered: false,
                    classificationLabel: matchCriteriaQuestion.classificationLabel,
                    isContainerClosure: matchCriteriaQuestion.isContainerClosure,
                };
            }
        });
        //console.log("question Difference", questionDifference);

        if (mtcCode in matchCriteriaAnswers) {
            const matchCriteriaQuestion = this.findCodeInMatchCriteria(mtcCode);
            if (matchCriteriaQuestion) {
                matchCriteriaAnswers[mtcCode] = {
                    answer: selection,
                    isAnswered:
                        typeof selection === 'boolean' || (typeof selection === 'object' && selection.length > 0)
                            ? true
                            : false,
                    classificationLabel: matchCriteriaQuestion.classificationLabel,
                    isContainerClosure: matchCriteriaQuestion.isContainerClosure,
                };
            }
        }

        const regex = /(T\d+)_(PMC\d+)/m;
        const match = regex.exec(mtcCode);
        if (typeof selection === 'boolean' && match) {
            const question = this.findCodeInMatchCriteria(mtcCode) as Question;

            if (question) {
                const duplicatedDefinitions = this.state.matchCriteria.reduce(
                    (duplicates: Array<ChildQuestion | Question>, matchQuestion: Question) => {
                        if (
                            matchQuestion.parentCode === match[2] &&
                            matchQuestion.children.length > 0 &&
                            matchQuestion.materialTypeCode === match[1]
                        ) {
                            return duplicates.concat(
                                matchQuestion.children.filter(
                                    (childQuestion: ChildQuestion) => childQuestion.parentDef === childQuestion.mtcDef,
                                ),
                            );
                        }

                        if (
                            matchQuestion.children.length > 0 &&
                            matchQuestion.classificationLabel !== question.classificationLabel &&
                            matchQuestion.materialTypeCode === match[1]
                        ) {
                            return duplicates.concat(
                                matchQuestion.children.filter(
                                    (childQuestion: ChildQuestion) => childQuestion.mtcDef === question.parentDef,
                                ),
                            );
                        }

                        if (
                            matchQuestion.parentDef === question.parentDef &&
                            matchQuestion.parentCode.startsWith('MC') &&
                            matchQuestion.children.length === 0 &&
                            matchQuestion.materialTypeCode === question.materialTypeCode
                        ) {
                            return duplicates.concat(matchQuestion);
                        }

                        return duplicates;
                    },
                    [],
                );

                duplicatedDefinitions.forEach((dupeQuestion: ChildQuestion | Question) => {
                    if (Object.prototype.hasOwnProperty.call(dupeQuestion, 'mtcCode')) {
                        const childQuestion = dupeQuestion as ChildQuestion;
                        matchCriteriaAnswers[`${match[1]}_${childQuestion.mtcCode}`] = {
                            answer: selection,
                            isAnswered: true,
                            classificationLabel: childQuestion.classificationLabel,
                            isContainerClosure: childQuestion.isContainerClosure,
                        };
                    } else {
                        const parentQuestion = dupeQuestion as Question;
                        matchCriteriaAnswers[`${parentQuestion.materialTypeCode}_${parentQuestion.parentCode}`] = {
                            answer: selection,
                            isAnswered: true,
                            classificationLabel: parentQuestion.classificationLabel,
                            isContainerClosure: parentQuestion.isContainerClosure,
                        };
                    }
                });
            }
        }
        //console.log("treeKeys", treeKeys);
        this.setState({
            matchCriteriaAnswers: matchCriteriaAnswers,
            matchCriteriaKeyOrder: treeKeys,
            appCacheFlags: {
                ...this.state.appCacheFlags,
                highestAvailable: 4,
            },
        });
    };
    // user selections and text
    changeComment = (text: any): void => {
        this.setState({ comment: text });
    };
    changeProductTypeSelection = (selection: any): void => {
        this.setState({ productTypeSelection: selection });
    };
    changeCategory = (...selection: Array<any>): void => {
        console.log("Change category called");
        // Checkbox is Unchecked operation
        if (this.state.category.includes(selection[0])) {
            const filteredArray = this.state.category.filter(item => item !== selection[0]);
            this.setState(
                {
                    category: filteredArray,
                    appCacheFlags: {
                        ...this.state.appCacheFlags,
                        loadMatchCriteria: true,
                        loadImpactedMaterials: true,
                        highestAvailable: 2,
                    },
                },
                () => {
                    this.checkIfCategoryAndSterilizationUnchecked();
                },
            );
        } else {
            this.setState({
                category: this.state.category.concat(selection),
                dpDsAnswered: true,
                appCacheFlags: {
                    ...this.state.appCacheFlags,
                    loadImpactedMaterials: true,
                    loadMatchCriteria: true,
                    highestAvailable: 2,
                },
            });
        }
    };
    changeSterilization = (...selection: Array<any>): void => {
        // Checkbox is Unchecked operation
        if (this.state.sterilization.includes(selection[0])) {
            const filteredArray = this.state.sterilization.filter(item => item !== selection[0]);
            this.setState(
                {
                    sterilization: filteredArray,
                    appCacheFlags: {
                        ...this.state.appCacheFlags,
                        loadMatchCriteria: true,
                        loadImpactedMaterials: true,
                        highestAvailable: 2,
                    },
                },
                () => {
                    this.checkIfCategoryAndSterilizationUnchecked();
                },
            );
        } else {
            this.setState({
                sterilization: this.state.sterilization.concat(selection),
                sterileAnswered: true,
                appCacheFlags: {
                    ...this.state.appCacheFlags,
                    loadMatchCriteria: true,
                    loadImpactedMaterials: true,
                    highestAvailable: 2,
                },
            });
        }
    };

    changeCPWSProps = (cpws: CPWS) => {
        this.setState({ cpws: cpws }, () => { this.sendSessionUpdate() });
    }

    updateUserSelectedMaterialProps = (materials: Array<SelectedMaterials>) => {
        console.log("updateUserSelectedMaterialProps called");
        console.log("materials", materials);
        this.setState({ userSelectedMaterials: materials });
        const tmpVal = materials.every((mat: Material) => {
            const materialTypeSelected = mat.type.length > 0;
            if (!materialTypeSelected) {
                return false;
            } else {
                return true
            }            
        });
        this.setState({visitedChangeClassification: tmpVal});

        this.setState({
            cpws: {
                ...this.state.cpws,
                impactedMaterials: materials,
            },
            appCacheFlags: {
                ...this.state.appCacheFlags,
                loadMatchCriteria: true,
            }
        });

        // this.setState({
        //     appCacheFlags: {
        //         ...this.state.appCacheFlags,
        //         loadImpactedMaterials: false,
        //         loadAdditionalInfo: true,
        //         loadClassificationList: false,
        //         loadClassificationListResponse: true,
        //         loadProductsFamilySitesMaterial: true,
        //         loadMatchCriteria: true,
        //         loadFinalResults: true,
        //     },
        // });

    }

    updateHighestAvailableforScreen2 = () => {
        this.setState({            
            appCacheFlags: {
                ...this.state.appCacheFlags,
                loadMatchCriteria: true,                
                highestAvailable: 2,
            },
        });
    }
    updateHighestAvailableforScreen3 = () => {
        this.setState({            
            appCacheFlags: {
                ...this.state.appCacheFlags,          
                highestAvailable: 3,
            },
        });
    }

    updateImpactedMaterialsOption = (option: boolean) => {
        const val = option ? "Yes" : "No";
        this.setState({ impactedMaterialsAvailableOption : val});
    }

    updateDownstreamImpactedMaterialsOption = (value: any, materialNumber: any) => {
        let impactedDownstreamMaterials = this.state.downstreamImpactedMaterialsAvailableOption;
        if (!impactedDownstreamMaterials) {
            impactedDownstreamMaterials = {};
        }
        impactedDownstreamMaterials[materialNumber] = value;
        this.setState({ downstreamImpactedMaterialsAvailableOption: impactedDownstreamMaterials });
    }

    updateTabSelection = (val: string) => {
        //let val = option ? "Yes" : "No";
        this.setState({ tab_num : val});
    }

    updateVisitedClassification = (val: boolean) => {
        this.setState({visitedChangeClassification : val})
    }

    updateUserSelectedExternalMaterialProps = (externalMaterials: Array<ExternalMaterial>) => {
        this.setState({ userSelectedExternalMaterials: externalMaterials });
    }
    checkIfCategoryAndSterilizationUnchecked = async (): Promise<void> => {
        const { category, sterilization } = this.state;
        // If all checkboxes are unchecked for either category or sterilization user cannot proceed to screen 2
        if (category.length === 0) {
            this.setState({ dpDsAnswered: false });
        }
        if (sterilization.length === 0) {
            this.setState({ sterileAnswered: false });
        }
    };
    saveCPWSAssessment = async (): Promise<number> => {
        const {
            cpws,
            category,
            sterilization,
            regulatoryQuestions,
            justification,
            productModalitySelections,
            user_nri_ri,
            system_nri_ri,
            biologic_nri_ri,
            vaccine_nri_ri,
            smallmolecule_nri_ri,
            scope_change_question,
            screen4_update,
            prat,
            sterileAnswered,
            submission_req_details,
            productsSitesImpacted,
            classificationCodes,
            materialCodeMapping,
            userSelectedMaterials,
            directionalDetails,
            matchCriteriaAnswers: answers,
            matchCriteria,
            assessments
        } = this.state;

        const saveAssessmentStatus =
        //  await (someRegulatoryQuestionAnswersAreYes(regulatoryQuestions)
            // ? this.api.saveCPWSAssessment({ cpws, regulatoryQuestions, justification })
            await this.api.saveCPWSAssessment({
                cpws,
                regulatoryQuestions,
                ds_dp: category,
                sterile_non_sterile: sterilization,
                modalityList: productModalitySelections,
                user_nri_ri,
                system_nri_ri,
                biologic_nri_ri,
                vaccine_nri_ri,
                smallmolecule_nri_ri,
                scope_change_question,
                screen4_update,
                prat,
                sterileAnswered,
                submission_req_details,
                productsSitesImpacted,
                classificationCodes,
                materialCodeMapping,
                userSelectedMaterials,
                directionalDetails,
                answers,
                matchCriteria,
                assessments
            });

        saveAssessmentStatus === 200
            ? this.setState({ cpwsCompleted: 'Complete', unlockPDF: true }, () => {
                this.sendSessionUpdate();
            })
            : this.setState({ cpwsCompleted: 'not complete', unlockPDF: false }, () => {
                this.sendSessionUpdate();
            });

        return saveAssessmentStatus;
    };    

    pdfGenerated = () => {
        this.setState({ PDF: false });
    };
    
    pdfCalled = async () => {        

        const matchCriteria = this.state.matchCriteriaYesRegulation.length > 0 ? this.state.matchCriteriaYesRegulation[0] : [];
        const cht_codes = [];

        for (const match in matchCriteria) {
            const matchInfo: any = matchCriteria[match];
            for (const info of matchInfo) {
                let getInfo: any = info;
                if (getInfo['regulations']) {
                    for (const regulation of getInfo['regulations']) {
                        if (regulation['regMarket'] === 'Red Compass') {
                            cht_codes.push(regulation['chtCode'])
                        }
                    }
                }
            }
        }

        const reducer = (codes: Array<string>, country: Country) => {
            if (
                (country.pdsFlag &&
                    Object.prototype.hasOwnProperty.call(country, 'manuallyAdded') &&
                    country.manuallyAdded) ||
                (country.pdsFlag && !Object.prototype.hasOwnProperty.call(country, 'manuallyAdded')) ||
                (!country.pdsFlag &&
                    Object.prototype.hasOwnProperty.call(country, 'manuallyAdded') &&
                    country.manuallyAdded)
            ) {
                codes.push(country.code);
            }
            return codes;
        };

        let tmpCountries = this.state.impactedCountries.map((country: Country) => country.code);
        let tmpMarkets = this.state.impactedCountries.reduce(reducer, []);
        
        const ReqCountries = tmpMarkets.length > 0 ? tmpMarkets : tmpCountries;
        if(ReqCountries == undefined || ReqCountries.length == 0 || cht_codes.length == 0) {
            this.state.logger.setError('Unable to Export Submission Workbook');
            notification.warning({
                message: 'Unable to Export Submission Workbook',
                description: 'Exporting Submission Workbook requires at least, one or more modalities, market(s) and related Red Compass change type code(s).',
                placement: 'topRight',
                duration: 15,
            }); 
        } else {        
            const requestSubmissionWorkbookID = await this.api.requestSubmissionWorkbook(this.state.cpws, this.state.productModalitySelections, ReqCountries, cht_codes);
            //console.log('requestSubmissionWorkbook: ', requestSubmissionWorkbookID)
            if(requestSubmissionWorkbookID == "error") {
                this.state.logger.setError('Unable to export submission workbook');
                notification.warning({
                    message: 'Unable to Export Submission Workbook',
                    description: 'Error While Generating Submission Workbook.',
                    placement: 'topRight',
                    duration: 15,
                }); 
            } else {

                const submissionWorkbookData = await this.api.downloadSubmissionWorkbook(requestSubmissionWorkbookID);
                //console.log('submissionWorkbookData: ', submissionWorkbookData)


                if (submissionWorkbookData !== false) {
                    var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
                    var a = document.createElement('a');
                    a.href = mediaType + submissionWorkbookData;
                    const today = new Date();
                    let formattedDate = today.toLocaleDateString();
                    formattedDate = formattedDate.replace(/\//g, '_')
                    a.download = `Submission_Workbook_${this.state.cpws.cpwsId}_${formattedDate}.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    this.state.logger.setError('Unable to export submission workbook');
                    notification.warning({
                        message: 'Unable to export submission workbook',
                        description: '',
                        placement: 'topRight',
                        duration: 15,
                    }); 
                }
            }
        }        
    };
    // state calls end

    // update user session save all data
    lockedFileWarning = (user: any, cpwsID: string,
        firstName: string,
        lastName: String,) => {
        notification.warning({
            message: `CAKE record for IPI ID ${cpwsID} is Locked by ${user}, ${firstName}, ${lastName}`,
            description: `The selected CAKE record for IPI ID ${cpwsID} is currently in-use by ${user}, ${firstName}, ${lastName}, please try again later or select another IPI ID ${cpwsID}`,
            placement: 'topRight',
            duration: 15,
        });
    };

    sessionExpired = () => {
        notification.warning({
            message: 'Your Session has expired',
            description:
                'Sorry, your current session has exceeded the maximum time-out and has expired, please log-out and back into the CAKE application...',
            placement: 'topRight',
            duration: 15,
        });
    };

    error = () => {
        notification.warning({
            message: 'Error saving session data',
            description: 'An error has occured while trying to save your current session data...',
            placement: 'topRight',
            duration: 15,
        });
    };
    sendSessionUpdate = async (page_save = "default"): Promise<void> => {
        const {
            cpws,
            status,
            current,
            prevStep,
            category,
            sterilization,
            productsAvailable,
            impactedMaterialsAvailable,
            impactedMaterialsAvailableOption,
            downstreamImpactedMaterialsAvailableOption,
            tab_num,
            visitedChangeClassification,
            comment_details,
            downstreamMaterials,
            sitesAvailable,
            productsSitesImpacted,
            appCacheFlags,
            downstreamProducts,
            downstreamSites,
            classificationList,
            matchCriteria,
            assessmentResults,
            comment,
            productTypeSelection,
            impactedMaterialsExternal,
            sendExternalMaterials,
            impactedMaterialType,
            qaQuestionAnswered,
            dpDsAnswered,
            sterileAnswered,
            impactedCountries,
            matchCriteriaYesRegulation,
            cpwsCompleted,
            unlockPDF,
            regulatoryQuestions,
            justification,
            productModalitySelections,
            siteFunctions,
            materialClassificationUuid,
            matchCriteriaAnswers,
            matchCriteriaKeyOrder,
            productColorTags,
            siteColorTags,
            formFields,
            nriOption,
            rationaleAssessment,
            userAssessment,
            userAssessmentRationale,
            additionalNotes,
            cakeSuggestions,
            reviewer,
            majorMarkets,
            secondaryMarkets,
            basicInfo,
            supportingDetails,
            additionalInfoData,
            relatedChanges,
            endorsements,
            productInformation,
            staticProductInformation,
            suppliers,
            materials,
            methods,
            partners,
            plants,
            registeredMarkets,
            impactAnalysis,
            cake_status,
            user_nri_ri,
            system_nri_ri,
            biologic_nri_ri,
            vaccine_nri_ri,
            smallmolecule_nri_ri,
            scope_change_question,
            screen4_update,
            update_type,
            user_comment,
            userSelectedMaterials,
            prat,
            submission_req_details,
            currentPlanningAssessmentType,
            currentDirectionalAssessmentReason,
            directionalDetails
        } = this.state;

        const cpwsId = cpws.cpwsId;
        const isid = await this.getISID();
        const first_name = await this.getFirstName();
        const last_name = await this.getLastName();

        if (!formFields.viewOnly && window.sessionStorage.getItem("ApiFlagError") != 'true') {
            const updateSession = await this.api.sendUpdate(
                status,
                isid,
                first_name,
                last_name,
                prevStep,
                current,
                cpwsId,
                cpws,
                basicInfo,
                supportingDetails,
                additionalInfoData,
                relatedChanges,
                endorsements,
                productInformation,
                suppliers,
                materials,
                methods,
                partners,
                plants,
                registeredMarkets,
                impactAnalysis,
                userSelectedMaterials,
                category,
                sterilization,
                productsAvailable,
                impactedMaterialsAvailable,
                impactedMaterialsAvailableOption,
                downstreamImpactedMaterialsAvailableOption,
                tab_num,
                visitedChangeClassification,
                comment_details,             
                staticProductInformation,
                sitesAvailable,
                productsSitesImpacted,
                appCacheFlags,
                downstreamProducts,
                downstreamSites,
                classificationList,
                matchCriteria,
                assessmentResults,
                comment,
                productTypeSelection,
                impactedMaterialsExternal,
                sendExternalMaterials,
                impactedMaterialType,
                qaQuestionAnswered,
                dpDsAnswered,
                sterileAnswered,
                impactedCountries,
                matchCriteriaYesRegulation,
                cpwsCompleted,
                unlockPDF,
                regulatoryQuestions,
                justification,
                downstreamMaterials,
                productModalitySelections,
                siteFunctions,
                materialClassificationUuid,
                matchCriteriaAnswers,
                matchCriteriaKeyOrder,
                productColorTags,
                siteColorTags,
                nriOption,
                rationaleAssessment,
                userAssessment,
                userAssessmentRationale,
                additionalNotes,
                cakeSuggestions,
                reviewer,
                majorMarkets,
                secondaryMarkets,
                this.determineRegulatoryImpact(regulatoryQuestions, this.state.matchCriteriaYesRegulation),
                cake_status,
                user_nri_ri,
                system_nri_ri,
                biologic_nri_ri,
                vaccine_nri_ri,
                smallmolecule_nri_ri,
                scope_change_question,
                screen4_update,
                update_type,
                user_comment,
                prat,
                submission_req_details,
                currentPlanningAssessmentType,
                currentDirectionalAssessmentReason,
                directionalDetails
            );

            if (updateSession.status === 'session_expired') {
                this.sessionExpired();
                this.sessionExpiredLogout();
            } else if(updateSession.status === 'not_proceed'){
                notification.error({
                    message: 'CAKE assessment for this IPI failed to save',
                    description: `Failed to Save CAKE assessment for this IPI:${cpwsId}, please try again later.`,
                    placement: 'topRight',
                    duration: 5,
                    className: 'nav-warnings',
                });
            } else {
                if(page_save === "page_save"){
                    notification.success({
                        message: 'CAKE assessment for this IPI successfully saved',
                        description: `Your CAKE assessment for this IPI:${cpwsId} has been saved to the database.`,
                        placement: 'topRight',
                        duration: 5,
                        className: 'nav-warnings',
                    });

                    // notification.error({
                    //     message: 'CAKE assessment for this IPI failed to save',
                    //     description: `Failed to Save CAKE assessment for this IPI:${this.state.cpws.cpwsId}, please try again later.`,
                    //     placement: 'topRight',
                    //     duration: 5,
                    //     className: 'nav-warnings',
                    // });

                }
                console.log('data saved...: ', updateSession);
            }

            if (prevStep !== current) {
                this.setState({ prevStep: current });
            }
        }
    };
    getISID: Function = async (): Promise<string> => {
        let isid = '';
        try {
            await Auth.currentAuthenticatedUser().then((user: any) => {
                isid = user.signInUserSession.idToken.payload.identities[0].userId;
            });
        } catch (ex) {
            console.log("Error in getISID Function", ex);
        }
        return isid;
    };
    getFirstName: Function = async (): Promise<string> => {
        let firstName = '';
        try {
            await Auth.currentAuthenticatedUser().then((user: any) => {
                firstName = user.attributes['custom:first_name'];
            });
        } catch (ex) {
            console.log("Error in getFirstName Function", ex);
        }
        return firstName;
    };

    getLastName: Function = async (): Promise<string> => {
        let lastName = '';
        try {
            await Auth.currentAuthenticatedUser().then((user: any) => {
                lastName = user.attributes['custom:last_name'];
            });
        } catch (ex) {
            console.log("Error in getLastName Function", ex);
        }
        return lastName;
    };

    setSessionID: Function = async (): Promise<void> => {
        const { cpws } = this.state;
        const cpwsSessionID = cpws.cpws_session_id;

        try {
            window.sessionStorage.setItem('sessionID', cpwsSessionID);
        } catch (err) {
            console.log("Error in setSessionID Function", err);
        }
    };

    setCPWSID: Function = async (): Promise<void> => {
        const { cpws } = this.state;
        const cpwsID = cpws.cpwsId;

        try {
            window.sessionStorage.setItem('cpwsID', cpwsID);
        } catch (err) {
            console.log("Error in setCPWSID Function", err);
        }
    };

    setErrorBoundaryContext = () => {
        const { cpws } = this.state;
        const { setErrorBoundaryFields } = this.context;
        try {
            setErrorBoundaryFields({ sessionID: cpws.cpws_session_id, cpwsID: cpws.cpwsId });
        } catch (err) {
            console.log("Error in setErrorBoundaryContext Function", err);
        }
    };

    onScreen3PageChange = (newPageNumber: number) => {
        this.setState({
            screen3PageNumber: newPageNumber,
        });
    };

    onContainerClosurePageChage = (matNumber: string, newPageNumber: number) => {
        this.setState({
            containerClosurePageNumber: {
                ...this.state.containerClosurePageNumber,
                [matNumber]: newPageNumber,
            },
        });
    };

    resetContainerClosurePageNumbers = () => {
        const pageNumbers = cloneDeep(this.state.containerClosurePageNumber);

        this.setState({
            containerClosurePageNumber: mapValues(pageNumbers, () => 1),
            screen3PageNumber: 1,
        });
    };
    
    mapStep = (current:number): string =>{
        switch(current){
            case 1: return 'Discover';
            case 2: return 'Materials';
            case 3: return 'Markets';
            case 4: return 'Impact Assessment';
            case 5: return 'Regulatory Screening';
            default: return 'Discover';
        }
    }

    CPWSSteps(props: StepProps): JSX.Element {
        const { current, goTo, highestAvailable, regulatoryQuestions } = props;
        const areRegQuesYes = someRegulatoryQuestionAnswersAreYes(regulatoryQuestions);

        return (
            <div>
                <Steps current={current - 1} className="cake-steps-header">
                    <Step
                        key="1"
                        title="Discover"
                        onClick={e => (highestAvailable >= 1 ? goTo(1) : e.preventDefault())}
                        status={current === 1 ? 'process' : current > 1 ? 'finish' : undefined}
                    />
                    <Step
                        key="2"
                        title="Materials"
                        onClick={e => (highestAvailable >= 2 && !areRegQuesYes ? goTo(2) : e.preventDefault())}
                        status={
                            current === 2
                                ? 'process'
                                : current !== 2 && highestAvailable >= 2 && !areRegQuesYes
                                    ? 'finish'
                                    : current !== 2 && highestAvailable >= 2 && areRegQuesYes
                                        ? 'wait'
                                        : undefined
                        }
                    />
                    <Step
                        key="3"
                        title="Markets"
                        onClick={e => (highestAvailable >= 3 && !areRegQuesYes ? goTo(3) : e.preventDefault())}
                        status={
                            current === 3
                                ? 'process'
                                : current !== 3 && highestAvailable >= 3 && !areRegQuesYes
                                    ? 'finish'
                                    : current !== 3 && highestAvailable >= 3 && areRegQuesYes
                                        ? 'wait'
                                        : undefined
                        }
                    />
                    <Step
                        key="4"
                        title="Impact Assessment"
                        onClick={e => (highestAvailable >= 4 && !areRegQuesYes ? goTo(4) : e.preventDefault())}
                        status={
                            current === 4
                                ? 'process'
                                : current !== 4 && highestAvailable >= 4 && !areRegQuesYes
                                    ? 'finish'
                                    : current !== 4 && highestAvailable >= 4 && areRegQuesYes
                                        ? 'wait'
                                        : undefined
                        }
                    />
                    <Step
                        key="5"
                        title="Regulatory Screening"
                        onClick={e => (highestAvailable >= 5 ? goTo(5) : e.preventDefault())}
                        status={
                            current === 5 ? 'process' : current !== 5 && highestAvailable >= 5 ? 'finish' : undefined
                        }
                    />
                </Steps>
            </div>
        );
    }

    showStep(current: number): JSX.Element {
        //console.log("State in App: ", this.state)
        switch (current) {
            case 1: {
                return (
                    <FormContext.Provider value={{ ...this.state.formFields, setFormFields: this.setFormFields }}>
                        <Screen1
                            sendCPWSFile={this.sendCPWSFile}
                            downloadFile={this.loadCPWS}
                            cpws={this.state.cpws}
                            impactedCountries={this.state.impactedCountries}
                            globalProps={this.globalProps}
                            onText={this.changeComment}
                            comment={this.state.comment}
                            cpwsId={this.state.cpws.cpwsId}
                            title={this.state.cpws.title}                            
                            staticProductInformation={this.state.staticProductInformation}
                            status={this.state.status}
                            ingestDataAsync={this.ingestCPWSDataAsync}
                            editData={this.editCPWSData}
                            sessionUpdate={this.sendSessionUpdate}
                            s3Loaded={this.state.tokenDetected}
                            isViewOnlyUser={this.state.isViewOnlyUser}
                            current={current}                            
                            viewData={this.viewCPWSData}
                            lockedByUserFirstName={this.state.lockedByUserLastName}
                            lockedByUserLastName={this.state.lockedByUserLastName}
                            getRelatedChanges={this.getRelatedChanges}
                            isGetRelatedChangesAPISucess={this.state.isGetRelatedChangesAPISucess}
                        />
                    </FormContext.Provider>
                );
            }
            case 2: {
                return (
                    <FormContext.Provider value={{ ...this.state.formFields, setFormFields: this.setFormFields }}>
                        <Screen2
                            cpws={this.state.cpws}
                            cpwsId={this.state.cpws.cpwsId}
                            title={this.state.cpws.title}
                            current={current}
                            tabNum={this.state.tab_num}
                            updateVisitedClassification={this.updateVisitedClassification}
                            visitedChangeClassification={this.state.visitedChangeClassification}
                            sterilization={this.changeSterilization}
                            category={this.changeCategory}
                            materialEvents={{
                                onSelect: this.addMaterial,
                                //loadMaterials: this.loadImpactedMaterials,
                                loadMaterialsExternal: this.loadImpactedMaterialsExternal,
                                onMaterialSelection: this.onMaterialSelection,
                            }}
                            impactedMaterialsAvailable={this.state.impactedMaterialsAvailable}
                            impactedMaterialsAvailableOption={this.state.impactedMaterialsAvailableOption}
                            downstreamImpactedMaterialsAvailableOption={this.state.downstreamImpactedMaterialsAvailableOption}
                            globalProps={this.globalProps}
                            staticProductInformation={this.state.staticProductInformation}
                            comment={this.state.comment}
                            setScreen2Validation={(e: any) => { this.setScreen2Validation(e, this.state.formFields, this.setFormFields); }}
                            addMaterial={this.addMaterial}
                            impactedMaterialsExternal={this.state.impactedMaterialsExternal}
                            sessionUpdate={this.sendSessionUpdate}
                            deleteMaterial={this.deleteMaterial}
                            editExternalMaterial={this.editExternalMaterial}
                            materialTableRef={this.formRefs.materialSelected}
                            formRefs={this.formRefs}
                            userSelectionCategory={this.state.category}
                            userSelectionSterilization={this.state.sterilization}
                            onProductModalityChange={this.onProductModalityChange}
                            updateProductModalityChange={this.updateProductModalityChange}
                            userSelectionProductModality={this.state.productModalitySelections}
                            postClassificationData={this.postClassificationData}
                            colorTags={{
                                productColorTags: this.state.productColorTags,
                                siteColorTags: this.state.siteColorTags,
                            }}
                            loadClassificationList={this.loadClassificationList}
                            classificationList={this.state.classificationList}
                            updateMaterialClassification={this.updateMaterialClassification}
                            updateMaterial={this.updateMaterial} // changed here
                            loadedClassification={this.state.appCacheFlags.loadClassificationList}
                            loadClassificationListFallback={this.loadClassificationListFallback}
                            classificationCodes={this.state.classificationCodes}
                            changeCPWSProps={this.changeCPWSProps}
                            removeMaterialType={this.removeMaterialType}
                            removeManuallyEnteredMaterials={this.removeManuallyEnteredMaterials}
                            loadMaterialType={this.loadImpactedMaterialTypeWithCount}
                            loadProductMaterialType={this.loadImpactedProductMaterialTypeWithCount}
                            impactedMaterialType={this.state.impactedMaterialType}
                            triggerApplyAllImpactedMaterials={this.impactedMaterialsApplyAll}
                            classificationListApplyAll = {this.classificationListApplyAll}
                            screen3PageNumber={this.state.screen3PageNumber}
                            onScreen3PageChange={this.onScreen3PageChange}
                            materialTypeRef={this.formRefs.materialTypeSelected}
                            formExternalMaterialsMissingRef={this.formRefs.formExternalMaterialsMissing}
                            downstreamMaterialTypeSelectedRef={this.formRefs.downstreamMaterialTypeSelected}
                            downstreamMaterialTypeRef={this.formRefs.downstreamMaterialType}
                            addMaterialContainerClosure={this.addMaterialContainerClosure}
                            removeMaterialContainerClosure={this.removeDownstreamType}
                            addDownstreamMaterial={this.addDownstreamMaterial}
                            getDownstreamMaterials={this.getDownstreamMaterials}
                            getImpactedProducts={this.getImpactedProducts}
                            getImpactedMaterials={this.getImpactedMaterials}
                            containerClosureApplyAll={this.containerClosureApplyAll}
                            removeExternalMaterialFromContainerClosure={this.removeExternalMaterialFromContainerClosure}
                            impactedMaterialsRef={this.formRefs.impactedMaterials}
                            containerClosurePageNumber={this.state.containerClosurePageNumber}
                            onContainerClosurePageChange={this.onContainerClosurePageChage}
                            expandedRowKeys={this.state.expandedRowKeys}
                            setExpandedRowKeys={this.setExpandedRowKeys}
                            downstreamProducts={this.state.downstreamProducts}
                            resetContainerClosurePageNumbers={this.resetContainerClosurePageNumbers}
                            loadAdditionalInfo={this.loadAdditionalInfo}
                            updateUserSelectedMaterialProps={this.updateUserSelectedMaterialProps}
                            updateHighestAvailableforScreen2={this.updateHighestAvailableforScreen2}
                            updateImpactedMaterialsOption={this.updateImpactedMaterialsOption}
                            updateDownstreamImpactedMaterialsOption={this.updateDownstreamImpactedMaterialsOption}
                            updateTabSelection={this.updateTabSelection}
                            userSelectedMaterialsWithMaterialType={this.state.userSelectedMaterials}
                            userSelectedExternalMaterials={this.updateUserSelectedExternalMaterialProps}
                            userSelectedExternalMaterialProps={this.state.userSelectedExternalMaterials}
                            appCacheFlags={this.state.appCacheFlags}
                        ></Screen2>
                    </FormContext.Provider>
                );
            }
            case 3: {
                return (
                    <FormContext.Provider value={{ ...this.state.formFields, setFormFields: this.setFormFields }}>
                        <Screen3
                            cpws={this.state.cpws}
                            impactedCountries={this.state.impactedCountries}
                            loadAdditionalInfo={this.loadAdditionalInfo}
                            globalProps={this.globalProps}
                            loadSiteFunctions = {this.loadSiteFunctions}
                            sessionUpdate={this.sendSessionUpdate}
                            siteFunctions={this.state.siteFunctions}
                            onFunctionSelect={this.addSiteFunction}
                            onFunctionDeselect={this.removeSiteFunction}
                            onSiteSelect={this.addSite}
                            onSiteDeselect={this.removeSite}
                            siteFunctionsRef={this.formRefs.siteFunctions}
                            staticProductInformation={this.state.staticProductInformation}
                            siteFunctionCardRef={this.formRefs.siteFunctionCardRef}
                            updateCountries={this.updateCountries}
                            resetPDS={this.resetPDSSites}
                        ></Screen3>
                    </FormContext.Provider>
                );
            }
            case 4: {
                return (
                    <FormContext.Provider value={{ ...this.state.formFields, setFormFields: this.setFormFields }}>
                        <Screen4
                            loadMatchCriteria={this.loadMatchCriteria}
                            matchCriteria={this.state.matchCriteria}
                            cpws={this.state.cpws}
                            globalProps={this.globalProps}
                            updateMatchQuestion={this.updateMatchQuestion}
                            comment={this.state.comment}
                            sessionUpdate={this.sendSessionUpdate}
                            matchCriteriaAnswers={this.state.matchCriteriaAnswers}
                            matchCriteriaCardRef={this.formRefs.matchCriteriaCardRef}
                            materialCodeMapping={this.state.materialCodeMapping}
                            impactedMaterials={this.state.cpws.impactedMaterials}
                            classificationCodes={this.state.classificationCodes}
                            staticProductInformation={this.state.staticProductInformation}
                            screen4_update={this.state.screen4_update}
                            setScreen4Update={this.setScreen4Update}
                        ></Screen4>
                    </FormContext.Provider>
                );
            }
            case 5: {
                return (
                    <FormContext.Provider value={{ ...this.state.formFields, setFormFields: this.setFormFields }}>
                        <Screen5
                            qaQuestionAnswered={this.state.qaQuestionAnswered}
                            updateQaQuestion={this.updateQAQuestion}
                            globalProps={this.globalProps}
                            category={this.state.category}
                            sterilization={this.state.sterilization}
                            userSelectionCategory={this.state.category}
                            userSelectionSterilization={this.state.sterilization}
                            current={current}
                            onProductModalityChange={this.onProductModalityChange}
                            userSelectionProductModality={this.state.productModalitySelections}
                            viewOnly={this.state.formFields.viewOnly}
                            api={this.api}
                            productModalitiesSelections={this.state.productModalitySelections}
                            cpws={this.state.cpws}
                            loadFinalResults={this.loadFinalResults}
                            loadMatchCriteriaYesRegulation={this.loadMatchCriteriaYesRegulation}
                            sessionUpdate={this.sendSessionUpdate}
                            nriOption={this.state.nriOption}
                            rationaleText={this.state.rationaleAssessment}
                            finalAssessmentRef={this.formRefs.finalAssessmentRef}
                            userAssessment={this.state.userAssessment}
                            userAssessmentRationale={this.state.userAssessmentRationale}
                            additionalNotes={this.state.additionalNotes}
                            cakeSuggestions={this.state.cakeSuggestions}
                            updateFinalAssessmentField={this.updateFinalAssessmentField}
                            clearFinalAssessmentField={this.clearFinalAssessmentField}
                            determineRegulatoryImpact={this.determineRegulatoryImpact}
                            reviewer={this.state.reviewer}
                            matchCriteriaYesRegulation={this.state.matchCriteriaYesRegulation}
                            regulatoryQuestions={this.state.regulatoryQuestions}
                            savePratInfo={this.savePratInfo}
                            savesubmission_req_details={this.savesubmission_req_details}
                            currentPratInfo={this.state.prat}
                            current_submission_req_info={this.state.submission_req_details}
                            directionalAssessment={this.state.directionalAssessment}
                            dataRequirements={this.state.dataRequirements}
                            marketLevelAssessment={this.state.marketLevelAssessment}
                            impactedCountries={this.state.impactedCountries}
                            openAttachmentApi={this.openAttachment}
                            savePlanningAssessmentType={this.savePlanningAssessmentType}
                            currentPlanningAssessmentType={this.state.currentPlanningAssessmentType}
                            saveDirectionalAssessment={this.saveDirectionalAssessment}
                            currentDirectionalAssessmentReason={this.state.currentDirectionalAssessmentReason}
                            prat={this.state.prat}
                            system_nri_ri={this.state.system_nri_ri}
                            user_nri_ri={this.state.user_nri_ri}
                            biologic_nri_ri={this.state.biologic_nri_ri}
                            vaccine_nri_ri={this.state.vaccine_nri_ri}
                            smallmolecule_nri_ri={this.state.smallmolecule_nri_ri}
                            scope_change_question={this.state.scope_change_question}
                            staticProductInformation={this.state.staticProductInformation}
                            impactedMaterials={this.state.cpws.impactedMaterials}
                            classificationList={this.state.classificationList}
                            updateMaterial={this.updateMaterial}
                            loading={false}
                            loadedClassification={this.state.appCacheFlags.loadClassificationList}
                            classificationCodes={this.state.classificationCodes}
                            loadClassificationListFallback={this.loadClassificationListFallback}
                            loadClassificationList={this.loadClassificationList}
                            justification={this.state.justification}
                            impactedProducts={this.state.impactedProductMaterials}
                            impactedSites={this.state.cpws.impactedSites}
                            colorTags={{
                                productColorTags: this.state.productColorTags,
                                siteColorTags: this.state.siteColorTags
                            }}
                            addMaterialContainerClosure={this.addMaterialContainerClosure}
                            removeMaterialContainerClosure={this.removeDownstreamType}
                            containerClosureApplyAll={this.containerClosureApplyAll}
                            currentScreen={current}
                            addDownstreamMaterial={this.addDownstreamMaterial}
                            getDownstreamMaterials={this.getDownstreamMaterials}
                            getImpactedProducts={this.getImpactedProducts}
                            getImpactedMaterials={this.getImpactedMaterials}
                            updateDownstreamImpactedMaterialsOption={this.updateDownstreamImpactedMaterialsOption}
                            downstreamImpactedMaterialsAvailableOption={this.state.downstreamImpactedMaterialsAvailableOption}
                            removeExternalMaterialFromContainerClosure={this.removeExternalMaterialFromContainerClosure}
                            removeManuallyEnteredMaterials={this.removeManuallyEnteredMaterials}
                            materials={this.state.userSelectedMaterials}
                            onContainerClosurePageChage={this.onContainerClosurePageChage}
                            impactedMaterialsRef={this.formRefs.impactedMaterials}
                            impactedProductMaterials={this.state.impactedProductMaterials}
                            removeMaterialType={this.removeMaterialType}
                            loadMaterialType={this.loadImpactedMaterialTypeWithCount}
                            triggerApplyAllImpactedMaterials={this.impactedMaterialsApplyAll}
                            classificationListApplyAll={this.classificationListApplyAll}
                            materialTypeRef={this.formRefs.materialTypeSelected}
                            addCustomMaterialContainerClosure={this.addMaterialContainerClosure}
                            expandedRowKeys={this.state.expandedRowKeys}
                            setExpandedRowKeys={this.setExpandedRowKeys}
                            userSelectedMaterials={this.state.userSelectedMaterials}
                            materialTypeWithCount={this.state.impactedMaterialType}                            
                            materialEvents={{
                                onSelect: this.addMaterial,
                                //loadMaterials: this.loadImpactedMaterials,
                                loadMaterialsExternal: this.loadImpactedMaterialsExternal,
                                onMaterialSelection: this.onMaterialSelection,
                            }}
                            impactedMaterialsAvailable={this.state.impactedMaterialsAvailable}
                            impactedMaterialsAvailableOption={this.state.impactedMaterialsAvailableOption}
                            addMaterial={this.addMaterial}
                            externalImpactedMaterials={this.state.newExternalMaterials}
                            deleteMaterial={this.deleteMaterial}
                            editExternalMaterial={this.editExternalMaterial}
                            materialTableRef={this.formRefs.materialSelected}
                            updateUserSelectedMaterialProps={this.updateUserSelectedMaterialProps}
                            updateImpactedMaterialsOption={this.updateImpactedMaterialsOption}
                            userSelectedExternalMaterials={this.state.userSelectedExternalMaterials}
                            areImpactedMaterialsMissing={this.state.impactedMaterialsAvailable}
                            directionalDetails={this.state.directionalDetails}
                            screen4_update={this.state.screen4_update}
                            setScreen4Update={this.setScreen4Update}
                            matchCriteria={this.state.matchCriteria}
                        ></Screen5>
                    </FormContext.Provider>
                );
            }
            
            
            default: {
                return (
                    <Screen0
                        sendCPWSFile={this.sendCPWSFile}
                        downloadFile={this.loadCPWS}
                        globalProps={this.globalProps}
                        cpws={this.state.cpws}
                        onText={this.changeComment}
                        comment={this.state.comment}
                    ></Screen0>
                );
            }
        }
    }

    async checkIfLoggedIn(): Promise<any> {
        // Check if user is already logged in
        try {
            await Auth.currentAuthenticatedUser().then((value: any) => {
                if (value.attributes['custom:membersOf'].includes(config.distributionListName)) {
                    this.setState({ authState: 'signedIn' });
                } else {
                    this.setState({ authState: 'unauthorized' });
                }
                return;
            });
        } catch (ex) {
            console.log('user not signed in', this.state.authState);
            this.logout();
        }
        return;
    }

    sessionExpiredLogout: Function = async (): Promise<any> => {
        await this.api.logout();
        await Auth.federatedSignIn()
            .then(() => {
                this.setState({ authState: 'signIn' });
            })
            .catch((err: any) => {
                console.log("Session Expired Logout", err);
            });
        return;
    };
    warningHeaderPopup = async (action: string): Promise<any> => {
        let cpwsID = this.state.cpws.cpwsId;
        if(cpwsID != "0") {
            const message = (
                <Space direction="vertical" size={4}>
                    <Text>{`You are currently working on the IPI ID ${cpwsID}. Please SAVE your work, otherwise it will be lost.`}</Text>
                    <br />
                    <div>
                        <WarningOutlined />
                        <Text>
                            <Text strong style={{ color: 'red' }}>{`Do you want to ${action}?`} </Text>
                        </Text>
                    </div>
                </Space>
            );
            Modal.confirm({
                icon: <ExclamationCircleOutlined className='display-none' />,
                content: message,
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    if(action == 'logout') {                         
                        this.logout();
                    } else if(action == 'refresh') {
                        this.afterPDFSave();
                    }
                },
                onCancel: () => {
                },
                className: 'modal-style',
            });
        } else {
            if(action == 'logout') { 
                this.logout();
            } else if(action == 'refresh') {
                this.afterPDFSave();
            }
        }
    }

    logout: Function = async (): Promise<any> => {
        const { cpws } = this.state;
        //console.log(cpws.cpwsId);
        if (cpws.cpwsId !== '0') {
            await this.sendSessionUpdate();
        }

        const sendLogout = await this.api.logout();

        await Auth.federatedSignIn()
            .then(() => {
                this.setState({ authState: 'signIn' });
            })
            .catch((err: any) => {
                console.log("Error in Logout", err);
            });
        return;
    };

    hideHeader = () => {
        const { PDF } = this.state;
        return PDF === true ? 0 : 1;
    };

    setFormFields = (fields: FormValidationFields) => {
        this.setState({
            formFields: { ...fields },
        });
    };

    setExpandedRowKeys = (keys: Array<string>) => {
        this.setState({
            expandedRowKeys: keys,
        });
    };

    handleMissingDownstreamMaterial = (
        sortedImpactedMaterials: Array<Material>,
        material: Material,
        impactedMaterialsRefs: ImpactedMaterialsRef,
    ) => {
        const matRef = find(impactedMaterialsRefs, ['number', material.number]);
        if (matRef && matRef.ref) {
            return {
                ref: matRef.ref,
                page: Math.floor(findIndex(sortedImpactedMaterials, ['number', material.number]) / 10 + 1),
                type: 'material',
                matNumber: material.number,
                needsExpansion: true,
            };
        } else {
            return {
                ref: undefined,
                page: 1,
                type: 'material',
                matNumber: material.number,
                needsExpansion: false,
            };
        }
    };

    handleMissingDownstreamType = (
        material: Material,
        impactedMaterialsRefs: ImpactedMaterialsRef,
        impactedMaterials: Array<Material>,
    ) => {
        const matPageNumber = Math.floor(findIndex(impactedMaterials, ['number', material.number]) / 10 + 1);
        const matRefIndex = findIndex(impactedMaterialsRefs, ['number', material.number]);
        const downstreamMatIndex = findIndex(
            material.downstreamMaterials,
            (downstream: ContainerClosureMaterial) => downstream.type.length === 0,
        );

        if (matPageNumber < 2) {
            if (matRefIndex > -1) {
                if (downstreamMatIndex > -1) {
                    const downstreamRef = find(impactedMaterialsRefs[matRefIndex].downstreamRefs, [
                        'number',
                        material.downstreamMaterials[downstreamMatIndex].number,
                    ]);
                    if (downstreamRef && downstreamRef.ref) {
                        return {
                            ref: downstreamRef.ref,
                            page: Math.floor(downstreamMatIndex / 10 + 1),
                            type: 'downstream',
                            imtPage: matPageNumber,
                            matNumber: material.number,
                        };
                    }
                }
            }
        } else {
            const matRef = impactedMaterialsRefs[matRefIndex];
            if (matRef && matRef.ref) {
                return {
                    ref: matRef.ref,
                    page: Math.floor(downstreamMatIndex / 10 + 1),
                    type: 'downstream',
                    imtPage: matPageNumber,
                    matNumber: material.number,
                };
            }
        }
        return {
            ref: undefined,
            page: Math.floor(downstreamMatIndex / 10 + 1),
            type: 'downstream',
            imtPage: matPageNumber,
            matNumber: material.number,
        };
    };

    determineRegulatoryImpact = (regulatoryQuestions: RegulatoryQuestions, matchCriteria: MatchCriteria): boolean => {
        /**
         * Currently, question1 -> childQuestion1 and question3 yield RI.
         */
        if (someRegulatoryQuestionAnswersAreYes(regulatoryQuestions)) {
            if (
                regulatoryQuestions['question3'].answer ||
                regulatoryQuestions['question5'].answer ||
                regulatoryQuestions['question4'].answer ||
                regulatoryQuestions['question2'].answer ||
                (regulatoryQuestions['question1'].answer &&
                    regulatoryQuestions['question1'].children &&
                    regulatoryQuestions['question1'].children['childQuestion1'].answer)
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return matchCriteria.length > 0;
        }
    };

    screen3Paginate = (
        impactedMaterials: Array<Material>,
        impactedMaterialsRefs: ImpactedMaterialsRef,
    ): ScrollToImpactedMaterial | ScrollToDownstreamMaterial => {
        const sortedImpactedMaterials = sortObjectArrayByValue(impactedMaterials, 'name');
        const index = sortedImpactedMaterials.findIndex((mat: Material) => mat.type.length === 0);

        if (index > -1) {
            const matRef = find(impactedMaterialsRefs, ['number', sortedImpactedMaterials[index].number]);
            if (matRef && matRef.ref) {
                return {
                    ref: matRef.ref,
                    page: Math.floor(index / 10 + 1),
                    type: 'material',
                    matNumber: sortedImpactedMaterials[index].number,
                    needsExpansion: false,
                };
            }
        } else {
            const matMissingDownstreamMat = sortedImpactedMaterials.filter(
                (mat: Material) =>
                    mat.type.some((matType: MaterialType) => matType.code === 'T14') &&
                    mat.downstreamMaterials.length === 0,
            );
            const matWithDownstreamMissingType = sortedImpactedMaterials.filter((mat: Material) =>
                mat.type.some(
                    (matType: MaterialType) =>
                        matType.code === 'T14' &&
                        !mat.downstreamMaterials.every(
                            (downstreamMat: ContainerClosureMaterial) => downstreamMat.type.length > 0,
                        ),
                ),
            );

            if (matMissingDownstreamMat.length > 0 && matWithDownstreamMissingType.length > 0) {
                if (matMissingDownstreamMat[0].name < matWithDownstreamMissingType[0].name) {
                    return this.handleMissingDownstreamMaterial(
                        sortedImpactedMaterials,
                        matMissingDownstreamMat[0],
                        impactedMaterialsRefs,
                    );
                } else {
                    return this.handleMissingDownstreamType(
                        matWithDownstreamMissingType[0],
                        impactedMaterialsRefs,
                        sortedImpactedMaterials,
                    );
                }
            } else if (matMissingDownstreamMat.length > 0) {
                return this.handleMissingDownstreamMaterial(
                    sortedImpactedMaterials,
                    matMissingDownstreamMat[0],
                    impactedMaterialsRefs,
                );
            } else if (matWithDownstreamMissingType.length > 0) {
                return this.handleMissingDownstreamType(
                    matWithDownstreamMissingType[0],
                    impactedMaterialsRefs,
                    sortedImpactedMaterials,
                );
            } else {
                return {
                    ref: undefined,
                    page: 1,
                    type: 'material',
                    matNumber: '',
                    needsExpansion: false,
                };
            }
        }
        return {
            ref: undefined,
            page: 1,
            type: 'none',
            matNumber: '',
            needsExpansion: false,
        };
    };

    screen5scroll = (impactedMaterials: Array<Material>) => {
        const initialScrollHeight = 346;
        const listItemHeight = 133;

        const index = impactedMaterials.findIndex((material: Material) => {
            return every(material.classification, value => {
                if (value) {
                    return value <= 50;
                } else {
                    return false;
                }
            });
        });

        return index * listItemHeight + initialScrollHeight - listItemHeight;
    };

    siteFunctionsScroll = (impactedSites: Array<Site>, formRefs: FormRefs) => {
        const index = impactedSites.findIndex((site: Site) => site.isPDS && site.siteFunctions.length === 0);
        if (index > -1) {
            const refObj = find(formRefs.siteFunctions, ['name', impactedSites[index].name]);
            return refObj;
        }
    };

    scrollTo = (formFields: Partial<FormValidationFields>, screen: string) => {
        if (screen === 'screen1') {
            if (!formFields.regulatoryQuestionsAnswered && this.formRefs.regulatoryQuestionsAnswered.current) {
                window.scrollTo(0, this.formRefs.regulatoryQuestionsAnswered.current.offsetTop);
            } else if (!formFields.justificationProvided && this.formRefs.justificationProvided.current) {
                window.scrollTo(0, this.formRefs.justificationProvided.current.offsetTop);
            } else if (!formFields.productsSelected && this.formRefs.productsSelected.current) {
                window.scrollTo(0, this.formRefs.productsSelected.current.offsetTop);
            } else if (!formFields.sitesSelected && this.formRefs.sitesSelected.current) {
                window.scrollTo(0, this.formRefs.sitesSelected.current.offsetTop);
            } else if (!formFields.siteFunctionsSelected) {
                const refObj = this.siteFunctionsScroll(this.state.cpws.impactedSites.selected, this.formRefs);
                if (refObj && refObj.ref.current && this.formRefs.sitesSelected.current) {
                    window.scrollTo(0, this.formRefs.sitesSelected.current.offsetTop + refObj.ref.current.offsetTop);
                }
            } else if (!formFields.cpwsQuestionsAnswered && this.formRefs.cpwsQuestionsAnswered.current) {
                window.scrollTo(0, this.formRefs.cpwsQuestionsAnswered.current.offsetTop);
            }
        } else if (screen === 'screen2') {
            if (!formFields.materialSelected && this.formRefs.materialSelected.current) {
                window.scrollTo(0, this.formRefs.materialSelected.current.offsetTop);
            }
        } else if (screen === 'screen3') {
            if (!formFields.materialTypeSelected && this.formRefs.materialTypeSelected.current) {
                const res = this.screen3Paginate(this.state.cpws.impactedMaterials, this.formRefs.impactedMaterials);

                if (res.type === 'material') {
                    const impactedMatObj = res as ScrollToImpactedMaterial;
                    const rowKeys = [...this.state.expandedRowKeys];
                    if (impactedMatObj.needsExpansion && !rowKeys.includes(impactedMatObj.matNumber)) {
                        rowKeys.push(impactedMatObj.matNumber);
                    }
                    this.setState({ screen3PageNumber: impactedMatObj.page, expandedRowKeys: rowKeys }, () => {
                        if (impactedMatObj.ref && impactedMatObj.ref.current) {
                            window.scrollTo(
                                0,
                                window.pageYOffset + impactedMatObj.ref.current.getBoundingClientRect().top - 150,
                            );
                        } else {
                            window.scrollTo(0, 0);
                        }
                    });
                }

                if (res.type === 'downstream') {
                    const downstreamMatObj = res as ScrollToDownstreamMaterial;
                    const rowKeys = [...this.state.expandedRowKeys];
                    if (!rowKeys.includes(downstreamMatObj.matNumber)) {
                        rowKeys.push(downstreamMatObj.matNumber);
                    }
                    this.setState(
                        {
                            containerClosurePageNumber: {
                                ...this.state.containerClosurePageNumber,
                                [downstreamMatObj.matNumber]: downstreamMatObj.page,
                            },
                            screen3PageNumber: downstreamMatObj.imtPage,
                            expandedRowKeys: rowKeys,
                        },
                        () => {
                            if (downstreamMatObj.ref && downstreamMatObj.ref.current && downstreamMatObj.imtPage < 2) {
                                window.scrollTo(
                                    0,
                                    window.pageYOffset + downstreamMatObj.ref.current.getBoundingClientRect().top - 200,
                                );
                            } else if (downstreamMatObj.ref && downstreamMatObj.ref.current) {
                                window.scrollTo(
                                    0,
                                    window.pageYOffset + downstreamMatObj.ref.current.getBoundingClientRect().top + 75,
                                );
                            } else {
                                if (this.formRefs.materialTypeSelected.current) {
                                    window.scrollTo(
                                        0,
                                        window.pageYOffset +
                                        this.formRefs.materialTypeSelected.current.getBoundingClientRect().top -
                                        200,
                                    );
                                }
                            }
                        },
                    );
                }
            } else if (!formFields.siteFunctionsSelected) {
                const refObj = this.siteFunctionsScroll(this.state.cpws.impactedSites.selected, this.formRefs);
                if (refObj && refObj.ref.current && this.formRefs.siteFunctionCardRef.current) {
                    window.scrollTo(
                        0,
                        this.formRefs.siteFunctionCardRef.current.offsetTop + refObj.ref.current.offsetTop,
                    );
                }
            }
        } else if (screen === 'screen4') {
            if (!formFields.qualitySelected && this.formRefs.qualitySelected.current) {
                window.scrollTo(0, this.formRefs.qualitySelected.current.offsetTop);
            }
        } else if (screen === 'screen5') {
            if (!formFields.classificationSelected) {
                window.scrollTo(0, this.screen5scroll(this.state.cpws.impactedMaterials));
            }
        } 
    };

    setScreen4Update = (updateValue: boolean) => {
        this.setState({screen4_update: updateValue});
    }

    savePratInfo = async (prat: any) => {
        this.setState({prat: prat, unlockPDF : false});
    }

    savesubmission_req_details = async (submission_req_details: any) => {
        this.setState({submission_req_details: submission_req_details, unlockPDF: false});
    }

    savePlanningAssessmentType = async (planningAssessmentType: string) => {
        this.setState({currentPlanningAssessmentType: planningAssessmentType, unlockPDF: false});
    }

    saveDirectionalAssessment = async (directionalAssessmentReason: string) => {
        this.setState({currentDirectionalAssessmentReason: directionalAssessmentReason, unlockPDF: false});
    }

    render(): JSX.Element {
        const { current, authState, PDF, afterPDFSaveRefresh } = this.state;
        const query = new URLSearchParams(window.location.search);

        return query.get('error_description') || authState === 'unauthorized' ? (
            <Result
                title="Sorry, you are not authorized to access CAKE"
                subTitle="To gain access to CAKE, please contact a CAKE admin to be added to the distribution list."
                status="info"
            />
        ) : (
            <div>
                {authState === 'loading' || afterPDFSaveRefresh ? (
                    <Spin size="large" className="loading-spin" />
                ) : (
                    <Layout className="Layout">
                        {PDF === true ? (
                            <div className="print-logo">CAKE</div>
                        ) : (
                            <Header className="App-header" style={{ opacity: this.hideHeader() }}>
                                <div className='logo-container'>
                                    <img src='../cake-logo.png' className='logo-style' alt='Cake Logo' title='Refresh Cake' onClick={() => this.warningHeaderPopup('refresh')}></img>
                                </div>    
                                <this.CPWSSteps
                                    current={current}
                                    goTo={this.goTo}
                                    highestAvailable={this.state.appCacheFlags.highestAvailable}
                                    regulatoryQuestions={this.state.regulatoryQuestions}
                                ></this.CPWSSteps>
                                <Popover content={`${this.state.firstName}, ${this.state.lastName}`} placement={'topRight'}>
                                    <UserOutlined className="login-user-icon" />
                                </Popover>
                                <CommentOutlined className="login-user-icon" onClick={this.openCommentModal}/> 
                                <Modal
                                    visible={this.state.commentModalVisibility}
                                    closable={false}
                                    centered={true}
                                    width={550}
                                    className={'comment-modal-style'}
                                    footer={this.state.formFields.viewOnly ?
                                        [<Button key="cancel" onClick={this.closeCommentModal}>
                                        Close
                                        </Button>]
                                        :
                                        [
                                        <Button key="submit" type="primary" onClick={this.submitCommentModal}>
                                            Submit
                                        </Button>,
                                        <Button key="cancel" onClick={this.closeCommentModal}>
                                            Cancel
                                        </Button>,
                                    ]}
                                >
                                    <Space direction="vertical" size={4}>
                                        <Text>
                                            Suggestions for CAKE (market specific feedback, user experience, questions etc)
                                        </Text>
                                        <TextArea
                                            value={this.state.comment}
                                            disabled={this.state.formFields.viewOnly}
                                            className={'modal-textarea-style'}
                                            onChange={this.userCommentValue}
                                        />
                                    </Space>
                                </Modal>
                                <div className="logout-btn">
                                    <Button type="primary" ghost onClick={() => this.warningHeaderPopup('logout')}>
                                        Logout
                                    </Button>
                                </div>
                            </Header>
                        )}
                        <Content className="App-content">
                            <div>{this.showStep(current)}</div>
                            <Debug appstate={this.state}></Debug>
                        </Content>
                        {PDF ? (
                            <div></div>
                        ) : (
                            // Hide Footer when user prints PDF
                            <FormContext.Provider
                                value={{ ...this.state.formFields, setFormFields: this.setFormFields }}
                            >
                                <StepFooter
                                    current={current}
                                    highestAvailable={this.state.appCacheFlags.highestAvailable}
                                    showFooter={this.state.appCacheFlags.highestAvailable > 0}
                                    prev={() => { this.prev('button'); }}
                                    next={() => { this.next('button'); }}
                                    save={() => { this.save('button'); }}
                                    qaQuestionAnswered={this.state.qaQuestionAnswered}
                                    dsDpAnswered={this.state.dpDsAnswered}
                                    sterileAnswered={this.state.sterileAnswered}
                                    saveAssessment={this.saveCPWSAssessment}
                                    cpws={this.state.cpws}
                                    generatePDF={this.pdfCalled}
                                    unlockPDF={this.state.unlockPDF}
                                    visitedChangeClassification={this.state.visitedChangeClassification}
                                    regulatoryQuestions={this.state.regulatoryQuestions}
                                    justification={this.state.justification}
                                    goTo={this.goTo}
                                    productModalitiesSelections={this.state.productModalitySelections}
                                    scrollTo={this.scrollTo}
                                    afterPDFSave={this.afterPDFSave}
                                    matchCriteriaAnswers={this.state.matchCriteriaAnswers}
                                    loadMatchCriteria={this.state.appCacheFlags.loadMatchCriteria}
                                    nriOption={this.state.nriOption}
                                    rationaleAssessment={this.state.rationaleAssessment}
                                    userAssessment={this.state.userAssessment}
                                    userAssessmentRationale={this.state.userAssessmentRationale}
                                    userSelectedMaterials={this.state.userSelectedMaterials}
                                    impactedMaterialsAvailableOption={this.state.impactedMaterialsAvailableOption}
                                    cakeAssessment={this.determineRegulatoryImpact(
                                        this.state.regulatoryQuestions,
                                        this.state.matchCriteriaYesRegulation,
                                    )}
                                    updateSession={this.sendSessionUpdate}
                                    user_nri_ri={this.state.user_nri_ri}
                                    system_nri_ri={this.state.system_nri_ri}
                                    biologic_nri_ri={this.state.biologic_nri_ri}
                                    vaccine_nri_ri={this.state.vaccine_nri_ri}
                                    smallmolecule_nri_ri={this.state.smallmolecule_nri_ri}
                                    scope_change_question={this.state.scope_change_question}
                                    user_comment={this.state.user_comment}
                                    prat={this.state.prat}
                                    submission_req_details={this.state.submission_req_details}
                                    currentPlanningAssessmentType={this.state.currentPlanningAssessmentType}
                                    currentDirectionalAssessmentReason={this.state.currentDirectionalAssessmentReason}
                                    productInformation={this.state.cpws.productInformation}
                                    productModalitySelections={this.state.productModalitySelections}
                                    directionalDetails={this.state.directionalDetails}
                                    markets={this.state.cpws.registeredMarkets}
                                    countries={this.state.impactedCountries}
                                    updateHighestAvailable = {this.updateHighestAvailableforScreen3}
                                />
                            </FormContext.Provider>
                        )}
                    </Layout>
                )}
            </div>
        );
    }
}