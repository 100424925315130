import { ExpandIconPosition } from 'antd/lib/collapse/Collapse';
import { AxiosResponse } from 'axios';
import React from 'react';

export enum ENV {
    DEV = 'DEV',
    LOCAL = 'LOCAL',
}

export * from './apiResponse';
export interface PanelProps {
    defaultActiveKey: string;
    expandIconPosition: ExpandIconPosition;
}
export interface GlobalProps {
    defaultPanelProps: PanelProps;
}
export interface ProductEvents {
    onSelect: any;
    onDeselect: any;
}

export interface SiteEvents {
    onSelect: Function;
    onDeselect: Function;
    onFunctionSelect: Function;
    onFunctionDeselect: Function;
}
export interface MaterialEvents {
    onSelect?: any;
    onDeselect?: any;
    loadMaterials?: Function;
    loadMaterialsExternal: Function;
    onMaterialSelection: Function;
}
export enum CPWSStateChange {
    LOADED,
    PRODUCTS_UPDATED,
    SITES_UPDATED,
    MATERIALS_UPDATED,
}
export interface AppOptions {
    debug?: boolean;
    mock?: boolean;
    env: ENV;
    id?: string;
    code?: boolean;
}
export interface Filing {
    chtCode: string;
    category: string;
    description: string;
    link: string;
    regionCode: string;
    filing: string;
    approvalTime?: string;
    filingType?: string;
    notification?: string;
    notificationType?: string;
    rank?: number;
}

export interface HistoricalData {
    RAD_RI: number;
    RAD_NRI: number;
    RI_Percent: number;
    NRI_Percent: number;
}
export interface AssessmentResult {
    countryCode: string;
    filingsRC: Array<Filing>;
    filingsHA: Array<Filing>;
    color: string;
    dataRequirements: Array<any>;
    historicalData:HistoricalData;
}

export interface MarketSiteFunction {
    site: string;
    status: string;
    function: string;
}

export interface Country {
    name: string;
    code: string;
    regionCode: string;
    pdsFlag: boolean;
    manuallyAdded?: boolean;
    siteFunctions?: Array<MarketSiteFunction>;
}

export interface ApiCall {
    url: string;
    request: any;
    response?: AxiosResponse;
    error?: any;
}

export interface Logger {
    setError: Function;
    setCall: Function;
    setException: Function;
    calls: Array<ApiCall>;
    errors: Array<ApiCall>;
    exceptions: Array<any>;
}
export interface S3Properties {
    url: string;
    key: string;
    AWSAccessKeyId: string;
    xamzsecuritytoken: string;
    policy: string;
    signature: string;
}
export interface ChildQuestion {
    parentCode: string;
    parentDef: string;
    parentAnswer: string;
    mtcCode: string;
    mtcDef: string;
    classificationLabel: string;
    materialTypeLabel: string;
    materialTypeCode: string;
    isContainerClosure: boolean;
}

export interface Question {
    parentCode: string;
    parentDef: string;
    children: Array<ChildQuestion>;
    classificationLabel: string;
    materialTypeLabel: string;
    materialTypeCode: string;
    isContainerClosure: boolean;
}

export type MatchCriteria = Array<Question>;

export interface MatchCriteriaAnswer {
    [key: string]: {
        answer: boolean | string | Array<string>;
        isAnswered: boolean;
        classificationLabel: string;
        isContainerClosure: boolean;
    };
}

export interface MaterialCodeMapping {
    [key: string]: string;
}

export interface MatchCriteriaFinalRegulation {
    link: string;
    chtCode: string;
    description: string;
    regMarket: string;
}
export interface MatchCriteriaFinalResult {
    mtcDefinition: string;
    regulations: Array<MatchCriteriaFinalRegulation>;
}

export interface MatchCriteriaFinalResultNew {
    B: {
        mtcDefinition: string;
        regulations: Array<MatchCriteriaFinalRegulation>;
    },
    SM: {
        mtcDefinition: string;
        regulations: Array<MatchCriteriaFinalRegulation>;
    },
    V: {
        mtcDefinition: string;
        regulations: Array<MatchCriteriaFinalRegulation>;
    },
}
export interface HttpClient {
    get: Function;
    post: Function;
}

export interface ColorTag {
    initiallySelected: Array<ProductModality | Site> | boolean;
    unmatched: Array<ProductModality | Site>;
    initialRemoved: Array<ProductModality | Site> | boolean;
}
export interface Site {
    name: string;
    code: string;
    found?: boolean;
    siteFunctions: Array<string>;
    isPDS?: boolean;
}

export interface SiteFunction {
    name: string;
    siteFunctions: Array<string>;
}

export interface ProductMaterialSites {
    products: Array<ProductModality>;
    sites: Array<Site>;
}

export interface ProductModalitySelections {
    biologic: boolean;
    smallMolecule: boolean;
    vaccine: boolean;
    [key: string]: boolean;
}

export interface ProductModality {
    familyName: string;
    genericName: string;
    mkNumber: string;
    additionalInfo: string;
    found: boolean;
    materials?: Array<Material>;
    modality: string;
    productTypeSelection?: string;
}

export interface BasicInfo {
    proposal_id?: string;
    title?: string;
    change_owner?: string;
    change_origin?: string;
    change_driver?: string;
    site_global?: string;
    site_functional_area?: string;
    change_description?: string;
}

export interface SupportingDetails {
    additional_reference?: string;
    change_element: string;
    change_element_detail: string;
    change_element_current: string;
    change_element_future: string;
    rationale: string;
}
export interface AdditionalInfoData {
    additional_considerations?: string;
    additional_considerations_summary?: string;
    product_impact?: string;
    product_impact_summary?: string;
    additional_reference?: string;
}

export interface RelatedChanges {
    proposal_id?: string;
    title?: string;
    change_type?: string;
    status?: string;
    ri_nri?: string;
    assessed_by?: string;
    complete?:string;
}
export interface Endorsements {
    endorsement_decision?: string;
    endorser?: string;
    summary_justification?: string;
}

export interface ProductInformation {
    modalities: Array<Modalities>;
    product_families: Array<ProductFamilies>;
    products: Array<ProductsInfo>;
}
export interface Modalities {
    modality_code: string;
    modality_name: string;
}

export interface ProductFamilies {
    directly_impacted?: boolean;
    manually_added?: boolean;
    product_family_name?: string;
}

export interface ProductsInfo {
    directly_impacted?: boolean;
    manually_added?: boolean;
    product_name: string;
    product_number: string;
    stages: Array<string>;
}

export interface Suppliers {
    vendor_supplier_name?: string;
    supplier_Type?: string;
}

export interface Materials {
    material_number: string;
    material_name: string;
    //type: Array<MaterialType>;
}

export interface Methods {
    method_Number?: string;
    method_Name?: string;
}

export interface Partners {
    partner_name?: string;
    manually_added?: boolean;
    directly_impacted?: boolean;
}

export interface Plants {
    plant_name?: string;
    plant_type?: string;
    manually_added?: boolean;
    directly_impacted?: boolean;
}

export interface RegisteredMarkets {
    country_code?: string;
    country_name?: string;
    manually_added?: boolean;
}

export interface ImpactAnalysis {
    key?: number;
    how_impacted?: string;
    overall_impact?: string;
    summary_justification?: string;
    responsible_resource?: string;
    actions: Array<Actions>;
}

export interface Actions {
    action_title?: string;
    action_description?: string;
}
export interface MaterialClassificationResult {
    status: string;
    message?: string;
    result: CPWSClassification;
}

export interface CPWSClassification {
    batchSize?: number;
    dmfCep?: number;
    inProcessControls?: number;
    process?: number;
    site?: number;
    specificationsAndMethods?: number;
    stability?: number;
    descriptionAndComposition?: number;
    [key: string]: number | undefined;
}

export interface CPWSClassificationCode {
    code: string;
    definition: string;
    description: string;
}

export type CPWSClassificationCodes = Array<CPWSClassificationCode>;
export interface Material {
    number: string;
    name: string;
    stageCode?: string;
    stageName?: string;
    productCode?: string;
    stg_description?: string;
    tradeName?: string;
    type: Array<MaterialType>;
    downstream_mtls?: Array<any>; //legacy field as of 03/04/21
    classification: CPWSClassification;
    downstreamMaterials: Array<ContainerClosureMaterial>;
    [key: string]: any;
}

export interface DownstreamMaterial {
    id: string;
    name: string;
    productCode: string;
    tradeName: string;
}

export interface ContainerClosureMaterial {
    name: string;
    number: string;
    stg_description: string;
    type: Array<MaterialType>;
    availableTypes: Array<MaterialType>;
    [key: string]: any;
    manually_entered: boolean;
}

export interface ExternalMaterial {
    number: string;
    name: string;
    [key: string]: string;
}

export interface SelectedMaterials {
    number: string;
    name: string;
    typeCode: string;
    type: Array<MaterialType>;
    classification: CPWSClassification;
    downstreamMaterials: Array<ContainerClosureMaterial>;
}

export interface MaterialType {
    code: string;
    name: string;
}
// FE no longer relies on hard-coded dictionary below (leaving as a reference)
// If T14/ContainerClosure changes, this must be updated on FE

// export const MaterialTypeDictforDownStreamOnly = {
//     T0: { code: 'T0', name: '' },
//     T1: { code: 'T1', name: 'Drug Substance' },
//     T2: { code: 'T2', name: 'Drug Substance Intermediate' },
//     T3: { code: 'T3', name: 'Regulatory Starting Material' },
//     T4: { code: 'T4', name: 'Raw Materials (DS)' },
//     T5: { code: 'T5', name: 'Solvent (DS)' },
//     T6: { code: 'T6', name: 'Reagent (DS)' },
//     T7: { code: 'T7', name: 'Catalyst (DS)' },
//     T8: { code: 'T8', name: 'Process Aid (DS)' },
//     T9: { code: 'T9', name: 'Excipient (DP)' },
//     T10: { code: 'T10', name: 'Drug Product Intermediate (In-process material)' },
//     T11: { code: 'T11', name: 'Finished Drug Product' },
//     T12: { code: 'T12', name: 'Diluent (DP)' },
//     T13: { code: 'T13', name: 'Medical Device' },
//     T14: { code: 'T14', name: 'Container Closure' },
//     T15: { code: 'T15', name: 'Working Cell Bank' },
//     T16: { code: 'T16', name: 'Working Stock Seed' },
//     T17: { code: 'T17', name: 'Master Seed' },
//     T18: { code: 'T18', name: 'Master Cell Bank' },
//     T19: { code: 'T19', name: 'Reference Standard' },
//     T20: { code: 'T20', name: 'Buffer Media' }
// };

export interface MaterialTypeCount {
    mtl_number: string;
    mtl_types_count: Array<{
        [key: string]: MaterialType;
    }>;
}

export interface ContainerClosureDownstreamMaterial {
    mtl_number: string;
    mtl_type: Array<MaterialType>;
}

export interface LegacyContainerClosureMaterial {
    mtl_name: string;
    mtl_number: string;
    parent: string;
    stg_description: string;
    type: Array<MaterialType>;
}
export interface AdditionalInfo {
    impactedCountries: Array<Country>;
   // downstreamMaterials: Array<DownstreamMaterial>;
    //impactedMaterials: Array<Material>;
}

export interface Matchable<T> {
    selected: Array<T>;
    unmatched: Array<T>;
}
export interface CPWS {
    status: string;
    locked_by_user: any;
    cpws_session_id: any;
    file_method: any;
    cpwsId: string;
    title: string;
    changeIdentifier: string;
    technicalInformation: string;
    additionalInfoComments: string;
    reasonForChange: string;
    changeDescCurrent: string;
    changeDescProposed: string;
    productModality: Matchable<ProductModality>;
    qualityAssessment: Array<string>;
    impactedSites: Matchable<Site>;
    impactedMaterials: Array<Material>;
    linkedChangeReference: string;
    qaQuestion: string | boolean;
    attachments: Array<any>;
    lastAccessedUserFirstName: string;
    lastAccessedUserLastName: string;
    lockedByUserFirstName: string;
    lockedByUserLastName: string;
    basicInfo: BasicInfo;
    supportingDetails: Array<SupportingDetails>;
    additionalInfoData: AdditionalInfoData;
    relatedChanges: Array<RelatedChanges>;
    endorsements: Endorsements;
    productInformation: ProductInformation;
    suppliers: Array<Suppliers>;
    materials: Array<Materials>;
    methods: Array<Methods>;
    partners: Array<Partners>;
    plants: Array<Plants>;
    registeredMarkets: Array<RegisteredMarkets>;
    soldMarkets: Array<RegisteredMarkets>;
    impactAnalysis: Array<ImpactAnalysis>;
}

export interface CPWSAssessment {
    cpwsID: string;
    cpws_title: string;
    quality_impact: string;
    mtl_list: Array<string>;
    ds_dp_list?: Array<string>;
    sterile_non_sterile?: Array<string>;
    cpws_fam_list: Array<string>;
    cpws_sit_list: Array<string>;
    pds_impacted_sites: Array<{
        sit_name: string;
        sit_functions: Array<string>;
    }>;
    materials_with_material_types: Array<{
        mtl_number: string;
        types: string[];
    }>;
    cpws_classifications: Array<{
        impacted_material_type: Array<string>;
        [key: string]: number | Array<string> | undefined;
    }>;
    regulatoryQuestions: Array<RegulatoryQuestions>;
    justification?: string;
    modality_list?: Array<string>;
}

export interface RegulatoryQuestionValue {
    isAnswered: boolean;
    answer: boolean;
    children?: {
        [key: string]: RegulatoryQuestionValue;
    };
}

export interface RegulatoryQuestions {
    [key: string]: RegulatoryQuestionValue;
}

export interface ApiResponseStatus {
    status: number;
}

export interface FormValidationFields {
    regulatoryQuestionsAnswered: boolean;
    productsSelected: boolean;
    sitesSelected: boolean;
    siteFunctionsSelected: boolean;
    inScopeMarketNotEmpty: boolean;
    justificationProvided: boolean;
    cpwsQuestionsAnswered: boolean;
    materialSelected: boolean;
    impactedMaterialsMissing: boolean;
    impactedMaterialsMissingWithYes: boolean;
    downstreamMaterialTypeSelected: boolean,
    downstreamMaterialType: boolean,
    formExternalMaterialsMissing: boolean,
    materialTypeSelected: boolean;
    qualitySelected: boolean;
    classificationSelected: boolean;
    matchCriteriaAnswered: boolean;
    finalAssessmentAnswered: boolean;
    viewOnly: boolean;
}

export interface ErrorBoundaryFields {
    sessionID: string;
    cpwsID: string;
}

export interface FormRefs {
    regulatoryQuestionsAnswered: React.RefObject<HTMLDivElement>;
    productsSelected: React.RefObject<HTMLDivElement>;
    sitesSelected: React.RefObject<HTMLDivElement>;
    justificationProvided: React.RefObject<HTMLDivElement>;
    cpwsQuestionsAnswered: React.RefObject<HTMLDivElement>;
    materialSelected: React.RefObject<HTMLDivElement>;
    qualitySelected: React.RefObject<HTMLDivElement>;
    materialTypeSelected: React.RefObject<HTMLDivElement>;
    siteFunctions: Array<{
        name: string;
        ref: React.RefObject<HTMLDivElement>;
    }>;
    impactedMaterials: ImpactedMaterialsRef;
    siteFunctionCardRef: React.RefObject<HTMLDivElement>;
    matchCriteriaCardRef: React.RefObject<HTMLDivElement>;
    containerClosureCardRef: React.RefObject<HTMLDivElement>;
    finalAssessmentRef: React.RefObject<HTMLDivElement>;
    downstreamMaterialTypeSelected: React.RefObject<HTMLDivElement>;
    downstreamMaterialType: React.RefObject<HTMLDivElement>;
    formExternalMaterialsMissing: React.RefObject<HTMLDivElement>;
}

export interface SelectionOption {
    key: string;
    value: string;
    children: string;
}

export type ImpactedMaterialsRef = Array<{
    number: string;
    ref: React.RefObject<HTMLDivElement>;
    downstreamRefs: Array<{
        number: string;
        ref: React.RefObject<HTMLDivElement>;
    }>;
}>;

export interface ScrollToImpactedMaterial {
    ref: React.RefObject<HTMLDivElement> | undefined;
    page: number;
    type: string;
    matNumber: string;
    needsExpansion: boolean;
}

export interface ScrollToDownstreamMaterial {
    ref: React.RefObject<HTMLDivElement> | undefined;
    page: number;
    type: string;
    imtPage: number;
    matNumber: string;
}
export interface AssesmentMarkets {
    priorApproval: Array<string>;
    notification: Array<string>;
    nri: Array<string>;
    incomplete: Array<string>;
    total: Array<string>;
}

export interface DataRquirmentSection {
    batch_analysis: [],
    bioequivalence_study: [],
    comparative_dissolution: [],
    dp_intermediate_stability: [],
    dp_stability: [],
    ds_intermediate_stability: [],
    ds_stability: [],
    product_packaging_interaction: [],
    transportation_storage_conditions: [],
    validation: [],
    change_types_without_data_requirements: Array<string>,
    questions_without_data_requirements: Array<string>
}
export interface DirectionalAssesmentResult {
    //finalResults: Array<AssessmentResult>;
    marketLevelAssessment: {
        B: Array<AssessmentResult>,
        SM: Array<AssessmentResult>,
        V: Array<AssessmentResult>
    },
    directionalAssesment: {
        B: {
            majorMarkets: AssesmentMarkets;
            secondaryMarkets: AssesmentMarkets;
        },
        SM: {
            majorMarkets: AssesmentMarkets;
            secondaryMarkets: AssesmentMarkets;
        },
        V: {
            majorMarkets: AssesmentMarkets;
            secondaryMarkets: AssesmentMarkets;
        }
    };
    dataRequirements: {
        B: DataRquirmentSection,
        SM: DataRquirmentSection,
        V: DataRquirmentSection
    },
    system_nri_ri: boolean,
    assessments: [],
    status: string
}

export interface CommentDetails {
    screen1: string;
    screen2: string;
    screen3: string;
    screen4: string;
    screen5: string;
}
export interface DirectAssessment {
    B: {
        majorMarkets: AssesmentMarkets;
        secondaryMarkets: AssesmentMarkets;
    },
    SM: {
        majorMarkets: AssesmentMarkets;
        secondaryMarkets: AssesmentMarkets;
    },
    V: {
        majorMarkets: AssesmentMarkets;
        secondaryMarkets: AssesmentMarkets;
    }
}
export interface DataRequirements {
    B: DataRquirmentSection,
    SM: DataRquirmentSection,
    V: DataRquirmentSection
}

export interface MarketLevelAssessments {   
    B: Array<AssessmentResult>,
    SM: Array<AssessmentResult>,
    V: Array<AssessmentResult>
}

export type FinalAssessmentKeys =
    | 'userAssessment'
    | 'userAssessmentRationale'
    | 'additionalNotes'
    | 'cakeSuggestions'
    | 'nriOption'
    | 'rationaleAssessment'
    | 'reviewer';
export class CPWSBuilder implements CPWS {
    status: any;
    locked_by_user: any;
    cpws_session_id: any;
    file_method: any;
    cpwsId: string;
    title: string;
    changeIdentifier: string;
    technicalInformation: string;
    additionalInfoComments: string;
    reasonForChange: string;
    changeDescCurrent: string;
    changeDescProposed: string;
    productModality: Matchable<ProductModality>;
    qualityAssessment: Array<string>;
    impactedSites: Matchable<Site>;
    impactedMaterials: Array<Material> = [];
    linkedChangeReference: string;
    qaQuestion: boolean;
    attachments: Array<any>;
    lastAccessedUserFirstName: string;
    lastAccessedUserLastName: string;
    lockedByUserFirstName: string;
    lockedByUserLastName: string;
    basicInfo:BasicInfo;
    supportingDetails: Array<SupportingDetails>;
    additionalInfoData: AdditionalInfoData;
    relatedChanges: Array<RelatedChanges>;
    endorsements: Endorsements;
    productInformation: ProductInformation;
    suppliers: Array<Suppliers>;
    materials: Array<Materials>;
    methods: Array<Methods>;
    partners: Array<Partners>;
    plants: Array<Plants>;
    registeredMarkets: Array<RegisteredMarkets>;
    soldMarkets: Array<RegisteredMarkets>;
    impactAnalysis: Array<ImpactAnalysis>;
    constructor(
        status?: any,
        locked_by_user?: any,
        cpws_session_id?: any,
        file_method?: any,
        cpwsId?: string,
        title?: string,
        changeIdentifier?: string,
        technicalInformation?: string,
        additionalInfoComments?: string,
        reasonForChange?: string,
        changeDescCurrent?: string,
        changeDescProposed?: string,
        qualityAssessment?: string[],
        impactedMaterials?: Array<Material>,
        linkedChangeReference?: string,
        productModality?: Matchable<ProductModality>,
        impactedSites?: Matchable<Site>,
        qaQuestion?: any,
        attachments?: Array<any>,
        lastAccessedUserFirstName?: string,
        lastAccessedUserLastName?: string,
        lockedByUserFirstName?: string,
        lockedByUserLastName?: string,
        basicInfo?:BasicInfo,
        supportingDetails?: Array<SupportingDetails>,
        additionalInfoData?: AdditionalInfoData,
        relatedChanges?: Array<RelatedChanges>,
        endorsements?: Endorsements,
        productInformation?: ProductInformation,
        suppliers?: Array<Suppliers>,
        materials?: Array<Materials>,
        methods?: Array<Methods>,
        partners?: Array<Partners>,
        plants?: Array<Plants>,
        registeredMarkets?: Array<RegisteredMarkets>,
        soldMarkets?: Array<RegisteredMarkets>,
        impactAnalysis?: Array<ImpactAnalysis>,
    ) {
        this.status = status || '';
        this.locked_by_user = locked_by_user || '';
        this.cpws_session_id = cpws_session_id || '';
        this.file_method = file_method || '';
        this.cpwsId = cpwsId || '0';
        this.title = title || '';
        this.changeIdentifier = changeIdentifier || '';
        this.technicalInformation = technicalInformation || '';
        this.additionalInfoComments = additionalInfoComments || '';
        this.reasonForChange = reasonForChange || '';
        this.changeDescCurrent = changeDescCurrent || '';
        this.changeDescProposed = changeDescProposed || '';
        this.qualityAssessment = qualityAssessment || [];
        this.impactedMaterials = impactedMaterials || [];
        this.linkedChangeReference = linkedChangeReference || '';
        this.productModality = productModality || { selected: [], unmatched: [] };
        this.impactedSites = impactedSites || { selected: [], unmatched: [] };
        this.qaQuestion = qaQuestion || '';
        this.attachments = attachments || [];
        this.lastAccessedUserFirstName = lastAccessedUserFirstName || '';
        this.lastAccessedUserLastName =  lastAccessedUserLastName || '';
        this.lockedByUserFirstName = lockedByUserFirstName || '';
        this.lockedByUserLastName = lockedByUserLastName || '';
        this.basicInfo = basicInfo || {};
        this.supportingDetails = supportingDetails || [];
        this.additionalInfoData = additionalInfoData || {};
        this.relatedChanges = relatedChanges || [];
        this.endorsements = endorsements || {};
        this.productInformation = productInformation || {modalities: [], product_families: [], products: []};
        this.suppliers = suppliers || [];
        this.materials = materials || [];
        this.methods = methods || [];
        this.partners = partners || [];
        this.plants = plants || [];
        this.registeredMarkets = registeredMarkets || [];
        this.soldMarkets = soldMarkets || [];
        this.impactAnalysis = impactAnalysis || [];
    }
}
 