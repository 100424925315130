import { Card, Collapse, Radio, Row, Col, Typography, Input, Select, Table, Cascader, Button } from 'antd';
import React, { Children, useEffect } from 'react';
import { PanelProps } from '../model';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from '../components';
import { isEmpty, uniqueId } from 'lodash';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { CPWS,MatchCriteria } from '../model';
import ApiClient from '../api/apiClient';
import PlusCircleOutlined  from '@ant-design/icons/PlusCircleOutlined';

const uuid = require('uuid');

const { Text } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

export interface FinalAssessmentQuestions {
    panelProps: PanelProps;
    isRegulatoryImpact: boolean;
    user_nri_ri: string;
    biologic_nri_ri: string;
    vaccine_nri_ri: string;
    smallmolecule_nri_ri: string;
    scope_change_question: string;
    screen4_update: boolean;
    setScreen4Update: Function;
    userAssessmentRationale: string;
    additionalNotes: string;
    cakeSuggestions: string;
    nriOption: string;
    rationaleText: string;
    updateFinalAssessmentField: Function;
    clearFinalAssessmentField: Function;
    printPDF: boolean;
    finalAssessmentRef: React.RefObject<HTMLDivElement>;
    reviewer: string;
    api: ApiClient,
    cpws: CPWS,
    savePratInfo: Function;
    savesubmission_req_details: Function;
    savePlanningAssessmentType: Function;
    saveDirectionalAssessment: Function;
    currentDirectionalAssessmentReason: string;
    currentPlanningAssessmentType: string;
    currentPratInfo: object;
    current_submission_req_info: any;
    viewOnly: boolean;
    prat: object;
    system_nri_ri: boolean;
    productModalitiesSelections: any;
    directionalDetails: string;
    matchCriteria:MatchCriteria;
}

const assessmentRationaleOptions = [
    {
        value: 'No reporting requirement',
    },
    {
        value: 'Change is within defined validation ranges',
    },
    {
        value: 'Change is within defined specifications for market (release/shelf-life)',
    },
    {
        value: 'Change is within defined process conditions/controls for market',
    },
    {
        value: 'Change is within prescribed performance characteristics for product (no impact on bioavailability/bioequivalency)',
    },
    {
        value: 'Change is subject of an initial product registration to be included in the original application',
    },
    {
        value: 'Change is the subject of a clinical product not yet commercialized; assessment to be completed in the development change control system',
    },
    {
        value: 'Change is a secondary change that is required to support a primary change; in and of itself does not require regulatory reporting',
    },
    {
        value: 'Other',
    },
];

export const FinalAssessmentQuestions: React.FC<FinalAssessmentQuestions> = (props: FinalAssessmentQuestions) => {
    const {
        panelProps,
        isRegulatoryImpact,
        user_nri_ri,
        biologic_nri_ri,
        vaccine_nri_ri,
        smallmolecule_nri_ri,
        scope_change_question,
        screen4_update,
        setScreen4Update,
        userAssessmentRationale,
        additionalNotes,
        cakeSuggestions,
        nriOption,
        rationaleText,
        updateFinalAssessmentField,
        clearFinalAssessmentField,
        printPDF,
        finalAssessmentRef,
        reviewer,
        api,
        cpws,
        savePratInfo,
        savesubmission_req_details,
        savePlanningAssessmentType,
        saveDirectionalAssessment,
        prat,
        system_nri_ri,
        productModalitiesSelections,
        directionalDetails,
        matchCriteria
    } = props;

    const [rationale, setRationale] = React.useState<string>('');
    const [detectUserNri, setDetectUserNri] = React.useState<string>('');
    const [userAssessmentText, setUserAssessmentText] = React.useState<string>('');
    const [additionalNotesText, setAdditionalNotesText] = React.useState<string>('');
    const [suggestions, setSuggestions] = React.useState<string>('');
    const [reviewerText, setReviewerText] = React.useState<string>('');
    const [detailsText, setDetailsText] = React.useState<string>('');
    const [submissionRequirements, setSubmissionRequirements] = React.useState<string>('');
    const [planningAssessmentType, updatePlanningAssessmentType] = React.useState<string>('');
    const [directionalAssessment, updateDirectionalAssessment] = React.useState<string>('');
    const [productAgnosticValue, setProductAgnosticValue] = React.useState<string>('');
    const [pratOptions, setPratOptions] = React.useState<Array<Object>>([]);
    const [pratType, setPratType] = React.useState<any>({});
    const [productOrMaterialTypeOptions, setProductOrMaterialTypeOptions] = React.useState<any>([]);
    const [submissionReqs, setSubmissionReqs] = React.useState<any>({});
    const [submissionRequirementsData, setSubmissionRequirementsData] = React.useState<Array<any>>([]);
    const [productOrMaterialTypeValueById, setProductOrMaterialTypeValueById] = React.useState<any>({});
    const [submissionReqOptionsForType, setSubmissionReqOptionsForType] = React.useState<any>({});
    const [submissionRequirementValueById, setSubmissionRequirementValueById] = React.useState<any>({});
    const [commentById, setCommentById] = React.useState<any>({});
    const [modalityById, setModalityById] = React.useState<any>({});
    const [vaccineModality, setVaccineModality] = React.useState<boolean>(false);
    const [biologicModality, setBiologicModality] = React.useState<boolean>(false);
    const [smallMoleculeModality, setSmallMoleculeModality] = React.useState<boolean>(false);
    const [modalityOptions, setModalityOptions] = React.useState<any>([]);

    const modalities: any = [];

    useEffect(() => {       
        
            const loadModalityInfo = () => {
                setSubmissionRequirementsData(props.current_submission_req_info);
                setPratType(props.currentPratInfo)

                const productValueById = {
                    ...productOrMaterialTypeValueById
                }

                const submissionReqValueById = {
                    ...submissionRequirementValueById
                }

                const comById = {
                    ...commentById
                }

                const modById = {
                    ...modalityById
                }

                if(props.current_submission_req_info !== undefined && props.current_submission_req_info.length > 0) {
                    for (const obj of props.current_submission_req_info) {
                        let tmpModal = [];
                        for(let i=0; i< obj.modality.length; i++) {
                            let tmp = obj.modality[i] === 'B' ? 'Biologic' : obj.modality[i] === 'SM' ? "Small Molecule" : "Vaccine";
                            tmpModal.push(tmp);
                        }
                        modById[obj.id] = tmpModal;                        
                        setModalityById(modById);
                        
                        productValueById[obj.id] = obj.product;
                        setProductOrMaterialTypeValueById(productValueById);

                        submissionReqValueById[obj.id] = obj.sub_req;
                        setSubmissionRequirementValueById(submissionReqValueById);

                        comById[obj.id] = obj.comment;
                        setCommentById(comById);
                    }
                }
            }

            if (props.currentPlanningAssessmentType !== '') {
                updatePlanningAssessmentType(props.currentPlanningAssessmentType);
            }

            if (props.currentDirectionalAssessmentReason !== '') {
                updateDirectionalAssessment(props.currentDirectionalAssessmentReason);
            }

            loadModalityInfo();

            if (props.prat) {
                setPratType(props.prat);
            }

            const getModalities = async () => {
                const tmpModalities: any = [];
                const allModalities: any = props.productModalitiesSelections;
                const allNamedModalities: any = [];

                for (const modality in allModalities) {
                    if (allModalities[modality] === true) {
                        if (modality === 'smallMolecule') {
                            tmpModalities.push('SM');
                            allNamedModalities.push({
                                value: 'Small Molecule'
                            });
                            setSmallMoleculeModality(true);
                        } else if (modality === 'vaccine') {
                            tmpModalities.push('V');
                            allNamedModalities.push({
                                value: 'Vaccine'
                            });
                            setVaccineModality(true);
                        } else if (modality === 'biologic') {
                            tmpModalities.push('B');
                            allNamedModalities.push({
                                value: 'Biologic'
                            });
                            setBiologicModality(true);
                        }
                    }
                }

                setModalityOptions(allNamedModalities);
                /*const pratModalities:any = await props.api.getFinalAssesmentAdditionalInputs(
                    tmpModalities
                );*/

                const pratModalities: any = await props.api.getFinalAssesmentAdditionalInputs(
                    tmpModalities
                );

                if (Object.keys(pratModalities).length > 0) {
                    const prat = pratModalities['prat'];
                     //console.log("prat:", prat);           
                    const getSubmissionReqs = pratModalities['submission_req'];

                    if (getSubmissionReqs !== null && getSubmissionReqs !== undefined) {

                        const submissionReqOptions: any = Object.keys(getSubmissionReqs);
                        setSubmissionReqs(getSubmissionReqs);
                        
                        for (const option of submissionReqOptions) {
                            const optionArr = getSubmissionReqs[option];
                            submissionReqOptionsForType[option] = [];
                            for (const subOption of optionArr) {
                                submissionReqOptionsForType[option].push({
                                    value: subOption
                                })
                            }
                        }
                        
                        setSubmissionReqOptionsForType(submissionReqOptionsForType);
            
                        const getProductOrMaterialTypeOptions = [];
            
                        for(const option of submissionReqOptions) {
                            getProductOrMaterialTypeOptions.push({
                                value: option
                            })
                        }
            
                        setProductOrMaterialTypeOptions(getProductOrMaterialTypeOptions);

                    }

                    const options = [];

                    if (prat) {
                        const keys = Object.keys(prat);

                        for (const key of keys) {
                            let updatedKey = '';
                            if (key === 'V') {
                                updatedKey = 'Vaccine';
                            } else if (key === 'SM') {
                                updatedKey = 'Small Molecule';
                            } else if (key === 'B') {
                                updatedKey = 'Biologic';
                            }

                            const chlidren = [];
                            const childArray = prat[key];
                            for(const child of childArray) {
                                chlidren.push({
                                    value: `${[Object.keys(child)[0]]+'#'+Object.values(child)[0]}`,
                                    label: Object.keys(child)[0],
                                    children: []
                                })
                            }
                            //console.log("Children:", chlidren);

                            options.push({
                                value: key,
                                label: updatedKey,
                                children: chlidren
                            })
                        }
                    }
                    //console.log("Options:", options);

                    setPratOptions(options);
                }

            }

            getModalities();           
        
    }, [])

    const setCommentValue = (event: any, record: any) => {
        const value = event.target.value;

        const findIndex = submissionRequirementsData.findIndex((x: any) => record.id === x.id);
        if (findIndex > -1) {
            submissionRequirementsData[findIndex]['comment'] = value;
            setSubmissionRequirementsData(submissionRequirementsData);
            updateSubmissionRequirements();
        }
    }

    const renderCommentColumn = (__: any, record: any) =>
        <div>
            {!props.viewOnly ?
                <input defaultValue={commentById[record.id]} placeholder='Add comment here' onChange={(event) => setCommentValue(event, record)}></input> 
                :
                <div>
                    {commentById[record.id]}
                </div>
            }
        </div>
        
    const deleteRow = (event: any, record: any) => {
        const data = [...submissionRequirementsData];
        const findIndex = data.findIndex((x: any) => x.id === record.id);
        if (findIndex > -1) {
            data.splice(findIndex, 1);
            setSubmissionRequirementsData(data);
            savesubmission_req_details(data);
        }
    }
        
    const renderDeleteColumn = (__: any, record: any) =>
        <Button disabled={props.viewOnly} onClick={(event) => deleteRow(event, record)}>
            <DeleteOutlined />
        </Button>

    const setProductOrMaterialTypeValue = (value: any, record: any) => {
        const findIndex = submissionRequirementsData.findIndex((x: any) => x.id === record.id);
        if (findIndex > -1) {
            const data = [...submissionRequirementsData];
            data[findIndex]['product'] = value;

            const valueById = {
                ...productOrMaterialTypeValueById
            }

            valueById[record.id] = value;
            setProductOrMaterialTypeValueById(valueById);

            updateSubmissionRequirements();
        }
    }

    const setSubmissionRequirementValue = (value: any, record: any) => {
        const findIndex = submissionRequirementsData.findIndex((x: any) => x.id === record.id);
        if (findIndex > -1) {
            submissionRequirementsData[findIndex]['sub_req'] = value;
            submissionRequirementValueById[record.id] = value;
            setSubmissionRequirementValueById(submissionRequirementValueById);
            updateSubmissionRequirements();
        }
    }

    const renderProductOrMaterialType = (__: any, record: any) =>
        <div>
            {!props.viewOnly ?
                <Select
                    options={productOrMaterialTypeOptions}
                    placeholder={'Select an option'}
                    value={productOrMaterialTypeValueById[record.id]}
                    onChange={(value: any) =>
                        setProductOrMaterialTypeValue(value, record)
                    }
                    style={{ width: '100%' }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: '500px' }}
                />
                :
                <div>
                    {productOrMaterialTypeValueById[record.id]}
                </div>
            }
        </div>

    const setSubmissionRequirementModality = (event: any, record: any) => {    
        let modalities = [];
        for (const modality of event) {
            if (modality === 'Biologic') {
                modalities.push('B');
            } else if (modality === 'Small Molecule') {
                modalities.push('SM');
            } else if (modality === 'Vaccine') {
                modalities.push('V');
            }
        }
        const findIndex = submissionRequirementsData.findIndex((x: any) => record.id === x.id);
        if (findIndex > -1) {
            submissionRequirementsData[findIndex]['modality'] = modalities;
            setSubmissionRequirementsData(submissionRequirementsData);
            updateSubmissionRequirements();
        }

    }

    const renderModalitySelection = (__: any, record: any) => 
        <div>
            <Select
                options={modalityOptions}
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={modalityById[record.id]}
                onChange={(event) => setSubmissionRequirementModality(event, record)}
            >
            </Select>
        </div>

    const renderSubmissionRequirements = (__: any, record: any) =>
        <div>
            {!props.viewOnly && Object.keys(submissionReqs).length > 0 && Object.keys(submissionReqOptionsForType).length > 0 ?
                <Select
                    options={submissionReqOptionsForType[productOrMaterialTypeValueById[record.id]]}
                    placeholder={'Select an option'}
                    value={submissionRequirementValueById[record.id]}
                    onChange={value =>
                        setSubmissionRequirementValue(value, record)
                    }
                    style={{ width: '100%' }}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: '500px' }}
                />
                :
                <div>
                    {submissionRequirementValueById[record.id]}
                </div>
            }
            <div></div>
        </div>

    const updateSubmissionRequirements = () => {
        savesubmission_req_details(submissionRequirementsData);
    }

    const submissionRequirementsColumns: any =
    [
        {
            title: 'Modality',
            dataIndex: 'modality',
            render: renderModalitySelection
        },
        {
            title: 'Product/Material Type',
            dataIndex: 'type',
            render: renderProductOrMaterialType,
        },
        {
            title: 'Submission Requirement',
            dataIndex: 'requirement',
            render: renderSubmissionRequirements,
            width: '250px',
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            render: renderCommentColumn
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            render: renderDeleteColumn
        }
    ]

    const addRow = () => {
        const data = [...submissionRequirementsData];
        data.push({
            modality: [],
            type: 'material_type',
            requirement: 'requirement',
            id: uuid.v4()
        })
        setSubmissionRequirementsData(data);
    }

    const displayUserAssessmentRationale = (system_nri_ri: boolean, user_nri_ri: string) => {
        return (system_nri_ri && user_nri_ri === 'FALSE') || (!system_nri_ri && user_nri_ri === 'TRUE')
            ? true
            : false;
    };

    const setPratValues = (event:any) => {
        if(event.length>0){
        const modality = event[0];
        const splitPratValues = event[1].split("#");
        const splitPratValues1 = splitPratValues[0];
        const splitPratValues2 = splitPratValues[1];
        const value = { [splitPratValues1]: splitPratValues2 };

        const prat = {
            ...pratType
        }

        prat[modality] = value;
        setPratType(prat);
        savePratInfo(prat);
          }
          else{
           setPratType({});
           savePratInfo({});
          }
    }


    const setPlanningAssessmentType = (value: any) => {
        updatePlanningAssessmentType(value);
        savePlanningAssessmentType(value);
    }

    const setDirectionalAssessment = (value: any) => {
        updateDirectionalAssessment(value);
        saveDirectionalAssessment(value);
    }

    const removePrat = (key: any) => {
        const updatedPrat = {
            ...pratType
        };

        delete updatedPrat[key];
        setPratType(updatedPrat);
        savePratInfo(updatedPrat);
    }   

    React.useEffect(() => {
        setRationale(rationaleText);
        setUserAssessmentText(userAssessmentRationale);
        setDetailsText(directionalDetails);
        setAdditionalNotesText(additionalNotes);
        setSuggestions(cakeSuggestions);
        setReviewerText(reviewer);
        if (screen4_update === true || matchCriteria.length === 0) {            
            clearFinalAssessmentField(true);
            setRationale('');
            setUserAssessmentText('');
            setAdditionalNotesText('');
            setSuggestions('');
            setReviewerText('');
            setDetailsText('');
            setDirectionalAssessment('');
            setPlanningAssessmentType('');
            setSubmissionRequirementsData([]);
            setPratType({});
            savePratInfo({});
            savesubmission_req_details([]);
            setScreen4Update(false);
            //console.log('propsAssignmentType', props.currentPlanningAssessmentType);
        }
    }, []);

    React.useEffect(() => {
        if(detectUserNri === 'TRUE' || detectUserNri === 'FALSE'){
            clearFinalAssessmentField(false);          
            setRationale('');
            setUserAssessmentText('');
            setAdditionalNotesText('');
            setSuggestions('');
            setReviewerText('');
            setDetailsText('');
            setDirectionalAssessment('');
            setPlanningAssessmentType('');
            setSubmissionRequirementsData([]);
            setPratType({});
            savePratInfo({});
            savesubmission_req_details([]);
        } 
    }, [detectUserNri]);


    return (
        <Card bordered={false}>
            <Collapse {...panelProps} defaultActiveKey={['concur']}>
                <Panel header="Final Assessment" key="concur">
                    <FormContext.Consumer>
                        {({ finalAssessmentAnswered, viewOnly }) => (
                            <div className={viewOnly ? 'view-only' : ''}>
                                <Row
                                    gutter={4}
                                    className={
                                        !finalAssessmentAnswered && user_nri_ri === ''
                                            ? 'panel-border panel-border-error'
                                            : ''
                                    }
                                    ref={finalAssessmentRef}
                                    align={'middle'}
                                >
                                    {!finalAssessmentAnswered && user_nri_ri === '' ? (
                                        <Col span={24} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
                                            <FormValidationErrorText text="Please answer the question" />
                                        </Col>
                                    ) : null}
                                    <Col span={10}>
                                        <Text strong>
                                            CMC Manager Regulatory Assessment (RI/NRI):
                                            <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>{' '}
                                        </Text>
                                    </Col>
                                    <Col span={14} style={{ alignContent: 'center', padding: '16px' }}>
                                        <Radio.Group
                                            onChange={e => {setDetectUserNri(e.target.value),updateFinalAssessmentField(e.target.value, 'user_nri_ri')}}
                                            value={user_nri_ri}
                                        >
                                            <Radio value={'TRUE'}>RI</Radio>
                                            <Radio value={'FALSE'}>NRI</Radio>
                                        </Radio.Group>
                                    </Col>
                                    {user_nri_ri === 'TRUE' && smallMoleculeModality === true && modalityOptions.length > 1 &&
                                        <Col span={24} style={{paddingBottom: '.5em'}}>
                                            <Text strong>
                                                Small Molecule (RI/NRI):
                                                <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>{' '}
                                            </Text>
                                            <Radio.Group
                                                onChange={e => updateFinalAssessmentField(e.target.value, 'smallmolecule_nri_ri')}
                                                value={smallmolecule_nri_ri}
                                                style={{paddingLeft: '29%'}}
                                            >
                                                <Radio value={'TRUE'}>RI</Radio>
                                                <Radio value={'FALSE'}>NRI</Radio>
                                            </Radio.Group>
                                        </Col>
                                    }
                                    {user_nri_ri === 'TRUE' && biologicModality === true && modalityOptions.length > 1 &&
                                        <Col span={24} style={{paddingBottom: '.5em'}}>
                                            <Text strong>
                                                Biologic (RI/NRI):
                                                <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>{' '}
                                            </Text>
                                            <Radio.Group
                                                onChange={e => updateFinalAssessmentField(e.target.value, 'biologic_nri_ri')}
                                                value={biologic_nri_ri}
                                                style={{paddingLeft: '33%'}}
                                            >
                                                <Radio value={'TRUE'}>RI</Radio>
                                                <Radio value={'FALSE'}>NRI</Radio>
                                            </Radio.Group>
                                        </Col>
                                    }
                                    {user_nri_ri === 'TRUE' && vaccineModality === true && modalityOptions.length > 1 &&
                                        <Col span={24} style={{paddingBottom: '2em'}}>
                                            <Text strong>
                                                Vaccine (RI/NRI):
                                                <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>{' '}
                                            </Text>
                                            <Radio.Group
                                                onChange={e => updateFinalAssessmentField(e.target.value, 'vaccine_nri_ri')}
                                                value={vaccine_nri_ri}
                                                style={{paddingLeft: '33.25%'}}
                                            >
                                                <Radio value={'TRUE'}>RI</Radio>
                                                <Radio value={'FALSE'}>NRI</Radio>
                                            </Radio.Group>
                                        </Col>
                                    }
                                </Row>
                                {(system_nri_ri && user_nri_ri === 'FALSE') ? (
                                    <Row gutter={[0, 8]} style={{ paddingTop: '8px' }}>
                                        <Col span={10}>
                                            <Text strong>
                                            Is the scope of the change limited to ONLY non-commercial and/or clinical products?
                                                <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>
                                            </Text>
                                        </Col>
                                        <Col span={14}>
                                            <Radio.Group
                                                    onChange={e => updateFinalAssessmentField(e.target.value, 'scope_change_question')}
                                                    value={scope_change_question}
                                                    style={{paddingLeft: '16px'}}
                                                >
                                                    <Radio value={'TRUE'}>Yes</Radio>
                                                    <Radio value={'FALSE'}>No</Radio>
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                ) : null}
                                {displayUserAssessmentRationale(system_nri_ri, user_nri_ri) ? (
                                    <Row gutter={[0, 8]} style={{ paddingTop: '8px' }}>
                                        <Col span={24}>
                                            <Text strong>
                                                CMC scientist assessment and CAKE assessment are different,
                                                please provide information or reasoning:
                                                <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>
                                            </Text>
                                        </Col>
                                        <Col span={24}>
                                            {!printPDF && !viewOnly ? (
                                                <>
                                                    {!finalAssessmentAnswered && isEmpty(userAssessmentRationale) ? (
                                                        <Col span={24}>
                                                            <FormValidationErrorText text="Please enter an assessment rationale" />
                                                        </Col>
                                                    ) : null}
                                                    <TextArea
                                                        id={'userAssessment'}
                                                        rows={4}
                                                        onChange={e => setUserAssessmentText(e.target.value)}
                                                        value={userAssessmentText}
                                                        className={
                                                            isEmpty(userAssessmentRationale) && !finalAssessmentAnswered
                                                                ? 'select-highlight'
                                                                : ''
                                                        }
                                                        onBlur={() =>
                                                            updateFinalAssessmentField(
                                                                userAssessmentText,
                                                                'userAssessmentRationale',
                                                            )
                                                        }
                                                        spellCheck={true}
                                                    />
                                                </>
                                            ) : (
                                                <div
                                                    className="div-print"
                                                    style={{ border: '1px solid  #f0f0f0', padding: '8px' }}
                                                >
                                                    {userAssessmentText}
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                ) : null}
                                {user_nri_ri === 'TRUE' &&
                                    <Row gutter={[0, 8]} style={{ paddingTop: '8px' }} align={'middle'}>
                                        <Col span={12} style={{ paddingRight: '8px' }}>
                                            <Text strong>
                                                Planning Assessment Type:
                                                <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>
                                            </Text>
                                        </Col>
                                        <span>   </span>
                                        <Col span={12} style={{ paddingRight: '8px' }}>
                                            <Select
                                                options={[{value: 'Directional'}, {value: 'Product Agnostic'}, {value: 'Final Global Regulatory Assessment'}]}
                                                placeholder={'Select an option'}
                                                value={planningAssessmentType}
                                                disabled={viewOnly}
                                                onChange={value =>
                                                    setPlanningAssessmentType(value)
                                                }
                                                style={{ width: '100%' }}
                                                dropdownMatchSelectWidth={false}
                                                dropdownStyle={{ width: '500px' }}
                                                className={
                                                    !finalAssessmentAnswered && nriOption === ''
                                                        ? 'select-highlight'
                                                        : ''
                                                }
                                            />
                                        </Col>
                                        <br></br>
                                    </Row>
                                }
                                {user_nri_ri === 'TRUE' && planningAssessmentType === 'Product Agnostic' ?
                                    <Row gutter={[0, 8]} style={{ paddingTop: '2em' }} align={'middle'}>
                                        <Col span={12} style={{ paddingRight: '8px' }}>
                                            <Text strong>
                                                Product Agnostic:
                                                <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>
                                            </Text>
                                        </Col>
                                        <Col span={12} style={{ paddingRight: '8px' }}>
                                            <span> </span>
                                            {!viewOnly ? 
                                                <Cascader style={{width: '100%'}} showSearch options={pratOptions} onChange={setPratValues} allowClear={true} placeholder="Please select" />
                                            : 
                                            <Cascader style={{width: '100%'}} disabled={viewOnly} options={pratOptions} onChange={setPratValues} allowClear={false} placeholder="Please select" />
                                            }
                                        </Col>
                                    </Row>
                                : null }
                                {user_nri_ri === 'TRUE' && planningAssessmentType === 'Product Agnostic' && Object.keys(pratType).length > 0 ?
                                    <Row gutter={[0, 8]} style={{ paddingTop: '8px' }}>
                                        {Object.keys(pratType).map((key) => 
                                            <div style={{position: 'relative', width: '100%'}}>
                                                <Row className='mb-3'>
                                                    <Col span={12} className='pr-4'>
                                                        {key === 'SM' ? 'Small Molecule : ' : key === 'B' ? 'Biologic : ' : 'Vaccine : '}                                                     
                                                        <a href={Object.values(pratType[key])[0] as string} target="_blank"> {Object.keys(pratType[key])[0]}</a>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Button className='mr-2' onClick={() => removePrat(key)}><DeleteOutlined/></Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </Row>
                                : null }
                                {user_nri_ri === 'TRUE' && planningAssessmentType === 'Directional' ?
                                    <Row gutter={[0, 8]} style={{ paddingTop: '2em' }} align={'middle'}>
                                        <Col span={12} style={{ paddingRight: '8px' }}>
                                            <Text strong>
                                                Directional:
                                                <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>
                                            </Text>
                                        </Col>
                                        <Col span={12} style={{ paddingRight: '8px' }}>
                                            <Select
                                                style={{width: '100%'}}
                                                disabled={viewOnly}
                                                showSearch
                                                allowClear
                                                options={[
                                                    {value: 'Prior approval most markets'},
                                                    {value: 'Notification most markets'},
                                                    {value: 'Notification major markets prior approval secondary markets'},
                                                    {value: 'Prior approval major markets, notification in secondary markets'},
                                                    {value: 'No Reg Impact major markets, notifications and/or prior approval in secondary markets'},
                                                    {value: 'US AR and/or Canada Level 3, no reg impact any other markets'},
                                                    {value: 'Other'}
                                                ]}
                                                placeholder={'Select an option'}
                                                value={directionalAssessment}
                                                onChange={value =>
                                                    setDirectionalAssessment(value)
                                                }
                                                dropdownMatchSelectWidth={false}
                                                dropdownStyle={{ width: '500px' }}
                                                className={
                                                    !finalAssessmentAnswered && nriOption === ''
                                                        ? 'select-highlight'
                                                        : ''
                                                }
                                            />
                                        </Col>
                                    </Row>
                                : null }
                                {user_nri_ri === 'TRUE' && directionalAssessment === 'Other' && planningAssessmentType === 'Directional' ? (
                                    <Row gutter={[0, 8]} style={{ paddingTop: '8px' }}>
                                        <Col span={24}>
                                            <Text strong>Details:</Text>
                                            <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>
                                        </Col>
                                        <Col span={24}>
                                            {!printPDF && !viewOnly ? (
                                                <TextArea
                                                    id={'directionalDetails'}
                                                    rows={4}
                                                    onChange={e => setDetailsText(e.target.value)}
                                                    value={detailsText}
                                                    onBlur={() =>
                                                        updateFinalAssessmentField(
                                                            detailsText,
                                                            'directionalDetails',
                                                        )
                                                    }
                                                    spellCheck={true}
                                                />
                                            ) : (
                                                <div
                                                    className="div-print"
                                                    style={{ border: '1px solid  #f0f0f0', padding: '8px' }}
                                                >
                                                    {detailsText}
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                ) : null}
                                {user_nri_ri === 'TRUE' &&
                                    <Row gutter={[0, 8]} style={{ paddingTop: '2em' }}>
                                        <Col span={24}>
                                            <Text strong>
                                                Submission Requirements:  
                                                <span className='ml-2' title='Add Submission Requirement' style={{cursor: 'pointer'}}>
                                                    <button style={{cursor: 'pointer'}} disabled={viewOnly} onClick={addRow}><PlusCircleOutlined /></button>
                                                </span>
                                                <Table
                                                    dataSource={submissionRequirementsData}
                                                    columns={submissionRequirementsColumns}                                                   
                                                    className="subtable-padding"
                                                />
                                            </Text>
                                        </Col>
                                    </Row>
                                }
                                {user_nri_ri === 'TRUE' ? (
                                    <Row gutter={[0, 8]} style={{ paddingTop: '8px' }}>
                                        <Col span={24}>
                                            <Text strong>Additional CMC notes on the change (i.e. RI in China):</Text>
                                        </Col>
                                        <Col span={24}>
                                            {!printPDF && !viewOnly ? (
                                                <TextArea
                                                    id={'additionalNotes'}
                                                    rows={4}
                                                    onChange={e => setAdditionalNotesText(e.target.value)}
                                                    value={additionalNotesText}
                                                    onBlur={() =>
                                                        updateFinalAssessmentField(
                                                            additionalNotesText,
                                                            'additionalNotes',
                                                        )
                                                    }
                                                    spellCheck={true}
                                                />
                                            ) : (
                                                <div
                                                    className="div-print"
                                                    style={{ border: '1px solid  #f0f0f0', padding: '8px' }}
                                                >
                                                    {additionalNotesText}
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                ) : null}
                                {user_nri_ri === 'FALSE' ? (
                                    <>
                                        <Row gutter={[0, 8]} style={{ paddingTop: '8px' }} align={'middle'}>
                                            <Col span={12} style={{ paddingRight: '8px' }}>
                                                <Text strong>
                                                    NRI Justification:
                                                    <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>
                                                </Text>
                                            </Col>
                                            <Col span={12}>
                                                <Row>
                                                    {!finalAssessmentAnswered && nriOption === '' ? (
                                                        <Col span={24}>
                                                            <FormValidationErrorText text="Please select an option" />
                                                        </Col>
                                                    ) : null}
                                                    <Col span={24}>
                                                        {!printPDF && !viewOnly ? (
                                                            <Select
                                                                options={assessmentRationaleOptions}
                                                                placeholder={'Select an option'}
                                                                value={nriOption}
                                                                onChange={value =>
                                                                    updateFinalAssessmentField(value, 'nriOption')
                                                                }
                                                                style={{ width: '100%' }}
                                                                dropdownMatchSelectWidth={false}
                                                                dropdownStyle={{ width: '500px' }}
                                                                className={
                                                                    !finalAssessmentAnswered && nriOption === ''
                                                                        ? 'select-highlight'
                                                                        : ''
                                                                }
                                                            />
                                                        ) : (
                                                            <div
                                                                className="div-print"
                                                                style={{ border: '1px solid  #f0f0f0', padding: '8px' }}
                                                            >
                                                                {nriOption}
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {props.nriOption === 'Other' ?
                                            <Row gutter={[0, 8]} style={{ paddingTop: '8px' }}>
                                                    <Col span={24}>
                                                        <Text strong>
                                                            NRI Justification (Other):
                                                            <span style={{ fontWeight: 400, color: '#ff4d4f' }}>*</span>
                                                        </Text>
                                                    </Col>
                                                <Col span={24}>
                                                    {!printPDF && !viewOnly ? (
                                                        <>
                                                            {!finalAssessmentAnswered && isEmpty(rationale) ? (
                                                                <Col span={24}>
                                                                    <FormValidationErrorText text="Please enter a CMC assessment rationale" />
                                                                </Col>
                                                            ) : null}
                                                            <TextArea
                                                                id={'rationale'}
                                                                rows={4}
                                                                onChange={e => setRationale(e.target.value)}
                                                                value={rationale}
                                                                onBlur={() =>
                                                                    updateFinalAssessmentField(
                                                                        rationale,
                                                                        'rationaleAssessment',
                                                                    )
                                                                }
                                                                spellCheck={true}
                                                                className={
                                                                    isEmpty(rationale) && !finalAssessmentAnswered
                                                                        ? 'select-highlight'
                                                                        : ''
                                                                }
                                                            />
                                                        </>
                                                    ) : (
                                                        <div
                                                            className="div-print"
                                                            style={{ border: '1px solid  #f0f0f0', padding: '8px' }}
                                                        >
                                                            {rationale}
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row> : null
                                        }  
                                    </>
                                ) : null}
                                <Row gutter={[0, 8]} style={{ paddingTop: '8px' }}>
                                </Row>
                                <Row gutter={[0, 8]} style={{ paddingTop: '8px' }}>
                                </Row>
                            </div>
                        )}
                    </FormContext.Consumer>
                </Panel>
            </Collapse>
        </Card>
    );
};
