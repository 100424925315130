import React from 'react';
import {
    AdditionalInfoComments,
    ChangeDescription,
    CPWSHeader,
    MatchCriteriaQuestions,
    QualityAssessmentStatements,
    ReasonForChange,
    TechnicalInformation,
} from '../components';

import {
    CPWS,
    MatchCriteria,
    GlobalProps,
    MatchCriteriaAnswer,
    MaterialCodeMapping,
    Material,
    CPWSClassificationCodes,
    ProductInformation,
} from '../model';
import { CPWSStep } from './screens';
import './screens.css';
import { Card, Collapse, Typography } from 'antd';
import { IpiIdTitle } from '../components/IpiIdTitle';
import { ChangeInformation } from '../components/ChangeInformation';
const { Text } = Typography;
const { Panel } = Collapse;

export interface Screen4Props {
    cpws: CPWS;
    globalProps: GlobalProps;
    matchCriteria: MatchCriteria;
    loadMatchCriteria: Function;
    updateMatchQuestion: Function;
    comment: string;
    sessionUpdate: Function;
    matchCriteriaAnswers: MatchCriteriaAnswer;
    matchCriteriaCardRef: React.RefObject<HTMLDivElement>;
    materialCodeMapping: MaterialCodeMapping;
    impactedMaterials: Array<Material>;
    classificationCodes: CPWSClassificationCodes;
    staticProductInformation: ProductInformation;
    screen4_update: boolean;
    setScreen4Update: Function;
}
export interface Screen4State {
    loading: boolean;
}
export class Screen4 extends React.Component<Screen4Props, Screen4State> {
    state = { loading: true };
    async componentDidMount(): Promise<void> {
        window.scrollTo(0, 0);
        this.props.sessionUpdate();
        await this.props.loadMatchCriteria();
        this.setState({ loading: false });
    }
    render(): JSX.Element {
        const { cpws, globalProps, matchCriteria, staticProductInformation } = this.props;
        const realTitle = cpws.title ? cpws.title : 'No data was loaded. ';
        return (
            <CPWSStep>
                <div  style={{border: '1px solid #d9d9d9', margin: '0px 24px'}}>
                <IpiIdTitle IpiId={cpws.cpwsId} realTitle={realTitle} />
                </div>
                <MatchCriteriaQuestions
                    updateMatchQuestion={this.props.updateMatchQuestion}
                    loading={this.state.loading}
                    panelProps={globalProps.defaultPanelProps}
                    matchCriteria={matchCriteria}
                    cpws={this.props.cpws}
                    matchCriteriaAnswers={this.props.matchCriteriaAnswers}
                    matchCriteriaCardRef={this.props.matchCriteriaCardRef}
                    materialCodeMapping={this.props.materialCodeMapping}
                    impactedMaterials={this.props.impactedMaterials}
                    classificationCodes={this.props.classificationCodes}
                    screen4_update={this.props.screen4_update}
                    setScreen4Update={this.props.setScreen4Update}
                />
                <ChangeInformation
                    cpws={cpws}
                    staticProductInformation={staticProductInformation}
                />
            </CPWSStep>
        );
    }
}
