import {
    Card,
    Progress,
    Result,
    Spin,
    Typography,
    Upload,
    Collapse,
    Input,
    List,
    Row,
    Col,
    notification,
    Button,
    Tabs,
    Table,
    Popover,
} from 'antd';
import { UserAddOutlined, AimOutlined } from '@ant-design/icons';
import {
    CPWS,
    ProductModality,
    Site,
    ProductEvents,
    SiteEvents,
    GlobalProps,
    RegulatoryQuestions,
    ProductModalitySelections,
    FormRefs,
    SiteFunction,
    ColorTag,
    Actions,
    ImpactAnalysis,
    RelatedChanges,
    Plants,
    Suppliers,
    Country,
    RegisteredMarkets,
    ProductInformation,
    ProductsInfo,
    DirectAssessment,
    DataRequirements,
    Partners,
} from '../model';

import React from 'react';
import { DirectionalAssessment } from '../components/DirectionalAssessment';
import { MatchCriteriaQuestionsFinalYes } from '../components/MatchCriteriaQuestionsFinalYes';
import { FinalResults } from '../components/FinalResults';
const { Text } = Typography;
const { TextArea } = Input;
import './components.css';
const { Panel } = Collapse;
const { TabPane } = Tabs;

export interface DetailedAssessmentsProps {
    matchCriteriaYesRegulation: any;
    impactedCountries: Array<Country>;
    openAttachmentApi: Function;
    cpws: CPWS;
    globalProps: GlobalProps;
    loading: boolean;
    printPDF: boolean;
    regulatoryQuestions: RegulatoryQuestions;
    marketLevelAssessment: any;
    productModalitiesSelections: any;
}

export const DetailedAssessments: React.FC<DetailedAssessmentsProps> = (props: DetailedAssessmentsProps) => {
    //: React.FC<ChangeInformationProps> = (props: ChangeInformationProps) => {
    // const { currentChange, proposedChange } = props;
    

    const { matchCriteriaYesRegulation, productModalitiesSelections, marketLevelAssessment, impactedCountries, regulatoryQuestions, cpws, loading, openAttachmentApi, globalProps } = props;
    //console.log(props.productModalitiesSelections);
    const BQuestions = (props.matchCriteriaYesRegulation[0] != undefined) ? props.matchCriteriaYesRegulation[0]["B"]: [];
    const SMQuestions = (props.matchCriteriaYesRegulation[0] != undefined) ? props.matchCriteriaYesRegulation[0]["SM"]: [];
    const VQuestions = (props.matchCriteriaYesRegulation[0] != undefined) ? props.matchCriteriaYesRegulation[0]["V"]: [];
    return (
        <Card bordered={false}>
            <Collapse defaultActiveKey={1} expandIconPosition="right">
                <Panel header="Detailed Assessments" key="1">
                    <Tabs defaultActiveKey="reg_impact_ass" type="card">
                        <TabPane tab="Regulatory Impact Assessment" key="reg_impact_ass">
                            <Card bordered={false}>
                                <Tabs type="card">
                                    {props.productModalitiesSelections["biologic"] ? 
                                    <TabPane tab="Biologic" key="reg_impact_ass_B">
                                        <Card bordered={false}>                                        
                                            <Row>
                                                {BQuestions && BQuestions.length > 0 && BQuestions[0] != null ?
                                                <MatchCriteriaQuestionsFinalYes
                                                    loading={props.loading}
                                                    panelProps={props.globalProps.defaultPanelProps}
                                                    matchCriteriaYesRegulation={BQuestions}
                                                    cpws={props.cpws}
                                                    openAttachmentApi={props.openAttachmentApi}
                                                    impactedCountries={props.impactedCountries}
                                                />
                                                : null}
                                            </Row>
                                        </Card>
                                    </TabPane>
                                    : null
                                    }
                                    {props.productModalitiesSelections["smallMolecule"] ?
                                    <TabPane tab="Small Molecule" key="reg_impact_ass_SM">
                                        <Card bordered={false}>
                                            <Row>
                                            {SMQuestions && SMQuestions.length > 0 && SMQuestions[0] != null ?
                                            <MatchCriteriaQuestionsFinalYes
                                                    loading={props.loading}
                                                    panelProps={props.globalProps.defaultPanelProps}
                                                    matchCriteriaYesRegulation={SMQuestions}
                                                    cpws={props.cpws}
                                                    openAttachmentApi={props.openAttachmentApi}
                                                    impactedCountries={props.impactedCountries}
                                                />
                                               : null } 
                                            </Row>                                            
                                        </Card>
                                    </TabPane>
                                    : null
                                    }
                                    {props.productModalitiesSelections["vaccine"] ?
                                    <TabPane tab="Vaccine" key="reg_impact_ass_V">
                                        <Card bordered={false}>
                                            <Row>
                                            {VQuestions && VQuestions.length > 0 && VQuestions[0] != null ?
                                            <MatchCriteriaQuestionsFinalYes
                                                    loading={props.loading}
                                                    panelProps={props.globalProps.defaultPanelProps}
                                                    matchCriteriaYesRegulation={VQuestions}
                                                    cpws={props.cpws}
                                                    openAttachmentApi={props.openAttachmentApi}
                                                    impactedCountries={props.impactedCountries}
                                                />
                                                : null }
                                            </Row>                                            
                                        </Card>
                                    </TabPane>
                                    : null
                                    }
                                </Tabs>
                            </Card>
                        </TabPane>
                        <TabPane tab="Market Level Assessment" key="mar_level_ass">
                            <Card bordered={false}>
                                <Tabs type="card">
                                    {props.productModalitiesSelections["biologic"] ?
                                    <TabPane tab="Biologic" key="mar_level_ass_B">
                                        <FinalResults
                                            loading={props.loading}
                                            qaQuestion={props.cpws.qaQuestion as boolean}
                                            panelProps={props.globalProps.defaultPanelProps}
                                            assessmentResults={props.marketLevelAssessment.B}
                                            countries={props.impactedCountries}
                                            printPDF={props.printPDF}
                                            regulatoryQuestions={props.regulatoryQuestions}
                                            openAttachmentApi={props.openAttachmentApi}                                            
                                        />
                                    </TabPane>
                                    : null
                                    }
                                    {props.productModalitiesSelections["smallMolecule"] ?
                                    <TabPane tab="Small Molecule" key="mar_level_ass_SM">
                                        <FinalResults
                                            loading={props.loading}
                                            qaQuestion={props.cpws.qaQuestion as boolean}
                                            panelProps={props.globalProps.defaultPanelProps}
                                            assessmentResults={props.marketLevelAssessment.SM}
                                            countries={props.impactedCountries}
                                            printPDF={props.printPDF}
                                            regulatoryQuestions={props.regulatoryQuestions}
                                            openAttachmentApi={props.openAttachmentApi}                                            
                                        />
                                    </TabPane>
                                    : null
                                    }
                                    {props.productModalitiesSelections["vaccine"] ?
                                    <TabPane tab="Vaccine" key="mar_level_ass_V">
                                        <FinalResults
                                            loading={props.loading}
                                            qaQuestion={props.cpws.qaQuestion as boolean}
                                            panelProps={props.globalProps.defaultPanelProps}
                                            assessmentResults={props.marketLevelAssessment.V}
                                            countries={props.impactedCountries}
                                            printPDF={props.printPDF}
                                            regulatoryQuestions={props.regulatoryQuestions}
                                            openAttachmentApi={props.openAttachmentApi}                                            
                                        />
                                    </TabPane>
                                    : null
                                    }
                                </Tabs>
                            </Card>
                        </TabPane>
                    </Tabs>
                </Panel>
            </Collapse>
        </Card>
    );
};
