import React from 'react';
import { Card, Collapse, Typography, Popover, List, Col, Row } from 'antd';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';
import { AssesmentMarkets, Country } from '../model';
import find from 'lodash/find';

const { Panel } = Collapse;
const { Text, Title } = Typography;

export interface DirectionalAssesmentProps {
    majorMarkets: AssesmentMarkets;
    secondaryMarkets: AssesmentMarkets;
    loading?: boolean;
    countries: Array<Country>;
}
export interface MarketTypes {
    markets: Array<string>;
    totalMarkets: Array<string>;
    info: string;
    title: string;
    countries: Array<Country>;
    header?: boolean;
}
const marketsList = (marketdetails: MarketTypes) => {
    return (
        <>
            <Title level={5}>{marketdetails.title}</Title>
            <List
                size={'small'}
                grid={{ column: 4 }}
                dataSource={marketdetails.markets
                    .reduce((marketNames: Array<string>, countryCode: string) => {
                        const country = find(marketdetails.countries, ['code', countryCode]);
                        if (country) {
                            marketNames.push(country.name);
                        }
                        if(!country&&countryCode==='EU'){
                            marketNames.push('European Union');
                        }
                        return marketNames;
                    }, [])
                    .sort()}
                style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '300px' }}
                renderItem={item => (
                    <List.Item className="directional-assessment-market">
                        <Text>{item}</Text>
                    </List.Item>
                )}
            />
        </>
    );
};
const Markets = (marketdetails: MarketTypes) => {
    return (
        <>
            {!marketdetails.header && marketdetails.markets.length > 0 && (
                <Popover
                    content={marketsList(marketdetails)}
                    placement={marketdetails.header ? 'topRight' : 'topLeft'}
                    overlayStyle={{ width: '600px' }}
                >
                    <Text
                        style={{
                            color: '#1890ff',
                            transition: 'color .3s',
                            textDecoration: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        {marketdetails.markets.length} of {marketdetails.totalMarkets.length}
                    </Text>
                    <Text> {marketdetails.info}</Text>
                </Popover>
            )}
            {!marketdetails.header && marketdetails.markets.length === 0 && (
                <Text>
                    {marketdetails.markets.length} of {marketdetails.totalMarkets.length} {marketdetails.info}
                </Text>
            )}
            {marketdetails.header && (
                <>
                    <Text strong underline style={{ fontSize: '16px' }}>
                        {marketdetails.title}
                    </Text>
                    <Popover
                        content={marketsList(marketdetails)}
                        placement={marketdetails.header ? 'topRight' : 'topLeft'}
                        overlayStyle={{ width: '600px' }}
                    >
                        <InfoCircleFilled style={{ color: '#1890ff', paddingLeft: '4px' }} />
                    </Popover>
                </>
            )}
        </>
    );
};

export const DirectionalAssessment: React.FC<DirectionalAssesmentProps> = (props: DirectionalAssesmentProps) => {
    const majorMarkets = (): JSX.Element => {
        return (
            <Row justify={'center'} style={{ paddingBottom: '16px' }}>
                <Col span={22}>
                    <Col span={24}>
                        <Markets
                            markets={props.majorMarkets.total}
                            totalMarkets={[]}
                            info={'Major Markets'}
                            title={'Major Markets'}
                            header={true}
                            countries={props.countries}
                        />
                    </Col>
                    <Col span={24}>
                        <Markets
                            markets={props.majorMarkets.priorApproval}
                            totalMarkets={props.majorMarkets.total}
                            info={'major markets require prior approval'}
                            title={'Prior Approval'}
                            countries={props.countries}
                        />
                    </Col>
                    <Col span={24}>
                        <Markets
                            markets={props.majorMarkets.notification}
                            totalMarkets={props.majorMarkets.total}
                            info={'major markets require notification'}
                            title={'Notification'}
                            countries={props.countries}
                        />
                    </Col>
                    <Col span={24}>
                        <Markets
                            markets={props.majorMarkets.nri}
                            totalMarkets={props.majorMarkets.total}
                            info={' major markets have no regulatory impact'}
                            title={'No Regulatory Impact'}
                            countries={props.countries}
                        />
                    </Col>
                    <Col span={24}>
                        <Markets
                            markets={props.majorMarkets.incomplete}
                            totalMarkets={props.majorMarkets.total}
                            info={'major markets require further assessment due to incomplete dataset'}
                            title={'Incomplete'}
                            countries={props.countries}
                        />
                    </Col>
                </Col>
            </Row>
        );
    };
    const secondaryMarkets = (): JSX.Element => {
        return (
            <Row justify={'center'}>
                <Col span={22}>
                    <Col span={24}>
                        <Markets
                            markets={props.secondaryMarkets.total}
                            totalMarkets={[]}
                            info={'Secondary Markets'}
                            title={'Secondary Markets'}
                            header={true}
                            countries={props.countries}
                        />
                    </Col>
                    <Col>
                        <Markets
                            markets={props.secondaryMarkets.priorApproval}
                            totalMarkets={props.secondaryMarkets.total}
                            info={'secondary markets require prior approval'}
                            title={'Prior approval'}
                            countries={props.countries}
                        />
                    </Col>
                    <Col>
                        <Markets
                            markets={props.secondaryMarkets.notification}
                            totalMarkets={props.secondaryMarkets.total}
                            info={'secondary markets require notification'}
                            title={'Notification'}
                            countries={props.countries}
                        />
                    </Col>
                    <Col>
                        <Markets
                            markets={props.secondaryMarkets.nri}
                            totalMarkets={props.secondaryMarkets.total}
                            info={'secondary markets have no regulatory impact'}
                            title={'No Regulatory Impact'}
                            countries={props.countries}
                        />
                    </Col>
                    <Col>
                        <Markets
                            markets={props.secondaryMarkets.incomplete}
                            totalMarkets={props.secondaryMarkets.total}
                            info={'secondary markets require further assessment due to incomplete dataset'}
                            title={'Incomplete'}
                            countries={props.countries}
                        />
                    </Col>
                </Col>
            </Row>
        );
    };
    return (
        <div>
            {majorMarkets()}
            {secondaryMarkets()}
        </div>        
    );
};
