import React from 'react';
import { Col, Radio, Row, Typography } from 'antd';
import { FormContext } from '../context/FormContext';
const { Text } = Typography;

interface Selections {
    [key: string]: string | boolean;
}

interface RegulatoryQuestionProps {
    questionText: string;
    onSelection: Function;
    questionName: string;
    selections: Selections;
    current?: number;
    isChild?: boolean;
    parent?: string;
}

export const RegulatoryQuestion: React.FC<RegulatoryQuestionProps> = (props: RegulatoryQuestionProps) => {
    return (
        <FormContext.Consumer>
            {({ viewOnly }) => (
                <Col span={24}>
                    <Row gutter={1} style={props.isChild ? { marginLeft: '16px' } : undefined}>
                        <Col
                            span={12}
                            style={{
                                alignContent: 'center',
                                padding: '16px',
                                ...(props.isChild && { borderLeft: '1px solid black' }),
                            }}
                        >
                            <Text>{props.questionText}</Text>
                        </Col>
                        <Col
                            span={12}
                            style={{
                                textAlign: 'center',
                                padding: props.isChild ? '16px 16px 16px 8px' : '16px',
                            }}
                        >
                            <Radio.Group
                                onChange={e => props.onSelection(e.target.value, e.target.name as string)}
                                value={
                                    props.parent
                                        ? props.selections[`${props.parent}C${props.questionName.slice(1)}`]
                                        : props.selections[props.questionName]
                                }
                                name={
                                    props.parent ? `${props.parent}C${props.questionName.slice(1)}` : props.questionName
                                }
                                className={viewOnly ? 'view-only' : ''}
                            >
                                <Radio value={true} disabled={props.current && props.current === 7 ? true : false}>
                                    Yes
                                </Radio>
                                <Radio value={false} disabled={props.current && props.current === 7 ? true : false}>
                                    No
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Col>
            )}
        </FormContext.Consumer>
    );
};
