import { Button, Card, Collapse, Input, Select, Table, Tag, Menu, Checkbox, Typography, ConfigProvider, Form, Radio, Row, Col, Popconfirm } from 'antd';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import FilterFilled from '@ant-design/icons/FilterFilled';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import differenceBy from 'lodash/differenceBy';
import difference from 'lodash/difference';
import React, { ReactText } from 'react';
import Highlighter from 'react-highlight-words';
import { CPWS, Material, MaterialEvents, PanelProps, SiteEvents, ExternalMaterial, Materials, SelectedMaterials, ProductsInfo } from '../model';
import { findObjectByValue, sortObjectArrayByValue, findObjectIndexByValue, objectExistsInArray } from '../utils';
import { ExternalMaterialsDropdown } from './ExternalMaterialsDropdown';
import { MaterialQuickSelect } from './MaterialQuickSelect';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';
import { isEmpty } from 'lodash';
import { IPIMaterials } from './IPIMaterials';
import { ConsoleSqlOutlined, DeleteOutlined } from '@ant-design/icons';
import { MaterialsSelection } from './MaterialsSection';

const { Panel } = Collapse;
const { Option } = Select;
const { Text } = Typography;

interface FilterInput {
    setSelectedKeys: any;
    selectedKeys: any;
    confirm: any;
    clearFilters: any;
}

interface ProcessStage {
    text: string;
    value: string;
    checked: boolean;
}

interface FilteredValues {
    processStageFilters: string[];
    materialNameFilter: string[];
    materialNumberFilter: string[];
}

interface ImpactedMaterialsState {
    searchText: string;
    searchedColumn: string;
    selectedRowKeys: Array<string>;
    loading: boolean;
    visible: boolean;
    sortedInfo: any;
    externalMaterials: Array<ExternalMaterial>;
    customInput: string;
    val: any;
    processStages: Array<ProcessStage>;
    filteredProcessStages: Array<ProcessStage>;
    processStageInput: string;
    filteredValues: FilteredValues;
    dropdownOpen: boolean;
    editedMaterialName: string;
    modalVisible: boolean;
    unselectedMaterials: Array<Material>;
    selectedMaterials: Array<Material>;
    unselectedExternalMaterials: Array<Material>;
    quickSelectRowKeys: Array<string>;
    repopulateData: boolean;
    areImpactedMaterialsMissing: any;
    setHovering: boolean;
    setPopupVisible: boolean;
    userSelectedMaterials: Array<SelectedMaterials>;
    mappedUserSelectedItems: any;
    userSelectedExternalMaterials: Array<SelectedMaterials>;
}
export interface ImpactedMaterialsProps {
    onSelectRows?: (rows: any) => void;
    cpws: CPWS;
    materialEvents: MaterialEvents;
    panelProps: PanelProps;
    impactedMaterialsAvailable: Array<Material>;
    impactedMaterialsAvailableOption: any;
    downstreamImpactedMaterialsAvailableOption: any;
    siteEvents?: SiteEvents;
    addMaterial: Function;
    externalImpactedMaterials: Array<ExternalMaterial>;
    deleteMaterial: Function;
    editExternalMaterial?: Function;
    materialTableRef: React.RefObject<HTMLDivElement>;
    updateUserSelectedMaterialProps: Function;
    updateImpactedMaterialsOption?: Function;
    updateDownstreamImpactedMaterialsOption: Function;
    userSelectedMaterials: Array<SelectedMaterials>;
    userSelectedExternalMaterials?: Function;
    userSelectedExternalMaterialProps?: Array<ExternalMaterial>;
    updateCurrentTab?: Function;
    areImpactedMaterialsMissing?: Function;
    viewOnly?: boolean;
}

export class ImpactedMaterials extends React.Component<ImpactedMaterialsProps, ImpactedMaterialsState> {

    constructor(props: any) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            selectedRowKeys: [],
            loading: true,
            visible: false,
            externalMaterials: [],
            customInput: '',
            val: '',
            sortedInfo: {
                order: 'descend',
                columnKey: 'checked',
                column: { key: 'checked', sortOrder: 'descend' },
            },
            processStages: [],
            filteredProcessStages: [],
            processStageInput: '',
            filteredValues: { processStageFilters: [], materialNameFilter: [], materialNumberFilter: [] },
            dropdownOpen: false,
            editedMaterialName: '',
            modalVisible: false,
            unselectedMaterials: [],
            selectedMaterials: [],
            unselectedExternalMaterials: [],
            quickSelectRowKeys: [],
            repopulateData: true,
            areImpactedMaterialsMissing: this.props.impactedMaterialsAvailableOption == "Yes" ? true : this.props.impactedMaterialsAvailableOption == "No" ? false : null,
            setPopupVisible: false,
            setHovering: false,
            userSelectedMaterials: [],
            mappedUserSelectedItems: [],
            userSelectedExternalMaterials: [],
        };
    }


    async componentDidMount() {
        //await this.props.materialEvents.loadMaterials();
       // await this.props.materialEvents.loadMaterialsExternal();

        Promise.all([
            //this.props.materialEvents.loadMaterials(),
            this.props.materialEvents.loadMaterialsExternal(),
        ]).then(() => {
            const EM = this.props.externalImpactedMaterials;
            const unselectedMaterials = cloneDeep(this.props.impactedMaterialsAvailable);
            //console.log('unselectedMaterials', unselectedMaterials)
            const selectedMaterials: Array<Material> = [];
            const selectedRowKeys: Array<string> = [];
            const unselectedExternalMaterials: Array<Material> = [];

            let index = findIndex(unselectedMaterials, (mat: Material) =>
                mat.number.toLocaleLowerCase().startsWith('ext-'),
            );
            //console.log('ImpactedMaterials >>> 145', index);
            while (index !== -1) {
                unselectedExternalMaterials.push(unselectedMaterials[index]);
                //console.log('ImpactedMaterials >>> 148', unselectedExternalMaterials);
                unselectedMaterials.splice(index, 1);
                index = findIndex(unselectedMaterials, (mat: Material) =>
                    mat.number.toLocaleLowerCase().startsWith('ext-'),
                );
            }

            const impactedMaterials = sortObjectArrayByValue(this.props.cpws.impactedMaterials, 'name');
            //console.log('ImpactedMaterials >>> 156', impactedMaterials);
            // impactedMaterials.forEach((material: Material) => {
            //     if (material.number.toLocaleLowerCase().startsWith('ext-')) {
            //         const index = findObjectIndexByValue(unselectedExternalMaterials, 'number', material.number);
            //         if (index > -1) {
            //             unselectedExternalMaterials.splice(index, 1);
            //             selectedMaterials.push(material);
            //             selectedRowKeys.push(material.number);
            //         }
            //     } else {
            //         const index = findObjectIndexByValue(unselectedMaterials, 'number', material.number);
            //         if (index > -1) {
            //             unselectedMaterials.splice(index, 1);
            //             selectedMaterials.push(material);
            //             selectedRowKeys.push(material.number);
            //         }
            //     }
            // });
            const externalMaterialsKeys: Array<string> = [];
            const userSelectedExternalMaterials: Array<SelectedMaterials> = [];
            const userSelectedMaterials: Array<SelectedMaterials> = [];
            const externalMaterialData = cloneDeep(this.props.impactedMaterialsAvailable);
            this.props.userSelectedMaterials.map((data: SelectedMaterials) => {
                //console.log('Select row key 182 data>> ', data);
                if (data.typeCode === 'E') {
                    userSelectedExternalMaterials.push(data);
                    //console.log('Select row key 183 >> ');
                    (externalMaterialData.filter(value => {
                        //console.log('Select row key 186 >> ', value);
                        if (value.name === data.name) {
                            //console.log('Select row key 188 >> ');
                            externalMaterialsKeys.push(value.name);
                        }
                    }))
                }
                else {
                    userSelectedMaterials.push(data);
                }
            });

            this.setState(
                {
                    loading: false,
                    externalMaterials: EM,
                    processStages: this.processStageFilters().map((processStage: { text: string; value: string }) => ({
                        text: processStage.text,
                        value: processStage.value,
                        checked: false,
                    })),
                    unselectedMaterials: sortObjectArrayByValue(unselectedMaterials, 'name'),
                    selectedMaterials: sortObjectArrayByValue(selectedMaterials, 'name'),
                    unselectedExternalMaterials: sortObjectArrayByValue(unselectedExternalMaterials, 'name'),
                    selectedRowKeys: externalMaterialsKeys,
                    userSelectedExternalMaterials: userSelectedExternalMaterials,
                    userSelectedMaterials: userSelectedMaterials,
                },
                () => this.setState({ filteredProcessStages: cloneDeep(this.state.processStages) }),
            );
        });
    }

    async componentDidUpdate(prevProps: any): Promise<void> {
        if (this.props.externalImpactedMaterials !== prevProps.externalImpactedMaterials) {
            //console.log('component did update loop1...');
            const tempProcessStages = this.state.processStages;
            this.processStageFilters().forEach(processStage => {
                if (!find(tempProcessStages, { text: processStage.text })) {
                    tempProcessStages.push({ text: processStage.text, value: processStage.value, checked: false });
                }
            });
            tempProcessStages.sort((a, b) => a.value.localeCompare(b.value));
            this.setState({
                externalMaterials: this.props.externalImpactedMaterials,
                processStages: tempProcessStages,
                filteredProcessStages: cloneDeep(tempProcessStages),
                processStageInput: '',
            });
        }

        if (this.props.impactedMaterialsAvailable.length > prevProps.impactedMaterialsAvailable.length) {
            //console.log('component did update loop2...');
            const newMaterialsName:any = [];
            const newMaterials = differenceBy(
                this.props.impactedMaterialsAvailable,
                prevProps.impactedMaterialsAvailable,
                'name',
            );
            //console.log("New Materials:", newMaterials);
            newMaterials.forEach((mat: Material) => {
                if (mat.number.toLocaleLowerCase().startsWith('ext-')) {
                    this.state.unselectedExternalMaterials.push(mat);
                    newMaterialsName.push(mat.name);
                } else {
                    this.state.unselectedMaterials.push(mat);
                }
            });

            this.setState({
                unselectedExternalMaterials: sortObjectArrayByValue(this.state.unselectedExternalMaterials, 'name'),
                unselectedMaterials: sortObjectArrayByValue(this.state.unselectedMaterials, 'name'),
            });
            if(newMaterialsName.length > 0) {
                this.onDynamicManualEnterSelection(newMaterialsName);
            }
        }
    }

    getColumnSearchProps = (dataIndex: string): any => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterInput): any => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered: any) => (
            <SearchOutlined style={{ color: !filtered ? 'rgb(100,100,100)' : 'rgb(24,144,255)' }} />
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex].startsWith('ext-')
                ? 'N/A'.toLocaleLowerCase().includes(value.toLocaleLowerCase())
                : record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()),
        render: (text: string) => {
            const regex = /^ext-/m;
            text = regex.exec(text) !== null ? 'N/A' : text;
            return this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text}
                />
            ) : (
                text
            );
        },
    });

    handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({
            searchText: '',
            repopulateData: true,
        });
    };

    setRepopulateDataFlag = (bool: boolean) => {
        this.setState({
            repopulateData: bool,
        });
    };

    onSelectChange = (selectedRowKeys: any) => {
        //console.log("selected rows:" +selectedRowKeys);
        const userSelectedExternalMaterialArray: any = []
        if (selectedRowKeys.length > this.state.selectedRowKeys.length) {
            selectedRowKeys.forEach((matNum: string) => {
                const externaMaterial = matNum !== undefined && matNum !== '' ? this.onSelectUpdateExternalMaterialData(matNum) : '';
                userSelectedExternalMaterialArray.push(externaMaterial);
            });
            this.props.userSelectedMaterials.map((material: SelectedMaterials) => {
                if (material.typeCode === 'M' || material.typeCode === 'P')
                    userSelectedExternalMaterialArray.push(material);
            });

            this.setState({ selectedRowKeys: selectedRowKeys, userSelectedExternalMaterials: userSelectedExternalMaterialArray }, () => {
                this.props.updateUserSelectedMaterialProps(userSelectedExternalMaterialArray);
            },
            );
        }
        else if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach((matNum: string) => {
                //console.log("userSelectedMat 364", this.state.userSelectedMaterials);
                if (!findObjectByValue(this.state.userSelectedMaterials, 'number', matNum)) {
                    const externaMaterial = matNum !== undefined && matNum !== '' ? this.onSelectUpdateExternalMaterialData(matNum) : '';
                    userSelectedExternalMaterialArray.push(externaMaterial);
                }
            });

            this.props.userSelectedMaterials.map((material: SelectedMaterials) => {
                if (material.typeCode === 'M' || material.typeCode === 'P')
                    userSelectedExternalMaterialArray.push(material);
            });
            this.setState({ selectedRowKeys: selectedRowKeys, userSelectedExternalMaterials: userSelectedExternalMaterialArray }, () => {
                this.props.updateUserSelectedMaterialProps(userSelectedExternalMaterialArray);
            },
            );
           
        }
        else {
            //console.log('Selected Change 350 line', selectedRowKeys)
            userSelectedExternalMaterialArray.pop();
            this.props.userSelectedMaterials.map((material: SelectedMaterials) => {
                if (material.typeCode === 'M' || material.typeCode === 'P')
                    userSelectedExternalMaterialArray.push(material);
            });
            //console.log('Selected Change 350  11 line', userSelectedExternalMaterialArray)
            this.setState(
                {
                    // selectedMaterials: sortObjectArrayByValue(selectedMaterials, 'name'),
                    // unselectedMaterials: [...unselectedMaterials],
                    // unselectedExternalMaterials: [...unselectedExternalMaterials],
                    selectedRowKeys: selectedRowKeys,
                    userSelectedExternalMaterials: userSelectedExternalMaterialArray
                },
                () => {
                    //this.props.materialEvents.onMaterialSelection(selectedMaterials);
                    this.props.updateUserSelectedMaterialProps(userSelectedExternalMaterialArray);

                },
            );
        }

    };

    getTags(materials: Array<Material>): Array<JSX.Element> {
        return materials.map((material: any) => {
            return <Tag key={material.name}>{material.name}</Tag>;
        });
    }
    loadOptions(materials: Array<Material>): Array<JSX.Element> {
        return materials.map((materialString: any) => {
            const material = JSON.parse(materialString);
            return (
                <Option value={material.name} key={JSON.stringify(material)}>
                    {material.name}
                </Option>
            );
        });
    }
    defaultValues = (materials: Array<Material>): any => {
        return materials.map((material: Material) => {
            return { key: JSON.stringify(material), label: material.name };
        });
    };

    setVisible = () => {
        this.setState({ visible: !this.state.visible });
    };

    externalMaterialSelected = (materialName: string) => {
        this.setState(
            {
                customInput: materialName,
            },
            () => {
                if (this.state.customInput !== '' && this.state.dropdownOpen === true) {
                    this.setState({ dropdownOpen: false });
                }
            },
        );
    };

    setDropdown = () => {
        this.setState({ dropdownOpen: true });
    };

    editExternalMaterial = (matNum: string, newName: string, oldName: string) => {
        const unselectedExternalMaterials = this.state.unselectedExternalMaterials;
        const selectedMaterials = this.state.selectedMaterials;

        let index = findObjectIndexByValue(unselectedExternalMaterials, 'number', matNum);
        if (index >= 0 && unselectedExternalMaterials[index]['name'] !== newName) {
            unselectedExternalMaterials[index]['name'] = newName;
        }

        index = findObjectIndexByValue(selectedMaterials, 'number', matNum);
        if (index >= 0 && selectedMaterials[index]['name'] !== newName) {
            selectedMaterials[index]['name'] = newName;
        }

        this.setState(
            {
                unselectedExternalMaterials: [...unselectedExternalMaterials],
                selectedMaterials: [...selectedMaterials],
                repopulateData: true,
                customInput: '',
            },
            () => {
                if (this.props.editExternalMaterial) {
                    this.props.editExternalMaterial(matNum, newName, oldName);
                }
            }
        );
    };

    sampleList(materials: Array<ExternalMaterial>): Array<JSX.Element> {
        return materials.map((material: ExternalMaterial) => {
            return (
                <Option value={material.name} key={material.number} label={material.name}>
                    <ExternalMaterialsDropdown
                        material={material}
                        externalMaterialSelected={this.externalMaterialSelected}
                        deleteItem={this.deleteItem}
                        setDropdown={this.setDropdown}
                        editExternalMaterial={this.editExternalMaterial}
                        externalMaterials={this.props.externalImpactedMaterials}
                    />
                </Option>
            );
        });
    }

    addManual = (event: any) => {
        //console.log("Event 482", event)
        for (let i = 0; i < event.length; ++i) {
            if (event[i].value) {
                const material = event[i].value;
                //console.log("Material 481", material)

                this.setState({ customInput: this.state.customInput.concat(material) });
            } else {
                this.setState({ customInput: event });
            }
        }
    };

    onClear = () => {
        this.setState({ customInput: '' });
    };

    onDynamicManualEnterSelection = (rows: Array<string>) => {
        console.log("onDynamicManualEnterSelection Called");        
        const finalArray = [...rows, ...this.state.selectedRowKeys];
        //console.log(this.state.selectedRowKeys);    
        this.onSelectChange(finalArray);;
        this.setRepopulateDataFlag(true);
        //console.log("unselected exernal Materials:", this.state.unselectedExternalMaterials);
        //console.log("selected row keys", this.state.selectedRowKeys);
    }

    sendData = () => {
        const materialName = this.state.customInput;
        //console.log("Material name impmat", materialName);
        if (materialName !== '' && materialName !== undefined) {
            const material = findObjectByValue(this.state.externalMaterials, 'name', materialName);
            if(material) {
                this.props.addMaterial(material)
            } else {
                this.props.addMaterial({ name: materialName, number: '' });
            }
            this.setState({
                customInput: materialName,
                repopulateData: true,
            });  
        }
        this.onClear();           
    };

    deleteItem = (material: ExternalMaterial,) => {
        //e.stopPropagation();
        const unselectedExternalMaterials = this.state.unselectedExternalMaterials;
        const selectedMaterials = this.state.selectedMaterials;

        let index = findObjectIndexByValue(unselectedExternalMaterials, 'number', material.number);
        if (index > -1) {
            unselectedExternalMaterials.splice(index, 1);
        }

        index = findObjectIndexByValue(selectedMaterials, 'number', material.number);
        if (index > -1) {
            selectedMaterials.splice(index, 1);
        }

        this.setState(
            {
                unselectedExternalMaterials: [...unselectedExternalMaterials],
                selectedMaterials: [...selectedMaterials],
                repopulateData: true,
            },
            () => this.props.deleteMaterial(material.number, material.name),
        );

        this.onClear();
    };

    confirmDelete = () => {
        //console.log('confirm delete');
    };

    manualMaterials = (viewOnly: boolean) => {
        const { customInput, externalMaterials } = this.state;

        return (
            <div className={(viewOnly || this.props.viewOnly === true) ? 'view-only manualMaterials' : 'manualMaterials'}>
                <div style={{ alignSelf: 'center' }}>Manually enter Impacted Material:</div>
                <Select
                    style={{ width: '50%', padding: '10px' }}
                    onSelect={value => this.addManual(value)}
                    // labelInValue={true}
                    onChange={value => this.addManual(value)}
                    onSearch={value => this.addManual(value)}
                    showSearch
                    showArrow={false}
                    value={customInput}
                    optionLabelProp="label"
                    open={this.state.dropdownOpen}
                    onClick={() => {
                        if (this.state.dropdownOpen === false) {
                            this.setState({ dropdownOpen: true });
                        }
                    }}
                    onBlur={() => {
                        this.setState({ dropdownOpen: false });
                    }}
                >
                    {this.sampleList(externalMaterials)}
                </Select>
                <Button onClick={()=> this.sendData() }>Add Material</Button>
            </div>
        );
    };

    checkFilteredValues = (material: Material, processStages: Array<{ text: string; value: string }>) => {
        if (
            !isEmpty(this.state.filteredValues.materialNameFilter) &&
            isEmpty(this.state.filteredValues.materialNumberFilter)
        ) {
            if (
                material.name
                    .toLocaleLowerCase()
                    .includes(this.state.filteredValues.materialNameFilter[0].toLocaleLowerCase()) &&
                material.stg_description
            ) {
                processStages.push(
                    material.stg_description !== 'external'
                        ? { text: material.stg_description, value: material.stg_description }
                        : { text: 'N/A', value: 'N/A' },
                );
                return processStages;
            }
        } else if (
            isEmpty(this.state.filteredValues.materialNameFilter) &&
            !isEmpty(this.state.filteredValues.materialNumberFilter)
        ) {
            if (
                material.number
                    .toLocaleLowerCase()
                    .includes(this.state.filteredValues.materialNumberFilter[0].toLocaleLowerCase()) &&
                material.stg_description
            ) {
                processStages.push(
                    material.stg_description !== 'external'
                        ? { text: material.stg_description, value: material.stg_description }
                        : { text: 'N/A', value: 'N/A' },
                );
                return processStages;
            }
        } else if (
            !isEmpty(this.state.filteredValues.materialNameFilter) &&
            !isEmpty(this.state.filteredValues.materialNumberFilter)
        ) {
            if (
                material.name
                    .toLocaleLowerCase()
                    .includes(this.state.filteredValues.materialNameFilter[0].toLocaleLowerCase()) &&
                material.number
                    .toLocaleLowerCase()
                    .includes(this.state.filteredValues.materialNumberFilter[0].toLocaleLowerCase()) &&
                material.stg_description
            ) {
                processStages.push(
                    material.stg_description !== 'external'
                        ? { text: material.stg_description, value: material.stg_description }
                        : { text: 'N/A', value: 'N/A' },
                );
                return processStages;
            }
        } else {
            if (material.stg_description) {
                processStages.push(
                    material.stg_description !== 'external'
                        ? { text: material.stg_description, value: material.stg_description }
                        : { text: 'N/A', value: 'N/A' },
                );
                return processStages;
            }
        }
        return processStages;
    };

    processStageFilters = (): Array<{ text: string; value: string }> => {
        const filters = this.props.impactedMaterialsAvailable
            .reduce((processStages: Array<{ text: string; value: string }>, material: Material) => {
                if (
                    material.stg_description !== 'external' &&
                    !find(processStages, ['value', material.stg_description])
                ) {
                    return this.checkFilteredValues(material, processStages);
                } else if (material.stg_description === 'external' && !find(processStages, ['value', 'N/A'])) {
                    return this.checkFilteredValues(material, processStages);
                }
                return processStages;
            }, [])
            .sort((a, b) => a.value.localeCompare(b.value));
        return filters;
    };

    processStageChecked = (processStageText: string | undefined) => {
        const processStageIndex = findIndex(this.state.processStages, { text: processStageText });
        const processStages = this.state.processStages;
        processStages[processStageIndex].checked = !processStages[processStageIndex].checked;

        const filteredProcessStagesIndex = findIndex(this.state.filteredProcessStages, { text: processStageText });
        const filteredProcessStages = this.state.filteredProcessStages;
        filteredProcessStages[filteredProcessStagesIndex].checked = !filteredProcessStages[filteredProcessStagesIndex]
            .checked;

        this.setState({
            processStages: [...this.state.processStages],
            filteredProcessStages: [...this.state.filteredProcessStages],
        });
    };

    clearProcessStageFilters = () => {
        this.setState(
            {
                processStages: this.processStageFilters().map((processStage: { text: string; value: string }) => ({
                    text: processStage.text,
                    value: processStage.value,
                    checked: false,
                })),
                processStageInput: '',
            },
            () =>
                this.setState({
                    filteredProcessStages: cloneDeep(this.state.processStages),
                }),
        );
    };

    filterProcessStages = (processStageInput: string): void => {
        if (processStageInput === '') {
            this.setState({ filteredProcessStages: cloneDeep(this.state.processStages) });
        } else {
            this.setState({
                filteredProcessStages: cloneDeep(
                    this.state.processStages.filter(processStage =>
                        processStage.text.toLowerCase().includes(processStageInput.toLowerCase()),
                    ),
                ),
            });
        }
    };

    handleProcessStageSearch = (confirm: Function) => {
        confirm();
        this.setState({
            filteredValues: {
                ...this.state.filteredValues,
                processStageFilters: this.state.processStages
                    .filter(processStage => processStage.checked)
                    .map(processStage => processStage.text),
            },
            repopulateData: true,
        });
    };

    handleChange = (pagination: any, filters: any, sorter: any) => {
        this.setState(
            {
                filteredValues: {
                    ...this.state.filteredValues,
                    materialNameFilter: filters.name,
                    materialNumberFilter: filters.number,
                },
                repopulateData: true,
            },
            () => {
                const processStages = this.processStageFilters().reduce(
                    (
                        stages: Array<{ text: string; value: string; checked: boolean }>,
                        stage: { text: string; value: string },
                    ) => {
                        const processStage = find(this.state.processStages, ['value', stage.value]);
                        if (!processStage) {
                            stages.push({
                                ...stage,
                                checked: false,
                            });
                        } else {
                            stages.push({
                                ...stage,
                                checked: processStage.checked,
                            });
                        }
                        return stages;
                    },
                    [],
                );
                this.setState({
                    processStages: processStages,
                    filteredProcessStages: cloneDeep(processStages),
                    filteredValues: {
                        ...this.state.filteredValues,
                        processStageFilters: processStages.reduce(
                            (stages: Array<string>, stage: { text: string; value: string; checked: boolean }) => {
                                if (stage.checked) {
                                    stages.push(stage.text);
                                }
                                return stages;
                            },
                            [],
                        ),
                    },
                });
            },
        );
    };

    quickSelect = () => (
        <Button
            onClick={e => {
                e.stopPropagation();
                this.setState({ modalVisible: true });
            }}
        >
            Quick Select
        </Button>
    );

    closeModal = () => {
        this.setState({ modalVisible: false });
        this.onSelectChange(this.state.quickSelectRowKeys);
    };
    updateImpactedMaterials = (value: boolean) => {
        this.setState({ areImpactedMaterialsMissing: value });
        if(value == false) { 
            let updatedMaterials = this.props.userSelectedMaterials.filter(e => e.typeCode != 'E'); 
            this.setState({ selectedRowKeys: [], unselectedExternalMaterials: [], userSelectedExternalMaterials: updatedMaterials }, () => {
                this.props.updateUserSelectedMaterialProps(updatedMaterials);
            });
        }
        if (this.props.updateImpactedMaterialsOption) {
            this.props.updateImpactedMaterialsOption(value);
        }
        
        if (this.props.areImpactedMaterialsMissing) {
            this.props.areImpactedMaterialsMissing(value);
        }
    }

    handleVisible = (visible: boolean) => {
        this.setState({ setPopupVisible: visible });
        if (!visible) {
            this.setState({ setHovering: false });
        }
    };

    onSelectRowsHandler = (rows: any) => {
        this.props.onSelectRows?.(rows);

        const materialData = rows['selectedMaterialRowKeys'];
        const productData = rows['selectedProductRowKeys'];

        const materialArray = materialData !== undefined && materialData.length > 0 ? this.onSelectUpdateMaterialsState(materialData) : '';
        const productArray = productData !== undefined && productData.length > 0 ? this.onSelectUpdateProductState(productData) : '';

        //console.log('Impacted materials 783 >>> ', this.state.userSelectedExternalMaterials);
        //console.log('Impacted materials 783 00 >>> ', this.props.userSelectedMaterials);

        const externalMaterialArray: Array<SelectedMaterials> = [];
        if (this.state.userSelectedExternalMaterials.length > 0) {
            let material: SelectedMaterials | undefined;
            //console.log('Impacted materials 783 11 >>> ', this.state.userSelectedExternalMaterials);
            this.state.userSelectedExternalMaterials.map((data: SelectedMaterials) => {
                material = findObjectByValue(this.props.userSelectedMaterials, 'number', data.number);
                if (material && material.typeCode === 'E')
                    externalMaterialArray.push(material);
                else if(data.typeCode === 'E')
                    externalMaterialArray.push(data);
            })
        }
        else if (this.props.userSelectedMaterials.length > 0) {
            //console.log('Impacted materials 783 11 11 >>> ', this.props.userSelectedMaterials);
            this.props.userSelectedMaterials.map((data: SelectedMaterials) => {
                if (data.typeCode === 'E')
                    externalMaterialArray.push(data);
            })
        }
        
        const userSelectedArray: any = []
        userSelectedArray.push(materialArray);
        userSelectedArray.push(productArray);
        if (externalMaterialArray.length > 0) {
            //console.log('Impacted materials 783 222>>> ', externalMaterialArray);
            userSelectedArray.push(externalMaterialArray);
        }
        const mappedUserSelectedItems: any = [];
        userSelectedArray.forEach((item: Array<SelectedMaterials>) => { if (!objectExistsInArray(mappedUserSelectedItems, 'number', item)) mappedUserSelectedItems.push(...item) });
        //console.log('Selected value >> 827 ', mappedUserSelectedItems)
        this.props.updateUserSelectedMaterialProps(mappedUserSelectedItems);

        // this.setState({ userSelectedMaterials: mappedUserSelectedItems });
    }

    onSelectUpdateMaterialsState = (rows: any): Array<SelectedMaterials> => {
        let selectedMat: Array<SelectedMaterials> = [];
        if (rows.length > 0) {
            rows.forEach((matNum: any) => {
                let material: Materials | undefined;
                let index: number;
                let userSelectedMat: SelectedMaterials | undefined;


                userSelectedMat = findObjectByValue(this.props.userSelectedMaterials, 'number', matNum);
                material = findObjectByValue(this.props.cpws.materials, 'material_number', matNum);

                if (material) {
                    index = findObjectIndexByValue(this.props.cpws.materials, 'material_number', matNum);
                    //console.log("index in impMat 868", index)
                    if (index > -1) {
                        const value: SelectedMaterials = {
                            number: material['material_number'],
                            name: material['material_name'],
                            typeCode: 'M',
                            type: userSelectedMat ? userSelectedMat.type : [],
                            downstreamMaterials: userSelectedMat ? userSelectedMat.downstreamMaterials : [],
                            classification: userSelectedMat ? userSelectedMat.classification : {},
                        };
                        selectedMat.push(value);
                    }
                }
            });
        }


        return selectedMat;
    }

    onSelectUpdateProductState = (rows: any): Array<SelectedMaterials> => {
        let selectedProduct: Array<SelectedMaterials> = [];
        if (rows.length > 0) {

            rows.forEach((matNum: any) => {
                let material: ProductsInfo | undefined;
                let index: number;
                let userSelectedProduct: SelectedMaterials | undefined;


                userSelectedProduct = findObjectByValue(this.props.userSelectedMaterials, 'number', matNum);


                material = findObjectByValue(this.props.cpws.productInformation.products, 'product_number', matNum);

                if (material) {
                    index = findObjectIndexByValue(this.props.cpws.productInformation.products, 'product_number', matNum);

                    if (index > -1 && findObjectIndexByValue(selectedProduct, 'number', matNum) == -1) {
                        const value: SelectedMaterials = {
                            number: material['product_number'],
                            name: material['product_name'],
                            typeCode: 'P',
                            type: userSelectedProduct ? userSelectedProduct.type : [],
                            downstreamMaterials: userSelectedProduct ? userSelectedProduct.downstreamMaterials : [],
                            classification: userSelectedProduct ? userSelectedProduct.classification : {},
                        };
                        selectedProduct.push(value);
                    }
                }
            });
        }
        return selectedProduct;
    }

    onSelectUpdateExternalMaterialData = (row: any): SelectedMaterials => {

        let material: ExternalMaterial | undefined;
        let value: SelectedMaterials = { name: '', number: '', typeCode: '', type: [], downstreamMaterials: [], classification: {} };

        let userSelectedExternalMat: SelectedMaterials | undefined;


        userSelectedExternalMat = findObjectByValue(this.props.userSelectedMaterials, 'name', row);
        material = findObjectByValue(this.state.unselectedExternalMaterials, 'name', row);
        //console.log("Material in impMat 904", material);
        if (material && material != undefined) {
            value = {
                number: material['number'],
                name: material['name'],
                typeCode: 'E',
                type: userSelectedExternalMat ? userSelectedExternalMat.type : [],
                downstreamMaterials: userSelectedExternalMat ? userSelectedExternalMat.downstreamMaterials : [],
                classification: userSelectedExternalMat ? userSelectedExternalMat.classification : {},
            };
        }
        //console.log('SelectedMat external >>> ', value);
        return value;
        
    }

    render() {
        const { selectedRowKeys } = this.state;
        //console.log('line 855 >>> ', selectedRowKeys)
        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys: any) => {
                if (this.props.updateCurrentTab) {
                    this.props.updateCurrentTab('ipiMaterials');
                }
                this.onSelectChange(selectedRowKeys);
                this.setRepopulateDataFlag(true);
            },
        };

        const selectProps: any = {};
        if (this.props.materialEvents) {
            selectProps.onDeselect = this.props.materialEvents.onDeselect;
            selectProps.onSelect = this.props.materialEvents.onSelect;

        } else {
            selectProps.disabled = true;
        }

        const impactedMaterials = [
            {
                title: 'Name',
                dataIndex: 'name',
            },
            {
                title: 'Removed from list',
                dataIndex: 'material_number',
                render: (text: any, record: ExternalMaterial) =>
                    <FormContext.Consumer>
                        {({ viewOnly }) => (
                            <>
                                <Popconfirm
                                    title={`Are you sure you want to delete: ${record.name}`}
                                    onConfirm={e => {
                                        this.deleteItem(record);
                                        this.setState({ setHovering: false });
                                    }}
                                    okText="Delete"
                                    cancelText="Cancel"
                                    placement="topRight"
                                    onVisibleChange={this.handleVisible}
                                    onCancel={() => this.setState({ setHovering: false })}
                                    disabled={(viewOnly || this.props.viewOnly === true)}
                                >
                                    <DeleteOutlined
                                        className={(viewOnly || this.props.viewOnly === true) ? 'view-only' : 'material-dropdown-icon material-dropdown-icon-trash'}
                                        title="delete"
                                        onClick={e => e.stopPropagation()}
                                    />
                                </Popconfirm>
                            </>
                        )}
                    </FormContext.Consumer>,
                width: '40%',
            },
        ];
        let locale = {
            emptyText: "No Materials Added"
        }

        return (
            <FormContext.Consumer>
                {({ materialSelected, impactedMaterialsMissing, impactedMaterialsMissingWithYes, viewOnly }) => (
                    <div className={(viewOnly || this.props.viewOnly === true) ? 'view-only' : ''}>
                    {!materialSelected ? (
                        <FormValidationErrorText text="Please select at least one impacted material" />
                    ) : null}
                    <div className={!materialSelected ? 'select-highlight' : ''}>
                        <IPIMaterials
                            cpws={this.props.cpws}
                            onSelectRows={this.onSelectRowsHandler}
                            userSelectedMaterials={this.props.userSelectedMaterials}
                            updateCurrentTab= {this.props.updateCurrentTab}
                        />
                        {!impactedMaterialsMissing ? (
                        <FormValidationErrorText text="Please select an option below" />
                    ) : null}
                    <div className={!impactedMaterialsMissing ? 'select-highlight' : ''}>
                        <Form labelCol={{ span: 12 }} wrapperCol={{ span: 24 }} >
                            <Form.Item style={{}} label="Are Impacted Materials(s) missing from the list? " name="radio" rules={[{ required: true, message: "Please select an option" }]}>
                                <Radio.Group defaultValue={this.props.impactedMaterialsAvailableOption} onChange={e => this.updateImpactedMaterials(e.target.value === 'Yes')}>
                                    <Radio value={'Yes'} disabled={(viewOnly || this.props.viewOnly === true)}>Yes</Radio>
                                    <Radio value={'No'} disabled={(viewOnly || this.props.viewOnly === true)}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Form>
                    </div>
                    {!impactedMaterialsMissingWithYes ? (
                        <FormValidationErrorText text="Please Add External Materials below or Choose 'No' Option above" />
                    ) : null}
                    <div className={!impactedMaterialsMissingWithYes ? 'select-highlight' : ''}>
                        {this.props.impactedMaterialsAvailable.length > 0 ?
                            <Row gutter={16} style={{ justifyContent: 'center' }}>
                                <Col span={16}>
                                    <h4 style={{ textAlign: 'center' }}> Materials</h4>
                                    <Table
                                        dataSource={[...this.state.unselectedExternalMaterials]}
                                        columns={impactedMaterials}
                                        rowSelection={
                                            (viewOnly || this.props.viewOnly === true)
                                                ? {
                                                    ...rowSelection,
                                                    getCheckboxProps: () => {
                                                        return { disabled: true };
                                                    },
                                                }
                                                : rowSelection
                                        }
                                        onChange={this.handleChange}
                                        locale={locale}
                                        scroll={{ y: 250 }}
                                        rowKey={(record: ExternalMaterial) => record.name}
                                        bordered

                                    />
                                </Col>
                            </Row>

                            : ''}
                        <div style={{ marginTop: "10px" }}>
                            {this.state.areImpactedMaterialsMissing ? this.manualMaterials((viewOnly || this.props.viewOnly === true)) : ''}
                        </div>
                        </div>
                        </div>                    
                    </div>
                )}
            </FormContext.Consumer>
        );
    }
}
