import {
    Card,
    Progress,
    Result,
    Spin,
    Typography,
    Upload,
    Collapse,
    Input,
    List,
    Row,
    Col,
    notification,
    Button,
    Tabs,
    Table,
    Popover,
} from 'antd';
import { UserAddOutlined, AimOutlined } from '@ant-design/icons';
import {
    CPWS,
    ProductModality,
    Site,
    ProductEvents,
    SiteEvents,
    GlobalProps,
    RegulatoryQuestions,
    ProductModalitySelections,
    FormRefs,
    SiteFunction,
    ColorTag,
    Actions,
    ImpactAnalysis,
    RelatedChanges,
    Plants,
    Suppliers,
    Country,
    RegisteredMarkets,
    ProductInformation,
    ProductsInfo,
    DirectAssessment,
    DataRequirements,
    Partners,
} from '../model';

import React from 'react';
import { DirectionalAssessment } from '../components/DirectionalAssessment';
const { Text } = Typography;
const { TextArea } = Input;
import './components.css';
const { Panel } = Collapse;
const { TabPane } = Tabs;
import _ from 'lodash';

export interface AssessmentSummariesProps {    
    dataRequirements: DataRequirements;
    directionalAssessment: DirectAssessment;
    impactedCountries: Array<Country>;    
    loading: boolean;
}

const batchAnalysisColumns = [
    {
        title: 'Product Type',
        dataIndex: 'product_type',
    },
    {
        title: 'Batch Type',
        dataIndex: 'batch_type',
    },
    {
        title: 'Number of Batches',
        dataIndex: 'number_of_batches',
    },
    {
        title: 'Regulations',
        dataIndex: 'change_types',
        render: (data: Array<string>) => {
            return data.join('; ')
        }
    },
]; 
const comparitiveDissolutionColumns = [
    {
        title: 'Number of Lots',
        dataIndex: 'number_of_lots',
    },
    {
        title: 'Media Type',
        dataIndex: 'media_type',
    },    
];
const stabilityColumns = [
    {
        title: 'Stability Type',
        dataIndex: 'stability_type',
    },
    {
        title: 'Number of Lots',
        dataIndex: 'number_of_lots',
    },
];


export const AssessmentSummaries: React.FC<AssessmentSummariesProps> = (props: AssessmentSummariesProps) => {
    //: React.FC<ChangeInformationProps> = (props: ChangeInformationProps) => {
    // const { currentChange, proposedChange } = props;

    const { dataRequirements, directionalAssessment, loading, impactedCountries } = props;
    //console.log("TTT", JSON.stringify(directionalAssessment, null, 2));
    
    const displayEmptyInfo = (title: string) => {
        return (
            <div style={{textAlign: "center"}}>
                <Text><i><b>{title}</b> is not required for this change.</i></Text>
            </div>
        )
    }

    const getQuestions = (data: Array<string>) => {              
        return (
            <div style={{"height":"80px", "overflowY":"auto"}}>
                <ul>
                    {data.map(listitem => (
                        <li key={listitem}>{listitem}</li>
                    ))}                      
                </ul>
            </div>
        )
        //console.log(quesArray);
    }
    
    return (
        <Card bordered={false}>
            <Collapse defaultActiveKey={1} expandIconPosition="right">
                <Panel header="Assessment Summaries" key="1">
                    {props.loading === true ? (
                        <Spin size="default" className="loading-spin" />
                    ) :
                    <div>                    
                    <Tabs defaultActiveKey="dir_ass" type="card">
                    {((directionalAssessment.B != undefined && directionalAssessment.B.majorMarkets.total.length > 0) ||
                    (directionalAssessment.SM != undefined && directionalAssessment.SM.majorMarkets.total.length > 0) ||
                    (directionalAssessment.V != undefined && directionalAssessment.V.majorMarkets.total.length > 0)) ? 	
                        <TabPane tab="Directional Assessment" key="dir_ass">                            
                            <Card bordered={false}>
                                <Tabs type="card">
                                    {directionalAssessment.B != undefined && directionalAssessment.B.majorMarkets.total.length > 0 ?
                                    <TabPane tab="Biologic" key="dir_ass_B">
                                        <Card bordered={false}>                                        
                                            <Row>                                                
                                                <DirectionalAssessment
                                                    majorMarkets={directionalAssessment.B.majorMarkets}
                                                    secondaryMarkets={directionalAssessment.B.secondaryMarkets}
                                                    countries={impactedCountries}
                                                    loading={props.loading}
                                                />
                                            </Row>
                                        </Card>
                                    </TabPane>
                                     : null
                                    }
                                    {directionalAssessment.SM != undefined && directionalAssessment.SM.majorMarkets.total.length > 0  ?
                                    <TabPane tab="Small Molecule" key="dir_ass_SM">
                                        <Card bordered={false}>
                                            <Row>
                                                
                                                <DirectionalAssessment
                                                    majorMarkets={directionalAssessment.SM.majorMarkets}
                                                    secondaryMarkets={directionalAssessment.SM.secondaryMarkets}
                                                    countries={impactedCountries}
                                                />
                                            </Row>                                            
                                        </Card>
                                    </TabPane>
                                     : null
                                    }
                                    {directionalAssessment.V != undefined && directionalAssessment.V.majorMarkets.total.length > 0  ?
                                    <TabPane tab="Vaccine" key="dir_ass_V">
                                        <Card bordered={false}>
                                            <Row>                                                
                                                <DirectionalAssessment
                                                    majorMarkets={directionalAssessment.V.majorMarkets}
                                                    secondaryMarkets={directionalAssessment.V.secondaryMarkets}
                                                    countries={impactedCountries}
                                                />
                                            </Row>                                            
                                        </Card>
                                    </TabPane>
                                     : null
                                    }
                                </Tabs>
                            </Card>
                        </TabPane>
                        : ""
                        }
                        {!_.isEmpty(dataRequirements) ?
                        <TabPane tab="Data Requirements" key="data_req">                            
                            <Card bordered={false}>
                                <Tabs type="card">
                                    {dataRequirements.B != undefined ?
                                    <TabPane tab="Biologic" key="data_req_B">
                                            <Card bordered={false}>                                             
                                                <Tabs defaultActiveKey="B_batch_analysis" type="card">
                                                    
                                                    <TabPane tab="Batch Analysis" key="B_batch_analysis" style={{padding:"10px 25px"}}>
                                                    {dataRequirements.B.batch_analysis.length > 0 ?
                                                        <Table
                                                            dataSource={dataRequirements.B.batch_analysis}
                                                            columns={batchAnalysisColumns}
                                                            pagination={false}                                                            
                                                            bordered
                                                        /> : null
                                                    }
                                                    {dataRequirements.B.batch_analysis.length == 0 && dataRequirements.B.questions_without_data_requirements != undefined && 
                                                     dataRequirements.B.questions_without_data_requirements.length == 0 ?
                                                            displayEmptyInfo("Batch Analysis")
                                                        : <div></div> 
                                                    }
                                                    </TabPane>
                                                    
                                                    <TabPane tab="Bioequivalence Study" key="V_bio_study">
                                                    {dataRequirements.B.bioequivalence_study.length > 0 ?
                                                        <div style={{textAlign: "center", padding: "15px"}}>
                                                        {dataRequirements.B.bioequivalence_study.map((itm: any, index: number)=> {                                                                
                                                            if(index == 0 && itm.biowaiver_accepted == 'N') {                                                                    
                                                                return <Text>Biowaiver Acceptanace - <b>Not Accepted</b></Text>
                                                            } else if(index == 0 && itm.biowaiver_accepted == 'Y') {
                                                                return <Text>Biowaiver Acceptanace - <b>Accepted</b></Text>
                                                            }                                                            
                                                        })}                                                        
                                                        </div> : null
                                                    }
                                                    {dataRequirements.B.bioequivalence_study.length == 0 && dataRequirements.B.questions_without_data_requirements != undefined && 
                                                     dataRequirements.B.questions_without_data_requirements.length == 0 ?
                                                            displayEmptyInfo("Bioequivalence Study")
                                                        : <div></div> 
                                                    }
                                                    </TabPane>
                                                    
                                                    <TabPane tab="Comparative Dissolution" key="B_comp_dis">
                                                    {dataRequirements.B.comparative_dissolution.length > 0 ?
                                                            <Table
                                                                dataSource={dataRequirements.B.comparative_dissolution}
                                                                columns={comparitiveDissolutionColumns}
                                                                style={{width:"400px", margin:"0px auto"}}
                                                                pagination={false}                                                            
                                                                bordered
                                                            /> : null
                                                    }
                                                    {dataRequirements.B.comparative_dissolution.length == 0 && dataRequirements.B.questions_without_data_requirements != undefined && 
                                                     dataRequirements.B.questions_without_data_requirements.length == 0 ?
                                                            displayEmptyInfo("Comparative Dissolution")
                                                        : <div></div> 
                                                    }
                                                    </TabPane>
                                                    
                                                    <TabPane tab="Product/Packaging Interaction" key="B_prod_pack">
                                                    {dataRequirements.B.product_packaging_interaction.length > 0 ?
                                                        <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                                            <Text><b>Product/Packaging Interaction Types</b></Text>
                                                            <div style={{paddingTop:"20px"}}>
                                                                {
                                                                    dataRequirements.B.product_packaging_interaction.map((itm: any) => {
                                                                        return <Text key={itm.type_of_interaction} style={{paddingRight:"25px"}}>{itm.type_of_interaction}</Text>
                                                                    })
                                                                }
                                                            </div>                                                            
                                                        </div> : null
                                                    }
                                                    {dataRequirements.B.product_packaging_interaction.length == 0 && dataRequirements.B.questions_without_data_requirements != undefined && 
                                                     dataRequirements.B.questions_without_data_requirements.length == 0 ?
                                                            displayEmptyInfo("Product/Packaging Interaction")
                                                        : <div></div> 
                                                    }
                                                    </TabPane>
                                                    
                                                    <TabPane tab="Stability" key="B_stab">
                                                    {(dataRequirements.B.dp_stability.length > 0 || dataRequirements.B.dp_intermediate_stability.length > 0 ||
                                                      dataRequirements.B.ds_stability.length > 0 || dataRequirements.B.ds_intermediate_stability.length > 0) ?
                                                      <div>
                                                        {dataRequirements.B.dp_stability.length > 0 ?
                                                            <div style={{textAlign: "center", marginTop: "20px"}}>
                                                                <Text><b>Drug Product Stability</b></Text>
                                                                <Table
                                                                    dataSource={dataRequirements.B.dp_stability}
                                                                    columns={stabilityColumns}
                                                                    style={{width:"400px", margin:"15px auto"}}
                                                                    pagination={false}                                                            
                                                                    bordered
                                                                />
                                                            </div> : <div></div>
                                                        }
                                                        {dataRequirements.B.dp_intermediate_stability.length > 0 ?
                                                            <div style={{textAlign: "center", marginTop: "20px"}}>
                                                                <Text><b>Drug Product Intermediate Stability</b></Text>
                                                                <Table
                                                                    dataSource={dataRequirements.B.dp_intermediate_stability}
                                                                    columns={stabilityColumns}
                                                                    style={{width:"400px", margin:"15px auto"}}
                                                                    pagination={false}                                                            
                                                                    bordered
                                                                />
                                                            </div> : <div></div>
                                                        }
                                                        {dataRequirements.B.ds_stability.length > 0 ?
                                                            <div style={{textAlign: "center", marginTop: "20px"}}>
                                                                <Text><b>Drug Substance Stability</b></Text>
                                                                <Table
                                                                    dataSource={dataRequirements.B.ds_stability}
                                                                    columns={stabilityColumns}
                                                                    style={{width:"400px", margin:"15px auto"}}
                                                                    pagination={false}                                                            
                                                                    bordered
                                                                />
                                                            </div> : <div></div>
                                                        }
                                                        {dataRequirements.B.ds_intermediate_stability.length > 0 ?
                                                            <div style={{textAlign: "center", marginTop: "20px"}}>
                                                                <Text><b>Drug Substance Intermediate Stability</b></Text>
                                                                <Table
                                                                    dataSource={dataRequirements.B.ds_intermediate_stability}
                                                                    columns={stabilityColumns}
                                                                    style={{width:"400px", margin:"15px auto"}}
                                                                    pagination={false}                                                            
                                                                    bordered
                                                                />
                                                            </div> : <div></div>
                                                        }
                                                       </div>  : null
                                                    }
                                                    {dataRequirements.B.dp_stability.length == 0 && dataRequirements.B.dp_intermediate_stability.length == 0 &&
                                                     dataRequirements.B.ds_stability.length == 0 && dataRequirements.B.ds_intermediate_stability.length == 0 &&
                                                     dataRequirements.B.questions_without_data_requirements != undefined && dataRequirements.B.questions_without_data_requirements.length == 0 ?
                                                            displayEmptyInfo("Staging")
                                                        : <div></div> 
                                                    }
                                                    </TabPane>
                                                    
                                                    <TabPane tab="Transportation/Storage" key="B_trans_storage">
                                                    {dataRequirements.B.transportation_storage_conditions.length > 0 ?
                                                        <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                                            <Text><b>Transportation/Storage Condition Types</b></Text>
                                                            <div style={{paddingTop:"20px"}}>
                                                                {
                                                                    dataRequirements.B.transportation_storage_conditions.map((itm: any) => {
                                                                        return <Text key={itm.type_of_conditions} style={{paddingRight:"25px"}}>{itm.type_of_conditions}</Text>
                                                                    })
                                                                }
                                                            </div>
                                                        </div> : null
                                                    }
                                                    {dataRequirements.B.transportation_storage_conditions.length == 0 && dataRequirements.B.questions_without_data_requirements != undefined && 
                                                     dataRequirements.B.questions_without_data_requirements.length == 0 ?
                                                            displayEmptyInfo("Transportation/Storage")
                                                        : <div></div> 
                                                    }
                                                    </TabPane>
                                                    
                                                    <TabPane tab="Validation" key="B_validation">
                                                    {dataRequirements.B.validation.length > 0 ?    
                                                        <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                                            <Text><b>Validation Types</b></Text>
                                                            <div style={{paddingTop:"20px"}}>
                                                                {
                                                                    dataRequirements.B.validation.map((itm: any) => {
                                                                        return <Text key={itm.data_requirements} style={{paddingRight:"25px"}}>{itm.data_requirements}</Text>
                                                                    })
                                                                }
                                                            </div>
                                                        </div> : null
                                                    }
                                                    {dataRequirements.B.validation.length == 0 && dataRequirements.B.questions_without_data_requirements != undefined && 
                                                     dataRequirements.B.questions_without_data_requirements.length == 0 ?
                                                            displayEmptyInfo("Validation")
                                                        : <div></div> 
                                                    }
                                                    </TabPane>
                                                </Tabs>
                                                {dataRequirements.B.questions_without_data_requirements != undefined && dataRequirements.B.questions_without_data_requirements.length > 0 ?
                                                <div style={{paddingTop: "20px"}}>
                                                    <Text>CAKE database does not include data requirements for the following Regulatory impact assessment questions and further assessment is required.</Text>   
                                                    {getQuestions(dataRequirements.B.questions_without_data_requirements)}
                                                </div>
                                                 : ""
                                                }
                                            </Card>
                                    </TabPane>
                                     : null
                                    }
                                    {dataRequirements.SM != undefined ?
                                    <TabPane tab="Small Molecule" key="data_req_SM">
                                        <Card bordered={false}>
                                            <Tabs defaultActiveKey="SM_batch_analysis" type="card">
                                                
                                                <TabPane tab="Batch Analysis" key="SM_batch_analysis" style={{padding:"10px 25px"}}>
                                                {dataRequirements.SM.batch_analysis.length > 0 ?    
                                                    <Table
                                                        dataSource={dataRequirements.SM.batch_analysis}
                                                        columns={batchAnalysisColumns}
                                                        pagination={false}                                                            
                                                        bordered
                                                    /> : null
                                                }
                                                {dataRequirements.SM.batch_analysis.length == 0 && dataRequirements.SM.questions_without_data_requirements != undefined && 
                                                 dataRequirements.SM.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Batch Analysis")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Bioequivalence Study" key="SM_bio_study">
                                                {dataRequirements.SM.bioequivalence_study.length > 0 ?    
                                                    <div style={{textAlign: "center", padding: "15px"}}>
                                                    {dataRequirements.SM.bioequivalence_study.map((itm: any, index: number)=> {                                                                
                                                        if(index == 0 && itm.biowaiver_accepted == 'N') {                                                                    
                                                            return <Text>Biowaiver Acceptanace - <b>Not Accepted</b></Text>
                                                        } else if(index == 0 && itm.biowaiver_accepted == 'Y') {
                                                            return <Text>Biowaiver Acceptanace - <b>Accepted</b></Text>
                                                        }
                                                        
                                                    })}                                                        
                                                    </div> : null
                                                }
                                                {dataRequirements.SM.bioequivalence_study.length == 0 && dataRequirements.SM.questions_without_data_requirements != undefined && 
                                                 dataRequirements.SM.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Bioequivalence Study")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Comparative Dissolution" key="SM_comp_dis">
                                                {dataRequirements.SM.comparative_dissolution.length > 0 ?    
                                                        <Table
                                                            dataSource={dataRequirements.SM.comparative_dissolution}
                                                            columns={comparitiveDissolutionColumns}
                                                            style={{width:"400px", margin:"0px auto"}}
                                                            pagination={false}                                                            
                                                            bordered
                                                        /> : null
                                                }
                                                {dataRequirements.SM.comparative_dissolution.length == 0 && dataRequirements.SM.questions_without_data_requirements != undefined && 
                                                 dataRequirements.SM.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Comparative Dissolution")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Product/Packaging Interaction" key="SM_prod_pack">
                                                {dataRequirements.SM.product_packaging_interaction.length > 0 ?    
                                                    <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                                        <Text><b>Product/Packaging Interaction Types</b></Text>
                                                        <div style={{paddingTop:"20px"}}>
                                                            {
                                                                dataRequirements.SM.product_packaging_interaction.map((itm: any) => {
                                                                    return <Text key={itm.type_of_interaction} style={{paddingRight:"25px"}}>{itm.type_of_interaction}</Text>
                                                                })
                                                            }
                                                        </div>                                                            
                                                    </div> : null
                                                }
                                                {dataRequirements.SM.product_packaging_interaction.length == 0 && dataRequirements.SM.questions_without_data_requirements != undefined && 
                                                 dataRequirements.SM.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Product/Packaging Interaction")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Stability" key="SM_stab">
                                                {(dataRequirements.SM.dp_stability.length > 0 || dataRequirements.SM.dp_intermediate_stability.length > 0 ||
                                                    dataRequirements.SM.ds_stability.length > 0 || dataRequirements.SM.ds_intermediate_stability.length > 0) ?    
                                                    <div>
                                                    {dataRequirements.SM.dp_stability.length > 0 ?
                                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                                            <Text><b>Drug Product Stability</b></Text>
                                                            <Table
                                                                dataSource={dataRequirements.SM.dp_stability}
                                                                columns={stabilityColumns}
                                                                style={{width:"400px", margin:"15px auto"}}
                                                                pagination={false}                                                            
                                                                bordered
                                                            />
                                                        </div> : <div></div>
                                                    }
                                                    {dataRequirements.SM.dp_intermediate_stability.length > 0 ?
                                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                                            <Text><b>Drug Product Intermediate Stability</b></Text>
                                                            <Table
                                                                dataSource={dataRequirements.SM.dp_intermediate_stability}
                                                                columns={stabilityColumns}
                                                                style={{width:"400px", margin:"15px auto"}}
                                                                pagination={false}                                                            
                                                                bordered
                                                            />
                                                        </div> : <div></div>
                                                    }
                                                    {dataRequirements.SM.ds_stability.length > 0 ?
                                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                                            <Text><b>Drug Substance Stability</b></Text>
                                                            <Table
                                                                dataSource={dataRequirements.SM.ds_stability}
                                                                columns={stabilityColumns}
                                                                style={{width:"400px", margin:"15px auto"}}
                                                                pagination={false}                                                            
                                                                bordered
                                                            />
                                                        </div> : <div></div>
                                                    }
                                                    {dataRequirements.SM.ds_intermediate_stability.length > 0 ?
                                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                                            <Text><b>Drug Substance Intermediate Stability</b></Text>
                                                            <Table
                                                                dataSource={dataRequirements.SM.ds_intermediate_stability}
                                                                columns={stabilityColumns}
                                                                style={{width:"400px", margin:"15px auto"}}
                                                                pagination={false}                                                            
                                                                bordered
                                                            />
                                                        </div> : <div></div>
                                                    }
                                                    </div> : null
                                                }
                                                {dataRequirements.SM.dp_stability.length == 0 && dataRequirements.SM.dp_intermediate_stability.length == 0 && 
                                                 dataRequirements.SM.ds_stability.length == 0 && dataRequirements.SM.ds_intermediate_stability.length == 0 && 
                                                 dataRequirements.SM.questions_without_data_requirements != undefined && dataRequirements.SM.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Stability")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Transportation/Storage" key="SM_trans_storage">
                                                {dataRequirements.SM.transportation_storage_conditions.length > 0 ?    
                                                    <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                                        <Text><b>Transportation/Storage Condition Types</b></Text>
                                                        <div style={{paddingTop:"20px"}}>
                                                            {
                                                                dataRequirements.SM.transportation_storage_conditions.map((itm: any) => {
                                                                    return <Text key={itm.type_of_conditions} style={{paddingRight:"25px"}}>{itm.type_of_conditions}</Text>
                                                                })
                                                            }
                                                        </div>
                                                    </div> : null
                                                }
                                                {dataRequirements.SM.transportation_storage_conditions.length == 0 && dataRequirements.SM.questions_without_data_requirements != undefined && 
                                                 dataRequirements.SM.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Transportation/Storage")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Validation" key="SM_validation">
                                                {dataRequirements.SM.validation.length > 0 ?
                                                    <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                                        <Text><b>Validation Types</b></Text>
                                                        <div style={{paddingTop:"20px"}}>
                                                            {
                                                                dataRequirements.SM.validation.map((itm: any) => {
                                                                    return <Text key={itm.validation_type} style={{paddingRight:"25px"}}>{itm.validation_type}</Text>
                                                                })
                                                            }
                                                        </div>
                                                    </div> : null
                                                }
                                                {dataRequirements.SM.validation.length == 0 && dataRequirements.SM.questions_without_data_requirements != undefined && 
                                                 dataRequirements.SM.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Validation")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                            </Tabs>                                           
                                            {dataRequirements.SM.questions_without_data_requirements != undefined && dataRequirements.SM.questions_without_data_requirements.length > 0 ?
                                                <div style={{paddingTop: "20px"}}>
                                                    <Text>CAKE database does not include data requirements for the following Regulatory impact assessment questions and further assessment is required.</Text>   
                                                    {getQuestions(dataRequirements.SM.questions_without_data_requirements)}
                                                </div>
                                                : ""
                                            }
                                        </Card>
                                    </TabPane>
                                    : null
                                    }
                                    {dataRequirements.V != undefined ?
                                    <TabPane tab="Vaccine" key="data_req_V">
                                        <Card bordered={false}>
                                            <Tabs defaultActiveKey="V_batch_analysis" type="card">
                                                
                                                <TabPane tab="Batch Analysis" key="V_batch_analysis" style={{padding:"10px 25px"}}>
                                                {dataRequirements.V.batch_analysis.length > 0 ?    
                                                    <Table
                                                        dataSource={dataRequirements.V.batch_analysis}
                                                        columns={batchAnalysisColumns}
                                                        pagination={false}                                                            
                                                        bordered
                                                    /> : null
                                                }
                                                {dataRequirements.V.batch_analysis.length == 0 && dataRequirements.V.questions_without_data_requirements != undefined && 
                                                 dataRequirements.V.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Batch Analysis")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Bioequivalence Study" key="V_bio_study">
                                                {dataRequirements.V.bioequivalence_study.length > 0 ?    
                                                    <div style={{textAlign: "center", padding: "15px"}}>
                                                    {dataRequirements.V.bioequivalence_study.map((itm: any, index: number)=> {                                                                
                                                        if(index == 0 && itm.biowaiver_accepted == 'N') {                                                                    
                                                            return <Text>Biowaiver Acceptanace - <b>Not Accepted</b></Text>
                                                        } else if(index == 0 && itm.biowaiver_accepted == 'Y') {
                                                            return <Text>Biowaiver Acceptanace - <b>Accepted</b></Text>
                                                        }
                                                        
                                                    })}                                                        
                                                    </div> : null
                                                }
                                                {dataRequirements.V.bioequivalence_study.length == 0 && dataRequirements.V.questions_without_data_requirements != undefined && 
                                                 dataRequirements.V.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Bioequivalence Study")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Comparative Dissolution" key="V_comp_dis">
                                                {dataRequirements.V.comparative_dissolution.length > 0 ?
                                                    <Table
                                                        dataSource={dataRequirements.V.comparative_dissolution}
                                                        columns={comparitiveDissolutionColumns}
                                                        style={{width:"400px", margin:"0px auto"}}
                                                        pagination={false}                                                            
                                                        bordered
                                                    /> : null
                                                }
                                                {dataRequirements.V.comparative_dissolution.length == 0 && dataRequirements.V.questions_without_data_requirements != undefined && 
                                                 dataRequirements.V.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Comparative Dissolution")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Product/Packaging Interaction" key="V_prod_pack">
                                                {dataRequirements.V.product_packaging_interaction.length > 0 ?    
                                                    <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                                        <Text><b>Product/Packaging Interaction Types</b></Text>
                                                        <div style={{paddingTop:"20px"}}>
                                                            {
                                                                dataRequirements.V.product_packaging_interaction.map((itm: any) => {
                                                                    return <Text key={itm.type_of_interaction} style={{paddingRight:"25px"}}>{itm.type_of_interaction}</Text>
                                                                })
                                                            }
                                                        </div>                                                            
                                                    </div> : null
                                                }
                                                {dataRequirements.V.product_packaging_interaction.length == 0 && dataRequirements.V.questions_without_data_requirements != undefined && 
                                                 dataRequirements.V.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Product/Packaging Interaction")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Stability" key="V_stab">
                                                {(dataRequirements.V.dp_stability.length > 0 || dataRequirements.V.dp_intermediate_stability.length > 0 ||
                                                    dataRequirements.V.ds_stability.length > 0 || dataRequirements.V.ds_intermediate_stability.length > 0) ?    
                                                    <div>
                                                    {dataRequirements.V.dp_stability.length > 0 ?
                                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                                            <Text><b>Drug Product Stability</b></Text>
                                                            <Table
                                                                dataSource={dataRequirements.V.dp_stability}
                                                                columns={stabilityColumns}
                                                                style={{width:"400px", margin:"15px auto"}}
                                                                pagination={false}                                                            
                                                                bordered
                                                            />
                                                        </div> : <div></div>
                                                    }
                                                    {dataRequirements.V.dp_intermediate_stability.length > 0 ?
                                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                                            <Text><b>Drug Product Intermediate Stability</b></Text>
                                                            <Table
                                                                dataSource={dataRequirements.V.dp_intermediate_stability}
                                                                columns={stabilityColumns}
                                                                style={{width:"400px", margin:"15px auto"}}
                                                                pagination={false}                                                            
                                                                bordered
                                                            />
                                                        </div> : <div></div>
                                                    }
                                                    {dataRequirements.V.ds_stability.length > 0 ?
                                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                                            <Text><b>Drug Substance Stability</b></Text>
                                                            <Table
                                                                dataSource={dataRequirements.V.ds_stability}
                                                                columns={stabilityColumns}
                                                                style={{width:"400px", margin:"15px auto"}}
                                                                pagination={false}                                                            
                                                                bordered
                                                            />
                                                        </div> : <div></div>
                                                    }
                                                    {dataRequirements.V.ds_intermediate_stability.length > 0 ?
                                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                                            <Text><b>Drug Substance Intermediate Stability</b></Text>
                                                            <Table
                                                                dataSource={dataRequirements.V.ds_intermediate_stability}
                                                                columns={stabilityColumns}
                                                                style={{width:"400px", margin:"15px auto"}}
                                                                pagination={false}                                                            
                                                                bordered
                                                            />
                                                        </div> : <div></div>
                                                    }
                                                    </div> : null
                                                }
                                                {dataRequirements.V.dp_stability.length == 0 && dataRequirements.V.dp_intermediate_stability.length == 0 && 
                                                 dataRequirements.V.ds_stability.length == 0 && dataRequirements.V.ds_intermediate_stability.length == 0 && 
                                                 dataRequirements.V.questions_without_data_requirements != undefined && dataRequirements.V.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Stability")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Transportation/Storage" key="V_trans_storage">
                                                {dataRequirements.V.transportation_storage_conditions.length > 0 ?    
                                                    <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                                        <Text><b>Transportation/Storage Condition Types</b></Text>
                                                        <div style={{paddingTop:"20px"}}>
                                                            {
                                                                dataRequirements.V.transportation_storage_conditions.map((itm: any) => {
                                                                    return <Text key={itm.type_of_conditions} style={{paddingRight:"25px"}}>{itm.type_of_conditions}</Text>
                                                                })
                                                            }
                                                        </div>
                                                    </div> : null
                                                }
                                                {dataRequirements.V.transportation_storage_conditions.length == 0 && dataRequirements.V.questions_without_data_requirements != undefined && 
                                                 dataRequirements.V.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Transportation/Storage")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                                
                                                <TabPane tab="Validation" key="V_validation">
                                                {dataRequirements.V.validation.length > 0 ?    
                                                    <div style={{textAlign: "center", paddingLeft: "25px", paddingRight: "25px"}}>
                                                        <Text><b>Validation Types</b></Text>
                                                        <div style={{paddingTop:"20px"}}>
                                                            {
                                                                dataRequirements.V.validation.map((itm: any) => {
                                                                    return <Text key={itm.data_requirements} style={{paddingRight:"25px"}}>{itm.data_requirements}</Text>
                                                                })
                                                            }
                                                        </div>
                                                    </div> : null
                                                }
                                                {dataRequirements.V.validation.length == 0 && dataRequirements.V.questions_without_data_requirements != undefined && 
                                                 dataRequirements.V.questions_without_data_requirements.length == 0 ?
                                                        displayEmptyInfo("Validation")
                                                    : <div></div> 
                                                }
                                                </TabPane>
                                            </Tabs>                                          
                                            {dataRequirements.V.questions_without_data_requirements != undefined && dataRequirements.V.questions_without_data_requirements.length > 0 ?
                                                <div style={{paddingTop: "20px"}}>
                                                    <Text>CAKE database does not include data requirements for the following Regulatory impact assessment questions and further assessment is required.</Text>   
                                                    {getQuestions(dataRequirements.V.questions_without_data_requirements)}
                                                </div>
                                                : ""
                                            }    
                                        </Card>
                                    </TabPane>
                                    : null
                                    }
                                </Tabs>
                            </Card>
                        </TabPane> : ""
                        }
                    </Tabs>                    
                    </div>
                    }
                </Panel>
            </Collapse>
        </Card>
    );
};
