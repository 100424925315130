import { Card, Collapse, Table } from 'antd';
import React from 'react';
import { PanelProps, ProductModality } from '../model';

const { Panel } = Collapse;

export interface DownstreamProductsProps {
    panelProps: PanelProps;
    downstreamProducts: Array<ProductModality>;
    loading: boolean;
}
export const DownstreamProducts: React.FC<DownstreamProductsProps> = (props: DownstreamProductsProps) => {
    const { panelProps, downstreamProducts, loading } = props;

    return (
        <Card bordered={false}>
            <Collapse {...panelProps}>
                <Panel header="Downstream Products" key="1">
                    <Table
                        pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
                        loading={loading}
                        size="small"
                        rowKey={(record: any): string => {
                            return JSON.stringify(record);
                        }}
                        columns={[
                            {
                                title: 'Product Family',
                                dataIndex: 'familyName',
                                sortOrder: 'ascend',
                            },
                        ]}
                        dataSource={downstreamProducts}
                    />
                </Panel>
            </Collapse>
        </Card>
    );
};
