import {
    Card,
    Progress,
    Result,
    Spin,
    Typography,
    Upload,
    Collapse,
    Input,
    List,
    Row,
    Col,
    notification,
    Button,
    Tabs,
    Table,
    Popover,
} from 'antd';
import { UserAddOutlined, AimOutlined } from '@ant-design/icons';
import {
    CPWS,
    ProductModality,
    Site,
    ProductEvents,
    SiteEvents,
    GlobalProps,
    RegulatoryQuestions,
    ProductModalitySelections,
    FormRefs,
    SiteFunction,
    ColorTag,
    Actions,
    ImpactAnalysis,
    RelatedChanges,
    Plants,
    Suppliers,
    Country,
    RegisteredMarkets,
    ProductInformation,
    ProductsInfo,
    DirectAssessment,
    DataRequirements,
    Partners,
    ExternalMaterial,
    Materials,
    ImpactedMaterialsRef,
    SelectedMaterials,
    CPWSClassification,
    CPWSClassificationCodes,
    Matchable,
} from '../model';

import React from 'react';
import { CPWSQuestions, QualityAssessmentQuestion, MaterialClassification, RegulatoryQuestionsPanel, RegulatoryJustification, ProductsSitesDisplay, ImpactedMaterialsList, ImpactedMaterials } from '.';

const { Text } = Typography;
const { TextArea } = Input;
import './components.css';
const { Panel } = Collapse;
const { TabPane } = Tabs;
import _ from 'lodash';

export interface AssessmentSelectionsProps {    
    category: any;
    current: number;
    sterilization: any;
    userSelectionCategory: any;
    userSelectionSterilization: any;
    onProductModalityChange: any;
    userSelectionProductModality: ProductModalitySelections;
    globalProps: GlobalProps;
    cpws: CPWS;
    updateQaQuestion: Function;
    qaQuestionAnswered: boolean;
    impactedMaterials: any;
    classificationList: CPWSClassification;
    loading: any;
    loadedClassification: any;
    classificationCodes: CPWSClassificationCodes;
    loadClassificationListFallback: Function;
    loadClassificationList: Function;
    classificationListApplyAll: Function;
    regulatoryQuestions: RegulatoryQuestions;
    justification: string;
    impactedProducts: Matchable<ProductModality>;
    impactedSites: Matchable<Site>;
    colorTags: {
        productColorTags: ColorTag;
        siteColorTags: ColorTag;
    };
    removeManuallyEnteredMaterials: Function;
    downstreamImpactedMaterialsAvailableOption: any;
    impactedProductMaterials?:any;
    updateMaterial?: any;
    removeMaterialType?: any;
    loadMaterialType: Function;
    materialTypeWithCount: Array<any>;
    triggerApplyAllImpactedMaterials?: Function;
    printPDF?: boolean;
    impactedMaterialsPageNumber?: number;
    onPageChange?: Function;
    materialTypeRef?: React.RefObject<HTMLDivElement>;
    addMaterialContainerClosure?: Function;
    removeMaterialContainerClosure?: Function;
    containerClosureApplyAll?: Function;
    deleteExternalMaterial?: Function;
    editExternalMaterial?: Function;
    addCustomMaterialContainerClosure?: Function;
    addDownstreamMaterial?: Function;
    getDownstreamMaterials: Function;
    getImpactedProducts: Function;
    getImpactedMaterials: Function;
    externalMaterials?: Array<ExternalMaterial>;
    removeExternalMaterialFromContainerClosure?: Function;
    impactedMaterialsRef?: ImpactedMaterialsRef;    
    onContainerClosurePageChage: Function;
    expandedRowKeys: Array<string>;
    setExpandedRowKeys: Function;
    userSelectedMaterials: Array<SelectedMaterials>;
    updateCurrentTab?: Function;
    updateDownstreamImpactedMaterialsOption: Function;
    materials: any;
    materialEvents: any;
    impactedMaterialsAvailable: any;
    impactedMaterialsAvailableOption: any;
    addMaterial: any;
    externalImpactedMaterials: any;
    deleteMaterial: any;
    materialTableRef: any;
    updateUserSelectedMaterialProps: any;
    updateImpactedMaterialsOption: any;
    userSelectedExternalMaterials: any;
    areImpactedMaterialsMissing: any;
}

export const AssessmentSelections: React.FC<AssessmentSelectionsProps> = (props: AssessmentSelectionsProps) => {

    return (
        <Card bordered={false}>
            <Collapse defaultActiveKey={1} expandIconPosition="right">
                <Panel header="Material(s) Selection" key="1">
                    <Tabs defaultActiveKey="dir_ass" type="card">
                        {}
                        <TabPane tab="Scope" key="scope">
                            <CPWSQuestions
                                panelProps={props.globalProps.defaultPanelProps}
                                category={props.category}
                                sterilization={props.sterilization}
                                userSelectionCategory={props.userSelectionCategory}
                                userSelectionSterilization={props.userSelectionSterilization}
                                current={props.current}
                                onProductModalityChange={props.onProductModalityChange}
                                userSelectionProductModality={props.userSelectionProductModality}
                                cpws={props.cpws}
                                viewOnly={true}
                            />
                        </TabPane>
                        <TabPane tab="IPI Materials" key="ipi_materials">
                            <ImpactedMaterials
                                materialEvents={props.materialEvents}
                                impactedMaterialsAvailable={props.impactedMaterialsAvailable}
                                impactedMaterialsAvailableOption={props.impactedMaterialsAvailableOption}
                                addMaterial={props.addMaterial}
                                externalImpactedMaterials={props.externalImpactedMaterials}
                                deleteMaterial={props.deleteMaterial}
                                materialTableRef={props.materialTableRef}
                                updateUserSelectedMaterialProps={props.updateUserSelectedMaterialProps}
                                updateImpactedMaterialsOption={props.updateImpactedMaterialsOption}
                                userSelectedExternalMaterials={props.userSelectedExternalMaterials}
                                areImpactedMaterialsMissing={props.areImpactedMaterialsMissing}
                                cpws={props.cpws}
                                panelProps={props.globalProps.defaultPanelProps}
                                downstreamImpactedMaterialsAvailableOption={props.downstreamImpactedMaterialsAvailableOption}
                                updateDownstreamImpactedMaterialsOption={props.updateDownstreamImpactedMaterialsOption}
                                userSelectedMaterials={props.userSelectedMaterials}
                                viewOnly={true}
                            />
                        </TabPane>
                        {}
                        <TabPane tab="Material Types" key="material_types">
                            <ImpactedMaterialsList
                                removeManuallyEnteredMaterials={props.removeManuallyEnteredMaterials}
                                downstreamImpactedMaterialsAvailableOption={props.downstreamImpactedMaterialsAvailableOption}
                                updateDownstreamImpactedMaterialsOption={props.updateDownstreamImpactedMaterialsOption}
                                loading={props.loading}
                                header="Impacted Materials"
                                impactedMaterials={props.cpws.materials}
                                impactedProductMaterials={props.impactedProductMaterials}
                                panelProps={props.globalProps.defaultPanelProps}
                                updateMaterial={props.updateMaterial}
                                removeMaterialType={props.removeMaterialType}
                                loadMaterialType={props.loadMaterialType}
                                materialTypeWithCount={props.materialTypeWithCount}
                                current={props.current}
                                triggerApplyAllImpactedMaterials={props.triggerApplyAllImpactedMaterials}
                                impactedMaterialsPageNumber={props.impactedMaterialsPageNumber}
                                onPageChange={props.onPageChange}
                                materialTypeRef={props.materialTypeRef}
                                addMaterialContainerClosure={props.addMaterialContainerClosure}
                                removeMaterialContainerClosure={props.removeMaterialContainerClosure}
                                containerClosureApplyAll={props.containerClosureApplyAll}
                                deleteExternalMaterial={props.deleteExternalMaterial}
                                addCustomMaterialContainerClosure={props.addDownstreamMaterial}
                                getDownstreamMaterials={props.getDownstreamMaterials}
                                getImpactedProducts={props.getImpactedProducts}
                                getImpactedMaterials={props.getImpactedMaterials}
                                editExternalMaterial={props.editExternalMaterial}
                                externalMaterials={props.externalMaterials}
                                removeExternalMaterialFromContainerClosure={props.removeExternalMaterialFromContainerClosure}
                                impactedMaterialsRef={props.impactedMaterialsRef}
                                onContainerClosurePageChage={props.onContainerClosurePageChage}
                                expandedRowKeys={props.expandedRowKeys}
                                setExpandedRowKeys={props.setExpandedRowKeys}
                                userSelectedMaterials = {props.userSelectedMaterials}
                                updateCurrentTab = {props.updateCurrentTab}
                                addDownstreamMaterial={props.addDownstreamMaterial}
                                viewOnly={true}
                            />
                        </TabPane>
                        <TabPane tab="Change Classifications" key="change_classification">
                            <MaterialClassification
                                loading={props.loading}
                                updateMaterial={props.updateMaterial}
                                impactedMaterials={props.impactedMaterials}
                                panelProps={props.globalProps.defaultPanelProps}
                                classificationList={props.classificationList}
                                loadedClassification={props.loadedClassification}
                                current={props.current}
                                classificationCodes={props.classificationCodes}
                                classificationListApplyAll={props.classificationListApplyAll}
                                loadClassificationList={props.loadClassificationList}
                                loadClassificationListFallback={props.loadClassificationListFallback}
                                viewOnly={true}
                            />
                        </TabPane>
                    </Tabs>
                </Panel>
            </Collapse>
        </Card>
    );
};
