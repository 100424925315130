import React from 'react';

import {
    AdditionalInfoComments,
    ChangeDescription,
    CountryList,
    CPWSHeader,
    DownstreamMaterialList,
    DownstreamProducts,
    DownstreamSites,
    ImpactedMaterialsList,
    QualityAssessmentStatements,
    ReasonForChange,
    TechnicalInformation,
    ProductsSitesDisplay,
    SiteFunctions,
} from '../components';
import {
    CPWS,
    ProductModality,
    Site,
    Country,
    GlobalProps,
    DownstreamMaterial,
    SiteFunction,
    SelectionOption,
    ColorTag,
    ImpactedMaterialsRef,
    SelectedMaterials,
} from '../model';
import { CPWSStep } from '../screens/screens';
import '../screens/screens.css';
import { Card, Collapse, Typography } from 'antd';
const { Text } = Typography;
const { Panel } = Collapse;

export interface MaterialTypesProps {
    removeManuallyEnteredMaterials: Function;
    downstreamImpactedMaterialsAvailableOption: any;
    updateDownstreamImpactedMaterialsOption: Function;
    impactedProductMaterials?:any;
    cpws: CPWS;
    loadAdditionalInfo: Function;
    //loadProductsFamilySitesMaterial: Function;
    updateMaterial: Function;
    removeMaterialType: Function;
    //impactedCountries: Array<Country>;
    //downstreamMaterials: Array<DownstreamMaterial>;
    globalProps: GlobalProps;
    downstreamProducts: Array<ProductModality>;
   // downstreamSites: Array<Site>;
    //comment: string;
    loadMaterialType: Function;
    impactedMaterialType: Array<any>;
    sessionUpdate: Function;
    addMaterialContainerClosure: Function;
    removeMaterialContainerClosure: Function;
    addDownstreamMaterial: Function;
    getDownstreamMaterials: Function;
    getImpactedProducts: Function;
    getImpactedMaterials: Function;
    containerClosureApplyAll: Function;
    impactedMaterialsExternal: Array<any>;
    current: number;
    deleteMaterial: Function;
    triggerApplyAllImpactedMaterials: Function;
    editExternalMaterial: Function;
    screen3PageNumber: number;
    onScreen3PageChange: Function;
    //siteFunctions: Array<SiteFunction>;
   // onFunctionSelect: Function;
    //onFunctionDeselect: Function;
    containerClosurePageNumber: { [key: string]: number };
    //onContainerClosurePageChange: Function;
    materialTypeRef: React.RefObject<HTMLDivElement>;
    //onSiteSelect: Function;
    //onSiteDeselect: Function;
    // siteFunctionsRef: Array<{
    //     name: string;
    //     ref: React.RefObject<HTMLDivElement>;
    // }>;
    // siteFunctionCardRef: React.RefObject<HTMLDivElement>;
    // colorTags: {
    //     productColorTags: ColorTag;
    //     siteColorTags: ColorTag;
    // };
    //containerClosureCardRef: React.RefObject<HTMLDivElement>;
    removeExternalMaterialFromContainerClosure: Function;
    impactedMaterialsRef: ImpactedMaterialsRef;
    onContainerClosurePageChage: Function;
    resetContainerClosurePageNumbers: Function;
    expandedRowKeys: Array<string>;
    setExpandedRowKeys: Function;
    userSelectedMaterials: Array<SelectedMaterials>;
    updateCurrentTab: Function;
    //tempuserSelectedMaterials: Array<SelectedMaterials>;
    //updateCountries: Function;
    //resetPDS: Function;
}
export interface MaterialTypesState {
    loadingAdditional: boolean;
    loadingProdfamily: boolean;
}

type selectionType = 'siteSelect' | 'siteFunctionSelect' | 'siteDeselect' | 'siteFunctionDeselect';
type siteType = 'cpws' | 'pds';

export class MaterialTypes extends React.Component<MaterialTypesProps, MaterialTypesState> {
    constructor(props: MaterialTypesProps) {
        super(props);
        this.state = {
            loadingAdditional: true,
            loadingProdfamily: true,
        };
        //this.onSelect = this.onSelect.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.sessionUpdate();
        this.props.loadAdditionalInfo().then(() => {
            this.setState({ loadingAdditional: false });
        });        
    }

    componentWillUnmount() {
        this.props.resetContainerClosurePageNumbers();
    }

    render(): JSX.Element {
        const { cpws, globalProps, current } = this.props;

        return (
            <CPWSStep>
            <div style={{"marginTop":"-40px"}}>
                <ImpactedMaterialsList
                    removeManuallyEnteredMaterials={this.props.removeManuallyEnteredMaterials}
                    downstreamImpactedMaterialsAvailableOption={this.props.downstreamImpactedMaterialsAvailableOption}
                    updateDownstreamImpactedMaterialsOption={this.props.updateDownstreamImpactedMaterialsOption}
                    loading={this.state.loadingAdditional}
                    header="Impacted Materials"
                    impactedMaterials={cpws.materials}
                    impactedProductMaterials={this.props.impactedProductMaterials}
                    panelProps={this.props.globalProps.defaultPanelProps}
                    updateMaterial={this.props.updateMaterial}
                    removeMaterialType={this.props.removeMaterialType}
                    loadMaterialType={this.props.loadMaterialType}
                    materialTypeWithCount={this.props.impactedMaterialType}
                    current={current}
                    triggerApplyAllImpactedMaterials={this.props.triggerApplyAllImpactedMaterials}
                    impactedMaterialsPageNumber={this.props.screen3PageNumber}
                    onPageChange={this.props.onScreen3PageChange}
                    materialTypeRef={this.props.materialTypeRef}
                    addMaterialContainerClosure={this.props.addMaterialContainerClosure}
                    removeMaterialContainerClosure={this.props.removeMaterialContainerClosure}
                    containerClosureApplyAll={this.props.containerClosureApplyAll}
                    deleteExternalMaterial={this.props.deleteMaterial}
                    addCustomMaterialContainerClosure={this.props.addDownstreamMaterial}
                    getDownstreamMaterials={this.props.getDownstreamMaterials}
                    getImpactedProducts={this.props.getImpactedProducts}
                    getImpactedMaterials={this.props.getImpactedMaterials}
                    editExternalMaterial={this.props.editExternalMaterial}
                    externalMaterials={this.props.impactedMaterialsExternal}
                    removeExternalMaterialFromContainerClosure={this.props.removeExternalMaterialFromContainerClosure}
                    impactedMaterialsRef={this.props.impactedMaterialsRef}
                    containerClosurePageNumber={this.props.containerClosurePageNumber}
                    onContainerClosurePageChage={this.props.onContainerClosurePageChage}
                    expandedRowKeys={this.props.expandedRowKeys}
                    setExpandedRowKeys={this.props.setExpandedRowKeys}
                    //userSelectedMaterials = {this.props.userSelectedMaterials}
                    userSelectedMaterials = {this.props.userSelectedMaterials}
                    updateCurrentTab = {this.props.updateCurrentTab}
                    addDownstreamMaterial={this.props.addDownstreamMaterial}
                />
                {}
                </div>
            </CPWSStep>
        );
    }
}
