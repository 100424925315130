import { Card, Col, Collapse, Row, Typography } from 'antd';
import React from 'react';
import { PanelProps } from '../model';

const { Panel } = Collapse;
const { Text, Title } = Typography;

interface QAViewProps {
    panelProps: PanelProps;
    qaQuestion: boolean;
}
export const QualityAssessmentView: React.FC<QAViewProps> = (props: QAViewProps) => {
    const { qaQuestion } = props;
    return (
        <div>
            <Card bordered={false}>
                <Title level={4}>Quality Assessment</Title>

                <Row gutter={4} style={{ borderColor: '#f0f0f0', borderStyle: 'solid', borderWidth: '.5px' }}>
                    <Col
                        span={16}
                        style={{
                            padding: '16px',
                            borderColor: '#f0f0f0',
                            borderStyle: 'solid',
                            borderWidth: '.5px',
                        }}
                    >
                        <Text strong>{'Does the proposed change potentially impact the product quality?'}</Text>
                    </Col>

                    <Col
                        span={8}
                        style={{
                            padding: '16px',
                            borderColor: '#f0f0f0',
                            borderStyle: 'solid',
                            borderWidth: '.5px',
                        }}
                    >
                        <Text code>{qaQuestion ? 'Yes' : 'No'}</Text>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

interface QAStatementsProps {
    qualityAssessment: Array<string>;
    panelProps: PanelProps;
}
export const QualityAssessmentStatements: React.FC<QAStatementsProps> = (props: QAStatementsProps) => {
    const { qualityAssessment } = props;

    return (
        <Card bordered={false}>
            <Collapse {...props.panelProps} defaultActiveKey={['singleString']}>
                <Panel header="Quality Assessment statements" key="singleString">
                    {qualityAssessment?.length > 0 ? qualityAssessment : '[No Quality Assessments were located]'}
                </Panel>
            </Collapse>
        </Card>
    );
};
