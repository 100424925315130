import {
    Card,
    Progress,
    Result,
    Spin,
    Typography,
    Upload,
    Collapse,
    Input,
    Row,
    Col,
    notification,
    Button,
    Tabs,
    Table,
    Popover,
} from 'antd';
import { UserAddOutlined, AimOutlined } from '@ant-design/icons';
import {
    CPWS,
    ProductModality,
    Site,
    ProductEvents,
    SiteEvents,
    GlobalProps,
    RegulatoryQuestions,
    ProductModalitySelections,
    FormRefs,
    SiteFunction,
    ColorTag,
    Actions,
    ImpactAnalysis,
    RelatedChanges,
    Plants,
    Suppliers,
    Country,
    RegisteredMarkets,
    ProductInformation,
    ProductsInfo,
    Partners,
} from '../model';

import React from 'react';
const { Text } = Typography;
const { TextArea } = Input;
import './components.css';
const { Panel } = Collapse;
const { TabPane } = Tabs;

const supportingDetailsColumns = [
    {
        title: 'Change Element',
        dataIndex: 'change_element',
    },
    {
        title: 'Change Element Detail',
        dataIndex: 'change_element_detail',
    },
    {
        title: 'Current state',
        dataIndex: 'change_element_current',
    },
    {
        title: 'Proposed state',
        dataIndex: 'change_element_future',
    },
    {
        title: 'Rationale',
        dataIndex: 'rationale',
    },
];
const relatedChangesColumns = [
    {
        title: 'ID',
        dataIndex: 'proposal_id',
        render: (text: any, record: RelatedChanges) => record.status!=="Not Available"?<a target="_blank" href={'/?mode=view&ipiId='+record.proposal_id} >{record.proposal_id}</a>:<Text>{record.proposal_id}</Text>
    },
    {
        title: 'Title',
        dataIndex: 'title',
    },
    {
        title: 'Change Type',
        dataIndex: 'change_type',
    },
    {
        title: 'CAKE Status',
        dataIndex: 'status',
        render: (text: any, record: RelatedChanges) =>{return mapStep(record);}
        //render: (text: any, record: RelatedChanges) =>{console.log("Workkd@",record);return mapStep(record);}
    },
    {
        title: 'RI/NRI',
        dataIndex: 'ri_nri',
    },
    {
        title: 'Assessed By',
        dataIndex: 'assessed_by',
    },
];
const productFamilyNames = [
    {
        title: 'Product Family Name',
        dataIndex: 'product_family_name',
    },
];

const mapStep = (step:any,)=>{
    switch(step.status){
        case '1': return 'Discover';
        case '2': return 'Materials';
        case '3': return 'Markets';
        case '4': return 'Impact Assessment';
        case '5': if(step.complete){return "Completed"} else return 'Regulatory Screening';
        case 'Not Available':return 'Not Available';
        default: try{throw `this is not a supported page number ${step}`} catch(e){ return step}
    }
}

const products = [
    {
        title: 'Product #',
        dataIndex: 'product_number',
        width: '35%',
        render: (text: any, record: ProductsInfo) => {
            return (
                <div>
                    <Text>{record.product_number}</Text>                    
                    {record.directly_impacted ? <Popover content={'Directly impacted'} placement={'topRight'}>
                        <AimOutlined style={{ color: '#1890ff', paddingLeft: '5px' }}> </AimOutlined>
                    </Popover> : ''}
                    {record.manually_added ? <Popover content={'Manually added'} placement={'topRight'}>
                        <UserAddOutlined style={{ color: '#1890ff', paddingLeft: '5px' }}> </UserAddOutlined>
                    </Popover> : ''}
                </div>);
        }
    },
    {
        title: 'Product Name',
        dataIndex: 'product_name',
    },
];

const modalities = [
    {
        title: 'Modality Name',
        dataIndex: 'modality_name',
    }
];

const plants = [
    {
        title: 'Plant Name',
        dataIndex: 'plant_name',
        render: (text: any, record: Plants) => {
            return (
                <div>
                    <Text>{record.plant_name}</Text>                    
                    {record.directly_impacted ? <Popover content={'Directly impacted'} placement={'topRight'}>
                        <AimOutlined style={{ color: '#1890ff', paddingLeft: '5px' }}> </AimOutlined>
                    </Popover> : ''}
                    {record.manually_added ? <Popover content={'Manually added'} placement={'topRight'}>
                        <UserAddOutlined style={{ color: '#1890ff', paddingLeft: '5px' }}> </UserAddOutlined>
                    </Popover> : ''}
                </div>);
        }
    },
    {
        title: 'Plant Type',
        dataIndex: 'plant_type',
        width: '40%',
    },
];
const suppliers = [
    {
        title: 'Vendor/Supplier Name',
        dataIndex: 'vendor_supplier_name',
        // render: (text: any, record: Suppliers) => {
        //     return (
        //     <div>
        //         <Text>{record.plant_name}</Text>
        //        {record.manually_added ? <Popover content={'Manually added'} placement={'topRight'}>
        //                 <UserAddOutlined  style={{ color: '#1890ff' }}> </UserAddOutlined>
        //            </Popover>: ''}
        //        {record.directly_impacted ? <Popover content={'Directly impacted'} placement={'topRight'}>
        //        <AimOutlined  style={{ color: '#1890ff' }}> </AimOutlined>
        //        </Popover>: ''}
        //     </div>);
        //     }
    },
    {
        title: 'Supplier Type',
        dataIndex: 'supplier_Type',
        width: '40%',
    },
];

const materials = [
    {
        title: 'Material #',
        dataIndex: 'material_number',
        width: '40%',
        render: (text: string) => {
            return text.replace(/^0+/, '')
        }
    },
    {
        title: 'Material Name',
        dataIndex: 'material_name',
    },
];
const methods = [
    {
        title: 'Method #',
        dataIndex: 'method_Number',
        width: '40%',
    },
    {
        title: 'Method Name',
        dataIndex: 'method_Name',
    },
];
const registeredCountries = [
    {
        title: 'Country Name',
        dataIndex: 'country_name',
        render: (text: string, record: RegisteredMarkets) => {
            return <Text>{`${record.country_name} (${record.country_code})`}</Text>;
        },
    },
];
const partners = [
    {
        title: 'Partner Name',
        dataIndex: 'partner_name',
        render: (text: any, record: Partners) => {
            return (
                <div>
                    <Text>{record.partner_name}</Text>
                    {record.directly_impacted ? <Popover content={'Directly impacted'} placement={'topRight'}>
                        <AimOutlined style={{ color: '#1890ff', paddingLeft: '5px' }}> </AimOutlined>
                    </Popover> : ''}
                    {record.manually_added ? <Popover content={'Manually added'} placement={'topRight'}>
                        <UserAddOutlined style={{ color: '#1890ff', paddingLeft: '5px' }}> </UserAddOutlined>
                    </Popover> : ''}                    
                </div>);
        }
    },
];
const impactAnalysis = [
    {
        title: 'How Impacted',
        dataIndex: 'how_impacted',
        //key: 'impacted'
    },
    {
        title: 'Overall Impact',
        dataIndex: 'overall_impact',
        //key: 'overallImpact'
    },
    {
        title: 'Summary/Justification',
        dataIndex: 'summary_justification',
        //key: 'summary_justification'
    },
    {
        title: 'Assessor(SME)',
        dataIndex: 'responsible_resource',
        //key: 'assessor'
    },
];

const actions = [
    { title: 'Action Title', dataIndex: 'action_title', key: 'title' },
    { title: 'Action Description', dataIndex: 'action_description', key: 'description' },

];


export interface ChangeInformationState {
    file: any;
    loading: boolean;
    success: boolean;
    error: boolean;
    filesdone: boolean;
    text: string;
    inputValue: string;
    locked_by_user: any;
    initialLoading: boolean;
    lockedByUserFirstName: string;
    lockedByUserLastName: string;
    relatedChangesData: Array<RelatedChanges>;
    isRelatedChangesLoading: boolean;
}
export interface ChangeInformationProps {    
    cpws: CPWS;
    staticProductInformation: ProductInformation;    
}
export const ChangeInformation: React.FC<any> = (props: any) => {
    //: React.FC<ChangeInformationProps> = (props: ChangeInformationProps) => {
    // const { currentChange, proposedChange } = props;

    const { cpws, staticProductInformation, materialEvents, globalProps } = props;
    
    return (
        <Card bordered={false}>
            <Collapse defaultActiveKey={1} expandIconPosition="right">
                <Panel header="Change Information" key="1">
                    <Tabs defaultActiveKey="1" type="card">
                        <TabPane tab="Define" key="define">
                            <Card bordered={false}>
                                <Tabs defaultActiveKey="1" type="card">
                                    <TabPane tab="Basic Info" key="1">
                                        <Card bordered={false}>
                                            <Row gutter={16}>
                                                <Col span={9}></Col>
                                                <Col span={7} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h2> Basic Info</h2>
                                                </Col>
                                            </Row>
                                            <hr className="line-style" />

                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Change Owner:</Text>
                                                </Col>
                                                <Col span={9}>
                                                    <Text>{props.cpws.basicInfo.change_owner}</Text>
                                                </Col>
                                            </Row>

                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Change Origin:</Text>
                                                </Col>
                                                <Col span={9}>
                                                    <Text>{props.cpws.basicInfo.change_origin}</Text>
                                                </Col>
                                            </Row>

                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Change Driver:</Text>
                                                </Col>
                                                <Col span={9}>
                                                    <Text>{props.cpws.basicInfo.change_driver}</Text>
                                                </Col>
                                            </Row>

                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Site/Global:</Text>
                                                </Col>
                                                <Col span={9}>
                                                    <Text>{props.cpws.basicInfo.site_global}</Text>
                                                </Col>
                                            </Row>

                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Site/Functional Group Name:</Text>
                                                </Col>
                                                <Col span={9}>
                                                    <Text>{props.cpws.basicInfo.site_functional_area}</Text>
                                                </Col>
                                            </Row>

                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Functional Name:</Text>
                                                </Col>
                                                <Col span={9}>
                                                    <Text>{props.cpws.basicInfo.site_functional_area}</Text>
                                                </Col>
                                            </Row>

                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Change Description:</Text>
                                                </Col>
                                                <Col span={12}>
                                                    <TextArea
                                                        id={'comments'}
                                                        rows={3}
                                                        value={props.cpws.basicInfo.change_description}
                                                        disabled={true}
                                                        style={{ color: 'black' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Endorsements" key="2">
                                        <Card bordered={false}>
                                            <Row gutter={16}>
                                                <Col span={8}></Col>
                                                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h2> Endorsements</h2>
                                                </Col>
                                            </Row>
                                            <hr className="line-style" />
                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Endorsement Decision: </Text>
                                                </Col>
                                                <Col span={9}>
                                                    <Text>{props.cpws.endorsements.endorsement_decision} </Text>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Endorser: </Text>
                                                </Col>
                                                <Col span={9}>
                                                    <Text>{props.cpws.endorsements.endorser}</Text>
                                                </Col>
                                            </Row>
                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Summary Justification: </Text>
                                                </Col>
                                                <Col span={12}>
                                                    <TextArea
                                                        id={'summaryJustification'}
                                                        rows={2}
                                                        value={props.cpws.endorsements.summary_justification}
                                                        disabled={true}
                                                        style={{ color: 'black' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Supporting Details" key="3">
                                        <Card bordered={false}>
                                            <Row gutter={16}>
                                                <Col span={8}></Col>
                                                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h2> Supporting Details</h2>
                                                </Col>
                                            </Row>
                                            <hr className="line-style" />
                                            <Table
                                                dataSource={props.cpws.supportingDetails}
                                                columns={supportingDetailsColumns}
                                                pagination={false}
                                                rowKey={
                                                    '_' +
                                                    Math.random()
                                                        .toString(36)
                                                        .substr(2, 9)
                                                }
                                                bordered
                                            />
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Additional Info" key="4">
                                        <Card bordered={false}>
                                            <Row gutter={16}>
                                                <Col span={8}></Col>
                                                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h2> Additional Info</h2>
                                                </Col>
                                            </Row>
                                            <hr className="line-style" />
                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Additional Reference: </Text>
                                                </Col>
                                                <Col span={12}>
                                                    <TextArea
                                                        id={'additionalReference'}
                                                        rows={1}
                                                        value={props.cpws.additionalInfoData.additional_reference}
                                                        disabled={true}
                                                        style={{ color: 'black' }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Additional Considerations: </Text>
                                                </Col>
                                                <Col span={9}>
                                                    <Text>{props.cpws.additionalInfoData.additional_considerations} </Text>
                                                </Col>
                                            </Row>
                                            {props.cpws.additionalInfoData.additional_considerations === "Yes" &&
                                                <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                    <Col span={1}></Col>
                                                    <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Text>Summary: </Text>
                                                    </Col>
                                                    <Col span={12}>
                                                        <TextArea
                                                            id={'additionalConsiderationsSummary'}
                                                            rows={2}
                                                            value={props.cpws.additionalInfoData.additional_considerations_summary}
                                                            disabled={true}
                                                            style={{ color: 'black' }}
                                                        />
                                                    </Col>
                                                </Row>}
                                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                <Col span={1}></Col>
                                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text>Product Impact: </Text>
                                                </Col>
                                                <Col span={9}>
                                                    <Text>{props.cpws.additionalInfoData.product_impact} </Text>
                                                </Col>
                                            </Row>
                                            {props.cpws.additionalInfoData.product_impact === "Yes" &&
                                                <Row gutter={16} style={{ paddingTop: '16px' }}>
                                                    <Col span={1}></Col>
                                                    <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Text>Summary: </Text>
                                                    </Col>
                                                    <Col span={12}>
                                                        <TextArea
                                                            id={'productImpactSummary'}
                                                            rows={2}
                                                            value={props.cpws.additionalInfoData.product_impact_summary}
                                                            disabled={true}
                                                            style={{ color: 'black' }}
                                                        />
                                                    </Col>
                                                </Row>
                                            }
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Related Changes" key="5">
                                        <Card bordered={false}>
                                            <Row gutter={16}>
                                                <Col span={8}></Col>
                                                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h2> Related Changes</h2>
                                                </Col>
                                            </Row>
                                            <hr className="line-style" />
                                            <Table
                                                //dataSource={this.props.status === 'proceed' && !this.state.isRelatedChangesLoading ? this.state.relatedChangesData : []}
                                                dataSource={props.cpws.relatedChanges}
                                                columns={relatedChangesColumns}
                                                pagination={false}
                                                rowKey={
                                                    '_' +
                                                    Math.random()
                                                        .toString(36)
                                                        .substr(2, 9)
                                                }
                                                bordered
                                            />
                                        </Card>
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </TabPane>
                        <TabPane tab="Scope" key="2">
                            <Card bordered={false}>
                                <Tabs defaultActiveKey="2" type="card">
                                    <TabPane tab="Product" key="1">
                                        <Card bordered={false}>
                                            <Row gutter={16}>
                                                <Col span={8}></Col>
                                                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h2> Product Information</h2>
                                                </Col>
                                            </Row>
                                            <hr className="line-style" />
                                            <Row gutter={16}>
                                                <Col span={7}>
                                                    <h4 style={{ textAlign: 'center' }}> Product Families</h4>
                                                    <Table
                                                        dataSource={props.cpws.productInformation.product_families}
                                                        columns={productFamilyNames}
                                                        pagination={false}
                                                        //className= "table-style"
                                                        rowKey={
                                                            '_' +
                                                            Math.random()
                                                                .toString(36)
                                                                .substr(2, 9)
                                                        }
                                                        bordered
                                                        scroll={{ y: 250 }}
                                                        style={{ minHeight: '200px' }}
                                                    />
                                                </Col>
                                                <Col span={10}>
                                                    <h4 style={{ textAlign: 'center' }}> Products</h4>
                                                    <Table
                                                        dataSource={props.cpws.productInformation.products}
                                                        columns={products}
                                                        scroll={{ y: 250 }}
                                                        pagination={false}
                                                        // className= "table-style"
                                                        rowKey={
                                                            '_' +
                                                            Math.random()
                                                                .toString(36)
                                                                .substr(2, 9)
                                                        }
                                                        bordered
                                                        style={{ minHeight: '200px' }}
                                                    />
                                                </Col>
                                                <Col span={7}>
                                                    <h4 style={{ textAlign: 'center' }}> Modalities</h4>
                                                    <Table
                                                        //dataSource={props.cpws.productInformation.modalities}
                                                        dataSource={props.staticProductInformation.modalities}
                                                        columns={modalities}
                                                        pagination={false}
                                                        //className= "table-style"
                                                        rowKey={
                                                            '_' +
                                                            Math.random()
                                                                .toString(36)
                                                                .substr(2, 9)
                                                        }
                                                        scroll={{ y: 250 }}
                                                        bordered
                                                        style={{ minHeight: '200px' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Site & Supplier" key="2">
                                        <Card bordered={false}>
                                            <Row gutter={16}>
                                                <Col span={7}></Col>
                                                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h2> Site & Supplier Information</h2>
                                                </Col>
                                            </Row>
                                            <hr className="line-style" />
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <h4 style={{ textAlign: 'center' }}> Plants</h4>
                                                    <Table
                                                        dataSource={props.cpws.plants}
                                                        columns={plants}
                                                        pagination={false}
                                                        scroll={{ y: 250 }}
                                                        //className= "table-style"
                                                        rowKey={
                                                            '_' +
                                                            Math.random()
                                                                .toString(36)
                                                                .substr(2, 9)
                                                        }
                                                        bordered
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <h4 style={{ textAlign: 'center' }}> Vendors/Suppliers</h4>
                                                    <Table
                                                        dataSource={props.cpws.suppliers}
                                                        columns={suppliers}
                                                        scroll={{ y: 250 }}
                                                        pagination={false}
                                                        // className= "table-style"
                                                        rowKey={
                                                            '_' +
                                                            Math.random()
                                                                .toString(36)
                                                                .substr(2, 9)
                                                        }
                                                        bordered
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Materials & Methods" key="3">
                                        <Card bordered={false}>
                                            <Row gutter={16}>
                                                <Col span={7}></Col>
                                                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h2> Method & Material Information</h2>
                                                </Col>
                                            </Row>
                                            <hr className="line-style" />
                                            <Row gutter={16}>
                                                <Col span={14}>
                                                    <h4 style={{ textAlign: 'center' }}> Materials</h4>
                                                    <Table
                                                        dataSource={props.cpws.materials}
                                                        columns={materials}
                                                        pagination={false}
                                                        scroll={{ y: 250 }}
                                                        rowKey={
                                                            '_' +
                                                            Math.random()
                                                                .toString(36)
                                                                .substr(2, 9)
                                                        }
                                                        bordered
                                                    />
                                                </Col>
                                                <Col span={10}>
                                                    <h4 style={{ textAlign: 'center' }}> Test Methods</h4>
                                                    <Table
                                                        dataSource={props.cpws.methods}
                                                        columns={methods}
                                                        pagination={false}
                                                        scroll={{ y: 250 }}
                                                        rowKey={
                                                            '_' +
                                                            Math.random()
                                                                .toString(36)
                                                                .substr(2, 9)
                                                        }
                                                        bordered
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Market" key="4">
                                        <Card bordered={false}>
                                            <Row gutter={16}>
                                                <Col span={7}></Col>
                                                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h2> Market & Partner Information</h2>
                                                </Col>

                                            </Row>
                                            <hr className="line-style" />
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <h4 style={{ textAlign: 'center' }}> Where Sold Countries</h4>
                                                    <Table
                                                        dataSource={props.cpws.soldMarkets}
                                                        columns={registeredCountries}
                                                        pagination={false}
                                                        scroll={{ y: 250 }}
                                                        rowKey={
                                                            '_' +
                                                            Math.random()
                                                                .toString(36)
                                                                .substr(2, 9)
                                                        }
                                                        bordered
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <h4 style={{ textAlign: 'center' }}> Partners</h4>
                                                    <Table
                                                        dataSource={props.cpws.partners}
                                                        columns={partners}
                                                        pagination={false}
                                                        scroll={{ y: 250 }}
                                                        rowKey={
                                                            '_' +
                                                            Math.random()
                                                                .toString(36)
                                                                .substr(2, 9)
                                                        }
                                                        bordered
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </TabPane>
                        <TabPane tab="Assessments" key="3">
                            <Card bordered={false}>
                                <Tabs defaultActiveKey="1" type="card">
                                    <TabPane tab="Impact Analysis" key="1">
                                        <Card bordered={false}>
                                            <Row gutter={16}>
                                                <Col span={7}></Col>
                                                <Col span={12} style={{ display: 'flex', textAlign: 'center' }}>
                                                    <h2> Impact Analysis</h2>
                                                </Col>
                                            </Row>
                                            <hr className="line-style" />
                                            <Table
                                                dataSource={props.cpws.impactAnalysis}
                                                columns={impactAnalysis}
                                                pagination={false}
                                                expandable={{
                                                    expandedRowRender: record => record.overall_impact === 'Yes' &&
                                                        <Tabs defaultActiveKey="1" type="card">
                                                            <TabPane tab="Actions" key="3">
                                                                <Card bordered={false}>
                                                                    <Table columns={actions} dataSource={record.actions} pagination={false} />
                                                                </Card>
                                                            </TabPane>
                                                        </Tabs>,
                                                    rowExpandable: record => record.overall_impact === 'Yes',
                                                }}
                                                bordered
                                            />
                                        </Card>
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </TabPane>
                    </Tabs>
                </Panel>
            </Collapse>
        </Card>
    );
};
