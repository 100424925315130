import { Card, Form } from 'antd';
import React from 'react';
import './screens.css';
export { Screen0 } from './Screen0';
export { Screen1 } from './Screen1';
export { Screen2 } from './Screen2';
export { Screen3 } from './Screen3';
export { Screen4 } from './Screen4';
export { Screen5 } from './Screen5';

interface CPWSStepProps {
    //children?: Array<JSX.Element>;
    children?: any;
}
export function CPWSStep(props: CPWSStepProps): JSX.Element {
    const { children } = props;
    return (
        //<Card className="screen" bordered={true}>
            <Form layout="horizontal" className="screenForm" hideRequiredMark>
                {children}
            </Form>
        //</Card>
    );
}
