import { Card, Collapse } from 'antd';
import React from 'react';
import { PanelProps } from '../model';

export { ChangeDescription } from './ChangeDescription';
export { CountryList } from './Countries';
export { CPWSHeader } from './CPWSHeader';
export { CPWSHeaderImportData } from './CPWSHeaderImportData';
export { CPWSQuestions } from './CPWSQuestions';
export { DownstreamMaterialList } from './DownstreamMaterialList';
export { DownstreamProducts } from './DownstreamProducts';
export { DownstreamSites } from './DownstreamSites';
export { FinalAssessmentQuestions } from './FinalAssessmentQuestions';
export { AssessmentSummaries } from './AssessmentSummaries';
export { DetailedAssessments } from './DetailedAssessments';
export { FinalResults } from './FinalResults';
export { FormValidationErrorText } from './FormValidationErrorText';
export { ImpactedMaterials } from './ImpactedMaterials';
export { ImpactedMaterialsList } from './ImpactedMaterialsList';
export { ImpactedSites } from './ImpactedSites';
export { MatchCriteriaQuestions } from './MatchCriteriaQuestions';
export { MatchCriteriaQuestionsFinalYes } from './MatchCriteriaQuestionsFinalYes';
export { MaterialClassification } from './MaterialClassification';
export { PDSSiteFunctionSelect } from './PDSSiteFunctionSelect';
export { PDSSiteSelect } from './PDSSiteSelect';
export { ProductsModalities } from './ProductsModalities';
export { ProductsSitesDisplay } from './ProductsSitesDisplay';
export { ProductsSitesSelection } from './ProductsSitesSelection';
export { QualityAssessmentQuestion, QualityAssessmentStatements } from './QualityAssessment';
export { QualityAssessmentView } from './QualityAssessmentView';
export { ReasonForChange } from './ReasonForChange';
export { RegulatoryImpactHeader } from './RegulatoryImpactHeader';
export { RegulatoryJustification } from './RegulatoryJustification';
export { RegulatoryQuestionsPanel } from './RegulatoryQuestionsPanel';
export { SiteFunctions } from './SiteFunctions';
export { StepFooter } from './StepFooter';
export { AssessmentSelections } from './AssessmentSelections';
const { Panel } = Collapse;

export interface TechnicalInformationProps {
    technicalInformation: string;
    panelProps: PanelProps;
}
export const TechnicalInformation: React.FC<TechnicalInformationProps> = (props: TechnicalInformationProps) => {
    const { technicalInformation } = props;
    const hasValue: boolean = technicalInformation ? true : false;

    return (
        <Card bordered={false}>
            <Collapse {...props.panelProps} defaultActiveKey={['technicalInformation']}>
                <Panel header="Technical Information" key="technicalInformation">
                    {hasValue ? technicalInformation : '[Not Entered]'}
                </Panel>
            </Collapse>
        </Card>
    );
};

export interface AdditionalInfoCommentsProps {
    additionalInfoComments: string;
    panelProps: PanelProps;
}
export const AdditionalInfoComments: React.FC<AdditionalInfoCommentsProps> = (props: AdditionalInfoCommentsProps) => {
    const { additionalInfoComments } = props;
    const hasValue: boolean = additionalInfoComments ? true : false;

    return (
        <Card bordered={false}>
            <Collapse {...props.panelProps} defaultActiveKey={['additionalInfoComments']}>
                <Panel header="Additional Info Comments" key="additionalInfoComments">
                    {hasValue ? additionalInfoComments : '[Not Entered]'}
                </Panel>
            </Collapse>
        </Card>
    );
};
