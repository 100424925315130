import { Select, Tag, Col, Row, Typography, Divider } from 'antd';
import find from 'lodash/find';
import differenceWith from 'lodash/differenceWith';
import React from 'react';
import { Matchable, Site, SiteEvents, SiteFunction, SelectionOption, ColorTag } from '../model';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';
import { PDSSiteSelect } from './PDSSiteSelect';
import { PDSSiteFunctionSelect } from './PDSSiteFunctionSelect';
import { CustomTagProps } from 'rc-select/lib/interface/generator';

const { Option } = Select;
const { Text } = Typography;

export interface ImpactedSitesProps {
    impactedSites: Matchable<Site>;
    sitesAvailable: Array<Site>;
    siteEvents?: SiteEvents;
    siteFunctions: Array<SiteFunction>;
    siteFunctionRefs?: Array<{
        name: string;
        ref: React.RefObject<HTMLDivElement>;
    }>;
    siteColorTags: ColorTag;
}
export class ImpactedSites extends React.Component<ImpactedSitesProps> {
    private siteFunctionsRefs: Array<{
        name: string;
        ref: React.RefObject<HTMLDivElement>;
    }>;
    constructor(props: ImpactedSitesProps) {
        super(props);
        this.siteFunctionsRefs = [];
    }

    comparitor = (a: Site, b: Site): boolean => {
        return a.name === b.name;
    };

    buildMasterList = (sites: Array<Site>): Array<JSX.Element> => {
        return sites.map((site: Site) => (
            <Option value={site.name} key={site.code}>
                {site.name}
            </Option>
        ));
    };

    defaultValues = (
        impactedSites: Array<Site>,
        siteType: 'cpws' | 'pds',
    ): Array<{ key: string; label: string; value: string }> => {
        return impactedSites.reduce((siteOptions: Array<{ key: string; label: string; value: string }>, site: Site) => {
            if ((siteType === 'cpws' && !site.isPDS) || (siteType === 'pds' && site.isPDS)) {
                siteOptions.push({
                    key: site.name,
                    label: site.name,
                    value: site.name,
                });
            }
            return siteOptions;
        }, []);
    };

    //do we need this anymore?
    notFound(impactedSites: Array<Site>, sitesAvailable: Array<Site>) {
        const found: Array<Site> = [];
        impactedSites.map((site: Site) => {
            const validSite = find(sitesAvailable, ['name', site.name]);
            if (validSite) {
                found.push(validSite);
            }
            return false;
        });
        return differenceWith(impactedSites, found, this.comparitor);
    }

    generateSiteFunctions = (impactedSites: Array<Site>, siteFunctions: Array<SiteFunction>) => {
        return impactedSites.reduce((sites: Array<JSX.Element>, site: Site) => {
            if (site.isPDS) {
                //TODO Move the ref creation down into PDSSiteFunctionSelect instead of above. You have to pass down the ref again.
                sites.push(
                    <PDSSiteFunctionSelect
                        site={site}
                        siteFunctions={siteFunctions}
                        onSiteFunctionSelect={this.props.siteEvents?.onFunctionSelect}
                        onSiteFunctionDeselect={this.props.siteEvents?.onFunctionDeselect}
                        key={site.name}
                        siteFunctionRefs={this.props.siteFunctionRefs}
                    />,
                );
            }
            return sites;
        }, []);
    };

    generateRedTags = (siteColorTags: ColorTag) => {
        return Object.keys(siteColorTags).reduce((tags: Array<JSX.Element>, key: string) => {
            if ((key === 'initialRemoved' && typeof siteColorTags[key] !== 'boolean') || key === 'unmatched') {
                const siteArr = siteColorTags[key] as Array<Site>;

                tags.push(
                    ...siteArr.map((site: Site) => (
                        <Tag key={site.name} color="red">
                            <Text delete={key === 'initialRemoved' ? true : false}>{site.name}</Text>
                        </Tag>
                    )),
                );
            }
            return tags;
        }, []);
    };

    getTagColor = (site: Site) => {
        return find(this.props.siteColorTags.initiallySelected as Array<Site>, ['name', site.name])
            ? 'default'
            : 'green';
    };

    tagRender = (tagProps: CustomTagProps) => {
        const { label, value, closable, onClose } = tagProps;

        let color: string;
        const site = find(this.props.impactedSites.selected, ['name', value]);
        if (site && typeof this.props.siteColorTags.initiallySelected !== 'boolean') {
            color = this.getTagColor(site);
            return (
                <Tag color={color} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                    {label}
                </Tag>
            );
        } else {
            return (
                <Tag color="default" closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                    {label}
                </Tag>
            );
        }
    };

    render(): JSX.Element {
        const { sitesAvailable, impactedSites } = this.props;

        return (
            <FormContext.Consumer>
                {({ sitesSelected, viewOnly }) => (
                    <div className={viewOnly ? 'view-only' : ''}>
                        {!sitesSelected ? (
                            <Row gutter={16}>
                                <Col span={8}></Col>
                                <Col span={12}>
                                    <FormValidationErrorText text="Please select a site" />
                                </Col>
                                <Col span={8}></Col>
                            </Row>
                        ) : null}
                        <Row gutter={16} style={{ paddingBottom: '8px' }}>
                            <Col offset={8}>{this.generateRedTags(this.props.siteColorTags)}</Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={4}></Col>
                            <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                                <Text>CPWS Sites:</Text>
                            </Col>
                            <Col span={12}>
                                <Select
                                    mode="multiple"
                                    size={'middle'}
                                    placeholder="Please select"
                                    disabled={!this.props.siteEvents}
                                    labelInValue={true}
                                    defaultValue={this.defaultValues(impactedSites.selected, 'cpws')}
                                    onDeselect={val => this.props.siteEvents?.onDeselect({ site: val })}
                                    onSelect={val => this.props.siteEvents?.onSelect({ site: val, siteType: 'cpws' })}
                                    style={{ width: '100%' }}
                                    className={!sitesSelected ? 'select-highlight' : ''}
                                    dropdownMatchSelectWidth={300}
                                    tagRender={this.tagRender}
                                >
                                    {this.buildMasterList(sitesAvailable)}
                                </Select>
                            </Col>
                            <Col span={4}></Col>
                        </Row>
                        <Divider />
                        <Row gutter={16}>
                            <Col span={4}></Col>
                            <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                                <Text>PDS Sites:</Text>
                            </Col>
                            <Col span={12}>
                                <PDSSiteSelect
                                    onSiteDeselect={(option: SelectionOption) =>
                                        this.props.siteEvents?.onDeselect({ site: option })
                                    }
                                    onSiteSelect={(option: SelectionOption) =>
                                        this.props.siteEvents?.onSelect({ site: option })
                                    }
                                    impactedSites={this.props.impactedSites.selected}
                                    siteFunctions={this.props.siteFunctions}
                                />
                            </Col>
                            <Col span={4}></Col>
                        </Row>
                        {this.generateSiteFunctions(this.props.impactedSites.selected, this.props.siteFunctions)}
                    </div>
                )}
            </FormContext.Consumer>
        );
    }
}
