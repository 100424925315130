import React from 'react';
import {
    Card,
    Progress,
    Result,
    Spin,
    Typography,
    Input,
    Row,
    Col,
    notification,
    Button,
    Tabs,
    Table,
    Space,
    Modal,
    Popover,
} from 'antd';
import {ExclamationCircleOutlined, WarningOutlined, UserAddOutlined, AimOutlined} from '@ant-design/icons';
import {
    CPWS,
    ProductModality,
    Site,
    GlobalProps,
    ProductModalitySelections,
    SiteFunction,
    ColorTag,
    RelatedChanges,
    Plants,
    Country,
    RegisteredMarkets,
    ProductsInfo,
    ProductInformation,
    Partners,
} from '../model';
import './screens.css';
import { CPWSStep } from './screens';
import { IpiIdTitle } from '../components/IpiIdTitle';

const { Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const supportingDetailsColumns = [
    {
        title: 'Change Element',
        dataIndex: 'change_element',
    },
    {
        title: 'Change Element Detail',
        dataIndex: 'change_element_detail',
    },
    {
        title: 'Current state',
        dataIndex: 'change_element_current',
    },
    {
        title: 'Proposed state',
        dataIndex: 'change_element_future',
    },
    {
        title: 'Rationale',
        dataIndex: 'rationale',
    },
];

const mapStep = (step:any,)=>{
    switch(step.status){
        case '1': return 'Discover';
        case '2': return 'Materials';
        case '3': return 'Markets';
        case '4': return 'Impact Assessment';
        case '5': if(step.complete){return "Completed"} else return 'Regulatory Screening';
        case 'Not Available':return 'Not Available';
        default: try{console.log("Step Number not in range 1 to 5 Current step=",step); throw `this is not a supported page number ${step}`} catch(e){ return step}
    }
}
const relatedChangesColumns = [
    {
        title: 'ID',
        dataIndex: 'proposal_id',
        render: (text: any, record: RelatedChanges) => record.status!=="Not Available"?<a target="_blank" href={'/?mode=view&ipiId='+record.proposal_id} >{record.proposal_id}</a>:<Text>{record.proposal_id}</Text>
    },
    {
        title: 'Title',
        dataIndex: 'title',
    },
    {
        title: 'Change Type',
        dataIndex: 'change_type',
    },
    {
        title: 'CAKE Status',
        dataIndex: 'status',
        render: (text: any, record: RelatedChanges) =>{console.log("Workkd@",record);return mapStep(record);}
    },
    {
        title: 'RI/NRI',
        dataIndex: 'ri_nri',
    },
    {
        title: 'Assessed By',
        dataIndex: 'assessed_by',
    },
];

const productFamilyNames: any = [
    {
        title: 'Product Family Name',
        dataIndex: 'product_family_name',
        key: 'product_family_name',
        defaultSortOrder: "ascend",
        sorter: (a:any, b:any) => a.product_family_name.normalize().localeCompare(b.product_family_name.normalize()),
    },
];

const products:any = [
    {
        title: 'Product #',
        dataIndex: 'product_number',
        width: '35%',
        render: (text: any, record: ProductsInfo) => {
            return (
            <div>
                <Text>{record.product_number}</Text>
               {record.directly_impacted ? <Popover content={'Directly impacted'} placement={'topRight'}>
               <AimOutlined  style={{ color: '#1890ff', paddingLeft: '5px' }}> </AimOutlined>
               </Popover>: ''}
               {record.manually_added ? <Popover content={'Manually added'} placement={'topRight'}>
                        <UserAddOutlined  style={{ color: '#1890ff', paddingLeft: '5px' }}> </UserAddOutlined>
                   </Popover>: ''}               
            </div>);
            }
    },
    {
        title: 'Product Name',
        dataIndex: 'product_name',
        key: 'product_name',
        defaultSortOrder: "ascend",
        sorter: (a:any, b:any) => a.product_name.normalize().localeCompare(b.product_name.normalize()),
    },
];


const modalities = [
    {
        title: 'Modality Name',
        dataIndex: 'modality_name',
    }
];

const plants:any = [
    {
        title: 'Plant Name',
        dataIndex: 'plant_name',
        key: 'plant_name',
        defaultSortOrder: "ascend",
        sorter: (a:any, b:any) => a.plant_name.normalize().localeCompare(b.plant_name.normalize()),
        render: (text: any, record: Plants) => {
        return (
        <div>
            <Text>{record.plant_name}</Text>
           {record.directly_impacted ? <Popover content={'Directly impacted'} placement={'topRight'}>
           <AimOutlined  style={{ color: '#1890ff', paddingLeft: '5px' }}> </AimOutlined>
           </Popover>: ''}
           {record.manually_added ? <Popover content={'Manually added'} placement={'topRight'}>
                    <UserAddOutlined  style={{ color: '#1890ff', paddingLeft: '5px' }}> </UserAddOutlined>
               </Popover>: ''}           
        </div>);
        }
    },
    {
        title: 'Plant Type',
        dataIndex: 'plant_type',
        width: '40%',
    },
];
const suppliers = [
    {
        title: 'Vendor/Supplier Name',
        dataIndex: 'vendor_supplier_name',        
    },
    {
        title: 'Supplier Type',
        dataIndex: 'supplier_Type',
        width: '40%',
    },
];

const materials = [
    {
        title: 'Material #',
        dataIndex: 'material_number',
        width: '40%',
        render: (text: string) => {
            return text.replace(/^0+/, '')
        },
    },
    {
        title: 'Material Name',
        dataIndex: 'material_name',
    },
];
const methods = [
    {
        title: 'Method #',
        dataIndex: 'method_Number',
        width:'40%',
    },
    {
        title: 'Method Name',
        dataIndex: 'method_Name',
    },
];
const registeredCountries = [
    {
        title: 'Country Name',
        dataIndex: 'country_name',
        render: (text: string, record: RegisteredMarkets) => {
            return <Text>{`${record.country_name} (${record.country_code})`}</Text>;
        },
    },
];
const partners = [
    {
        title: 'Partner Name',
        dataIndex: 'partner_name',
        render: (text: any, record: Partners) => {
            return (
            <div>
                <Text>{record.partner_name}</Text>
               {record.directly_impacted ? <Popover content={'Directly impacted'} placement={'topRight'}>
               <AimOutlined  style={{ color: '#1890ff', paddingLeft: '5px' }}> </AimOutlined>
               </Popover>: ''}
               {record.manually_added ? <Popover content={'Manually added'} placement={'topRight'}>
                        <UserAddOutlined  style={{ color: '#1890ff', paddingLeft: '5px' }}> </UserAddOutlined>
                   </Popover>: ''}               
            </div>);
            }
    },
];
const impactAnalysis = [
    {
        title: 'How Impacted',
        dataIndex: 'how_impacted',
        //key: 'impacted'
    },
    {
        title: 'Overall Impact',
        dataIndex: 'overall_impact',
        //key: 'overallImpact'
    },
    {
        title: 'Summary/Justification',
        dataIndex: 'summary_justification',
        //key: 'summary_justification'
    },
    {
        title: 'Assessor(SME)',
        dataIndex: 'responsible_resource',
        //key: 'assessor'
    },
];

const actions = [
    { title: 'Action Title', dataIndex: 'action_title', key:'title'},
    { title: 'Action Description', dataIndex: 'action_description', key: 'description' },
  
  ];

const expandedRow = (data:any) => {
    const columns = [
      { title: 'Action Title', dataIndex: 'action_title', key:'title'},
      { title: 'Action Description', dataIndex: 'action_description', key: 'description' },
    
    ];
    return <TabPane><Table columns={columns} dataSource={data.actions} pagination={false} /> </TabPane>;
  };
export interface Screen1State {
    file: any;
    loading: boolean;
    success: boolean;
    error: boolean;
    filesdone: boolean;
    text: string;
    inputValue: string;
    locked_by_user: any;
    initialLoading: boolean;
    lockedByUserFirstName: string;
    lockedByUserLastName: string;
    relatedChangesData: Array<RelatedChanges>;
    isRelatedChangesLoading: boolean;
}

export interface Screen1Props {
    sendCPWSFile: Function;
    downloadFile: Function;
    cpws: CPWS;
    impactedCountries:Array<Country>;
    globalProps: GlobalProps;
    onText: Function;
    comment: string;
    cpwsId: string;
    title: string;    
    status: any;
    ingestDataAsync: Function;
    editData: Function;
    sessionUpdate: Function;
    s3Loaded: boolean;
    isViewOnlyUser: boolean;
    current: number;
    staticProductInformation: ProductInformation;    
    viewData: Function;
    getRelatedChanges: Function;
    isGetRelatedChangesAPISucess: boolean;
    lockedByUserFirstName: string;
    lockedByUserLastName: string;
}

export class Screen1 extends React.Component<Screen1Props, Screen1State> {
    constructor(props: any) {
        super(props);
        this.state = {
            file: null,
            loading: false,
            success: false,
            error: false,
            filesdone: false,
            text: '',
            inputValue: '',
            locked_by_user: '',
            initialLoading: true,
            lockedByUserFirstName: '',
            lockedByUserLastName: '',
            relatedChangesData: [],
            isRelatedChangesLoading: false,
        };
       
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams?.entries());
        const {view, ipiId} = params;

        if(ipiId){
            if (window.history.replaceState) {
                window.history.replaceState('', 'CAKE', (window.location.href).split('?')[0]);
             }
            setTimeout(()=>{
                this.view(ipiId);
            },100)
        }
    }

    async componentDidMount(): Promise<void> {
        const { cpws } = this.props;
        if (cpws.cpwsId !== '0') {
            console.log('IPI ID detected sending update...');
            await this.props.sessionUpdate();            
        }
        this.setState({ loading: false});        
    }

    async componentDidUpdate(): Promise<void> {
        //console.log(this.props.status);
        if(this.props.status == "noClicked" && this.state.loading) {
            this.setState({loading: false});
        }
    }
    
    
    // async componentDidUpdate(): Promise<void> {
    //     if (this.props.status === 'proceed') {
    //         console.log('Related changes API call...');
    //         await this.getRelatedChanges();
    //     }
    // }

    enableDownload = async (filename: string): Promise<void> => {
        this.setState({ loading: true });
        try {
            const download = await this.props.downloadFile(filename);
            if (download.response) {
                
                download.callback();
            } else if (download.error === 'file locked') {
                console.log('error file locked');
                this.setState({ error: true, locked_by_user: download.locked_by_user, 
                            lockedByUserFirstName: download.lockedByUserFirstName,
                            lockedByUserLastName: download.lockedByUserLastName }, () => {
                    this.lockedFileWarning();
                });
            } else if (download.error === 'not_proceed') {
                this.setState({ error: false, loading: false, inputValue: '' }, () => {
                    console.log('awaiting user response...');
                });
            } else {
                console.log('CAKE record for IPI ID is not found');
                this.setState({ error: true, inputValue: '' }, () => {
                    this.missingFileWarning();
                });
            }
        } catch (err) {
            this.missingFileWarning();
            this.setState({ error: true, inputValue: '' });
        }
        this.responseMessage();
    };
   
    lockedFileWarning = () => {
        notification.error({
            message: `CAKE record for IPI ID ${this.state.inputValue} is locked by ${this.state.lockedByUserFirstName}, ${this.state.lockedByUserLastName}`,
            description: `The selected CAKE record for IPI ID ${this.state.inputValue} is currently in-use by ${this.state.lockedByUserFirstName}, ${this.state.lockedByUserLastName}, please try again later or select another IPI ID ...`,
            placement: 'topRight',
            duration: 15,
        });
        this.setState({inputValue: '', loading: false});
    };

    editMissingFileWarning = () => {
        notification.warning({
            message: 'Error retrieving CAKE record for this IPI ID.',
            description:
                'The IPI ID has not been ingested to CAKE, or there was an error retrieving the file. If the file has previously been ingested into CAKE, please try to edit the file again.',
            placement: 'topRight',
            duration: 15,
        });
        this.setState({loading:false})
    };

    missingFileWarning = () => {
        notification.warning({
            message: 'Could not find CAKE record for this IPI ID',
            description: 'The selected CAKE record for this IPI ID could not be found! Please try again...',
            placement: 'topRight',
            duration: 15,
        });
        this.setState({loading:false})
    };

    viewMissingFileWarning = () => {
        notification.warning({
            message: 'Could not find CAKE record for this IPI ID',
            description:
                'The selected CAKE record for this IPI ID file could not be found! Files must be ingested into CAKE before they can be viewed.',
            placement: 'topRight',
            duration: 15,
        });
        this.setState({loading:false})
    };

     getRelatedChanges = async (): Promise<Array<RelatedChanges>> => {
        this.setState({isRelatedChangesLoading: true})
        const relatedChangesData = await this.props.getRelatedChanges();
        if(relatedChangesData) {
            this.setState({relatedChangesData: relatedChangesData, isRelatedChangesLoading: false});
            return relatedChangesData;
        }
        return [];
    }

    ingestConnectionWarning = () => {
        notification.warning({
            message: `Error connecting to IPI service`,
            description: `There was a connection error when trying to retrieve the requested CAKE record for this IPI ID...`,
            placement: 'topRight',
            duration: 15,
        });
        this.setState({loading:false})
    };

    invalidCPWSIDWarning = () => {
        notification.warning({
            message: `The entered IPI ID is not valid`,
            description: `Please enter a valid IPI ID and try again...`,
            placement: 'topRight',
            duration: 15,
        });
        this.setState({loading:false})
    };

    responseMessage(): JSX.Element {
        const { success, filesdone, error } = this.state;
        const { status } = this.props;
        // switch (key) {
        //     case value:

        if (error) {
            if (status == 'locked') {
                return (
                    <div>
                        <Result status="info" subTitle="File is currently locked..." />
                    </div>
                );
            } else {
                return (
                    <div>
                        <Result status="info" subTitle="File not found..." />
                    </div>
                );
            }
        }
        if (this.state.loading) {
            return <Spin tip="Loading..."></Spin>;
        }
        if (success && !filesdone) {
            return (
                <div>
                    <Result
                        status="success"
                        title="Successfully uploaded"
                        subTitle="Your data will be available shortly"
                    />
                    <Progress
                        strokeColor={{
                            from: '#108ee9',
                            to: '#87d068',
                        }}
                        percent={100}
                        status="active"
                    />
                </div>
            );
        } else {
            return (
                <div>                    
                </div>
            );
        }
    }

    onChange = (info: any): void => {
        this.setState({ file: info.file, loading: true });
    };
    beforeUpload = (file: any): boolean => {
        this.setState({ loading: true });
        this.props.sendCPWSFile(file, this.enableDownload);
        return false;
    };
    changeText = (event: any): void => {
        this.props.onText(event.target.value);
    };
    inputValue = (event: any): void => {
        this.setState({ inputValue: event.target.value });
    };
    warningPopup = async (cpwsID : string, action: string): Promise<void> => {
        //alert(this.state.inputValue);
        //let cpwsID = this.state.inputValue;
        let newVal = this.state.inputValue;
        if(cpwsID != "0") {
            const message = (
                <Space direction="vertical" size={4}>
                    <Text>{`You are currently working on the IPI ID ${cpwsID}. Please SAVE your work, otherwise it will be lost.`}</Text>
                    <br />
                    <div>
                        <WarningOutlined />
                        <Text>
                            <Text strong style={{ color: 'red' }}>{`Do you want to ${action} the IPI ID ${newVal}?`} </Text>
                        </Text>
                    </div>
                </Space>
            );
            Modal.confirm({
                icon: <ExclamationCircleOutlined style={{ display: 'none' }} />,
                content: message,
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    console.log("Ok Clicked");
                    if(action == 'import') { 
                        this.ingest();
                    } else if(action == 'edit') {
                        this.edit();
                    } else {
                        this.view();
                    }
                },
                onCancel: () => {
                    console.log("No Clicked");
                },
                className: 'modal-style',
            });
        } else {
            if(action == 'import') { 
                this.ingest();
            } else if(action == 'edit') {
                this.edit();
            } else {
                this.view();
            }
        }
    };
    ingest = async (): Promise<void> => {
        
        this.setState({ loading: true, inputValue: this.state.inputValue.toLocaleUpperCase() });
        
        try {
            const download = await this.props.ingestDataAsync(this.state.inputValue.trim());
            if (download.response) {
                this.setState({ success: true, loading: false, inputValue: '' });
                download.callback();
            } else if (download.error === 'INVALID_CPWS_ID') {
                console.log('user entered an invalid ipi id...');
                this.setState({ error: true, inputValue: '' }, () => {
                    this.invalidCPWSIDWarning();
                });
            } else if (download.error === 'connection_error') {
                console.log('connection error called');
                this.setState({ error: true, inputValue: '' }, () => {
                    this.ingestConnectionWarning();
                });
            } else if (download.error === 'file locked') {
                console.log('error file locked');
                this.setState({ error: true, locked_by_user: download.locked_by_user, 
                                lockedByUserFirstName: download.lockedByUserFirstName,
                                lockedByUserLastName: download.lockedByUserLastName}, () => {
                this.lockedFileWarning();
                });
            } else if (download.error === 'not_proceed') {
                this.setState({ error: false, loading: true, inputValue: '' }, () => {
                    console.log('awaiting user response...');

                });
            } else {
                console.log('CAKE record for this IPI ID is not found');
                this.setState({ error: true, inputValue: '' }, () => {
                    this.missingFileWarning();
                });
            }
        } catch (err) {
            this.missingFileWarning();
            this.setState({ error: true, inputValue: '' });
        }
        this.responseMessage();
    };
    edit = async (): Promise<void> => {
        this.setState({ loading: true, inputValue: this.state.inputValue.toLocaleUpperCase() });
        try {
            const download = await this.props.editData(this.state.inputValue.trim());
            console.log(download);
            if (download.response) {
                this.setState({ success: true, loading: false, inputValue: '' });
                download.callback();
            } else if (download.error === 'file locked') {
                console.log('error locked called');
                this.setState({ error: true, locked_by_user: download.locked_by_user, 
                        lockedByUserFirstName: download.lockedByUserFirstName,
                        lockedByUserLastName: download.lockedByUserLastName}, () => {
                    this.lockedFileWarning();
                });
            } else if (download.error === 'not proceed') {
                this.setState({ error: false, loading: false, inputValue: '' });
            } else {
                console.log('IPI File not found');
                this.setState({ error: true, inputValue: '' }, () => {
                    this.missingFileWarning();
                });
            }
        } catch (err) {
            this.editMissingFileWarning();
            this.setState({ error: true, inputValue: '' });
        }
        this.responseMessage();
    };

    view:any = async (searchParam:string): Promise<void> => {
        this.setState({ loading: true, inputValue:searchParam || this.state.inputValue.toLocaleUpperCase() });
        try {
            const download = await this.props.viewData(this.state.inputValue.trim());
            if (download.response) {
                this.setState({ success: true, loading: false, inputValue: '' });
                download.callback();
            } else if (download.error === 'cancelled') {
                this.setState({ error: false, loading: false, inputValue: '' });
            } else {
                console.log('IPI File not found');
                this.setState({ error: true, inputValue: '' }, () => {
                    this.viewMissingFileWarning();
                });
            }
        } catch (err) {
            this.viewMissingFileWarning();
            console.log(err);
            this.setState({ error: true, inputValue: '' });
        }
        this.responseMessage();
    };
    /*customExpandIcon(cpws:any) {
        const impactAnalysisActions=cpws.impactAnalysis;
        
        impactAnalysisActions.array.forEach((ele:any) => {
            if (ele.overall_impact.isEqual('Yes')) {
                // return <Button title="Close the row" type="primary" htmlType="submit" icon="close" onClick={e => {
                //     props.onExpand(ele.actions, e);
    
                // }}>Close</Button>
                return <Button title="Click to open" htmlType="submit" icon="history" >Plus</Button>
            } else {
    
                    return <Button title="Click to open" htmlType="submit" icon="history" >Minus</Button>
    
            }
        });
        return <Button title="Click to open" htmlType="submit" icon="history" >Minus</Button>
        
    }*/

    render(): JSX.Element {
        const {
            cpws,
            staticProductInformation,
            globalProps,
            cpwsId,
            title,
            current,
        } = this.props;
        const realTitle = title ? title : 'No data was loaded. ';
        const props = {
            name: 'file',
            multiple: false,
            action: '#',
            onChange: this.onChange,
            beforeUpload: this.beforeUpload,
            showUploadList: { showRemoveIcon: false, showDownloadIcon: false, showPreviewIcon: false },
        };

        cpws.productInformation.products.forEach(function (o) {
            Object.keys(o).forEach(function () {
                if (o['product_name'] === null) {
                    o['product_name'] = " ";
                }
            });
        });
        //console.log('products info final', cpws.productInformation.products)
        
        return (
            <CPWSStep>
                <Card bordered={false} key={`IPIcard${cpwsId}2`}>
                    {this.props.s3Loaded === false || this.state.loading? (
                        <Spin size="large" style={{zIndex:9999}} className="loading-spin" />
                    ) : (
                        <Row gutter={4}>
                            <Col
                                span={4}
                                style={{
                                    background: '#fafafa',
                                    padding: '16px',
                                    borderColor: '#d9d9d9',
                                    borderStyle: 'solid',
                                    borderWidth: '0.5px',
                                    borderRight: '0',
                                }}
                            >
                                <Text strong>{`IPI ID:  ${cpwsId}`}</Text>
                                <Input value={this.state.inputValue} onChange={this.inputValue}></Input>
                            </Col>
                            {this.props.isViewOnlyUser ?
                            <Col
                                span={3}
                                style={{
                                    background: '#fafafa',
                                    padding: '26px 16px 16px 16px',
                                    borderColor: '#d9d9d9',
                                    borderStyle: 'solid',
                                    borderWidth: '0.5px',
                                    borderLeft: '0',
                                    borderRight: '0',
                                }}
                            >                                   
                                <Row>
                                    <Button className="ingest-edit" onClick={() => this.warningPopup(this.props.cpwsId, 'view')}>
                                        {' '}
                                        VIEW{' '}
                                    </Button>
                                </Row>
                            </Col> :
                            <Col
                            span={3}
                            style={{
                                background: '#fafafa',
                                padding: '16px',
                                borderColor: '#d9d9d9',
                                borderStyle: 'solid',
                                borderWidth: '0.5px',
                                borderLeft: '0',
                                borderRight: '0',
                            }}
                        >                            
                           
                            <Row>
                                <Button type='primary' className='button-margin' onClick={() => this.warningPopup(this.props.cpwsId, 'import')}>
                                    {' '}
                                    IMPORT{' '}
                                </Button>
                            </Row>
                            <Row>
                                <Button type='primary' className='button-margin' onClick={() => this.warningPopup(this.props.cpwsId, 'edit')}>
                                    {' '}
                                    EDIT{' '}
                                </Button>
                            </Row>                                
                            <Row>
                                <Button type='primary' className='button-margin' onClick={() => this.warningPopup(this.props.cpwsId, 'view')}>
                                    {' '}
                                    VIEW{' '}
                                </Button>
                            </Row>
                        </Col>
                            }
                            <Col span={17} style={{border: '1px solid #d9d9d9'}}>
                            <IpiIdTitle IpiId={cpwsId} realTitle={realTitle} />
                            </Col>
                        </Row>
                    )}
                </Card>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Define" key="1">
                        <Card  bordered={false}>
                            <Row gutter={16}>
                                <Col span={9}></Col>
                                <Col span={7} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2> Basic Info</h2>
                                </Col>
                            </Row>
                            <hr className="line-style"/>
                            
                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Change Owner:</Text>
                                </Col>
                                <Col span={9}>
                                    <Text>{cpws.basicInfo.change_owner}</Text>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Change Origin:</Text>
                                </Col>
                                <Col span={9}>
                                    <Text>{cpws.basicInfo.change_origin}</Text>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Change Driver:</Text>
                                </Col>
                                <Col span={9}>
                                    <Text>{cpws.basicInfo.change_driver}</Text>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Site/Global:</Text>
                                </Col>
                                <Col span={9}>
                                    <Text>{cpws.basicInfo.site_global}</Text>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Site/Functional Area:</Text>
                                </Col>
                                <Col span={9}>
                                    <Text>{cpws.basicInfo.site_functional_area}</Text>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Change Description:</Text>
                                </Col>
                                <Col span={12}>
                                    <TextArea
                                        id={'comments'}
                                        rows={3}
                                        value={cpws.basicInfo.change_description}
                                        disabled = {true}
                                        style = {{color: 'black'}}
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered={false}>
                            <Row gutter={16}>
                                <Col span={8}></Col>
                                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2> Supporting Details</h2>
                                </Col>
                            </Row>
                            <hr className="line-style"/>
                            <Table
                                dataSource={cpws.supportingDetails}
                                columns={supportingDetailsColumns}
                                pagination={false}
                                rowKey={
                                    '_' +
                                    Math.random()
                                        .toString(36)
                                        .substr(2, 9)
                                }
                                bordered
                            />
                        </Card>
                        <Card  bordered={false}>
                            <Row gutter={16}>
                                <Col span={8}></Col>
                                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2> Additional Info</h2>
                                </Col>
                            </Row>
                            <hr className="line-style"/>
                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Additional Reference: </Text>
                                </Col>
                                <Col span={12}>
                                    <TextArea
                                        id={'additionalReference'}
                                        rows={1}
                                        value={cpws.additionalInfoData.additional_reference}
                                        disabled = {true}
                                        style = {{color: 'black'}}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Additional Considerations: </Text>
                                </Col>
                                <Col span={9}>
                                    <Text>{cpws.additionalInfoData.additional_considerations} </Text>
                                </Col>
                            </Row>
                            {cpws.additionalInfoData.additional_considerations==="Yes"&&
                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Summary: </Text>
                                </Col>
                                <Col span={12}>
                                    <TextArea
                                        id={'additionalConsiderationsSummary'}
                                        rows={2}
                                        value={cpws.additionalInfoData.additional_considerations_summary}
                                        disabled = {true}
                                        style = {{color: 'black'}}
                                    />
                                </Col>
                            </Row>}
                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Product Impact: </Text>
                                </Col>
                                <Col span={9}>
                                    <Text>{cpws.additionalInfoData.product_impact} </Text>
                                </Col>
                            </Row>
                            {cpws.additionalInfoData.product_impact==="Yes"&&
                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Summary: </Text>
                                </Col>
                                <Col span={12}>
                                    <TextArea
                                        id={'productImpactSummary'}
                                        rows={2}
                                        value={cpws.additionalInfoData.product_impact_summary}
                                        disabled = {true}
                                        style = {{color: 'black'}}
                                    />
                                </Col>
                            </Row>
                            }
                        </Card>
                        <Card bordered={false}>
                            <Row gutter={16}>
                                <Col span={8}></Col>
                                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2> Related Changes</h2>
                                </Col>
                            </Row>
                            <hr className="line-style"/>
                            <Table
                                //dataSource={this.props.status === 'proceed' && !this.state.isRelatedChangesLoading ? this.state.relatedChangesData : []}
                                dataSource = {cpws.relatedChanges}
                                columns={relatedChangesColumns}
                                pagination={false}
                                rowKey={
                                    '_' +
                                    Math.random()
                                        .toString(36)
                                        .substr(2, 9)
                                }
                                bordered
                            />
                        </Card>
                        <Card  bordered={false}>
                            <Row gutter={16}>
                                <Col span={8}></Col>
                                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2> Endorsements</h2>
                                </Col>
                            </Row>
                            <hr className="line-style"/>
                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Endorsement Decision: </Text>
                                </Col>
                                <Col span={9}>
                                    <Text>{cpws.endorsements.endorsement_decision} </Text>
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Endorser: </Text>
                                </Col>
                                <Col span={9}>
                                    <Text>{cpws.endorsements.endorser}</Text>
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col span={1}></Col>
                                <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Text>Summary Justification: </Text>
                                </Col>
                                <Col span={12}>
                                    <TextArea
                                        id={'summaryJustification'}
                                        rows={2}
                                        value={cpws.endorsements.summary_justification}
                                        disabled = {true}
                                        style = {{color: 'black'}}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </TabPane>
                    <TabPane tab="Scope" key="2">
                        <Card bordered={false}>
                            <Row gutter={16}>
                                <Col span={8}></Col>
                                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2> Product Information</h2>
                                </Col>
                            </Row>
                            <hr className="line-style"/>
                            <Row gutter={16}>
                                <Col span={7}>
                                    <h4 style={{textAlign: 'center'}}> Product Families ({(cpws.productInformation.product_families != undefined) ? cpws.productInformation.product_families.length : "0"})</h4>
                                    <Table
                                        dataSource={cpws.productInformation.product_families}
                                        columns={productFamilyNames}
                                        pagination={false}
                                        //className= "table-style"
                                        rowKey={
                                            '_' +
                                            Math.random()
                                                .toString(36)
                                                .substr(2, 9)
                                        }
                                        bordered
                                        scroll={{ y: 250 }}
                                        style={{minHeight: '200px'}}
                                    />
                                </Col>
                                <Col span={10}>
                                    <h4 style={{textAlign: 'center'}}> Products ({(cpws.productInformation.products != undefined) ? cpws.productInformation.products.length : "0"})</h4>
                                    <Table
                                        dataSource={cpws.productInformation.products}
                                        columns={products}
                                        scroll={{ y: 250 }}
                                        pagination={false}
                                       // className= "table-style"
                                        rowKey={
                                            '_' +
                                            Math.random()
                                                .toString(36)
                                                .substr(2, 9)
                                        }
                                        bordered
                                        style={{minHeight: '200px'}}
                                    />
                                </Col>
                                <Col span={7}>
                                    <h4 style={{textAlign: 'center'}}> Modalities ({(staticProductInformation.modalities != undefined) ? staticProductInformation.modalities.length : "0"})</h4>
                                    <Table
                                        //dataSource={cpws.productInformation.modalities}
                                        dataSource={staticProductInformation.modalities}
                                        columns={modalities}
                                        pagination={false}
                                        //className= "table-style"
                                        rowKey={
                                            '_' +
                                            Math.random()
                                                .toString(36)
                                                .substr(2, 9)
                                        }
                                        scroll={{ y: 250 }}
                                        bordered
                                        style={{minHeight: '200px'}}
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered={false}>
                            <Row gutter={16}>
                                <Col span={7}></Col>
                                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2> Site & Supplier Information</h2>
                                </Col>
                            </Row>
                            <hr className="line-style"/>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <h4 style={{textAlign: 'center'}}> Plants ({(cpws.plants != undefined) ? cpws.plants.length : "0"})</h4>
                                    <Table
                                        dataSource={cpws.plants}
                                        columns={plants}
                                        pagination={false}
                                        scroll={{ y: 250 }}
                                        //className= "table-style"
                                        rowKey={
                                            '_' +
                                            Math.random()
                                                .toString(36)
                                                .substr(2, 9)
                                        }
                                        bordered
                                    />
                                </Col>
                                <Col span={12}>
                                    <h4 style={{textAlign: 'center'}}> Vendors/Suppliers ({(cpws.suppliers != undefined) ? cpws.suppliers.length : "0"})</h4>
                                    <Table
                                        dataSource={cpws.suppliers}
                                        columns={suppliers}
                                        scroll={{ y: 250 }}
                                        pagination={false}
                                       // className= "table-style"
                                        rowKey={
                                            '_' +
                                            Math.random()
                                                .toString(36)
                                                .substr(2, 9)
                                        }
                                        bordered
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered={false}>
                            <Row gutter={16}>
                                <Col span={7}></Col>
                                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2> Method & Material Information</h2>
                                </Col>
                            </Row>
                            <hr className="line-style"/>
                            <Row gutter={16}>
                                <Col span={14}>
                                    <h4 style={{textAlign: 'center'}}> Materials ({(cpws.materials != undefined) ? cpws.materials.length : "0"})</h4>
                                    <Table
                                        dataSource={cpws.materials}
                                        columns={materials}
                                        pagination={false}
                                        scroll={{ y: 250 }}
                                        rowKey={
                                            '_' +
                                            Math.random()
                                                .toString(36)
                                                .substr(2, 9)
                                        }
                                        bordered
                                    />
                                </Col>
                                <Col span={10}>
                                    <h4 style={{textAlign: 'center'}}> Test Methods ({(cpws.methods != undefined) ? cpws.methods.length : "0"})</h4>
                                    <Table
                                        dataSource={cpws.methods}
                                        columns={methods}
                                        pagination={false}
                                        scroll={{ y: 250 }}
                                        rowKey={
                                            '_' +
                                            Math.random()
                                                .toString(36)
                                                .substr(2, 9)
                                        }
                                        bordered
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered={false}>
                            <Row gutter={16}>
                                <Col span={7}></Col>
                                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2> Market & Partner Information</h2>
                                </Col>
                                
                            </Row>
                            <hr className="line-style"/>
                            <Row gutter={16}>
                                <Col span={12}>
                                    {
                                    //cpws.soldMarkets[cpws.soldMarkets.length + 1].country_name?.toLocaleLowerCase()
                                    }
                                    <h4 style={{textAlign: 'center'}}> Where Sold Countries ({(cpws.soldMarkets != undefined) ? cpws.soldMarkets.length : "0"})</h4>
                                    <Table
                                        dataSource={cpws.soldMarkets}
                                        columns={registeredCountries}
                                        pagination={false}
                                        scroll={{ y: 250 }}
                                        rowKey={
                                            '_' +
                                            Math.random()
                                                .toString(36)
                                                .substr(2, 9)
                                        }
                                        bordered
                                    />
                                </Col>
                                <Col span={12}>
                                    <h4 style={{textAlign: 'center'}}> Partners ({(cpws.partners != undefined) ? cpws.partners.length : "0"})</h4>
                                    <Table
                                        dataSource={cpws.partners}
                                        columns={partners}
                                        pagination={false}
                                        scroll={{ y: 250 }}
                                        rowKey={
                                            '_' +
                                            Math.random()
                                                .toString(36)
                                                .substr(2, 9)
                                        }
                                        bordered
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </TabPane>
                    <TabPane tab="Assessments" key="3">
                    <Card bordered={false}>
                            <Row gutter={16}>
                                <Col span={7}></Col>
                                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2> Impact Analysis</h2>
                                </Col>
                            </Row>
                            <hr className="line-style"/>
                                <Table
                                    dataSource={cpws.impactAnalysis}
                                    columns={impactAnalysis}
                                    pagination={false}
                                    expandable={{
                                        expandedRowRender: record => record.overall_impact==='Yes'&& 
                                        <Tabs defaultActiveKey="1" type="card">
                                            <TabPane tab="Actions" key="3">
                                                <Card bordered={false}>
                                                    <Table columns={actions} dataSource={record.actions} pagination={false} />
                                                </Card>
                                            </TabPane>
                                        </Tabs>,
                                        rowExpandable: record => record.overall_impact === 'Yes',
                                      }}                          
                                    bordered
                                />
                        </Card>
                    </TabPane>
                </Tabs>
            </CPWSStep>
        );
    }
}
