import { Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

interface RegulatoryImpactHeaderProps {
    isRegulatoryImpact: boolean;
}

export const RegulatoryImpactHeader: React.FC<RegulatoryImpactHeaderProps> = (props: RegulatoryImpactHeaderProps) => {
    return (
        <>
            {props.isRegulatoryImpact ? (
                <Title level={4} className="regulatory-impact-header">
                    Potential Regulatory Impact: Yes{' '}
                </Title>
            ) : (
                <Title level={4} className="regulatory-impact-header">
                    Potential Regulatory Impact: No{' '}
                </Title>
            )}
        </>
    );
};
