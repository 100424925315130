import React, { ErrorInfo } from 'react';
import { getAppOptions } from './utils';
import ApiClient from './api/apiClient';
import { Result, Button } from 'antd';
import { ErrorBoundaryContext } from './context/ErrorBoundaryContext';
import { ErrorBoundaryFields } from './model';

interface ErrorBoundaryProps {
    children: JSX.Element;
}

interface ErrorBoundaryState {
    hasError: boolean;
    errorFields: ErrorBoundaryFields;
}

const emptyLogger = {
    setError: (): void => {
        return;
    },
    setCall: (): void => {
        return;
    },
    setException: (): void => {
        return;
    },
    errors: [],
    calls: [],
    exceptions: [],
};

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    api: ApiClient;
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            errorFields: {
                cpwsID: '',
                sessionID: '',
            },
        };
        this.api = new ApiClient(getAppOptions(), emptyLogger);
        this.setErrorFields = this.setErrorFields.bind(this);
    }

    private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
        console.log("new promise rejection handler called");
        console.log("reason:", event.reason);
        /*this.setState({
            hasError: true
            //error: event.reason
        });*/
        this.setState(() => {
            throw new Error;
        })

    }

    static getDerivedStateFromError() {
        console.log('get Derived state from error called');
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.log('component did catch called');
        console.log(error);
        const val: string = window.sessionStorage.getItem('asyncError')!;
        if(val != null && val != "" && val != undefined) {
        //const tmp = JSON.parse(val);
            console.log("Session Error", val);
            errorInfo.componentStack = val;
            const cpwsID = window.sessionStorage.getItem('cpwsID')!;
            const sessionID = window.sessionStorage.getItem('sessionID')!;
            this.api.postFrontendError(error, errorInfo, cpwsID, sessionID);
        } else {
            this.api.postFrontendError(error, errorInfo, this.state.errorFields.cpwsID, this.state.errorFields.sessionID);
        }
    }
    componentDidMount() {
        // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
        window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
    }

    handleClick() {
        location.reload();
    }

    setErrorFields(fields: ErrorBoundaryFields) {
        console.log("set Error Fields function called");        
        this.setState({
            errorFields: { ...this.state.errorFields, ...fields },
            //hasError: true
        });
    }

    render() {
        console.log("Error BOundary Components render called");
        if (this.state.hasError) {
            return (
                <Result
                    title="Sorry, something has failed in the application."
                    subTitle="This error which caused this issue has been recorded and the business team will automatically be notified. Refresh the page to reload the application."
                    status="error"
                    extra={
                        <Button type="primary" key="refresh" onClick={this.handleClick}>
                            Refresh
                        </Button>
                    }
                />
            );
        }
        return (
            <ErrorBoundaryContext.Provider
                value={{ ...this.state.errorFields, setErrorBoundaryFields: this.setErrorFields }}
            >
                {this.props.children}
            </ErrorBoundaryContext.Provider>
        );
    }
}
