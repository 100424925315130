import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import 'antd/dist/antd.css';
import { BrowserRouter } from 'react-router-dom';
import Amplify from '@aws-amplify/core';
import config from './config';

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        oauth: {
            domain: config.cognito.USER_POOL_URI,
            scope: config.cognito.TOKEN_SCOPES,
            redirectSignIn: config.cognito.CALLBACK_URI,
            redirectSignOut: config.cognito.CALLBACK_URI,
            responseType: 'code',
        },
    },
});

ReactDOM.render(
    <BrowserRouter>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </BrowserRouter>,
    document.getElementById('root'),
);
