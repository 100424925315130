import { Col, Row, Typography, Card, Collapse } from 'antd';
import React from 'react';
import { Site, SiteFunction, PanelProps } from '../model';
import { PDSSiteSelect } from './PDSSiteSelect';
import { PDSSiteFunctionSelect } from './PDSSiteFunctionSelect';
import { FormContext } from '../context/FormContext';

const { Text } = Typography;
const { Panel } = Collapse;

export interface SiteFunctionsProps {
    header: string;
    panelProps: PanelProps;
    impactedSites: Array<Site>;
    siteFunctions: Array<SiteFunction>;
    onFunctionSelect: Function;
    onFunctionDeselect: Function;
    onSiteSelect: Function;
    onSiteDeselect: Function;
    siteFunctionRefs: Array<{
        name: string;
        ref: React.RefObject<HTMLDivElement>;
    }>;
    siteFunctionCardRef: React.RefObject<HTMLDivElement>;
}

export const SiteFunctions: React.FC<SiteFunctionsProps> = (props: SiteFunctionsProps) => {
    const {
        header,
        panelProps,
        impactedSites,
        siteFunctions,
        onFunctionSelect,
        onFunctionDeselect,
        onSiteSelect,
        onSiteDeselect,
        siteFunctionRefs,
        siteFunctionCardRef,
    } = props;

    const generatePDSSites = (siteFunctions: Array<SiteFunction>, impactedSites: Array<Site>) => {
        return (
            <>
                <Col span={4}></Col>
                <Col span={4} style={{ paddingBottom: '16px', display: 'flex', alignItems: 'center' }}>
                    <Text>PDS Sites:</Text>
                </Col>
                <Col span={12} style={{ paddingBottom: '16px', display: 'flex', alignItems: 'center' }}>
                    <PDSSiteSelect
                        onSiteDeselect={onSiteDeselect}
                        onSiteSelect={onSiteSelect}
                        impactedSites={impactedSites}
                        siteFunctions={siteFunctions}
                    />
                </Col>
                <Col span={4}></Col>
            </>
        );
    };

    const generateSiteFunctions = (impactedSites: Array<Site>, siteFunctions: Array<SiteFunction>) => {
        return impactedSites.reduce((sites: Array<JSX.Element>, site: Site) => {
            if (site.isPDS) {
                sites.push(
                    <PDSSiteFunctionSelect
                        site={site}
                        siteFunctions={siteFunctions}
                        onSiteFunctionSelect={onFunctionSelect}
                        onSiteFunctionDeselect={onFunctionDeselect}
                        key={site.name}
                        siteFunctionRefs={siteFunctionRefs}
                    />,
                );
            }
            return sites;
        }, []);
    };

    return (
        <div ref={siteFunctionCardRef}>
            <Card bordered={false}>
                <Collapse {...panelProps}>
                    {/* <Panel header={header} key="1"> */}
                        <FormContext.Consumer>
                            {({ viewOnly }) => (
                                <>
                                    <Row gutter={16} className={viewOnly ? 'view-only' : ''}>
                                        {generatePDSSites(siteFunctions, impactedSites)}
                                    </Row>
                                    {generateSiteFunctions(impactedSites, siteFunctions)}
                                </>
                            )}
                        </FormContext.Consumer>
                    {/* </Panel> */}
                </Collapse>
            </Card>
        </div>
    );
};
