import React from 'react';
import { Button, Card, Col, Collapse, Row } from 'antd';
import { PanelProps, RegulatoryQuestions, RegulatoryQuestionValue } from '../model';
import { someRegulatoryQuestionsAnswered } from '../utils';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import findKey from 'lodash/findKey';
import { RegulatoryQuestion } from './RegulatoryQuestion';

const { Panel } = Collapse;

interface RegulatoryQuestionsPanelProps {
    panelProps: PanelProps;
    regulatoryQuestions: RegulatoryQuestions;
    onRegulatoryQuestionSelection?: Function;
    current?: number;
    regulatoryQuestionsRef?: React.RefObject<HTMLDivElement>;
}

interface Selections {
    [key: string]: string | boolean;
}

interface QuestionVal {
    text: string;
    children?: RegulatoryQuestionText;
}

interface RegulatoryQuestionText {
    [key: string]: QuestionVal;
}

const questionText = {
    question1: {
        text: 'Is the scope of the CPWS in support of a new product registration?',
        children: {
            childQuestion1: {
                text: 'Does this change have potential to impact the original marketing application (OMA)?',
            },
        },
    },
    question2: {
        text: 'Is the scope of the CPWS in support of a product, image (i.e.strength) or presentation deletion?',
    },
    question3: { text: 'Is the scope of the CPWS to update the artwork and/or labelling with no other change?' },
    question4: {
        text:
            'Is the scope of the CPWS in support of a dossier conformance or was the change raised in support of a dossier update?',
    },
    question5: { text: 'Is the scope of the CPWS in support of health authority questions or commitments?' },
};

export const RegulatoryQuestionsPanel: React.FC<RegulatoryQuestionsPanelProps> = (
    props: RegulatoryQuestionsPanelProps,
) => {
    const [selections, setSelections] = React.useState<Selections>({
        question1: '',
        question2: '',
        question3: '',
        question4: '',
        question1ChildQuestion1: '',
        question5: '',
    });
    const noToAll = (): void => {
        setSelections(mapValues(selections, () => false));
        if (props.onRegulatoryQuestionSelection) {
            props.onRegulatoryQuestionSelection(false, 'noToAll');
        }
    };
    const radioButtonSelected = (value: boolean, name: string): void => {
        if (value && !name.includes('ChildQuestion')) {
            const tempSelections: { [key: string]: boolean | string } = mapValues(
                selections,
                (val: string | boolean, key: string) => (!key.includes('ChildQuestion') ? false : ''),
            );
            tempSelections[name] = value;
            setSelections(tempSelections);
        } else {
            setSelections({
                ...selections,
                [name]: value,
            });
        }

        if (props.onRegulatoryQuestionSelection) {
            props.onRegulatoryQuestionSelection(value, name);
        }
    };

    const populateSelections = (
        userSelections: Selections,
        regulatoryQuestions: RegulatoryQuestions,
        parent?: string,
    ) => {
        forEach(regulatoryQuestions, (val: RegulatoryQuestionValue, key: string) => {
            if (key.includes('childQuestion')) {
                userSelections[`${parent}C${key.slice(1)}`] = val.isAnswered ? val.answer : '';
            } else {
                userSelections[key] = val.isAnswered ? val.answer : '';
            }
            if (Object.prototype.hasOwnProperty.call(val, 'children')) {
                populateSelections(userSelections, val.children as RegulatoryQuestions, key);
            }
        });
        return userSelections;
    };

    React.useEffect(() => {
        if (someRegulatoryQuestionsAnswered(props.regulatoryQuestions)) {
            const userSelections = populateSelections({}, props.regulatoryQuestions);
            setSelections(userSelections);
        }
    }, []);

    const renderRegulatoryQuestions = (
        regulatoryQuestions: RegulatoryQuestions,
        questionText: RegulatoryQuestionText,
        isChild?: boolean,
        parentKey?: string,
    ): Array<JSX.Element> => {
        /**
         * If we are in view mode for a file where a child question does not exist, we will not show the child question.
         */
        return map(questionText, (obj: QuestionVal, key: string) => {
            return (
                <FormContext.Consumer>
                    {({ viewOnly }) => (
                        <>
                            {viewOnly &&
                            isChild &&
                            parentKey &&
                            !findKey(
                                regulatoryQuestions[parentKey].children,
                                (val: RegulatoryQuestionValue, regKey: string) => key === regKey,
                            ) ? null : (
                                <>
                                    <RegulatoryQuestion
                                        questionText={obj.text}
                                        onSelection={radioButtonSelected}
                                        questionName={key}
                                        selections={selections}
                                        current={props.current ? props.current : undefined}
                                        isChild={isChild}
                                        parent={parentKey ? parentKey : undefined}
                                    />
                                    {Object.prototype.hasOwnProperty.call(obj, 'children') &&
                                    typeof selections[key] === 'boolean' &&
                                    selections[key]
                                        ? renderRegulatoryQuestions(
                                              regulatoryQuestions,
                                              obj.children as RegulatoryQuestionText,
                                              true,
                                              key,
                                          )
                                        : null}
                                </>
                            )}
                        </>
                    )}
                </FormContext.Consumer>
            );
        });
    };

    return (
        <FormContext.Consumer>
            {({ regulatoryQuestionsAnswered }) => (
                <div ref={props.regulatoryQuestionsRef}>
                    <Card bordered={false}>
                        <Collapse {...props.panelProps} defaultActiveKey={['regulatoryQuestions']}>
                            <Panel
                                header="Regulatory Questions"
                                key="regulatoryQuestions"
                                extra={
                                    props.current && props.current !== 7 ? (
                                        <Button
                                            type="primary"
                                            onClick={e => {
                                                e.stopPropagation();
                                                noToAll();
                                            }}
                                        >
                                            No to All
                                        </Button>
                                    ) : null
                                }
                            >
                                <Row
                                    gutter={1}
                                    className={
                                        !regulatoryQuestionsAnswered
                                            ? 'panel-border panel-border-error'
                                            : 'panel-border'
                                    }
                                >
                                    {!regulatoryQuestionsAnswered ? (
                                        <Col span={24} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
                                            <FormValidationErrorText text="Please answer all regulatory questions." />
                                        </Col>
                                    ) : null}
                                    {renderRegulatoryQuestions(props.regulatoryQuestions, questionText)}
                                </Row>
                            </Panel>
                        </Collapse>
                    </Card>
                </div>
            )}
        </FormContext.Consumer>
    );
};
