export default {
    apiGateway: {
        REGION: process.env.REACT_APP_REGION,
        URL: process.env.REACT_APP_API_GATEWAY_URL,
    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        USER_POOL_URI: process.env.REACT_APP_COGNITO_USER_POOL_URI,
        CALLBACK_URI: process.env.REACT_APP_COGNITO_CALLBACK_URI,
        TOKEN_SCOPES: process.env.REACT_APP_COGNITO_TOKEN_SCOPES?.split(', '),
    },
    distributionListName: process.env.REACT_APP_DISTRIBUTION_LIST_NAME,
};
