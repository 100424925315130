import { Card, Collapse } from 'antd';
import React from 'react';
import { PanelProps } from '../model';

const { Panel } = Collapse;

export interface ReasonForChangeProps {
    reasonForChange: string;
    panelProps: PanelProps;
}
export const ReasonForChange: React.FC<ReasonForChangeProps> = (props: ReasonForChangeProps) => {
    const { reasonForChange } = props;
    const hasValue: boolean = reasonForChange ? true : false;

    return (
        <Card bordered={false}>
            <Collapse {...props.panelProps} defaultActiveKey={['reasonForChange']}>
                <Panel header="Reason For Change" key="reasonForChange">
                    {hasValue ? reasonForChange : '[Not Entered]'}
                </Panel>
            </Collapse>
        </Card>
    );
};
