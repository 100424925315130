import { Card, Collapse, Table } from 'antd';
import React from 'react';
import { PanelProps, DownstreamMaterial } from '../model';

const { Panel } = Collapse;

export interface DownstreamMaterialsListProps {
    header: string;
    downstreamMaterials: Array<DownstreamMaterial>;
    panelProps: PanelProps;
}
export class DownstreamMaterialList extends React.Component<DownstreamMaterialsListProps> {
    render(): JSX.Element {
        const columns = [
            {
                title: 'Impacted Material',
                dataIndex: 'name',
            },
            {
                title: 'Downstream Material',
                dataIndex: 'tradeName',
            },
        ];
        return (
            <Card bordered={false}>
                <Collapse {...this.props.panelProps}>
                    <Panel header={this.props.header} key="1">
                        <Table
                            size="small"
                            rowKey={(record: any): string => {
                                return JSON.stringify(record);
                            }}
                            columns={columns}
                            dataSource={this.props.downstreamMaterials}
                        />
                    </Panel>
                </Collapse>
            </Card>
        );
    }
}
