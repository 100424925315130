import React from 'react';
import {
    AdditionalInfoComments,
    ChangeDescription,
    CPWSHeader,
    MaterialClassification,
    QualityAssessmentStatements,
    ReasonForChange,
    TechnicalInformation,
    FinalAssessmentQuestions,
    AssessmentSummaries,
    DetailedAssessments,
    AssessmentSelections,
    RegulatoryQuestionsPanel,
    ProductsSitesDisplay
} from '../components';

import { CPWS, CPWSClassification, CPWSClassificationCodes, GlobalProps, Country, DirectAssessment, RegulatoryQuestions, MarketLevelAssessments, DataRequirements, ProductInformation, ProductModalitySelections, SelectedMaterials,
    ExternalMaterial,
    ImpactedMaterialsRef,
    Materials, ProductModality, Site, Matchable, ColorTag,MatchCriteria } from '../model';
import { CPWSStep } from './screens';
import { IpiIdTitle } from '../components/IpiIdTitle';
import { ChangeInformation } from '../components/ChangeInformation';
import './screens.css';
import { Card, Collapse, Typography, notification } from 'antd';
const { Text } = Typography;
const { Panel } = Collapse;
import _ from 'lodash';

import ApiClient from '../api/apiClient';
import {
    mapExternalMaterialFromJSON,
    mapBasicInfoData,
    mapSupportingDetails,
    mapAdditionalInfoData,
    mapRelatedChangesData,
    mapEndorsementsData,
    mapProductInformation,
    mapSuppliers,
    mapMaterials,
    mapMethods,
    mapPartners,
    mapPlants,
    mapRegisteredMarkets,
    mapImpactAnalysis,
} from '../api/apiMapper';

export interface Screen5Props {
    cpws: CPWS;
    globalProps: GlobalProps;
    nriOption: string;
    rationaleText: string;
    determineRegulatoryImpact: Function;
    finalAssessmentRef: React.RefObject<HTMLDivElement>;
    userAssessment: string;
    userAssessmentRationale: string;
    additionalNotes: string;
    cakeSuggestions: string;
    updateFinalAssessmentField: Function;
    clearFinalAssessmentField: Function;
    reviewer: string;
    loadFinalResults: Function;
    loadMatchCriteriaYesRegulation: Function;
    sessionUpdate: Function;
    regulatoryQuestions: RegulatoryQuestions;
    productModalitiesSelections: any;
    api: ApiClient;
    savePratInfo: Function;
    savesubmission_req_details: Function;
    currentPratInfo: object;
    current_submission_req_info: any;
    matchCriteriaYesRegulation: Array<any>
    directionalAssessment: DirectAssessment;
    dataRequirements: DataRequirements;
    impactedCountries: Array<Country>;
    marketLevelAssessment: any;
    openAttachmentApi: Function;
    viewOnly: boolean;
    savePlanningAssessmentType: Function;
    currentPlanningAssessmentType: any;
    saveDirectionalAssessment: Function;
    currentDirectionalAssessmentReason: string;
    prat: object;
    system_nri_ri: boolean;
    user_nri_ri: string;
    biologic_nri_ri: string;
    vaccine_nri_ri: string;
    smallmolecule_nri_ri: string;
    scope_change_question: string;
    screen4_update: boolean;
    setScreen4Update: Function;
    staticProductInformation: ProductInformation;
    category: any;
    current: number;
    sterilization: any;
    userSelectionCategory: any;
    userSelectionSterilization: any;
    onProductModalityChange: any;
    userSelectionProductModality: ProductModalitySelections;
    updateQaQuestion: Function;
    qaQuestionAnswered: boolean;
    impactedMaterials: any;
    classificationList: CPWSClassification;
    loadedClassification: any;
    classificationCodes: CPWSClassificationCodes;
    loadClassificationListFallback: Function;
    loadClassificationList: Function;
    justification: string;
    impactedProducts: Matchable<ProductModality>;
    impactedSites: Matchable<Site>;
    colorTags: {
        productColorTags: ColorTag;
        siteColorTags: ColorTag;
    };
    removeManuallyEnteredMaterials: Function;
    downstreamImpactedMaterialsAvailableOption: any;
    impactedProductMaterials?:any;
    updateMaterial?: any;
    removeMaterialType?: any;
    loading: any;
    loadMaterialType: Function;
    materialTypeWithCount: Array<any>;
    triggerApplyAllImpactedMaterials?: Function;
    classificationListApplyAll:Function;
    printPDF?: boolean;
    impactedMaterialsPageNumber?: number;
    onPageChange?: Function;
    materialTypeRef?: React.RefObject<HTMLDivElement>;
    addMaterialContainerClosure?: Function;
    removeMaterialContainerClosure?: Function;
    containerClosureApplyAll?: Function;
    deleteExternalMaterial?: Function;
    editExternalMaterial?: Function;
    addCustomMaterialContainerClosure?: Function;
    addDownstreamMaterial?: Function;
    getDownstreamMaterials: Function;
    getImpactedProducts: Function;
    getImpactedMaterials: Function;
    externalMaterials?: Array<ExternalMaterial>;
    removeExternalMaterialFromContainerClosure?: Function;
    impactedMaterialsRef?: ImpactedMaterialsRef;
    onContainerClosurePageChage: Function;
    expandedRowKeys: Array<string>;
    setExpandedRowKeys: Function;
    userSelectedMaterials: Array<SelectedMaterials>;
    updateCurrentTab?: Function;
    updateDownstreamImpactedMaterialsOption: Function;
    currentScreen: any;
    materials: any;
    materialEvents: any;
    impactedMaterialsAvailable: any;
    impactedMaterialsAvailableOption: any;
    addMaterial: any;
    externalImpactedMaterials: any;
    deleteMaterial: any;
    materialTableRef: any;
    updateUserSelectedMaterialProps: any;
    updateImpactedMaterialsOption: any;
    userSelectedExternalMaterials: any;
    areImpactedMaterialsMissing: any;
    directionalDetails: any;
    matchCriteria: MatchCriteria;
}
export interface Screen5State {
    loading: boolean;
}

let pratModalities: any = {};
export class Screen5 extends React.Component<Screen5Props, Screen5State> {
    state = { loading: true, checked: '', printPDF: false };

    async componentDidMount(): Promise<void> {
        window.scrollTo(0, 0);        
        await Promise.all([this.props.loadFinalResults(), this.props.loadMatchCriteriaYesRegulation()]);
        if(window.sessionStorage.getItem("ApiFlagError") != "true") {
            this.props.sessionUpdate();
            this.setState({ loading: false });

            const paramModalities: any = [];
            if(this.props.productModalitiesSelections['biologic']) {paramModalities.push('B')};
            if(this.props.productModalitiesSelections['smallMolecule']) {paramModalities.push('SM')};
            if(this.props.productModalitiesSelections['vaccine']) {paramModalities.push('V')};
            
            const additional_inputs = await this.props.api.getFinalAssesmentAdditionalInputs(
                paramModalities
            );

            pratModalities = additional_inputs;
        }
    }

    isRegulatoryImpact() {
        const regulatoryImpact = this.props.determineRegulatoryImpact(
            this.props.regulatoryQuestions,
            this.props.matchCriteriaYesRegulation,
        )

        return regulatoryImpact;
    }

    render(): JSX.Element {
        const { cpws, globalProps, directionalAssessment, dataRequirements, staticProductInformation, impactedCountries, matchCriteriaYesRegulation, marketLevelAssessment, openAttachmentApi } = this.props;
        const realTitle = cpws.title ? cpws.title : 'No data was loaded. ';

        const system_ri_result = this.props.system_nri_ri === true ? 'Regulatory Impact' : 'No Regulatory Impact';
        return (
            <CPWSStep>
                <div  style={{border: '1px solid #d9d9d9', margin: '0px 24px'}}>
                    <IpiIdTitle IpiId={cpws.cpwsId} realTitle={realTitle} />
                </div>
                <h2 style={{paddingLeft: '1.2em', paddingTop: '.5em'}}>CAKE Assessment: {system_ri_result}</h2>
                
                <div className="div-margins">
                {
                    ((directionalAssessment.B != undefined && directionalAssessment.B.majorMarkets.total.length > 0) ||
                    (directionalAssessment.SM != undefined && directionalAssessment.SM.majorMarkets.total.length > 0) ||
                    (directionalAssessment.V != undefined && directionalAssessment.V.majorMarkets.total.length > 0) ||
                    (!_.isEmpty(dataRequirements))) ? 
                    <AssessmentSummaries
                        dataRequirements={dataRequirements}
                        impactedCountries={impactedCountries}
                        directionalAssessment={directionalAssessment}
                        loading={this.state.loading}                        
                    />
                    : ""  
                }
                </div>

                { <div className="div-margins">
                    <DetailedAssessments
                        matchCriteriaYesRegulation={matchCriteriaYesRegulation}
                        impactedCountries={impactedCountries}
                        openAttachmentApi={openAttachmentApi}
                        cpws={cpws}
                        loading={this.state.loading}
                        globalProps={globalProps}
                        printPDF={this.state.printPDF}
                        regulatoryQuestions={this.props.regulatoryQuestions}
                        marketLevelAssessment={marketLevelAssessment}
                        productModalitiesSelections={this.props.productModalitiesSelections}
                    />
                </div> }

                <div className="div-margins">
                    <FinalAssessmentQuestions
                        productModalitiesSelections={this.props.productModalitiesSelections}
                        viewOnly={this.props.viewOnly}
                        cpws={this.props.cpws}
                        api={this.props.api}
                        panelProps={globalProps.defaultPanelProps}
                        nriOption={this.props.nriOption}
                        rationaleText={this.props.rationaleText}
                        isRegulatoryImpact={this.isRegulatoryImpact()}
                        printPDF={this.state.printPDF}
                        finalAssessmentRef={this.props.finalAssessmentRef}
                        userAssessmentRationale={this.props.userAssessmentRationale}
                        additionalNotes={this.props.additionalNotes}
                        cakeSuggestions={this.props.cakeSuggestions}
                        updateFinalAssessmentField={this.props.updateFinalAssessmentField}
                        clearFinalAssessmentField={this.props.clearFinalAssessmentField}
                        reviewer={this.props.reviewer}
                        savePratInfo={this.props.savePratInfo}
                        savesubmission_req_details={this.props.savesubmission_req_details}
                        currentPratInfo={this.props.currentPratInfo}
                        current_submission_req_info={this.props.current_submission_req_info}
                        savePlanningAssessmentType={this.props.savePlanningAssessmentType}
                        currentPlanningAssessmentType={this.props.currentPlanningAssessmentType}
                        saveDirectionalAssessment={this.props.saveDirectionalAssessment}
                        currentDirectionalAssessmentReason={this.props.currentDirectionalAssessmentReason}
                        prat={this.props.prat}
                        system_nri_ri={this.props.system_nri_ri}
                        user_nri_ri={this.props.user_nri_ri}
                        biologic_nri_ri={this.props.biologic_nri_ri}
                        vaccine_nri_ri={this.props.vaccine_nri_ri}
                        smallmolecule_nri_ri={this.props.smallmolecule_nri_ri}
                        scope_change_question={this.props.scope_change_question}
                        directionalDetails={this.props.directionalDetails}
                        screen4_update={this.props.screen4_update}
                        setScreen4Update={this.props.setScreen4Update}
                        matchCriteria={this.props.matchCriteria}
                    />
                </div>
                
                <ChangeInformation
                    cpws={cpws}
                    staticProductInformation={staticProductInformation}
                />

                <div className="div-margins">
                    <AssessmentSelections
                        cpws={this.props.cpws}
                        globalProps={this.props.globalProps}
                        category={this.props.category}
                        sterilization={this.props.sterilization}
                        userSelectionCategory={this.props.category}
                        userSelectionSterilization={this.props.sterilization}
                        current={this.props.current}
                        onProductModalityChange={this.props.onProductModalityChange}
                        userSelectionProductModality={this.props.userSelectionProductModality}
                        updateQaQuestion={this.props.updateQaQuestion}
                        qaQuestionAnswered={this.props.qaQuestionAnswered}
                        loading={this.props.loading}
                        updateMaterial={this.props.updateMaterial}
                        impactedMaterials={this.props.impactedMaterials}
                        classificationList={this.props.classificationList}
                        loadedClassification={this.props.loadedClassification}
                        classificationCodes={this.props.classificationCodes}
                        loadClassificationList={this.props.loadClassificationList}
                        classificationListApplyAll={this.props.classificationListApplyAll}
                        loadClassificationListFallback={this.props.loadClassificationListFallback}
                        regulatoryQuestions={this.props.regulatoryQuestions}
                        justification={this.props.justification}
                        impactedProducts={this.props.impactedProducts}
                        impactedSites={this.props.cpws.impactedSites}
                        colorTags={this.props.colorTags}
                        removeMaterialContainerClosure={this.props.removeMaterialContainerClosure}
                        containerClosureApplyAll={this.props.containerClosureApplyAll}
                        deleteExternalMaterial={this.props.deleteExternalMaterial}
                        editExternalMaterial={this.props.editExternalMaterial}
                        addCustomMaterialContainerClosure={this.props.addCustomMaterialContainerClosure}
                        addDownstreamMaterial={this.props.addDownstreamMaterial}
                        getDownstreamMaterials={this.props.getDownstreamMaterials}
                        getImpactedProducts={this.props.getImpactedProducts}
                        getImpactedMaterials={this.props.getImpactedMaterials}
                        externalMaterials={this.props.externalMaterials}
                        removeExternalMaterialFromContainerClosure={this.props.removeExternalMaterialFromContainerClosure}
                        impactedMaterialsRef={this.props.impactedMaterialsRef}
                        onContainerClosurePageChage={this.props.onContainerClosurePageChage}
                        expandedRowKeys={this.props.expandedRowKeys}
                        setExpandedRowKeys={this.props.setExpandedRowKeys}
                        userSelectedMaterials={this.props.userSelectedMaterials}
                        updateCurrentTab={this.props.updateCurrentTab}
                        updateDownstreamImpactedMaterialsOption={this.props.updateDownstreamImpactedMaterialsOption}
                        materials={this.props.materials}
                        materialTypeWithCount={this.props.materialTypeWithCount}
                        removeManuallyEnteredMaterials={this.props.removeManuallyEnteredMaterials}
                        downstreamImpactedMaterialsAvailableOption={this.props.downstreamImpactedMaterialsAvailableOption}
                        loadMaterialType={this.props.loadMaterialType}
                        materialEvents={this.props.materialEvents}
                        impactedMaterialsAvailable={this.props.impactedMaterialsAvailable}
                        impactedMaterialsAvailableOption={this.props.impactedMaterialsAvailableOption}
                        addMaterial={this.props.addMaterial}
                        externalImpactedMaterials={this.props.externalImpactedMaterials}
                        deleteMaterial={this.props.deleteMaterial}
                        materialTableRef={this.props.materialTableRef}
                        updateUserSelectedMaterialProps={this.props.updateUserSelectedMaterialProps}
                        updateImpactedMaterialsOption={this.props.updateImpactedMaterialsOption}
                        userSelectedExternalMaterials={this.props.userSelectedExternalMaterials}
                        areImpactedMaterialsMissing={this.props.areImpactedMaterialsMissing}
                    />
                </div>
                
                {/* <ChangeDescription
                    currentChange={cpws.changeDescCurrent}
                    proposedChange={cpws.changeDescProposed}
                    panelProps={globalProps.defaultPanelProps}
                />
                <QualityAssessmentStatements
                    qualityAssessment={cpws.qualityAssessment}
                    panelProps={this.props.globalProps.defaultPanelProps}
                />
                <TechnicalInformation
                    technicalInformation={this.props.cpws.technicalInformation}
                    panelProps={globalProps.defaultPanelProps}
                ></TechnicalInformation>
                <AdditionalInfoComments
                    additionalInfoComments={cpws.additionalInfoComments}
                    panelProps={globalProps.defaultPanelProps}
                ></AdditionalInfoComments>
                <ReasonForChange
                    reasonForChange={cpws.reasonForChange}
                    panelProps={globalProps.defaultPanelProps}
                ></ReasonForChange>
                <Card bordered={false}>
                    <Collapse {...globalProps.defaultPanelProps} defaultActiveKey={['attachments']}>
                        <Panel header="CPWS Attachments" key="attachments">
                            {cpws.attachments && cpws.attachments.length > 0 ? (
                                <div className="attachments-container">
                                    {cpws.attachments.map((attachment, index) => {
                                        const attachNameRegex = /.*(\/.*)$/gm;
                                        const attachName = attachNameRegex.exec(attachment);
                                        if (attachName !== null) {
                                            return (
                                                <a
                                                    className="attachments"
                                                    key={index + 1}
                                                    href={attachment}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {attachName[1].slice(1)}
                                                </a>
                                            );
                                        }
                                    })}
                                </div>
                            ) : (
                                <div>No CPWS Attachments...</div>
                            )}
                            <h3>Supplemental Comments (from PDF or other sources)</h3>
                            {this.props.comment === '' ? <Text>N/A</Text> : <Text>{this.props.comment}</Text>}
                        </Panel>
                    </Collapse>
                </Card> */}
            </CPWSStep>
        );
    }
}
