import { Card, Col, Collapse, Row, Spin, Typography, List } from 'antd';
import React from 'react';

import {
    MatchCriteria,
    PanelProps,
    Question,
    CPWS,
    ChildQuestion,
    MatchCriteriaAnswer,
    MaterialCodeMapping,
    Material,
    CPWSClassificationCodes,
    MaterialType,
    ContainerClosureMaterial,
} from '../model';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';
import { MatchCriteriaQuestionsSelections } from './MatchCriteriaQuestionsSelections';
import { TreeIcon } from './TreeIcon';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import uniq from 'lodash/uniq';
import union from 'lodash/union';
import isEqual from 'lodash/isEqual';
import flatten from 'lodash/flatten';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';

const { Panel } = Collapse;
const { Title, Text } = Typography;

export interface MatchCriteriaProps {
    panelProps: PanelProps;
    matchCriteria: MatchCriteria;
    loading: boolean;
    updateMatchQuestion: Function;
    cpws: CPWS;
    matchCriteriaAnswers: MatchCriteriaAnswer;
    matchCriteriaCardRef: React.RefObject<HTMLDivElement>;
    materialCodeMapping: MaterialCodeMapping;
    impactedMaterials: Array<Material>;
    classificationCodes: CPWSClassificationCodes;
    screen4_update?: boolean;
    setScreen4Update?: Function;
}

interface QuestionMapping {
    [key: string]: Array<string>;
}
interface ParentSelections {
    [key: string]: Array<string> | boolean | string;
}

interface TreeVisibility {
    [key: string]: boolean;
}

type MatchCriteriaSelection = boolean | Array<string>;

export const MatchCriteriaQuestions: React.FC<MatchCriteriaProps> = (props: MatchCriteriaProps) => {
    const {
        panelProps,
        matchCriteria,
        updateMatchQuestion,
        matchCriteriaAnswers,
        matchCriteriaCardRef,
        materialCodeMapping,
        impactedMaterials,
        classificationCodes,
        screen4_update,
        setScreen4Update
    } = props;

    const formContext = React.useContext(FormContext);

    const [parentSelections, setParentSelections] = React.useState<ParentSelections>({});
    const [formValidation, setFormValidation] = React.useState<boolean>(formContext.matchCriteriaAnswered);
    const [treeVisibility, setTreeVisibility] = React.useState<TreeVisibility>({});
    const [missingHeadings, setMissingHeadings] = React.useState<Array<string>>([]);
    const [duplicateChildSelected, setDuplicateChildSelected] = React.useState<boolean>();

    const isTopLevelQuestion = (childCode: string, materialTypeCode: string, childDef: string) =>
        matchCriteria.some(
            (question: Question) =>
                (question.parentCode === childCode || question.parentDef === childDef) &&
                question.materialTypeCode === materialTypeCode,
        );

    const isQuestionSingleton = (question: Question) =>
        question.parentCode.startsWith('MC') && question.children.length === 0;

    const isQuestionParent = (question: Question) => {
        return matchCriteria.some(
            (parentQuestion: Question) =>
                parentQuestion.parentCode.startsWith('PMC') &&
                parentQuestion.parentDef === question.parentDef &&
                parentQuestion.parentCode !== question.parentCode &&
                parentQuestion.materialTypeCode === question.materialTypeCode,
        );
    };

    const isDuplicateChild = (childQuestion: ChildQuestion) => {
        // does there exist a child with the same MTC code, same impacted material type and same classification? If so, then yes.
        return matchCriteria.some((question: Question) => {
            if (
                question.parentCode.startsWith('PMC') &&
                question.parentCode !== childQuestion.parentCode &&
                question.materialTypeCode === childQuestion.materialTypeCode &&
                question.classificationLabel === childQuestion.classificationLabel
            ) {
                return question.children.some((child: ChildQuestion) => {
                    return child.mtcCode === childQuestion.mtcCode;
                });
            }
        });
    };

    const findChild = (mtcCode: string) => {
        //console.log("find child called");
        const regex = /(T\d+)_(MC\d+)/m;
        const match = regex.exec(mtcCode);
        if (match) {
            return matchCriteria.reduce((matchedQuestion: ChildQuestion | undefined, question: Question) => {
                const child = find(question.children, (child: ChildQuestion) => {
                    return child.mtcCode === match[2] && child.materialTypeCode === match[1];
                });
                if (child) {
                    matchedQuestion = child;
                    return matchedQuestion;
                } else {
                    return matchedQuestion;
                }
            }, undefined);
        } else {
            return undefined;
        }
    };

    const getClassificationName = (code: string) => {
        //console.log("get classification name called");
        const codeObj = find(classificationCodes, ['code', code]);
        return codeObj ? codeObj.definition : code;
    };

    const getParentQuestionStructure = (matchCriteria: MatchCriteria) => {
        //console.log("get parent question structure called");
        //console.log('matchCriteria: ', matchCriteria)

        return matchCriteria.reduce((questionMapping: any, question: Question) => {
            if (
                `${question.materialTypeCode}_${question.parentCode}` in questionMapping &&
                !questionMapping[`${question.materialTypeCode}_${question.parentCode}`].includes(
                    question.classificationLabel
                    )
            ) {
                //console.log("old if condition:", question.classificationLabel);
                questionMapping[`${question.materialTypeCode}_${question.parentCode}`].push(
                    question.classificationLabel,
                    question.isContainerClosure
                );
                questionMapping[`${question.materialTypeCode}_${question.parentCode}`].sort();
            } else if (!(`${question.materialTypeCode}_${question.parentCode}` in questionMapping)) {
                //console.log("old else condition:", question.classificationLabel);
                questionMapping[`${question.materialTypeCode}_${question.parentCode}`] = [question.classificationLabel,question.isContainerClosure];
            }
            //console.log("Question Mapping Old:", questionMapping);
            return questionMapping;
        }, {});
        /*return matchCriteria.reduce((questionMapping: any, question: Question) => {
            //console.log('questionMapping up top: ', Object.keys(questionMapping).includes('T10_PMC1a'))
            const materialTypeCode: any = question.materialTypeCode;
            const parentCode: string = question.parentCode;
            const matchCodes: string = `${materialTypeCode}_${parentCode}`;
            //console.log("Mapping keys: ", Object.keys(questionMapping))
            //console.log("Mathc code: ", matchCodes)
            //console.log("Maping? ", Object.keys(questionMapping).includes(matchCodes))
            if (Object.keys(questionMapping).includes(matchCodes) && !questionMapping[matchCodes].includes(question.classificationLabel)) {
                //console.log('questionMapping: ', questionMapping)
                questionMapping[`${question.materialTypeCode}_${question.parentCode}`].push(
                    question.classificationLabel,
                );
                questionMapping[`${question.materialTypeCode}_${question.parentCode}`].sort();
            } else if (!(Object.keys(questionMapping).includes(matchCodes))) {
                //console.log("Question mappinG: ", questionMapping)
                //console.log('questionMapping in else: ', question.materialTypeCode + '_' + question.parentCode)
                //console.log(questionMapping[`${question.materialTypeCode}_${question.parentCode}`])
                questionMapping[`${question.materialTypeCode}_${question.parentCode}`] = [question.classificationLabel];
            }
            console.log("Question Mapping New:", questionMapping);
            return questionMapping;
        }, {});*/
    };

    const getChildQuestionStructure = (matchCriteria: MatchCriteria, code: string, selections: Array<string>) => {
        //console.log("get child question structure called");
        const regex = /(T\d+)_((MC|PMC)\d+)/m;
        const match = regex.exec(code);
        if (match) {
            const parentQuestion = find(
                matchCriteria,
                (question: Question) => question.materialTypeCode === match[1] && question.parentCode === match[2],
            );
            if (parentQuestion) {
                return parentQuestion.children.reduce(
                    (questionMapping: QuestionMapping, childQuestion: ChildQuestion) => {
                        if (
                            childQuestion.mtcCode in questionMapping &&
                            selections.includes(childQuestion.parentAnswer) &&
                            childQuestion.mtcDef !== childQuestion.parentDef &&
                            !isTopLevelQuestion(
                                childQuestion.mtcCode,
                                parentQuestion.materialTypeCode,
                                childQuestion.mtcDef,
                            )
                        ) {
                            questionMapping[childQuestion.mtcCode] = uniq([
                                ...questionMapping[childQuestion.mtcCode],
                                childQuestion.parentAnswer,
                            ]);
                        } else {
                            if (
                                selections.includes(childQuestion.parentAnswer) &&
                                childQuestion.mtcDef !== childQuestion.parentDef &&
                                !isTopLevelQuestion(
                                    childQuestion.mtcCode,
                                    parentQuestion.materialTypeCode,
                                    childQuestion.mtcDef,
                                )
                            ) {
                                questionMapping[childQuestion.mtcCode] = [childQuestion.parentAnswer];
                            }
                        }
                        return questionMapping;
                    },
                    {},
                );
            } else {
                return {};
            }
        } else {
            return {};
        }
    };

    const getHeadings = (parentQuestionMapping: QuestionMapping, materialCodeMapping: MaterialCodeMapping) => {
        //console.log("get headings called");
        const regex = /T\d+/m;
        //console.log("Parent Question Mappings", parentQuestionMapping);
        //console.log("materialCodeMapping", materialCodeMapping);

        const headings = Object.keys(parentQuestionMapping).reduce((labels: Array<Array<string>>, key: string) => {
            const match = regex.exec(key);
            if (
                match &&
                labels.filter((label: Array<string>) => isEqual(label, [match[0], ...parentQuestionMapping[key]]))
                    .length === 0
            ) {
                labels.push([match[0], ...parentQuestionMapping[key]]);
            }
            return labels;
        }, []);

        return headings.sort((a: Array<string>, b: Array<string>) => {
            if (materialCodeMapping[a[0]] < materialCodeMapping[b[0]]) {
                return -1;
            } else if (materialCodeMapping[a[0]] > materialCodeMapping[b[0]]) {
                return 1;
            } else {
                if (a.length < b.length) {
                    return -1;
                } else if (a.length > b.length) {
                    return 1;
                } else {
                    if (a[1] < b[1]) {
                        return -1;
                    } else if (a[1] > b[1]) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }
        });
    };

    const getMissingHeadings = (
        matchCriteria: MatchCriteria,
        materialCodeMapping: MaterialCodeMapping,
        impactedMaterials: Array<Material>,
    ) => {
        // console.log("get missing headings called");
        // console.log("Match Criteria:", matchCriteria);
        // console.log("Material Code Mapping: ", materialCodeMapping);
        const allPossibleCombinations = impactedMaterials.reduce(
            (
                combinations: { headings: Array<Array<string>>; typeNames: { [key: string]: string } },
                material: Material,
            ) => {
                const regex = / \(\d+\)$/m;
                const selectedClassifications = Object.keys(material.classification).filter(
                    (key: string) => material.classification[key] && (material.classification[key] as number) >= 50,
                );
                material.type.forEach((type: MaterialType) => {
                    if (type.code === 'T14') {
                        material.downstreamMaterials.forEach((downstream_mat: ContainerClosureMaterial) => {
                            downstream_mat.type.forEach((downstream_type: MaterialType) => {
                                if (!(downstream_type.code in combinations.typeNames)) {
                                    combinations.typeNames[downstream_type.code] = downstream_type.name.replace(
                                        regex,
                                        '',
                                    );
                                }

                                selectedClassifications.forEach((classification: string) => {
                                    if (
                                        !combinations.headings.some((value: Array<string>) =>
                                            isEqual(value, [
                                                downstream_type.code,
                                                getClassificationName(classification),
                                            ]),
                                        )
                                    ) {
                                        combinations.headings.push([
                                            downstream_type.code,
                                            getClassificationName(classification),
                                            "true"
                                        ]);
                                    }
                                });
                            });
                        });
                    } else {
                        if (!(type.code in combinations.typeNames)) {
                            combinations.typeNames[type.code] = type.name.replace(regex, '');
                        }

                        selectedClassifications.forEach((classification: string) => {
                            if (
                                !combinations.headings.some((value: Array<string>) =>
                                    isEqual(value, [type.code, getClassificationName(classification)]),
                                )
                            ) {
                                combinations.headings.push([type.code, getClassificationName(classification),"false"]);
                            }
                        });
                    }
                });
                return combinations;
            },
            { headings: [], typeNames: {} },
        );
        const parentStructure = getParentQuestionStructure(matchCriteria);
        const headings = getHeadings(parentStructure, materialCodeMapping);

        //console.log("All Possible Combinations: ", allPossibleCombinations);
        //console.log("Headings", headings);

        return allPossibleCombinations.headings
            .reduce((leftoverCombinations: Array<string>, combination: Array<string>) => {
                //If we find a heading which match the first element, and then includes any of the following, its good.
                if (
                    !find(
                        headings,
                        (heading: Array<string>) =>
                            isEqual(combination[0], heading[0]) && heading.includes(combination[1]),
                    )
                ) {                    
                    let isConClose = find(
                        allPossibleCombinations.headings,
                        (heading: Array<string>) =>
                            isEqual(combination[0], heading[0]) && heading.includes(combination[1]),
                    );
                    if(isConClose != undefined && isConClose[2] == "true") {
                        if(leftoverCombinations.indexOf("Container Closure: " + [allPossibleCombinations.typeNames[combination[0]], combination[1]].join(' - ')) === -1) {
                            leftoverCombinations.push(
                                "Container Closure: " + [allPossibleCombinations.typeNames[combination[0]], combination[1]].join(' - '),
                            ); 
                        }
                    } else {
                        if(leftoverCombinations.indexOf([allPossibleCombinations.typeNames[combination[0]], combination[1]].join(' - ')) === -1) {
                            leftoverCombinations.push(
                                [allPossibleCombinations.typeNames[combination[0]], combination[1]].join(' - '),
                            );
                        }
                    }
                }
                //console.log("Left Over Combinations:", leftoverCombinations);
                //return [...new Set(leftoverCombinations)];
                return leftoverCombinations;
            }, [])
            .sort();
    };

    const getAlphabetizedHeadings = (questionMapping: QuestionMapping, selections: Array<string>) => {
        let arrOfParentAnswerArray = Object.values(questionMapping).reduce(
            (reducedValues: Array<Array<string>>, heading: Array<string>) => {
                if (!reducedValues.some((value: Array<string>) => isEqual(value, heading))) {
                    reducedValues.push(heading);
                }
                return reducedValues;
            },
            [],
        );

        arrOfParentAnswerArray = arrOfParentAnswerArray.filter((headings: Array<string>) =>
            headings.every(
                (heading: string) => findIndex(selections, (selection: string) => selection === heading) > -1,
            ),
        );
        const sortedParentAnswerArrays = arrOfParentAnswerArray.map((answers: Array<string>) => answers.sort());

        return sortedParentAnswerArrays.sort((a: Array<string>, b: Array<string>) => {
            if (a.length < b.length) {
                return -1;
            } else if (a.length > b.length) {
                return 1;
            } else {
                if (a[0] < b[0]) {
                    return -1;
                } else if (a[0] > b[0]) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    };

    const isMultiSelectQuestion = (childQuestions: Array<ChildQuestion>) =>
        childQuestions.every((childQuestion: ChildQuestion) => childQuestion.parentAnswer !== 'NO_TYPE');

    const collapsedClicked = (key: string) => {
        setTreeVisibility({
            ...treeVisibility,
            [key]: !treeVisibility[key],
        });
    };

    const sortMatchCriteria = (matchCriteria: MatchCriteria) => {
        return matchCriteria.sort((a: Question, b: Question) => {
            if (a.children.length !== 0 && b.children.length === 0) {
                return -1;
            } else if (a.children.length === 0 && b.children.length !== 0) {
                return 1;
            } else {
                if (isMultiSelectQuestion(a.children) && !isMultiSelectQuestion(b.children)) {
                    return -1;
                } else if (!isMultiSelectQuestion(a.children) && isMultiSelectQuestion(b.children)) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    };

    const getTopLevelKeys = () => {
        //console.log("get top level keys called");        
        const parentStructure = getParentQuestionStructure(matchCriteria);
        const headings = getHeadings(parentStructure, materialCodeMapping);
        //console.log("Parent Structure", Object.keys(parentStructure));
        return flatten(
            headings.map((label: Array<string>) => {
                const applicableMatchCriteriaQuestions = Object.keys(parentStructure).reduce(
                    (matchCriteriaQuestions: MatchCriteria, key: string) => {
                        const regex = /(T\d+)_((MC|PMC)\d+)/m;
                        const match = regex.exec(key);
                        //console.log("Match in Get Top level key", match);
                        if (match) {
                            if (isEqual([match[1], ...parentStructure[key]], label)) {
                                const question = find(
                                    matchCriteria,
                                    (question: Question) =>
                                        question.materialTypeCode === match[1] && question.parentCode === match[2],
                                );
                                if (question) {
                                    matchCriteriaQuestions.push(question);
                                }
                            }
                        }
                        return matchCriteriaQuestions;
                    },
                    [],
                );
                return sortMatchCriteria(applicableMatchCriteriaQuestions).map(
                    (question: Question) => `${question.materialTypeCode}_${question.parentCode}`,
                );
            }),
        );
    };

    const selectionMade = (mtcCode: string, selection: MatchCriteriaSelection) => {
        /*if (setScreen4Update) {
            setScreen4Update(true);
        }*/
        if(props.screen4_update == false && props.setScreen4Update) {
            props.setScreen4Update(true);
        }
        if (mtcCode in parentSelections) {
            parentSelections[mtcCode] = selection;
            if (isEqual(selection, []) || !selection) {
                setTreeVisibility({
                    ...treeVisibility,
                    [mtcCode]: true,
                });
            }

            const regex = /(T\d+)_(PMC\d+)/m;
            const match = regex.exec(mtcCode);
            if (
                match &&
                !(
                    isEqual(selection, []) ||
                    !selection ||
                    (typeof selection !== 'boolean' && (selection as Array<string>).includes('N/A'))
                )
            ) {
                const question = find(matchCriteria, ['parentCode', match[2]]);
                if (question) {
                    const duplicateExists = question.children.some((childQuestion: ChildQuestion) =>
                        isDuplicateChild(childQuestion),
                    );
                    if (duplicateExists && treeVisibility[mtcCode]) {
                        setDuplicateChildSelected(true);
                    } else {
                        setDuplicateChildSelected(false);
                    }
                }
            } else if (
                match &&
                (isEqual(selection, []) ||
                    !selection ||
                    (typeof selection !== 'boolean' && (selection as Array<string>).includes('N/A')))
            ) {
                setDuplicateChildSelected(false);
            }
        } else {
            const child = findChild(mtcCode);
            if (child) {
                if (isDuplicateChild(child)) {
                    setDuplicateChildSelected(true);
                } else {
                    setDuplicateChildSelected(false);
                }
            } else {
                setDuplicateChildSelected(false);
            }
        }

        let keys = getTopLevelKeys();
        //console.log("KEYS: ", keys);
        keys = flatten(
            keys.map((key: string) => {
                // Skip if empty array, empty string, or false
                if (
                    key in parentSelections &&
                    !(
                        parentSelections[key] === false ||
                        isEqual(parentSelections[key], []) ||
                        parentSelections[key] === ''
                    )
                ) {
                    const regex = /(T\d+)_(PMC\d+)/m;
                    const match = regex.exec(key);
                    if (match) {
                        const question = find(
                            matchCriteria,
                            (question: Question) =>
                                question.materialTypeCode === match[1] && question.parentCode === match[2],
                        ) as Question;
                        if (isMultiSelectQuestion(question.children)) {
                            const selection = parentSelections[key] as Array<string>;

                            const childStructure = getChildQuestionStructure(
                                matchCriteria,
                                `${question.materialTypeCode}_${question.parentCode}`,
                                selection as Array<string>,
                            );
                            //console.log("Child Structure", childStructure);
                            const alphabetizedHeadings = getAlphabetizedHeadings(
                                childStructure,
                                selection as Array<string>,
                            );
                            //console.log("alphabetizedHeadings", alphabetizedHeadings);
                            if (mtcCode === key) {
                                alphabetizedHeadings.forEach((heading: Array<string>) => {
                                    treeVisibility[`${match[1]}_${heading.join(', ')}`] = true;
                                });
                            }
                            
                            const childKeyOrder = flatten(
                                alphabetizedHeadings.map((heading: Array<string>) =>
                                    Object.keys(childStructure).reduce((childKeys: Array<string>, key: string) => {
                                        if (isEqual(childStructure[key], heading)) {
                                            childKeys.push(`${question.materialTypeCode}_${key}`);
                                        }
                                        return childKeys;
                                    }, []),
                                ),
                            );
                            //console.log("Child Key Order", childKeyOrder);
                            return [key, ...childKeyOrder];
                        } else {
                            //console.log("Question", question.children);
                            //console.log("Question", question);
                            return [
                                key,
                                ...question.children.reduce(
                                    (childCodes: Array<string>, childQuestion: ChildQuestion) => {
                                        if (
                                            !childCodes.includes(
                                                `${question.materialTypeCode}_${childQuestion.mtcCode}`,
                                            )/* &&
                                            childQuestion.mtcDef !== childQuestion.parentDef &&
                                            !isTopLevelQuestion(
                                                childQuestion.mtcCode,
                                                question.materialTypeCode,
                                                childQuestion.mtcDef,
                                            )*/
                                        ) {
                                            childCodes.push(`${question.materialTypeCode}_${childQuestion.mtcCode}`);
                                        }
                                        //console.log("child Codes", childCodes);
                                        return childCodes;
                                    },
                                    [],
                                ),
                            ];
                        }
                    } else {
                        return key;
                    }
                } else {
                    return key;
                }
            }),
        );
        //console.log("AFTER KEYS : ", keys)

        setParentSelections({ ...parentSelections });
        updateMatchQuestion(mtcCode, selection, keys);
    };

    const generateMultiSelectChildren = (
        childCodes: Array<string>,
        parentQuestion: Question,
        matchCriteriaAnswers: MatchCriteriaAnswer,
        matchCriteriaAnswered: boolean,
        viewOnly: boolean,
    ) => {
        return childCodes.reduce((jsx: Array<JSX.Element>, childCode: string) => {
            const child = find(parentQuestion.children, ['mtcCode', childCode]);
            if (
                child &&
                child.mtcDef !== child.parentDef &&
                !isTopLevelQuestion(child.mtcCode, parentQuestion.materialTypeCode, child.mtcDef)
            ) {
                jsx.push(
                    <Row align="middle" gutter={16} key={child.mtcCode}>
                        <Col span={22} style={{ paddingLeft: '16px' }}>
                            <Row
                                className={
                                    !matchCriteriaAnswered &&
                                    `${parentQuestion.materialTypeCode}_${child.mtcCode}` in matchCriteriaAnswers &&
                                    !matchCriteriaAnswers[`${parentQuestion.materialTypeCode}_${child.mtcCode}`]
                                        .isAnswered
                                        ? 'panel-border-error tree-padding'
                                        : isDuplicateChild(child) && duplicateChildSelected
                                        ? 'panel-border-duplicate tree-padding'
                                        : 'bottom-border tree-padding'
                                }
                                align="middle"
                            >
                                {!matchCriteriaAnswered &&
                                `${parentQuestion.materialTypeCode}_${child.mtcCode}` in matchCriteriaAnswers &&
                                !matchCriteriaAnswers[`${parentQuestion.materialTypeCode}_${child.mtcCode}`]
                                    .isAnswered ? (
                                    <Col span={24} style={{ paddingLeft: '8px' }}>
                                        <FormValidationErrorText text="Please answer question" />
                                    </Col>
                                ) : isDuplicateChild(child) && duplicateChildSelected ? (
                                    <Col span={24} style={{ paddingLeft: '8px' }}>
                                        <FormValidationErrorText
                                            text="Another question exists with identical criteria. Responses will be synchronized."
                                            style={{ color: '#1890ff' }}
                                        />
                                    </Col>
                                ) : null}
                                <Col span={21} style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                                    {child.mtcDef}
                                </Col>
                                <Col span={3} style={{ paddingLeft: '22px' }}>
                                    <MatchCriteriaQuestionsSelections
                                        parentCode={child.parentCode}
                                        childCode={`${parentQuestion.materialTypeCode}_${child.mtcCode}`}
                                        onSelection={selectionMade}
                                        selection={
                                            matchCriteriaAnswers[`${parentQuestion.materialTypeCode}_${child.mtcCode}`]
                                                ? matchCriteriaAnswers[
                                                      `${parentQuestion.materialTypeCode}_${child.mtcCode}`
                                                  ].answer
                                                : ''
                                        }
                                        viewOnly={viewOnly}
                                        radioDisabled={isTopLevelQuestion(
                                            child.mtcCode,
                                            parentQuestion.materialTypeCode,
                                            child.mtcDef,
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>,
                );
            }
            return jsx;
        }, []);
    };

    const generateChildren = (
        parentQuestion: Question,
        matchCriteria: MatchCriteria,
        matchCriteriaAnswers: MatchCriteriaAnswer,
        matchCriteriaAnswered: boolean,
        viewOnly: boolean,
    ) => {
        if (isMultiSelectQuestion(parentQuestion.children)) {
            const childStructure = getChildQuestionStructure(
                matchCriteria,
                `${parentQuestion.materialTypeCode}_${parentQuestion.parentCode}`,
                matchCriteriaAnswers[`${parentQuestion.materialTypeCode}_${parentQuestion.parentCode}`].answer as Array<
                    string
                >,
            );
            const alphabetizedHeadings = getAlphabetizedHeadings(
                childStructure,
                matchCriteriaAnswers[`${parentQuestion.materialTypeCode}_${parentQuestion.parentCode}`].answer as Array<
                    string
                >,
            );

            return alphabetizedHeadings.map((heading: Array<string>) => {
                const title = heading.join(', ');
                const childCodes = Object.keys(childStructure).filter((key: string) =>
                    isEqual(childStructure[key], heading),
                );
                return (
                    <Row align="middle" key={title} style={{ paddingTop: '8px', paddingLeft: '16px' }}>
                        <Col span={23}>
                            <TreeIcon
                                iconKey={`${parentQuestion.materialTypeCode}_${title}`}
                                callback={collapsedClicked}
                                iconState={treeVisibility[`${parentQuestion.materialTypeCode}_${title}`]}
                            />
                            <span className="tree-heading">{title}</span>
                        </Col>
                        <Col span={23} offset={1} className="tree-line" style={{ marginLeft: '4px' }}>
                            {`${parentQuestion.materialTypeCode}_${title}` in treeVisibility &&
                            treeVisibility[`${parentQuestion.materialTypeCode}_${title}`] !== false
                                ? generateMultiSelectChildren(
                                      childCodes,
                                      parentQuestion,
                                      matchCriteriaAnswers,
                                      matchCriteriaAnswered,
                                      viewOnly,
                                  )
                                : null}
                        </Col>
                    </Row>
                );
            });
        } else {
            return parentQuestion.children.map((childQuestion: ChildQuestion) => {
                return childQuestion.mtcDef !== childQuestion.parentDef &&
                    !isTopLevelQuestion(
                        childQuestion.mtcCode,
                        parentQuestion.materialTypeCode,
                        childQuestion.mtcDef,
                    ) ? (
                    <Row
                        align="middle"
                        gutter={16}
                        key={childQuestion.mtcCode}
                        style={{ marginLeft: '4px' }}
                        className="tree-line"
                    >
                        <Col span={23} style={{ paddingLeft: '16px' }}>
                            <Row
                                className={
                                    !matchCriteriaAnswered &&
                                    `${parentQuestion.materialTypeCode}_${childQuestion.mtcCode}` in
                                        matchCriteriaAnswers &&
                                    !matchCriteriaAnswers[`${parentQuestion.materialTypeCode}_${childQuestion.mtcCode}`]
                                        .isAnswered
                                        ? 'panel-border-error tree-padding'
                                        : isDuplicateChild(childQuestion) && duplicateChildSelected
                                        ? 'panel-border-duplicate tree-padding'
                                        : 'bottom-border tree-padding'
                                }
                                align="middle"
                            >
                                {!matchCriteriaAnswered &&
                                `${parentQuestion.materialTypeCode}_${childQuestion.mtcCode}` in matchCriteriaAnswers &&
                                !matchCriteriaAnswers[`${parentQuestion.materialTypeCode}_${childQuestion.mtcCode}`]
                                    .isAnswered ? (
                                    <Col span={24} style={{ paddingLeft: '8px' }}>
                                        <FormValidationErrorText text="Please answer question" />
                                    </Col>
                                ) : isDuplicateChild(childQuestion) && duplicateChildSelected ? (
                                    <Col span={24} style={{ paddingLeft: '8px' }}>
                                        <FormValidationErrorText
                                            text="Another question exists with identical criteria. Responses will be synchronized."
                                            style={{ color: '#1890ff' }}
                                        />
                                    </Col>
                                ) : null}
                                <Col span={20} style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                                    {childQuestion.mtcDef}
                                </Col>
                                <Col span={4} style={{ paddingLeft: '6px' }}>
                                    <MatchCriteriaQuestionsSelections
                                        parentCode={childQuestion.parentCode}
                                        childCode={`${parentQuestion.materialTypeCode}_${childQuestion.mtcCode}`}
                                        onSelection={selectionMade}
                                        selection={
                                            childQuestion.mtcDef === childQuestion.parentDef &&
                                            matchCriteriaAnswers[
                                                `${parentQuestion.materialTypeCode}_${childQuestion.parentCode}`
                                            ] &&
                                            matchCriteriaAnswers[
                                                `${parentQuestion.materialTypeCode}_${childQuestion.parentCode}`
                                            ].answer
                                                ? true
                                                : matchCriteriaAnswers[
                                                      `${parentQuestion.materialTypeCode}_${childQuestion.mtcCode}`
                                                  ]
                                                ? matchCriteriaAnswers[
                                                      `${parentQuestion.materialTypeCode}_${childQuestion.mtcCode}`
                                                  ].answer
                                                : ''
                                        }
                                        viewOnly={viewOnly}
                                        radioDisabled={
                                            childQuestion.mtcDef === childQuestion.parentDef ||
                                            isTopLevelQuestion(
                                                childQuestion.mtcCode,
                                                parentQuestion.materialTypeCode,
                                                childQuestion.mtcDef,
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : null;
            });
        }
    };

    const generateParentQuestions = (
        matchCriteria: MatchCriteria,
        matchCriteriaAnswers: MatchCriteriaAnswer,
        matchCriteriaAnswered: boolean,
        viewOnly: boolean,
    ) => {
        return (
            <>
                {matchCriteria.map((question: Question, index: number) => {
                    return !(isQuestionSingleton(question) && isQuestionParent(question)) ? (
                        <div key={question.parentCode}>
                            <Row
                                align="middle"
                                gutter={16}
                                className={
                                    !matchCriteriaAnswered &&
                                    `${question.materialTypeCode}_${question.parentCode}` in matchCriteriaAnswers &&
                                    !matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`]
                                        .isAnswered
                                        ? 'panel-border-error'
                                        : (!treeVisibility[`${question.materialTypeCode}_${question.parentCode}`] ||
                                              matchCriteriaAnswers[
                                                  `${question.materialTypeCode}_${question.parentCode}`
                                              ].answer === false ||
                                              matchCriteriaAnswers[
                                                  `${question.materialTypeCode}_${question.parentCode}`
                                              ].answer === '' ||
                                              isEqual(
                                                  matchCriteriaAnswers[
                                                      `${question.materialTypeCode}_${question.parentCode}`
                                                  ].answer,
                                                  [],
                                              ) ||
                                              question.children.filter(
                                                  (childQuestion: ChildQuestion) =>
                                                      childQuestion.mtcDef !== childQuestion.parentDef &&
                                                      !isTopLevelQuestion(
                                                          childQuestion.mtcCode,
                                                          question.materialTypeCode,
                                                          childQuestion.mtcDef,
                                                      ),
                                              ).length === 0) &&
                                          index !== matchCriteria.length - 1
                                        ? 'bottom-border tree-padding'
                                        : 'tree-padding'
                                }
                                key={question.parentCode}
                            >
                                {!matchCriteriaAnswered &&
                                `${question.materialTypeCode}_${question.parentCode}` in matchCriteriaAnswers &&
                                !matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`]
                                    .isAnswered ? (
                                    <Col span={24}>
                                        <FormValidationErrorText text="Please answer question" />
                                    </Col>
                                ) : null}
                                <Col
                                    span={
                                        (question.children && !isMultiSelectQuestion(question.children)) ||
                                        question.children.length === 0
                                            ? 19
                                            : 18
                                    }
                                >
                                    {/* eslint-disable */
                                    question.children.length > 0 &&
                                    question.children.filter(
                                        (childQuestion: ChildQuestion) =>
                                            childQuestion.mtcDef !== childQuestion.parentDef &&
                                            !isTopLevelQuestion(
                                                childQuestion.mtcCode,
                                                question.materialTypeCode,
                                                childQuestion.mtcDef,
                                            ),
                                    ).length > 0 &&
                                    `${question.materialTypeCode}_${question.parentCode}` in matchCriteriaAnswers &&
                                    matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`]
                                        .isAnswered &&
                                    !(
                                        matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`]
                                            .answer === false ||
                                        isEqual(
                                            matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`]
                                                .answer,
                                            [],
                                        ) ||
                                        matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`]
                                            .answer === '' ||
                                        (typeof matchCriteriaAnswers[
                                            `${question.materialTypeCode}_${question.parentCode}`
                                        ].answer !== 'boolean' &&
                                            matchCriteriaAnswers[
                                                `${question.materialTypeCode}_${question.parentCode}` // @ts-ignore
                                            ].answer.includes('N/A'))
                                    ) ? (
                                        <TreeIcon
                                            iconKey={`${question.materialTypeCode}_${question.parentCode}`}
                                            callback={collapsedClicked}
                                            iconState={
                                                treeVisibility[`${question.materialTypeCode}_${question.parentCode}`]
                                            }
                                        />
                                    ) : null}
                                    {question.parentDef}
                                </Col>
                                <Col
                                    span={
                                        (question.children && !isMultiSelectQuestion(question.children)) ||
                                        question.children.length === 0
                                            ? 5
                                            : 6
                                    }
                                >
                                    <MatchCriteriaQuestionsSelections
                                        childQuestions={question.children}
                                        parentCode={`${question.materialTypeCode}_${question.parentCode}`}
                                        onSelection={selectionMade}
                                        selection={
                                            matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`]
                                                .answer
                                        }
                                        viewOnly={viewOnly}
                                        isTopLevelQuestion={isTopLevelQuestion}
                                    />
                                </Col>
                            </Row>
                            {question.children.length > 0 &&
                            matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`].isAnswered &&
                            !isEqual(
                                matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`].answer,
                                [],
                            ) &&
                            matchCriteriaAnswers[`${question.materialTypeCode}_${question.parentCode}`].answer &&
                            `${question.materialTypeCode}_${question.parentCode}` in treeVisibility &&
                            treeVisibility[`${question.materialTypeCode}_${question.parentCode}`]
                                ? generateChildren(
                                      question,
                                      matchCriteria,
                                      matchCriteriaAnswers,
                                      matchCriteriaAnswered,
                                      viewOnly,
                                  )
                                : null}
                        </div>
                    ) : null;
                })}
            </>
        );
    };

    const generateTree = (
        matchCriteria: MatchCriteria,
        matchCriteriaAnswers: MatchCriteriaAnswer,
        matchCriteriaAnswered: boolean,
        materialCodeMapping: MaterialCodeMapping,
        viewOnly: boolean,
    ) => {
        const parentStructure = getParentQuestionStructure(matchCriteria);
        const headings = getHeadings(parentStructure, materialCodeMapping);
        //console.log(headings,'label headings')
        return headings.map((label: Array<string>) => {
            const applicableMatchCriteriaQuestions = Object.keys(parentStructure).reduce(
                (matchCriteriaQuestions: MatchCriteria, key: string) => {
                    const regex = /(T\d+)_((MC|PMC)\d+)/m;
                    const match = regex.exec(key);
                    if (match) {
                        if (isEqual([match[1], ...parentStructure[key]], label)) {
                            const question = find(
                                matchCriteria,
                                (question: Question) =>
                                    question.materialTypeCode === match[1] && question.parentCode === match[2],
                            );
                            if (question) {
                                matchCriteriaQuestions.push(question);
                            }
                        }
                    }
                    return matchCriteriaQuestions;
                },
                [],
            );
            //console.log('regulatory impact questions label', label);
            label = union(label);
            //console.log('regulatory impact questions label', label);
            return (
                <div key={label.join(', ')}>
                    <Row align="middle" gutter={16} className="tree-title" key={label.join(', ')}>
                        <Title level={5} style={{ marginBottom: '0.25em' }} className="tree-heading">
                            {`${label[label.length-1] ? 'Container Closure: ' : ''}${materialCodeMapping[label[0]]} - ${label.slice(1,-1).join(', ')}`}
                        </Title>
                    </Row>
                    {generateParentQuestions(
                        sortMatchCriteria(applicableMatchCriteriaQuestions),
                        matchCriteriaAnswers,
                        matchCriteriaAnswered,
                        viewOnly,
                    )}
                </div>
            );
        });
    };

    React.useEffect(() => {
        setParentSelections(
            Object.keys(matchCriteriaAnswers).reduce((parentSelections: ParentSelections, key: string) => {
                const regex = /T\d+_PMC/m;
                if (regex.exec(key)) {
                    if (matchCriteriaAnswers[key].isAnswered) {
                        parentSelections[key] = matchCriteriaAnswers[key].answer;
                    } else {
                        parentSelections[key] = '';
                    }
                }
                return parentSelections;
            }, {}),
        );

        setTreeVisibility(
            Object.keys(matchCriteriaAnswers).reduce((firstTreeVisibility: TreeVisibility, key: string) => {
                const regex = /(T\d+)_(PMC\d+)/m;
                const match = regex.exec(key);
                if (match) {
                    firstTreeVisibility[key] = true;

                    const question = find(
                        matchCriteria,
                        (question: Question) =>
                            question.materialTypeCode === match[1] && question.parentCode === match[2],
                    ) as Question;
                    //console.log("Printing Question 1006", question);
                    if(question != undefined) {
                        if (isMultiSelectQuestion(question.children)) {
                            const answer = matchCriteriaAnswers[key].answer;
                            if (typeof answer !== 'string' && typeof answer !== 'boolean' && answer.length > 0) {
                                const childStructure = getChildQuestionStructure(
                                    matchCriteria,
                                    `${question.materialTypeCode}_${question.parentCode}`,
                                    answer as Array<string>,
                                );
                                const alphabetizedHeadings = getAlphabetizedHeadings(
                                    childStructure,
                                    answer as Array<string>,
                                );

                                alphabetizedHeadings.forEach((heading: Array<string>) => {
                                    firstTreeVisibility[`${match[1]}_${heading.join(', ')}`] = true;
                                });
                            }
                        }
                    }
                }
                return firstTreeVisibility;
            }, {}),
        );
    }, [matchCriteriaAnswers, matchCriteria]);

    React.useEffect(() => {
        setMissingHeadings(getMissingHeadings(matchCriteria, materialCodeMapping, impactedMaterials));
    }, [matchCriteria, materialCodeMapping, impactedMaterials]);

    React.useEffect(() => {
        if (!formContext.matchCriteriaAnswered) {
            setTreeVisibility(mapValues(treeVisibility, () => true));
            setFormValidation(false);
            formContext.setFormFields({
                ...formContext,
                matchCriteriaAnswered: true,
            });
        }
    }, [formContext]);

    return (
        <div ref={matchCriteriaCardRef}>
            <Card bordered={false}>
                <Collapse {...panelProps} defaultActiveKey={1}>
                    <Panel header="Regulatory Impact Assessment Questions" key="1">
                        {!props.loading ? (
                            <FormContext.Consumer>
                                {({ viewOnly }) => (
                                    <>
                                        {!isEmpty(missingHeadings) ? (
                                            <Row align="middle" style={{ paddingBottom: '24px' }}>
                                                <List
                                                    header={
                                                        <Text strong>
                                                            {
                                                                'No applicable regulations and/or guidances for the following impacted material types and classifications:'
                                                            }
                                                        </Text>
                                                    }
                                                    bordered
                                                    dataSource={missingHeadings}
                                                    renderItem={(item: string) => (
                                                        <List.Item style={{ margin: 0 }}>{item}</List.Item>
                                                    )}
                                                    size="small"
                                                    grid={{ column: 2, gutter: 0 }}
                                                />
                                            </Row>
                                        ) : null}
                                        {generateTree(
                                            matchCriteria,
                                            matchCriteriaAnswers,
                                            formValidation,
                                            materialCodeMapping,
                                            viewOnly,
                                        )}
                                    </>
                                )}
                            </FormContext.Consumer>
                        ) : (
                            <Spin size="default" className="loading-spin" />
                        )}
                    </Panel>
                </Collapse>
            </Card>
        </div>
    );
};
