import { truncate } from 'fs';
import React from 'react';
import { FormValidationFields } from '../model';

export const FormContext = React.createContext({
    regulatoryQuestionsAnswered: true,
    productsSelected: true,
    sitesSelected: true,
    siteFunctionsSelected: true,
    inScopeMarketNotEmpty: true,
    justificationProvided: true,
    cpwsQuestionsAnswered: true,
    materialSelected: true,
    impactedMaterialsMissing: true,
    impactedMaterialsMissingWithYes: true,
    downstreamMaterialTypeSelected: true,
    downstreamMaterialType: true,
    formExternalMaterialsMissing: true,
    materialTypeSelected: true,
    qualitySelected: true,
    classificationSelected: true,
    matchCriteriaAnswered: true,
    finalAssessmentAnswered: true,
    viewOnly: false,
    // eslint-disable-next-line
    setFormFields: (fields: FormValidationFields) => {},
});
