import { Card, Col, Collapse, Row, Tag, Typography } from 'antd';
import React from 'react';
import { ColorTag, Matchable, PanelProps, ProductModality, Site } from '../model';
import find from 'lodash/find';

const { Panel } = Collapse;
const { Text } = Typography;

export interface ProductsSitesDisplayProps {
    impactedProducts: any;
    impactedSites: Matchable<Site>;
    panelProps: PanelProps;
    colorTags: {
        productColorTags: ColorTag;
        siteColorTags: ColorTag;
    };
}

export const ProductsSitesDisplay: React.FC<ProductsSitesDisplayProps> = (props: ProductsSitesDisplayProps) => {
    const { impactedProducts, impactedSites, colorTags } = props;

    const impactedProductsTags = (products: any): any => {
        return products.map((product: any) => {
            if (typeof colorTags.productColorTags.initiallySelected !== 'boolean') {
                return find(colorTags.productColorTags.initiallySelected, ['familyName', product.familyName]) ? (
                    <Tag key={product.familyName} color="default" style={{ margin: '3px', maxWidth: '100%' }}>
                        <Text ellipsis style={{ maxWidth: '100%' }} title={product.familyName}>
                            {product.product_name}
                        </Text>
                    </Tag>
                ) : (
                    <Tag key={product.familyName} color="green" style={{ margin: '3px', maxWidth: '100%' }}>
                        <Text ellipsis style={{ maxWidth: '100%' }} title={product.familyName}>
                            {product.product_name}
                        </Text>{' '}
                    </Tag>
                );
            } else {
                return (
                    <Tag key={product.familyName} color="default" style={{ margin: '3px', maxWidth: '100%' }}>
                        <Text ellipsis style={{ maxWidth: '100%' }} title={product.familyName}>
                            {product.familyName}
                        </Text>{' '}
                    </Tag>
                );
            }
        });
    };

    const generateSiteFunctions = (impactedSites: Array<Site>) => {
        //console.log("In generate site functions: ", impactedSites)
        return impactedSites.reduce((sites: Array<JSX.Element>, site: Site) => {
            if (site.isPDS) {
                sites.push(
                    <Row gutter={8} style={{ paddingTop: '16px' }} key={site.name}>
                        <Col span={10}>
                            <Text>{`${site.name} Functions: `}</Text>
                        </Col>
                        <Col span={14}>
                            {site.siteFunctions.map((siteFunction: string) => (
                                <Tag style={{ margin: '2px', maxWidth: '100%' }} key={siteFunction}>
                                    <Text ellipsis style={{ maxWidth: '100%' }} title={siteFunction}>
                                        {siteFunction}
                                    </Text>
                                </Tag>
                            ))}
                        </Col>
                    </Row>,
                );
            }
            return sites;
        }, []);
    };

    const impactedSitesTags = (impactedSites: Array<Site>): any => {
        return (
            <>
                <Row gutter={8}>
                    <Col span={10}>
                        <Text>CPWS Sites:</Text>
                    </Col>
                    <Col span={14}>
                        {impactedSites.reduce((sites: Array<JSX.Element>, impactedSite: Site) => {
                            if (!impactedSite.isPDS) {
                                if (typeof colorTags.siteColorTags.initiallySelected !== 'boolean') {
                                    sites.push(
                                        find(colorTags.siteColorTags.initiallySelected, ['name', impactedSite.name]) ? (
                                            <Tag
                                                key={impactedSite.name}
                                                color="default"
                                                style={{ margin: '2px', maxWidth: '100%' }}
                                            >
                                                <Text ellipsis style={{ maxWidth: '100%' }} title={impactedSite.name}>
                                                    {impactedSite.name}
                                                </Text>
                                            </Tag>
                                        ) : (
                                            <Tag
                                                key={impactedSite.name}
                                                color="green"
                                                style={{ margin: '2px', maxWidth: '100%' }}
                                                title={impactedSite.name}
                                            >
                                                <Text ellipsis style={{ maxWidth: '100%' }} title={impactedSite.name}>
                                                    {impactedSite.name}
                                                </Text>
                                            </Tag>
                                        ),
                                    );
                                } else {
                                    sites.push(
                                        <Tag
                                            key={impactedSite.name}
                                            color="default"
                                            style={{ margin: '2px', maxWidth: '100%' }}
                                        >
                                            <Text ellipsis style={{ maxWidth: '100%' }} title={impactedSite.name}>
                                                {impactedSite.name}
                                            </Text>
                                        </Tag>,
                                    );
                                }
                            }
                            return sites;
                        }, [])}
                    </Col>
                </Row>
                <Row gutter={8} style={{ paddingTop: '16px' }}>
                    <Col span={10}>
                        <Text>PDS Sites:</Text>
                    </Col>
                    <Col span={14}>
                        {impactedSites.reduce((sites: Array<JSX.Element>, impactedSite: Site) => {
                            if (impactedSite.isPDS) {
                                sites.push(
                                    <Tag key={impactedSite.name} style={{ margin: '2px', maxWidth: '100%' }}>
                                        <Text ellipsis style={{ maxWidth: '100%' }} title={impactedSite.name}>
                                            {impactedSite.name}
                                        </Text>
                                    </Tag>,
                                );
                            }
                            return sites;
                        }, [])}
                    </Col>
                </Row>
                {generateSiteFunctions(impactedSites)}
            </>
        );
    };

    return (
        <Card bordered={false}>
            <Row gutter={4}>
                <Col span={10}>
                    <Collapse {...props.panelProps}>
                        <Panel header="Products" key="1" style={{ padding: '2px' }}>
                            {impactedProductsTags(props.impactedProducts)}
                        </Panel>
                    </Collapse>
                </Col>
                <Col span={14}>
                    <Collapse {...props.panelProps}>
                        <Panel header="Sites" key="1" style={{ padding: '2px' }}>
                            {impactedSitesTags(impactedSites.selected)}
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </Card>
    );
};
