import { Col, Row, Typography } from "antd"
import React from "react"
const { Text } = Typography;

export interface IpiIdTitleProps {
    IpiId:string;
    realTitle:string;
}
export const IpiIdTitle: React.FC<any> = (props: any) => {
    return(
            <Row>            
            <Col
            span={24}
            style={{
                padding: '16px',
                margin: '0px auto'
            }}
            >
                <Row>
                <Text strong style={{padding: '10px'}}>{`IPI ID: ${props.IpiId}`}</Text>
                </Row>
                <Row>
                <Text strong style={{padding: '10px'}}>{`Title: ${props.realTitle}`}</Text>
                </Row>
            </Col>
            </Row>
    )
}