import React from 'react';

interface FormValidationErrorTextProps {
    text: string;
    style?: React.CSSProperties;
}

export const FormValidationErrorText: React.FC<FormValidationErrorTextProps> = (
    props: FormValidationErrorTextProps,
) => (
    <div className="ant-form-item-explain form-error" style={props.style ? props.style : undefined}>
        <div>{props.text}</div>
    </div>
);
