import {
    Card,
    Progress,
    Result,
    Spin,
    Typography,
    Upload,
    Collapse,
    Input,
    Row,
    Col,
    notification,
    Button,
    Tabs,
    Table,
    Popover,
} from 'antd';
import { UserAddOutlined, AimOutlined, RightCircleTwoTone, LeftCircleTwoTone, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import {
    CPWS,
    ProductModality,
    Site,
    ProductEvents,
    SiteEvents,
    GlobalProps,
    RegulatoryQuestions,
    ProductModalitySelections,
    FormRefs,
    SiteFunction,
    ColorTag,
    Actions,
    ImpactAnalysis,
    RelatedChanges,
    Plants,
    Suppliers,
    Country,
    RegisteredMarkets,
    ProductsInfo,
    Partners,
    CPWSClassificationCodes,
    CPWSClassification,
    ProductInformation,
    ContainerClosureMaterial,
    Modalities,
    ExternalMaterial,
    Material,
    MaterialType,
    MaterialEvents,
    ImpactedMaterialsRef,
    SelectedMaterials,
} from '../model';

import React from 'react';
const { Text } = Typography;
const { TextArea } = Input;
import './components.css';
import { CPWSQuestions } from './CPWSQuestions';
import { IPIMaterials } from './IPIMaterials';
import { MaterialClassification } from './MaterialClassification';
import { mapModalitiesData } from '../api/apiMapper';
import { map } from 'lodash';
import { ImpactedMaterials } from './ImpactedMaterials';
import { Screen3 } from '../screens/Screen3';
import { MaterialTypes } from './MaterialTypes';
import { StringifyOptions } from 'querystring';

const { Panel } = Collapse;
const { TabPane } = Tabs;

export interface MaterialsSelectionState {
    loading: boolean;
    activeTab: string;
    btnContinueClass: string;
    isIPIMaterialsDisabled: boolean;
    isMaterialTypesDisabled: boolean;
    isClassificationDisabled: boolean;
    areImpactedMaterialsMissing: any;    
    selectedRows: any;
    userSelectedMaterials: Array<SelectedMaterials>;
    currentTab: string;
}

export interface MaterialsSelectionProps {
    removeManuallyEnteredMaterials: Function;
    globalProps: GlobalProps;
    category: Function;
    sterilization: Function;
    current: number;
    tabNum: string;
    userSelectionCategory: Array<any>;
    userSelectionSterilization: Array<any>;
    onProductModalityChange: Function;
    updateProductModalityChange: Function;
    updateVisitedClassification: Function;
    userSelectionProductModality: ProductModalitySelections;
    updateMaterial: Function;
    classificationCodes: CPWSClassificationCodes;
    loadClassificationListFallback: Function;
    loadedClassification: any;
    classificationList: CPWSClassification;
    loadClassificationList: Function;
    cpws: CPWS;
    changeCPWSProps: Function;
    impactedProductMaterials?: any;
    // impactedCountries: Array<Country>;
    // sitesAvailable: Array<Site>;
    // productsAvailable: Array<ProductModality>;
    // productEvents: ProductEvents;
    // siteEvents: SiteEvents;
    // onText: Function;
    // comment: string;
    // cpwsId: string;
    // title: string;
    // onProductSelection: any;
    // productTypeSelection: any; 
    // status: any;
    // ingestData: Function;
    // editData: Function;
    sessionUpdate: Function;
    // s3Loaded: boolean;
    // regulatoryQuestions: RegulatoryQuestions;
    // onRegulatoryQuestionSelection: Function;
    // justification: string;
    // onJustificationChange: Function;
    // formRefs: FormRefs;
    // siteFunctions: Array<SiteFunction>;
    // colorTags: {
    //     productColorTags: ColorTag;
    //     siteColorTags: ColorTag;
    // };
    // viewData: Function;
    // getRelatedChanges: Function;
    // lockedByUserFirstName: string;
    // lockedByUserLastName: string;
    addMaterial: Function;
    setScreen2Validation: Function;
    externalImpactedMaterials: Array<ExternalMaterial>;
    impactedMaterialsAvailable: Array<Material>;
    impactedMaterialsAvailableOption: any;
    downstreamImpactedMaterialsAvailableOption: any;
    impactedMaterialsExternal: Array<ExternalMaterial>;
    deleteMaterial: Function;
    editExternalMaterial: Function;
    materialTableRef: React.RefObject<HTMLDivElement>;
    materialEvents: MaterialEvents;
    removeMaterialType: Function;
    loadMaterialType: Function;
    loadProductMaterialType?: Function;
    impactedMaterialType: Array<any>;
    triggerApplyAllImpactedMaterials: Function;
    classificationListApplyAll: Function;
    screen3PageNumber: number;
    onScreen3PageChange: Function;
    materialTypeRef: React.RefObject<HTMLDivElement>;
    addMaterialContainerClosure: Function;
    removeMaterialContainerClosure: Function;
    addDownstreamMaterial: Function;
    getDownstreamMaterials: Function;
    // allDownstreamMaterials: Function;
    getImpactedProducts: Function;
    getImpactedMaterials: Function;
    containerClosureApplyAll: Function;
    removeExternalMaterialFromContainerClosure: Function;
    impactedMaterialsRef: ImpactedMaterialsRef;
    containerClosurePageNumber: { [key: string]: number };
    onContainerClosurePageChange: Function;
    expandedRowKeys: Array<string>;
    setExpandedRowKeys: Function;
    downstreamProducts: Array<ProductModality>;
    resetContainerClosurePageNumbers: Function;
    loadAdditionalInfo: Function;
    updateUserSelectedMaterialProps: Function;
    updateImpactedMaterialsOption: Function;
    updateDownstreamImpactedMaterialsOption: Function;
    updateTabSelection: Function;
    userSelectedMaterialsWithMaterialType: Array<SelectedMaterials>;
    updateMaterialClassification: Function;
    userSelectedExternalMaterials: Function;
    userSelectedExternalMaterialProps: Array<ExternalMaterial>;
    appCacheFlags: any;
}


export class MaterialsSelection extends React.Component<MaterialsSelectionProps, MaterialsSelectionState> {
    
    state = {
        loading: false,
        activeTab: this.props.tabNum == "4" ? "changeClassification" : this.props.tabNum == "3" ? "materialTypes" : this.props.tabNum == "2" ? "ipiMaterials" : "scope",
        isIPIMaterialsDisabled: (this.props.tabNum == "4" || this.props.tabNum == "3" || this.props.tabNum == "2") ? false : true,
        isMaterialTypesDisabled: (this.props.tabNum == "4" || this.props.tabNum == "3") ? false : true,
        isClassificationDisabled: this.props.tabNum == "4" ? false : true,
        btnContinueClass: '',
        selectedRows: {},
        userSelectedMaterials: [],
        currentTab: this.props.tabNum,
        areImpactedMaterialsMissing: this.props.impactedMaterialsAvailableOption == "Yes" ? true : this.props.impactedMaterialsAvailableOption == "No" ? false : null
    };

    async componentDidUpdate(): Promise<void> {        
        let tmpCls = '';
        if((this.state.activeTab == 'ipiMaterials' && (this.props.userSelectedMaterialsWithMaterialType.length < 1 || this.state.areImpactedMaterialsMissing == null)) ||
           (this.state.activeTab == 'ipiMaterials' && this.state.areImpactedMaterialsMissing == true && !this.verifyExternalMaterials(this.props.userSelectedMaterialsWithMaterialType)) || 
           (this.state.activeTab == 'materialTypes' && (!this.checkDownstreamMaterials() || !this.checkImpactedMaterials() || !this.checkImpactedMaterialType() || !this.checkExternalMaterialsMissing())) ||
           (this.state.activeTab == 'scope' && (this.props.userSelectionCategory.length < 1 || this.props.userSelectionSterilization.length < 1 ||
           (!this.props.userSelectionProductModality.biologic && !this.props.userSelectionProductModality.smallMolecule && !this.props.userSelectionProductModality.vaccine)))) {
           tmpCls = "grey";
        } else {
            tmpCls = "blue";
        }
        
        if(this.state.btnContinueClass != tmpCls) {
            this.setState({btnContinueClass : tmpCls});
        }
    }
    async componentDidMount(): Promise<void> {
        window.scrollTo(0, 0);        
    }    

    verifyExternalMaterials = (extMaterials: Array<any>) => {
        let extMaterialsAvailable = false;
        extMaterials.forEach((mat: any) => {
            //console.log("typeCode", mat.typeCode);
            if(mat.typeCode == 'E') {
                extMaterialsAvailable = true;
            }
        });
        return extMaterialsAvailable;
    }

    checkImpactedMaterialType = () => {
        //const materials = this.props.cpws.impactedMaterials;
        const materials = this.props.userSelectedMaterialsWithMaterialType;
        //console.log("materials in check type: ", materials);
        if (materials.length === 0) {
            return false;
        }
        return materials.every((mat: any) => {
            const materialTypeSelected = mat.type.length > 0;
            if (!materialTypeSelected) {
                return false;
            } else {
                return true;
            }
        });
    }

    checkExternalMaterialsMissing = () => {
        const materials = this.props.cpws.impactedMaterials;
        return materials.every((mat: any) => {
            const materialTypeSelected = mat.type.length > 0;

            const hasContainerClosure = mat.type.some((matType: any) => matType.code === 'T14');

            if (materialTypeSelected && hasContainerClosure && this.props.downstreamImpactedMaterialsAvailableOption === null) {
                return false;
            }

            if (this.props.downstreamImpactedMaterialsAvailableOption !== undefined) {
                if (materialTypeSelected && hasContainerClosure && this.props.downstreamImpactedMaterialsAvailableOption[mat.number] === undefined) {
                    return false;
                }
            }

            if (this.props.downstreamImpactedMaterialsAvailableOption !== undefined) {
                if (materialTypeSelected && hasContainerClosure && this.props.downstreamImpactedMaterialsAvailableOption[mat.number] === 'Yes') {
                    const downstreamMaterials = mat.downstreamMaterials;
                    let hasExternalMaterial = false;
                    for (const downstreamMat of downstreamMaterials) {
                        if (downstreamMat.manually_entered === true) {
                            hasExternalMaterial = true;
                        } 
                    }
    
                    return hasExternalMaterial;
                } else {
                    return true;
                }
            }
        });

    }

    checkImpactedMaterials = () => {
        const materials = this.props.cpws.impactedMaterials;
        return materials.every((mat: any) => {
            const materialTypeSelected = mat.type.length > 0;

            const hasContainerClosure = mat.type.some((matType: any) => matType.code === 'T14');
            if (materialTypeSelected && hasContainerClosure) {
                const allDownstreamMaterialsHaveType = mat.downstreamMaterials.every(
                    (downstreamMat: ContainerClosureMaterial) => downstreamMat.type.length > 0,
                );

                if (!allDownstreamMaterialsHaveType) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        });
    };

    checkDownstreamMaterials = () => {
        const materials = this.props.cpws.impactedMaterials;
        return materials.every((mat: Material) => {
            const materialTypeSelected = mat.type.length > 0;

            const hasContainerClosure = mat.type.some((matType: MaterialType) => matType.code === 'T14');
            if (materialTypeSelected && hasContainerClosure) {
                if (mat.downstreamMaterials.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        });
    }

    manuallyEnteredMaterialMissing = () => {
        const materials = this.props.cpws.impactedMaterials;
        return materials.every((mat: Material) => {
            const materialTypeSelected = mat.type.length > 0;

            const hasContainerClosure = mat.type.some((matType: MaterialType) => matType.code === 'T14');
            if (materialTypeSelected && hasContainerClosure) {
                for (const downstreamMat of mat.downstreamMaterials) {
                    if (downstreamMat.manually_entered === true) {
                        return true;
                    }
                }
                return false;
            } else {
                return true;
            }
        });
    }

    onContinue = async () => {
        let cpws: CPWS = this.props.cpws;
        let productInfo: ProductInformation = this.props.cpws.productInformation;
       
        if (this.state.activeTab === 'scope') {
            if(this.props.userSelectionCategory.length > 0
                && this.props.userSelectionSterilization.length > 0
                && (this.props.userSelectionProductModality.biologic
                    || this.props.userSelectionProductModality.smallMolecule
                    || this.props.userSelectionProductModality.vaccine)) {
            this.setState({ activeTab: 'ipiMaterials' });
            this.props.updateTabSelection("2");
            } else {
                this.props.setScreen2Validation('cpws');
                return false;
            }
        }
        else if (this.state.activeTab === 'ipiMaterials'){
            //console.log('impacted Materials', this.state.areImpactedMaterialsMissing);
            if(this.props.userSelectedMaterialsWithMaterialType.length < 1) {
                this.props.setScreen2Validation('material');
                return false;
            }
            if(this.state.areImpactedMaterialsMissing == null) {
                this.props.setScreen2Validation('matmissing');
                return false;
            } 
            //console.log(this.state.areImpactedMaterialsMissing);
            //console.log(!this.verifyExternalMaterials(this.props.userSelectedMaterialsWithMaterialType))
            if(this.state.areImpactedMaterialsMissing == true && !this.verifyExternalMaterials(this.props.userSelectedMaterialsWithMaterialType)) {
              this.props.setScreen2Validation('matMissingWithYes');
              return false;
            }           
            this.setState({ activeTab: 'materialTypes', isIPIMaterialsDisabled: false });
            this.props.updateTabSelection("3");
            //this.props.updateUserSelectedMaterialProps(this.state.userSelectedMaterials);
            // api call here
            //console.log('selectedRows before invoking material type api >>', this.state.selectedRows);
            //await this.props.loadProductMaterialType?.({selectedRows: this.state.selectedRows});
            await this.props.loadProductMaterialType?.();
            return;
        }
        else if (this.state.activeTab === 'materialTypes') {
            if (this.checkDownstreamMaterials()) {
                this.props.setScreen2Validation('downstreamMaterialTypeSelectedTrue')
            }
            if(!this.checkDownstreamMaterials()) {
                this.props.setScreen2Validation('downstreamMaterialTypeSelectedFalse');
                return false;
            }
            if(!this.checkImpactedMaterials()) {
                this.props.setScreen2Validation('downstreamMaterialType');
                console.log("Check impacted materials in false")
                return false;
            }
            if(!this.checkImpactedMaterialType()) {
                console.log("In mtype")
                this.props.setScreen2Validation('mtype');
                return false;
            }
            if(!this.checkExternalMaterialsMissing()) {
                console.log("In formexternalmissing")
                this.props.setScreen2Validation('formExternalMaterialsMissing');
                return false;
            }
            //console.log(this.props.impactedMaterialsRef);
	        this.loadClassificationData();
            this.setState({ activeTab: 'changeClassification', isMaterialTypesDisabled: false, isClassificationDisabled: false });
            this.props.updateTabSelection("4");
            this.props.updateVisitedClassification(true);
        }
        //this.props.next();
        productInfo = { ...this.props.cpws.productInformation, modalities: this.updateProductInformationModalities() }
        cpws = { ...this.props.cpws, productInformation: productInfo }
        this.props.changeCPWSProps(cpws);
        //this.props.sessionUpdate();
    }
    ImpactedMaterialsMissing = (val:boolean) => {
        //console.log(val);
        this.setState({areImpactedMaterialsMissing : val});
        if(val == false) {
            this.props.setScreen2Validation('clearImpactMaterials');
            this.props.setScreen2Validation('clearMatMissingYes');
        }
    }

    updateCurrentTab = (input: string) => {
        //console.log("updatecurrenttab input", input);
        if (input === 'materialTypes') {
            this.state.isClassificationDisabled = true;
            this.state.currentTab = 'materialTypes';
        }
        else if (input === 'ipiMaterials') {
            //console.log("updatecurrenttab else if", input);
            this.state.isMaterialTypesDisabled = true;
            this.state.isClassificationDisabled = true;
            this.state.currentTab = 'ipiMaterials';
        }
        else if (input === 'Scope') {
            //console.log("updatecurrenttab if loop", input);
            this.setState({
                currentTab: input,
                isIPIMaterialsDisabled: true,
                isMaterialTypesDisabled: true, isClassificationDisabled: true
            }, () => console.log("updatecurrenttab", this.state.currentTab));
        }

    }

    updateProductInformationModalities(): (Array<Modalities>) {
        let modalitiesArr: Array<Modalities> = [];
        let modalityData: Modalities = { modality_code: '', modality_name: '' };
        if (this.props.userSelectionProductModality.biologic) {
            modalityData = { modality_code: 'B', modality_name: 'Biologic' };
            modalitiesArr.push(modalityData);
        }
        if (this.props.userSelectionProductModality.smallMolecule) {
            modalityData = { modality_code: 'SM', modality_name: 'Small Molecule' };
            modalitiesArr.push(modalityData);
        }
        if (this.props.userSelectionProductModality.vaccine) {
            modalityData = { modality_code: 'V', modality_name: 'Vaccine' };
            modalitiesArr.push(modalityData);
        }

        return modalitiesArr;
    }

    loadClassificationData = () => {
        let retries = 0;
        while (retries < 10) {
            const response = this.props.loadClassificationList();

            if (response === 'pending') {
                retries += 1;
                if (retries === 10) {
                    this.props.loadClassificationListFallback();
                    notification.error({
                        message: 'Unable to load classification list',
                        description:
                            'There was an error loading the classification list. Please select material classifications manually.',
                        duration: 10,
                    });
                    break;
                }
                //await sleep();
            } else if (response === 'error') {
                this.props.loadClassificationListFallback();
                notification.error({
                    message: 'Unable to load classification list',
                    description:
                        'There was an error loading the classification list. Please select material classifications manually.',
                    duration: 10,
                });
                break;
            } else {
                break;
            }
        }
    }

    onBack = () => {

        if (this.state.activeTab === 'changeClassification') {
            this.setState({ activeTab: 'materialTypes' });
            this.props.updateTabSelection("3");
        }
        else if (this.state.activeTab === 'materialTypes') {
            this.setState({ activeTab: 'ipiMaterials' });
            this.props.updateTabSelection("2");
            this.props.setScreen2Validation('clearMType');
            this.props.setScreen2Validation('clearMissing');
            this.props.setScreen2Validation('clearMatMissingYes');
        }
        else if (this.state.activeTab === 'ipiMaterials') {
            this.props.setScreen2Validation('clearMaterial');
            this.props.setScreen2Validation('clearMatMissingYes');
            this.setState({ activeTab: 'scope' });
            this.props.updateTabSelection("1");
        }
    }

    changeTab = (activeKey: any) => {

        this.setState({
            activeTab: activeKey
        });
        if(activeKey == 'ipiMaterials') {
            this.props.setScreen2Validation('clearMType');
            this.props.setScreen2Validation('clearMissing');
            this.props.updateTabSelection("2");
        } else if(activeKey == 'scope') {
            this.props.setScreen2Validation('clearMaterial');
            this.props.updateTabSelection("1");
        } else if(activeKey == 'materialTypes') {
            this.props.updateTabSelection("3");
        } else {
            this.props.updateTabSelection("4");
            this.props.updateVisitedClassification(true);
        }
    };
    onSelectRows = (rows: any) => {
        this.setState({ ...this.state, selectedRows: rows });
    }

    render(): JSX.Element {
        const {
            cpws,
            // productsAvailable,
            // productEvents,
            // sitesAvailable,
            globalProps,
            // cpwsId,
            // title,
            // formRefs,
            category,
            sterilization,
            current,
            userSelectionCategory,
            onProductModalityChange,
            updateProductModalityChange,
            userSelectionProductModality,
            userSelectionSterilization,
            classificationList,
            updateMaterial,
            loadedClassification,
            classificationCodes,
            materialEvents,
            appCacheFlags

        } = this.props;
        //console.log(this.props.userSelectedMaterialsWithMaterialType);
        //console.log('appCacheFlags in materials section: ', appCacheFlags)

        return (
            <Card bordered={false}>
                <Collapse defaultActiveKey={1}>
                    <Panel header="Material(s) Selection" key="1">
                        <Tabs activeKey={this.state.activeTab} type="card" onChange={this.changeTab}>
                            <TabPane tab="Scope" key="scope">
                                <Card bordered={false}>
                                    <CPWSQuestions
                                        panelProps={globalProps.defaultPanelProps}
                                        category={category}
                                        sterilization={sterilization}
                                        userSelectionCategory={userSelectionCategory}
                                        userSelectionSterilization={userSelectionSterilization}
                                        current={current}
                                        onProductModalityChange={onProductModalityChange}
                                        updateProductModalityChange={updateProductModalityChange}
                                        userSelectionProductModality={userSelectionProductModality}
                                        cpws={cpws}
                                        updateCurrentTab={this.updateCurrentTab}

                                    />
                                </Card>
                            </TabPane>
                            <TabPane tab="IPI Materials" key="ipiMaterials" disabled={this.state.isIPIMaterialsDisabled}>
                                <Card bordered={false}>
                                    {}
                                    <ImpactedMaterials
                                        onSelectRows={this.onSelectRows}
                                        cpws={cpws}
                                        materialEvents={materialEvents}
                                        panelProps={globalProps.defaultPanelProps}
                                        impactedMaterialsAvailable={this.props.impactedMaterialsAvailable}
                                        addMaterial={this.props.addMaterial}
                                        externalImpactedMaterials={this.props.impactedMaterialsExternal}
                                        deleteMaterial={this.props.deleteMaterial}
                                        editExternalMaterial={this.props.editExternalMaterial}
                                        materialTableRef={this.props.materialTableRef}
                                        updateUserSelectedMaterialProps={this.props.updateUserSelectedMaterialProps}
                                        updateImpactedMaterialsOption={this.props.updateImpactedMaterialsOption}
                                        updateDownstreamImpactedMaterialsOption={this.props.updateDownstreamImpactedMaterialsOption}
                                        userSelectedMaterials={this.props.userSelectedMaterialsWithMaterialType}
                                        userSelectedExternalMaterials={this.props.userSelectedExternalMaterials}
                                        userSelectedExternalMaterialProps={this.props.userSelectedExternalMaterialProps}
                                        updateCurrentTab={this.updateCurrentTab}
                                        impactedMaterialsAvailableOption={this.props.impactedMaterialsAvailableOption}
                                        downstreamImpactedMaterialsAvailableOption={this.props.downstreamImpactedMaterialsAvailableOption}
                                        areImpactedMaterialsMissing={this.ImpactedMaterialsMissing}
                                    />
                                </Card>
                            </TabPane>
                            <TabPane tab="Material Types" key="materialTypes" disabled={this.state.isMaterialTypesDisabled} >
                                <h4 style={{paddingLeft: '3.4em'}}>Assign the material type(s) to each impacted material</h4>
                                <Card bordered={false}>
                                    <MaterialTypes
                                        removeManuallyEnteredMaterials={this.props.removeManuallyEnteredMaterials}
                                        downstreamImpactedMaterialsAvailableOption={this.props.downstreamImpactedMaterialsAvailableOption}
                                        updateDownstreamImpactedMaterialsOption={this.props.updateDownstreamImpactedMaterialsOption}
                                        cpws={this.props.cpws}
                                        //impactedMaterials = {this.props.cpws.materials}
                                        impactedProductMaterials={this.props.impactedProductMaterials}
                                        loadAdditionalInfo={this.props.loadAdditionalInfo}
                                        globalProps={this.props.globalProps}
                                        updateMaterial={this.props.updateMaterial}
                                        removeMaterialType={this.props.removeMaterialType}
                                        loadMaterialType={this.props.loadMaterialType}
                                        impactedMaterialType={this.props.impactedMaterialType}
                                        current={current}
                                        triggerApplyAllImpactedMaterials={this.props.triggerApplyAllImpactedMaterials}
                                        screen3PageNumber={this.props.screen3PageNumber}
                                        onScreen3PageChange={this.props.onScreen3PageChange}
                                        materialTypeRef={this.props.materialTypeRef}
                                        addMaterialContainerClosure={this.props.addMaterialContainerClosure}
                                        removeMaterialContainerClosure={this.props.removeMaterialContainerClosure}
                                        addDownstreamMaterial={this.props.addDownstreamMaterial}
                                        getDownstreamMaterials={this.props.getDownstreamMaterials}
                                        // allDownstreamMaterials={this.props.allDownstreamMaterials}
                                        getImpactedProducts={this.props.getImpactedProducts}
                                        getImpactedMaterials={this.props.getImpactedMaterials}
                                        containerClosureApplyAll={this.props.containerClosureApplyAll}
                                        removeExternalMaterialFromContainerClosure={this.props.removeExternalMaterialFromContainerClosure}
                                        impactedMaterialsRef={this.props.impactedMaterialsRef}
                                        containerClosurePageNumber={this.props.containerClosurePageNumber}
                                        onContainerClosurePageChage={this.props.onContainerClosurePageChange}
                                        expandedRowKeys={this.props.expandedRowKeys}
                                        setExpandedRowKeys={this.props.setExpandedRowKeys}
                                        downstreamProducts={this.props.downstreamProducts}
                                        deleteMaterial={this.props.deleteMaterial}
                                        editExternalMaterial={this.props.editExternalMaterial}
                                        impactedMaterialsExternal={this.props.impactedMaterialsExternal}
                                        sessionUpdate={this.props.sessionUpdate}
                                        resetContainerClosurePageNumbers={this.props.resetContainerClosurePageNumbers}
                                        userSelectedMaterials={this.props.userSelectedMaterialsWithMaterialType}
                                        updateCurrentTab={this.updateCurrentTab}
                                    // tempuserSelectedMaterials = {this.state.userSelectedMaterials}
                                    />
                                </Card>
                            </TabPane>
                            <TabPane tab="Change Classification" key="changeClassification" disabled={this.state.isClassificationDisabled} >
                                <h4 style={{paddingLeft: '2em', paddingTop: '1em'}}>Assign the change classification(s) to each impacted material</h4>
                                <Card bordered={false}>
                                    {appCacheFlags.loadClassificationListResponse === true ? <Spin style={{marginLeft: '32em'}}/> :
                                        <MaterialClassification
                                            loading={this.state.loading}
                                            updateMaterial={this.props.updateMaterialClassification}
                                            impactedMaterials={this.props.userSelectedMaterialsWithMaterialType}
                                            panelProps={globalProps.defaultPanelProps}
                                            classificationList={classificationList}
                                            loadedClassification={this.props.loadedClassification}
                                            current={this.props.current}
                                            classificationCodes={this.props.classificationCodes}
                                            loadClassificationList={this.props.loadClassificationList}
                                            classificationListApplyAll={this.props.classificationListApplyAll}
                                            loadClassificationListFallback={this.props.loadClassificationListFallback}
                                        />
                                    }
                                </Card>
                            </TabPane>
                        </Tabs>
                        <Row style={{ justifyContent: "center" }}>
                            <Button 
                                style={{ alignItems: 'center', 
                                display: this.state.activeTab === 'scope' ? 'none' : '' }} 
                                shape="round" 
                                onClick={this.onBack}
                                icon={<LeftCircleOutlined />} >
                                Back 
                            </Button>&nbsp;&nbsp;
                            <Button 
                                type="primary"
                                className={this.state.btnContinueClass == 'grey' ? 'greyContinue' : ''}
                                style={{ alignItems: 'center', display: this.state.activeTab === 'changeClassification' ? 'none' : '' }}
                                shape="round" onClick={this.onContinue} >
                                Continue <RightCircleOutlined />
                            </Button>
                        </Row>


                    </Panel>
                </Collapse>
            </Card>
        );
    }
};
