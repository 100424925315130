import { Card, Col, Collapse, Row, Radio, Button, Popover, Table, Tag, Typography } from 'antd';
import React from 'react';
import { ImpactedSites, ProductsModalities } from '.';
import { FormContext } from '../context/FormContext';
import {
    Matchable,
    PanelProps,
    ProductEvents,
    ProductModality,
    Site,
    SiteEvents,
    FormRefs,
    SiteFunction,
    ColorTag,
} from '../model';

const { Panel } = Collapse;
const { Text } = Typography;

export interface ProductsSitesSelectionProps {
    impactedProducts: Matchable<ProductModality>;
    impactedSites: Matchable<Site>;
    sitesAvailable: Array<Site>;
    productsAvailable: Array<ProductModality>;
    productEvents: ProductEvents;
    siteEvents: SiteEvents;
    panelProps: PanelProps;
    onProductSelection: any;
    productTypeSelection: any;
    productsSitesRefs?: Partial<FormRefs>;
    siteFunctions: Array<SiteFunction>;
    colorTags: {
        productColorTags: ColorTag;
        siteColorTags: ColorTag;
    };
}

const colorTagDefinitions = [
    {
        colorTag: 'GREY',
        definition: 'Automatically populated product or site',
    },
    {
        colorTag: 'STRIKE',
        definition: 'Automatically populated product or site which was removed',
    },
    {
        colorTag: 'GREEN',
        definition: 'Manually added product or site',
    },
    {
        colorTag: 'RED',
        definition: 'Automatically populated product or site which was not found in database',
    },
];

export const ProductsSitesSelection: React.FC<ProductsSitesSelectionProps> = (props: ProductsSitesSelectionProps) => {
    const {
        productsAvailable,
        impactedProducts,
        productEvents,
        sitesAvailable,
        impactedSites,
        siteEvents,
        panelProps,
        onProductSelection,
        productTypeSelection,
        productsSitesRefs,
        siteFunctions,
        colorTags,
    } = props;

    const onChange = (e: any) => {
        onProductSelection(e.target.value);
    };

    const generateColorTag = (color: string) => {
        switch (color) {
            case 'GREY':
                return (
                    <Tag closable={false} color="default">
                        <Text>Product/Site</Text>
                    </Tag>
                );
            case 'STRIKE':
            case 'RED':
                return (
                    <Tag closable={false} color="red">
                        <Text delete={color === 'STRIKE' ? true : false}>Product/Site</Text>
                    </Tag>
                );
            case 'GREEN':
                return (
                    <Tag closable={false} color="green">
                        <Text>Product/Site</Text>
                    </Tag>
                );
        }
    };

    const colorTagColumnDefRender = (text: string) => <Text className="classification-def">{text}</Text>;

    const colorTagColumns = [
        {
            title: 'Color Tag',
            dataIndex: 'colorTag',
            render: (color: string) => generateColorTag(color),
            width: '10%',
            className: 'classification-dropdown-column',
        },
        {
            title: 'Color Tag Definition',
            dataIndex: 'definition',
            render: (text: string) => colorTagColumnDefRender(text),
            className: 'classification-dropdown-column',
        },
    ];

    const renderColorTagLegend = () => {
        return (
            <Table
                dataSource={colorTagDefinitions}
                columns={colorTagColumns}
                pagination={false}
                style={{ maxWidth: '500px' }}
                rowKey={
                    '_' +
                    Math.random()
                        .toString(36)
                        .substr(2, 9)
                }
                bordered
            />
        );
    };

    const colorTagLegend = () => {
        return (
            <Popover content={renderColorTagLegend()} placement="bottomLeft" trigger="click">
                <Button onClick={(e: any) => e.stopPropagation()}>Color Tag Legend</Button>
            </Popover>
        );
    };

    return (
        <FormContext.Consumer>
            {({ viewOnly }) => (
                <>
                    <div ref={productsSitesRefs?.productsSelected}>
                        <Card bordered={false}>
                            <Row key="productssitesrow">
                                <Col span={24}>
                                    <Collapse {...panelProps}>
                                        <Panel
                                            header="Products"
                                            key="1"
                                            style={{ padding: '2px' }}
                                            extra={colorTagLegend()}
                                        >
                                            <Row
                                                justify="center"
                                                gutter={1}
                                                style={{ alignItems: 'center' }}
                                                className={viewOnly ? 'view-only' : ''}
                                            >
                                                <Col span={4}></Col>
                                                <Col
                                                    span={16}
                                                    style={{
                                                        alignContent: 'center',
                                                        padding: '4px',
                                                        display: 'inline-block',
                                                        fontSize: '8px',
                                                    }}
                                                >
                                                    <Radio.Group defaultValue={productTypeSelection}>
                                                        <Radio onChange={onChange} value={'tradeName'}>
                                                            Trade Name
                                                        </Radio>
                                                        <Radio onChange={onChange} value={'genericName'}>
                                                            Generic Name
                                                        </Radio>
                                                        <Radio onChange={onChange} value={'mkNumber'}>
                                                            MK/V-#
                                                        </Radio>
                                                    </Radio.Group>
                                                </Col>
                                                <Col span={4}></Col>
                                            </Row>
                                            <ProductsModalities
                                                productsAvailable={productsAvailable}
                                                impactedProducts={impactedProducts}
                                                productEvents={productEvents}
                                                productTypeSelection={productTypeSelection}
                                                productColorTags={colorTags.productColorTags}
                                            />
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                    <div ref={productsSitesRefs?.sitesSelected}>
                        <Card bordered={false}>
                            <Row>
                                <Col span={24}>
                                    <Collapse {...props.panelProps}>
                                        <Panel
                                            header="Sites"
                                            key="1"
                                            style={{ padding: '2px', listStyle: 'red' }}
                                            className={siteEvents.onSelect ? 'onSelectSite' : 'impacted-sites'}
                                        >
                                            {productsSitesRefs?.siteFunctions ? (
                                                <ImpactedSites
                                                    sitesAvailable={sitesAvailable}
                                                    impactedSites={impactedSites}
                                                    siteEvents={siteEvents}
                                                    siteFunctions={siteFunctions}
                                                    siteFunctionRefs={productsSitesRefs.siteFunctions}
                                                    siteColorTags={colorTags.siteColorTags}
                                                />
                                            ) : (
                                                <ImpactedSites
                                                    sitesAvailable={sitesAvailable}
                                                    impactedSites={impactedSites}
                                                    siteEvents={siteEvents}
                                                    siteFunctions={siteFunctions}
                                                    siteColorTags={colorTags.siteColorTags}
                                                />
                                            )}
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </>
            )}
        </FormContext.Consumer>
    );
};
