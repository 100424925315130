import React from 'react';

import { CPWS, Material, MaterialEvents, GlobalProps, ExternalMaterial, ColorTag, ProductModalitySelections, FormRefs, CPWSClassification, CPWSClassificationCodes, ImpactedMaterialsRef, ProductModality, ProductInformation, SelectedMaterials } from '../model';
import { CPWSStep } from './screens';
import './screens.css';
import { Card, Collapse, notification, Typography } from 'antd';
import { ChangeInformation } from '../components/ChangeInformation';
import { IpiIdTitle } from '../components/IpiIdTitle';
import { MaterialsSelection } from '../components/MaterialsSection';
const { Text } = Typography;
const { Panel } = Collapse;

export interface Screen2Props {
    cpws: CPWS;
    cpwsId: string;
    title: string;
    formRefs: FormRefs;
    impactedMaterialsAvailable: Array<Material>;
    impactedMaterialsAvailableOption: any;
    downstreamImpactedMaterialsAvailableOption: any;
    materialEvents: MaterialEvents;
    globalProps: GlobalProps;
    staticProductInformation: any;
    comment: string;
    tabNum: string;
    updateVisitedClassification: Function;
    visitedChangeClassification: boolean;
    addMaterial: Function;
    setScreen2Validation: Function;
    impactedMaterialsExternal: Array<ExternalMaterial>;
    userSelectionCategory: Array<any>;
    userSelectionSterilization: Array<any>;
    onProductModalityChange: Function;
    updateProductModalityChange: Function;
    userSelectionProductModality: ProductModalitySelections;
    sessionUpdate: Function;
    deleteMaterial: Function;
    editExternalMaterial: Function;
    materialTableRef: React.RefObject<HTMLDivElement>;
    postClassificationData: Function;
    category: Function;
    current: number;
    sterilization: Function;
    colorTags: {
        productColorTags: ColorTag;
        siteColorTags: ColorTag;
    };
    classificationList: CPWSClassification;
    loadClassificationList: Function;
    updateMaterial: Function;
    loadedClassification: any;
    loadClassificationListFallback: Function;
    classificationCodes: CPWSClassificationCodes;
    changeCPWSProps: Function;
    removeMaterialType: Function;
    removeManuallyEnteredMaterials: Function;
    loadMaterialType: Function;
    loadProductMaterialType?:Function;
    impactedMaterialType: Array<any>;
    triggerApplyAllImpactedMaterials: Function;
    classificationListApplyAll:Function;
    screen3PageNumber: number;
    onScreen3PageChange: Function;
    materialTypeRef: React.RefObject<HTMLDivElement>;
    formExternalMaterialsMissingRef: React.RefObject<HTMLDivElement>;
    downstreamMaterialTypeSelectedRef: React.RefObject<HTMLDivElement>;
    downstreamMaterialTypeRef: React.RefObject<HTMLDivElement>;
    addMaterialContainerClosure: Function;
    removeMaterialContainerClosure: Function;
    addDownstreamMaterial: Function;
    getDownstreamMaterials: Function;
    getImpactedMaterials: Function;
    getImpactedProducts: Function;
    containerClosureApplyAll: Function;
    removeExternalMaterialFromContainerClosure: Function;
    impactedMaterialsRef: ImpactedMaterialsRef;
    containerClosurePageNumber: { [key: string]: number };
    onContainerClosurePageChange: Function;
    expandedRowKeys: Array<string>;
    setExpandedRowKeys: Function;
    downstreamProducts: Array<ProductModality>;
    resetContainerClosurePageNumbers: Function;
    loadAdditionalInfo: Function,
    impactedProductMaterials?:any;
    updateUserSelectedMaterialProps: Function,
    updateHighestAvailableforScreen2: Function,
    updateImpactedMaterialsOption: Function,
    updateDownstreamImpactedMaterialsOption: Function,
    updateTabSelection: Function,
    userSelectedMaterialsWithMaterialType: Array<SelectedMaterials>;
    updateMaterialClassification: Function,   
    userSelectedExternalMaterials: Function;
    userSelectedExternalMaterialProps: Array<ExternalMaterial>;
    appCacheFlags: any;
}
export class Screen2 extends React.Component<Screen2Props> {

    async componentDidMount(): Promise<void> {
        window.scrollTo(0, 0);
        if(this.props.tabNum == "") {
            await this.props.updateTabSelection("1");
        }
        this.props.sessionUpdate();
        this.props.postClassificationData();
        //console.log("Slected row key screen2 >> ", this.props.userSelectedMaterialsWithMaterialType)
    }

    render(): JSX.Element {
        const { cpws, staticProductInformation, title, tabNum, sterilization, globalProps, category, current, updateProductModalityChange, onProductModalityChange, userSelectionProductModality, userSelectionCategory, userSelectionSterilization, updateMaterial, classificationList,impactedProductMaterials } = this.props;
        const realTitle = title ? title : 'No data was loaded. ';
        return (
            <CPWSStep>
                <div style={{border: '1px solid #d9d9d9', margin: '0px 24px'}}>
                <IpiIdTitle IpiId={this.props.cpwsId} realTitle={realTitle} />
                </div>

                <MaterialsSelection
                    cpws={cpws}
                    impactedProductMaterials={impactedProductMaterials}
                    globalProps={globalProps}
                    category={category}
                    sterilization={sterilization}
                    current={current}
                    tabNum={tabNum}
                    updateVisitedClassification={this.props.updateVisitedClassification}
                    userSelectionCategory={userSelectionCategory}
                    userSelectionSterilization={userSelectionSterilization}
                    onProductModalityChange={onProductModalityChange}
                    updateProductModalityChange={updateProductModalityChange}
                    userSelectionProductModality={userSelectionProductModality}
                    //cpwsQuestionsRef={this.props.formRefs.cpwsQuestionsAnswered}
                    updateMaterial={updateMaterial}
                    classificationList={classificationList}
                    loadedClassification={this.props.loadedClassification}
                    classificationCodes={this.props.classificationCodes}
                    loadClassificationList={this.props.loadClassificationList}
                    sessionUpdate={this.props.sessionUpdate}
                    loadClassificationListFallback={this.props.loadClassificationListFallback}
                    changeCPWSProps={this.props.changeCPWSProps}
                    addMaterial={this.props.addMaterial}
                    externalImpactedMaterials={this.props.impactedMaterialsExternal}
                    materialEvents={this.props.materialEvents}
                    impactedMaterialsAvailable={this.props.impactedMaterialsAvailable}
                    impactedMaterialsAvailableOption={this.props.impactedMaterialsAvailableOption}
                    downstreamImpactedMaterialsAvailableOption={this.props.downstreamImpactedMaterialsAvailableOption}
                    impactedMaterialsExternal={this.props.impactedMaterialsExternal}
                    deleteMaterial={this.props.deleteMaterial}
                    editExternalMaterial={this.props.editExternalMaterial}
                    materialTableRef={this.props.materialTableRef}
                    removeMaterialType={this.props.removeMaterialType}
                    removeManuallyEnteredMaterials={this.props.removeManuallyEnteredMaterials}
                    loadMaterialType={this.props.loadMaterialType}
                    
                    loadProductMaterialType={this.props.loadProductMaterialType}
                    setScreen2Validation={this.props.setScreen2Validation}
                    impactedMaterialType={this.props.impactedMaterialType}
                    triggerApplyAllImpactedMaterials={this.props.triggerApplyAllImpactedMaterials}
                    classificationListApplyAll={this.props.classificationListApplyAll}
                    screen3PageNumber={this.props.screen3PageNumber}
                    onScreen3PageChange={this.props.onScreen3PageChange}
                    materialTypeRef={this.props.materialTypeRef}
                    addMaterialContainerClosure={this.props.addMaterialContainerClosure}
                    removeMaterialContainerClosure={this.props.removeMaterialContainerClosure}
                    getDownstreamMaterials={this.props.getDownstreamMaterials}
                    getImpactedProducts={this.props.getImpactedProducts}
                    getImpactedMaterials={this.props.getImpactedMaterials}
                    addDownstreamMaterial={this.props.addDownstreamMaterial}
                    containerClosureApplyAll={this.props.containerClosureApplyAll}
                    removeExternalMaterialFromContainerClosure={this.props.removeExternalMaterialFromContainerClosure}
                    impactedMaterialsRef={this.props.impactedMaterialsRef}
                    containerClosurePageNumber={this.props.containerClosurePageNumber}
                    onContainerClosurePageChange={this.props.onContainerClosurePageChange}
                    expandedRowKeys={this.props.expandedRowKeys}
                    setExpandedRowKeys={this.props.setExpandedRowKeys}
                    downstreamProducts={this.props.downstreamProducts}
                    resetContainerClosurePageNumbers = {this.props.resetContainerClosurePageNumbers}
                    loadAdditionalInfo = {this.props.loadAdditionalInfo}
                    updateUserSelectedMaterialProps = {this.props.updateUserSelectedMaterialProps}
                    updateImpactedMaterialsOption = {this.props.updateImpactedMaterialsOption}
                    updateDownstreamImpactedMaterialsOption={this.props.updateDownstreamImpactedMaterialsOption}
                    updateTabSelection = {this.props.updateTabSelection}
                    userSelectedMaterialsWithMaterialType = {this.props.userSelectedMaterialsWithMaterialType}
                    updateMaterialClassification = {this.props.updateMaterialClassification}
                    userSelectedExternalMaterials= {this.props.userSelectedExternalMaterials}
                    userSelectedExternalMaterialProps= {this.props.userSelectedExternalMaterialProps}
                    appCacheFlags={this.props.appCacheFlags}
                />
                <ChangeInformation
                    cpws={this.props.cpws}
                    staticProductInformation={this.props.staticProductInformation}                    
                />
            </CPWSStep>
        );
    }
}
