import { ErrorInfo } from 'react';
import axios, { CancelTokenSource } from 'axios';
import {
    AdditionalInfo,
    ApiCall,
    AppOptions,
    AssessmentResult,
    Country,
    CPWS,
    CPWSClassification,
    ENV,
    HttpClient,
    Logger,
    MatchCriteria,
    Material,
    ProductModality,
    S3Properties,
    Site,
    RegulatoryQuestions,
    ExternalMaterial,
    DownstreamMaterial,
    ProductModalitySelections,
    SiteFunction,
    MaterialClassificationResult,
    MatchCriteriaAnswer,
    ColorTag,
    CPWSClassificationCodes,
    ContainerClosureMaterial,
    DirectionalAssesmentResult,
    AssesmentMarkets,
    BasicInfo,
    SupportingDetails,
    AdditionalInfoData,
    RelatedChanges,
    Endorsements,
    ProductInformation,
    Suppliers,
    Materials,
    Methods,
    Partners,
    Plants,
    RegisteredMarkets,
    ImpactAnalysis,
    SelectedMaterials,
    ProductsInfo,
    CommentDetails
} from '../model';
import {
    mapCpwsFileUploadResponse,
    mapGetAdditionalInfoFromResponse,
    mapGetAdditionalInfoRequest,
    mapGetClassificationListRequest,
    mapGetClassificationListResponse,
    mapGetFinalResultsRequest,
    mapGetFinalResultsResponse,
    mapGetImpactedMaterialsFromResponse,
    mapGetImpactedMaterialsExternalFromResponse,
    mapGetImpactedMaterialsRequest,
    mapGetMatchCriteriaRequest,
    mapGetMatchCriteriaResponse,
    mapGetPreSignedS3,
    //mapGetProductsFamilySitesMaterial,
    //mapGetProductsFamilySitesMaterialResponse,
    mapGetProductsRequest,
    mapProductModalities,
    mapSites,
    mapGetMaterialTypeWithCountRequest,
    mapUpdateSession,
    mapSaveCpwsAssessment,
    mapIngestNotProceed,
    mapUploadNotProceed,
    mapMaterialTypes,
    //mapGetContainerClosure,
    //mapGetContainerClosureResponse,
    //mapMaterialTypesforDownstream,
    //mapGetContainerClosureMaterialTypeWithCountRequest,
    mapEditProceed,
    mapGetMatchCriteriaYesRegulationFromResponse,
    mapGetSiteFunctions,
    mapGetSiteFunctionsFromResponse,
    mapGetClassificationCodesResponse,
    getRelatedChangesRequest,
    mapRelatedChangesApiResponse,
    mapGetProductMaterialTypeWithCountRequest,
    mapRequestSubmissionWorkflow
} from './apiMapper';
import { URLOptions, URLS } from './urls';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { someRegulatoryQuestionAnswersAreYes } from '../utils';
import { mockHttpClient } from './mocks/mockApi';

const uuid = require('uuid');

axios.interceptors.request.use(async function(config: any): Promise<any> {
    return Auth.currentAuthenticatedUser()
        .then((session: any) => {
            config.params = config.params || {};
            config.headers['Authorization'] = `Bearer ${session.signInUserSession.idToken.jwtToken}`;
            config.headers['Content-type'] = 'application/json';
            config.headers['Accept'] = 'application/json';
            config.params['reqID'] = `${uuidv4()}` || '0';
            config.params['sessionID'] = config.params['sessionID']
                ? config.params['sessionID']
                : `${window.sessionStorage.getItem('sessionID')}` || '0'; // sessionID is set when a cpws file is loaded
            config.params['isid'] = `${session.signInUserSession.idToken.payload.identities[0].userId}` || null;
            config.params['cpwsID'] = config.params['cpwsID']
                ? config.params['cpwsID']
                : `${window.sessionStorage.getItem('cpwsID')}` || null;
            // config.headers['Access-Control-Allow-Origin'] = '*' ;
            // config.headers['Access-Control-Request-Headers']= 'Authorization, Content-Type';
            // config.headers['Access-Control-Request-Method'] = ' POST, GET, OPTIONS';
           // config.params['first_name'] = `${session.attributes['custom:first_name']}`|| null;
           // config.params['last_name'] = `${session.attributes['custom:last_name']}` || null;

            return Promise.resolve(config);
        })
        .catch(err => {
            console.log('User credentials could not be found', err);
            return Promise.resolve(config);
        });
});

export default class ApiClient {
    httpClient: HttpClient;
    options: AppOptions;
    logger: Logger;
    urls: URLOptions = URLS.DEV; //TODO: default to something better
    private additionalInfoSource: CancelTokenSource | null;
    private siteFunctionsSource: CancelTokenSource | null;
    constructor(options: AppOptions, logger: Logger) {
        this.options = options;
        if (options.env === ENV.DEV) {
            this.urls = URLS.DEV;
        }
        this.httpClient = this.getHttpClient();
        this.logger = logger;
        this.additionalInfoSource = null;
        this.siteFunctionsSource = null;
    }
    defaultConfig = {
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    };

    //base method wrappers
    async post(
        url: string,
        data: any,
        config: any = this.defaultConfig,
    ): Promise<{ data: any; status: number | undefined } | boolean> {
        const call: ApiCall = { url, request: data };

        try {
            call.response = await axios.post(url, data, config);
        } catch (ex) {
            if (axios.isCancel(ex)) {
                console.log('request was cancelled');
                console.log(call.response);
            }
            call.error = ex;
            this.logger.setError(call);
        }
        this.logger.setCall(call);
        if (call.response) {
            //console.log('session response', call.response.data);
            return {
                data: call.response.data ? call.response.data : {},
                status: call.response.status ? call.response?.status : undefined,
            };
        } else if (call.error) {
            return {
                data: {},
                status: 0,
            };
        } else {
            this.logger.setError(call);
            return false;
        }
    }

    async get(url: string, config?: any): Promise<any> {
        const call: ApiCall = { url, request: 'GET' };

        try {
            call.response = await axios.get(url, config);
        } catch (ex) {
            this.logger.setError(call);
        }
        if (!call.response) {
            this.logger.setError(call);
            return false;
        }
        this.logger.setCall(call);

        return call.response.data;
    }

    getHttpClient(): HttpClient {
        return axios.create();
    }

    sendCpwsFiletoS3(file: any, s3props: S3Properties, callback: Function): any {
        const formData = new FormData();
        formData.append('key', s3props.key);
        formData.append('AWSAccessKeyId', s3props.AWSAccessKeyId);
        formData.append('x-amz-security-token', s3props.xamzsecuritytoken);
        formData.append('policy', s3props.policy);
        formData.append('signature', s3props.signature);
        formData.append('file', file);
        //const axiomg = axios.create();
        this.httpClient
            .post(s3props.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .catch(() => {
                file.status = 'error';
            })
            .then((response: any) => {
                if (response) {
                    if (response.status === 204) {
                        callback(s3props.key);
                    }
                }
                return;
            });
    }

    async getPreSignedS3(): Promise<S3Properties> {
        const response = await this.get(this.urls.GET_PRESIGNED_URL, {
            params: {
                cpwsID: 'null',
            },
        });
        return mapGetPreSignedS3(response);
    }

    async getCPWSFromS3(filename: string): Promise<any> {
        if (!filename) throw new Error('filename not defined');
        const response = await this.get(this.urls.CPWS_FILE_UPLOAD, {
            params: {
                file_name: filename,
                cpwsID: 'null',
            },
        });
        // check for not_proceed status
        if (response.status === 'not_proceed') {
            console.log('not_proceed upload');
            return mapUploadNotProceed(response);
        } else {
            return mapCpwsFileUploadResponse(response);
        }
    }

    async uploadOverwrite(filename: string): Promise<CPWS> {
        if (!filename) throw new Error('filename not defined');
        const url = this.urls.CPWS_FILE_UPLOAD;
        const response = await this.get(url, {
            params: {
                file_name: filename,
                overwrite: true,
                cpwsID: 'null',
            },
        });

        return mapCpwsFileUploadResponse(response);
    }

    /*async getProductsFamilySitesMaterial(products: Array<ProductModality>, materials: Array<Material>): Promise<any> {
        const request = mapGetProductsFamilySitesMaterial(products, materials);
        const response = await this.post(this.urls.GET_PRODUCTS_FAM_AND_SITES_BY_MATERIALS, request);
        if (typeof response !== 'boolean') {
            return mapGetProductsFamilySitesMaterialResponse(response.data);
        } else {
            return {};
        }
    }*/

    async getProducts(params?: string): Promise<Array<ProductModality>> {
        const response = await this.get(mapGetProductsRequest(this.urls.PRODUCTS_MODALITIES, params));
        return mapProductModalities(response);
    }

    async getSites(params?: string): Promise<Array<Site>> {
        const response = await this.get(mapGetProductsRequest(this.urls.SITES, params));
        return mapSites(response);
    }

    // async getSiteFunctions(products: Array<ProductModality>): Promise<Array<SiteFunction> | number> {
    //     const url = this.urls.GET_PDS_SITE_FUNCTIONS;

    //     const CancelToken = axios.CancelToken;

    //     if (this.siteFunctionsSource) {
    //         this.siteFunctionsSource.cancel('Canceling old API request.');
    //     }

    //     this.siteFunctionsSource = CancelToken.source();

    //     const requestBody = mapGetSiteFunctions(products);
    //     const response = await this.post(url, requestBody, {
    //         ...this.defaultConfig,
    //         cancelToken: this.siteFunctionsSource.token,
    //     });

    //     if (typeof response !== 'boolean') {
    //         if (response.status === 0) {
    //             return 0;
    //         }
    //         this.additionalInfoSource = null;
    //         return mapGetSiteFunctionsFromResponse(response.data);
    //     } else {
    //         return [];
    //     }
    // }

    async getSiteFunctions(products: Array<ProductsInfo>): Promise<Array<SiteFunction> | number> {
        const url = this.urls.GET_PDS_SITE_FUNCTIONS;

        const CancelToken = axios.CancelToken;

        if (this.siteFunctionsSource) {
            this.siteFunctionsSource.cancel('Canceling old API request.');
        }

        this.siteFunctionsSource = CancelToken.source();

        const requestBody = mapGetSiteFunctions(products);
        const response = await this.post(url, requestBody, {
            ...this.defaultConfig,
            cancelToken: this.siteFunctionsSource.token,
        });

        if (typeof response !== 'boolean') {
            if (response.status === 0) {
                return 0;
            }
            this.additionalInfoSource = null;
            return mapGetSiteFunctionsFromResponse(response.data);
        } else {
            return [];
        }
    }

    async getImpactedMaterials(
        products: Array<ProductModality>,
        sites: Array<Site>,
        ds_dp: Array<any>,
    ): Promise<Array<Material>> {
        const url = this.urls.GET_IMPACTED_MATERIALS;
        // const requestBody = mapGetImpactedMaterialsRequest(products, sites);
        const requestBody = mapGetImpactedMaterialsRequest(products, sites, ds_dp);
        const response = await this.post(url, requestBody);
        if (typeof response !== 'boolean') {
            return mapGetImpactedMaterialsFromResponse(response.data);
        } else {
            return [];
        }
    }

    async getImpactedMaterialsExternal(): Promise<Array<ExternalMaterial>> {
        const url = this.urls.GET_IMPACTED_MATERIAL_EXTERNAL;
        const response = await this.get(url);

        return mapGetImpactedMaterialsExternalFromResponse(response);
    }

    async getClassificationList(uuid: string): Promise<Partial<MaterialClassificationResult>> {
        const url = this.urls.GET_CLASSIFICATION_RESPONSE;

        const response = await this.get(url, {
            params: {
                tranID: uuid,
            },
        });

        if (response.status === 'pending' || response.status === 'error') {
            return response;
        } else {
            return mapGetClassificationListResponse(response);
        }
    }

    async postClassificationData(cpws: CPWS, uuid: string) {
        const url = this.urls.GET_CLASSIFICATION_LIST;

        const requestBody = mapGetClassificationListRequest(cpws);
        //console.log('requestBody :::', requestBody);

        const response = await this.post(url, requestBody, {
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            params: {
                tranID: uuid,
            },
        });

        if (typeof response !== 'boolean') {
            return response.status;
        } else {
            return 0;
        }
    }

    async getFinalResultsResponse (cpws: CPWS, uuid: string) : Promise<Partial<DirectionalAssesmentResult>>{
        const url = this.urls.GET_FINAL_RESULTS_RESPONSE + '?tranID=' + uuid;

        const response = await this.get(url, {
           
        });

        // if (typeof response !== 'boolean') {
        if ( response.status === 'pending' || response.status === 'error') {
            return response;
        } else {
            return mapGetFinalResultsResponse(response.result);
        }
    }
    
    

    async getClassificationCodes() {
        const url = this.urls.GET_CLASSIFICATION_CODES;

        const response = await this.get(url);

        if (response) {
            return mapGetClassificationCodesResponse(response);
        } else {
            return [];
        }
    }

    async getUserImportEditOption() {
        const url = this.urls.GET_ISVIEWONLYUSER;

        const response = await this.get(url);

        if (response) {
            return response;
        } else {
            return null;
        }
    }

    async getMatchCriteria(
        cpwsID: any,
        cpws: CPWS,
        countries: Array<Country>,
        ds_dp: Array<any>,
        sterile_non_sterile: Array<any>,
        productModalitySelections: ProductModalitySelections,
        userSelectedMaterials: Array<SelectedMaterials>,
    ): Promise<MatchCriteria> {
        const url = this.urls.GET_MATCH_CRITERIA;
        const requestBody = mapGetMatchCriteriaRequest(
            cpws,
            countries,
            ds_dp,
            sterile_non_sterile,
            productModalitySelections,
            userSelectedMaterials,
        );
        //console.log('match request body', requestBody);
        const response = await this.post(url, requestBody);
        //const response = await mockHttpClient.post(url);
        //console.log(response);

        if (typeof response !== 'boolean') {
            return mapGetMatchCriteriaResponse(response.data);
        } else {
            return [];
        }
    }

    async getFinalResults(
        sterile_non_sterile: Array<any>,
        category: Array<any>,
        cpws: CPWS,
        countries: Array<Country>,
        answers: MatchCriteriaAnswer,
        productModalitySelections: ProductModalitySelections,
        classificationCodes: CPWSClassificationCodes,
        matchCriteria: MatchCriteria,
        assessments: [],
        uuid: string,
    ) {
        
        const url = this.urls.GET_FINAL_RESULTS + '?tranID=' + uuid;
        const requestBody = mapGetFinalResultsRequest(
            sterile_non_sterile,
            category,
            cpws,
            countries,
            answers,
            productModalitySelections,
            classificationCodes,
            matchCriteria,
        );
       const response = await this.post(url, requestBody);
        //const response = await mockHttpClient.post(url);

        //console.log('response: ', response)
        
        // if (typeof response !== 'boolean') {
        //    return mapGetFinalResultsResponse(response.data);
        //    //return mapGetFinalResultsResponse(response.data as ApiFinalResult);
        // } else {
        //     return {} as DirectionalAssesmentResult;
        // }

        if (typeof response !== 'boolean') {
            return response.status;
        } else {
            return 0;
        }
    }

    async getAdditionalInfo(
            products: Array<ProductsInfo>,
            sites: Array<Site>,
        ): Promise<AdditionalInfo | number> {
            const url = this.urls.CPWS_ADDITIONAL_INFO;
            const CancelToken = axios.CancelToken;
    
            if (this.additionalInfoSource) {
                this.additionalInfoSource.cancel('Canceling old API request.');
            }
    
            this.additionalInfoSource = CancelToken.source();
    
            const requestBody = mapGetAdditionalInfoRequest(products, sites);
            const response = await this.post(url, requestBody, {
                ...this.defaultConfig,
                cancelToken: this.additionalInfoSource.token,
            });
    
            if (typeof response !== 'boolean') {
                if (response.status === 0) {
                    return 0;
                }
                this.additionalInfoSource = null;
                return mapGetAdditionalInfoFromResponse(response.data);
            } else {
                return {
                    impactedCountries: [],
                    //downstreamMaterials: [],
                    //impactedMaterials: [],
                };
            }
        }
    
    // async getAdditionalInfo(
    //     externalMaterials: Array<ExternalMaterial>,
    //     products: Array<ProductModality>,
    //     sites: Array<Site>,
    //     materials: Array<Material>,
    // ): Promise<AdditionalInfo | number> {
    //     const url = this.urls.CPWS_ADDITIONAL_INFO;
    //     const CancelToken = axios.CancelToken;

    //     if (this.additionalInfoSource) {
    //         this.additionalInfoSource.cancel('Canceling old API request.');
    //     }

    //     this.additionalInfoSource = CancelToken.source();

    //     const requestBody = mapGetAdditionalInfoRequest(externalMaterials, products, sites, materials);
    //     const response = await this.post(url, requestBody, {
    //         ...this.defaultConfig,
    //         cancelToken: this.additionalInfoSource.token,
    //     });

    //     if (typeof response !== 'boolean') {
    //         if (response.status === 0) {
    //             return 0;
    //         }
    //         this.additionalInfoSource = null;
    //         return mapGetAdditionalInfoFromResponse(response.data);
    //     } else {
    //         return {
    //             impactedCountries: [],
    //             downstreamMaterials: [],
    //             impactedMaterials: [],
    //         };
    //     }
    // }

    async getFinalAssesmentAdditionalInputs(modalities: any): Promise<object> {
        const url = this.urls.GET_FINAL_ASSESSMENT_ADDITIONAL_INPUTS;
        //console.log('modalities in api call: ', modalities)
        const requestBody = {
            modalities: modalities
        };

        const response = await this.post(url, requestBody);

        //console.log('response in new api call: ', response)

        if (typeof response !== 'boolean') {
            return response.data;
        } else {
            return {};
        }
    }

    async getProductMaterialTypeWithCount(productMaterials: any, DS_DP: Array<any>, modalities:ProductModalitySelections): Promise<Array<any>> {
        const url = this.urls.GET_MATERIAL_TYPE_WITH_COUNT;
        const requestBody = mapGetProductMaterialTypeWithCountRequest(productMaterials, DS_DP, modalities);
        const response = await this.post(url, requestBody);

        if (typeof response !== 'boolean') {
            const mapped = await mapMaterialTypes(response.data);
            return mapped;
        } else {
            return [];
        }
    }

    async getMaterialTypeWithCount(materials: Array<Material>, DS_DP: Array<any>,modalities:ProductModalitySelections): Promise<Array<any>> {
        const url = this.urls.GET_MATERIAL_TYPE_WITH_COUNT;
        const requestBody = mapGetMaterialTypeWithCountRequest(materials, DS_DP,modalities);
        const response = await this.post(url, requestBody);

        if (typeof response !== 'boolean') {
            const mapped = await mapMaterialTypes(response.data);
            return mapped;
        } else {
            return [];
        }
    }

    /*async getMaterialTypeWithCountforDownstream(materials: Array<ContainerClosureMaterial>, DS_DP: Array<string>, modalities:ProductModalitySelections) {
        const url = this.urls.GET_MATERIAL_TYPE_WITH_COUNT;
        const requestBody = mapGetContainerClosureMaterialTypeWithCountRequest(materials, DS_DP, modalities);
        const response = await this.post(url, requestBody);

        if (typeof response !== 'boolean') {
            return mapMaterialTypesforDownstream(response.data);
        } else {
            return [];
        }
    }*/

    // Update API
    async sendUpdate(
        status: any,
        first_name:string,
        last_name:string,
        isid: any,
        prevStep: any,
        curStep: any,
        cpwsID: any,
        cpws: CPWS,
        basicInfo: BasicInfo,
        supportingDetails: Array<SupportingDetails>,
        additionalInfoData: AdditionalInfoData,
        relatedChanges: Array<RelatedChanges>,
        endorsements: Endorsements,
        productInformation: ProductInformation,
        suppliers: Array<Suppliers>,
        materials: Array<Materials>,
        methods: Array<Methods>,
        partners: Array<Partners>,
        plants: Array<Plants>,
        registeredMarkets: Array<RegisteredMarkets>,
        impactAnalysis: Array<ImpactAnalysis>,
        userSelectedMaterials: Array<SelectedMaterials>,
        ds_dp?: Array<any>,
        sterile_non_sterile?: Array<any>,
        productsAvailable?: Array<ProductModality>,
        impactedMaterialsAvailable?: Array<Material>,
        impactedMaterialsAvailableOption?: any,
        downstreamImpactedMaterialsAvailableOption?: any,
        tab_num?: string,
        visitedChangeClassification?: boolean,
        comment_details?: CommentDetails,
        staticProductInformation?: ProductInformation,
        sitesAvailable?: Array<Site>,
        productsSitesImpacted?: any,
        appCacheFlags?: any,
        downstreamProducts?: Array<ProductModality>,
        downstreamSites?: Array<Site>,
        classificationList?: CPWSClassification,
        matchCriteria?: MatchCriteria,
        assessmentResults?: Array<AssessmentResult>,
        comment?: string,
        productTypeSelection?: string,
        impactedMaterialsExternal?: Array<ExternalMaterial>,
        sendExternalMaterials?: Array<any>,
        impactedMaterialType?: Array<any>,
        qaQuestionAnswered?: boolean,
        dpDsAnswered?: boolean,
        sterileAnswered?: boolean,
        impactedCountries?: Array<Country>,
        matchCriteriaYesRegulation?: Array<any>,
        complete?: any,
        unlockPDF?: boolean,
        regulatoryQuestions?: RegulatoryQuestions,
        justification?: string,
        downstreamMaterials?: Array<DownstreamMaterial>,
        productModalitySelections?: ProductModalitySelections,
        siteFunctions?: Array<SiteFunction>,
        materialClassificationUuid?: string,
        matchCriteriaAnswers?: MatchCriteriaAnswer,
        matchCriteriaKeyOrder?: Array<string>,
        productColorTags?: ColorTag,
        siteColorTags?: ColorTag,
        nriOption?: string,
        rationaleAssessment?: string,
        userAssessment?: string,
        userAssessmentRationale?: string,
        additionalNotes?: string,
        cakeSuggestions?: string,
        reviewer?: string,
        majorMarkets?:AssesmentMarkets,
        secondaryMarkets?:AssesmentMarkets,
        regImpact?:boolean,
        cake_status?: string,
        user_nri_ri?: string,
        system_nri_ri?: boolean,
        biologic_nri_ri?: string,
        vaccine_nri_ri?: string,
        smallmolecule_nri_ri?: string,
        scope_change_question?: string,
        screen4_update?: boolean,
        update_type?: string,
        user_comment?: string,
        prat?: object,
        submission_req_details?: any,
        currentPlanningAssessmentType?: string,
        currentDirectionalAssessmentReason?: string,
        directionalDetails?: string
    ): Promise<any> {
        //console.log('submission_req_details: ', submission_req_details)
        const url = this.urls.UPDATE_SESSION;
        const requestBody = mapUpdateSession(
            status,
            first_name,
            last_name,
            isid,
            prevStep,
            curStep,
            cpwsID,
            cpws,
            basicInfo,
            supportingDetails,
            additionalInfoData,
            relatedChanges,
            endorsements,
            productInformation,
            suppliers,
            materials,
            methods,
            partners,
            plants,
            registeredMarkets,
            impactAnalysis,
            userSelectedMaterials,
            ds_dp,
            sterile_non_sterile,
            productsAvailable,
            impactedMaterialsAvailable,
            impactedMaterialsAvailableOption,
            downstreamImpactedMaterialsAvailableOption,
            tab_num,
            visitedChangeClassification,
            comment_details,
            staticProductInformation,
            sitesAvailable,
            productsSitesImpacted,
            appCacheFlags,
            downstreamProducts,
            downstreamSites,
            classificationList,
            matchCriteria,
            assessmentResults,
            comment,
            productTypeSelection,
            impactedMaterialsExternal,
            sendExternalMaterials,
            impactedMaterialType,
            qaQuestionAnswered,
            dpDsAnswered,
            sterileAnswered,
            impactedCountries,
            matchCriteriaYesRegulation,
            complete,
            unlockPDF,
            regulatoryQuestions,
            justification,
            downstreamMaterials,
            productModalitySelections,
            siteFunctions,
            materialClassificationUuid,
            matchCriteriaAnswers,
            matchCriteriaKeyOrder,
            productColorTags,
            siteColorTags,
            nriOption,
            rationaleAssessment,
            userAssessment,
            userAssessmentRationale,
            additionalNotes,
            cakeSuggestions,
            reviewer,
            majorMarkets,
            secondaryMarkets,
            regImpact,
            cake_status,
            user_nri_ri,
            system_nri_ri,
            biologic_nri_ri,
            vaccine_nri_ri,
            smallmolecule_nri_ri,
            scope_change_question,
            screen4_update,
            update_type,
            user_comment,
            prat,
            submission_req_details,
            currentPlanningAssessmentType,
            currentDirectionalAssessmentReason,
            directionalDetails
        );

        const response = await this.post(url, requestBody);
        //console.log('session update', requestBody);

        if (typeof response !== 'boolean') {
            return response.data;
        } else {
            return {};
        }
    }

    // when user logs out cpws file will be unlocked
    async logout(cpwsID?: any): Promise<any> {
        const url = this.urls.LOGOUT;
        if (cpwsID) {
            await this.get(url, {
                params: {
                    cpwsID: cpwsID,
                },
            });
            console.log('logout succesful');
        } else {
            const response = await this.get(url);

            return response;
        }
    }

    //If user refreshses or closes browser this method will be called to unlock any CPWS file they had epen
    componentCleanup(cpwsID: string) {
        const url = new URL(this.urls.LOGOUT);
        const headers = new Headers();
        Auth.currentAuthenticatedUser().then((session: any) => {
            headers.append('Authorization', `Bearer ${session.signInUserSession.idToken.jwtToken}`);
            headers.append('Content-type', 'application/json');
            headers.append('Accept', 'application/json');
            url.search = new URLSearchParams({
                reqID: `${uuidv4()}` || '0',
                sessionID: `${window.sessionStorage.getItem('sessionID')}` || '0',
                cpwsID: cpwsID,
                isid: `${session.signInUserSession.idToken.payload.identities[0].userId}` || '',
            }).toString();

            fetch(url.toString(), {
                method: 'GET',
                headers: headers,
                keepalive: true,
            });
        });
    }

    // ingest CPWS data
    //
    async ingest(cpwsID: string,firstName:string,lastName:string): Promise<any> {
        if (!cpwsID) throw new Error('Invalid IPI ID');
        const url = this.urls.INGEST_CPWS_DATA;
        const response = await this.get(url, {
            params: {
                cpwsID: cpwsID.toLocaleUpperCase(),
                first_name: firstName,
                last_name: lastName
            },
        });
        // const response = await mockHttpClient.get(url);
        // console.log('ingest reponses', response);
        // console.log('ingest reponses status', response.status);
        if (response.status === 'not_proceed') {
        //if(String(response.status) === 'not proceed') {
            // change to not_proceed for live data
            return mapIngestNotProceed(response);
        } else {
            return mapCpwsFileUploadResponse(response);
        }
    }

    async ingestAsync(cpwsID: string,firstName:string,lastName:string, uuid:string): Promise<any> {
        if (!cpwsID) throw new Error('Invalid IPI ID');
        const url = this.urls.INGEST_CPWS_DATA_ASYNC;
        const response = await this.get(url, {
            params: {
                cpwsID: cpwsID.toLocaleUpperCase(),
                first_name: firstName,
                last_name: lastName,
                tranID: uuid
            },
        });
        // const response = await mockHttpClient.get(url);
        // console.log('ingest reponses', response);
        // console.log('ingest reponses status', response.status);        
        if (response.status === 'not_proceed') {        
            return mapIngestNotProceed(response);
        } else {
            console.log("Response:", response)
            return mapCpwsFileUploadResponse(response);
        }        
    }

    async ingestOverwriteAsync(cpwsID: string, firstName:string, lastName:string, uuid:string): Promise<any> {
        const url = this.urls.INGEST_CPWS_DATA_ASYNC;
        // const response = await this.get(url + '?cpwsID=' + cpwsID, '?overwrite=' + true);
        const response = await this.get(url, {
            params: {
                cpwsID: cpwsID.toLocaleUpperCase(),
                overwrite: true,
                first_name: firstName,
                last_name: lastName,
                tranID: uuid
            },
        });
        //console.log(response);
        var tmpRes = mapCpwsFileUploadResponse(response);
        return tmpRes;
    }

    async getIngestResponse(cpwsID: string, firstName:string, lastName:string, uuid:string): Promise<any> {
        const url = this.urls.GET_INGEST_RESPONSE;
        // const response = await this.get(url + '?cpwsID=' + cpwsID, '?overwrite=' + true);
        const response = await this.get(url, {
            params: {
                cpwsID: cpwsID.toLocaleUpperCase(),
                overwrite: true,
                first_name: firstName,
                last_name: lastName,
                tranID: uuid
            },
        });
        return mapCpwsFileUploadResponse(response);
    }

    async ingestOverwrite(cpwsID: string, firstName:string, lastName:string): Promise<any> {
        const url = this.urls.INGEST_CPWS_DATA;
        // const response = await this.get(url + '?cpwsID=' + cpwsID, '?overwrite=' + true);
        const response = await this.get(url, {
            params: {
                cpwsID: cpwsID.toLocaleUpperCase(),
                overwrite: true,
                first_name: firstName,
                last_name: lastName
            },
        });
        return mapCpwsFileUploadResponse(response);
    }

    // edit CPWS data
    async edit(cpwsID: string, firstName:string, lastName:string): Promise<any> {
        if (!cpwsID) throw new Error('Invalid IPI ID');
        const url = this.urls.EDIT_CPWS_DATA;
        const response = await this.get(url, {
            params: {
                cpwsID: cpwsID.toLocaleUpperCase(),
                first_name: firstName,
                last_name: lastName
            },
        });

        if (response.status === 'proceed') {
            return mapEditProceed(response);
        } else {
            return response;
        }
    }

    async view(cpwsID: string,firstName:string, lastName:string): Promise<any> {        
        if (!cpwsID) throw new Error('Invalid IPI ID');
        const url = this.urls.EDIT_CPWS_DATA;
    // const response = await mockHttpClient.get(url);
        //return mapEditProceed(response.data);
        const response = await this.get(url, {
            params: {
                cpwsID: cpwsID.toLocaleUpperCase(),
                readOnly: true,
                first_name: firstName,
                last_name: lastName
            },
        });

        if (response.status === 'proceed') {
            return mapEditProceed(response);
        } else {
            return response;
        }               
    }

    async deleteMaterialExternal(materialNumber: string): Promise<any> {
        if (materialNumber === '') throw new Error('Invalid material number');
        const url = this.urls.DELETE_MATERIAL_EXTERNAL;
        const response = await this.get(url, {
            params: {
                mtl_number: materialNumber,
            },
        });

        return response;
    }

    async saveCPWSAssessment({
        cpws,
        regulatoryQuestions,
        ds_dp,
        sterile_non_sterile,
        justification,
        modalityList,
        user_nri_ri,
        system_nri_ri,
        biologic_nri_ri,
        vaccine_nri_ri,
        smallmolecule_nri_ri,
        scope_change_question,
        screen4_update,
        prat,
        sterileAnswered,
        submission_req_details,
        productsSitesImpacted,
        classificationCodes,
        materialCodeMapping,
        userSelectedMaterials,
        directionalDetails,
        answers,
        matchCriteria,
        assessments
    }: {
        cpws: CPWS;
        regulatoryQuestions: RegulatoryQuestions;
        ds_dp?: Array<string>;
        sterile_non_sterile?: Array<string>;
        justification?: string;
        modalityList?: ProductModalitySelections;
        user_nri_ri: string;
        system_nri_ri: boolean;
        biologic_nri_ri: string;
        vaccine_nri_ri: string;
        smallmolecule_nri_ri: string;
        scope_change_question: string;
        screen4_update: boolean;
        prat: any;
        sterileAnswered: any;
        submission_req_details: any;
        productsSitesImpacted: any;
        classificationCodes: any;
        materialCodeMapping: any;
        userSelectedMaterials: any;
        directionalDetails: string;
        answers: MatchCriteriaAnswer;
        matchCriteria: MatchCriteria;
        assessments:[]
    }): Promise<number> {
        const url = this.urls.SAVE_CPWS_ASSESSMENT;

        //console.log('regulatoryQuestions: ', regulatoryQuestions)

        // const requestBody = someRegulatoryQuestionAnswersAreYes(regulatoryQuestions)
        //     ? mapSaveCpwsAssessment({ cpws, regulatoryQuestions, justification })
        //     : mapSaveCpwsAssessment({
        //           cpws,
        //           regulatoryQuestions,
        //           ds_dp,
        //           sterile_non_sterile,
        //           modalityList,
        //       });

        const requestBody = mapSaveCpwsAssessment({
            cpws,
            regulatoryQuestions,
            ds_dp,
            sterile_non_sterile,
            justification,
            modalityList,
            user_nri_ri,
            system_nri_ri,
            biologic_nri_ri,
            vaccine_nri_ri,
            smallmolecule_nri_ri,
            scope_change_question,
            screen4_update,
            prat,
            sterileAnswered,
            submission_req_details,
            productsSitesImpacted,
            classificationCodes,
            materialCodeMapping,
            userSelectedMaterials,
            directionalDetails,
            answers,
            matchCriteria,
            assessments
        })

        //console.log('requestBody: ', requestBody)

        const response = await this.post(url, requestBody);
        if (typeof response !== 'boolean') {
            if (response.status) {
                return response.status;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    async downloadSubmissionWorkbook(tranID: any): Promise<any> {
        const url = this.urls.DOWNLOAD_SUBMISSION_WORKBOOK;
        
        const response = await this.get(url, {
            headers: {
               'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
               Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            params: {
                tranID: tranID
            }
        });

        console.log("Response in download workbook: ", response)

        return response;
    }

    async requestSubmissionWorkbook(cpws: CPWS, modalityList: ProductModalitySelections, markets: any, cht_codes: any): Promise<any> {
        const url = this.urls.REQUEST_SUBMISSION_WORKBOOK;

        //console.log("Markets: ", markets)
        //console.log("Cht codes: ", cht_codes)

        const requestBody = mapRequestSubmissionWorkflow(cpws, modalityList, markets, cht_codes);

        const getTranID = uuid.v4();

        console.log('getTranID: ', getTranID)

        const response = await this.post(url, requestBody, {
            params: {
                tranID: getTranID
            } 
        });

        console.log('response in workbook submission: ', response)

        if (typeof response !== 'boolean') {
            if(response.data.status == "processed") {
                return getTranID;
            } else {
                return "error"
            }            
        } else {
            return [];
        }
    }

    /*async getContainerClosure(materialNumber: string, products: Array<ProductModality>): Promise<any> {
        const url = this.urls.GET_CONTAINER_CLOSURE_MATERIALS;
        const requestBody = mapGetContainerClosure(materialNumber, products);

        const response = await this.post(url, requestBody);

        if (typeof response !== 'boolean') {
            return mapGetContainerClosureResponse(response.data);
        } else {
            return [];
        }
    }*/

    async getMatchCriteriaYesRegulation(
        sterile_non_sterile: Array<any>,
        category: Array<any>,
        cpws: CPWS,
        countries: Array<Country>,
        answers: MatchCriteriaAnswer,
        productModalitySelections: ProductModalitySelections,
        classificationCodes: CPWSClassificationCodes,
        matchCriteria: MatchCriteria,
        uuid:string,
    ): Promise<any> {
        const url = this.urls.GET_MATCH_CRITERIA_YES_REGULATION + '?tranID=' + uuid;;
        const requestBody = mapGetFinalResultsRequest(
            sterile_non_sterile,
            category,
            cpws,
            countries,
            answers,
            productModalitySelections,
            classificationCodes,
            matchCriteria
        );
        const response = await this.post(url, requestBody);
        
        if (typeof response !== 'boolean') {
            return response;
        } else {
            return 0;
        }
    }


    async getMatchCriteriaYesRegulationResponse ( uuid: string) : Promise<any>{
        const url = this.urls.GET_MATCH_CRITERIA_YES_REGULATION_RESPONSE + '?tranID=' + uuid;;

        const response = await this.get(url, {
           
        });

        if ( response.status === 'pending' || response.status === 'error') {
            return response;
        } else {
            return mapGetMatchCriteriaYesRegulationFromResponse(response.result);
        }
    }

    async saveExternalMaterials(material: ExternalMaterial) {
        const url = this.urls.SAVE_EXTERNAL_MATERIALS;
        const requestBody = {
            external_material: [
                {
                    mtl_name: material.name,
                    mtl_number: material.number,
                },
            ],
        };

        const response = await this.post(url, requestBody);
        if (typeof response !== 'boolean') {
            return response.data;
        } else {
            return {};
        }
    }

    async getAttachment(filename: string) {
        const url = `${this.urls.FILE}/${filename}`;
        const response = await this.get(url);
        return response;
    }

    postFrontendError(error: Error, errorInfo: ErrorInfo, cpwsID: string, sessionID: string) {
        console.log("post FrontEndError called");
        window.sessionStorage.setItem('ApiFlagError','true');
        const url = this.urls.ERROR;
        this.post(
            url,
            {
                error_message: error.toString(),
                stack_trace: errorInfo.componentStack.toString(),
            },
            {
                crossDomain: true,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                params: {
                    cpwsID: cpwsID,
                    sessionID: sessionID,
                },
            },
        );
    }

    setIncomplete(cpwsID: string) {
        const url = this.urls.SET_INCOMPLETE;
        this.get(url, {
            params: {
                cpwsID: cpwsID,
            },
        });
    }

    async getRelatedChanges(data: Array<RelatedChanges>): Promise<any> {
        const request = getRelatedChangesRequest(data);
        const url = this.urls.GET_RELATED_CHANGES;
        // const response = await mockHttpClient.get(url);
        const response = await this.post(url, request);
        
        // console.log('ingest reponses status', response.status);
        if (response) {
            return mapRelatedChangesApiResponse(response);
        // //if(String(response.status) === 'not proceed') {
        //     // change to not_proceed for live data
        //     console.log('mapIngestNotProceed');
        //    
        // } else {
        //     console.log('other', response.status);
        //     return mapCpwsFileUploadResponse(response);
        // }
        }
    }
}
