import React from 'react';
import EditOutlined from '@ant-design/icons/EditOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { Popconfirm, Modal, notification } from 'antd';
import find from 'lodash/find';
import { ExternalMaterial } from '../model';

interface ExternalMaterialsDropdownProps {
    material: ExternalMaterial;
    externalMaterialSelected: Function;
    deleteItem: Function;
    setDropdown: Function;
    editExternalMaterial: Function;
    externalMaterials: Array<ExternalMaterial>;
}

export const ExternalMaterialsDropdown: React.FC<ExternalMaterialsDropdownProps> = (
    props: ExternalMaterialsDropdownProps,
) => {
    const [editFlag, setEditFlag] = React.useState(false);
    const [hovering, setHovering] = React.useState(false);
    const [popupVisible, setPopupVisible] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const materialNameExists = () => {
        notification.info({
            message: 'Material Name error',
            description: 'A material with that name already exists or the material name was empty. No edit made.',
        });
    };

    const openEditModal = async () => {
        return Modal.confirm({
            title: 'Edit Material Name: ',
            content: (
                <input
                    id={props.material.number}
                    onChange={e => console.log(e.target.value)}
                    defaultValue={props.material.name}
                    className="ant-input"
                    ref={inputRef}
                ></input>
            ),
            onOk: () => {
                return new Promise<void>((resolve, reject) => {
                    if (inputRef.current) {
                        if (
                            !find(
                                props.externalMaterials,
                                (material: ExternalMaterial) =>
                                    inputRef.current && material.name === inputRef.current.value,
                            ) &&
                            inputRef.current.value !== ''
                        ) {
                            props.editExternalMaterial(props.material.number, inputRef.current.value).then(() => {
                                if (inputRef.current) {
                                    props.externalMaterialSelected(inputRef.current.value);
                                    resolve();
                                } else {
                                    reject();
                                }
                            });
                        } else {
                            materialNameExists();
                            resolve();
                        }
                    }
                }).catch(() => console.log('errors!'));
            },
        });
    };

    const handleVisible = (visible: boolean) => {
        setPopupVisible(visible);
        if (!visible) {
            setHovering(false);
        }
    };

    const checkPopup = () => {
        if (!popupVisible) {
            setHovering(false);
        }
    };

    return (
        <div className="material-dropdown" onMouseEnter={() => setHovering(true)} onMouseLeave={() => checkPopup()}>
            <div
                className="material-dropdown-child"
                onClick={() => {
                    if (!editFlag) {
                        props.externalMaterialSelected(props.material);
                    } else {
                        props.setDropdown();
                    }
                }}
            >
                <span title={props.material.name}>{props.material.name}</span>
            </div>
            {hovering ? (
                <div>
                    <EditOutlined
                        onClick={() => {
                            setEditFlag(true);
                            openEditModal();
                        }}
                        className="material-dropdown-icon material-dropdown-icon-edit"
                        title="edit"
                    />
                    <Popconfirm
                        title={`Are you sure you want to delete: ${props.material.name}`}
                        onConfirm={e => {
                            props.deleteItem(props.material, e);
                            setHovering(false);
                        }}
                        okText="Delete"
                        cancelText="Cancel"
                        placement="topRight"
                        onVisibleChange={handleVisible}
                        onCancel={() => setHovering(false)}
                    >
                        <DeleteOutlined
                            className="material-dropdown-icon material-dropdown-icon-trash"
                            title="delete"
                            onClick={e => e.stopPropagation()}
                        />
                    </Popconfirm>
                </div>
            ) : null}
        </div>
    );
};
