import { Card, Collapse, Select, Table, Button } from 'antd';
import React from 'react';
import { Material, MaterialType, PanelProps, ExternalMaterial, ImpactedMaterialsRef, Materials, SelectedMaterials } from '../model';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import mapValues from 'lodash/mapValues';
import cloneDeep from 'lodash/cloneDeep';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';
import { sortObjectArrayByValue } from '../utils';
import { ContainerClosure } from './NewContainerClosure';
import { MaterialTypes } from './MaterialTypes';

const { Option } = Select;
const { Panel } = Collapse;

export interface ImpactedMaterialsListProps {
    removeManuallyEnteredMaterials: Function;
    downstreamImpactedMaterialsAvailableOption: any;
    impactedProductMaterials?:any;
    header: string;
    impactedMaterials: Array<Materials>;
    panelProps: PanelProps;
    updateMaterial?: any;
    removeMaterialType?: any;
    loading: boolean;
    loadMaterialType: Function;
    materialTypeWithCount: Array<any>;
    current: number;
    triggerApplyAllImpactedMaterials?: Function;
    printPDF?: boolean;
    impactedMaterialsPageNumber?: number;
    onPageChange?: Function;
    materialTypeRef?: React.RefObject<HTMLDivElement>;
    addMaterialContainerClosure?: Function;
    removeMaterialContainerClosure?: Function;
    containerClosureApplyAll?: Function;
    deleteExternalMaterial?: Function;
    editExternalMaterial?: Function;
    addCustomMaterialContainerClosure?: Function;
    addDownstreamMaterial?: Function;
    getDownstreamMaterials: Function;
    getImpactedProducts: Function;
    getImpactedMaterials: Function;
    externalMaterials?: Array<ExternalMaterial>;
    removeExternalMaterialFromContainerClosure?: Function;
    impactedMaterialsRef?: ImpactedMaterialsRef;
    containerClosurePageNumber?: {
        [key: string]: number;
    };
    onContainerClosurePageChage: Function;
    expandedRowKeys: Array<string>;
    setExpandedRowKeys: Function;
    userSelectedMaterials: Array<SelectedMaterials>;
    updateCurrentTab?: Function;
    updateDownstreamImpactedMaterialsOption: Function;
    viewOnly?: boolean;
}

export interface ImpactedMaterialsListState {
    hover: boolean;
    containerOpen: boolean;
    containerClosureLoading: { [key: string]: boolean };
    selectedMaterialType: string;
    //expandedRowKeys: Array<string>;
}

export class ImpactedMaterialsList extends React.Component<ImpactedMaterialsListProps, ImpactedMaterialsListState> {
    constructor(props: any) {
        super(props);
        this.state = {
            containerOpen: false,
            hover: false,
            containerClosureLoading: {},
            selectedMaterialType: '',
            //expandedRowKeys: [],
        };
        this.props.impactedMaterials.forEach((mat: Materials) => {
            if (this.props.impactedMaterialsRef && !find(this.props.impactedMaterialsRef, ['number', mat.material_number])) {
                this.props.impactedMaterialsRef.push({
                    number: mat.material_number,
                    ref: React.createRef(),
                    downstreamRefs: [],
                });
            }
        });
    }

    async componentDidMount() {
        //await this.props.loadMaterialType();

        const rowKeys = this.props.impactedMaterials.reduce((materialNumbers: Array<string>, mat: Materials) => {
            if (
               //mat.type.some((matType: MaterialType) => matType.code === 'T14') &&
               
                !materialNumbers.includes(mat.material_number)
            ) {
                materialNumbers.push(mat.material_number);
            }
            return materialNumbers;
        }, []);
        if (this.props.current !== 7) {
            this.props.setExpandedRowKeys(rowKeys);
        }

    }

    onHover = () => {
        this.setState({ hover: true });
    };

    hoverOff = () => {
        this.setState({ hover: false });
    };

    handleExpand = (expanded: boolean, record: SelectedMaterials) => {
        const index = this.props.expandedRowKeys.indexOf(record.number);
        if (index > -1 && !expanded) {
            const newKeys = cloneDeep(this.props.expandedRowKeys);
            newKeys.splice(index, 1);
            this.props.setExpandedRowKeys(newKeys);
        } else if ((index === -1 && expanded) || (index > -1 && expanded)) {
            const newKeys = cloneDeep(this.props.expandedRowKeys);
            newKeys.push(record.number);
            this.props.setExpandedRowKeys(newKeys);
        }
    };

    applyAllImpactedMaterials() {
        if (this.props.triggerApplyAllImpactedMaterials) {
            const sortedMaterials = sortObjectArrayByValue([...this.props.userSelectedMaterials], 'name');
            if (sortedMaterials[0].type.some((matType: MaterialType) => matType.code === 'T14')) 
            {
                const keys = sortedMaterials.slice(1).reduce(
                    (keys: { rowKeys: Array<string>; closureKeys: { [key: string]: boolean } }, mat: SelectedMaterials) => {
                        if (!this.props.expandedRowKeys.includes(mat.number)) {
                            keys.rowKeys.push(mat.number);
                        }
                        keys.closureKeys[mat.number] = true;
                        return keys;
                    },
                    { rowKeys: [], closureKeys: { ...this.state.containerClosureLoading } },
                );
                this.props.setExpandedRowKeys([...this.props.expandedRowKeys, ...keys.rowKeys]);
                this.setState({
                    containerClosureLoading: keys.closureKeys,
                });
                this.props.triggerApplyAllImpactedMaterials().then(() =>
                    this.setState({
                        containerClosureLoading: mapValues(keys.closureKeys, () => false),
                    }),
                );
            } else {
                this.props.triggerApplyAllImpactedMaterials();
            }
        }
    }

    hasContainerClosureSelected(material: SelectedMaterials) {
        return material.type.some((matType: MaterialType) => matType.code === 'T14');
      //return true;
    }

    renderContainerClosureTable(material: SelectedMaterials) {
        return (
            <ContainerClosure
                material={material}
                addMaterialContainerClosure={this.props.addMaterialContainerClosure}
                removeMaterialContainerClosure={this.props.removeMaterialContainerClosure}
                containerClosureApplyAll={this.props.containerClosureApplyAll}
                impactedMaterials={this.props.impactedMaterials}
                currentScreen={this.props.current}
                containerClosureLoading={this.state.containerClosureLoading}
                deleteExternalMaterial={this.props.deleteExternalMaterial}
                editExternalMaterial={this.props.editExternalMaterial}
                addDownstreamMaterial={this.props.addDownstreamMaterial}
                getDownstreamMaterials={this.props.getDownstreamMaterials}
                getImpactedProducts={this.props.getImpactedProducts}
                getImpactedMaterials={this.props.getImpactedMaterials}
                externalMaterials={this.props.externalMaterials}
                updateDownstreamImpactedMaterialsOption={this.props.updateDownstreamImpactedMaterialsOption}
                downstreamImpactedMaterialsAvailableOption={this.props.downstreamImpactedMaterialsAvailableOption}
                removeExternalMaterialFromContainerClosure={this.props.removeExternalMaterialFromContainerClosure}
                removeManuallyEnteredMaterials={this.props.removeManuallyEnteredMaterials}
                pageNumber={this.props.containerClosurePageNumber && 
                    this.props.containerClosurePageNumber[material.number]
                        ? this.props.containerClosurePageNumber[material.number]
                        : 1
                }
                materials={this.props.userSelectedMaterials}
                onContainerClosurePageChage={this.props.onContainerClosurePageChage}
                impactedMaterialsRef={this.props.impactedMaterialsRef}
                printPDF={this.props.printPDF}
            />
        );
    }

    selectBox(text: any, record: SelectedMaterials): JSX.Element {
        const { materialTypeWithCount, current } = this.props;
        let extractMaterial: { [key: string]: MaterialType } = {};       

        //console.log('materialTypeWithCount: ', materialTypeWithCount)

        materialTypeWithCount.forEach((material: any) => {
            //console.log('material found: ', material)
            if (material.number == record.number) {
                extractMaterial = material.mtl_types_count[0];
            }
        });

        const selectionMade = (__: any, option: any, record: any) => {
            const regex = /^(.+)_/m;
            const match = regex.exec(option.key);
            if (match) {
                const material = find(this.props.userSelectedMaterials, ['number', record.number]);
                if (material) {
                    const parsedMaterial = JSON.parse(option.value);
                    this.setState({selectedMaterialType: parsedMaterial.name})
                    if (this.props.updateCurrentTab) {
                        this.props.updateCurrentTab('materialTypes');
                    }
                    if (parsedMaterial.code === 'T14') {
                        this.props.setExpandedRowKeys(
                            !this.props.expandedRowKeys.includes(material.number)
                                ? [...this.props.expandedRowKeys, material.number]
                                : this.props.expandedRowKeys,
                        );
                        this.setState({
                            containerClosureLoading: {
                                ...this.state.containerClosureLoading,
                                [material.number]: true,
                            },
                        });
                        this.props.updateMaterial(material, option.value).then(() =>
                            this.setState({
                                containerClosureLoading: {
                                    ...this.state.containerClosureLoading,
                                    [material.number]: false,
                                },
                            }),
                        );
                    } else {
                        this.props.updateMaterial(material, option.value);
                    }
                }
            }
        };

        const deselectionMade = (typeValue: string, material: SelectedMaterials) => {
            const parsedInput = JSON.parse(typeValue) as MaterialType;
            this.props.removeMaterialType(material, parsedInput);

            if (parsedInput.code === 'T14' && this.props.expandedRowKeys.includes(material.number)) {
                const rowKeys = [...this.props.expandedRowKeys];
                const index = findIndex(rowKeys, material.number);
                rowKeys.splice(index, 1);
                this.props.setExpandedRowKeys(rowKeys);
            }
        };

        const getValues = () => {
            //console.log('record value>> ',  record);
            const mat = find(this.props.userSelectedMaterials, ['number', record.number]) as any;
            if (mat) {
                const regex = / \(\d+\)$/m;
                return mat?.type?.map?.((type: MaterialType) =>
                    JSON.stringify({
                        code: type.code,
                        name: type.name.replace(regex, ''),
                    }),
                );
            } else {
                return [];
           }
        };

        return (
            <FormContext.Consumer>
                {({ materialTypeSelected, viewOnly }) => (
                    <div className={(viewOnly || this.props.viewOnly === true) ? 'view-only' : ''}>
                        {!materialTypeSelected && record.type.length < 1 
                        ? (
                            <FormValidationErrorText text="Please select a material type" />
                        ) : null}
                        <Select
                            value={getValues()}
                            mode="multiple"
                            style={{ width: 200 }}
                            onSelect={(value, option) => selectionMade(null, option, record)}
                            onDeselect={(typeValue: string) => deselectionMade(typeValue, record)}
                            placeholder="Please select"
                            dropdownStyle={{ width: this.state.hover ? '375px' : '200px' }}
                            onMouseEnter={this.onHover}
                            onMouseLeave={this.hoverOff}
                            dropdownMatchSelectWidth={false}
                            disabled={current === 7 ? true : false}
                            className={!materialTypeSelected 
                                && record.type.length < 1 
                                ? 'select-highlight' : ''}
                        >
                            {Object.values(extractMaterial)
                                .sort((a: MaterialType, b: MaterialType) =>
                                    a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
                                )
                                .map((materialType: any) => {
                                    const regex = / \(\d+\)$/m;
                                    if (materialType.code !== 'T0') {
                                        return (
                                            <Option
                                                key={`${record.number}_${materialType.code}`}
                                                value={JSON.stringify({
                                                    code: materialType.code,
                                                    name: materialType.name.replace(regex, ''),
                                                })}
                                                disabled={
                                                    record.type.some(
                                                        (matType: MaterialType) => matType.code === 'T14',
                                                    ) && materialType.code !== 'T14'
                                                        ? true
                                                        : record.type.some(
                                                              (matType: MaterialType) =>
                                                                  matType.code !== 'T14' && materialType.code === 'T14',
                                                          )
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {materialType.name}
                                            </Option>
                                        );
                                    }
                                    return false;
                                })}
                        </Select>
                    </div>
                )}
            </FormContext.Consumer>
        );
    }

    render(): JSX.Element {
        const columns: any = [
            {
                title: 'Name',
                dataIndex: 'name',
                render: (text: string, record: SelectedMaterials) => {
                    const index = findIndex(this.props.impactedMaterialsRef, ['number', record.number]);
                    if (index > -1 && this.props.impactedMaterialsRef) {
                        return <span ref={this.props.impactedMaterialsRef[index].ref}>{text}</span>;
                    } else {
                        return <span>{text}</span>;
                    }
                },
                className: 'subtable-border',
            },
            {
                title: 'Type',
                dataIndex: 'type',
                elipsis: true,
                render: (text: string, record: SelectedMaterials) => {
                    if(record.typeCode === "P")
                        return 'Product';
                    else if(record.typeCode === "M")
                        return 'Material';
                    else if(record.typeCode === "E")
                        return 'N/A';
                },
            },
            {
                title: 'Material Number',
                dataIndex: 'number',
                elipsis: true,
                render: (text: string) => {
                    const regex = /^ext-/m;
                    return regex.exec(text) !== null ? 'N/A' : text.replace(/^0+/, '');
                },
            },
            {
                title: 'Select Material Type',
                render: (text: any, record: any) => {
                    return this.selectBox(text, record);
                },
            },
        ];
        return this.props.current === 2 ? (
            <div ref={this.props.materialTypeRef}>
                <Card bordered={false}>
                    <FormContext.Consumer>
                        {({ viewOnly }) => (
                            <Collapse defaultActiveKey="1">
                                <Panel
                                    header={this.props.header}
                                    key="1"
                                    extra={
                                        <Button
                                            type="primary"
                                            disabled={(viewOnly || this.props.viewOnly === true)}
                                            onClick={e => {
                                                e.stopPropagation();
                                                this.applyAllImpactedMaterials();
                                            }}
                                        >
                                            Apply All
                                        </Button>
                                    }
                                >
                                    <Table
                                       // loading={this.props.loading}
                                        pagination={
                                            !this.props.printPDF && this.props.impactedMaterialsPageNumber
                                                ? {
                                                      defaultPageSize: 10,
                                                      hideOnSinglePage: true,
                                                      showTitle: false,
                                                      current: this.props.impactedMaterialsPageNumber,
                                                      onChange: (page, pageSize) => {
                                                          if (
                                                              this.props.onPageChange &&
                                                              this.props.materialTypeRef?.current
                                                          ) {
                                                              window.scrollTo(
                                                                  0,
                                                                  this.props.materialTypeRef?.current?.offsetTop,
                                                              );
                                                              this.props.onPageChange(page, pageSize);
                                                          }
                                                      },
                                                  }
                                                : !this.props.printPDF && !this.props.impactedMaterialsPageNumber
                                                ? {
                                                      defaultPageSize: 10,
                                                      hideOnSinglePage: true,
                                                      showTitle: false,
                                                      onChange: () => {
                                                          if (this.props.materialTypeRef?.current) {
                                                              window.scrollTo(
                                                                  0,
                                                                  this.props.materialTypeRef?.current?.offsetTop,
                                                              );
                                                          }
                                                      },
                                                  }
                                                : false
                                        }
                                        size="small"
                                        rowKey={(record: any) => record.number}
                                        columns={columns}
                                        dataSource={this.props.userSelectedMaterials}
                                        expandable={{
                                            rowExpandable: this.hasContainerClosureSelected,
                                            expandedRowRender: (record: SelectedMaterials) =>
                                                this.renderContainerClosureTable(record),
                                            expandedRowKeys: this.props.expandedRowKeys,
                                            defaultExpandAllRows: true,
                                            onExpand: this.handleExpand,
                                        }}
                                    />
                                   
                                </Panel>
                            </Collapse>
                        )}
                    </FormContext.Consumer>
                </Card>
            </div>
        ) : (
            
            <div ref={this.props.materialTypeRef}>
               
                    <Collapse defaultActiveKey="1">
                        <Panel header={this.props.header} key="1">
                        
                            <Table
                               // loading={this.props.loading}
                                pagination={
                                    this.props.printPDF
                                        ? false
                                        : {
                                              defaultPageSize: 10,
                                              hideOnSinglePage: true,
                                              showTitle: false,
                                              onChange: () => {
                                                  if (this.props.materialTypeRef?.current) {
                                                      //console.log(this.props.materialTypeRef.current.offsetTop);
                                                      window.scrollTo(
                                                          0,
                                                          this.props.materialTypeRef?.current?.offsetTop,
                                                      );
                                                  }
                                              },
                                          }
                                }
                                showHeader={this.props.printPDF ? false : true}
                                size="small"
                                rowKey={(record: any) => record.number}
                                columns={columns}
                                dataSource={this.props.userSelectedMaterials}
                                expandable={{
                                    rowExpandable: this.hasContainerClosureSelected,
                                    expandedRowRender: (record: SelectedMaterials) => this.renderContainerClosureTable(record),
                                    expandedRowKeys: this.props.expandedRowKeys,
                                    defaultExpandAllRows: false,
                                    onExpand: this.handleExpand,
                                }}
                            />
                        </Panel>
                    </Collapse>
                
            </div>
        );
    }
}
