import { Card, Col, Collapse, Row, Typography, Checkbox } from 'antd';
import React from 'react';
import { CPWS, PanelProps, ProductModalitySelections } from '../model';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const { Panel } = Collapse;
const { Text } = Typography;

interface CPWSQuestionProps {
    panelProps: PanelProps;
    category?: any;
    sterilization?: any;
    userSelectionCategory: Array<any>;
    userSelectionSterilization: Array<any>;
    current: number;
    onProductModalityChange?: Function;
    updateProductModalityChange?: Function;
    userSelectionProductModality: ProductModalitySelections;
    cpwsQuestionsRef?: React.RefObject<HTMLDivElement>;
    cpws: CPWS;
    updateCurrentTab?: Function;
    viewOnly?: boolean;
}

export interface CPWSQuestionState {
    loading: boolean;
    checked: boolean;
    DS: boolean;
    DP: boolean;
    sterile: boolean;
    nonSterile: boolean;
    biologic: boolean;
    smallMolecule: boolean;
    vaccine: boolean;
}

export class CPWSQuestions extends React.Component<CPWSQuestionProps, CPWSQuestionState> {
    constructor(props: CPWSQuestionProps) {
        super(props);
        this.state = {
            loading: true,
            checked: false,
            DS: false,
            DP: false,
            sterile: false,
            nonSterile: false,
            biologic: false,
            smallMolecule: false,
            vaccine: false,
        };
    }

    onModalityChange = (e: CheckboxChangeEvent) => {
        if (e.target.value) {
            if(e.target.value === 'biologic')
                this.setState({biologic:e.target.checked});
            if(e.target.value === 'smallMolecule')
                this.setState({smallMolecule:e.target.checked});
            if(e.target.value === 'vaccine')
                this.setState({vaccine:e.target.checked});
            if(this.props.onProductModalityChange)
                this.props.onProductModalityChange(e.target.value, e.target.checked);

            if (this.props.updateCurrentTab)
                this.props.updateCurrentTab('Scope');
        }
    };

    onChangeDS = (e: any) => {
        this.setState({ DS: e.target.checked });
        this.props.category(e.target.value);
        if (this.props.updateCurrentTab)
            this.props.updateCurrentTab('Scope');
    };
    onChangeDP = (e: any) => {
        this.setState({ DP: e.target.checked });
        this.props.category(e.target.value);
        if (this.props.updateCurrentTab)
            this.props.updateCurrentTab('Scope');
    };
    onChangeSterile = (e: any) => {
        this.setState({ sterile: e.target.checked });
        this.props.sterilization(e.target.value);
        if (this.props.updateCurrentTab)
            this.props.updateCurrentTab('Scope');
    };
    onChangeNonSterile = (e: any) => {
        this.setState({ nonSterile: e.target.checked });
        this.props.sterilization(e.target.value);
        if (this.props.updateCurrentTab)
            this.props.updateCurrentTab('Scope');
    };
    componentDidMount = () => {
        const { userSelectionCategory, userSelectionSterilization, cpws, userSelectionProductModality } = this.props;
        if (userSelectionCategory.includes('DS')) {
            this.setState({ DS: true });
        }
        if (userSelectionCategory.includes('DP')) {
            this.setState({ DP: true });
        }
        if (userSelectionSterilization.includes('Sterile')) {
            this.setState({ sterile: true });
        }
        if (userSelectionSterilization.includes('Non-sterile')) {
            this.setState({ nonSterile: true });
        }
        if(userSelectionProductModality.biologic) {           
            this.setState({biologic: true});
        }
        if(userSelectionProductModality.smallMolecule) {           
            this.setState({smallMolecule: true});
        }
        if(userSelectionProductModality.vaccine) {           
            this.setState({vaccine: true});
        }
        /*if(cpws.productInformation.modalities.some(value => (value.modality_code === 'SM')) || this.props.userSelectionProductModality.smallMolecule)
        {
            if(this.props.updateProductModalityChange)
                this.props.updateProductModalityChange('smallMolecule', true);
            this.setState({smallMolecule: true});
        }
        if(cpws.productInformation.modalities.some(value => (value.modality_code === 'V')) || this.props.userSelectionProductModality.vaccine)
        {
            if(this.props.updateProductModalityChange)
                this.props.updateProductModalityChange('vaccine', true);
            this.setState({vaccine: true});
        }*/

    };

    render(): JSX.Element {
        const { current } = this.props;

        return (
            <FormContext.Consumer>
                {({ cpwsQuestionsAnswered, viewOnly }) => (
                    // <div ref={this.props.cpwsQuestionsRef}>
                        <div style={{alignItems:'center'}}>
                        {/* <Card {...this.props.panelProps} bordered={false}>
                            <Collapse {...this.props.panelProps} defaultActiveKey={['cpwsQuestions']}> */}
                                    <Row
                                       // ref={this.props.cpwsQuestionsRef}
                                        justify="center"
                                        gutter={1}
                                         className={
                                             (viewOnly || this.props.viewOnly === true)
                                                 ? 'view-only'
                                                 : !cpwsQuestionsAnswered
                                                 ? 'panel-border panel-border-error'
                                                 : 'panel-border'
                                         }
                                    >
                                        { !cpwsQuestionsAnswered ? (
                                            <Col span={24} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
                                                <FormValidationErrorText text="Please select at least one Product Modality, Category, and Sterilization." />
                                            </Col>
                                        ) : null}
                                        <Col span={24} style={{ alignContent: 'center', padding: '16px 0px 0px 16px' }}>
                                            <Row style={{ paddingLeft: '16px' }}>
                                                <Text strong>Impacted Product Modality:</Text>
                                            </Row>
                                            <Row style={{ paddingLeft: '16px' }}>
                                                <Col span={18}>
                                                    <Checkbox
                                                       // checked={this.props.userSelectionProductModality.biologic}
                                                        onChange={e => this.onModalityChange(e)}
                                                        value={'biologic'}
                                                        disabled={current === 7 ? true : false}
                                                        checked = {this.state.biologic}
                                                    >
                                                        Biologic
                                                    </Checkbox>
                                                    <Checkbox
                                                        //checked={this.props.userSelectionProductModality.smallMolecule}
                                                        onChange={e => this.onModalityChange(e)}
                                                        value={'smallMolecule'}
                                                        disabled={current === 7 ? true : false}
                                                        checked = {this.state.smallMolecule}
                                                    >
                                                        Small Molecule
                                                    </Checkbox>
                                                    <Checkbox
                                                        //checked={this.props.userSelectionProductModality.vaccine}
                                                        onChange={e => this.onModalityChange(e)}
                                                        value={'vaccine'}
                                                        disabled={current === 7 ? true : false}
                                                        checked = {this.state.vaccine}

                                                        
                                                    >
                                                        Vaccine
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24} style={{ alignContent: 'center', padding: '16px 0px 0px 16px' }}>
                                            <Row style={{ paddingLeft: '16px' }}>
                                                <Text strong>Is the change related to Drug Substance and/or Drug Product?</Text>
                                            </Row>
                                            <Row style={{ paddingLeft: '16px' }}>
                                                <Col span={18}>
                                                    <Checkbox
                                                        defaultChecked={this.state.DS}
                                                        checked={this.state.DS}
                                                        onChange={this.onChangeDS}
                                                        value={'DS'}
                                                        disabled={current === 7 ? true : false}
                                                    >
                                                        Drug Substance
                                                    </Checkbox>
                                                    <Checkbox
                                                        checked={this.state.DP}
                                                        onChange={this.onChangeDP}
                                                        value={'DP'}
                                                        disabled={current === 7 ? true : false}
                                                    >
                                                        Drug Product
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col
                                            span={24}
                                            style={{ alignContent: 'center', padding: '16px 0px 16px 16px' }}
                                        >
                                            <Row style={{ paddingLeft: '16px' }}>
                                                {this.state.DP && !this.state.DS ? 
                                                <Text strong>Is the impacted Drug Product Sterile and/or Non-Sterile?</Text> : 
                                                this.state.DP && this.state.DS ?
                                                <Text strong>Is the impacted Drug Substance and Drug Product Sterile and/or Non-Sterile?</Text> :
                                                <Text strong>Is the impacted Drug Substance Sterile and/or Non-Sterile?</Text>}
                                            </Row>
                                            <Row style={{ paddingLeft: '16px' }}>
                                                <Col span={18}>
                                                    <Checkbox
                                                        checked={this.state.sterile}
                                                        onChange={this.onChangeSterile}
                                                        value={'Sterile'}
                                                        disabled={current === 7 ? true : false}
                                                    >
                                                        Sterile
                                                    </Checkbox>
                                                    <Checkbox
                                                        checked={this.state.nonSterile}
                                                        onChange={this.onChangeNonSterile}
                                                        value={'Non-sterile'}
                                                        disabled={current === 7 ? true : false}
                                                    >
                                                        Non-Sterile
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                            {/* </Collapse>
                        </Card> */}
                    </div>
                )}
            </FormContext.Consumer>
        );
    }
}
