import { Card, Collapse, Spin, Typography, Popover, Space, Table } from 'antd';
import React from 'react';
import find from 'lodash/find';
import { PanelProps, CPWS, MatchCriteriaFinalRegulation, Country, MatchCriteriaFinalResult } from '../model';
import { openAttachment } from '../utils';

const { Panel } = Collapse;
const { Text, Link } = Typography;

export interface MatchCriteriaProps {
    panelProps: PanelProps;
    loading: boolean;
    matchCriteriaYesRegulation: Array<MatchCriteriaFinalResult>;
    cpws: CPWS;
    openAttachmentApi: Function;
    impactedCountries: Array<Country>;
}

export const MatchCriteriaQuestionsFinalYes: React.FC<MatchCriteriaProps> = (props: MatchCriteriaProps) => {
    const { panelProps, matchCriteriaYesRegulation, impactedCountries, loading } = props;
    const renderRIAQuestions = (text: string) => <Text strong>{text}</Text>;
    const renderRegualtion = (regulation: Array<MatchCriteriaFinalRegulation>) => {
        return regulation.map((regulation: MatchCriteriaFinalRegulation, index: number) => {
            const title =
                (regulation.link === 'Not Available' || regulation.link === 'n/a' || regulation.link === '') ? (
                    <Text style={{ fontWeight: 600, paddingTop: '4px', paddingBottom: '4px' }}>
                        Regulation description: {regulation.chtCode}
                    </Text>
                ) : (
                    <Link
                        href={regulation.link}
                        target="_blank"
                        onClick={e => {
                            openAttachment(e, regulation.link, props.openAttachmentApi);
                        }}
                        style={{ fontWeight: 400, paddingTop: '4px', paddingBottom: '4px' }}
                    >
                        Regulation description: {regulation.chtCode}
                    </Link>
                );
            const content = (
                <Space direction="vertical">
                    <Text>{regulation.description}</Text>
                    {/*<Space size={2}>
                                <Text strong>Market: </Text>
                                <Text>{f.regionCode}</Text>
                    </Space>*/}
                </Space>
            );

            return (
                <Popover key={JSON.stringify(`${regulation.chtCode}${index}`)} title={title} content={content}>
                    <Text key={regulation.chtCode}>{regulation.chtCode}; </Text>
                </Popover>
            );
        });
    };

    const columns = [
        {
            title: 'Regulatory Impact Assessment Question',
            dataIndex: 'mtcDefinition',
            render: (text: any) => renderRIAQuestions(text),
            width: 60,
        },
        {
            title: 'HA Regulation',
            dataIndex: 'regulations',
            width: 25,
            render: (text: any, record: MatchCriteriaFinalResult) =>
                renderRegualtion(record.regulations.filter(item => item.regMarket !== 'Red Compass')),
        },
        {
            title: 'Red Compass',
            dataIndex: 'regulations',
            width: 25,
            render: (text: any, record: MatchCriteriaFinalResult) =>
                renderRegualtion(record.regulations.filter(item => item.regMarket === 'Red Compass')),
        },
    ];
    const getCountryName = (countryCode: string) => {
        const country = find(impactedCountries, ['code', countryCode]);
        return country ? country.name : countryCode;
    };
    return (
            <div style={{width:"100%"}}>
                    {matchCriteriaYesRegulation && matchCriteriaYesRegulation.length > 0 ? (
                        <Table
                            loading={loading}
                            style={{width:"100%", border:"1px solid #ccc", maxHeight:"300px", overflow:"auto"}}                         
                            // rowKey={(record: any): string => {

                            //     return JSON.stringify(record);
                            // }}
                            dataSource={matchCriteriaYesRegulation}
                            columns={columns}
                            pagination={false}
                        />
                    ) : (
                        <div>
                            {props.loading === true ? (
                                <Spin size="default" className="loading-spin" />
                            ) : (
                                <div>No applicable match criteria questions</div>
                            )}
                        </div>
                    )}
              </div> 
    );
};
