import { Button, Collapse, List, Popover, Typography, Progress, Table, Row, Col, notification } from 'antd';
import React from 'react';
import {
    CPWSClassification,
    Material,
    PanelProps,
    CPWSClassificationCodes,
    MaterialType,
    CPWSClassificationCode,
    SelectedMaterials,
} from '../model';
import { sortObjectArrayByValue } from '../utils';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';
import find from 'lodash/find';
import some from 'lodash/some';

const { Panel } = Collapse;
const { Text } = Typography;

export interface MaterialClassificationProps {
    panelProps: PanelProps;
    impactedMaterials: Array<SelectedMaterials>;
    classificationList: CPWSClassification;
    updateMaterial?: Function;
    classificationListApplyAll: Function;
    loading: boolean;
    loadedClassification: boolean;
    current: number;
    classificationCodes: CPWSClassificationCodes;
    loadClassificationListFallback: Function;
    loadClassificationList: Function;
    viewOnly?: boolean;
}

interface ConfidenceLevel {
    classificationName: string;
    confidenceLevel: number | undefined;
}

const classificationDefinitionColumnsNameRender = (text: string) => <Text strong>{text}</Text>;
const classificationDefinitionColumnsDefRender = (text: string) => <Text className="classification-def">{text}</Text>;
const classificationDefinitionColumns = [
    {
        title: 'Classification Name',
        dataIndex: 'definition',
        render: classificationDefinitionColumnsNameRender,
        width: '40%',
        className: 'classification-dropdown-column',
    },
    {
        title: 'Classification Definition',
        dataIndex: 'description',
        render: classificationDefinitionColumnsDefRender,
        className: 'classification-dropdown-column',
    },
];

const confidenceLevelColumnsNameRender = (text: string) => <Text strong>{text}</Text>;
const confidenceLevelColumnsLevelRender = (text: number) => (
    <Progress
        percent={Math.round(text)}
        className="progress-bars"
        strokeColor={text >= 50 ? 'blue' : 'grey'}
        trailColor={text >= 50 ? '#d4e3f3' : '#fafafa'}
        status="normal"
    />
);
const confidenceLevelColumns = [
    {
        title: 'Classification Name',
        dataIndex: 'classificationName',
        render: confidenceLevelColumnsNameRender,
        width: '40%',
        className: 'classification-dropdown-column',
    },
    {
        title: 'Classification Name',
        dataIndex: 'confidenceLevel',
        render: confidenceLevelColumnsLevelRender,
        className: 'classification-dropdown-column',
    },
];
const colorLegendColumns = [
    {
        title: 'Color',
        dataIndex: 'color',
        className: 'colorLegend-column',
        width: '30%',
    },
    {
        title: 'Definition',
        dataIndex: 'definition',
        align: 'center' as 'center'
    }
];
const colorLegendData = [
    {
        color: <Col style={{ display: 'flex', alignItems: 'center' }}>
            <div className="square" style={{ backgroundColor: 'blue', textAlign: 'center', color: 'white' }}>X</div>
        </Col>,

        definition: 'Recommended'
    },
    {
        color: <Col style={{ display: 'flex', alignItems: 'center' }}>
            <div className="square" style={{ backgroundColor: 'green', textAlign: 'center', color: 'white' }}>Y</div>
        </Col>,

        definition: 'Selected By User'
    },
    {
        color: <Col style={{ display: 'flex', alignItems: 'center' }}>
            <div className="square" style={{ backgroundColor: 'red', textAlign: 'center', color: 'white' }}>Z</div>
        </Col>,

        definition: 'Unselected By User'
    }
];
export class MaterialClassification extends React.Component<MaterialClassificationProps> {
    constructor(props: MaterialClassificationProps) {
        super(props);
    }
    state = { loading: true,  classificationList: this.props.classificationList, }

    
    async componentDidMount(): Promise<void> {

        let retries = 0;
        while (retries < 10) {
            const response = await this.props.loadClassificationList();
            
            if (response === 'pending') {
                retries += 1;
                if (retries === 10) {
                    this.props.loadClassificationListFallback();
                    notification.error({
                        message: 'Unable to load classification list',
                        description:
                            'There was an error loading the classification list. Please select material classifications manually.',
                        duration: 10,
                    });
                    break;
                }
                //await sleep();
            } else if (response === 'error') {
                this.props.loadClassificationListFallback();
                notification.error({
                    message: 'Unable to load classification list',
                    description:
                        'There was an error loading the classification list. Please select material classifications manually.',
                    duration: 10,
                });
                break;
            } else {
                break;
            }
        }
        
        this.setState({ loading: false });
    }


    waitLogin = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    async componentDidUpdate(prevProps: any): Promise<void> {
        if (this.props.loading !== prevProps.loading) {
            this.renderConfidenceLevels();
        }
        if(this.props.impactedMaterials !== prevProps.impactedMaterials)
        {
            console.log("inside component did update");
            let retries = 0;
            while (retries < 10) {
                const response = await this.props.loadClassificationList();
                
                if (response === 'pending') {
                    retries += 1;
                    if (retries === 10) {
                        this.props.loadClassificationListFallback();
                        notification.error({
                            message: 'Unable to load classification list',
                            description:
                                'There was an error loading the classification list. Please select material classifications manually.',
                            duration: 10,
                        });
                        break;
                    }
                    //await sleep();
                } else if (response === 'error') {
                    this.props.loadClassificationListFallback();
                    notification.error({
                        message: 'Unable to load classification list',
                        description:
                            'There was an error loading the classification list. Please select material classifications manually.',
                        duration: 10,
                    });
                    break;
                } else {
                    break;
                }
            }
            
            this.setState({ loading: false });
        }
    }

    getClassificationName(code: string) {
        const codeObj = find(this.props.classificationCodes, ['code', code]);
        return codeObj ? codeObj.definition : code;
    }

    setClassificationValue(material: SelectedMaterials, code: string) {
        console.log("setClassificationValue Called");
        console.log("Material:", material);
        if (
           ( material.classification[code]! >= 50 &&
            material.classification[code]! <= 100 &&
            this.props.classificationList[code]! >= 50 &&
            this.props.classificationList[code]! <= 100) 
            // ||
            // (this.state.classificationList[code]! >= 50 &&
            //     this.state.classificationList[code]! <= 100
            // )
        ) {
            //console.log('classification code >> !>=50 && !<=100');
            material.classification[code] = -1;
            //this.state.classificationList[code] = -1;
        } else if (material.classification[code] === 100 || material.classification[code] === 200) {
            //console.log('classification code >> 100 or 200');
            material.classification[code] = 0;
        } else if (material.classification[code] === -1 ) {
        //|| this.state.classificationList[code] === -1) {
            //console.log('classification code >> -1');
            material.classification[code] = this.props.classificationList[code];
            //this.state.classificationList[code] = this.props.classificationList[code];
        } else {
            // Val must be 0 or less than 50 and user selected
            material.classification[code] = 200;
        }

        if (this.props.updateMaterial) {
            this.props.updateMaterial(material);
        }
    }

    setButtonColor(material: SelectedMaterials, code: string) {
        //console.log("setButtonColor Called");
        if (
           ( material.classification[code] &&
            material.classification[code]! >= 50 &&
            material.classification[code]! <= 100  &&
           this.props.classificationList[code]! >= 50 &&
            this.props.classificationList[code]! <= 100) 
            // ||
            // (this.state.classificationList[code] ! >= 50 &&
            //     this.state.classificationList[code] ! <=100)
        ) {
            //console.log('classification code >> blue');
            return 'blue';
        } else if (material.classification[code] === -1 ) {
            //|| this.state.classificationList[code] === -1) {
            //console.log('classification code >> red');
            return 'red';
        } else if (
            material.classification[code] === 200 &&
            this.props.classificationList[code]! >= 50 &&
            this.props.classificationList[code]! <= 100
        ) {
            //console.log('classification code >> 200');
            return 'blue';
        } else if (material.classification[code] === 100 || material.classification[code] === 200) {
            return 'green';
        } else {
            return 'white';
        }
    }

    sortedClassificationCodes = (classificationCodes: CPWSClassificationCodes) => {
        console.log("sortedClassificationCodes Called");
        return classificationCodes.sort((a: CPWSClassificationCode, b: CPWSClassificationCode) => {
            if (a.definition < b.definition) {
                return -1;
            } else if (a.definition > b.definition) {
                return 1;
            } else {
                return 0;
            }
        });
    };

    applyAllClassificationList() {
        console.log('triggerd');
        const clist = this.props.impactedMaterials[0].classification;
        this.props.classificationListApplyAll(clist);
    }

    renderButtons(material: SelectedMaterials) {
        //console.log("renderButtons Called");
        return Object.keys(this.props.classificationList)
            .sort((a: string, b: string) =>
                this.getClassificationName(a) < this.getClassificationName(b)
                    ? -1
                    : this.getClassificationName(a) > this.getClassificationName(b)
                        ? 1
                        : 0,
            )
            .map((code: string) => {
                return (
                    <Button
                        type="default"
                        onClick={e => {
                            this.props.current === 5 ? e.preventDefault() : this.setClassificationValue(material, code);
                        }}
                        shape="round"
                        size={'small'}
                        style={{
                            backgroundColor: this.setButtonColor(material, code),
                            color: this.setButtonColor(material, code) === 'white' ? 'black' : 'white',
                        }}
                        key={
                            '_' +
                            Math.random()
                                .toString(36)
                                .substr(2, 9)
                        }
                    >
                        {this.getClassificationName(code)}
                    </Button>
                );
            });
    }

    renderListItems(material: SelectedMaterials, classificationSelected: boolean) {
        const impactedmatNames = sortObjectArrayByValue([material], 'name'); 
        console.log("impacted materials", impactedmatNames)
        const names = impactedmatNames.map((_list) =>
                    _list.downstreamMaterials &&
                     _list.downstreamMaterials
                        .map((materials) => materials.type.map((names) => names.name).join(', '))
                        .filter((v, i, a) => a.indexOf(v) === i).sort().join(', ')
                    
                );

        return (
            <>
                {!classificationSelected &&
                    !some(material.classification, value => {
                        if (value) {
                            return value >= 50;
                        } else {
                            return false;
                        }
                    }) ? (
                    <FormValidationErrorText text="Please select a material classification" />
                ) : null}
                <List.Item.Meta
                    title={
                        <div>
                            {material.name}, Material Type:{' '} 
                            <span style={{"fontWeight": "normal"}}>
                                {material.type
                                    .map((matType: MaterialType) => matType.name)
                                    .sort()
                                    .join(', ')}
                            </span>&nbsp;&nbsp;
                            {/* Screen 2 enhancement additional material type fields display for container closure */}
                            <span className="material-type-style">
                                {material.type.map((matType: MaterialType) => matType.code == "T14" ? `${'('+names.filter(val => val)+')'}` : null)}
                            </span>
                        </div>
                    }
                //description={material.name}
                />
                {this.renderButtons(material)}
            </>
        );
    }

    renderConfidenceLevels = () => {
        console.log("renderConfidenceLevels Called");
        return (
            <Table
                dataSource={Object.keys(this.props.classificationList)
                    .map((code: string) => {
                        return {
                            classificationName: this.getClassificationName(code),
                            confidenceLevel: this.props.classificationList[code],
                        };
                    })
                    .sort((a: ConfidenceLevel, b: ConfidenceLevel) => {
                        return a.classificationName < b.classificationName
                            ? -1
                            : a.classificationName > b.classificationName
                                ? 1
                                : 0;
                    })}
                columns={confidenceLevelColumns}
                pagination={false}
                className="classification-dropdown"
                rowKey={
                    '_' +
                    Math.random()
                        .toString(36)
                        .substr(2, 9)
                }
                scroll={{ y: 500 }}
            />
        );
    };

    renderClassificationContent = () => {
        console.log("renderClassificationContent Called");
        return (
            <Table
                dataSource={this.sortedClassificationCodes(this.props.classificationCodes)}
                columns={classificationDefinitionColumns}
                pagination={false}
                className="classification-dropdown"
                rowKey={
                    '_' +
                    Math.random()
                        .toString(36)
                        .substr(2, 9)
                }
                scroll={{ y: 500 }}
            />
        );
    };

    renderColorLegend = () => {
        return (

            <Table
                dataSource={colorLegendData}
                columns={colorLegendColumns}
                pagination={false}
                rowKey={
                    '_' +
                    Math.random()
                        .toString(36)
                        .substr(2, 9)
                }
                bordered
            />
        );
    }

    renderClassificationDefinitions = (current: number) => {
        console.log("renderClassificationDefinitions Called");
        return (

            <Row>{current !== 7 ? <Col>{this.showClassificationConfidence()}</Col> : null}
                <Popover content={this.renderClassificationContent} placement="bottomLeft" trigger="click">
                    <Button
                        type="default"
                        onClick={(e: any) => {
                            e.stopPropagation();
                        }}
                        style={{ marginLeft: '5px', marginRight: '5px' }}
                    >
                        Classification Definitions
                </Button>
                </Popover>                
                <Popover content={this.renderColorLegend} placement="bottomLeft" trigger="click">
                    <Button
                        type="default"
                        onClick={(e: any) => {
                            e.stopPropagation();
                        }}
                    >
                        Color Legend
                </Button>
                </Popover>
                
            </Row>
        );
    };

    recommendationListDisplay = (current: number) => {
        console.log("recommendationListDisplay Called");
        if (this.props.loading === false) {
            const recommendedList = Object.keys(this.props.classificationList)
                .reduce((recommendations: Array<string>, classificationCode: string) => {
                    if (
                        this.props.classificationList[classificationCode] &&
                        this.props.classificationList[classificationCode]! >= 50 &&
                        this.props.classificationList[classificationCode]! <= 100
                    ) {
                        recommendations.push(this.getClassificationName(classificationCode));
                    }
                    return recommendations;
                }, [])
                .sort();

            return (
                // <div>
                <Row>
                    <Col style={{ paddingTop: '12px', paddingRight: '5px' }}>
                        <Text strong>Recommended Change Classification:</Text>
                    </Col>
                    {/* <Col>
                        <List
                            //grid={{ gutter: 16 }}
                            dataSource={recommendedList}
                            //renderItem={item => <List.Item>{item}</List.Item>}
                            renderItem={item => <div>{item}</div>}
                        ></List>
                    </Col> */}

                    <Col style={{ paddingTop: '9px', marginLeft: '25px' }}>
                        <Row>{current !== 7 ? <Col>{this.showClassificationConfidence()}</Col> : null}
                            <Popover content={this.renderClassificationContent} placement="bottomLeft" trigger="click">
                                <Button
                                    type="default"
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                    }}
                                    style={{ marginLeft: '5px', marginRight: '5px' }}
                                >
                                    Classification Definitions
                  </Button>
                            </Popover>
                            <Popover content={this.renderColorLegend} placement="bottomLeft" trigger="click">
                                <Button
                                    type="default"
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    Color Legend
                  </Button>
                            </Popover>
                        </Row>
                    </Col>
                </Row>
            );
        }
    };

    showClassificationConfidence = (): any => {
        console.log("showClassificationConfidence Called");
        if (this.props.loading === false) {
            return (
                <Popover content={this.renderConfidenceLevels} placement="bottomLeft" trigger="click">
                    <Button style={{ marginLeft: '10px' }} type="default" onClick={e => e.stopPropagation()}>
                        Confidence Level
                    </Button>
                </Popover>
            );
        } else {
            return <span>Loading...</span>;
        }
    };

    
    render(): JSX.Element {
        
        const { panelProps, impactedMaterials, current } = this.props;

        console.log('classification code >> materials >> ');
        console.log("Impacted Materials", impactedMaterials);
        return (
            <Collapse {...panelProps} defaultActiveKey={1}>
                <Panel
                    header={this.recommendationListDisplay(current)}
                    key="1"
                //extra={current === 7 ? <span></span> : this.renderClassificationDefinitions(current)}
                >
                    
                    {impactedMaterials != undefined && impactedMaterials.length > 1 ?
                    <FormContext.Consumer>
                        {({ viewOnly }) => (
                            <Row style={{ paddingLeft: '24px' }}>
                                <Col className="btn-clist-applyall">
                                    <Button
                                        type="default"
                                        className = "ant-btn ant-btn-primary"
                                        disabled={(viewOnly || this.props.viewOnly === true)}
                                        onClick={() => this.applyAllClassificationList()}
                                    > Apply All </Button>
                                </Col>                        
                            </Row> 
                        )}
                    </FormContext.Consumer> : null }
                   
                    {/* <Row gutter={16} className="legend-box">
                        <Col>
                            <Text strong>Legend:</Text>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="square" style={{ backgroundColor: 'blue' }}></div>
                            <Text style={{ paddingLeft: '10px' }}>Recommended</Text>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="square" style={{ backgroundColor: 'green', padding: '10px' }}></div>
                            <Text style={{ paddingLeft: '10px' }}>Selected by user</Text>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="square" style={{ backgroundColor: 'red', padding: '10px' }}></div>
                            <Text style={{ paddingLeft: '10px' }}>Unselected by user</Text>
                        </Col>
                    </Row> */}
                    <FormContext.Consumer>
                        {({ classificationSelected, viewOnly }) => (
                            <List
                                loading={this.props.loading}
                                itemLayout="vertical"
                                size="large"
                                dataSource={sortObjectArrayByValue(impactedMaterials, 'name')}
                                locale={{ emptyText: <Text>No classification recommendations</Text> }}
                                className={(viewOnly || this.props.viewOnly === true) ? 'view-only' : ''}
                                renderItem={material => (
                                    <List.Item
                                        key={material.name}
                                        className={
                                            !classificationSelected &&
                                                !some(material.classification, value => {
                                                    if (value) {
                                                        return value >= 50;
                                                    } else {
                                                        return false;
                                                    }
                                                })
                                                ? 'list-highlight'
                                                : ''
                                        }
                                    >
                                        {this.renderListItems(material, classificationSelected)}
                                    </List.Item>
                                )}
                            />
                        )}
                    </FormContext.Consumer>
                </Panel>
            </Collapse>
        );
    }
}
