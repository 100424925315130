import React from 'react';
import { PanelProps } from '../model';
import { Card, Col, Row, Input, Collapse } from 'antd';
import { FormContext } from '../context/FormContext';
import { FormValidationErrorText } from './FormValidationErrorText';

const { TextArea } = Input;
const { Panel } = Collapse;

interface RegulatoryJustificationProps {
    panelProps: PanelProps;
    justification: string;
    onJustificationChange?: Function;
    current?: number;
    justificationRef?: React.RefObject<HTMLDivElement>;
}

export const RegulatoryJustification: React.FC<RegulatoryJustificationProps> = (
    props: RegulatoryJustificationProps,
) => {
    return (
        <FormContext.Consumer>
            {({ justificationProvided, viewOnly }) => (
                <div ref={props.justificationRef}>
                    <Card bordered={false}>
                        <Collapse {...props.panelProps} defaultActiveKey={['regulatoryJustification']}>
                            <Panel header="Regulatory Justification" key="regulatoryJustification">
                                <Row gutter={1}>
                                    {!justificationProvided ? (
                                        <Col span={24}>
                                            <FormValidationErrorText text="Please enter a justification." />
                                        </Col>
                                    ) : null}
                                    <Col span={24}>
                                        {(!props.current || (props.current && props.current) !== 7) && !viewOnly ? (
                                            <TextArea
                                                rows={props.current && props.current === 7 ? undefined : 4}
                                                autoSize={props.current && props.current === 7 ? true : false}
                                                placeholder="Enter justification for regulatory questions here."
                                                value={props.justification}
                                                onChange={e =>
                                                    props.onJustificationChange
                                                        ? props.onJustificationChange(e.target.value)
                                                        : e.preventDefault()
                                                }
                                                className={
                                                    !justificationProvided
                                                        ? 'select-highlight textarea-print'
                                                        : 'textarea-print'
                                                }
                                                spellCheck={true}
                                            ></TextArea>
                                        ) : (
                                            <div className="div-print">{props.justification}</div>
                                        )}
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Card>
                </div>
            )}
        </FormContext.Consumer>
    );
};
